const statuses = {
  not_started: "Not Started",
  started: "In Progress",
  submitted: "Submitted",
  unlocked: "Unlocked",
  signed: "Signed",
};

const revieweeStatuses = {
  ...statuses,
  appealed: "Appealed",
  appeal_rejected: "Appeal Rejected",
  appeal_accepted: "Appeal Accepted",
};

export { statuses, revieweeStatuses };

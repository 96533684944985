import React from "react";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { Button, Modal, Row, Col, Form, Divider, Tabs } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { APIBasedEmployeeSearch } from "components";
import BulkAddReviews from "./BulkAddReviews";
import { addReviewCycleParticipantsApi } from "apis/perfReviewApi";
import { searchEmployeesWithManagerApi } from "apis/commonApi";
import {successNotification, errorNotification } from "app/appUtils";

const { TabPane } = Tabs;

const EmployeeManagerInput = ({
  value = "",
  fieldKey = "",
  participants = [],
  setFieldsValue = () => {},
}) => {
  const onChange = (newVal, selectedEmployee) => {
    const newParticipants = participants?.map((participant, index) => {
      if (index === fieldKey) {
        return {
          reviewee_id: newVal,
          reviewer_id: selectedEmployee?.manager?.id || null,
          availableReviewer: selectedEmployee?.manager || [],
        };
      } else {
        return participant;
      }
    });

    setFieldsValue({ participants: newParticipants });
  };

  return (
    <>
      <APIBasedEmployeeSearch
        value={value}
        placeholder="Type Reviewee"
        showArrow={false}
        allowClear={true}
        shouldShowAvatar={true}
        shouldSendSelectedEmployee={true}
        currentOwners={[]}
        searchApi={searchEmployeesWithManagerApi}
        onChange={onChange}
      />
    </>
  );
};
EmployeeManagerInput.propTypes = {};

const getCurrentAvailableReviewer = (participants = [], fieldKey = 0) => {
  const review = participants[fieldKey] || {};
  return review?.availableReviewer || [];
};

const AddReviewModal = ({
  cycle = {},
  reviewCycleId = "",
  visible = false,
  employees = [],
  inActiveEmployees = [],
  refetch = () => {},
  handleSearch = () => {},
  handleCancel = () => {},
  handleSubmit = () => {},
}) => {
  const [form] = Form.useForm();

  const [addReviewCycleParticipants, { isLoading: isAddingParticipants }] =
    useMutation(addReviewCycleParticipantsApi, {
      onSuccess: () => {
        successNotification("Successfully added participants")
        handleCancel();
        form.resetFields();
        refetch();
        handleSearch();
      },
      onError: (error) => {
        errorNotification(error);
      },
    });

  const handleAddReviews = () => {
    form
      .validateFields()
      .then((values) => {
        addReviewCycleParticipants({
          participants: values?.participants || [],
          reviewCycleId,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={`Add Reviewee to ${cycle?.name || ""}`}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      onOk={handleAddReviews}
      footer={null}
    >
      <Tabs>
        <TabPane tab="Add a Employee" key={"1"}>
          <Form
            layout="vertical"
            form={form}
            name="dynamic_form_nest_item"
            onFinish={handleAddReviews}
            autoComplete="off"
            initialValues={{
              participants: [
                {
                  reviewee_id: null,
                  reviewer_id: null,
                },
              ],
            }}
          >
            <Form.List name="participants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row gutter={[8]}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "reviewee_id"]}
                          fieldKey={[fieldKey, "reviewee_id"]}
                          rules={[
                            { required: true, message: "Missing reviewee" },
                          ]}
                        >
                          <EmployeeManagerInput
                            fieldKey={fieldKey}
                            participants={form.getFieldValue("participants")}
                            setFieldsValue={form.setFieldsValue}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, "reviewer_id"]}
                          fieldKey={[fieldKey, "reviewer_id"]}
                          rules={[
                            { required: true, message: "Missing reviewer" },
                          ]}
                        >
                          <APIBasedEmployeeSearch
                            placeholder="Type Reviewer"
                            showArrow={false}
                            allowClear={true}
                            shouldShowAvatar={true}
                            currentOwners={
                              getCurrentAvailableReviewer(
                                form.getFieldValue("participants"),
                                fieldKey
                              ) || []
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="flex-display align-items-center">
                        <MinusCircleOutlined
                          className="mb-20"
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      className="width-250-px"
                      type="dashed"
                      onClick={() =>
                        add({
                          reviewee_id: null,
                          reviewer_id: null,
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add More
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item> */}
          </Form>
          <Divider className="my-8" />
          <Row>
            <Button type="primary" onClick={handleAddReviews}>
              Add Participants
            </Button>

            <Button
              className="ml-8"
              type="primary"
              ghost
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </TabPane>
        <TabPane tab="Bulk Add" key={"2"}>
          <BulkAddReviews
            isAddingParticipants={isAddingParticipants}
            activeEmployees={employees?.map((emp) => ({
              ...emp,
              key: emp?.id,
            }))}
            inActiveEmployees={inActiveEmployees}
            reviewCycleId={reviewCycleId}
            addReviewCycleParticipants={addReviewCycleParticipants}
            handleCancel={handleCancel}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
AddReviewModal.propTypes = {
  cycle: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AddReviewModal;

import axios from "axios";

const saveKPIApi = async ({ kpi = {} }) => {
  const { data } = await axios.post("/api/v2/kpi", {
    kpi,
  });
  return data;
};

const getCompanyKPIsApi = async (_, team_id) => {
  const { data } = await axios.post("/api/v2/kpi/company_kpis", {
    team_id: team_id === "company-okrs" ? null : team_id,
  });
  return data;
};

const getMyKPIsApi = async () => {
  const { data } = await axios.get("/api/v2/kpi/my_kpis");
  return data;
};

const deleteKPIApi = async (id = null) => {
  const { data } = await axios.delete(`/api/v2/kpi/${id}`);
  return data;
};

const updateProgressApi = async ({ id, checkin }) => {
  const { data } = await axios.post(`/api/v2/kpi/${id}/current_value_checkin`, {
    ...checkin,
  });
  return data;
};

const saveTargetValueApi = async ({ kpiId, checkin = {} }) => {
  const { data } = await axios.post(
    `/api/v2/kpi/${kpiId}/target_value_checkin`,
    {
      checkin,
    }
  );
  return data;
};

const removeTargetValueApi = async ({ kpiId, checkinId }) => {
  const { data } = await axios.delete(
    `/api/v2/kpi/${kpiId}/target_value_checkin/${checkinId}`
  );
  return data;
};

const getProgressOverTimeApi = async (_, kpiId) => {
  const { data } = await axios.get(`/api/v2/kpi/${kpiId}/progress_over_time`);
  return data;
};

const getKPIActivityDataApi = async (_, kpiId) => {
  const { data } = await axios.get(`/api/v2/kpi/${kpiId}/activities`);
  return data;
};

const getAllCommentsApi = async (_, kpiId) => {
  const { data } = await axios.get(`/api/v2/kpi/${kpiId}/comments/`);
  return data;
};

const updateCommentApi = async ({ kpiId, commentId, comment }) => {
  const { data } = await axios.put(
    `/api/v2/kpi/${kpiId}/comments/${commentId}`,
    {
      comment,
    }
  );
  return data;
};

const postCommentApi = async ({ kpiId, comment }) => {
  const { data } = await axios.post(`/api/v2/kpi/${kpiId}/comments`, {
    comment,
  });
  return data;
};

const deleteCommentApi = async ({ kpiId, commentId }) => {
  const { data } = await axios.delete(
    `/api/v2/kpi/${kpiId}/comments/${commentId}`
  );
  return data;
};

const getKPIInsightsSummary = async () => {
  const { data } = await axios.get("/api/v2/kpi/kpi_insights_summary");

  return data;
};

const getEmployeesWithoutCheckinsApi = async ({
  days = 7,
  kpi_title_query = "",
  filters = [],
}) => {
  const { data } = await axios.post(`/api/v2/kpi/emp_without_checkin`, {
    inactive_days: days,
    kpi_title_query,
    filters,
  });
  return data;
};

const getEmployeesWithKPIStatusApi = async ({
  status = "at_risk",
  kpi_title_query = "",
  filters = [],
}) => {
  const { data } = await axios.post(`/api/v2/kpi/status/`, {
    status,
    kpi_title_query,
    filters,
  });
  return data;
};

const getEmployeeKPIsApi = async (_, empId) => {
  const { data } = await axios.get(`/api/v2/kpi/employee_kpis/${empId}/`);
  return data;
};

const getKPITeamInsightsApi = async () => {
  const { data } = await axios.post("/api/v2/kpi/team_kpis_insights");
  return data;
};

export {
  saveKPIApi,
  getCompanyKPIsApi,
  getMyKPIsApi,
  deleteKPIApi,
  updateProgressApi,
  saveTargetValueApi,
  removeTargetValueApi,
  getProgressOverTimeApi,
  getKPIActivityDataApi,
  getAllCommentsApi,
  updateCommentApi,
  postCommentApi,
  deleteCommentApi,
  getKPIInsightsSummary,
  getEmployeesWithoutCheckinsApi,
  getEmployeesWithKPIStatusApi,
  getEmployeeKPIsApi,
  getKPITeamInsightsApi,
};

import React from "react";
// import PropTypes from 'prop-types';
import moment from "moment";
import { DatePicker } from "antd";

const KeyResultDeadlinePicker = ({
  krIndex,
  selectedPeriodObj = {},
  value,
  onChange,
  handleChange,
}) => {
  const handleOnChange = (val) => {
    if (val) {
      onChange(val);
    } else {
      handleChange(krIndex, val, onChange);
    }
  };
  return (
    <DatePicker
      format="MMM D"
      disabledDate={(current) =>
        current &&
        (current <
          moment(selectedPeriodObj?.start_date, "YYYY-MM-DD").startOf("day") ||
          current >
            moment(selectedPeriodObj?.end_date, "YYYY-MM-DD").endOf("day"))
      }
      value={value}
      onChange={handleOnChange}
    />
  );
};

KeyResultDeadlinePicker.propTypes = {};

export default KeyResultDeadlinePicker;

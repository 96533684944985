import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import { pluralize } from "humanize-plus";

const { Text } = Typography;

const getDueDateType = (differenceOfDates = 0, isDueDateInPast = false) => {
  if (isDueDateInPast || differenceOfDates === 0 || differenceOfDates === 1) {
    return "danger";
  }

  if (differenceOfDates <= 3) {
    return "warning";
  }

  return "success";
};

const DueDateText = ({ differenceOfDates = 0, isDueDateInPast = true }) => {
  if (differenceOfDates === 0) {
    return <Text type={"danger"}>Due today</Text>;
  }

  return (
    <Text type={getDueDateType(differenceOfDates, isDueDateInPast)}>{`Due ${
      !isDueDateInPast ? "in" : ""
    } ${differenceOfDates} ${pluralize(differenceOfDates, "day") || "days"} ${
      isDueDateInPast ? "ago" : ""
    }`}</Text>
  );
};
DueDateText.propTypes = {
  differenceOfDates: PropTypes.number,
  isDueDateInPast: PropTypes.bool,
};

export default DueDateText;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Divider, Row } from "antd";

import { ButtonWithSpinner, EmployeeSearchSelect } from "components";
import { getEmployeeName } from "app/appUtils";

const EditManagerModel = ({
  visible = false,
  updatingEmployee = false,
  employee = {},
  activeEmployees = [],
  inActiveEmployees = [],
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();

  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onSubmit(values);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const otherActiveEmployees = activeEmployees?.filter(
    (emp) => emp?.id !== employee?.id
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={`Edit ${getEmployeeName(employee)}'s Manager`}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="employee_update_manager"
        initialValues={{
          employeeId: employee?.id,
          managerId: employee?.manager_id,
        }}
      >
        <Form.Item name="employeeId" hidden={true} />
        <Form.Item name="managerId" label="Manager">
          <EmployeeSearchSelect
            className={"width-250-px"}
            shouldShowAvatar={true}
            allowClear={true}
            placeholder={"Search Employees"}
            employees={otherActiveEmployees}
            inActiveEmployees={inActiveEmployees}
          />
        </Form.Item>
        <Divider className="my-0" />
        <Row className="mt-24 mb-0" justify="end">
          <ButtonWithSpinner
            type="primary"
            isSpinning={updatingEmployee}
            disabled={updatingEmployee}
            onClick={handleUpdate}
          >
            Submit
          </ButtonWithSpinner>
          <Button
            className="ml-8"
            disabled={updatingEmployee}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

EditManagerModel.propTypes = {
  visible: PropTypes.bool,
  employee: PropTypes.object,
  managerId: PropTypes.number,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default EditManagerModel;

import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";

const ItemMenu = ({
  canUpdate = true,
  isKeyResult = true,
  isItemBoolean = false,
  isKRPostponed = false,
  handleIsEditVisible = () => {},
  handleIsCommentsModalVisible = () => {},
  handleIsActivityModalVisible = () => {},
  handlePostponeKR = () => {},
  handleChangeToObjective = () => {},
  handlePopover = () => {},
  handleDelete = () => {},
  handleConvertYesNoItem = () => {},
  ...props
}) => {
  return (
    <Menu {...props}>
      {canUpdate && (
        <Menu.Item disabled={!canUpdate} onClick={handleIsEditVisible}>
          {isKeyResult ? "Edit Key Result" : "Edit Initiative"}
        </Menu.Item>
      )}
      {!isItemBoolean && canUpdate && (
        <Menu.Item disabled={!canUpdate} onClick={handlePopover}>
          Check In
        </Menu.Item>
      )}
      {canUpdate && !isItemBoolean && (
        <Menu.Item
          disabled={!canUpdate}
          onClick={() => {
            handleConvertYesNoItem(true);
          }}
        >
          {`Convert to Yes/No ${isKeyResult ? "KR" : "Initiative"}`}
        </Menu.Item>
      )}
      {canUpdate && isItemBoolean && (
        <Menu.Item
          disabled={!canUpdate}
          onClick={() => {
            handleConvertYesNoItem(false);
          }}
        >
          {`Convert to Metric based ${isKeyResult ? "KR" : "Initiative"}`}
        </Menu.Item>
      )}
      {canUpdate && isKeyResult && (
        <Menu.Item disabled={!canUpdate} onClick={handlePostponeKR}>
          {isKRPostponed ? "Un-Postpone Key Result" : "Postpone Key Result"}
        </Menu.Item>
      )}
      {canUpdate && isKeyResult && (
        <Menu.Item disabled={!canUpdate} onClick={handleChangeToObjective}>
          Convert to Objective
        </Menu.Item>
      )}
      <Menu.Item onClick={handleIsCommentsModalVisible}>Add Comment</Menu.Item>
      <Menu.Item onClick={handleIsActivityModalVisible}>See Activity</Menu.Item>
      {canUpdate && (
        <Menu.Item disabled={!canUpdate} onClick={handleDelete}>
          Delete
        </Menu.Item>
      )}
    </Menu>
  );
};

ItemMenu.propTypes = {
  canUpdate: PropTypes.bool,
  handleIsEditKeyResultVisible: PropTypes.func,
  handlePopover: PropTypes.func,
  handleDeleteKeyResult: PropTypes.func,
};

ItemMenu.propTypes = {};

export default ItemMenu;

import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Upload, Typography } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryCache } from "react-query";

import { InlineSpinner } from "components";
import { useSetupCompletionPercentage } from "store";
import {
  errorNotificationWithString,
  successNotification,
  errorNotification,
} from "app/appUtils";
import { updateGroupLogoApi } from "apis/appApi";
import { useCurrentCompany } from "store";
import CompanyNameInput from "./CompanyNameInput";

import logo192 from "assets/logo192.png";
import styles from "components/layouts/AppLayoutV3.module.css";

const { Text, Title } = Typography;

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

const { Dragger } = Upload;

function validations(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    errorNotificationWithString("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    errorNotificationWithString(
      "Image too big, please try it with a smaller image."
    );
  }
  // return false to prevent default api calling from antd
  return isJpgOrPng && isLt2M;
}

const CompanyDetails = (props) => {
  const queryCache = useQueryCache();
  const [currentCompany, setCurrentCompany] = useCurrentCompany();
  const [isEditingCompanyName, setIsEditingCompanyName] = useState(false);
  const [imgFileList, setImgFileList] = useState([]);
  //   const [imgURL, setImgURL] = useState(null);
  const [setupCompletionPercentage] = useSetupCompletionPercentage();

  const [updateGroupLogo, { isLoading: isUploadingLogo }] = useMutation(
    updateGroupLogoApi,
    {
      onSuccess: (data) => {
        // setCurrentCompany((currentCompany ) => ({...currentCompany, logo: data}))
        successNotification("Successfully updated logo");
        setCurrentCompany({ ...currentCompany, logo_url: data?.logo_url });
        if (setupCompletionPercentage !== "100") {
          queryCache.invalidateQueries("getCheckList");
        }
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleEditCompName = () => {
    setIsEditingCompanyName(!isEditingCompanyName);
  };

  const handleChange = (info) => {
    // console.log(info);
    if (info?.fileList?.length === 1) {
      const formData = new FormData();
      console.log(info?.fileList[0]);
      formData.append("file", info?.file);
      if (validations(info?.fileList[0])) {
        setImgFileList(info?.fileList);
        updateGroupLogo({ formData });
      }
    } else if (info?.fileList?.length > 1) {
      const newFileData = info?.file;
      const formData = new FormData();
      formData.append("file", newFileData);
      if (validations(newFileData)) {
        setImgFileList([info?.fileList[info?.fileList?.length - 1]]);
        updateGroupLogo({ formData });
      }
    }
  };

  const handleRemove = () => {
    setImgFileList([]);
    const formData = new FormData();
    // formData.append("file", null);
    updateGroupLogo({ formData });
  };

  const currentLogo = currentCompany?.logo_url;

  return (
    <div>
      <div>
        <Text className="block-display">Company Name</Text>
        {isEditingCompanyName ? (
          <CompanyNameInput
            shouldShowCancel={true}
            onCancel={handleEditCompName}
          />
        ) : (
          <>
            <Title className="inline-flex-display mt-0" level={2}>
              {currentCompany?.name}
            </Title>
            <EditOutlined
              className="ml-8 cursor-pointer text-secondary"
              onClick={handleEditCompName}
            />
          </>
        )}
      </div>

      <div className="mt-24">
        <div className="mb-8">
          <Text className="mr-8 block-display">Company Logo</Text>
          <Text type="secondary">
            Please upload an image of height 48px and width upto 60px
          </Text>
          {isUploadingLogo && <InlineSpinner />}
        </div>
        <div className="width-250-px">
          {currentLogo ? (
            <>
              <img
                className={styles.logoDetails}
                src={currentCompany?.logo_url || logo192}
                alt="logo"
              />
              <DeleteOutlined onClick={handleRemove} />
            </>
          ) : (
            <Dragger
              className="logo-dragger"
              accept={"image/jpeg, image/png"}
              listType="picture-card"
              multiple={false}
              fileList={imgFileList}
              beforeUpload={() => {
                return false;
              }}
              onChange={handleChange}
              onRemove={handleRemove}
              showUploadList={{ showPreviewIcon: false }}
            >
              {imgFileList?.length > 0 ? (
                <>Click or drag to Update</>
              ) : (
                <div>
                  <PlusOutlined />
                  <div className="mt-8">Click or drag to Upload</div>
                </div>
              )}
            </Dragger>
          )}
        </div>
      </div>
    </div>
  );
};

CompanyDetails.propTypes = {};

export default CompanyDetails;

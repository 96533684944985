import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Form, Input, Modal, Space, Typography, Row, Col, Switch } from "antd";
import { DeleteOutlined, DragOutlined } from "@ant-design/icons";

import useSetQuestionFormValues from "hooks/useSetQuestionFormValues";
import MacroTextNote from "./MacroTextNote";
import styles from "../../../Review.module.css";

const { Title, Link, Text } = Typography;

const MCQ_TYPE = "PerfReviewMcq";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((question, index) => ({ ...question, order: index + 1 }));
};

const AddMcqQuestionForm = ({
  visible,
  isRatingQuestion = false,
  onCancel,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [reviewerError, setReviewerError] = useState("");
  useSetQuestionFormValues({ type: MCQ_TYPE, form, values: initialValues });

  useEffect(() => {
    setReviewerError("");
  }, []);

  const onOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleUpdateOption = (e, optionId, options = []) => {
    const updatedOptions = options.map((option) => {
      if (option.id === optionId) {
        return { ...option, value: e.target.value };
      }
      return { ...option };
    });
    form.setFieldsValue({ options: updatedOptions });
  };

  const handleRemoveOption = (optionId, options = []) => {
    const updatedOptions = options.filter((option) => option.id !== optionId);
    const updatedOrderedOptions = updatedOptions.map((option, index) => ({
      ...option,
      order: index + 1,
    }));
    form.setFieldsValue({ options: updatedOrderedOptions });
  };

  const handleAddOption = (options = []) => {
    const maxOptionId =
      options?.length > 0
        ? Math.max(...options?.map((option) => option?.id))
        : 0;
    const updatedOptions = [...options, { id: maxOptionId + 1, value: "" }];
    form.setFieldsValue({ options: updatedOptions });
  };

  const handleDragEndOption = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let options = form.getFieldValue("options") || [];
    const orderedOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    form.setFieldsValue({ options: orderedOptions });
  };

  return (
    <Modal
      maskClosable={false}
      width="1000px"
      title="Add Multiple Choice Question"
      visible={visible}
      onOk={onOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name={isRatingQuestion ? "ratingQuestionForm" : "mcqQuestionForm"}
        initialValues={{
          type: MCQ_TYPE,
          options: [{ id: 1, value: "" }],
        }}
      >
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="order" hidden>
          <Input />
        </Form.Item>

        <Row gutter={[16]}>
          <Col span={12}>
            <Text>Question for the Reviewee</Text>
            <Form.Item
              name="reviewee_text"
              // label="Question for the Reviewee"
              rules={[
                {
                  required: true,
                  message: "Reviewee question is required",
                },
                {
                  whitespace: true,
                  message: "Reviewee question cant be empty.",
                },
              ]}
              required={false}
            >
              <Input.TextArea
                disabled={isRatingQuestion}
                className={clsx("mt-16", "input-textarea-resize-none")}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space>
              <Form.Item noStyle shouldUpdate>
                {({ setFieldsValue, getFieldValue }) => {
                  const isRequired = getFieldValue("is_required");
                  return (
                    <Switch
                      disabled={isRatingQuestion}
                      checked={isRequired}
                      onChange={(checked) =>
                        setFieldsValue({ is_required: checked })
                      }
                    />
                  );
                }}
              </Form.Item>
              <Text>Question for the Reviewer</Text>
            </Space>

            <Form.Item
              shouldUpdate
              // name="reviewer_text"
              // label="Question for the Reviewer"
              // rules={[
              //   {
              //     required: true,
              //     message: "Reviewer question is required",
              //   },
              //   {
              //     whitespace: true,
              //     message: "Reviewer question cant be empty.",
              //   },
              // ]}
              // required={false}
            >
              {({ setFieldsValue, getFieldValue }) => {
                const isRequired = getFieldValue("is_required");
                const reviewerText = getFieldValue("reviewer_text");
                return (
                  <>
                    {isRequired && (
                      <>
                        <Input.TextArea
                          disabled={isRatingQuestion}
                          value={reviewerText}
                          onChange={(e) => {
                            const reviewer_text = e.target.value;
                            setFieldsValue({ reviewer_text });
                            if (!reviewer_text) {
                              setReviewerError("Reviewer question is required");
                            } else if (!reviewer_text?.trim()) {
                              setReviewerError(
                                "Reviewer question cant be empty."
                              );
                            } else {
                              setReviewerError("");
                            }
                          }}
                          className={clsx(
                            "mt-16",
                            "input-textarea-resize-none"
                          )}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                        {reviewerError && (
                          <Text type="danger">{reviewerError}</Text>
                        )}
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <MacroTextNote />

        <Title level={4}>Options</Title>
        <Form.Item shouldUpdate className={styles.createMcqOptionsWidth}>
          {({ getFieldValue }) => {
            const options = getFieldValue("options") || [];
            return (
              <DragDropContext onDragEnd={handleDragEndOption}>
                <Droppable droppableId="options">
                  {(provided) => (
                    <div
                      className="mt-8"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {options.map((option, index) => (
                        <Draggable draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <div
                              className="mb-8"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Input
                                value={option?.value || ""}
                                onChange={(e) => {
                                  handleUpdateOption(
                                    e,
                                    option?.id,
                                    form.getFieldValue("options") || []
                                  );
                                }}
                                addonAfter={
                                  <Space>
                                    <DragOutlined
                                      {...provided.dragHandleProps}
                                    />
                                    <DeleteOutlined
                                      onClick={() => {
                                        handleRemoveOption(
                                          option?.id,
                                          form.getFieldValue("options") || []
                                        );
                                      }}
                                    />
                                  </Space>
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <Form.Item>
                        <Link
                          onClick={() => {
                            handleAddOption(form.getFieldValue("options"));
                          }}
                          block
                        >
                          Add Another Option
                        </Link>
                      </Form.Item>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddMcqQuestionForm.propTypes = {};

export default AddMcqQuestionForm;

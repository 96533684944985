import React from "react";
import PropTypes from "prop-types";
import { Select, Typography, Tooltip } from "antd";

import { EmployeeAvatarWithName } from "components";
import {
  getNameFromEmployeeObj,
  getInactiveEmployeeName,
  filterOptionsByLabel,
} from "app/appUtils";

const { Option } = Select;
const { Text } = Typography;

const EmployeeSearchSelect = ({
  disabled = false,
  allowClear = false,
  value,
  shouldShowAvatar = false,
  shouldShowEmail = true,
  employees = [],
  inActiveEmployees = [],
  shouldShowInactiveEmployees = false,
  filterOption = filterOptionsByLabel,
  onChange = () => {},
  ...props
}) => {
  let correctedValue = value;

  if (props?.mode === "multiple") {
    correctedValue = value?.map((empId) =>
      getInactiveEmployeeName(empId, employees, inActiveEmployees)
    );
  } else {
    correctedValue = getInactiveEmployeeName(
      value,
      employees,
      inActiveEmployees
    );
  }

  let renderEmployees = [];

  if (shouldShowInactiveEmployees) {
    renderEmployees = [...employees, ...inActiveEmployees];
  } else {
    renderEmployees = [...employees];
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        // className={className}
        disabled={disabled}
        value={correctedValue}
        showSearch
        optionFilterProp="label"
        optionLabelProp="labelRender"
        allowClear={allowClear}
        filterOption={filterOption}
        onChange={onChange}
        {...props}
      >
        {renderEmployees?.map((emp) => (
          <Option
            key={emp?.id}
            value={emp?.id}
            label={getNameFromEmployeeObj(emp)}
            labelRender={
              <>
                {shouldShowAvatar ? (
                  <EmployeeAvatarWithName
                    shouldShowEmail={false}
                    height={props?.mode === "multiple" ? "16px" : "24px"}
                    width={props?.mode === "multiple" ? "16px" : "24px"}
                    employee={emp}
                  />
                ) : (
                  <Text>{getNameFromEmployeeObj(emp)}</Text>
                )}
              </>
            }
          >
            {shouldShowAvatar ? (
              <EmployeeAvatarWithName
                shouldShowEmail={shouldShowEmail}
                height={props?.mode === "multiple" ? "20px" : "32px"}
                width={props?.mode === "multiple" ? "20px" : "32px"}
                employee={emp}
              />
            ) : (
              <>
                <Text>{getNameFromEmployeeObj(emp)}</Text>
                {shouldShowEmail ? (
                  <Tooltip title={emp?.email}>
                    <Text className="block-display" type="secondary">
                      {emp?.email}
                    </Text>
                  </Tooltip>
                ) : null}
              </>
            )}
          </Option>
        ))}
      </Select>
    </div>
  );
};

EmployeeSearchSelect.propTypes = {
  defaultValue: PropTypes.number,
  employees: PropTypes.array,
};

export default EmployeeSearchSelect;

import React from "react";
// import PropTypes from 'prop-types';
import { Space, Typography } from "antd";

const { Text } = Typography;

const MacroTextNote = (props) => {
  return (
    <Space className="mb-16" direction="vertical">
      <Text type="secondary">Macros you can use in the question text.</Text>
      <Text>Reviewee First Name - %FIRST_NAME%</Text>
      <Text>Reviewee Last Name - %LAST_NAME%</Text>
      <Text>Reviewee Full Name - %FULL_NAME%</Text>
    </Space>
  );
};

MacroTextNote.propTypes = {};

export default MacroTextNote;

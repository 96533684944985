import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Tooltip, Row } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  getNameFromEmployeeObj,
  formattedDate,
  // errorNotificationWithString,
} from "app/appUtils";

const getExtraHelpText = (status, type = "reviewee") => {
  if (
    status === "Not Started" ||
    status === "In Progress" ||
    status === "Unlocked"
  ) {
    return `Waiting for ${
      type === "reviewee" ? "reviewer" : "reviewee"
    } to submit`;
  }
  return "";
};

const getHelperText = (
  tableType = "",
  type = "reviewee",
  revieweeStatus = "",
  reviewerStatus = "",
  peerStatus = "",
  row = {},
  review = {}
) => {
  if (type === "indirect") {
    if (
      (revieweeStatus === "Submitted" && reviewerStatus === "Submitted") ||
      (revieweeStatus === "Signed" && reviewerStatus === "Signed")
    ) {
      return "";
    } else {
      return "Waiting for both Reviewee & Reviewer to submit ";
    }
  }

  if (tableType === "peer") {
    switch (peerStatus) {
      case "Not Started":
        return `Please get started on peer assessment for ${getNameFromEmployeeObj(
          row?.reviewee
        )} it is due by ${formattedDate(row?.deadline_for_submit)}`;
      case "In Progress":
        return `Please submit your peer assessment for ${getNameFromEmployeeObj(
          row?.reviewee
        )} it is due by ${formattedDate(row?.deadline_for_submit)}`;
      case "Submitted":
        return "You have submitted your peer assessment";
      default:
        return "";
    }
  }

  switch (type === "reviewee" ? revieweeStatus : reviewerStatus) {
    case "Not Started":
      return `Please get started. Your ${
        type === "reviewee" ? "Self Assessment" : "assessment"
      } ${
        type === "reviewer"
          ? `for ${getNameFromEmployeeObj(row?.reviewee)} `
          : ""
      }is due for submission by ${formattedDate(
        review?.review_cycle?.deadline_for_submit
      )}`;
    case "In Progress":
      return `Your ${type === "reviewee" ? "Self Assessment" : "assessment"} ${
        type === "reviewer"
          ? `for ${getNameFromEmployeeObj(row?.reviewee)} `
          : ""
      }is due for submission by ${formattedDate(
        review?.review_cycle?.deadline_for_submit
      )}`;
    case "Unlocked":
      return `Your ${type === "reviewee" ? "Self Assessment" : "assessment"} ${
        type === "reviewer"
          ? `for ${getNameFromEmployeeObj(row?.reviewee)} `
          : ""
      }is due for submission by ${formattedDate(
        review?.review_cycle?.deadline_for_submit
      )}`;
    case "Submitted":
      if (type === "reviewee" && !Boolean(row?.reviewer)) {
        return `There is no reviewer assigned to you, please contact your HR. Deadline for submit: ${formattedDate(
          review?.review_cycle?.deadline_for_sign
        )}.`;
      }
      return `Your ${type === "reviewee" ? "Self Assessment" : "assessment"} ${
        type === "reviewer"
          ? `for ${getNameFromEmployeeObj(row?.reviewee)} `
          : ""
      }is due for sign  by ${formattedDate(
        review?.review_cycle?.deadline_for_sign
      )} ${getExtraHelpText(
        type === "reviewee" ? reviewerStatus : revieweeStatus,
        type
      )}`;
    case "Signed":
      return `You have signed your ${
        type === "reviewer"
          ? `assessment for ${getNameFromEmployeeObj(row?.reviewee)} `
          : "self-assessment"
      }`;
    case "Appealed":
      return "You have appealed your review, waiting on HR to respond";
    case "Appeal Rejected":
      return "Your appeal was rejected by HR";
    case "Appeal Accepted":
      return "Your appeal was accepted by HR";

    default:
      return "No assessment for you in this cycle. Please contact your HR";
  }
};

const ActionBtn = ({
  tableType = "self",
  rowId = null,
  row = {},
  review = {},
  type = "reviewer",
  peerStatus = "",
  revieweeStatus = "",
  reviewerStatus = "",
}) => {
  const history = useHistory();

  const getRevieweeAssessmentButtonText = (
    revieweeStatus = "",
    reviewerStatus = ""
  ) => {
    if (type === "indirect") {
      return "View";
    }

    if (tableType === "peer" && peerStatus === "Submitted") {
      return "View";
    }

    const myStatus =
      tableType === "peer"
        ? peerStatus
        : type === "reviewer"
        ? reviewerStatus
        : revieweeStatus;
    const otherStatus = type === "reviewer" ? revieweeStatus : reviewerStatus;

    switch (myStatus) {
      case "Not Started":
        return "Write";
      case "In Progress":
        return "Submit";
      case "Unlocked":
        return "Update";
      case "Submitted":
        if (
          otherStatus === "Not Started" ||
          otherStatus === "In Progress" ||
          otherStatus === "Unlocked"
        ) {
          return "View Review";
        }
        if (otherStatus === "Signed" || otherStatus === "Submitted") {
          return "Sign Review";
        }
        return "Sign";
      case "Signed":
        return ["View"];
      case "Appealed":
        return "Appealed";
      case "Appeal Rejected":
        return "Sign";
      case "Appeal Accepted":
        return "Sign";
      default:
        return [""];
    }
  };

  const handleNavigationToSubmit = () => {
    if (tableType === "peer") {
      history.push(
        `/reviews/peer/${row?.id}/review/${
          review?.my_review?.length > 0 ? review?.my_review[0]?.id : ""
        }`
      );
    } else if (tableType === "direct" || tableType === "indirect") {
      history.push(`/reviews/review/${row?.id}/`);
    } else {
      const myReview = review?.my_review[0];
      history.push(`/reviews/review/${myReview?.id}`);
    }
  };

  const btnText = getRevieweeAssessmentButtonText(
    revieweeStatus,
    reviewerStatus
  );

  const helperText = getHelperText(
    tableType,
    type,
    revieweeStatus,
    reviewerStatus,
    peerStatus,
    row,
    review
  );

  return (
    <Row justify="start" align="middle">
      <Button
        type="secondary"
        size="small"
        className="mr-8"
        onClick={handleNavigationToSubmit}
      >
        {btnText}
      </Button>
      {helperText && (
        <Tooltip title={helperText}>
          <ExclamationCircleOutlined />
        </Tooltip>
      )}
    </Row>
  );
};
ActionBtn.propTypes = {
  type: PropTypes.string,
  revieweeStatus: PropTypes.string,
  reviewerStatus: PropTypes.string,
};

export default ActionBtn;

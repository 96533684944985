import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { Typography, Table, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoxLoader, InlineSpinner } from "components";
import ViewFeedbackModal from "./ViewFeedbackModal";
import GiveFeedbackDrawer from "../give-feedback/GiveFeedbackDrawer";
import RequestFeedbackModal from "../RequestFeedbackModal";
import {
  getFeedbackRequestApi,
  nudgeRequestorsApi,
  declineRequestApi,
} from "apis/continuousFeedbackApi";
import {
  getPendingFeedbackRequestsColumns,
  getRaisedRequestsColumns,
  getPendingFeedbackRequestsDataSource,
  getRaisedRequestsDataSource,
} from "../utils";
import { successNotification, errorNotification } from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";

const { Title } = Typography;
const { confirm } = Modal;

const FeedbackRequests = ({
  isGiveFeedbackOpen = false,
  handleCloseFeedbackDrawer = () => {},
  setFeedbackRequestsCount = () => {},
}) => {
  const [viewFeedbackRequestId, setViewFeedbackRequestId] = useState(null);
  const [editFeedback, setEditFeedback] = useState(null);
  const [writeRequest, setWriteRequest] = useState(null);

  const { data, isLoading, isFetching, refetch } = useQuery(
    "getFeedbackRequest",
    getFeedbackRequestApi,
    {
      onSuccess: (data) => {
        setFeedbackRequestsCount(data?.pending_feedback_requests?.length);
      },
    }
  );

  const [nudgeRequestors] = useMutation(nudgeRequestorsApi, {
    onSuccess: () => {
      successNotification("Successfully nudged requestors");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [declineRequest] = useMutation(declineRequestApi, {
    onSuccess: () => {
      successNotification("Successfully declined request");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleDeclineRequest = (requestUserId) => {
    confirm({
      title: "Are you sure, you want to decline this request?",
      icon: <ExclamationCircleOutlined />,
      content:
        "This will permanently decline this request and you will no longer see this on screen!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        declineRequest({ requestUserId });
      },
      onCancel() {},
    });
  };

  const handleNudgeEmployees = (requestId) => {
    nudgeRequestors({ requestId });
  };

  const handleIsViewFeedbackVisible = (id) => {
    setViewFeedbackRequestId(id);
  };

  const handleWriteRequest = (request) => {
    setWriteRequest(request);
  };

  const handleEditFeedback = (feedback) => {
    setEditFeedback(feedback);
  };

  const loader = isLoading;

  return (
    <>
      {Boolean(editFeedback) && (
        <RequestFeedbackModal
          visible={Boolean(editFeedback)}
          feedback={editFeedback}
          onCancel={() => {
            handleEditFeedback(null);
          }}
          refetch={refetch}
        />
      )}
      {isGiveFeedbackOpen && (
        <GiveFeedbackDrawer
          visible={isGiveFeedbackOpen}
          feedback={null}
          onClose={handleCloseFeedbackDrawer}
        />
      )}

      {Boolean(viewFeedbackRequestId) && (
        <ViewFeedbackModal
          requestId={viewFeedbackRequestId}
          visible={Boolean(viewFeedbackRequestId)}
          onCancel={() => {
            handleIsViewFeedbackVisible(null);
          }}
        />
      )}
      {Boolean(writeRequest) && (
        <GiveFeedbackDrawer
          feedback={writeRequest}
          visible={Boolean(writeRequest)}
          refetch={refetch}
          onClose={() => {
            handleWriteRequest(null);
          }}
        />
      )}
      <div>
        {loader ? (
          <BoxLoader />
        ) : (
          <>
            <Title className="mt-40" level={3}>
              Pending Feedback Requests{" "}
              {isFetching && <InlineSpinner fontSize={20} />}
            </Title>
            <Table
              columns={getPendingFeedbackRequestsColumns({
                handleWriteRequest,
                handleDeclineRequest,
              })}
              dataSource={getPendingFeedbackRequestsDataSource(
                data?.pending_feedback_requests
              )}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
                pageSize: 4,
              }}
              scroll={mobileTableScroll}
            />
            <Title className="mt-40" level={3}>
              Requested Feedbacks
            </Title>
            <Table
              className="mb-32"
              columns={getRaisedRequestsColumns({
                handleNudgeEmployees,
                handleIsViewFeedbackVisible,
                handleEditFeedback,
              })}
              dataSource={getRaisedRequestsDataSource(data?.raised_requests)}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
                pageSize: 4,
              }}
              scroll={mobileTableScroll}
            />
          </>
        )}
      </div>
    </>
  );
};

FeedbackRequests.propTypes = {
  setFeedbackRequestsCount: PropTypes.func,
};

export default FeedbackRequests;

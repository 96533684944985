import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import moment from "moment";
import {
  Drawer,
  Divider,
  Form,
  Typography,
  Button,
  Row,
  Col,
  Grid,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import {
  APIBasedEmployeeSearch,
  ButtonWithSpinner,
  EmojiTextInput,
  EmployeeOKRActivity,
  ShowMoreText,
} from "components";
import { postFeedbackApi } from "apis/continuousFeedbackApi";
import {
  successNotification,
  errorNotification,
  getNameFromEmployeeObj,
  formattedDate,
  getIsMobile,
} from "app/appUtils";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const SelectEmployee = ({
  value,
  currentOwners = [],
  onChange = () => {},
  setSelectedEmployee = () => {},
}) => {
  const handleChange = (val, selectedEmployee) => {
    onChange(val);
    setSelectedEmployee(selectedEmployee);
  };

  return (
    <APIBasedEmployeeSearch
      value={value}
      placeholder="Type Employee"
      showArrow={false}
      allowClear={true}
      shouldShowAvatar={true}
      currentOwners={currentOwners}
      shouldSendSelectedEmployee={true}
      onChange={handleChange}
    />
  );
};

const GiveFeedbackDrawer = ({
  feedback = {},
  visible = false,
  refetch = () => {},
  onClose = () => {},
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [isOKRSuggestionsOpen, setIsOKRSuggestionsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(
    feedback?.employee || null
  );
  const [feedbackError, setFeedbackError] = useState("");

  const [postFeedback, { isLoading }] = useMutation(postFeedbackApi, {
    onSuccess: () => {
      successNotification("Successfully given feedback");
      refetch();
      onClose();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleIsOKRSuggestionsOpen = () => {
    setIsOKRSuggestionsOpen(!isOKRSuggestionsOpen);
  };

  const handleSubmit = () => {
    const feedback = form.getFieldValue("feedback");

    if (feedback.trim() === "") {
      setFeedbackError("Feedback cant be empty");
    } else {
      form
        .validateFields()
        .then((values) => {
          console.log({ values });
          postFeedback({ continuous_feedback: { ...values, feedback } });
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const isMobile = getIsMobile(screens);

  return (
    <Drawer
      width={isMobile ? "85%" : "50%"}
      push={{ distance: "500%" }}
      title={
        feedback?.isRequestFeedback
          ? `Respond to ${getNameFromEmployeeObj(selectedEmployee)}'s request`
          : Boolean(feedback)
          ? "Edit Feedback"
          : "Give Feedback"
      }
      visible={visible}
      onClose={onClose}
      footer={
        <div className="pl-14">
          <ButtonWithSpinner
            type="primary"
            isSpinning={isLoading}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {Boolean(feedback?.id) ? "Update Feedback" : "Submit Feedabck"}
          </ButtonWithSpinner>
          <Button
            className="ml-8"
            type="primary"
            ghost={true}
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          feedback: "",
          ...feedback,
          employee_id: feedback?.employee?.id || undefined,
          request_user_id: feedback?.isRequestFeedback
            ? feedback?.request_user_id
            : undefined,
        }}
      >
        <Form.Item name="id" hidden={true} />
        <Form.Item name="request_user_id" hidden={true} />
        {feedback?.isRequestFeedback ? (
          <div className="mb-16">
            <Text
              className="block-display"
              type="secondary"
            >{`${getNameFromEmployeeObj(selectedEmployee)}'s Request: `}</Text>
            <ShowMoreText
              charactersToShowOnLess={110}
              text={feedback?.question || ""}
            />
            <Text
              className="block-display"
              type="secondary"
            >{`Requested on: ${formattedDate(feedback?.requested_on)}`}</Text>

            <Form.Item name="employee_id" hidden={true} />
          </div>
        ) : (
          <Row>
            <Col>
              <Form.Item
                className="width-250-px"
                name="employee_id"
                label="Whom to give?"
                rules={[
                  {
                    required: true,
                    message: "Please select employee",
                  },
                ]}
                required={false}
              >
                <SelectEmployee
                  currentOwners={feedback?.employee || []}
                  setSelectedEmployee={setSelectedEmployee}
                />
              </Form.Item>
            </Col>

            {Boolean(feedback) && (
              <Col offset={1}>
                <Text type="secondary" className="block-display">
                  Feedback Given on
                </Text>
                <Text className="mt-8 block-display">
                  {formattedDate(feedback?.created_at)}
                </Text>
              </Col>
            )}
          </Row>
        )}

        <Form.Item id="feedback" hidden />
        <Form.Item className="width-100-percent" shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => {
            const feedbackValue = getFieldValue("feedback");

            const handleChange = (newValue) => {
              setFieldsValue({
                feedback: newValue,
              });
            };

            return (
              <EmojiTextInput
                value={feedbackValue}
                validationError={feedbackError}
                autoSize={{ minRows: 6, maxRows: 6 }}
                pickerContainerStyles={{
                  position: "fixed",
                  zIndex: 1050,
                  marginTop: "-25px",
                  marginLeft: "-250px",
                }}
                onChange={handleChange}
              />
            );
          }}
        </Form.Item>
        {selectedEmployee && (
          <>
            <QuestionCircleOutlined className="mr-8 text-secondary" />
            <Text type="secondary">
              {`Your feedback will be visible for ${getNameFromEmployeeObj(
                selectedEmployee
              )} and their manager chain.`}
            </Text>
          </>
        )}
        <div>
          <QuestionCircleOutlined className="mr-8 text-secondary" />
          <Text type="secondary">
            {`You will be able to edit your feedback till ${moment()
              ?.add(14, "days")
              ?.format("MMM D")}.`}
          </Text>
        </div>

        <Divider />

        <Title level={3}>
          {feedback?.isRequestFeedback ? "Related Links" : "Suggestions"}
        </Title>
        {selectedEmployee ? (
          <>
            <Button
              className="pl-0"
              type="link"
              onClick={handleIsOKRSuggestionsOpen}
            >
              {isOKRSuggestionsOpen ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowRightOutlined />
              )}
              {`${
                feedback?.isRequestFeedback ? "See" : "Give Feedback on"
              } ${getNameFromEmployeeObj(selectedEmployee)}'s OKRs`}
            </Button>
            {isOKRSuggestionsOpen && (
              <div className="ml-30">
                <EmployeeOKRActivity
                  employee={selectedEmployee}
                  shouldShowCurrentPeriod={true}
                />
              </div>
            )}
          </>
        ) : (
          <Text type="secondary">Select an employee to get suggestions</Text>
        )}
      </Form>
    </Drawer>
  );
};

GiveFeedbackDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GiveFeedbackDrawer;

const getAllTeamsMockApi = () => {
  return [
    {
      id: 1,
      name: "HR",
      owner: {
        id: 222,
        first_name: "Pranav",
        last_name: "PB",
        email: "pranav@beautifulcode.in",
        name: "Pranav PB",
        active: true,
        user_first_name: "Pranav PB",
        user_last_name: "",
        user_profile_pic_url:
          "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        manager: null,
      },
    },
    {
      id: 2,
      name: "HR 2",
      owner: {
        id: 222,
        first_name: "Pranav",
        last_name: "PB",
        email: "pranav@beautifulcode.in",
        name: "Pranav PB",
        active: true,
        user_first_name: "Pranav PB",
        user_last_name: "",
        user_profile_pic_url:
          "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        manager: null,
      },
    },
  ];
};

const saveAllTeamMockApi = () => {
  return {
    id: 1,
    name: "HR",
    owner: {
      id: "1245",
      first_name: "Abhilash",
      last_name: "K",
      email: "ak@435.com",
      manager_id: null,
    },
  };
};

const deleteTeamMockApi = () => {
  return { message: "Successfully Deleted!" };
};

export { getAllTeamsMockApi, saveAllTeamMockApi, deleteTeamMockApi };

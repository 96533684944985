import React from "react";

import { ReactComponent as Bulb } from "assets/praise/bulb.svg";
import { ReactComponent as Flag } from "assets/praise/flag.svg";
import { ReactComponent as Flower } from "assets/praise/flower.svg";
import { ReactComponent as Psychology } from "assets/praise/psycology.svg";
import { ReactComponent as Star } from "assets/praise/star.svg";
import { ReactComponent as ThumbsUp } from "assets/praise/thumbs_up.svg";
import {
  ApiOutlined,
  HeartOutlined,
  ReconciliationOutlined,
  HighlightOutlined,
} from "@ant-design/icons";

import "../PraiseStyles.css";

const iconOptionsMap = {
  business_center: <Psychology className={"praiseIcon"} />,
  event_seat: <Flower className={"praiseIcon"} />,
  build: <ApiOutlined className={"praiseIcon"} />,
  thumb_up: <ThumbsUp className={"praiseIcon"} />,
  flight_take_off: <Flag className={"praiseIcon"} />,
  group_work: <ReconciliationOutlined className={"praiseIcon"} />,
  star: <Star className={"praiseIcon"} />,
  cake: <HeartOutlined className={"praiseIcon"} />,
  brush: <HighlightOutlined className={"praiseIcon"} />,
  flash_on: <Bulb className={"praiseIcon"} />,
};

export { iconOptionsMap };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useQuery, useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Typography, Card, Space, Table, List } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { pluralize } from "humanize-plus";

import { useCurrentCompany } from "store";
import NotificationModal from "./common/NotificationModal";
import {
  errorNotification,
  successNotification,
  formattedDate,
  getIsCompanyFeatureDisabled,
} from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";
import { getSurveysApi, notifyParticipantsApi } from "apis/surveyApi";
import {
  BoxLoader,
  BarChart,
  DraftRibbon,
  // VimeoVideo,
  VimeoVideoModal,
  DisabledFeatureAlert,
} from "components";

import styles from "./Survey.module.css";

const { Title, Text } = Typography;

const surveyQuestionTypes = {
  TEXT: "SurveyText",
  MCQ: "SurveyMcq",
};

const SurveysCard = ({ survey = {}, refetch = () => {}, disabled = false }) => {
  const history = useHistory();

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const [notifyParticipants, { status: notificationStatus }] = useMutation(
    notifyParticipantsApi,
    {
      onSuccess: () => {
        handleEmailDialogDone();
        successNotification("Notified participants");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleDetails = () => {
    setIsDetailsExpanded(!isDetailsExpanded);
  };

  const handleEmailDialog = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const handleEmailDialogDone = () => {
    handleEmailDialog();
    refetch();
  };

  const handleNotifyParticipants = () => {
    notifyParticipants(survey?.id);
  };

  const handleEditSurvey = () => {
    history.push(`/company/survey/edit/${survey?.id}`);
  };

  const handleCloneSurvey = () => {
    history.push(`/company/survey/clone_from/${survey?.id}`);
  };

  const handleCreateFromDraft = () => {
    history.push(`/company/survey/create_from_draft/${survey?.id}`);
  };

  const getSeriesData = (mcqDistribution = {}, options = {}) => {
    const data = Object.keys(options).map(() => 0);
    Object.entries(mcqDistribution).forEach(([key, value]) => {
      data[key - 1] = value;
    });
    return data;
  };

  const getBarChartSpan = (type = "", options = {}) => {
    if (type === surveyQuestionTypes.MCQ) {
      const optionsLength = Object.keys(options).length;
      if (optionsLength < 5) {
        return optionsLength * 3;
      }
      return 12;
    }
    return 12;
  };

  const getDataSource = () => {
    const responseStatistic = survey?.response_statistics || {};

    return survey?.questions?.map((question) => {
      const questionComments = responseStatistic[question?.id]?.comments || [];

      const orderedQuestionComments = questionComments?.sort(
        (a, b) => Number(b?.answer || "0") - Number(a?.answer || "0")
      );

      return {
        key: question?.order,
        question: question?.description,
        type: question?.type,
        answers_comments_length:
          question?.type === surveyQuestionTypes.TEXT
            ? `${responseStatistic[question?.id]?.answers_count || 0} ${
                pluralize(
                  responseStatistic[question?.id]?.answers_count,
                  "answer"
                ) || "answers"
              }`
            : `${responseStatistic[question?.id]?.comments_count || 0} ${
                pluralize(
                  responseStatistic[question?.id]?.comments_count,
                  "comment"
                ) || "comments"
              }`,
        avg_score:
          question?.type === surveyQuestionTypes.TEXT
            ? "-"
            : `${
                Math.round(
                  responseStatistic[question?.id]?.average_score * 100
                ) / 100
              }`,
        options: question?.options || {},
        mcqDistribution: getSeriesData(
          responseStatistic[question?.id]?.mcq_number_distribution,
          question?.options || {}
        ),
        comments: orderedQuestionComments || [],
        answers: responseStatistic[question?.id]?.answers || [],
      };
    });
  };

  return (
    <div className="my-8">
      <NotificationModal
        isLoading={notificationStatus === "loading"}
        survey={survey}
        visible={isEmailDialogOpen}
        handleCancel={handleEmailDialog}
        handleSubmit={handleNotifyParticipants}
      />
      <DraftRibbon hidden={!survey?.draft}>
        <Card className={"mt-8"}>
          <Row justify="space-between" align="center">
            <Title level={3}>{`${survey?.name}`}</Title>
            {!survey?.draft && (
              <Space>
                <Button
                  className={disabled ? "primary-disabled-btn-text" : ""}
                  disabled={disabled}
                  type="primary"
                  ghost={true}
                  size="small"
                  onClick={handleCloneSurvey}
                >
                  Clone
                </Button>
                <Button
                  className={disabled ? "primary-disabled-btn-text" : ""}
                  disabled={disabled}
                  type="primary"
                  ghost={true}
                  size="small"
                  onClick={handleEditSurvey}
                >
                  Edit
                </Button>
              </Space>
            )}
            {survey?.draft && (
              <Button
                className={disabled ? "primary-disabled-btn-text" : ""}
                disabled={disabled}
                type="primary"
                ghost={true}
                size="small"
                onClick={handleCreateFromDraft}
              >
                Continue working on this draft
              </Button>
            )}
          </Row>
          <Row className="mt-16">
            <Col span={16}>
              <Space direction="vertical" className={styles.width100Percent}>
                <Row>
                  <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Space direction="vertical">
                      <Text type="secondary">Due Date:</Text>
                      <Text type="secondary">Respondents:</Text>
                      <Text type="secondary">Non Respondents:</Text>
                    </Space>
                  </Col>
                  <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                    <Space direction="vertical">
                      <Text>{formattedDate(survey?.due_date)}</Text>
                      <Text>{survey?.respondent}</Text>
                      <Text>{survey?.non_respondent}</Text>
                    </Space>
                  </Col>
                </Row>

                {!survey?.draft && (
                  <Row className="mt-8" gutter={[8, 8]}>
                    <Col>
                      <Button
                        type="primary"
                        ghost={true}
                        size="small"
                        onClick={handleDetails}
                      >
                        {isDetailsExpanded
                          ? "Hide Survey Responses"
                          : "Show Survey Responses"}
                      </Button>
                    </Col>
                    {!survey?.are_emails_sent && (
                      <Col>
                        <Button
                          className={
                            disabled ? "primary-disabled-btn-text" : ""
                          }
                          disabled={disabled}
                          type="primary"
                          ghost={true}
                          size="small"
                          onClick={handleEmailDialog}
                        >
                          Notify Employees
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
              </Space>
            </Col>
            <Col span={8}>
              <Row justify="end">
                {/* {survey?.draft && (
                  <Button
                    type="primary"
                    ghost={true}
                    onClick={handleCreateFromDraft}
                  >
                    Continue working on this draft
                  </Button>
                )} */}
              </Row>
            </Col>
          </Row>
          {isDetailsExpanded && (
            <Table
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
              className="mt-8"
              columns={[
                {
                  title: "Question",
                  dataIndex: "question",
                  key: "question",
                },
                {
                  title: "Avg Score",
                  dataIndex: "avg_score",
                  key: "avg_score",
                },
                {
                  title: "Answers/Comments",
                  dataIndex: "answers_comments_length",
                  key: "answers_comments_length",
                },
              ]}
              dataSource={getDataSource()}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (record) => (
                  <Row gutter={[8]} justify="space-between">
                    <Col span={getBarChartSpan(record?.type, record?.options)}>
                      <Row justify="center">
                        {record?.type === surveyQuestionTypes.MCQ ? (
                          <div className={styles.width90Percent}>
                            <BarChart
                              title=""
                              seriesName=""
                              labelOptions={record?.options}
                              seriesData={record?.mcqDistribution}
                            />
                          </div>
                        ) : (
                          <List
                            size="small"
                            className={styles.width100Percent}
                            header={<div>Answers</div>}
                            // bordered
                            dataSource={record?.answers || []}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        )}
                      </Row>
                    </Col>
                    <Col span={12}>
                      {record?.type === surveyQuestionTypes.MCQ && (
                        <List
                          size="small"
                          header={<div>Comments</div>}
                          // bordered
                          dataSource={record?.comments || []}
                          renderItem={(item) => (
                            <>
                              {item?.comment && (
                                <List.Item>
                                  <div>
                                    <Text>
                                      {`${item?.comment || ""}
                                  `}
                                    </Text>

                                    <span>
                                      <Text className="text-primary">{` (${
                                        record?.options[item?.answer]
                                      }) `}</Text>
                                    </span>
                                  </div>
                                </List.Item>
                              )}
                            </>
                          )}
                        />
                      )}
                    </Col>
                  </Row>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <DownOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={(e) => onExpand(record, e)} />
                  ),
              }}
              scroll={mobileTableScroll}
            />
          )}
        </Card>
      </DraftRibbon>
    </div>
  );
};

SurveysCard.propTypes = {
  survey: PropTypes.object,
  refetch: PropTypes.func,
  disabled: PropTypes.bool,
};

const Surveys = () => {
  const [currentCompany] = useCurrentCompany();
  const [isVimeoModalVisible, setIsVimeoModalVisible] = useState(false);
  const [isVimeoVideoVisible, setIsVimeoVideoVisible] = useState(true);

  useEffect(() => {
    if (!isVimeoVideoVisible) {
      setIsVimeoVideoVisible(true);
    }
  }, [isVimeoVideoVisible]);

  const {
    data: surveys = [],
    status,
    isFetching,
    isLoading,
    refetch,
  } = useQuery("getSurveys", getSurveysApi, {
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [isCompletedSurveyOpen, setIsCompletedSurveyOpen] = useState(false);

  const handleIsCompletedSurveyOpen = () => {
    setIsCompletedSurveyOpen(!isCompletedSurveyOpen);
  };

  const handleIsVimeoModalVisible = () => {
    setIsVimeoModalVisible(!isVimeoModalVisible);
  };

  const activeSurveys = surveys.filter((survey) => survey.is_active);
  const completedSurveys = surveys.filter((survey) => !survey.is_active);

  const isLoaderPresent = status === "loading" || isLoading || isFetching;
  const isFeatureDisabled = getIsCompanyFeatureDisabled(
    "Surveys",
    currentCompany?.features
  );

  return (
    <>
      {isFeatureDisabled && <DisabledFeatureAlert featureName="Surveys" />}

      <Row>
        <Col span={10}>
          <Title level={2}>Surveys</Title>
        </Col>
        <Col span={14} className="company-create-btn">
          <Row align="middle" justify="end" gutter={[16, 16]}>
            {!isLoading && activeSurveys?.length > 0 && (
              <Col>
                {/* <TinyTutorialBtn
                  text="Watch the tiny tutorial on Surveys"
                  onClick={handleIsVimeoModalVisible}
                /> */}
              </Col>
            )}
            <Col>
              <Link to="/company/surveys/add">
                <Button disabled={isFeatureDisabled} type="primary">
                  Create Survey
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {isVimeoModalVisible && (
        <VimeoVideoModal
          title="Survey Tutorial"
          src="https://player.vimeo.com/video/486782683?color=e02c4b&title=0&byline=0&portrait=0"
          visible={isVimeoModalVisible}
          onCancel={handleIsVimeoModalVisible}
          onEnd={handleIsVimeoModalVisible}
        />
      )}

      {isLoaderPresent && <BoxLoader />}

      {!isLoaderPresent &&
        activeSurveys?.length === 0 &&
        completedSurveys?.length === 0 && (
          <Row className="mt-16">
            <Col span={24}>
              <Card>
                <Row justify="center" align="middle">
                  <Text>
                    {`There are no surveys at  ${
                      currentCompany?.name || "your company"
                    }.`}
                  </Text>

                  {/* {isVimeoVideoVisible && (
                    <VimeoVideo
                      src="https://player.vimeo.com/video/486782683?color=e02c4b&title=0&byline=0&portrait=0"
                      onEnd={() => {
                        setIsVimeoVideoVisible(false);
                      }}
                    />
                  )} */}

                  <Link to="/company/surveys/add">
                    <Button
                      className="white-space-normal auto-height pl-0"
                      type="link"
                    >
                      Create your first Survey
                    </Button>
                  </Link>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

      {!isLoaderPresent &&
        activeSurveys?.map((survey) => (
          <SurveysCard
            survey={survey}
            refetch={refetch}
            disabled={isFeatureDisabled}
          />
        ))}

      {!isLoaderPresent && completedSurveys?.length > 0 && (
        <Row
          justify="center"
          className={clsx("mt-8", isCompletedSurveyOpen ? "mb-8" : "mb-32")}
        >
          <Col size={6}>
            <Button size="medium" onClick={handleIsCompletedSurveyOpen}>
              {isCompletedSurveyOpen
                ? "Hide Completed Surveys"
                : "Show Completed Surveys"}
            </Button>
          </Col>
        </Row>
      )}

      {completedSurveys?.length > 0 && isCompletedSurveyOpen && (
        <>
          {completedSurveys?.map((survey) => (
            <SurveysCard
              survey={survey}
              refetch={refetch}
              disabled={isFeatureDisabled}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Surveys;

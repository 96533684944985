import React from "react";
import PropTypes from "prop-types";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import {
  getNameFromEmployeeObj,
  replaceMacrosWithName,
  formattedDate,
} from "app/appUtils";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  content: {
    fontFamily: "Roboto",
    fontSize: "14px",
  },
  logo: {
    width: "80px",
    height: "20px",
  },
  emptyDiv: {
    width: "80px",
    height: "20px",
  },
  header: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
  },
  smallImage: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  divider: {
    marginTop: "16px",
    marginBottom: "16px",
    height: "4px",
    width: "100%",
    backgroundColor: "#e3e3e3",
  },
  topTitle: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  secondaryText: {
    fontSize: "12px",
    color: "#a3a3a3",
  },
  optionListItem: {
    display: "flex",
    border: "1px solid #e3e3e3",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "4px 8px",
  },
  optionListItemText: {
    width: "30%",
    display: "flex",
  },
  employeeAvatar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleEmployeeAvatar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  questionTitle: {
    fontSize: "16px",
  },
  ml20: {
    marginLeft: "20px",
  },
  ml4: {
    marginLeft: "4px",
  },
  mt8: {
    marginTop: "8px",
  },
  mb30: {
    marginBottom: "30px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mb16: {
    marginBottom: "16px",
  },
  periodText: {
    marginTop: "2px",
    fontSize: "12px",
  },
});

const PerfReviewAssessmentDocument = ({ reviewData = {}, type = "" }) => {
  const revieweeAnswers = reviewData?.reviewee?.answers || {};
  const reviewerAnswers = reviewData?.reviewer?.answers || {};

  const peerAnswers = reviewData?.reviewee?.peers?.filter(
    (peer) =>
      peer?.status === "Submitted" &&
      Boolean(peer?.answers) &&
      typeof peer?.answers === "object" &&
      Object.keys(peer?.answers)?.length > 0
  );

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <div style={styles.content}>
          <div style={styles.header}>
            <Image
              style={styles.logo}
              src={"/assets/culture-logo.png"}
              source="culture.easy"
            />
            <Text>{`${getNameFromEmployeeObj(
              reviewData?.reviewee
            )}'s Review`}</Text>
            <div style={styles.emptyDiv}></div>
          </div>
          <div style={styles.divider}></div>
          <div style={styles.topTitle}>
            <div>
              <div style={styles.titleEmployeeAvatar}>
                {reviewData?.reviewee?.user_profile_pic_url && (
                  <Image
                    style={styles.image}
                    src={reviewData?.reviewee?.user_profile_pic_url}
                    source={""}
                  />
                )}
                <div style={styles.ml4}>
                  <Text style={styles.secondaryText}>Report</Text>
                  <Text>{getNameFromEmployeeObj(reviewData?.reviewee)}</Text>
                </div>
              </div>
            </div>
            <div style={styles.ml20}>
              <div style={styles.titleEmployeeAvatar}>
                {reviewData?.reviewer?.user_profile_pic_url && (
                  <Image
                    style={styles.image}
                    src={reviewData?.reviewer?.user_profile_pic_url}
                    source={""}
                  />
                )}
                <div style={styles.ml4}>
                  <Text style={styles.secondaryText}>Manager</Text>
                  <Text>{getNameFromEmployeeObj(reviewData?.reviewer)}</Text>
                </div>
              </div>
            </div>
            <div style={styles.ml20}>
              <Text style={styles.secondaryText}>Period Under Review</Text>
              <Text style={styles.periodText}>{`${formattedDate(
                reviewData?.perf_review_cycle?.period_from
              )} to ${formattedDate(
                reviewData?.perf_review_cycle?.period_to
              )}`}</Text>
            </div>
          </div>
          <div style={styles.divider}></div>
          {reviewData?.questions?.map((question, index) => {
            const questionDescriptionArray = `${index + 1}. ${
              question[`${type}_text`] || question["reviewee_text"]
            }`?.split("\n");
            return (
              <>
                <Text style={styles.questionTitle}>
                  {questionDescriptionArray?.map((descSentence) => (
                    <Text>
                      {replaceMacrosWithName(
                        descSentence,
                        reviewData?.reviewee?.first_name || "-",
                        reviewData?.reviewee?.last_name || "-"
                      )}
                    </Text>
                  ))}
                </Text>

                {peerAnswers?.map((peer) => {
                  const peerAnswers = peer?.answers || {};
                  const isNamePresent = peer?.name;
                  return (
                    <>
                      {question.type === "PerfReviewText" && (
                        <div className="flex-display">
                          <div>
                            <Text style={styles.secondaryText}>
                              {isNamePresent
                                ? getNameFromEmployeeObj(peer)
                                : "Anonymous"}
                              {" (Peer)"}
                            </Text>
                            <Text style={styles.mb10}>
                              {peerAnswers[question.id] || "-"}{" "}
                            </Text>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}

                <div style={styles.mb30}>
                  {question.type === "PerfReviewText" && (
                    <div style={styles.mt8}>
                      <Text style={styles.secondaryText}>
                        {type === "reviewee"
                          ? "Self : "
                          : getNameFromEmployeeObj(reviewData?.reviewee)}
                      </Text>
                      <Text style={styles.mb10}>
                        {revieweeAnswers[question.id]}
                      </Text>
                      <Text
                        style={styles.secondaryText}
                      >{`Manager (${getNameFromEmployeeObj(
                        reviewData?.reviewer
                      )}) : `}</Text>
                      <Text className="block-display">
                        {question?.is_required
                          ? reviewerAnswers[question.id]
                          : "No question for reviewer"}
                      </Text>
                    </div>
                  )}

                  {question.type === "PerfReviewMcq" && (
                    <div style={styles.mt8}>
                      {question?.options?.map((option) => (
                        <div style={styles.optionListItem}>
                          <span style={styles.optionListItemText}>
                            <Text>{option?.value}</Text>
                          </span>
                          <span>
                            {revieweeAnswers[question.id] === option.id && (
                              <div style={styles.employeeAvatar}>
                                {reviewData?.reviewee?.user_profile_pic_url && (
                                  <Image
                                    style={styles.smallImage}
                                    src={
                                      reviewData?.reviewee?.user_profile_pic_url
                                    }
                                    source={""}
                                  />
                                )}
                                <Text style={styles.ml4}>
                                  {getNameFromEmployeeObj(reviewData?.reviewee)}
                                </Text>
                              </div>
                            )}
                          </span>
                          <span>
                            {reviewerAnswers[question.id] === option.id && (
                              <div style={styles.employeeAvatar}>
                                {reviewData?.reviewer?.user_profile_pic_url && (
                                  <Image
                                    style={styles.smallImage}
                                    src={
                                      reviewData?.reviewer
                                        ?.user_profile_pic_url || ""
                                    }
                                    source={""}
                                  />
                                )}
                                <Text style={styles.ml4}>
                                  {getNameFromEmployeeObj(reviewData?.reviewer)}
                                </Text>
                              </div>
                            )}
                          </span>
                          <span>
                            {peerAnswers
                              ?.filter(
                                (peer) =>
                                  peer?.answers[question?.id] === option?.id
                              )
                              ?.map((peer) =>
                                peer?.user_profile_pic_url ? (
                                  <Image
                                    style={styles.smallImage}
                                    src={
                                      peer?.name
                                        ? peer?.user_profile_pic_url || ""
                                        : ""
                                    }
                                    alt="A"
                                    source={""}
                                  />
                                ) : (
                                  <Text
                                    style={{
                                      backgroundColor: "#bebebe",
                                      color: "white",
                                      borderRadius: "50%",
                                      width: "16px",
                                      textAlign: "center",
                                    }}
                                  >
                                    A
                                  </Text>
                                )
                              )}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            );
          })}
          <div>
            {reviewData?.perf_review_cycle?.rating_enabled && (
              <>
                <Text>
                  {replaceMacrosWithName(
                    type === "reviewer"
                      ? "How do you rate %FIRST_NAME%?"
                      : "How do you rate yourself?",
                    reviewData?.reviewee?.first_name || "-",
                    reviewData?.reviewee?.last_name || "-"
                  )}
                </Text>

                <div style={styles.mt8}>
                  {reviewData?.perf_review_cycle?.rating_options?.map(
                    (option) => (
                      <div style={styles.optionListItem}>
                        <span style={styles.optionListItemText}>
                          <Text>{option?.value}</Text>
                        </span>
                        <span>
                          {reviewData?.reviewee?.rating_option_id ===
                            option.id && (
                            <div style={styles.employeeAvatar}>
                              {reviewData?.reviewee?.user_profile_pic_url && (
                                <Image
                                  style={styles.smallImage}
                                  src={
                                    reviewData?.reviewee?.user_profile_pic_url
                                  }
                                  source={""}
                                />
                              )}
                              <Text style={styles.ml4}>
                                {getNameFromEmployeeObj(reviewData?.reviewee)}
                              </Text>
                            </div>
                          )}
                        </span>
                        <span>
                          {reviewData?.reviewer?.rating_option_id ===
                            option.id && (
                            <div style={styles.employeeAvatar}>
                              {reviewData?.reviewer?.user_profile_pic_url && (
                                <Image
                                  style={styles.smallImage}
                                  src={
                                    reviewData?.reviewer
                                      ?.user_profile_pic_url || ""
                                  }
                                  source={""}
                                />
                              )}
                              <Text style={styles.ml4}>
                                {getNameFromEmployeeObj(reviewData?.reviewer)}
                              </Text>
                            </div>
                          )}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Page>
    </Document>
  );
};
PerfReviewAssessmentDocument.propTypes = {
  reviewData: PropTypes.object,
  type: PropTypes.string,
};

export default PerfReviewAssessmentDocument;

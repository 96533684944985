import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Form, Card, Row, Col, Select, Space, Input, Button } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import { InlineSpinner } from "components";
import { saveBadgeApi } from "apis/praiseApis";
import { successNotification, errorNotification } from "app/appUtils";
import { iconOptionsMap } from "./constants";

const { Option } = Select;

const BadgeForm = ({
  mode = "create",
  badge = {},
  refetch = () => {},
  onCancel = () => {},
  onSave = () => {},
}) => {
  const [form] = Form.useForm();

  const [saveBadge, { status }] = useMutation(saveBadgeApi, {
    onSuccess: () => {
      successNotification(
        mode === "create"
          ? "Created Badge Successfully"
          : "Edited Badge Successfully"
      );
      refetch();
      onSave();
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  let initValues = {
    icon: "flash_on",
    title: "",
    description: "",
  };
  if (mode === "edit") {
    initValues = {
      icon: badge?.icon || "flash_on",
      title: badge?.title || "",
      description: badge?.description || "",
    };
  }

  const handleFinish = (values) => {
    if (mode === "create") {
      saveBadge({ badge: { ...values } });
    } else {
      saveBadge({ badge: { ...values, id: badge?.id } });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="badgeForm"
      initialValues={initValues}
      onFinish={handleFinish}
    >
      <Card>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 3 }}>
            <Row justify="center" align="middle" className="height-90-percent">
              <Form.Item name="icon" label="Icon" className="width-90-percent">
                <Select
                  size="large"
                  className="praise-badge-select"
                  suffixIcon={<CaretDownOutlined />}
                >
                  {Object.entries(iconOptionsMap).map(([id, value]) => (
                    <Option value={id}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
          </Col>
          <Col className="ml-32" span={20}>
            <div className="width-100-percent">
              <Form.Item
                name="title"
                label="Badge Title"
                required={false}
                rules={[
                  { required: true, message: "Name is required" },
                  { whitespace: true, message: "Name cant be empty" },
                ]}
                className="width-70-percent"
              >
                <Input
                  disabled={status === "loading"}
                  className="width-100-percent"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description for the qualities and behaviors that represent this badge"
                required={false}
                rules={[
                  { required: true, message: "Description is required" },
                  { whitespace: true, message: "Description cant be empty" },
                ]}
                className="width-95-percent"
              >
                <Input.TextArea
                  disabled={status === "loading"}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  className="input-textarea-resize-none"
                />
              </Form.Item>
              <Space>
                {status === "loading" && <InlineSpinner />}
                <Button
                  size="small"
                  type="primary"
                  htmlType="submit"
                  disabled={status === "loading"}
                >
                  {status === "loading" ? "Saving..." : "Save"}
                </Button>
                <Button
                  size="small"
                  disabled={status === "loading"}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

BadgeForm.propTypes = {
  mode: PropTypes.string,
  badge: PropTypes.object,
  refetch: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default BadgeForm;

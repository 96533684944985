import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const EmailInput = ({ disabled = false, value, onChange }) => {
  const handleChange = (e) => {
    const value = e?.target?.value || "";
    onChange(value?.trim());
  };
  return (
    <Input
      disabled={disabled}
      value={value}
      onChange={handleChange}
      placeholder="Enter Employee Email"
    />
  );
};

EmailInput.propTypes = {
  disabled: PropTypes.bool,
};

export default EmailInput;

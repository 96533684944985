import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Row, Empty } from "antd";
import { useQuery } from "react-query";

import { BoxLoader } from "components";
import EmptyOKRCard from "../common/EmptyOKRCard";
import Objectives from "../common/objectives/Objectives";
import Summary from "../common/Summary";
// import SearchAndFilters from "../common/SearchAndFilters";
// import { getSearchedObjectives, getFilteredObjectives } from "../common/utils";
import { errorNotification } from "app/appUtils";
import { getCompanyObjectivesApi } from "apis/okrV2Api";
import { ReactComponent as ObjectivesIcon } from "assets/cold-screens/Objectives.svg";

const CompanyOKRs = ({
  selectedPeriodId = null,
  selectedTeamId = null,
  timePeriods = [],
  selectedPeriodObj = {},
  shouldRefetch = false,
  handleIsAddOKRVisible = () => {},
  handleShouldRefetch = () => {},
}) => {
  const [progressHash, setProgressHash] = useState({});

  const { data, status, isLoading, isFetching, refetch } = useQuery(
    ["getCompanyObjectives", selectedPeriodId, selectedTeamId],
    getCompanyObjectivesApi,
    {
      enabled: !!selectedPeriodId && !!selectedTeamId,
      onSuccess: (data) => {
        if (shouldRefetch) {
          handleShouldRefetch();
        }
        return data;
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  const objectiveLoader = status === "loading" || isLoading;

  // if (periodLoader) {
  //   return <BoxLoader />;
  // }

  const allObjectives = data?.objectives || [];

  const isEmpty =
    !objectiveLoader && !isFetching && data?.objectives?.length === 0;

  const handleUpdateProgressHash = (updatedProgressHash) => {
    setProgressHash(updatedProgressHash);
  };

  return (
    <>
      {objectiveLoader ? (
        <BoxLoader />
      ) : (
        <>
          {data && (
            <div className="my-16">
              <div id="progress-popover" style={{ position: "relative" }} />
              {isEmpty ? (
                <EmptyOKRCard
                  Icon={<ObjectivesIcon />}
                  text="No objectives to display."
                  handleCreateObjective={handleIsAddOKRVisible}
                />
              ) : (
                <>
                  <Summary
                    overallProgress={data?.overall_progress}
                    objectiveProgressPercentage={
                      data?.objectives_percentage_progress_data
                    }
                    isMyOKR={false}
                    objectives={data?.objectives}
                    selectedPeriodObj={selectedPeriodObj}
                    selectedTeamId={selectedTeamId}
                  />
                  {/* <SearchAndFilters
                    filters={filters}
                    filteredObjectivesLength={filteredObjectives?.length}
                    allObjectivesLength={allObjectives?.length}
                    employees={[...activeEmployees, ...inActiveEmployees]}
                    setFilters={setFilters}
                  /> */}

                  {allObjectives?.length > 0 ? (
                    <Objectives
                      from="company-okrs"
                      selectedTeamId={selectedTeamId || "company-okrs"}
                      className="root-panel"
                      ghost={false}
                      timePeriods={timePeriods}
                      objectives={allObjectives}
                      percentageProgressData={progressHash}
                      selectedPeriodObj={selectedPeriodObj}
                      refetch={refetch}
                      parentDetailsRefetch={refetch}
                      handleUpdateProgressHash={handleUpdateProgressHash}
                    />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Objectives matching the given filters"
                    />
                  )}
                </>
              )}
            </div>
          )}
          {!isEmpty && (
            <Button type="primary" ghost={true}>
              <Row align="top" onClick={handleIsAddOKRVisible}>
                Create OKR
              </Row>
            </Button>
          )}
        </>
      )}
    </>
  );
};

CompanyOKRs.propTypes = {
  selectedPeriodId: PropTypes.number,
  selectedTeamId: PropTypes.number,
  selectedPeriodObj: PropTypes.object,
  empData: PropTypes.object,
  periodLoader: PropTypes.bool,
  empStatus: PropTypes.string,
};

export default CompanyOKRs;

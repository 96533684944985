const statusColors = {
  not_started: "#A3A3A3",
  at_risk: "#FF4D4F",
  behind: "#FAAD14",
  on_track: "#52C41A",
  postponed: "#7D7D7D",
  done: "#5330C9",
};

const statusText = {
  not_started: "Not Started",
  at_risk: "At Risk",
  behind: "Behind",
  on_track: "On Track",
  postponed: "Postponed",
  done: "Done",
};

export { statusColors, statusText };

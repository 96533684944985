import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Drawer, Typography, Row, Button, Table } from "antd";

import {
  APIBasedEmployeeSearch,
  ButtonWithSpinner,
  InlineSpinner,
  EmployeeAvatarWithName,
} from "components";
import { useWindowWidth } from "hooks";
import {
  addPeerApi,
  removePeerApi,
  finalizePeersApi,
} from "apis/perfReviewApi";
import {
  getNameFromEmployeeObj,
  errorNotification,
  successNotification,
} from "app/appUtils";

const { Title, Text } = Typography;

const AddPeersDrawer = ({
  revieweeForPeers = null,
  refetch = () => {},
  onClose = () => {},
}) => {
  const width = useWindowWidth();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [error, setError] = useState(false);

  const [addPeer, { isLoading: isAddingPeer }] = useMutation(addPeerApi, {
    onSuccess: () => {
      refetch();
      setSelectedEmployee(null);
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [removePeer, { isLoading: isRemovingPeer }] = useMutation(
    removePeerApi,
    {
      onSuccess: () => {
        refetch();
        setSelectedEmployee(null);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const [finalizePeers, { isLoading: isFinalizingPeer }] = useMutation(
    finalizePeersApi,
    {
      onSuccess: () => {
        refetch();
        setSelectedEmployee(null);
        onClose();
        successNotification("Finalized peers");
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  //TODO: get all peers from api or useState

  const handleAddPeer = () => {
    if (!selectedEmployee) {
      setError("No employee selected");
      return;
    }

    setError("");
    addPeer({ reviewId: revieweeForPeers?.id, employeeId: selectedEmployee });
  };

  const handleRemovePeer = (employeeId) => {
    console.log(employeeId);
    removePeer({ reviewId: revieweeForPeers?.id, employeeId });
  };

  const handleFinalizePeers = () => {
    finalizePeers({ reviewId: revieweeForPeers?.id });
  };

  return (
    <Drawer
      width={width <= 1100 ? "45%" : "35%"}
      visible={Boolean(revieweeForPeers)}
      closable={false}
      onClose={onClose}
    >
      {Boolean(revieweeForPeers) ? (
        <>
          <Row justify="space-between">
            <Title level={3}>{`Peers for ${getNameFromEmployeeObj(
              revieweeForPeers?.reviewee
            )}`}</Title>
            <ButtonWithSpinner
              isSpinning={isFinalizingPeer}
              disabled={isFinalizingPeer}
              type="primary"
              onClick={handleFinalizePeers}
            >
              Finalize Peers
            </ButtonWithSpinner>
          </Row>

          <Table
            className="mt-16"
            dataSource={revieweeForPeers?.peers}
            columns={[
              {
                title: "Employee",
                dataIndex: "peer",
                key: "peer",
                render: (_, row) => (
                  <EmployeeAvatarWithName
                    employee={row}
                    emptyText="Anonymous"
                  />
                ),
              },
              {
                title: "Actions",
                dataIndex: "actions",
                key: "actions",
                render: (_, row) => (
                  <Row align="middle">
                    <Button
                      type="link"
                      onClick={() => handleRemovePeer(row?.id)}
                    >
                      Remove as Peer
                    </Button>
                  </Row>
                ),
              },
            ]}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
          />

          <Row className="mt-16">
            <APIBasedEmployeeSearch
              value={selectedEmployee}
              className="width-250-px"
              placeholder="Employee Name"
              allowClear={true}
              shouldShowAvatar={true}
              currentOwners={[]}
              onChange={setSelectedEmployee}
            />
            <ButtonWithSpinner
              isSpinning={isAddingPeer}
              disabled={isAddingPeer}
              className="ml-16"
              type="primary"
              onClick={handleAddPeer}
            >
              Add as Peer
            </ButtonWithSpinner>
            {isRemovingPeer && <InlineSpinner className="ml-8" />}
          </Row>
          {error && (
            <Text className="block-display" type="danger">
              {error}
            </Text>
          )}

          <Button
            className="mt-16"
            type="primary"
            ghost={true}
            onClick={onClose}
          >
            Close
          </Button>
        </>
      ) : null}
    </Drawer>
  );
};

AddPeersDrawer.propTypes = {
  reviewId: PropTypes.object,
  handleAddPeersDrawer: PropTypes.func,
};

export default AddPeersDrawer;

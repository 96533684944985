import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useQuery } from "react-query";
import { Typography, Row, Button, Col, Empty } from "antd";

import { BoxLoader } from "components";
import { useCurrentCompany } from "store";
import KPICard from "./KPICard";
import SaveKPIDrawer from "./save/SaveKPIDrawer";
import { getMyKPIsApi, getCompanyKPIsApi } from "apis/kpis";

import styles from "../OKRs.module.css";

const { Title } = Typography;

const KPIs = ({ selectedTeamId = null }) => {
  const [currentCompany] = useCurrentCompany();
  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ["geKPIs", selectedTeamId],
    selectedTeamId === "my-okrs" ? getMyKPIsApi : getCompanyKPIsApi
  );

  const handleClickCreateBtn = () => {
    setIsCreateDrawerVisible(!isCreateDrawerVisible);
  };

  const getInitialTypeId = () => {
    if (selectedTeamId === "my-okrs") {
      return "IndividualKpi";
    }
    if (selectedTeamId === "company-okrs") {
      return "OrganizationKpi";
    }
    return selectedTeamId
      ? Number(selectedTeamId) || "OrganizationKpi"
      : "OrganizationKpi";
  };

  const currentUserId = currentCompany?.user?.id;

  return (
    <div className="mb-32">
      {isCreateDrawerVisible && (
        <SaveKPIDrawer
          initialValues={{
            operator: ">=",
            type_id: getInitialTypeId(),
            kpi_metric: "Number",
            owner_id: currentUserId,
          }}
          visible={isCreateDrawerVisible}
          onClose={handleClickCreateBtn}
          refetch={refetch}
        />
      )}
      <Row justify="space-between" align="top">
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Title level={2}>KPIs</Title>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 18 }}>
          <Row align="middle" justify="end" gutter={[16, 16]}>
            <Col span={5}></Col>
            <Col></Col>
            <Col xxl={{ span: 3 }} lg={{ span: 4 }}>
              <Button
                className={`mr-16  ${styles?.kpisBtn}`}
                type="primary"
                onClick={handleClickCreateBtn}
              >
                Create KPI
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <Row
          className="mb-16 mt--8"
          justify={data?.length === 0 ? "center" : "start"}
        >
          {data?.length > 0 ? (
            data?.map((kpi) => <KPICard kpi={kpi} refetch={refetch} />)
          ) : (
            <Empty description="No KPIs" />
          )}
        </Row>
      )}
    </div>
  );
};

KPIs.propTypes = {};

export default KPIs;

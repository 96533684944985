import React, { Fragment, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Redirect } from "react-router";
import * as Sentry from "@sentry/react";

import { useCurrentCompany } from "store";
import AppLayoutV3 from "components/layouts/AppLayoutV3";
import { PageNotFound, Forbidden403, DisabledFeature } from "components";
import settingRoutes from "app/settings/settings.routes";
import companyRoutes from "app/company/company.routes";
import reviewRoutes from "app/reviews/reviews.routes";
import Auth from "app/auth/Auth";
import AuthWithSAML from "app/auth/AuthWithSAML";
import Signup from "app/signup/Signup";
import NoOrgsFound from "app/no-orgs-found/NoOrgsFound";
import Demo from "app/demo/Demo";
import { getOrderedActiveSteps, getCompletionPercentage } from "app/appUtils";
import AuthGuard from "components/AuthGuard";
import OnboardGuard from "components/OnboardGuardV2";
import surveyRoutes from "app/surveys/surveys.routes";
// import objectiveRoutes from "app/objectives/objectives.routes";
import okrsRoutes from "app/okrs/okrs.routes";
import Insights from "app/okrs/insights/Insights";
import praiseRoutes from "app/praise/praise.routes";
import SetupProgress from "app/setup-progress/SetupProgress";
import SuperAdmin from "app/super-admin/SuperAdmin";
import continuousFeedbackRoutes from "app/continuous-feedback/continuous-feedback.routes";
import homeRoutes from "app/home/home.routes.js";

function ErrorFallback({ error }) {
  const history = useHistory();
  const location = useLocation();
  const [currentCompany] = useCurrentCompany();

  useEffect(() => {
    Sentry.captureMessage(
      `${currentCompany?.user?.name} (${currentCompany?.user?.email}) - Error from FE - ${error?.message}
      This error occurred at path:${location?.pathname} search: ${location?.search}`,
      {
        extra: error,
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleReload = () => {
    history.push("/");
  };

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={handleReload}>Try again</button>
    </div>
  );
}

const DefaultRoute = () => {
  const [currentCompany] = useCurrentCompany();
  const isAdmin = currentCompany?.role === "admin";

  const orderedActiveSetupSteps = getOrderedActiveSteps(
    currentCompany?.steps || []
  );
  const setupPercentage = getCompletionPercentage(orderedActiveSetupSteps);

  if (isAdmin) {
    if (setupPercentage !== "100") {
      return <Redirect to="/setup-progress" />;
    }
    return <Redirect to={"/home"} />;
  }
  return <Redirect to={"/home"} />;
};

const routesConfig = [
  {
    exact: true,
    path: "/",
    layout: AppLayoutV3,
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: DefaultRoute,
  },
  {
    exact: true,
    path: "/demo",
    component: Demo,
  },
  {
    exact: true,
    path: "/login-saml",
    component: AuthWithSAML,
  },
  {
    exact: true,
    path: "/auth",
    component: Auth,
  },
  {
    exact: true,
    path: "/signup",
    guard: AuthGuard,
    component: Signup,
  },
  {
    exact: true,
    path: "/no-orgs-found",
    guard: AuthGuard,
    component: NoOrgsFound,
  },
  {
    path: "/reviews",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...reviewRoutes],
  },
  {
    path: "/surveys",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...surveyRoutes],
  },
  {
    path: "/hpr-surveys",
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: DisabledFeature,
  },
  // {
  //   path: "/objectives",
  //   guard: AuthGuard,
  //   onboard: OnboardGuard,
  //   routes: [...objectiveRoutes],
  // },
  {
    path: "/okrs",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...okrsRoutes],
  },
  {
    exact: true,
    path: "/insights/",
    guard: AuthGuard,
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: () => <Redirect to="/insights/okr-insights" />,
  },
  {
    path: "/insights/:tab",
    guard: AuthGuard,
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: Insights,
  },
  {
    path: "/360-feedback",
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: DisabledFeature,
  },
  {
    path: "/continuous-feedback",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...continuousFeedbackRoutes],
  },
  {
    path: "/career-management",
    guard: AuthGuard,
    onboard: OnboardGuard,
    component: DisabledFeature,
  },
  {
    path: "/home",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...homeRoutes],
  },
  {
    exact: true,
    path: "/meetings",
    guard: AuthGuard,
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: () => "1:1 Meetings",
  },
  {
    path: "/praise",
    guard: AuthGuard,
    onboard: OnboardGuard,
    routes: [...praiseRoutes],
  },
  {
    path: "/settings",
    guard: AuthGuard,
    onboard: OnboardGuard,
    isAdminRoute: true,
    routes: [...settingRoutes],
  },
  {
    path: "/company",
    guard: AuthGuard,
    onboard: OnboardGuard,
    isAdminRoute: true,
    routes: [...companyRoutes],
  },
  {
    exact: true,
    path: "/setup-progress",
    guard: AuthGuard,
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    isAdminRoute: true,
    component: SetupProgress,
  },
  {
    exact: true,
    path: "/super-admin",
    guard: AuthGuard,
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: SuperAdmin,
  },
  {
    exact: true,
    path: "/feature-disabled",
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: DisabledFeature,
  },
  {
    exact: true,
    path: "/404-not-found",
    onboard: OnboardGuard,
    layout: AppLayoutV3,
    component: PageNotFound,
  },
  {
    exact: true,
    path: "/access-denied",
    layout: AppLayoutV3,
    component: Forbidden403,
  },
];

const renderRoutes = (routes, isAdmin) =>
  routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;
        let Guard = Fragment;
        let Onboard = Fragment;
        let isAdminRoute = false;

        if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
          Guard = route.guard || Fragment;
          Onboard = route.onboard || Fragment;
          isAdminRoute = route.isAdminRoute || false;
        } else {
          Guard = route.guard || Fragment;
          Onboard = route.onboard || Fragment;
        }

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Onboard>
                  <Layout>
                    {isAdminRoute && !isAdmin ? (
                      <>
                        {route.routes ? (
                          <AppLayoutV3>
                            <Forbidden403 />
                          </AppLayoutV3>
                        ) : (
                          <Forbidden403 />
                        )}
                      </>
                    ) : (
                      <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Component {...props} />
                          </ErrorBoundary>
                        )}
                      </Sentry.ErrorBoundary>
                    )}
                  </Layout>
                </Onboard>
              </Guard>
            )}
          />
        );
      })}
      <Route
        render={() => {
          return (
            <AppLayoutV3>
              <PageNotFound />
            </AppLayoutV3>
          );
        }}
      />
    </Switch>
  ) : null;

function Routes() {
  const [currentCompany] = useCurrentCompany();

  return renderRoutes(routesConfig, currentCompany?.role === "admin");
}

export default Routes;

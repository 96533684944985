import React from "react";
import clsx from "clsx";
import { Form, Input, Modal, Row, Col, Typography, Space } from "antd";

import useSetQuestionFormValues from "hooks/useSetQuestionFormValues";
import { TEXT_TYPE } from "../../../threeSixtyConstants";

const { Text } = Typography;

const AddTextQuestionForm = ({ visible, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  useSetQuestionFormValues({ type: TEXT_TYPE, form, values: initialValues });

  const onOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      width="1000px"
      title={"Add Text Question"}
      visible={visible}
      onOk={onOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="textQuestionForm"
        initialValues={{ type: TEXT_TYPE }}
      >
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="order" hidden>
          <Input />
        </Form.Item>

        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              // label="Question for the Reviewee"
              rules={[
                {
                  required: true,
                  message: "Question is required",
                },
                {
                  whitespace: true,
                  message: "Question cant be empty.",
                },
              ]}
              required={false}
            >
              <Input.TextArea
                className={clsx("mt-16", "input-textarea-resize-none")}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space direction="vertical">
          <Text type="secondary">Macros you can use in the question text.</Text>
          <Text>First Name - %FIRST_NAME%</Text>
          <Text>Last Name - %LAST_NAME%</Text>
          <Text>Full Name - %FULL_NAME%</Text>
        </Space>
      </Form>
    </Modal>
  );
};

AddTextQuestionForm.propTypes = {};

export default AddTextQuestionForm;

import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { useQuery, useMutation } from "react-query";
import HSBar from "react-horizontal-stacked-bar-chart";
import { debounce, throttle } from "lodash";
import {
  Row,
  Col,
  Table,
  Typography,
  Card,
  Grid,
  InputNumber,
  Select,
  Input,
} from "antd";

import {
  BoxLoader,
  InlineSpinner,
  OKRTimePeriodSelect,
  LineChart,
  EmployeeAvatarWithName,
  CountCard,
} from "components";
import { useWindowWidth } from "hooks";
import SeeOKRsDrawer from "app/home/common/SeeOKRsDrawer";
import { statusText } from "app/okrs/common/constants";
import {
  getObjectivePeriodsApi,
  getInsightsSummaryApi,
  getEmployeesWithoutCheckinsApi,
  getEmployeesWithOKRStatusApi,
  getEmployeesWithoutOKRsApi,
  bulkNudgeInsightsApi,
} from "apis/okrV2Api";
import { getHsBarData, getOutlineWidth } from "app/okrs/common/utils";
import {
  successNotification,
  errorNotification,
  errorNotificationWithString,
  getNameFromEmployeeObj,
  getWidthForChart,
} from "app/appUtils";

import styles from "app/okrs/OKRs.module.css";

import { ReactComponent as LeftNudge } from "assets/reviews/reviewee_nudge.svg";

const { Text } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

const getTickAmount = (okrCheckins = {}) => {
  const checkinCounts = Object.values(okrCheckins);
  const maxCheckinCount = Math.max(...checkinCounts);

  if (maxCheckinCount < 5) {
    return maxCheckinCount;
  }
  return 5;
};

const OKRInsights = (props) => {
  const screens = useBreakpoint();

  const width = useWindowWidth();

  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [selectedDays, setSelectedDays] = useState(7);
  const [selectedStatus, setSelectedStatus] = useState("at_risk");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [searchString, setSearchString] = useState({});
  const [isLessThanThreeChars, setIsLessThanThreeChars] = useState("");
  const [checkinsDataSource, setCheckinsDataSource] = useState([]);
  const [noOKRsDataSource, setNoOKRsDataSource] = useState([]);
  const [statusDataSource, setStatusDataSource] = useState([]);
  const [nudgeTypeForLoading, setNudgeTypeForLoading] = useState("");

  const {
    data: periods,
    status: periodStatus,
    isLoading: isPeriodLoading,
    // isFetching: isPeriodFetching,
  } = useQuery("getAllObjectivePeriods", getObjectivePeriodsApi, {
    onSuccess: (data) => {
      const currentPeriod = data?.filter((period) => period?.is_active)[0];
      setSelectedPeriodId((prevValue) => {
        if (!prevValue) {
          return currentPeriod?.id;
        }
        return prevValue;
      });
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const {
    data: summaryData,
    isLoading: isSummaryLoading,
    isFetching: isSummaryFetching,
  } = useQuery(
    ["getInsightsSummary", selectedPeriodId],
    getInsightsSummaryApi,
    { enabled: selectedPeriodId }
  );

  const [bulkNudgeInsights, { isLoading: bulkNudging }] = useMutation(
    bulkNudgeInsightsApi,
    {
      onSuccess: () => {
        setNudgeTypeForLoading("");
        successNotification("Successfully Nudged");
      },
      onError: (err) => {
        setNudgeTypeForLoading("");
        errorNotification(err);
      },
    }
  );

  const [
    getEmployeesWithoutCheckins,
    {
      data: checkinsData,
      isLoading: isGetCheckinsLoading,
      isFetching: isGetCheckinsFetching,
    },
  ] = useMutation(getEmployeesWithoutCheckinsApi, {
    onSuccess: (data) => {
      setCheckinsDataSource(data);
      setSearchString((prevState) => ({ ...prevState, checkinTable: "" }));
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    getEmployeesWithoutOKRs,
    { data: okrsData, isLoading: isOKRsLoading, isFetching: isOKRsFetching },
  ] = useMutation(getEmployeesWithoutOKRsApi, {
    onSuccess: (data) => {
      setNoOKRsDataSource(data);
      setSearchString((prevState) => ({ ...prevState, checkinTable: "" }));
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    getEmployeesWithOKRStatus,
    {
      data: employeesOKRData,
      isLoading: isEmployeesOKRLoading,
      isFetching: isEmployeesOKRFetching,
    },
  ] = useMutation(getEmployeesWithOKRStatusApi, {
    onSuccess: (data) => {
      setStatusDataSource(data);
      setSearchString((prevState) => ({ ...prevState, statusTable: "" }));
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  useEffect(() => {
    if (selectedPeriodId) {
      getEmployeesWithoutCheckins({
        selectedPeriodObjId: selectedPeriodId,
        days: selectedDays,
      });
    }
  }, [getEmployeesWithoutCheckins, selectedDays, selectedPeriodId]);

  useEffect(() => {
    if (selectedPeriodId) {
      getEmployeesWithoutOKRs({
        selectedPeriodObjId: selectedPeriodId,
      });
    }
  }, [getEmployeesWithoutOKRs, selectedPeriodId]);

  useEffect(() => {
    if (selectedPeriodId) {
      getEmployeesWithOKRStatus({
        selectedPeriodObjId: selectedPeriodId,
        status: selectedStatus,
      });
    }
  }, [getEmployeesWithOKRStatus, selectedPeriodId, selectedStatus]);

  const handleSelectedPeriodChange = (periodId) => {
    setSelectedPeriodId(periodId);
  };

  const handleCheckinsChange = (value) => {
    if (!value) {
      errorNotificationWithString("Days can't be empty");
      return;
    }
    setSelectedDays(value);
  };

  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleSeeEmployeeOkrs = (employee = null) => {
    setSelectedEmployee(employee);
  };

  const handleWithoutCheckinSearch = (e) => {
    let value = e.target.value;
    setSearchString({ checkinTable: value });

    if (!value) {
      setCheckinsDataSource(checkinsData);
      setIsLessThanThreeChars("");
      return;
    }

    if (value?.length > 2) {
      const filteredData = checkinsData?.filter((row) => {
        const employeeName = getNameFromEmployeeObj(row?.employee) || "";
        return employeeName?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setCheckinsDataSource(filteredData);
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("checkin-table");
    }
  };

  const handleWithoutCheckinSearchThrottled = (e) => {
    throttle(() => {
      handleWithoutCheckinSearch(e);
    }, 750)();
  };

  const handleWithoutOKRsSearch = (e) => {
    let value = e.target.value;
    setSearchString({ okrsTable: value });

    if (!value) {
      setNoOKRsDataSource(okrsData);
      setIsLessThanThreeChars("");
      return;
    }

    if (value?.length > 2) {
      const filteredData = okrsData?.filter((row) => {
        const employeeName = getNameFromEmployeeObj(row?.employee) || "";
        return employeeName?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setNoOKRsDataSource(filteredData);
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("no-okrs-table");
    }
  };

  const handleWithoutOKRsSearchThrottled = (e) => {
    throttle(() => {
      handleWithoutOKRsSearch(e);
    }, 750)();
  };

  const handleStatusSearch = (e) => {
    let value = e.target.value;
    setSearchString({ statusTable: value });

    if (!value) {
      setStatusDataSource(employeesOKRData);
      setIsLessThanThreeChars("");
      return;
    }

    if (value?.length > 2) {
      const filteredData = employeesOKRData?.filter((row) => {
        const employeeName = getNameFromEmployeeObj(row?.employee) || "";
        return employeeName?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setStatusDataSource(filteredData);
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("status-table");
    }
  };

  const handleStatusSearchThrottled = (e) => {
    throttle(() => {
      handleStatusSearch(e);
    }, 750)();
  };

  const handleNudge = ({ type, note, employee_ids }) => {
    setNudgeTypeForLoading(type);
    bulkNudgeInsights({
      type,
      note,
      employee_ids,
      selectedPeriodObjId: selectedPeriodId,
    });
  };

  const statuses = summaryData?.objectives || {};

  const statusValues = Object.values(statuses) || [];
  const objectivesCount = statusValues?.reduce((a, b) => a + b, 0);

  let selectedPeriod = periods?.filter(
    (period) => period?.id === selectedPeriodId
  );
  selectedPeriod = selectedPeriod?.length > 0 ? selectedPeriod[0] : null;

  const okrPeriodLoading = periodStatus === "loading" || isPeriodLoading;

  const checkinsLoader = isGetCheckinsLoading || isGetCheckinsFetching;

  const okrsLoader = isOKRsLoading || isOKRsFetching;

  const employeesWithOKRsLoader =
    isEmployeesOKRFetching || isEmployeesOKRLoading;

  if (okrPeriodLoading || isSummaryLoading) {
    return <BoxLoader />;
  }

  return (
    <>
      {Boolean(selectedEmployee) && (
        <SeeOKRsDrawer
          screens={screens}
          defaultOKRPeriodId={selectedPeriodId}
          employee={selectedEmployee}
          onClose={() => handleSeeEmployeeOkrs(null)}
        />
      )}
      <div className="mb-8 flex-display justify-content-flex-end align-items-center">
        <>
          {isSummaryFetching && (
            <InlineSpinner className="ml-8" fontSize={22} />
          )}
        </>
        <OKRTimePeriodSelect
          className="width-250-px"
          periods={periods}
          selectedPeriodId={selectedPeriodId}
          handleSelectedPeriodChange={handleSelectedPeriodChange}
        />
      </div>

      <Card>
        <Row gutter={[8]}>
          <Col span={10}>
            <div className="flex-display mb-8">
              <CountCard
                title="Objectives"
                count={objectivesCount || 0}
                hasMiniCount={true}
                miniCount={`(${summaryData?.overall_progress || 0} %)`}
              />
              <CountCard
                className="ml-8"
                title="Key Results"
                count={summaryData?.key_results || 0}
              />
              <CountCard
                className="ml-8"
                title="KR Checkins"
                count={summaryData?.no_of_checkins || 0}
              />
            </div>
            <div className={styles.okrCountCard}>
              <Text className="font-size-14">OKRs BY STATUS</Text>
              <div>
                {(statuses["not_started"] > 0 ||
                  statuses["on_track"] > 0 ||
                  statuses["behind"] > 0 ||
                  statuses["at_risk"] > 0 ||
                  statuses["done"] > 0) && (
                  <HSBar
                    height={25}
                    data={getHsBarData(statuses)}
                    outlineWidth={getOutlineWidth([
                      statuses["not_started"],
                      statuses["on_track"],
                      statuses["behind"],
                      statuses["at_risk"],
                      statuses["done"],
                    ])}
                    outlineColor="white"
                  />
                )}

                <Row className="min-width-250px">
                  <Col span={12}>
                    <Row className="height-100-percent mb-8" align="middle">
                      <span className={styles.onTrackDot} />
                      <Text className="font-size-14 line-height-16px">{`${statuses["on_track"]} ON TRACK`}</Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row className="height-100-percent mb-8" align="middle">
                      <span className={styles.behindDot} />
                      <Text className="font-size-14 line-height-16px">{`${statuses["behind"]} BEHIND`}</Text>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Row className="height-100-percent mb-8" align="middle">
                      <span className={styles.atRiskDot} />
                      <Text className="font-size-14 line-height-16px">{`${statuses["at_risk"]} AT RISK`}</Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row className="height-100-percent mb-8" align="middle">
                      <span className={styles.doneDot} />
                      <Text className="font-size-14 line-height-16px">{`${statuses["done"]} DONE`}</Text>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Row className="height-100-percent mb-8" align="middle">
                      <span className={styles.notStartedDot} />
                      <Text className="font-size-14 line-height-16px">{`${statuses["not_started"]} NOT STARTED`}</Text>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <LineChart
              xTitle="OKR Checkins over time"
              width={Math.round(getWidthForChart(width))}
              series={[
                {
                  name: "check-ins",
                  data:
                    Object.keys(summaryData?.okr_checkins || {})?.length > 0
                      ? Object.entries(summaryData?.okr_checkins)?.map(
                          ([key, val]) => ({
                            x: key,
                            y: val,
                          })
                        )
                      : [],
                },
              ]}
              tickAmount={getTickAmount(summaryData?.okr_checkins)}
            />
          </Col>
        </Row>
      </Card>
      <div className="mt-40">
        <Card
          className="insights-card-table"
          title={
            <div className="flex-display justify-content-space-between align-items-center mt-8">
              <div
                className="flex-display align-items-center"
                style={{ marginBottom: "22px" }}
              >
                <Text>{`(${
                  checkinsData?.length || 0
                }) Employees without checkins in last `}</Text>
                <InputNumber
                  size="small"
                  className="mr-8"
                  value={selectedDays}
                  onChange={debounce(handleCheckinsChange, 750)}
                />
                <Text>days</Text>
                <LeftNudge
                  disabled={bulkNudging}
                  className="ml-8 cursor-pointer"
                  onClick={() => {
                    handleNudge({
                      type: "no_recent_checkin",
                      note: `You haven't checked-in in the past ${selectedDays} days, please checkin now`,
                      employee_ids: checkinsDataSource?.map(
                        (data) => data?.employee?.id
                      ),
                    });
                  }}
                />
                {(checkinsLoader ||
                  nudgeTypeForLoading === "no_recent_checkin") && (
                  <InlineSpinner className="ml-8" fontSize={22} />
                )}
              </div>
              <div>
                <div>
                  <Input
                    allowClear
                    className="width-250-px"
                    placeholder="Search Employee"
                    value={searchString?.checkinTable || ""}
                    onChange={handleWithoutCheckinSearchThrottled}
                  />
                </div>
                {isLessThanThreeChars === "checkin-table" ? (
                  <Text className="width-250-px" type="danger">
                    Type at least 3 characters
                  </Text>
                ) : (
                  <div style={{ minHeight: "22px" }}></div>
                )}
              </div>
            </div>
          }
        >
          <div>
            <Table
              loading={checkinsLoader}
              className="mt-4"
              dataSource={checkinsDataSource}
              columns={[
                {
                  title: "Employee Name",
                  dataIndex: "employee",
                  key: "employee",
                  render: (employee) => (
                    <>
                      <EmployeeAvatarWithName employee={employee} />
                    </>
                  ),
                },
                {
                  title: "Number of Objectives",
                  dataIndex: "objectives",
                  key: "objectives",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.objectives - b?.objectives,
                },
                {
                  title: "Number of KRs",
                  dataIndex: "key_results",
                  key: "key_results",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.key_results - b?.key_results,
                },
                {
                  title: "Number of Checkins",
                  dataIndex: "no_of_checkins",
                  key: "no_of_checkins",
                  defaultSortOrder: "ascend",
                  sorter: (a, b) => a?.no_of_checkins - b?.no_of_checkins,
                },
                {
                  title: "Actions",
                  render: (_, row) => {
                    return (
                      <Text
                        className="text-decoration-underline text-primary cursor-pointer"
                        onClick={() => {
                          handleSeeEmployeeOkrs({
                            ...row?.employee,
                          });
                        }}
                      >
                        See OKRs
                      </Text>
                    );
                  },
                },
              ]}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
            />
          </div>
        </Card>
      </div>

      <div className="mt-40">
        <Card
          className="insights-card-table"
          title={
            <div className="flex-display justify-content-space-between align-items-center mt-8">
              <div
                className="flex-display align-items-center"
                style={{ marginBottom: "22px" }}
              >
                <Text>{`(${
                  okrsData?.length || 0
                }) Employees with no OKRs or Key Results `}</Text>
                <LeftNudge
                  disabled={bulkNudging}
                  className="ml-8 cursor-pointer"
                  onClick={() => {
                    handleNudge({
                      type: "no_okrs",
                      note: `You don't have any OKRs or key results in culture.easy for the time period ${selectedPeriod?.name}, please create an OKR or a key-result`,
                      employee_ids: noOKRsDataSource?.map(
                        (data) => data?.employee?.id
                      ),
                    });
                  }}
                />
                {(okrsLoader || nudgeTypeForLoading === "no_okrs") && (
                  <InlineSpinner className="ml-8" fontSize={22} />
                )}
              </div>
              <div>
                <div>
                  <Input
                    allowClear
                    className="width-250-px"
                    placeholder="Search Employee"
                    value={searchString?.okrsTable || ""}
                    onChange={handleWithoutOKRsSearchThrottled}
                  />
                </div>
                {isLessThanThreeChars === "no-okrs-table" ? (
                  <Text className="width-250-px" type="danger">
                    Type at least 3 characters
                  </Text>
                ) : (
                  <div style={{ minHeight: "22px" }}></div>
                )}
              </div>
            </div>
          }
        >
          <div>
            <Table
              loading={okrsLoader}
              className="mt-4"
              dataSource={noOKRsDataSource}
              columns={[
                {
                  title: "Employee Name",
                  dataIndex: "employee",
                  key: "employee",
                  render: (employee) => (
                    <>
                      <EmployeeAvatarWithName employee={employee} />
                    </>
                  ),
                },
                {
                  title: "Number of Objectives",
                  dataIndex: "objectives",
                  key: "objectives",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.objectives - b?.objectives,
                },
                {
                  title: "Number of KRs",
                  dataIndex: "key_results",
                  key: "key_results",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.key_results - b?.key_results,
                },
                {
                  title: "Actions",
                  render: (_, row) => {
                    return (
                      <Text
                        className="text-decoration-underline text-primary cursor-pointer"
                        onClick={() => {
                          handleSeeEmployeeOkrs({
                            ...row?.employee,
                          });
                        }}
                      >
                        See OKRs
                      </Text>
                    );
                  },
                },
              ]}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
            />
          </div>
        </Card>
      </div>

      <div className="mt-40">
        <Card
          className="insights-card-table"
          title={
            <div className="flex-display justify-content-space-between align-items-center mt-8">
              <div
                className="flex-display align-items-center"
                style={{ marginBottom: "22px" }}
              >
                <Text>{`(${
                  employeesOKRData?.length || 0
                }) Employees with OKRs `}</Text>
                <Select
                  className="width-150-px"
                  value={selectedStatus}
                  onChange={handleSelectedStatus}
                >
                  <Option value="not_started">Not Started</Option>
                  <Option value="at_risk">At Risk</Option>
                  <Option value="behind">Behind</Option>
                  <Option value="on_track">On Track</Option>
                  <Option value="done">Done</Option>
                </Select>

                <LeftNudge
                  className={`ml-8 ${
                    bulkNudging ||
                    selectedStatus === "on_track" ||
                    selectedStatus === "done"
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    if (
                      bulkNudging ||
                      selectedStatus === "on_track" ||
                      selectedStatus === "done"
                    ) {
                      return;
                    }
                    handleNudge({
                      type: "current_okr_status",
                      note: `Your okrs or key result are in ${statusText[selectedStatus]} state, please do the needful to stay on track.`,
                      employee_ids: statusDataSource?.map(
                        (data) => data?.employee?.id
                      ),
                    });
                  }}
                />
                {(employeesWithOKRsLoader ||
                  nudgeTypeForLoading === "current_okr_status") && (
                  <InlineSpinner className="ml-8" fontSize={22} />
                )}
              </div>
              <div>
                <div>
                  <Input
                    allowClear
                    className="width-250-px"
                    placeholder="Search Employee"
                    value={searchString?.statusTable || ""}
                    onChange={handleStatusSearchThrottled}
                  />
                </div>
                {isLessThanThreeChars === "status-table" ? (
                  <Text className="width-250-px" type="danger">
                    Type at least 3 characters
                  </Text>
                ) : (
                  <div style={{ minHeight: "22px" }}></div>
                )}
              </div>
            </div>
          }
        >
          <div>
            <Table
              loading={employeesWithOKRsLoader}
              className="mt-4"
              dataSource={statusDataSource}
              columns={[
                {
                  title: "Employee Name",
                  dataIndex: "employee",
                  key: "employee",
                  render: (employee) => (
                    <>
                      <EmployeeAvatarWithName employee={employee} />
                    </>
                  ),
                },
                {
                  title: "Number of Objectives",
                  dataIndex: "objectives",
                  key: "objectives",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.objectives - b?.objectives,
                },
                {
                  title: "Number of KRs",
                  dataIndex: "key_results",
                  key: "key_results",
                  defaultSortOrder: "descend",
                  sorter: (a, b) => a?.key_results - b?.key_results,
                },
                {
                  title: "Number of Checkins",
                  dataIndex: "no_of_checkins",
                  key: "no_of_checkins",
                  defaultSortOrder: "ascend",
                  sorter: (a, b) => a?.no_of_checkins - b?.no_of_checkins,
                },
                {
                  title: "Actions",
                  render: (_, row) => {
                    return (
                      <Text
                        className="text-decoration-underline text-primary cursor-pointer"
                        onClick={() => {
                          handleSeeEmployeeOkrs({
                            ...row?.employee,
                          });
                        }}
                      >
                        See OKRs
                      </Text>
                    );
                  },
                },
              ]}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

OKRInsights.propTypes = {};

export default OKRInsights;

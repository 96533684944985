import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import { Input, Row, Col, Typography, Button, Space } from "antd";

import { useCurrentCompany } from "store";
import { ButtonWithSpinner } from "components";
import { updateGroupNameApi } from "apis/appApi";
import { errorNotification, successNotification } from "app/appUtils";

const { Text } = Typography;

const CompanyNameInput = ({
  shouldShowCancel = false,
  onCancel = () => {},
}) => {
  const [currentCompany, setCurrentCompany] = useCurrentCompany();

  const [orgName, setOrgName] = useState(currentCompany?.name || "");
  const [error, setError] = useState("");

  const [updateOrgName, { status, isLoading, isFetching }] = useMutation(
    updateGroupNameApi,
    {
      onSuccess: (data) => {
        setCurrentCompany({ ...currentCompany, name: data?.name || "" });
        successNotification("Updated Name Successfully");
        onCancel();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setError("Company name cant be empty");
    } else {
      setError("");
    }
    setOrgName(e.target.value);
  };

  const isUpdatingOrgName = status === "loading" || isLoading || isFetching;

  return (
    <div className="mb-40 mt-8">
      <Row align="bottom" gutter={[8]}>
        <Col xs={{ span: 14 }} lg={{ span: 12 }} xl={{ span: 8 }}>
          <Text type="secondary">Enter Company name</Text>
          <Input value={orgName} onChange={handleNameChange} />
        </Col>
        <Col xs={{ span: 10 }} lg={{ span: 4 }}>
          <Space direction="horizontal">
            <ButtonWithSpinner
              type="primary"
              ghost={true}
              disabled={isUpdatingOrgName || error}
              isSpinning={isUpdatingOrgName}
              spinnerColor="#5330C9"
              className={
                isUpdatingOrgName || error ? "primary-disabled-btn-text" : ""
              }
              onClick={() => {
                updateOrgName({ name: orgName });
              }}
            >
              Save Company name
            </ButtonWithSpinner>
            {shouldShowCancel && (
              <Button
                ghost={true}
                className="default-ghost-btn"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      {(!orgName || orgName?.trim()?.length === 0) && (
        <div>
          <Text className="mt-8" type="danger">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};

CompanyNameInput.propTypes = {};

export default CompanyNameInput;

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { RecoilRoot } from "recoil";
import { ReactQueryConfigProvider } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import App from "./App";

import { makeServer } from "./mock-apis/server";

if (process.env.REACT_APP_MOCK_ENV === "mirage-start") {
  makeServer({ environment: "development" });
}

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 0,
  },
};

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://92a428f352f0474faf1a4c6e2c62ebed@o464798.ingest.sentry.io/5478409",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.4,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "this[i] is not a function",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Non-Error promise rejection captured with value",
      "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
    ],
  });
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  Sentry.init({
    dsn: "https://ed9648620e9a4e5dbaba330cc4e56bb3@o464798.ingest.sentry.io/5477894",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.4,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "this[i] is not a function",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Non-Error promise rejection captured with value",
    ],
  });
}

const stripePromise = loadStripe(
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? process.env.REACT_APP_STRIPE_PUBLISH_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
);

ReactDOM.render(
  // <React.StrictMode>
  <ReactQueryConfigProvider config={queryConfig}>
    <Elements stripe={stripePromise}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Elements>
  </ReactQueryConfigProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

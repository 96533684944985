import axios from "axios";

const getAllBadgesApi = async () => {
  const { data } = await axios.get("/api/v2/praise_badges/");
  return data;
};

const saveBadgeApi = async ({ badge }) => {
  console.log({ badge });
  const { data } = await axios.post("/api/v2/admin/praise_badges/", {
    praise_badge: badge,
  });
  return data;
};

const deleteBadgeApi = async ({ id }) => {
  const { data } = await axios.delete(`api/v2/admin/praise_badges/${id}`);
  return data;
};

const getBadgesApi = async () => {
  const { data } = await axios.get("api/v2/praise_badges/");
  return data;
};

const getPraisesApi = async (key, lastId = 0) => {
  const limit = 9;
  const { data } = await axios.get(`/api/v2/praises/all/${limit}/${lastId}`);
  return data;
};

const savePraiseApi = async ({ praise }) => {
  const { data } = await axios.post("/api/v2/praises/", {
    praise,
  });
  return data;
};

const deletePraiseApi = async ({ id }) => {
  const { data } = await axios.delete(`/api/v2/praises/${id}`);
  return data;
};

const likePraiseApi = async ({ id }) => {
  const { data } = await axios.post(`/api/v2/praises/${id}/like`);
  return data;
};

const unLikePraiseApi = async ({ id }) => {
  const { data } = await axios.delete(`/api/v2/praises/${id}/unlike`);
  return data;
};

const getAllCommentsApi = async (_, id) => {
  const { data } = await axios.get(`/api/v2/praises/${id}/comments`);
  return data;
};

const updateCommentApi = async ({ praiseId, commentId, comment }) => {
  const { data } = await axios.put(
    `/api/v2/praises/${praiseId}/comments/${commentId}`,
    {
      comment,
    }
  );
  return data;
};

const postCommentApi = async ({ praiseId, comment }) => {
  const { data } = await axios.post(`/api/v2/praises/${praiseId}/comments/`, {
    comment,
  });
  return data;
};

const deleteCommentApi = async ({ praiseId, commentId }) => {
  const { data } = await axios.delete(
    `/api/v2/praises/${praiseId}/comments/${commentId}`
  );
  return data;
};

const postPraiseSearchApi = async ({
  employee_id,
  from_date,
  to_date,
  radioValue,
}) => {
  const { data } = await axios.post("api/v2/praises/search", {
    employee_id,
    from_date,
    to_date,
    praise_given: radioValue === "given",
  });

  return data;
};

export {
  getBadgesApi,
  getPraisesApi,
  savePraiseApi,
  deletePraiseApi,
  likePraiseApi,
  unLikePraiseApi,
  getAllCommentsApi,
  postCommentApi,
  updateCommentApi,
  deleteCommentApi,
  getAllBadgesApi,
  saveBadgeApi,
  deleteBadgeApi,
  postPraiseSearchApi,
};

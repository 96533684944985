import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TimedMessage = ({
  message = "",
  timeout = 2000,
  onTimeOut = () => {},
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      onTimeOut();
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return show ? <>{message}</> : null;
};

TimedMessage.propTypes = {
  message: PropTypes.node,
  timeout: PropTypes.number,
};

export default TimedMessage;

import React from "react";
import PropTypes from "prop-types";
import { Space, Typography, Tooltip } from "antd";

import { useCurrentCompany } from "store";
import EmployeeAvatar from "./EmployeeAvatar";
import { getNameFromEmployeeObj } from "app/appUtils";
const { Text } = Typography;

const EmployeeAvatarWithName = ({
  className = "",
  textClassName = "",
  employee = {},
  shouldShowYou = false,
  shouldShowInactive = false,
  shouldShowDetails = true,
  shouldShowEmail = false,
  emptyText = "No User",
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  backgroundColor = "",
  size = "default",
  onClick = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();
  const name = getNameFromEmployeeObj(employee);

  if (!name) {
    return <Text className={`italic-font ${className}`}>{emptyText}</Text>;
  }

  const you =
    shouldShowYou && currentCompany?.user?.email === employee?.email
      ? " (You)"
      : "";

  return (
    <Space className={className} onClick={onClick}>
      <EmployeeAvatar
        employee={employee}
        height={height}
        width={width}
        shouldShowDetails={shouldShowDetails}
        textFontSize={textFontSize}
        backgroundColor={backgroundColor}
        size={size}
      />
      <div>
        <div>
          <Text className={textClassName}>{`${name}${you}`}</Text>
          {shouldShowInactive && !employee?.active && (
            <Text type="secondary" className="font-size-14">
              (Inactive)
            </Text>
          )}
        </div>
        {shouldShowEmail ? (
          <Tooltip title={employee?.email}>
            <Text className="block-display" type="secondary">
              {employee?.email}
            </Text>
          </Tooltip>
        ) : null}
      </div>
    </Space>
  );
};

EmployeeAvatarWithName.propTypes = {
  employee: PropTypes.object,
};

export default EmployeeAvatarWithName;

import React from "react";
import PropTypes from "prop-types";
import { Space, Typography } from "antd";

import { EmployeeAvatar } from "components";
import { getNameFromEmployeeObj, formattedDate } from "app/appUtils";

const { Text } = Typography;

const EmployeeAvatarWithDate = ({
  className = "",
  textClassName = "",
  employee = {},
  date = "",
  shouldShowYou = false,
  shouldShowInactive = false,
  shouldShowDetails = true,
  shouldShowEmail = false,
  emptyText = "No User",
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  size = "default",
}) => {
  const name = getNameFromEmployeeObj(employee);

  return (
    <Space>
      <EmployeeAvatar
        employee={employee}
        height={height}
        width={width}
        shouldShowDetails={shouldShowDetails}
        textFontSize={textFontSize}
        size={size}
      />

      <div className="flex-display flex-direction-column">
        <Space>
          <Text className={textClassName}>{name}</Text>
          {shouldShowInactive && !employee?.active && (
            <Text type="secondary" className="font-size-14">
              (Inactive)
            </Text>
          )}
        </Space>

        <Text type="secondary" className="font-size-14">
          {formattedDate(date)}
        </Text>
      </div>
    </Space>
  );
};

EmployeeAvatarWithDate.propTypes = {
  employee: PropTypes.object,
};

export default EmployeeAvatarWithDate;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Typography, Space, Card } from "antd";
import moment from "moment";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import { saveThreeSixtyFeedbackCycleApi } from "apis/threeSixtyFeedbackApi";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import NotificationModal from "../common/NotificationModal";
import ThreeSixtyCycleForm from "../common/form-components/ThreeSixtyCycleForm";
import { errorNotification, successNotification } from "app/appUtils";
import { threeSixtyDegreeFeedback, degreeCharCode } from "app/appConstants";
import { TEXT_TYPE, MCQ_TYPE } from "../threeSixtyConstants";

const { Text } = Typography;

const DEFAULT_QUESTIONS = [
  {
    type: TEXT_TYPE,
    order: 1,
    description: "What did %FIRST_NAME% do well?",
    is_required: true,
  },
  {
    type: TEXT_TYPE,
    order: 2,
    description: "What can %FIRST_NAME% improve on?",
    is_required: true,
  },
  {
    type: MCQ_TYPE,
    order: 3,
    description: "%FIRST_NAME% treats people with respect consistently.",
    options: [
      { id: 1, value: "Strongly Agree" },
      { id: 2, value: "Agree" },
      { id: 3, value: "Disagree" },
      { id: 4, value: "Strongly Disagree" },
    ],
    is_required: true,
  },
];

const descriptionText =
  "Please provide feedback on the reviewee's performance during the review period.";

function AddThreeSixtyFeedbackCycle({
  mode = "new_cycle",
  initialValues = {},
}) {
  const history = useHistory();

  const [cycleData, setCycleData] = useState(null);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
    isEmployeesFetching,
  } = useEmployees();

  useEffect(() => {
    if (mode !== "new_cycle") {
      if (initialValues !== null) {
        setCycleData({ ...initialValues });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (mode === "new_cycle") {
      if (
        !cycleData &&
        !isEmployeesLoading &&
        activeEmployees &&
        activeEmployees?.length > 0
      ) {
        console.log(1);
        const currentQuarter = moment().quarter();
        const startDateOfQuarter = moment()
          .quarter(currentQuarter)
          .startOf("quarter");
        const endDateOfQuarter = moment()
          .quarter(currentQuarter)
          .endOf("quarter");

        const initValues = {
          name: `360${String.fromCharCode(
            degreeCharCode
          )} Feedback Cycle Q${currentQuarter}/${moment().year()}`,
          reviewer_message: descriptionText,
          period: [startDateOfQuarter, endDateOfQuarter],
          nominate_due_date: null,
          finalize_due_date: null,
          submit_due_date: null,
          for_entire_company: true,
          employee_can_nominate: true,
          participants: [],
          employees: JSON.parse(JSON.stringify(activeEmployees)),
          questions: DEFAULT_QUESTIONS.map((question) => ({ ...question })),
        };
        setCycleData({ ...initValues });
      }
    } else {
      setCycleData({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEmployees, isEmployeesLoading]); // TODO: add initialValues

  const handleEmailDialogOpen = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const [saveThreeSixtyFeedbackCycle, { status, isLoading }] = useMutation(
    saveThreeSixtyFeedbackCycleApi,
    {
      onSuccess: (data) => {
        if (!data?.draft) {
          const msg = data.are_emails_sent ? (
            <div>
              <Space direction="vertical">
                <Text>{`${threeSixtyDegreeFeedback} Cycle created`}</Text>
                <Text>Emails Sent</Text>
              </Space>
            </div>
          ) : (
            `${threeSixtyDegreeFeedback} Cycle created`
          );
          successNotification(msg);
        }
        history.push("/company/360-feedback");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleCreateThreeSixtyFeedbackReviewCycle = (should_trigger_emails) => {
    const period = cycleData?.period || [];
    let startDate = "";
    let endDate = "";
    if (period[0] && period[1]) {
      startDate = period[0].format("YYYY-MM-DD");
      endDate = period[1].format("YYYY-MM-DD");
    }
    // const questions = cycleData?.questions?.map((question) => {
    //   if (question?.type === TEXT_TYPE) {
    //     return { ...question };
    //   }
    //   const options = {};
    //   question.options.forEach(({ value }, index) => {
    //     options[index + 1] = value;
    //   });
    //   return { ...question, options };
    // });
    const threesixty_feedback_cycle = {
      ...cycleData,
      draft: false,
      from_date: startDate,
      to_date: endDate,
      // nominate_due_date: cycleData["nominate_due_date"].format("YYYY-MM-DD"),
      // finalize_due_date: cycleData["finalize_due_date"].format("YYYY-MM-DD"),
      // submit_due_date: cycleData["submit_due_date"].format("YYYY-MM-DD"),
      send_emails: should_trigger_emails,
      // questions,
    };
    // if (cycleData["nominate_due_date"]) {
    //   cycleData["nominate_due_date"] = cycleData["nominate_due_date"].format(
    //     "YYYY-MM-DD"
    //   );
    // }
    delete threesixty_feedback_cycle["employees"];
    saveThreeSixtyFeedbackCycle({ threesixty_feedback_cycle });
  };

  const handleSaveAsDraft = (values) => {
    const period = values?.period || [];
    let startDate = "";
    let endDate = "";
    if (period[0] && period[1]) {
      startDate = period[0].format("YYYY-MM-DD");
      endDate = period[1].format("YYYY-MM-DD");
    }

    const threesixty_feedback_cycle = {
      ...values,
      from_date: startDate,
      to_date: endDate,
      send_emails: false,
      draft: true,
    };
    if (
      threesixty_feedback_cycle["nominate_due_date"] &&
      typeof threesixty_feedback_cycle["nominate_due_date"] !== "string"
    ) {
      threesixty_feedback_cycle[
        "nominate_due_date"
      ] = threesixty_feedback_cycle["nominate_due_date"].format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    if (
      threesixty_feedback_cycle["finalize_due_date"] &&
      typeof threesixty_feedback_cycle["finalize_due_date"] !== "string"
    ) {
      threesixty_feedback_cycle[
        "finalize_due_date"
      ] = threesixty_feedback_cycle["finalize_due_date"].format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    if (
      threesixty_feedback_cycle["submit_due_date"] &&
      typeof threesixty_feedback_cycle["submit_due_date"] !== "string"
    ) {
      threesixty_feedback_cycle["submit_due_date"] = threesixty_feedback_cycle[
        "submit_due_date"
      ].format("YYYY-MM-DD HH:mm:ss");
    }
    if (threesixty_feedback_cycle["threesixty_participants"]) {
      delete threesixty_feedback_cycle["threesixty_participants"];
    }
    delete threesixty_feedback_cycle["employees"];
    saveThreeSixtyFeedbackCycle({ threesixty_feedback_cycle });
  };

  const loader = isEmployeesLoading || isEmployeesFetching;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>

      <NotificationModal
        from="form"
        cycle={cycleData}
        isLoading={status === "loading" || isLoading}
        visible={isEmailDialogOpen}
        handleCancel={handleEmailDialogOpen}
        handleSubmit={handleCreateThreeSixtyFeedbackReviewCycle}
      />
      <FullScreenLayout
        source={{
          path: "/company/360-feedback",
          name: threeSixtyDegreeFeedback,
        }}
        title={`Create ${threeSixtyDegreeFeedback} Cycle.`}
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            <ThreeSixtyCycleForm
              mode="create"
              draftStep={cycleData?.draft_step || null}
              cycleData={cycleData}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={handleSaveAsDraft}
              setCycleData={setCycleData}
              handleDone={handleEmailDialogOpen}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
}

export default AddThreeSixtyFeedbackCycle;

import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Button, Row, Col, Grid } from "antd";

import { getIsMobile } from "app/appUtils";
import { ReactComponent as PointRight } from "assets/reviews/reviewer_nudge.svg";

const { Title, Text } = Typography;

const { useBreakpoint } = Grid;

const PlanCard = ({
  cardBackGroundColor = "white",
  className = "",
  plan = {},
  shouldShowHint = true,
  ctaText = "SELECT NOW",
  onSelect = () => {},
}) => {
  const screens = useBreakpoint();
  const title = plan?.title;
  const features = plan?.features;
  const hint = plan?.hint;
  const hintType = plan?.hintType;
  const ctaGhost = plan?.ctaGhost;
  const ctaDanger = plan?.ctaDanger;

  const isMobile = getIsMobile(screens);

  return (
    <Card
      className={className}
      style={{
        width: isMobile ? "100%" : "380px",
        backgroundColor: cardBackGroundColor,
      }}
    >
      <div className="flex-display flex-direction-column align-items-center">
        <Title level={3} className="mb-24">
          {title}
        </Title>
        <div style={{ width: "100%" }}>
          {features?.map((feature) => (
            <Text className="block-display">{feature}</Text>
          ))}
        </div>

        {shouldShowHint && (
          <Row className="mt-24 ">
            <Col span={2}>
              <PointRight />
            </Col>
            <Col offset={1} span={21}>
              <Text type={hintType}>{hint}</Text>
            </Col>
          </Row>
        )}

        <Button
          type={"primary"}
          ghost={ctaGhost}
          danger={ctaDanger}
          className="min-width-150-px mt-16"
          onClick={onSelect}
        >
          {ctaText}
        </Button>
      </div>
    </Card>
  );
};

PlanCard.propTypes = {
  className: PropTypes.string,
  plan: PropTypes.object,
};

export default PlanCard;

import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col } from "antd";

import { OKRTimePeriodSelect, TimePeriodSelect } from "components";
const { Title } = Typography;

const TimePeriodTitle = ({
  title = "",
  selectedTab = "okrs",
  periods = [],
  selectedPeriodId = null,
  handleSelectedPeriodChange = () => {},
}) => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title level={3}>{title}</Title>
      </Col>
      <Col>
        {selectedTab === "okrs" ? (
          <OKRTimePeriodSelect
            periods={periods}
            selectedPeriodId={selectedPeriodId}
            handleSelectedPeriodChange={handleSelectedPeriodChange}
          />
        ) : (
          <TimePeriodSelect
            periods={periods}
            selectedPeriodId={selectedPeriodId}
            handleSelectedPeriodChange={handleSelectedPeriodChange}
          />
        )}
      </Col>
    </Row>
  );
};

TimePeriodTitle.propTypes = {
  title: PropTypes.string,
};

export default TimePeriodTitle;

import React from "react";
import moment from "moment";
import { Typography, Button, Tooltip } from "antd";

import {
  EmployeeAvatarWithName,
  EmployeeAvatarGroup,
  EllipseText,
} from "components";
import { ReactComponent as LeftNudgePoint } from "assets/reviews/reviewee_nudge.svg";
import { formattedDate, getNameFromEmployeeObj } from "app/appUtils";

const { Text } = Typography;

const format = "YYYY-MM-DD";
const today = moment().format(format);

const getPeriods = () => {
  return [
    {
      id: "last-1-week",
      title: "Last Week",
      from: moment().subtract(1, "week").format(format),
      to: today,
    },
    {
      id: "last-30-days",
      title: "Last 30 Days",
      from: moment().subtract(1, "months").format(format),
      to: today,
    },
    {
      id: "current-month",
      title: "Current Month",
      from: moment().startOf("month").format(format),
      to: today,
    },
    {
      id: "last-month",
      title: "Last Month",
      from: moment().startOf("month").subtract(1, "month"),
      to: moment().endOf("month").subtract(1, "month"),
    },
    {
      id: "this-year",
      title: "This Year",
      from: moment().startOf("year").format(format),
      to: today,
    },
  ];
};

const getPendingFeedbackRequestsColumns = ({
  handleWriteRequest = () => {},
  handleDeclineRequest = () => {},
}) => {
  return [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (employee) => <EmployeeAvatarWithName employee={employee} />,
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (question) => <EllipseText text={question} />,
    },
    {
      title: "Requested On",
      dataIndex: "requested_on",
      key: "requested_on",
      render: (requested_on) => <Text>{formattedDate(requested_on)}</Text>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render: (_, row) => (
        <>
          <Button
            size="small"
            type="primary"
            ghost={true}
            onClick={() => {
              const writeRequest = {
                ...row,
                isRequestFeedback: true,
                request_user_id: row?.id,
              };
              delete writeRequest?.id;
              handleWriteRequest(writeRequest);
            }}
          >
            Write
          </Button>
          <Button
            className="ml-8"
            size="small"
            danger={true}
            ghost={true}
            onClick={() => {
              handleDeclineRequest(row?.id);
            }}
          >
            Decline
          </Button>
        </>
      ),
    },
  ];
};

const getIsAtLeastOneInPendingState = (requestedUsers = []) => {
  let retVal = false;
  requestedUsers.forEach((user) => {
    if (user?.status === "pending") {
      retVal = true;
    }
  });
  return retVal;
};

const getRaisedRequestsColumns = ({
  handleNudgeEmployees = () => {},
  handleIsViewFeedbackVisible = () => {},
  handleEditFeedback = () => {},
}) => {
  return [
    {
      title: "Employee",
      dataIndex: "employees",
      key: "employees",
      render: (employees, row) => (
        <div className="flex-display align-items-center">
          <EmployeeAvatarGroup employees={employees || []} />
          {getIsAtLeastOneInPendingState(row?.request_users) && (
            <Tooltip
              title={
                row?.nudged_on
                  ? `Nudged on ${formattedDate(row?.nudged_on)}`
                  : null
              }
            >
              <LeftNudgePoint
                className="ml-8 cursor-pointer"
                onClick={() => {
                  handleNudgeEmployees(row?.id);
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (question) => <EllipseText text={question} />,
    },
    {
      title: "Requested On",
      dataIndex: "requested_on",
      key: "requested_on",
      render: (requested_on) => <Text>{formattedDate(requested_on)}</Text>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render: (_, row) => (
        <>
          <Button
            size="small"
            type="primary"
            ghost={true}
            onClick={() => {
              handleEditFeedback({
                id: row?.id,
                employees: row?.employees,
                question: row?.question,
              });
            }}
          >
            Edit
          </Button>
          <Button
            className="ml-8"
            size="small"
            type="primary"
            ghost={true}
            onClick={() => {
              handleIsViewFeedbackVisible(row?.id);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];
};

const getPendingFeedbackRequestsDataSource = (pendingFeedbackRequests = []) => {
  return pendingFeedbackRequests?.map((requestObj) => ({
    id: requestObj?.id,
    employee: requestObj?.request?.requested_by,
    question: requestObj?.request?.question,
    request_user_id: requestObj?.id,
    requested_on: requestObj?.created_at,
  }));
};

const getRaisedRequestsDataSource = (raisedRequests = []) => {
  const formattedRaisedRequests = raisedRequests?.map((requestObj) => ({
    id: requestObj?.id,
    employees: requestObj?.request_users?.map(
      (requestUser) => requestUser?.employee
    ),
    request_users: requestObj?.request_users,
    question: requestObj?.question,
    requested_on: requestObj?.created_at,
    nudged_on: requestObj?.nudged_on,
  }));

  formattedRaisedRequests.sort((a, b) => {
    const aRequestedOn = a?.requested_on;
    const bRequestedOn = b?.requested_on;

    if (
      moment(aRequestedOn, "YYYY-MM-DDTHH:mm:ss.SSSZ").isAfter(
        moment(bRequestedOn, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return -1;
    }
    if (
      moment(aRequestedOn, "YYYY-MM-DDTHH:mm:ss.SSSZ").isBefore(
        moment(bRequestedOn, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return 1;
    }
    return 0;
  });

  return formattedRaisedRequests;
};

const getGroupOfRequesterNames = (emps = []) => {
  if (emps?.length === 0) {
    return "";
  }

  const firstEmpName = getNameFromEmployeeObj(emps[0]);
  if (emps?.length >= 2) {
    return `${firstEmpName} and ${emps?.length - 1} ${
      emps?.length === 2 ? "other" : "others"
    }`;
  }
  return firstEmpName;
};

export {
  getPeriods,
  getPendingFeedbackRequestsColumns,
  getRaisedRequestsColumns,
  getPendingFeedbackRequestsDataSource,
  getRaisedRequestsDataSource,
  getGroupOfRequesterNames,
};

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useQuery } from "react-query";
import { Row, Col, Drawer } from "antd";

import { BoxLoader } from "components";
import { useCurrentCompany } from "store";
import OKRForm from "../common/form/OKRForm";
import { getObjectiveApi } from "apis/okrV2Api";
import { errorNotification } from "app/appUtils";

const EditOKR = ({
  visible = false,
  okrId = null,
  onSave = () => {},
  onCancel = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  const { data, status, isLoading, isFetching } = useQuery(
    ["getObjective", okrId],
    getObjectiveApi,
    {
      cacheTime: 1,
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = status === "loading" || isLoading || isFetching;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Helmet>
        <title>OKRs - culture.easy</title>
      </Helmet>
      <Drawer
        title="Edit OKR"
        visible={visible}
        footer={null}
        width="80%"
        centered={true}
        onClose={onCancel}
      >
        {loader ? (
          <BoxLoader height="75vh" />
        ) : (
          <Row>
            <Col span={1} />
            <Col span={22}>
              <OKRForm
                mode="edit"
                initialValues={{
                  ...data,
                  owner_ids: data?.owners?.map((owner) => owner?.id),
                  currentOwners: data?.owners,
                  type_id:
                    data?.type === "IndividualObjective" ||
                    data?.type === "OrganizationObjective"
                      ? data?.type
                      : data?.team?.id,
                  objective_period_id: data?.objective_period?.id,
                  key_results: data?.key_results?.map((kr) => ({
                    ...kr,
                    owner_id: kr?.owner?.id,
                    currentOwner: kr?.owner,
                    is_deadline: Boolean(kr?.due_date),
                    due_date: kr?.due_date
                      ? moment(kr?.due_date, "YYYY-MM-DD")
                      : null,
                  })),
                  parent_objective_id: data?.parent_objective?.id,
                  parentObjective: data?.parent_objective,
                }}
                currentUserId={currentCompany?.user?.id}
                onSave={onSave}
                onCancel={onCancel}
              />
            </Col>
          </Row>
        )}
      </Drawer>
    </div>
  );
};

EditOKR.propTypes = {
  okrId: PropTypes.number,
};

export default EditOKR;

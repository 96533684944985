import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

const { Text } = Typography;

const ThreeSixtyStatusText = ({ status = "not_started" }) => {
  if (status === "not_started") {
    return <Text type="danger">Not Started</Text>;
  }
  if (status === "in_progress") {
    return <Text type="warning">In Progress</Text>;
  }
  return <Text type="success">Submitted</Text>;
};

ThreeSixtyStatusText.propTypes = {
  status: PropTypes.string,
};

export default ThreeSixtyStatusText;

import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import { Grid } from "antd";

import "./AppV3.less";
import "./App.css";
import Routes from "./Routes";
import firebaseApp from "firebaseApp";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { useCurrentUser, useCurrentCompany } from "store";
import PageSpinner from "components/PageSpinner";
import { setCookie } from "app/appUtils";

const { useBreakpoint } = Grid;

axios.defaults.baseURL =
  process.env.REACT_APP_REST_API_LOCATION || "http://localhost:8080";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "just now",
    ss: "%d seconds ago",
    m: "a minute ago",
    mm: "%d mins ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a day ago",
    dd: "%d days ago",
    w: "a week ago",
    ww: "%d weeks ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

axios.interceptors.request.use(async (config) => {
  try {
    if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
      const auth = getAuth(firebaseApp);
      const tokenResult = await auth.currentUser?.getIdTokenResult(false);
      if (tokenResult) {
        config.headers["Authorization"] = `Bearer ${tokenResult?.token}`;
      }
      const currentCompanyId = localStorage.getItem("currentCompanyId");
      if (currentCompanyId) {
        config.headers["X-WWW-GROUP-ID"] = currentCompanyId;
      }
    }
    return config;
  } catch (e) {
    console.error(e);
  }
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403 || error.response.status === 307) {
      window.location = "/access-denied";
    }
    if (error.response.status === 409) {
      window.location = "/feature-disabled";
    }
    return Promise.reject(error);
  }
);

const handleAddLinkedInConversionPixel = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const linkedInPixel = document.getElementById("linkedin-pixel");
    if (linkedInPixel) {
      linkedInPixel.remove();
    }

    const pixelElement = document.getElementById("linkedin-pixel-conversion");
    if (!pixelElement) {
      const linkedInImg = document.createElement("img");
      linkedInImg.id = "linkedin-pixel-conversion";
      linkedInImg.height = "1";
      linkedInImg.width = "1";
      linkedInImg.style.cssText = "display:none;";
      linkedInImg.alt = "";
      linkedInImg.src =
        "https://px.ads.linkedin.com/collect/?pid=2845156&conversionId=3502124&fmt=gif";
      document.body.appendChild(linkedInImg);
    }
  }
};

function App() {
  const [isFetchingUser, setIsFetchingUser] = useState(
    process.env.REACT_APP_MOCK_ENV !== "mirage-start"
  );
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [currentCompany] = useCurrentCompany();

  const screens = useBreakpoint();

  const auth = getAuth(firebaseApp);

  useEffect(() => {
    if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
      onAuthStateChanged(auth, (user) => {
        try {
          setCurrentUser(user);
          setIsFetchingUser(false);
          window.globalCurrentUserEmail = user?.email;
          if (user?.email !== "culture-demo@easy.xyz" && user?.email) {
            handleAddLinkedInConversionPixel();
            if (process.env.REACT_APP_ENVIRONMENT === "production") {
              setCookie("t_he", user?.email, 365);
            }
          }
        } catch (error) {
          console.log(error);
          setIsFetchingUser(false);
        }
      });
    } else {
      setCurrentUser({
        displayName: "Mock user",
        email: "mock.email@easy.xyz",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUserEmail = currentUser?.email;
  const currentCompanyId = currentCompany?.id;
  useEffect(() => {
    if (currentUserEmail && currentCompanyId) {
      Sentry.setContext("character", {
        email: currentUserEmail,
        "org-id": currentCompanyId,
      });

      if (process.env.REACT_APP_ENVIRONMENT) {
        window.FreshworksWidget("identify", "ticketForm", {
          name: currentCompany?.user?.name,
          email: currentUserEmail,
        });
        window.FreshworksWidget("disable", "ticketForm", ["name", "email"]);
      }
    }
    // eslint-disable-next-line
  }, [currentUserEmail, currentCompanyId]);

  const screenSizes = Object.values(screens);
  useEffect(() => {
    const freshWorksContainerDiv = document.getElementById(
      "freshworks-container"
    );

    if (freshWorksContainerDiv && freshWorksContainerDiv.style)
      if (screens?.md) {
        freshWorksContainerDiv.style.display = "block";
      } else {
        freshWorksContainerDiv.style.display = "none";
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSizes]);

  if (process.env.REACT_APP_MOCK_ENV !== "mirage-start") {
    onIdTokenChanged(auth, async (user) => {
      const newToken = (await user?.getIdToken(false)) || "";
      const currentToken = (await currentUser?.getIdToken(false)) || "";
      if (user && newToken !== currentToken) {
        setCurrentUser(user);
      }
    });
  }

  if (isFetchingUser) {
    return <PageSpinner />;
  }

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;

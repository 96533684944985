import React from "react";
import PropTypes from "prop-types";
import { Modal, Tabs } from "antd";

import ItemTitle from "../ItemTitle";
import ItemActivity from "./ItemActivity";
import ItemComments from "./ItemComments";

const { TabPane } = Tabs;

const ActivityCommentsModal = ({
  visible,
  defaultSelectedTab = "comments",
  objectiveId = "",
  type = "",
  keyResult = {},
  initiative = {},
  commentsCount = 0,
  onCancel = () => {},
  handleUpdateCommentsCount = () => {},
}) => {
  const isKeyResult = type === "key_result";

  return (
    <>
      <Modal
        visible={visible}
        width="750px"
        title={
          <ItemTitle
            ellipsis={true}
            type={type}
            keyResult={keyResult}
            initiative={initiative}
          />
        }
        onCancel={onCancel}
        footer={null}
      >
        <Tabs defaultActiveKey={defaultSelectedTab}>
          <TabPane key="comments" tab={`Comments (${commentsCount})`}>
            <ItemComments
              isKeyResult={isKeyResult}
              objectiveId={objectiveId}
              initiativeId={initiative?.id}
              keyResultId={keyResult?.id}
              handleUpdateCommentsCount={handleUpdateCommentsCount}
            />
          </TabPane>
          <TabPane key="activity" tab="Activity Log">
            <ItemActivity
              isKeyResult={isKeyResult}
              objectiveId={objectiveId}
              keyResultId={keyResult?.id}
              initiativeId={initiative?.id}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

ActivityCommentsModal.propTypes = {
  objectiveId: PropTypes.number,
  keyResultId: PropTypes.number,
  keyResultComments: PropTypes.array,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  handleUpdateComments: PropTypes.func,
};

export default ActivityCommentsModal;

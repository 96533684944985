import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { useMutation } from "react-query";
import { Empty } from "antd";

import { EmployeeSearchSelect, InlineSpinner } from "components";
import { searchEmployeesApi } from "apis/commonApi";

const APIBasedEmployeeSearch = ({
  disabled = false,
  value,
  shouldShowAvatar = false,
  shouldSendSelectedEmployee = false,
  shouldShowInactiveEmployees = false,
  currentOwners = [], // Can be {} if mode is single
  onChange = () => {},
  setAvailableEmployees = () => {},
  searchApi = searchEmployeesApi,
  ...props
}) => {
  const isMultiple = props?.mode === "multiple";

  const [selectedOwners, setSelectedOwners] = useState(currentOwners);
  const [isLessThan3Char, setIsLessThan3Char] = useState(true);

  useEffect(() => {
    if (currentOwners) {
      setSelectedOwners(currentOwners);
    }
  }, [currentOwners]);

  const [
    searchEmployees,
    { data: searchedEmployees, isLoading: isLoadingEmployees },
  ] = useMutation(searchApi, {
    onSuccess: (data) => {
      if (props?.mode === "multiple") {
        setAvailableEmployees([...data, ...currentOwners]);
      }
      return data;
    },
  });

  const debounceTimeOut = 800;

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value?.length >= 3) {
        searchEmployees({ searchTerm: value });
        setIsLessThan3Char(false);
      } else {
        setIsLessThan3Char(true);
      }
    };

    return debounce(loadOptions, debounceTimeOut);
  }, [searchEmployees, debounceTimeOut]);

  const handleChange = (val, selectedEmployee) => {
    if (isMultiple) {
      // Remove un-selected owners
      const updatedOwners = selectedOwners?.filter((owner) =>
        val?.includes(owner?.id)
      );
      const updatedOwnerIds = updatedOwners?.map((owner) => owner?.id);

      // Add newly selected owners
      const newOwnerIds = val?.filter(
        (ownerId) => !updatedOwnerIds?.includes(ownerId)
      );
      const newOwners =
        searchedEmployees?.filter((emp) => newOwnerIds?.includes(emp?.id)) ||
        [];

      // merge
      setSelectedOwners([...updatedOwners, ...newOwners]);
    }

    if (shouldSendSelectedEmployee) {
      onChange(val, selectedEmployee?.children?.props?.employee || {});
    } else {
      onChange(val);
    }
  };

  let employees = [];
  let inActiveEmployees = [];

  if (isMultiple) {
    const activeSelectedOwners =
      selectedOwners?.filter((owner) => owner?.active) || [];
    const inActiveSelectedOwners =
      selectedOwners?.filter((owner) => !owner?.active) || [];

    const selectedOwnerIds = selectedOwners?.map((owner) => owner?.id) || [];

    const activeSearchedEmployees =
      searchedEmployees?.filter(
        (owner) => owner?.active && !selectedOwnerIds?.includes(owner?.id)
      ) || [];

    const inActiveSearchedEmployees =
      searchedEmployees?.filter(
        (owner) => !owner?.active && !selectedOwnerIds?.includes(owner?.id)
      ) || [];

    employees = [...activeSelectedOwners, ...activeSearchedEmployees];
    inActiveEmployees = [
      ...inActiveSelectedOwners,
      ...inActiveSearchedEmployees,
    ];
  } else {
    const activeSearchedEmployees =
      searchedEmployees?.filter((owner) => owner?.active) || [];

    const inActiveSearchedEmployees =
      searchedEmployees?.filter((owner) => !owner?.active) || [];

    employees = [...activeSearchedEmployees];
    inActiveEmployees = [...inActiveSearchedEmployees];

    const isSelectedOwnerActive = selectedOwners?.active;
    if (isSelectedOwnerActive) {
      employees.push(selectedOwners);
    } else {
      inActiveEmployees.push(selectedOwners);
    }
  }

  return (
    <EmployeeSearchSelect
      disabled={disabled}
      value={value}
      onChange={handleChange}
      shouldShowAvatar={shouldShowAvatar}
      employees={employees}
      inActiveEmployees={inActiveEmployees}
      // filterOption={false}
      onSearch={debounceFetcher}
      shouldShowInactiveEmployees={shouldShowInactiveEmployees}
      notFoundContent={
        isLoadingEmployees ? (
          <InlineSpinner size="small" />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              isLessThan3Char ? "Please type at least 3 characters" : "No Data"
            }
          />
        )
      }
      {...props}
    />
  );
};

APIBasedEmployeeSearch.propTypes = {
  defaultValue: PropTypes.number,
  employees: PropTypes.array,
};

export default APIBasedEmployeeSearch;

import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row } from "antd";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import { useCurrentCompany } from "store";
import { plans, planNames } from "app/appConstants";
import { updatePlanApi } from "apis/billingApi";
import { errorNotification, successNotification } from "app/appUtils";
import PlanCard from "./PlanCard";

const PlanCardsRow = ({
  className = "",
  cardBackGroundColor = "white",
  onUpgrade = () => {},
  refetch = () => {},
}) => {
  const [currentCompany, setCurrentCompany] = useCurrentCompany();

  const { activeEmployees, isEmployeesLoading } = useEmployees();

  const [updatePlan, { isLoading: updateLoading }] = useMutation(
    updatePlanApi,
    {
      onSuccess: () => {
        successNotification("Successfully updated plan.");
        refetch();
        setCurrentCompany({ ...currentCompany, trial_days_remaining: null });
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleUpdate = (name) => {
    updatePlan({ planName: name });
  };

  return (
    <Row className={className}>
      {(isEmployeesLoading || updateLoading) && <BoxLoader />}
      {plans?.map((plan, index) => {
        if (plan?.id === planNames?.earlyAdopter) {
          return null;
        }

        return (
          <PlanCard
            cardBackGroundColor={cardBackGroundColor}
            className={index !== 0 ? "ml-72" : ""}
            plan={{
              ...plan,
              hint:
                index === 0
                  ? `You have ${activeEmployees?.length} active users and ${
                      activeEmployees?.length - 5
                    } will loose access if you select this plan.`
                  : `Recommended plan since you have ${activeEmployees?.length} active users.`,
              ctaGhost:
                index === 0 ? activeEmployees?.length > 5 : plan?.ctaGhost,
            }}
            shouldShowHint={activeEmployees?.length > 5}
            onSelect={
              index === 0
                ? () => {
                    handleUpdate(planNames?.free);
                  }
                : onUpgrade
            }
          />
        );
      })}
    </Row>
  );
};

PlanCardsRow.propTypes = {
  className: PropTypes.string,
};

export default PlanCardsRow;

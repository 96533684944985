import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Modal, Row, Typography } from "antd";

import { BoxLoader, DoubleLineChart } from "components";
import {
  getActualChartData,
  getExpectedDateObjects,
  getAllDatesInSelectedPeriod,
} from "../utils";
import { getSummaryApi } from "apis/okrV2Api";

const { Text } = Typography;

const ObjectiveSummaryModal = ({
  objectiveId = "",
  visible = false,
  selectedPeriodObj = {},
  onCancel = () => {},
}) => {
  const [getSummary, { data, isLoading }] = useMutation(getSummaryApi);

  useEffect(() => {
    getSummary({ period_id: selectedPeriodObj?.id, objective_id: objectiveId });
  }, [getSummary, objectiveId, selectedPeriodObj.id]);

  const allDates = getAllDatesInSelectedPeriod(selectedPeriodObj);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title="Objective Summary"
        visible={visible}
        footer={null}
        onCancel={onCancel}
      >
        {isLoading ? (
          <BoxLoader />
        ) : (
          <>
            <Row justify="center" align="middle">
              <DoubleLineChart
                series={[
                  {
                    name: "Expected",
                    data: getExpectedDateObjects(allDates, selectedPeriodObj),
                  },
                  {
                    name: "Actual",
                    data: getActualChartData(allDates, data),
                  },
                ]}
                xAxisCategories={allDates}
              />
            </Row>
            <Row justify="center" align="middle">
              <Text type="secondary">
                Hover over the graph for more details
              </Text>
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};

ObjectiveSummaryModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ObjectiveSummaryModal;

import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { Tabs, Typography } from "antd";

import { DisabledFeature } from "components";
import { useCurrentCompany } from "store";
import OKRInsights from "./OKRInsights";
import KPIInsights from "./KPIInsights";
import { getKPIFeatureObj } from "app/appUtils";

const { Title } = Typography;
const { TabPane } = Tabs;

const Insights = (props) => {
  const history = useHistory();
  const { tab = "okr-insights" } = useParams();

  const [currentCompany] = useCurrentCompany();
  const [kpiFeature, setKpiFeature] = useState(null);

  const features = currentCompany?.features || [];
  useEffect(() => {
    const kpiFeature = getKPIFeatureObj(features);
    setKpiFeature(kpiFeature);
  }, [features]);

  const handleChange = (activeKey) => {
    history.push(`/insights/${activeKey}`);
  };

  const isKpiFeatureEnabled = kpiFeature?.enabled;

  return (
    <>
      <Title level={2}>Insights</Title>
      <Tabs activeKey={tab} onChange={handleChange}>
        <TabPane tab="OKR Insights" key="okr-insights">
          <OKRInsights />
        </TabPane>
        <TabPane tab="KPI Insights" key="kpi-insights">
          {isKpiFeatureEnabled ? <KPIInsights /> : <DisabledFeature />}
        </TabPane>
      </Tabs>
    </>
  );
};

Insights.propTypes = {};

export default Insights;

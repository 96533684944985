import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { Modal, Card } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import SurveyForm from "../common/form-components/SurveyForm";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { getSurveyDetailsApi, createSurveyApi } from "apis/surveyApi";
import { errorNotification, successNotification } from "app/appUtils";

const { confirm } = Modal;

const EditSurvey = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [surveyDetails, setSurveyDetails] = useState(null);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const { status, isLoading, isFetching } = useQuery(
    ["getSurveyDetails", id],
    getSurveyDetailsApi,
    {
      onSuccess: (data) => {
        const details = {
          ...data,
          due_date: moment(data?.due_date, "YYYY-MM-DD"),
          for_entire_company: data?.for_entire_company ? true : false,
          participants: data.survey_participants.map(
            (participation) => participation.employee.id
          ),
          selectedTemplate: {
            name: "Edit Questionnaire",
            questions: [],
          },
        };
        delete details.survey_participants;
        setSurveyDetails({ ...details });
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  // const [updateSurvey] = useMutation(updateSurveyApi, {
  //   onSuccess: () => {
  //     successNotification("Survey updated successfully");
  //     history.push("/company/surveys");
  //   },
  //   onError: (error) => {
  //     errorNotification(error);
  //   },
  // });

  const [updateSurvey, { isLoading: isSaving }] = useMutation(createSurveyApi, {
    onSuccess: () => {
      successNotification("Survey updated");
      history.push("/company/surveys");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleSubmit = (editedSurvey) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will updated the survey (${surveyDetails?.name || ""}).`,
      onOk() {
        const updatedSurvey = {
          id,
          ...editedSurvey,
          due_date: editedSurvey.due_date?.format("YYYY-MM-DD"),
        };
        delete updatedSurvey.employees;
        updateSurvey(updatedSurvey);
      },
      okText: "Update",
      onCancel() {},
    });
  };

  const loader =
    status === "loading" || isFetching || isLoading || isEmployeesLoading;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      <FullScreenLayout
        source={{ path: "/company/surveys", name: "Surveys" }}
        title="Edit Survey"
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            <SurveyForm
              mode="edit"
              isSaving={isSaving}
              survey={surveyDetails}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={() => {}}
              setSurvey={setSurveyDetails}
              handleDone={handleSubmit}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

EditSurvey.propTypes = {};

export default EditSurvey;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import { unparse } from "papaparse";
import {
  PageHeader,
  Input,
  Button,
  Table,
  Card,
  Row,
  Col,
  Modal,
  Typography,
  Radio,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "react-sortable-tree/style.css";

import { useCurrentCompany, useCurrentUser } from "store";
import {
  ButtonWithSpinner,
  EditEmployeeNameModal,
  InlineSpinner,
} from "components";
import { useEmployees } from "hooks";
import DirectoryNavBar from "./common/DirectoryNavBar";
// import CompanyNameInput from "./common/CompanyNameInput";
import EditManagerModel from "./common/EditManagerModel";
import ChangeInactiveManagerModal from "./common/ChangeInactiveManagerModal";
import { prepareColumns } from "./common/utils";
import {
  errorNotification,
  successNotification,
  getNameFromEmployeeObj,
} from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";
import { addAdminApi, removeAdminApi } from "apis/adminApi";
import { updateEmployeeApi } from "apis/commonApi";

import { BoxLoader } from "components";
import DirectoryTreeViewV2 from "./tree-view/DirectoryTreeViewV2";

// import styles from "./Directory.module.css";

const { Title, Text } = Typography;
const { confirm } = Modal;

function Directory() {
  const [currentCompany, setCurrentCompany] = useCurrentCompany();
  const [currentUser] = useCurrentUser();

  const [view, setView] = useState("list");
  const [isDownloading, setIsDownloading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [updateEmployeeManager, setUpdateEmployeeManager] = useState(null);
  const [employeeToMarkInactive, setEmployeeToMarkInactive] = useState(null);

  const {
    allEmployeesHash,
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
    isEmployeesFetching,
    refetch,
  } = useEmployees();

  useEffect(() => {
    if (activeEmployees?.length > 0) {
      setEmployees(activeEmployees);
    }
  }, [activeEmployees]);

  const [
    addAdmin,
    {
      status: addAdminStatus,
      isLoading: addAdminLoading,
      isFetching: addAdminFetching,
    },
  ] = useMutation(addAdminApi, {
    onSuccess: () => {
      refetch();
      successNotification("Admin added Successfully");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    removeAdmin,
    {
      status: removeAdminStatus,
      isLoading: removeAdminLoading,
      isFetching: removeAdminFetching,
    },
  ] = useMutation(removeAdminApi, {
    onSuccess: () => {
      refetch();
      successNotification("Admin removed Successfully");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    updateEmployee,
    {
      status: updateEmployeeStatus,
      isLoading: updateEmployeeLoading,
      isFetching: updateEmployeeFetching,
    },
  ] = useMutation(updateEmployeeApi, {
    onSuccess: (data) => {
      refetch();
      successNotification("Employee Updated Successfully");
      if (currentUser.email === data?.email) {
        const currentCompanyUser = { ...currentCompany?.user };
        setCurrentCompany({
          ...currentCompany,
          user: { ...currentCompanyUser, name: data?.name },
        });
      }
    },
    onError: (error, variables, context) => {
      errorNotification(error);
    },
  });

  const handleEditEmployeeDetails = (employee) => {
    setSelectedEmployee(employee);
    setIsEditModalVisible(true);
  };

  const handleEmployeeNameChange = (
    employee,
    {
      first_name,
      last_name,
      email,
      department,
      sub_department,
      designation,
      company_employee_id,
    }
  ) => {
    updateEmployee({
      employeeId: employee.id,
      employee: {
        first_name,
        last_name,
        email,
        department,
        sub_department,
        designation,
        company_employee_id,
      },
    });
    setIsEditModalVisible(false);
  };

  const handleMarkInActiveSubmit = ({ employee = {} }) => {
    updateEmployee({
      employeeId: employee?.id,
      employee: {
        active: !employee?.active,
      },
    });
    handleIsMarkingInactive(null);
  };

  const handleIsMarkingInactive = (employee = null) => {
    setEmployeeToMarkInactive(employee);
  };

  const handleAddAsAdmin = (employee) => {
    confirm({
      title: "Add Admin",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Are you sure you want to add</Text>
          <Text strong>{` ${getNameFromEmployeeObj(employee)} `}</Text>
          <Text>{`as an admin?`}</Text>
        </>
      ),
      onOk() {
        addAdmin({ userEmail: employee?.email });
      },
      width: "1000px",
      okText: "Yes",
      // okButtonProps: { danger: true },
      cancelText: "Cancel",
      onCancel() {},
    });
  };

  const handleRemoveAsAdmin = (employee) => {
    confirm({
      title: "Remove Admin",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Are you sure you want to remove</Text>
          <Text strong>{` ${getNameFromEmployeeObj(employee)} `}</Text>
          <Text>{`as an admin?`}</Text>
        </>
      ),
      onOk() {
        removeAdmin({ userEmail: employee?.email });
      },
      width: "1000px",
      okText: "Yes",
      okButtonProps: { danger: true },
      cancelText: "Cancel",
      onCancel() {},
    });
  };

  const searchEmployees = (e) => {
    let value = e.target.value;

    if (!value) {
      setEmployees(activeEmployees || []);
    }

    setEmployees(
      activeEmployees.filter(
        (employee) =>
          employee?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          (employee.manager_id
            ? allEmployeesHash[employee?.manager_id]?.name
                ?.toLowerCase()
                ?.includes(value.toLowerCase())
            : false)
      )
    );
  };

  const handleViewChange = (e) => {
    if (e?.target?.value === "list") {
      setEmployees(activeEmployees || []);
    }

    setView(e.target.value);
  };

  const handleUpdateManager = ({ employeeId, managerId }) => {
    updateEmployee({
      employeeId,
      employee: {
        manager_id: managerId || null,
      },
    });
    setUpdateEmployeeManager(null);
  };

  const handleUpdateEmployeeManager = (emp = null) => {
    setUpdateEmployeeManager(emp);
  };

  const handleExportEmployeeDetails = () => {
    setIsDownloading(true);

    const allData = [...activeEmployees, ...inActiveEmployees]?.map(
      (emp, index) => ({
        "Sl. No": index + 1,
        "First Name": emp?.first_name || "",
        "Last Name": emp?.last_name || "",
        Active: emp?.active ? "Yes" : "No",
        "Employee Code": emp?.company_employee_id || "",
        Department: emp?.department || "",
        Designation: emp?.designation || "",
        Email: emp?.email || "",
        "Manager Name":
          getNameFromEmployeeObj(allEmployeesHash[emp?.manager_id]) || "",
        "Sub Department": emp?.sub_department || "",
      })
    );

    const csvData = unparse(allData, {
      columns: [
        "Sl. No",
        "First Name",
        "Last Name",
        "Email",
        "Active",
        "Employee Code",
        "Designation",
        "Department",
        "Sub Department",
        "Manager Name",
      ],
    });

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, "Employees");
    setIsDownloading(false);
  };

  const loader = isEmployeesLoading;

  const isAddingAdmin =
    addAdminStatus === "loading" || addAdminLoading || addAdminFetching;

  const isRemovingAdmin =
    removeAdminStatus === "loading" ||
    removeAdminLoading ||
    removeAdminFetching;

  const updatingEmployee =
    updateEmployeeStatus === "loading" ||
    updateEmployeeLoading ||
    updateEmployeeFetching;

  const columns = prepareColumns(
    currentUser,
    allEmployeesHash,
    handleEditEmployeeDetails,
    handleUpdateEmployeeManager,
    handleIsMarkingInactive,
    handleAddAsAdmin,
    handleRemoveAsAdmin
  );

  return (
    <>
      <Helmet>
        <title>Settings - culture.easy</title>
      </Helmet>

      {isEditModalVisible && (
        <EditEmployeeNameModal
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onUpdate={handleEmployeeNameChange}
          employee={selectedEmployee}
        />
      )}

      {Boolean(updateEmployeeManager) && (
        <EditManagerModel
          visible={Boolean(updateEmployeeManager)}
          employee={updateEmployeeManager}
          activeEmployees={activeEmployees}
          inActiveEmployees={inActiveEmployees}
          updatingEmployee={updatingEmployee}
          onSubmit={handleUpdateManager}
          onCancel={() => handleUpdateEmployeeManager(null)}
        />
      )}

      {Boolean(employeeToMarkInactive) && (
        <ChangeInactiveManagerModal
          visible={Boolean(employeeToMarkInactive)}
          employee={employeeToMarkInactive}
          activeEmployees={activeEmployees}
          onSubmit={handleMarkInActiveSubmit}
          onCancel={() => handleIsMarkingInactive(null)}
        />
      )}

      <PageHeader
        className="directory-page-header pl-0 pt-0 pr-0"
        title={<Title level={2}>People</Title>}
        // onBack={() => history.push("/settings")}
        extra={[
          <Link
            to={{
              pathname: "/settings/directory/new-employee",
              source: { path: "/settings/directory" },
            }}
          >
            <Button type="primary">Add People</Button>
          </Link>,
        ]}
      />

      <DirectoryNavBar />
      {loader ? (
        <BoxLoader />
      ) : (
        <>
          {/* <CompanyNameInput /> */}
          <Card>
            <Row
              justify="space-between"
              className="mb-8"
              gutter={[{ xs: 8 }, { xs: 8 }]}
            >
              <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <Radio.Group
                  className="mr-8"
                  onChange={handleViewChange}
                  defaultValue="list"
                >
                  <Radio.Button value="list">List View</Radio.Button>
                  <Radio.Button value="tree">Tree View</Radio.Button>
                </Radio.Group>
                {isEmployeesFetching && <InlineSpinner />}
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                {view === "list" && (
                  <Input
                    placeholder="Search for Employee or Manager"
                    onChange={searchEmployees}
                    prefix={<SearchOutlined />}
                  />
                )}
              </Col>
            </Row>

            {view === "list" ? (
              <>
                <Title className="mt-16" level={4}>
                  {`Active Employees (${employees?.length || 0})`}
                </Title>
                <Table
                  loading={updatingEmployee || isAddingAdmin || isRemovingAdmin}
                  columns={columns}
                  dataSource={employees}
                  scroll={mobileTableScroll}
                  pagination={{
                    hideOnSinglePage: true,
                    size: "small",
                  }}
                />

                {inActiveEmployees?.length > 0 && (
                  <>
                    <Title className="mt-16" level={4}>
                      {`Inactive Employees (${inActiveEmployees?.length || 0})`}
                    </Title>
                    <Table
                      loading={updatingEmployee}
                      columns={columns}
                      dataSource={inActiveEmployees || []}
                      scroll={mobileTableScroll}
                      pagination={{
                        hideOnSinglePage: true,
                        size: "small",
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <DirectoryTreeViewV2
                isEmployeesFetching={isEmployeesFetching}
                updatingEmployee={updatingEmployee}
                employees={activeEmployees?.map((emp) => ({
                  ...emp,
                  key: emp?.id,
                }))}
                inActiveEmployees={inActiveEmployees}
                updateEmployee={updateEmployee}
                addAdmin={addAdmin}
                removeAdmin={removeAdmin}
              />
            )}

            <ButtonWithSpinner
              className="mt-16"
              type="primary"
              ghost={true}
              isSpinning={isDownloading}
              disabled={isDownloading}
              spinnerColor={"#5330C9"}
              onClick={handleExportEmployeeDetails}
            >
              Download All Employee Details
            </ButtonWithSpinner>
          </Card>
        </>
      )}
    </>
  );
}

export default Directory;

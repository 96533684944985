import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "./VerticalTreeChart.css";

import { EmployeeAvatarWithName } from "components";

function VerticalTreeNodeContent({ node }) {
  const [showChildren, setShowChildren] = useState(true);
  return (
    <>
      <div className="node_content">
        <div>
          <EmployeeAvatarWithName employee={node} />
          <div>
            {node.children && (
              <Button
                basic
                size="small"
                color="black"
                onClick={() => setShowChildren(!showChildren)}
              >
                <>
                  {node.children.length}{" "}
                  {showChildren ? <UpOutlined /> : <DownOutlined />}
                </>
              </Button>
            )}
          </div>
        </div>
      </div>
      {node.children && showChildren && (
        <ul className="v_tree-ul">
          <VerticalTreeNode children={node.children} />
        </ul>
      )}
    </>
  );
}
VerticalTreeNodeContent.propTypes = {
  node: PropTypes.objectOf(PropTypes.any).isRequired,
};

function VerticalTreeNode({ children }) {
  return (
    <>
      {children.map((node) => {
        return (
          <li className="v_tree-li">
            <VerticalTreeNodeContent node={node} />
          </li>
        );
      })}
    </>
  );
}
VerticalTreeNode.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function VerticalTreeChart({ treeData }) {
  console.log({ treeData });
  return (
    <div className="v_tree">
      <ul className="v_tree_ul">
        <VerticalTreeNode children={treeData} />
      </ul>
    </div>
  );
}
VerticalTreeChart.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VerticalTreeChart;

import React from "react";
import { useQuery } from "react-query";
import { Row, Dropdown, Menu, Typography, Space, Button } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  CaretLeftOutlined,
} from "@ant-design/icons";

import {
  EmployeeAvatarWithName,
  InlineSpinner,
  // PerfReviewNudge,
  PerfReviewDownloadAssessment,
  OptionsPopover,
} from "components";
import StatusText from "./components/StatusText";
import ActionBtn from "./components/ActionBtn";
import { appealed, appealRejected, appealAccepted } from "../reviewConstants";
import { getReviewDetailsApi } from "apis/perfReviewApi";
import { errorNotification } from "app/appUtils";

const { Text } = Typography;

const statusMap = {
  not_started: "Not Started",
  started: "In Progress",
  submitted: "Submitted",
};

const getCountsFromRow = (row) => {
  let submittedCount = 0;
  let peerSubmitCount = 0;

  submittedCount +=
    row?.reviewee_status === "Signed" || row?.reviewee_status === "Submitted"
      ? 1
      : 0;

  submittedCount +=
    row?.reviewer_status === "Signed" || row?.reviewer_status === "Submitted"
      ? 1
      : 0;

  row.peers.forEach((peer) => {
    peerSubmitCount += peer?.status === "Submitted" ? 1 : 0;
  });

  let totalCount = row?.peers?.length + 1;
  let revieweeReviewerCount = 1;
  if (Boolean(row?.reviewer)) {
    totalCount += 1;
    revieweeReviewerCount += 1;
  }

  return { submittedCount, peerSubmitCount, totalCount, revieweeReviewerCount };
};

const getStatus = (text, row) => {
  const revieweeStatus = row?.reviewee_status;
  if (
    revieweeStatus === appealed ||
    revieweeStatus === appealRejected ||
    revieweeStatus === appealAccepted
  ) {
    return revieweeStatus;
  }

  const { submittedCount, revieweeReviewerCount } = getCountsFromRow(row);

  if (submittedCount === revieweeReviewerCount) {
    return "Done";
  }
  return "In Progress";
};

const Options = ({ rowId }) => {
  const { data: reviewData = {}, status } = useQuery(
    ["reviewDetails", rowId],
    getReviewDetailsApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = status === "loading";

  return (
    <Dropdown
      trigger="click"
      placement="topCenter"
      overlay={
        <Menu>
          <Menu.Item key="download" disabled={loader}>
            {loader ? (
              <>
                <InlineSpinner fontSize={20} /> <Text>Fetching Assessment</Text>
              </>
            ) : (
              <PerfReviewDownloadAssessment
                reviewId={rowId}
                type="reviewer"
                reviewData={reviewData}
                downloadClassName="color-text-color"
              />
            )}
          </Menu.Item>
        </Menu>
      }
    >
      <MoreOutlined />
    </Dropdown>
  );
};

const optionsColumn = {
  title: "",
  width: 10,
  render: (text, row) => <Options rowId={row?.id} />,
};

const getColumns = ({
  type = "self",
  disabled = false,
  review = {},
  expandedRowKeys = [],
  handleEditReviewerModalOpen = () => {},
  handleNudgingTable = () => {},
  handleNudgeSuccess = () => {},
  handleAddPeers = () => {},
  handleFinalize = () => {},
  handleViewAssessment = () => {},
  handleReset = () => {},
  handleDeleteReview = () => {},
  handleAcceptAppeal = () => {},
  handleRejectAppeal = () => {},
  handleSubmitAsReviewer = () => {},
  setExpandedRowKeys = () => {},
}) => {
  // const handleIsNudging = (bool) => {
  //   if (bool) {
  //     handleNudgingTable(type);
  //   } else {
  //     handleNudgingTable();
  //   }
  // };

  // const handleSuccess = (data) => {
  //   handleNudgeSuccess(data, type);
  // };

  switch (type) {
    case "finalize-peers-table":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj) => <EmployeeAvatarWithName employee={obj} />,
        },
        {
          title: "Peers Added",
          dataIndex: "peers_added",
          key: "peers_added",
          render: (_, row) => <Text>{`${row?.peers?.length} Peers`}</Text>,
        },
        {
          title: "Status",
          dataIndex: "reviewee_status",
          key: "reviewee_status",
          render: (_, row) => (
            <Text>
              {row?.peer_selection_finalized ? "Finalized" : "In Progress"}
            </Text>
          ),
        },
        {
          title: "Actions",
          render: (text, row) => (
            <>
              {!row?.peer_selection_finalized ? (
                <>
                  <Button
                    className="pl-0"
                    type="link"
                    onClick={() => {
                      handleAddPeers(row);
                    }}
                  >
                    Add Peers
                  </Button>
                  <Button type="link" onClick={() => handleFinalize(row)}>
                    Finalize
                  </Button>
                </>
              ) : (
                <Text>-</Text>
              )}
            </>
          ),
        },
        // {
        //   ...optionsColumn,
        // },
      ];
    case "self":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj) => <EmployeeAvatarWithName employee={obj} />,
        },
        {
          title: "Manager",
          dataIndex: "reviewer",
          key: "reviewer",
          render: (obj, row) => (
            <Row align="middle">
              {/* <PerfReviewNudge
                type="reviewer"
                employee={obj}
                reviewId={row?.id}
                nudgedAt={row?.reviewer_nudge_at}
                reviewCycle={review?.review_cycle}
                status={row?.reviewee_status}
                otherStatus={row?.reviewer_status}
                setIsNudging={handleIsNudging}
                onSuccess={handleSuccess}
              /> */}
              <EmployeeAvatarWithName
                emptyText="No Manager"
                className="ml-8"
                employee={obj || {}}
              />
            </Row>
          ),
        },
        {
          title: "Review Progress",
          dataIndex: "reviewee_progress",
          key: "reviewee_progress",
          render: (text, row) => {
            const { submittedCount, peerSubmitCount, totalCount } =
              getCountsFromRow(row);
            const totalCompleteCount = submittedCount + peerSubmitCount || 0;

            const isRowExpanded = expandedRowKeys?.includes(row?.id);
            return (
              <>
                {`${totalCompleteCount} / ${totalCount} reviews submitted`}
                <CaretLeftOutlined
                  className="ml-8"
                  rotate={isRowExpanded ? -90 : 0}
                  onClick={(e) => {
                    if (isRowExpanded) {
                      setExpandedRowKeys(
                        expandedRowKeys?.filter((rowKey) => rowKey !== row?.id)
                      );
                    } else {
                      setExpandedRowKeys([...expandedRowKeys, row?.id]);
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, row) => {
            return getStatus(text, row);
          },
        },
        // {
        //   title: "My Status",
        //   dataIndex: "reviewee_status",
        //   key: "reviewee_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        // {
        //   title: "Manager Status",
        //   dataIndex: "reviewer_status",
        //   key: "reviewer_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        {
          title: "Actions",
          render: (text, row) => (
            <ActionBtn
              type="reviewee"
              rowId={row?.id}
              row={row}
              review={review}
              revieweeStatus={row?.reviewee_status}
              reviewerStatus={row?.reviewer_status}
            />
          ),
        },
        {
          ...optionsColumn,
        },
      ];
    case "direct":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj, row) => (
            <Row align="middle">
              <EmployeeAvatarWithName className="mr-8" employee={obj} />
              {/* <PerfReviewNudge
                type="reviewee"
                employee={obj}
                reviewId={row?.id}
                nudgedAt={row?.reviewee_nudge_at}
                reviewCycle={review?.review_cycle}
                status={row?.reviewer_status}
                otherStatus={row?.reviewee_status}
                setIsNudging={handleIsNudging}
                onSuccess={handleSuccess}
              /> */}
            </Row>
          ),
        },
        {
          title: "Manager",
          dataIndex: "reviewer",
          key: "reviewer",
          render: (obj) => (
            <EmployeeAvatarWithName
              emptyText="No Manager"
              employee={obj || {}}
            />
          ),
        },
        {
          title: "Review Progress",
          dataIndex: "reviewee_progress",
          key: "reviewee_progress",
          render: (text, row) => {
            const { submittedCount, peerSubmitCount, totalCount } =
              getCountsFromRow(row);
            const totalCompleteCount = submittedCount + peerSubmitCount || 0;

            const isRowExpanded = expandedRowKeys?.includes(row?.id);
            return (
              <>
                {`${totalCompleteCount} / ${totalCount} reviews submitted`}
                <CaretLeftOutlined
                  className="ml-8"
                  rotate={isRowExpanded ? -90 : 0}
                  onClick={(e) => {
                    if (isRowExpanded) {
                      setExpandedRowKeys(
                        expandedRowKeys?.filter((rowKey) => rowKey !== row?.id)
                      );
                    } else {
                      setExpandedRowKeys([...expandedRowKeys, row?.id]);
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, row) => {
            return getStatus(text, row);
          },
        },
        // {
        //   title: "Reviewee Status",
        //   dataIndex: "reviewee_status",
        //   key: "reviewee_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        // {
        //   title: "My Status",
        //   dataIndex: "reviewer_status",
        //   key: "reviewer_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        {
          title: "Actions",
          render: (text, row) => (
            <ActionBtn
              tableType="direct"
              rowId={row?.id}
              row={row}
              review={review}
              revieweeStatus={row?.reviewee_status}
              reviewerStatus={row?.reviewer_status}
              // handleEditDrawerVisible={handleEditDrawerVisible}
            />
          ),
        },
        {
          ...optionsColumn,
        },
      ];
    case "my-peers":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj, row) => (
            <Row align="middle">
              <EmployeeAvatarWithName employee={obj} />
              {/* <PerfReviewNudge
                className="ml-8"
                type="peer" //TODO: integrate api in component
                employee={obj}
                reviewId={row?.id}
                nudgedAt={row?.reviewee_nudge_at}
                reviewCycle={review?.review_cycle}
                status={row?.reviewer_status}
                otherStatus={row?.reviewee_status}
                setIsNudging={handleIsNudging}
                onSuccess={handleSuccess}
              /> */}
            </Row>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text) => <StatusText text={statusMap[text]} />,
        },
        {
          title: "Actions",
          render: (text, row) => (
            <ActionBtn
              tableType="peer"
              rowId={row?.id}
              row={row}
              review={review}
              // revieweeStatus={}
              peerStatus={statusMap[row?.status]}
              reviewerStatus={statusMap[row?.status]}
              // handleEditDrawerVisible={handleEditDrawerVisible}
            />
          ),
        },
      ];
    case "indirect":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj, row) => (
            <EmployeeAvatarWithName className="mr-8" employee={obj} />
          ),
        },
        {
          title: "Manager",
          dataIndex: "reviewer",
          key: "reviewer",
          render: (obj) => (
            <EmployeeAvatarWithName
              emptyText="No Manager"
              employee={obj || {}}
            />
          ),
        },
        {
          title: "Review Progress",
          dataIndex: "reviewee_progress",
          key: "reviewee_progress",
          render: (text, row) => {
            const { submittedCount, peerSubmitCount, totalCount } =
              getCountsFromRow(row);
            const totalCompleteCount = submittedCount + peerSubmitCount || 0;

            const isRowExpanded = expandedRowKeys?.includes(row?.id);
            return (
              <>
                {`${totalCompleteCount} / ${totalCount} reviews submitted`}
                <CaretLeftOutlined
                  className="ml-8"
                  rotate={isRowExpanded ? -90 : 0}
                  onClick={(e) => {
                    if (isRowExpanded) {
                      setExpandedRowKeys(
                        expandedRowKeys?.filter((rowKey) => rowKey !== row?.id)
                      );
                    } else {
                      setExpandedRowKeys([...expandedRowKeys, row?.id]);
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, row) => {
            return getStatus(text, row);
          },
        },
        // {
        //   title: "Reviewee Status",
        //   dataIndex: "reviewee_status",
        //   key: "reviewee_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        // {
        //   title: "My Status",
        //   dataIndex: "reviewer_status",
        //   key: "reviewer_status",
        //   render: (text) => <StatusText text={text} />,
        // },
        {
          title: "Actions",
          render: (text, row) => (
            <ActionBtn
              tableType="indirect"
              type="indirect"
              rowId={row?.id}
              row={row}
              review={review}
              revieweeStatus={row?.reviewee_status}
              reviewerStatus={row?.reviewer_status}
              // handleEditDrawerVisible={handleEditDrawerVisible}
            />
          ),
        },
        {
          ...optionsColumn,
        },
      ];
    case "hr-admin":
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (text, row) => (
            <EmployeeAvatarWithName
              employee={row?.reviewee}
              shouldShowInactive={true}
            />
          ),
        },
        {
          title: "Manager",
          dataIndex: "reviewer",
          key: "reviewer",
          render: (text, row) => (
            <Space>
              <EmployeeAvatarWithName
                employee={row?.reviewer}
                emptyText="No Reviewer"
              />
              <EditOutlined
                className={disabled && "icon-disabled"}
                onClick={() => {
                  if (!disabled) {
                    handleEditReviewerModalOpen(row);
                  }
                }}
              />
            </Space>
          ),
        },
        {
          title: "Review Progress",
          dataIndex: "reviewee_progress",
          key: "reviewee_progress",
          render: (text, row) => {
            const { submittedCount, peerSubmitCount, totalCount } =
              getCountsFromRow(row);
            const totalCompleteCount = submittedCount + peerSubmitCount || 0;

            const isRowExpanded = expandedRowKeys?.includes(row?.id);
            return (
              <>
                {`${totalCompleteCount} / ${totalCount} reviews submitted`}
                <CaretLeftOutlined
                  className="ml-8"
                  rotate={isRowExpanded ? -90 : 0}
                  onClick={(e) => {
                    if (isRowExpanded) {
                      setExpandedRowKeys(
                        expandedRowKeys?.filter((rowKey) => rowKey !== row?.id)
                      );
                    } else {
                      setExpandedRowKeys([...expandedRowKeys, row?.id]);
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, row) => {
            return getStatus(text, row);
          },
        },
        // {
        //   title: "Status",
        //   dataIndex: "reviewee_status",
        //   key: "reviewee_status",
        //   render: (text) => {
        //     const [type, displayText] = getStatusType(text);
        //     return (
        //       <Text
        //         type={type}
        //         className={type === "primary" && "primary-typography"}
        //       >
        //         {displayText}
        //       </Text>
        //     );
        //   },
        // },
        // {
        //   title: "Nudge",
        //   dataIndex: "nudges",
        //   key: "nudges",
        //   render: (nudges, row) => {
        //     return (
        //       <>
        //         <PerfReviewNudge
        //           type="reviewee"
        //           employee={row?.reviewee}
        //           nudgedAt={row?.reviewee_nudge_at}
        //           reviewCycle={cycle}
        //           reviewId={row?.id}
        //           status={row?.reviewee_status}
        //           otherStatus={row?.reviewer_status}
        //           setIsNudging={setIsNudging}
        //           onSuccess={handleNudgeSuccess}
        //         />
        //         <PerfReviewNudge
        //           className="ml-8"
        //           type="reviewer"
        //           employee={row?.reviewer}
        //           reviewId={row?.id}
        //           nudgedAt={row?.reviewer_nudge_at}
        //           reviewCycle={cycle}
        //           status={row?.reviewer_status}
        //           otherStatus={row?.reviewee_status}
        //           setIsNudging={setIsNudging}
        //           onSuccess={handleNudgeSuccess}
        //         />
        //       </>
        //     );
        //   },
        // },

        // {
        //   title: "Status",
        //   dataIndex: "reviewer_status",
        //   key: "reviewer_status",
        //   render: (text) => {
        //     const [type, displayText] = getStatusType(text);
        //     return (
        //       <Text
        //         type={type}
        //         className={type === "primary" && "primary-typography"}
        //       >
        //         {displayText}
        //       </Text>
        //     );
        //   },
        // },
        {
          render: (text, row) => (
            <OptionsPopover
              disabled={disabled}
              row={row}
              handleViewAssessment={handleViewAssessment}
              handleReset={handleReset}
              handleDeleteReview={handleDeleteReview}
              handleAcceptAppeal={handleAcceptAppeal}
              handleSubmitAsReviewer={handleSubmitAsReviewer}
              handleRejectAppeal={handleRejectAppeal}
            />
          ),
        },
      ];
    default:
      return [
        {
          title: "Employee",
          dataIndex: "reviewee",
          key: "reviewee",
          render: (obj) => <EmployeeAvatarWithName employee={obj} />,
        },
        {
          title: "Manager",
          dataIndex: "reviewer",
          key: "reviewer",
          render: (obj) => (
            <EmployeeAvatarWithName
              emptyText="No Manager"
              employee={obj || {}}
            />
          ),
        },
        {
          title: "Employee Status",
          dataIndex: "reviewee_status",
          key: "reviewee_status",
          render: (text) => <StatusText text={text} />,
        },
        {
          title: "Manager Status",
          dataIndex: "reviewer_status",
          key: "reviewer_status",
          render: (text) => <StatusText text={text} />,
        },
        {
          title: "Actions",
          render: (text, row) => (
            <ActionBtn
              rowId={row?.id}
              row={row}
              review={review}
              revieweeStatus={row?.reviewee_status}
              reviewerStatus={row?.reviewer_status}
            />
          ),
        },
        {
          ...optionsColumn,
        },
      ];
  }
};

export { getColumns };

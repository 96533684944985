import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Collapse,
  Space,
  Radio,
  Grid,
  // Input,
  // Button,
} from "antd";
import { UnorderedListOutlined, ApartmentOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { CaretRightOutlined } from "@ant-design/icons";
import HSBar from "react-horizontal-stacked-bar-chart";

import { DoubleLineChart, BoxLoader, InlineSpinner } from "components";
import {
  getExpectedDateObjects,
  getActualChartData,
  getStatus,
  getExpectedPercentageFor,
  getAllDatesInSelectedPeriod,
  getObjectiveStatusesFromSectionData,
  getHsBarData,
  getOutlineWidth,
} from "./utils";
import { getSummaryApi } from "apis/okrV2Api";
import { statusColors, statusText } from "../common/constants";
import styles from "../OKRs.module.css";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const SummaryPanel = ({
  pace = "",
  paceColor = "",
  overallProgress = 0,
  screens = {},
  selectedPeriodObj = {},
  data = {},
  objectives = [],
}) => {
  const allDates = getAllDatesInSelectedPeriod(selectedPeriodObj);
  const statuses = getObjectiveStatusesFromSectionData(
    objectives,
    selectedPeriodObj
  );

  return (
    <Row align="start" justify="center" gutter={[screens.xxl ? 96 : 48]}>
      <Col>
        <Space direction="vertical" size={40}>
          <Text className="font-size-14">OVERALL PROGRESS</Text>
          <div>
            <Title level={2}>{` ${Math.round(overallProgress || 0)} %`}</Title>
            <Space>
              <Text type="secondary">Pace: </Text>
              <Text
                style={{
                  color: paceColor,
                }}
              >
                {pace}
              </Text>
            </Space>
          </div>
        </Space>
      </Col>

      <Col className="ml-30">
        <Space direction="vertical" size={16}>
          <Text className="font-size-14">PROGRESS OVER TIME</Text>
          <div>
            <DoubleLineChart
              series={[
                {
                  name: "Expected",
                  data: getExpectedDateObjects(allDates, selectedPeriodObj),
                },
                {
                  name: "Actual",
                  data: getActualChartData(allDates, data),
                },
              ]}
              xAxisCategories={allDates}
            />
          </div>
        </Space>
      </Col>
      <Col>
        <Space direction="vertical" size={26}>
          <Text className="font-size-14">OKR BY STATUS</Text>
          <div>
            {(statuses["not_started"] > 0 ||
              statuses["on_track"] > 0 ||
              statuses["behind"] > 0 ||
              statuses["at_risk"] > 0 ||
              statuses["done"] > 0) && (
              <HSBar
                height={25}
                data={getHsBarData(statuses)}
                outlineWidth={getOutlineWidth([
                  statuses["not_started"],
                  statuses["on_track"],
                  statuses["behind"],
                  statuses["at_risk"],
                  statuses["done"],
                ])}
                outlineColor="white"
              />
            )}

            <Row className="min-width-250px">
              <Col span={12}>
                <Row className="height-100-percent mb-8" align="middle">
                  <span className={styles.onTrackDot} />
                  <Text className="font-size-14 line-height-16px">{`${statuses["on_track"]} ON TRACK`}</Text>
                </Row>
              </Col>
              <Col span={12}>
                <Row className="height-100-percent mb-8" align="middle">
                  <span className={styles.behindDot} />
                  <Text className="font-size-14 line-height-16px">{`${statuses["behind"]} BEHIND`}</Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row className="height-100-percent mb-8" align="middle">
                  <span className={styles.atRiskDot} />
                  <Text className="font-size-14 line-height-16px">{`${statuses["at_risk"]} AT RISK`}</Text>
                </Row>
              </Col>
              <Col span={12}>
                <Row className="height-100-percent mb-8" align="middle">
                  <span className={styles.doneDot} />
                  <Text className="font-size-14 line-height-16px">{`${statuses["done"]} DONE`}</Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Row className="height-100-percent mb-8" align="middle">
                  <span className={styles.notStartedDot} />
                  <Text className="font-size-14 line-height-16px">{`${statuses["not_started"]} NOT STARTED`}</Text>
                </Row>
              </Col>
            </Row>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

const Summary = ({
  overallProgress = 0,
  isMyOKR = false,
  objectives = [],
  shouldShowViews = true,
  selectedPeriodObj = {},
  objectiveProgressPercentage = {},
  selectedTeamId = "company-okrs",
}) => {
  const [getSummary, { data, isLoading, isFetching }] =
    useMutation(getSummaryApi);

  const history = useHistory();
  const screens = useBreakpoint();
  const [activeKeys, setActiveKeys] = useState([]);

  const handleShowTreeView = () => {
    history.push(
      `/okrs/company-okrs/tree-view?selectedPeriodId=${
        selectedPeriodObj?.id
      }&selectedTeamId=${selectedTeamId || "company-okrs"}`
    );
  };

  useEffect(() => {
    if (activeKeys[0] === "summary") {
      getSummary({
        my_okrs: isMyOKR,
        period_id: selectedPeriodObj?.id,
        team_id: selectedTeamId === "company-okrs" ? null : selectedTeamId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallProgress]);

  const handleActiveKeys = () => {
    if (activeKeys?.length === 0) {
      setActiveKeys(["summary"]);
      getSummary({
        my_okrs: isMyOKR,
        period_id: selectedPeriodObj?.id,
        team_id: selectedTeamId === "company-okrs" ? null : selectedTeamId,
      });
    } else {
      setActiveKeys([]);
    }
  };

  const pace =
    statusText[
      getStatus(overallProgress, getExpectedPercentageFor(selectedPeriodObj))
    ];

  const paceColor =
    statusColors[
      getStatus(overallProgress, getExpectedPercentageFor(selectedPeriodObj))
    ];

  const loader = isLoading || isFetching;

  return (
    <Collapse
      bordered={false}
      activeKey={activeKeys}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          onClick={handleActiveKeys}
          rotate={isActive ? 90 : 0}
        />
      )}
      onClick={handleActiveKeys}
      className="custom-collapse"
    >
      <Panel
        key="summary"
        header={
          <Row
            justify="space-between"
            align="middle"
            onClick={handleActiveKeys}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Title level={4} className="mb-0">
                Summary
              </Title>
              {activeKeys?.length === 0 && (
                <>
                  <Text className="ml-8">
                    {`(Overall Progress: ${Math.round(
                      overallProgress || 0
                    )}%,  Pace: `}
                  </Text>
                  <Text
                    className="ml-4"
                    style={{
                      color: paceColor,
                    }}
                  >
                    {pace}
                  </Text>
                  <Text>)</Text>
                  {loader && <InlineSpinner className="ml-8" fontSize={24} />}
                </>
              )}
            </div>
            {shouldShowViews && (
              <div onClick={(e) => e.stopPropagation()}>
                <Radio.Group size="small" className="mr-8" value="list">
                  <Radio.Button value="list">
                    <UnorderedListOutlined style={{ padding: "4px" }} />
                    <Text className="ml-8">List View</Text>
                  </Radio.Button>

                  <Radio.Button
                    value="tree"
                    onClick={() => handleShowTreeView()}
                  >
                    <ApartmentOutlined style={{ padding: "4px" }} />
                    <Text className="ml-8">Alignment View</Text>
                  </Radio.Button>
                </Radio.Group>
              </div>
            )}
          </Row>
        }
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
        }}
        className="custom-collapse-panel root-panel"
      >
        {activeKeys[0] === "summary" && (
          <>
            {loader ? (
              <BoxLoader height="10vh" />
            ) : (
              <SummaryPanel
                pace={pace}
                paceColor={paceColor}
                overallProgress={overallProgress}
                screens={screens}
                selectedPeriodObj={selectedPeriodObj}
                data={data}
                objectives={objectives}
              />
            )}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

Summary.propTypes = {};

export default Summary;

import axios from "axios";

const getAllEmployeesApi = async () => {
  const { data } = await axios.get("/api/v2/employees");
  return data;
};

const updateProfileApi = async ({ employee }) => {
  const { data } = await axios.post("/api/v2/employees/update", {
    employee,
  });
  return data;
};

const updateEmployeeApi = async ({ employeeId, employee }) => {
  const { data } = await axios.put(`/api/v2/admin/employees/${employeeId}`, {
    employee,
  });
  return data;
};

const addEmployeeApi = async ({ employee }) => {
  const { data } = await axios.post(`/api/v2/admin/employees/`, {
    employee,
  });
  return data;
};

const searchEmployeesApi = async ({ searchTerm }) => {
  const { data } = await axios.post(`/api/v2/employees/search`, {
    query: searchTerm,
  });
  return data;
};

const searchEmployeesWithManagerApi = async ({ searchTerm }) => {
  const { data } = await axios.post(`/api/v2/employees/search`, {
    query: searchTerm,
    with_manager: true,
  });
  return data;
};

const searchReportsChain = async ({ searchTerm }) => {
  const { data } = await axios.post("/api/v2/employees/search_report_chain", {
    query: searchTerm,
  });
  return data;
};

const searchDirectReports = async ({ searchTerm = "" }) => {
  const { data } = await axios.post("/api/v2/employees/search_direct_reports", {
    query: searchTerm,
  });
  return data;
};

export {
  getAllEmployeesApi,
  addEmployeeApi,
  updateProfileApi,
  updateEmployeeApi,
  searchEmployeesApi,
  searchEmployeesWithManagerApi,
  searchReportsChain,
  searchDirectReports,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Input, Modal, Button, Typography, Alert } from "antd";

import { updateSettingApi } from "apis/billingApi";
import { ButtonWithSpinner } from "components";
import { successNotification, errorNotification } from "app/appUtils";

const { Text } = Typography;

const ChangeAddress = ({
  currentAddress = "",
  isVisible = false,
  onCancel = () => {},
  refetchBillingDetails = () => {},
}) => {
  const [address, setAddress] = useState(currentAddress);
  const [error, setError] = useState("");

  const [
    updateSetting,
    {
      status: updatingStatus,
      isLoading: isUpdatingLoading,
      isFetching: isUpdatingFetching,
    },
  ] = useMutation(updateSettingApi, {
    onSuccess: () => {
      successNotification("Successfully updated Address");
      refetchBillingDetails();
      onCancel();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  useEffect(() => {
    setAddress(currentAddress);
  }, [currentAddress]);

  const handleAddress = (e) => {
    const addressVal = e.target.value;
    setAddress(addressVal);
    if (!addressVal) {
      setError("Address is required");
    } else if (!addressVal?.trim()) {
      setError("Address cant be empty.");
    } else {
      setError("");
    }
  };

  const handleSubmit = () => {
    if (error || !address) {
      setError("Address is required");
      return;
    }

    updateSetting({
      address,
    });
  };

  const spinning =
    updatingStatus === "loading" || isUpdatingLoading || isUpdatingFetching;

  return (
    <Modal
      visible={isVisible}
      title="Change Address"
      footer={[
        <ButtonWithSpinner
          isSpinning={spinning}
          disabled={spinning}
          type="primary"
          onClick={handleSubmit}
        >
          Change Address
        </ButtonWithSpinner>,
        <Button className="ml-8" type="primary" ghost={true} onClick={onCancel}>
          Cancel
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <Alert
        className="mb-8"
        message="This address will be shown on the invoice."
      />
      <Input.TextArea
        autoSize={{ minRows: 4, maxRows: 4 }}
        className={`mb-8 input-textarea-resize-none ${
          error && "input-text-area-error"
        }`}
        value={address}
        onChange={handleAddress}
        placeholder={`Joh Doe,
ABC Inc,
UITE 5A-1204,
799 E DRAGRAM,
TUCSON AZ 85705,
USA`}
      />
      {error && (
        <Text className="block-display" type="danger">
          {error}
        </Text>
      )}
    </Modal>
  );
};

ChangeAddress.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ChangeAddress;

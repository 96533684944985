import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { useQuery, useMutation } from "react-query";
import {
  Row,
  Typography,
  Input,
  // Modal,
  Col,
  Empty,
  Divider,
  Dropdown,
  Menu,
  Space,
  Button,
} from "antd";
import {
  // ExclamationCircleOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useCurrentUser, useCurrentCompany } from "store";
import {
  MultiLineText,
  EmployeeAvatar,
  EmployeeAvatarWithName,
  InlineSpinner,
  BoxLoader,
} from "components";
import {
  getAllCommentsApi,
  postCommentApi,
  updateCommentApi,
  deleteCommentApi,
} from "apis/okrV2Api";
import {
  errorNotification,
  errorNotificationWithString,
  getAuthorNameFromComment,
} from "app/appUtils";

import styles from "../../../OKRs.module.css";

const { Text } = Typography;
// const { confirm } = Modal;

const KeyResultCommentItem = ({
  isKeyResult = true,
  objectiveId = "",
  keyResultId = "",
  initiativeId = "",
  comment = [],
  refetchComments = () => {},
}) => {
  const [currentUser] = useCurrentUser();

  const [isEdit, setIsEdit] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(comment?.content);

  const commentContent = comment?.content || "";
  useEffect(() => {
    setUpdatedComment(commentContent);
  }, [commentContent]);

  const [updateComment, { status: updateCommentStatus }] = useMutation(
    updateCommentApi,
    {
      onSuccess: () => {
        refetchComments();
      },
      onError: (error) => {
        setUpdatedComment(comment?.content);
        errorNotification(error);
      },
    }
  );

  const [deleteComment, { status: deleteCommentStatus }] = useMutation(
    deleteCommentApi,
    {
      onSuccess: () => {
        refetchComments();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    deleteComment({
      objectiveId,
      itemId: isKeyResult ? keyResultId : initiativeId,
      isKeyResult,
      commentId: comment?.id,
    });
  };

  const handleCancel = () => {
    setUpdatedComment(comment?.content);
    setIsEdit(false);
  };

  const handleUpdate = () => {
    if (updatedComment && updatedComment.trim()) {
      setIsEdit(false);
      updateComment({
        objectiveId,
        itemId: isKeyResult ? keyResultId : initiativeId,
        isKeyResult,
        commentId: comment?.id,
        comment: updatedComment,
      });
    } else {
      errorNotificationWithString("Comment cant be empty!");
    }
  };

  const handleChangeComment = (e) => {
    setUpdatedComment(e.target.value);
  };

  const authorName = getAuthorNameFromComment(comment);

  const loader =
    updateCommentStatus === "loading" || deleteCommentStatus === "loading";

  return (
    <>
      <Row
        className="width-100-percent mb-8"
        justify="space-between"
        align="middle"
        gutter={[8]}
      >
        <Col xs={{ span: 24 }} md={{ span: 5 }}>
          <EmployeeAvatarWithName
            employee={{
              name: authorName || "",
              user_profile_pic_url: comment?.user_profile_pic_url || "",
              designation: comment?.designation || "",
              department: comment?.department || "",
              sub_department: comment?.sub_department || "",
            }}
          />
          <div className="ml-40">
            <Moment
              className="ant-typography ant-typography-secondary  font-size-14"
              fromNow
            >
              {comment?.created_at || new Date()}
            </Moment>
          </div>
        </Col>
        <Col xs={{ span: 21 }} md={{ span: 17 }}>
          {isEdit ? (
            <>
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 3 }}
                className="input-textarea-resize-none mb-4"
                value={updatedComment}
                onChange={handleChangeComment}
              />
              <Row justify="end">
                <Space>
                  <Button size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button size="small" color="primary" onClick={handleUpdate}>
                    Update
                  </Button>
                </Space>
              </Row>
            </>
          ) : (
            <>
              <MultiLineText text={updatedComment || ""} />
              <>
                {comment?.created_at !== comment?.updated_at && (
                  <Text type="secondary" className="ml-8 font-size-14">
                    (edited)
                  </Text>
                )}
              </>
            </>
          )}
          {loader && <InlineSpinner fontSize={18} />}
        </Col>
        <Col span={2}>
          {currentUser?.email === comment?.author_id && (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Menu mode="inline">
                    <Menu.Item key="Edit" onClick={handleEdit}>
                      <EditOutlined /> Edit
                    </Menu.Item>
                    <Menu.Item key="delete" onClick={handleDelete}>
                      <DeleteOutlined /> Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                placement="topRight"
              >
                <span className="pl-30">
                  <MoreOutlined />
                </span>
              </Dropdown>
            </div>
          )}
        </Col>
      </Row>
      <Divider className="my-8" />
    </>
  );
};

KeyResultCommentItem.propTypes = {
  keyResult: PropTypes.object,
};

const KeyResultComments = ({
  objectiveId = "",
  isKeyResult = true,
  keyResultId = "",
  initiativeId,
  handleUpdateCommentsCount = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const {
    status: allCommentsStatus,
    isLoading: isAllCommentsLoading,
    isFetching: isAllCommentsFetching,
    refetch: refetchAllComments,
  } = useQuery(
    [
      "getAllComments",
      objectiveId,
      isKeyResult ? keyResultId : initiativeId,
      isKeyResult,
    ],
    getAllCommentsApi,
    {
      onSuccess: (data) => {
        if (data) {
          setComments([...data]);
          const unDeletedComments = data?.filter(
            (comment) => !comment?.deleted
          );
          handleUpdateCommentsCount(unDeletedComments?.length);
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [postComment, { status: postCommentsStatus }] = useMutation(
    postCommentApi,
    {
      onSuccess: () => {
        setNewComment("");
        refetchAllComments();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleKeyDown = (e) => {
    // KeyCode of enter is 13
    if (e.keyCode === 13) {
      if (e.target.value && e.target.value.trim() !== "") {
        postComment({
          objectiveId,
          itemId: isKeyResult ? keyResultId : initiativeId,
          comment: e.target.value,
          isKeyResult,
        });
      } else {
        errorNotificationWithString("Comment cant be empty");
      }
    }
  };

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const loader =
    allCommentsStatus === "loading" ||
    (isAllCommentsFetching && !isAllCommentsLoading) ||
    postCommentsStatus === "loading";

  return (
    <div className={styles.activityCommentsTabPane}>
      <Row className="mt-8 mb-16" justify="start" align="center">
        <Col span={2} className="flex-display align-items-center">
          <EmployeeAvatar employee={currentCompany?.user} />
        </Col>
        <Col span={21}>
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 2 }}
            className="input-textarea-resize-none"
            placeholder="Type & Press Enter to comment"
            value={newComment}
            onChange={handleNewCommentChange}
            onKeyDown={handleKeyDown}
          />
        </Col>
        <Col
          span={1}
          className="flex-display align-items-center justify-content-center"
        >
          {loader && <InlineSpinner fontSize={18} />}
        </Col>
      </Row>

      {isAllCommentsLoading && <BoxLoader />}

      {!isAllCommentsLoading && comments?.length === 0 ? (
        <Empty description="No Comments yet"></Empty>
      ) : (
        <div id={`key-result-comment-${keyResultId}`}>
          {comments?.map((comment) => {
            if (!comment?.deleted) {
              return (
                <KeyResultCommentItem
                  isKeyResult={isKeyResult}
                  objectiveId={objectiveId}
                  keyResultId={keyResultId}
                  initiativeId={initiativeId}
                  comment={comment}
                  refetchComments={refetchAllComments}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

KeyResultComments.propTypes = {
  objectiveId: PropTypes.number,
  keyResultId: PropTypes.number,
  keyResultComments: PropTypes.array,
  handleUpdateCommentsLength: PropTypes.func,
};

export default KeyResultComments;

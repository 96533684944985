import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import {
  Modal,
  Row,
  Col,
  Typography,
  InputNumber,
  Tooltip,
  Button,
  Empty,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { BoxLoader, ButtonWithSpinner } from "components";
import { getObjectiveDetailsApi, postUpdateWeightageApi } from "apis/okrV2Api";
import {
  roundToTwoDigits,
  roundToFourDigits,
  stripDecimalPoints,
  errorNotification,
  successNotification,
  errorNotificationWithString,
} from "app/appUtils";

import styles from "../../OKRs.module.css";

const { Text } = Typography;

const InputRow = ({
  type = "",
  id = "",
  title = "",
  isPostponed = false,
  value = 0,
  onChange = () => {},
}) => {
  return (
    <Row key={id} className="mt-16">
      <Col
        span={1}
        className="flex-display justify-content-center align-items-center"
      >
        <Text type="secondary">{type}</Text>
      </Col>
      <Col span={20} className="flex-display align-items-center">
        <Text>{title}</Text>
        {isPostponed && (
          <Text className="ml-8" type="secondary">
            (postponend)
          </Text>
        )}
      </Col>
      <Col
        span={3}
        className="flex-display justify-content-center align-items-center"
      >
        <InputNumber
          value={value}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
          onChange={(val) => onChange(id, Number(val) || 0, type)}
        />
      </Col>
    </Row>
  );
};

const ChangeObjectiveWeights = ({
  visible = false,
  objective = {},
  onCancel = () => {},
  refetch = () => {},
  refetchAncestorProgress = () => {},
}) => {
  const [weights, setWeights] = useState({
    key_results: {},
    sub_objectives: {},
  });

  const { data, isLoading, isFetching } = useQuery(
    ["getObjectiveDetails", objective?.id],
    getObjectiveDetailsApi,
    {
      onSuccess: (data) => {
        const updatedWeights = { ...weights };
        if (Boolean(data?.weightage)) {
          data.key_results.forEach((kr) => {
            updatedWeights.key_results[Number(kr?.id)] =
              stripDecimalPoints(data?.weightage?.key_results[kr?.id], 2) || 0;
          });

          data.objectives.forEach((subObj) => {
            updatedWeights.sub_objectives[Number(subObj?.id)] =
              stripDecimalPoints(
                data?.weightage?.sub_objectives[subObj?.id],
                2
              ) || 0;
          });

          setWeights({ ...updatedWeights });
        } else {
          const totalChildren =
            data?.key_results?.length + data?.objectives?.length || 0;

          const defaultDistribution = roundToTwoDigits(100 / totalChildren);

          data.key_results.forEach((kr) => {
            updatedWeights.key_results[Number(kr?.id)] = defaultDistribution;
          });

          data.objectives.forEach((subObj) => {
            updatedWeights.sub_objectives[
              Number(subObj?.id)
            ] = defaultDistribution;
          });

          setWeights({ ...updatedWeights });
        }
      },
    }
  );

  const [postUpdateWeightage, { isLoading: isUpdatingWeightage }] = useMutation(
    postUpdateWeightageApi,
    {
      onSuccess: () => {
        successNotification("Successfully updated weights");
        refetch();
        refetchAncestorProgress();
        onCancel();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleSubmit = () => {
    if (currentSum >= 99.9 && currentSum <= 100) {
      postUpdateWeightage({ weightage: weights, objectiveId: objective?.id });
    } else {
      errorNotificationWithString(
        "Sum of weights all children must be in the range of 99.90% to 100%"
      );
    }
  };

  const handleChange = (id, val, type) => {
    const updatedWeights = { ...weights };
    if (type === "KR") {
      updatedWeights.key_results[id] = roundToFourDigits(val);
    } else {
      updatedWeights.sub_objectives[id] = roundToFourDigits(val);
    }
    setWeights(updatedWeights);
  };

  const currentKRWeights = Object.values(weights?.key_results) || [];
  const currentSubObjWeights = Object.values(weights?.sub_objectives) || [];
  const currentKRSum = currentKRWeights?.reduce((a, b) => a + b, 0);
  const currentSubObjSum = currentSubObjWeights?.reduce((a, b) => a + b, 0);
  let currentSum = currentKRSum + currentSubObjSum;
  currentSum = Number(currentSum?.toFixed(2));

  const loader = isFetching || isLoading;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        width={"1000px"}
        title="Change KR Weights"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancel</Button>,
          <ButtonWithSpinner
            type="primary"
            isSpinning={isUpdatingWeightage}
            disabled={isUpdatingWeightage}
            onClick={handleSubmit}
          >
            Submit
          </ButtonWithSpinner>,
        ]}
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <>
            {data?.key_results?.length === 0 &&
              data?.objectives?.length === 0 && (
                <Empty description="No children to show " />
              )}
            <div className={styles.weightsScroll}>
              {data?.key_results?.map((kr) => (
                <InputRow
                  type="KR"
                  key={kr?.id}
                  id={kr?.id}
                  title={kr?.title}
                  isPostponed={kr?.postponed}
                  value={weights?.key_results[kr?.id]}
                  onChange={handleChange}
                />
              ))}
              {data?.objectives?.map((subObj) => (
                <InputRow
                  type="OBJ"
                  key={subObj?.id}
                  id={subObj?.id}
                  title={subObj?.title}
                  value={weights?.sub_objectives[subObj?.id]}
                  onChange={handleChange}
                />
              ))}
            </div>
            <Row justify="end" className="mt-16">
              <Col
                span={21}
                className="flex-display justify-content-flex-end align-items-center"
              >
                <Tooltip
                  title={
                    "Sum of weights all children must be in the range of 99.90% to 100%"
                  }
                >
                  <InfoCircleOutlined className="mr-8 mt--3" />
                </Tooltip>
                <Text>Total:</Text>
              </Col>
              <Col span={3} className="flex-display align-items-center">
                <Text
                  className="ml-16"
                  type={
                    currentSum >= 99.9 && currentSum <= 100
                      ? "success"
                      : "danger"
                  }
                >
                  {roundToFourDigits(currentSum)}%
                </Text>
              </Col>
            </Row>
            {/* <Row className="mb-16" justify="end">
              <Col
                span={21}
                className="flex-display justify-content-flex-end align-items-center"
              >
                <InfoCircleOutlined className="mr-8" />
                <Text type="secondary" className="mt--3">
                  Sum of weights all children must be in the range of 99.90% to
                  100%
                </Text>
              </Col>
              <Col span={3}></Col>
            </Row> */}
          </>
        )}
      </Modal>
    </div>
  );
};

ChangeObjectiveWeights.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ChangeObjectiveWeights;

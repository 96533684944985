import React from "react";
import PropTypes from "prop-types";
import { Row, Dropdown, Button, Menu, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { statuses, revieweeStatuses } from "./constants";

const MenuFilters = ({
  filters = {},
  employees = [],
  setFilters = () => {},
  ...props
}) => {
  const reviewee_statuses = filters?.reviewee_statuses || [];
  const reviewer_statuses = filters?.reviewer_statuses || [];

  const handleRevieweeStatusChange = (reviewee_statuses) => {
    setFilters({ ...filters, reviewee_statuses });
  };

  const handleReviewerStatusChange = (reviewer_statuses) => {
    setFilters({ ...filters, reviewer_statuses });
  };

  return (
    <Menu
      getPopupContainer={() => document.getElementById("okr-filter-menu")}
      triggerSubMenuAction="click"
      {...props}
    >
      <Menu.SubMenu key="reviewee_status" title="Reviewee Status">
        <div className="m-16 width-150-px" onClick={(e) => e.stopPropagation()}>
          <Checkbox.Group
            value={reviewee_statuses}
            onChange={handleRevieweeStatusChange}
          >
            <Row style={{ display: "flex", flexDirection: "column" }}>
              {Object.entries(revieweeStatuses)?.map(([value, text], index) => (
                <Checkbox
                  className={index === 0 && "ml-8"}
                  key={value}
                  value={value}
                >
                  {text}
                </Checkbox>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      </Menu.SubMenu>
      <Menu.SubMenu key="reviewer_status" title="Reviewer Status">
        <div className="m-16 width-150-px" onClick={(e) => e.stopPropagation()}>
          <Checkbox.Group
            value={reviewer_statuses}
            onChange={handleReviewerStatusChange}
          >
            <Row style={{ display: "flex", flexDirection: "column" }}>
              {Object.entries(statuses)?.map(([value, text], index) => (
                <Checkbox
                  className={index === 0 && "ml-8"}
                  key={value}
                  value={value}
                >
                  {text}
                </Checkbox>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      </Menu.SubMenu>
    </Menu>
  );
};
MenuFilters.propTypes = {
  filters: PropTypes.object,
};

const SearchAndFilters = ({ filters = {}, setFilters = () => {} }) => {
  return (
    <Dropdown
      overlay={<MenuFilters filters={filters} setFilters={setFilters} />}
      getPopupContainer={() => document.getElementById("okr-search-filter")}
      trigger={"click"}
      placement="bottomCenter"
      arrow
    >
      <Button className="mr-16" style={{ height: "40px", paddingTop: "9px" }}>
        <FilterOutlined />
      </Button>
    </Dropdown>
  );
};

SearchAndFilters.propTypes = {};

export default SearchAndFilters;

import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import ItemActivity from "./ItemActivity";
import ItemComments from "./ItemComments";

const { TabPane } = Tabs;

const ActivityCommentsSection = ({
  defaultSelectedTab = "comments",
  kpi = {},
}) => {
  return (
    <>
      <div>
        <Tabs
          defaultActiveKey={defaultSelectedTab}
          destroyInactiveTabPane={true}
        >
          <TabPane key="comments" tab={"Comments"}>
            <ItemComments kpi={kpi} />
          </TabPane>
          <TabPane key="activity" tab="Activity Log">
            <ItemActivity kpi={kpi} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

ActivityCommentsSection.propTypes = {
  objectiveId: PropTypes.number,
  keyResultId: PropTypes.number,
  keyResultComments: PropTypes.array,
  handleUpdateComments: PropTypes.func,
};

export default ActivityCommentsSection;

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { PageHeader, Button, Tabs, Typography } from "antd";
import { useParams, useHistory } from "react-router-dom";

import RequestFeedbackModal from "./RequestFeedbackModal";
import FeedbackWall from "./feedback-wall/FeedbackWall";
import FeedbackRequests from "./feedback-requests/FeedbackRequests";

const { TabPane } = Tabs;
const { Title } = Typography;

const ContinuousFeedback = (props) => {
  const { feedbackTab } = useParams();
  const history = useHistory();

  const [isGiveFeedbackOpen, setIsGiveFeedbackOpen] = useState(false);
  const [isRequestFeedbackModalOpen, setIsRequestFeedbackModalOpen] = useState(
    false
  );
  const [editGiveFeedback, setEditGiveFeedback] = useState(null);
  const [feedbackRequestsCount, setFeedbackRequestsCount] = useState(0);

  const handleIsGiveFeedbackOpen = () => {
    setIsGiveFeedbackOpen(!isGiveFeedbackOpen);
  };

  const handleIsRequestFeedbackModalOpen = () => {
    setIsRequestFeedbackModalOpen(!isRequestFeedbackModalOpen);
  };

  const handleCloseFeedbackDrawer = () => {
    if (isGiveFeedbackOpen) {
      handleIsGiveFeedbackOpen();
    } else {
      handleEditGiveFeedback(null);
    }
  };

  const handleEditGiveFeedback = (feedback = null) => {
    setEditGiveFeedback(feedback);
  };

  const handleTabChange = (tab) => {
    history.push(`/continuous-feedback/${tab}`);
  };

  return (
    <div>
      {isRequestFeedbackModalOpen && (
        <RequestFeedbackModal
          visible={isRequestFeedbackModalOpen}
          onCancel={handleIsRequestFeedbackModalOpen}
        />
      )}
      <PageHeader
        className="pl-0"
        title={<Title level={2}>Continuous Feedback</Title>}
        extra={[
          <Button
            type="primary"
            ghost={true}
            onClick={handleIsRequestFeedbackModalOpen}
          >
            Request Feedback
          </Button>,
          <Button type="primary" onClick={handleIsGiveFeedbackOpen}>
            Give Feedback
          </Button>,
        ]}
      />

      <Helmet>
        <title>Cont. Feedback - culture.easy</title>
      </Helmet>

      <Tabs
        activeKey={feedbackTab}
        onChange={handleTabChange}
        destroyInactiveTabPane={true}
      >
        <TabPane key="feedback-wall" tab={"Feedback Wall"}>
          <FeedbackWall
            isGiveFeedbackOpen={isGiveFeedbackOpen}
            editGiveFeedback={editGiveFeedback}
            handleCloseFeedbackDrawer={handleCloseFeedbackDrawer}
            handleEditGiveFeedback={handleEditGiveFeedback}
            setFeedbackRequestsCount={setFeedbackRequestsCount}
          />
        </TabPane>
        <TabPane
          key="feedback-requests"
          tab={`Feedback Requests ${
            feedbackRequestsCount ? `(${feedbackRequestsCount})` : ""
          }`}
        >
          <FeedbackRequests
            isGiveFeedbackOpen={isGiveFeedbackOpen}
            handleCloseFeedbackDrawer={handleCloseFeedbackDrawer}
            setFeedbackRequestsCount={setFeedbackRequestsCount}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

ContinuousFeedback.propTypes = {};

export default ContinuousFeedback;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  //  debounce,
  throttle,
} from "lodash";
import { Row, Col, Typography, Input, Table, Grid } from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";

import { getIsMobile } from "app/appUtils";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const updatedSelectedRows = (recordKey, selected = false, updatedKeys = []) => {
  // This function takes care of updation of keys.
  if (selected) {
    updatedKeys.push(recordKey);
  } else {
    const indexOfHead = updatedKeys?.indexOf(recordKey);
    if (indexOfHead > -1) {
      updatedKeys.splice(indexOfHead, 1);
    }
  }
};

const getAllSelectedRow = (record, selected = false, updatedKeys = []) => {
  // This function takes care of recursion
  if (record?.active) {
    updatedSelectedRows(record?.id, selected, updatedKeys);
  }

  if (record?.children) {
    record.children.forEach((child) => {
      getAllSelectedRow(child, selected, updatedKeys);
    });
  }

  return updatedKeys;
};

const getFinalEmployeeNodes = (
  tree = [],
  searchedEmployeeIds = [],
  finalEmployeeNodes = []
) => {
  if (!tree || tree?.length === 0) {
    return;
  }
  tree.forEach((node) => {
    const nodeId = node?.id;
    if (searchedEmployeeIds?.includes(nodeId)) {
      finalEmployeeNodes.push(node);
      return;
    } else {
      getFinalEmployeeNodes(
        node?.children || [],
        searchedEmployeeIds,
        finalEmployeeNodes
      );
    }
  });
};

const handleUpdateTreeData = (
  initialTreeData,
  searchedEmployeeIds,
  setTreeData,
  setIsSearching
) => {
  // let filteredTreeData = [...treeData];
  // let finalPath = [];
  if (searchedEmployeeIds?.length > 0) {
    // treeData.forEach((node) => {
    //   searchedEmployeeIds.some((empId) => {
    //     const isAncestor = getIsDescendant(node, empId);
    //     if (isAncestor) {
    //       const path = getPath(empId, employeeManagerMap);
    //       finalPath = [...finalPath, ...path];
    //     }
    //     return isAncestor;
    //   });
    // });

    const finalEmployeeNodes = [];
    getFinalEmployeeNodes(
      initialTreeData,
      searchedEmployeeIds,
      finalEmployeeNodes
    );
    setTreeData(finalEmployeeNodes);
    setIsSearching(false);
    // handleExpandMultiple(finalPath);
  } else {
    setTreeData([]);
    setIsSearching(false);
  }
  return;
};

// const getIsDescendant = (older, youngerId) => {
//   return (
//     !!older?.children &&
//     older.children.some(
//       (child) => child?.id === youngerId || getIsDescendant(child, youngerId)
//     )
//   );
// };

// const getEmployeeManagerMap = (employees = []) => {
//   const finalMap = {};
//   employees.forEach((emp) => {
//     finalMap[emp?.id] = emp?.manager?.id;
//   });
//   return finalMap;
// };

// const getEmployeeIdMap = (employees = []) => {
//   const finalMap = {};
//   employees.forEach((emp) => {
//     finalMap[emp?.id] = emp;
//   });
//   return finalMap;
// };

// const getPath = (childId, employeeManagerMap = {}) => {
//   const path = [];
//   let currentId = childId;
//   while (currentId) {
//     path.push(currentId);
//     currentId = employeeManagerMap[currentId];
//   }
//   return path;
// };

const TreeTable = ({
  shouldShowRowSelection = true,
  selections = [],
  selectedRowKeys = [],
  initialTreeData = [],
  activeEmployees = [],
  inActiveEmployees = [],
  columns = [],
  dataSource = [],
  setTreeData = () => {},
  handleSelect = () => {},
  handleSelectAll = () => {},
  handleResetSearch = () => {},
  ...props
}) => {
  const screens = useBreakpoint();
  const [isSearching, setIsSearching] = useState(false);
  const [isLessThanThreeChars, setIsLessThanThreeChars] = useState(false);

  const handleSearchEmployees = (e) => {
    setIsSearching(true);
    let value = e.target.value;

    if (!value) {
      // debounce(() => {
      // handleCollapseAll();
      setIsLessThanThreeChars(false);
      handleResetSearch();
      setIsSearching(false);
      // }, 500)();
      return;
    }

    if (value?.length > 2) {
      setIsLessThanThreeChars(false);
      const filteredEmployeeIds = [];
      const allEmployees = [...activeEmployees, ...inActiveEmployees];
      allEmployees.forEach((employee) => {
        if (employee.name.toLowerCase().includes(value.toLowerCase())) {
          filteredEmployeeIds.push(employee?.id);
        }
      });

      // debounce(() => {
      handleUpdateTreeData(
        initialTreeData,
        filteredEmployeeIds,
        setTreeData,
        setIsSearching
      );
      // }, 750)();
    } else {
      setIsLessThanThreeChars(true);
      setIsSearching(false);
    }
  };

  const handleSearchEmployeesThrottled = (e) => {
    throttle(() => {
      handleSearchEmployees(e);
    }, 750)();
  };

  //   const handleCollapseAll = () => {
  //     setExpandedRowKeys([]);
  //   };

  //   const handleUpdateRowKeys = (updatedKeys = []) => {
  //     setExpandedRowKeys(updatedKeys);
  //   };

  //   const handleExpandMultiple = (path = []) => {
  //     if (path?.length > 0) {
  //       const updatedExpanded = [...expandedRowKeys, ...path];
  //       setExpandedRowKeys(updatedExpanded);
  //     }
  //   };

  //   const handleExpandRow = (rowId) => {
  //     const indexOfRowId = expandedRowKeys?.indexOf(rowId);
  //     if (indexOfRowId === -1) {
  //       handleUpdateRowKeys([...expandedRowKeys, rowId]);
  //     }
  //   };

  //   const handleCollapseRow = (rowId) => {
  //     const indexOfRowId = expandedRowKeys?.indexOf(rowId);
  //     if (indexOfRowId > -1) {
  //       const updatedRowIds = [...expandedRowKeys];
  //       updatedRowIds.splice(indexOfRowId, 1);
  //       handleUpdateRowKeys(updatedRowIds);
  //     }
  //   };

  const isMobile = getIsMobile(screens);

  return (
    <>
      <Row
        className="width-100-percent my-8"
        align="middle"
        justify="space-between"
      >
        <Col>
          {shouldShowRowSelection ? (
            <Text>{`${selections?.length}/${activeEmployees?.length} Selected`}</Text>
          ) : (
            ""
          )}
        </Col>
        <Col>
          <div>
            <Input
              className={`${isMobile ? "width-100-percent" : "width-400-px"} ${
                isLessThanThreeChars && "input-text-area-error "
              }`}
              allowClear
              placeholder="Search Employee"
              onChange={handleSearchEmployeesThrottled}
              prefix={<SearchOutlined />}
            />
          </div>
          {isLessThanThreeChars && (
            <Text type="danger">Type at least 3 characters</Text>
          )}
        </Col>
      </Row>
      <Table
        loading={isSearching}
        columns={columns}
        dataSource={dataSource}
        rowSelection={
          shouldShowRowSelection
            ? {
                checkStrictly: true,
                preserveSelectedRowKeys: true,
                getCheckboxProps: (record) => ({ disabled: !record?.active }),
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  // console.log(selectedRowKeys);
                  // handleEmployeeSelection(
                  //   selectedRowKeys,
                  //   form.getFieldValue("managerMappings")
                  // );
                },
                onSelect: (record, selected) => {
                  const currentSelectedKeys = selectedRowKeys;

                  const updatedKeys = [...currentSelectedKeys];

                  getAllSelectedRow(record, selected, updatedKeys);

                  handleSelect([...new Set(updatedKeys)]);
                },
                onSelectAll: (selected) => {
                  const allEmployeeIds = activeEmployees?.map((emp) => emp?.id);

                  handleSelectAll(
                    selected ? allEmployeeIds : []
                    //   form.getFieldValue("managerMappings")
                  );
                },
              }
            : null
        }
        expandable={{
          //   expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0)
              return expanded ? (
                <CaretDownOutlined
                  className="vertical-align-middle mr-8"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <CaretRightOutlined
                  className="vertical-align-middle mr-8"
                  onClick={(e) => onExpand(record, e)}
                />
              );
          },
        }}
        // pagination={{
        //   hideOnSinglePage: true,
        //   size: "small",
        // }}
        pagination={false}
        scroll={{ y: 400 }}
        // components={VList({
        //   height: 500,
        // })}
        {...props}
      />
    </>
  );
};

TreeTable.propTypes = {
  selections: PropTypes.array,
  selectedRowKeys: PropTypes.array,
  initialTreeData: PropTypes.array,
  activeEmployees: PropTypes.array,
  inActiveEmployees: PropTypes.array,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  handleSelect: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleUpdateTreeData: PropTypes.func,
  setTreeData: PropTypes.func,
};

export default TreeTable;

import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Space,
  Divider,
  List,
  Radio,
  Switch,
} from "antd";
import Icon from "@ant-design/icons";

import { useCurrentCompany } from "store";
import { MacroHighlightedText } from "components";
import StepFormActionButtons from "../StepFormActionButtons";
import styles from "./cycleQuestionnaireForm.module.css";
import AddTextQuestionForm from "./AddTextQuestionForm";
import AddMcqQuestionForm from "./AddMcqQuestionForm";
import { ReactComponent as Drag } from "assets/icons/drag.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { errorNotificationWithString } from "app/appUtils";
import { TEXT_TYPE, MCQ_TYPE } from "../../../threeSixtyConstants";

const { Title, Text } = Typography;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((question, index) => ({ ...question, order: index + 1 }));
};

const Question = ({
  question,
  index,
  firstName,
  lastName,
  handleEdit,
  handleIsRequired,
  handleDelete,
}) => {
  const type = question.type;
  return (
    <Draggable draggableId={index.toString()} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Row justify="space-between" className="mb-8">
            <Col span={12}>
              <Text type="secondary">
                {type === TEXT_TYPE && "TEXT QUESTION"}
                {type === MCQ_TYPE && "MULTIPLE CHOICE QUESTION"}
              </Text>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <Space>
                    <Space>
                      <Text type="secondary">Required</Text>
                      <Switch
                        checked={question?.is_required}
                        onChange={(checked) =>
                          handleIsRequired(checked, question?.order)
                        }
                      />
                    </Space>
                    <Icon component={Edit} onClick={() => handleEdit(index)} />
                    <Icon
                      className="cursor-move"
                      component={Drag}
                      {...provided.dragHandleProps}
                    />
                    <Icon
                      component={Delete}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-36">
            <div className="mb-8">
              <MacroHighlightedText
                firstName={firstName || "-"}
                lastName={lastName || "-"}
                text={question?.description}
              />
            </div>
            {type === TEXT_TYPE && (
              <Input.TextArea disabled className="input-textarea-resize-none" />
            )}
          </Row>
          {type === MCQ_TYPE && (
            <Row justify="space-around" className="mb-30">
              <Col span={18}>
                <List
                  className="reviews-mcq-options-list"
                  dataSource={question.options}
                  bordered
                  renderItem={(item) => (
                    <List.Item>
                      <Row justify="flex-start">
                        <Radio disabled />
                        <Title level={4}>{item?.value || ""}</Title>
                      </Row>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </Draggable>
  );
};

function CycleQuestionnaireForm({
  mode = "create",
  initialValues = {},
  employees = [],
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) {
  const [currentCompany] = useCurrentCompany();
  const [questionsForm] = Form.useForm();
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [showTextQuestionForm, setShowTextQuestionForm] = useState(false);
  const [showMcqQuestionForm, setShowMcqQuestionForm] = useState(false);

  const resetCurrentEditQuestion = () => {
    setCurrentQuestion({});
    setEditQuestionId(null);
  };

  const showTextModal = () => {
    setShowTextQuestionForm(true);
  };

  const hideTextModal = () => {
    setShowTextQuestionForm(false);
    resetCurrentEditQuestion();
  };

  const showMcqModal = () => {
    setShowMcqQuestionForm(true);
  };

  const hideMcqModal = () => {
    setShowMcqQuestionForm(false);
    resetCurrentEditQuestion();
  };

  const prepareEditQuestion = (key) => {
    let question = questionsForm.getFieldValue("questions")[key];
    setCurrentQuestion(question);
    setEditQuestionId(key);
  };

  const handleTextEdit = (index) => {
    prepareEditQuestion(index);
    showTextModal();
  };

  const handleMcqEdit = (index) => {
    prepareEditQuestion(index);
    showMcqModal();
  };

  const handleQuestionDelete = (index) => {
    const questions = questionsForm.getFieldValue("questions");
    questions.splice(index, 1);
    const updatedQuestions = questions?.map((question, index) => ({
      ...question,
      order: index + 1,
    }));
    questionsForm.setFieldsValue({ questions: updatedQuestions });
  };

  const getAreOptionsEmpty = (options = []) => {
    let result = false;
    // eslint-disable-next-line no-unused-expressions
    options?.forEach((option) => {
      if (!option?.value?.trim()) {
        result = true;
      }
    });
    return result;
  };

  const handleQuestionSubFormSubmit = (name, { values, forms }) => {
    const questions = questionsForm.getFieldValue("questions") || [];
    const options = forms?.mcqQuestionForm?.getFieldValue("options") || [];

    if (values?.type === MCQ_TYPE && options?.length < 2) {
      errorNotificationWithString(
        "Multiple Choice Question should have at least two options"
      );
      return;
    }
    const areOptionsEmpty = getAreOptionsEmpty(options);
    if (values?.type === MCQ_TYPE && areOptionsEmpty) {
      errorNotificationWithString(
        "Options cant be empty. Please fill all options"
      );
      return;
    }

    if (editQuestionId !== null) {
      const oldQuestion = questions[editQuestionId];
      if (values?.type === TEXT_TYPE) {
        questions[editQuestionId] = { ...oldQuestion, ...values };
      } else {
        questions[editQuestionId] = { ...oldQuestion, ...values, options };
      }
      questionsForm.setFieldsValue({
        questions: questions,
      });
      setEditQuestionId(null);
    } else {
      if (values?.type === TEXT_TYPE) {
        questionsForm.setFieldsValue({
          questions: [
            ...questions,
            { ...values, order: questions.length + 1, is_required: true },
          ],
        });
      } else {
        questionsForm.setFieldsValue({
          questions: [
            ...questions,
            {
              ...values,
              order: questions.length + 1,
              options,
              is_required: true,
            },
          ],
        });
      }
    }

    if (name === "textQuestionForm") {
      forms.textQuestionForm.resetFields();
      hideTextModal();
    } else if (name === "mcqQuestionForm") {
      forms.mcqQuestionForm.resetFields();
      hideMcqModal();
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let questions = questionsForm.getFieldValue("questions");
    const orderedQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    questionsForm.setFieldsValue({ questions: orderedQuestions });
  };

  const handleIsRequired = (checked, questionOrder) => {
    const currentQuestions = questionsForm.getFieldValue("questions") || [];
    const updatedQuestions = currentQuestions?.map((question) => {
      if (question?.order === questionOrder) {
        return { ...question, is_required: checked };
      }
      return { ...question };
    });
    questionsForm.setFieldsValue({ questions: updatedQuestions });
  };

  return (
    <>
      <Form
        className="mt-24"
        name="questionnaire"
        form={questionsForm}
        initialValues={initialValues}
      >
        <Form.Item
          shouldUpdate
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && value.length > 0) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Questions cant be empty");
                }
              },
            }),
          ]}
        >
          {({ getFieldValue }) => {
            const questions = getFieldValue("questions") || [];
            return questions.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="questions">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Row className={styles.headingContainer}>
                        <Col span={24}>
                          <Title level={3} className={styles.heading}>
                            Compose Questionnaire
                          </Title>
                        </Col>
                      </Row>
                      {questions.map((question, index) => {
                        let handleEdit = () => {};
                        if (question.type === TEXT_TYPE) {
                          handleEdit = handleTextEdit;
                        } else if (question.type === MCQ_TYPE) {
                          handleEdit = handleMcqEdit;
                        }

                        return (
                          <Question
                            question={question}
                            index={index}
                            handleEdit={handleEdit}
                            handleDelete={handleQuestionDelete}
                            handleIsRequired={handleIsRequired}
                            firstName={currentCompany?.user?.first_name}
                            lastName={currentCompany?.user?.last_name}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              "No Questions Yet!"
            );
          }}
        </Form.Item>

        <Row justify="space-around" className="mb-8">
          <Col>
            <Button type="primary" ghost onClick={showTextModal}>
              Add Text Question
            </Button>
          </Col>
        </Row>
        <Row justify="space-around" className="mb-8">
          <Col>
            <Button type="primary" ghost onClick={showMcqModal}>
              Add Multiple Choice Question
            </Button>
          </Col>
        </Row>

        <Divider className="questionsDivider" />
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.questions !== curValues.questions
          }
        >
          {() => (
            <StepFormActionButtons
              shouldShowSaveAsDraft={initialValues?.draft}
              currentForm={questionsForm}
              currentStep={1}
              mode={mode}
              handlePreviousClick={handlePreviousClick}
              handleSaveAsDraft={() => {
                handleSaveAsDraft({
                  ...questionsForm.getFieldsValue(true),
                  draft_step: 1,
                });
              }}
            />
          )}
        </Form.Item>
      </Form>

      <Form.Provider onFormFinish={handleQuestionSubFormSubmit}>
        <AddTextQuestionForm
          visible={showTextQuestionForm}
          onCancel={hideTextModal}
          initialValues={currentQuestion}
        />
        <AddMcqQuestionForm
          visible={showMcqQuestionForm}
          onCancel={hideMcqModal}
          initialValues={currentQuestion}
        />
      </Form.Provider>
    </>
  );
}

export default CycleQuestionnaireForm;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { Modal, Alert, Card } from "antd";
import PropTypes from "prop-types";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import questions from "../common/form-components/questions/questionsData";
import { getHprSurveyDetailsApi, createHprSurveyApi } from "apis/hprSurveyApi";
import { errorNotification, successNotification } from "app/appUtils";
import HprSurveyBasicsForm from "../common/form-components/HprSurveyBasicsForm";

const { confirm } = Modal;

const EditHprSurvey = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [hprSurveyDetails, setHprSurveyDetails] = useState(null);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const { status, isLoading, isFetching } = useQuery(
    ["getHprSurveyDetails", id],
    getHprSurveyDetailsApi,
    {
      onSuccess: (data) => {
        const details = {
          ...data,
          due_date: moment(data?.due_date, "YYYY-MM-DD"),
          for_entire_company: data?.for_entire_company ? true : false,
        };
        setHprSurveyDetails({ ...details });
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [updateHprSurvey, { isLoading: isSaving }] = useMutation(
    createHprSurveyApi,
    {
      onSuccess: () => {
        successNotification("HPR Survey updated");
        history.push("/company/hpr-surveys");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleSubmit = (editedSurvey) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will updated the HPR survey (${
        hprSurveyDetails?.title || ""
      }).`,
      onOk() {
        const updatedHprSurvey = {
          id,
          ...editedSurvey,
          due_date: editedSurvey.due_date?.format("YYYY-MM-DD"),
          questions: questions,
        };
        delete updatedHprSurvey.employees;
        updateHprSurvey(updatedHprSurvey);
      },
      okText: "Update",
      onCancel() {},
    });
  };

  const loader =
    status === "loading" || isFetching || isLoading || isEmployeesLoading;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      <FullScreenLayout
        source={{ path: "/company/hpr-surveys", name: "HPR Surveys" }}
        title={
          hprSurveyDetails?.is_draft ? "Create HPR Survey" : "Edit HPR Survey"
        }
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            {!hprSurveyDetails?.is_draft && (
              <Alert
                message="You are editing a live HPR Survey"
                type="warning"
                showIcon
                closable
              />
            )}

            <HprSurveyBasicsForm
              mode="edit"
              initialValues={{ ...hprSurveyDetails }}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={() => {}}
              handleDone={handleSubmit}
              setHprSurvey={setHprSurveyDetails}
              isSaving={isSaving}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

EditHprSurvey.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
};

export default EditHprSurvey;

import axios from "axios";

const getCheckListApi = async () => {
  const { data } = await axios.get("/api/v2/admin/checklist");
  return data;
};

const updateCheckListApi = async ({ step }) => {
  const { data } = await axios.put("/api/v2/admin/checklist/step", { step });
  return data;
};

export { getCheckListApi, updateCheckListApi };

import React, { useEffect } from "react";
import moment from "moment";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Typography,
  Tooltip,
  InputNumber,
  Dropdown,
  Menu,
  Select,
  Switch,
} from "antd";
import { InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";

import { APIBasedEmployeeSearch, KPISelect } from "components";
import ConvertToBooleanKR from "../ConvertToBooleanKR";
import SetDeadline from "../SetDeadline";
import KeyResultDeadlinePicker from "../KeyResultDeadlinePicker";
import { filterOptionsByLabel } from "app/appUtils";

import styles from "../../OKRs.module.css";

const { Text } = Typography;
const { Option } = Select;

const BooleanSwitch = ({ value, handleBoolean }) => {
  return <Switch checked={value} onChange={handleBoolean} />;
};

const EditItem = ({
  visible = false,
  selectedPeriodObj = {},
  objectiveTitle = "",
  type = "key_result",
  keyResult = {},
  initiative = {},
  taggableKeyResults = [],
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();

  const isKeyResult = type === "key_result";

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResult?.title]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk({
          ...values,
          due_date: values?.due_date
            ? moment(values?.due_date, "YYYY-MM-DD")?.format("YYYY-MM-DD")
            : null,
        });
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={isKeyResult ? "Edit Key Result" : "Edit Initiative"}
      visible={visible}
      destroyOnClose
      width="1000px"
      keyboard={false}
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okText={`Update ${isKeyResult ? "Key Result" : "Initiative"}`}
    >
      <Row>
        <Text>
          {objectiveTitle ? `Objective Title: ${objectiveTitle}` : ""}
        </Text>
      </Row>
      <Form
        form={form}
        layout="vertical"
        className="editManagerForm"
        name="editManager"
        initialValues={{
          id: isKeyResult ? keyResult?.id || null : initiative?.id || null,
          title: isKeyResult
            ? keyResult?.title || null
            : initiative?.title || null,
          owner_id: isKeyResult
            ? keyResult?.owner?.id || null
            : initiative?.owner?.id || null,
          kpi_metric: isKeyResult
            ? keyResult?.kpi_metric || null
            : initiative?.kpi_metric || null,
          target: isKeyResult
            ? keyResult?.target || 0
            : initiative?.target || 0,
          start: isKeyResult ? keyResult?.start || 0 : initiative?.start || 0,
          is_boolean: isKeyResult
            ? keyResult?.is_boolean || false
            : initiative?.is_boolean || false,
          is_deadline: isKeyResult
            ? Boolean(keyResult?.due_date) || false
            : Boolean(initiative?.due_date) || false,
          due_date: isKeyResult
            ? keyResult?.due_date
              ? moment(keyResult?.due_date, "YYYY-MM-DD")
              : null
            : null,
          tagged_key_result_id: isKeyResult
            ? null
            : initiative?.tagged_key_result_id || null,
          taggableKeyResults: isKeyResult
            ? null
            : initiative?.taggableKeyResults || null,
        }}
      >
        <Form.Item name="id" hidden />
        <Row gutter={16} className="mt-16">
          <Col xs={{ span: 24 }} md={{ span: 16 }}>
            <Form.Item
              name="title"
              label="Name"
              rules={[
                { required: true, message: "Missing title." },
                {
                  whitespace: true,
                  message: "Title can't be empty.",
                },
              ]}
              required={false}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 3 }}
                className="input-textarea-resize-none"
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              name={"owner_id"}
              label="Owner"
              rules={[{ required: true, message: "Missing Owner." }]}
              required={false}
            >
              <APIBasedEmployeeSearch
                shouldShowAvatar={true}
                currentOwners={
                  isKeyResult
                    ? keyResult?.owner || null
                    : initiative?.owner || null
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item hidden name="is_boolean" />
        <Form.Item hidden name="is_deadline" />
        <Form.Item hidden name="due_date" />
        <Row gutter={8}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => {
              const isBooleanKr = getFieldValue("is_boolean");
              const isDeadlineSet = getFieldValue("is_deadline");
              const dueDate = getFieldValue("due_date");

              const handleBoolean = () => {
                setFieldsValue({ is_boolean: !isBooleanKr });
              };

              const handleSetDueDate = () => {
                setFieldsValue({ is_deadline: !isDeadlineSet });
              };

              const handleChange = () => {
                setFieldsValue({ is_deadline: !isDeadlineSet, due_date: null });
              };

              const handleRemoveDueDate = () => {
                setFieldsValue({ is_deadline: !isDeadlineSet, due_date: null });
              };

              const dueDateOnChange = (val) => {
                setFieldsValue({ due_date: val });
              };

              if (isBooleanKr) {
                return (
                  <>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      className="flex-display align-items-center"
                    >
                      <Text>{`This is a Yes/No ${
                        isKeyResult ? "Key Result" : "Initiative"
                      }`}</Text>
                    </Col>
                    {isDeadlineSet && (
                      <Col span={5} xxl={{ span: 4 }}>
                        <Form.Item
                          label="Due Date"
                          rules={[
                            {
                              required: true,
                              message: "Missing Deadline.",
                            },
                          ]}
                          required={false}
                        >
                          <KeyResultDeadlinePicker
                            krIndex={""}
                            value={dueDate}
                            selectedPeriodObj={selectedPeriodObj}
                            onChange={dueDateOnChange}
                            handleChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {isKeyResult ? (
                      <Col
                        xs={{ span: 10 }}
                        md={{ span: 6 }}
                        className={styles.formThreeDot}
                        style={{ marginBottom: "2px" }}
                      >
                        <Dropdown
                          overlay={
                            <Menu mode="vertical">
                              <Menu.Item key="set-dead-line">
                                <Form.Item noStyle>
                                  <SetDeadline
                                    krIndex={""}
                                    disabled={!isKeyResult}
                                    value={isDeadlineSet}
                                    onChange={handleSetDueDate}
                                    onRemoveDeadline={handleRemoveDueDate}
                                  />
                                </Form.Item>
                              </Menu.Item>

                              <Menu.Item key="boolean-kr">
                                <Form.Item noStyle>
                                  <ConvertToBooleanKR
                                    isKeyResult={isKeyResult}
                                    value={isBooleanKr}
                                    onChange={handleBoolean}
                                  />
                                </Form.Item>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                          placement="topLeft"
                        >
                          <MoreOutlined />
                        </Dropdown>
                      </Col>
                    ) : (
                      <Form.Item
                        className="ml-16"
                        name={"is_boolean"}
                        label="Is Yes/No Initiative"
                      >
                        <BooleanSwitch handleBoolean={handleBoolean} />
                      </Form.Item>
                    )}
                  </>
                );
              } else {
                return (
                  <>
                    <Col xs={{ span: 24 }} md={{ span: 5 }}>
                      <Form.Item
                        name={"kpi_metric"}
                        label={
                          <>
                            <Text type="secondary" className="mr-8">
                              KPI(Metric)
                            </Text>
                            <Tooltip title="This will be used as a prefix while checking in the KR. Examples: $, Revenue, %, profits, profit margin, Meetings">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </>
                        }
                        rules={[
                          { required: true, message: "Missing KPI." },
                          {
                            whitespace: true,
                            message: "KPI can't be empty.",
                          },
                        ]}
                        required={false}
                      >
                        <KPISelect />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 5 }}>
                      <Form.Item
                        name={"target"}
                        label="Target Value"
                        rules={[
                          {
                            required: true,
                            message: "Missing Target Value.",
                          },
                        ]}
                        required={false}
                      >
                        <InputNumber className="width-100-percent" />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 20 }} md={{ span: 5 }}>
                      <Form.Item
                        name={"start"}
                        label="Start Value"
                        rules={[
                          {
                            required: true,
                            message: "Missing Start Value.",
                          },
                        ]}
                        required={false}
                      >
                        <InputNumber className="width-100-percent" />
                      </Form.Item>
                    </Col>
                    {isDeadlineSet && (
                      <Col xs={{ span: 20 }} md={{ span: 5 }}>
                        <Form.Item
                          label="Due Date"
                          rules={[
                            {
                              required: true,
                              message: "Missing Deadline.",
                            },
                          ]}
                          required={false}
                        >
                          <KeyResultDeadlinePicker
                            krIndex={""}
                            value={dueDate}
                            selectedPeriodObj={selectedPeriodObj}
                            onChange={dueDateOnChange}
                            handleChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {isKeyResult ? (
                      <Col span={2} className={`${styles.formThreeDot} mb-24`}>
                        <Dropdown
                          overlay={
                            <Menu mode="vertical">
                              <Menu.Item key="set-dead-line">
                                <Form.Item noStyle>
                                  <SetDeadline
                                    krIndex={""}
                                    disabled={!isKeyResult}
                                    value={isDeadlineSet}
                                    onChange={handleSetDueDate}
                                    onRemoveDeadline={handleRemoveDueDate}
                                  />
                                </Form.Item>
                              </Menu.Item>

                              <Menu.Item key="boolean-kr">
                                <Form.Item noStyle>
                                  <ConvertToBooleanKR
                                    isKeyResult={isKeyResult}
                                    value={isBooleanKr}
                                    onChange={handleBoolean}
                                  />
                                </Form.Item>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                          placement="topLeft"
                        >
                          <MoreOutlined className="mt-15" />
                        </Dropdown>
                      </Col>
                    ) : (
                      <Form.Item
                        className="ml-16"
                        name={"is_boolean"}
                        label="Is Yes/No Initiative"
                      >
                        <BooleanSwitch handleBoolean={handleBoolean} />
                      </Form.Item>
                    )}
                  </>
                );
              }
            }}
          </Form.Item>
        </Row>
        <Row className="mt-16">
          {!isKeyResult && (
            <Col span={18}>
              <Form.Item name="tagged_key_result_id" label="Tagged Key Result">
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a Key Result"
                  optionFilterProp="label"
                  filterOption={filterOptionsByLabel}
                >
                  {taggableKeyResults?.map((keyRes) => (
                    <Option label={keyRes?.title} value={keyRes?.id}>
                      <Tooltip placement="topLeft" title={keyRes?.title}>
                        {keyRes?.title}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

EditItem.propTypes = {};

export default EditItem;

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Typography, Space } from "antd";

import templates from "./templatesData";

import styles from "../../../Survey.module.css";

const { Text, Title } = Typography;

const TemplateList = ({
  handleSelectedTemplate = () => {},
  setFieldsValue = () => {},
}) => {
  return (
    <Space direction="vertical">
      <Text type="secondary">
        Select one of the following templates for your questionnaire
      </Text>
      <Row gutter={[16, 8]}>
        {templates?.map((template) => (
          <Col span={8} className="cursor-pointer">
            <Card
              className={styles.templateCard}
              onClick={() => {
                // handleSelectedTemplate(template);
                setFieldsValue({
                  questions: template?.questions || [],
                  selectedTemplate: template,
                });
              }}
            >
              <Space direction="vertical">
                <Title level={3}>{template.name}</Title>
                <Text>{template.description}</Text>
                <Text type="secondary">{`Questions: ${template.questions.length}`}</Text>
              </Space>
            </Card>
          </Col>
        ))}
        <Col span={8}>
          <Card
            className={styles.customQuestionnaireCard}
            onClick={() => {
              handleSelectedTemplate({});
              setFieldsValue({
                questions: [],
                selectedTemplate: {
                  name: "Custom Questionnaire",
                  questions: [],
                },
              });
            }}
          >
            <Title level={3}>Custom Questionnaire</Title>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

TemplateList.propTypes = {
  handleSelectedQuestions: PropTypes.func,
};

export default TemplateList;

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

const { Text } = Typography;

const ThreeSixtyRevieweeStatusText = ({
  status = "",
  reviewers = [],
  canEmployeeNominate = false,
  isForHR = false,
}) => {
  const submittedReviewers =
    reviewers?.filter((reviewer) => reviewer?.status === "submitted") || [];
  switch (status) {
    case "not_started":
      return <Text type="danger">Not Started</Text>;
    case "nominating_reviewers":
      return <Text type="danger">Nominating Reviewers</Text>;
    case "finalizing_reviewers":
      return (
        <Text type="warning">
          {isForHR
            ? canEmployeeNominate
              ? "Reviewers Nominated"
              : "Manager Finalizing Reviewers"
            : canEmployeeNominate
            ? "Reviewers Nominated"
            : reviewers?.length === 0
            ? "Not Started"
            : "Finalizing Reviewers"}
        </Text>
      );
    case "collecting_feedback":
      return (
        <Text
          type={
            isForHR
              ? reviewers?.length === submittedReviewers?.length &&
                submittedReviewers?.length > 0
                ? "success"
                : "warning"
              : "success"
          }
        >
          {isForHR
            ? `Collecting Feedback (${submittedReviewers?.length || 0}/${
                reviewers?.length || 0
              })`
            : "Collecting Feedback"}
        </Text>
      );
    case "feedback_shared":
      return <Text type="success">Feedback Shared</Text>;
    default:
      return <Text type="danger">Not Started</Text>;
  }
};

ThreeSixtyRevieweeStatusText.propTypes = {
  status: PropTypes.string,
};

export default ThreeSixtyRevieweeStatusText;

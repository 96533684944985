import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import { ButtonWithSpinner } from "components";
import { useSetupCompletionPercentage } from "store";
import {
  getCompletionPercentage,
  getOrderedActiveSteps,
  errorNotification,
} from "app/appUtils";
import { getCheckListApi } from "apis/setupCheckListApi";

const GettingStartedBtn = ({ onClick = () => {} }) => {
  const [
    setupCompletionPercentage,
    setSetupCompletionPercentage,
  ] = useSetupCompletionPercentage();

  const { status, isLoading, isFetching } = useQuery(
    "getCheckList",
    getCheckListApi,
    {
      onSuccess: (data) => {
        const orderedActiveSetupSteps = getOrderedActiveSteps(
          data?.steps || []
        );
        setSetupCompletionPercentage(
          getCompletionPercentage(orderedActiveSetupSteps)
        );
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const isSpinning = status === "loader" || isLoading || isFetching;

  if (setupCompletionPercentage === "100") {
    return <></>;
  }

  return (
    <ButtonWithSpinner
      isSpinning={isSpinning}
      disabled={isSpinning}
      spinnerColor={"#5330C9"}
      className="mr-24"
      size="small"
      onClick={onClick}
    >
      {`Getting Started (${setupCompletionPercentage}%)`}
    </ButtonWithSpinner>
  );
};

GettingStartedBtn.propTypes = {
  onClick: PropTypes.func,
};

export default GettingStartedBtn;

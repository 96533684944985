import React from "react";
import PropTypes from "prop-types";
import { Row, Tag } from "antd";

import { statusText } from "./constants";

import styles from "../OKRs.module.css";

const SearchAndFilterTags = ({
  className = "",
  filters = {},
  setFilters = () => {},
}) => {
  const handleStatusClose = () => {
    setFilters({ ...filters, selectedStatuses: [] });
  };

  // const handleAlignClose = () => {
  //   setFilters({ ...filters, aligned: false });
  // };

  const handleArithmeticClose = () => {
    setFilters({
      ...filters,
      lessThan: { selected: false, value: 0 },
      greaterThan: { selected: false, value: 0 },
    });
  };

  const handleOwnersClose = () => {
    setFilters({ ...filters, owners: [], ownersInput: "" });
  };

  return (
    <Row className={className}>
      {filters?.selectedStatuses?.length > 0 && (
        <Tag
          className={styles.filterTag}
          closable
          onClose={handleStatusClose}
        >{`Status: ${
          filters?.selectedStatuses
            ?.map((status) => statusText[status])
            ?.join(", ") || ""
        }`}</Tag>
      )}
      {filters?.alignment?.length === 1 && (
        <Tag
          className={styles.filterTag}
          // closable
          // onClose={handleAlignClose}
        >{`Alignment: ${
          filters?.alignment?.includes("aligned") ? "Aligned" : ""
        } ${filters?.alignment?.length === 2 ? ", " : ""} ${
          filters?.alignment?.includes("not_aligned") ? "Not Aligned" : ""
        }`}</Tag>
      )}
      {(filters?.greaterThan?.selected || filters?.lessThan?.selected) && (
        <Tag
          className={styles.filterTag}
          closable
          onClose={handleArithmeticClose}
        >
          {`${
            filters?.greaterThan?.selected
              ? `More Than: ${filters?.greaterThan?.value}`
              : ""
          }`}
          {filters?.lessThan?.selected
            ? ` Less Than: ${filters?.lessThan?.value}`
            : ""}
        </Tag>
      )}
      {filters?.owners?.length > 0 && (
        <Tag
          className={styles.filterTag}
          closable
          onClose={handleOwnersClose}
        >{`Owners: ${
          filters?.owners?.map((owner) => owner?.name)?.join(", ") || ""
        }`}</Tag>
      )}
    </Row>
  );
};

SearchAndFilterTags.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default SearchAndFilterTags;

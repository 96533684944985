import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Modal, Timeline, Typography, Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { BoxLoader, EmployeeAvatarWithName } from "components";
import { formattedDateTime } from "app/appUtils";
import { getAnswerDetailsApi } from "apis/perfReviewApi";

const { Text } = Typography;

const getActivityDataSource = (
  logs = [],
  isRating,
  questionId,
  questionType,
  questionOptionHash
) => {
  const dataSource = [];
  if (isRating) {
    logs.forEach((log) => {
      const rating = log?.changes?.rating || [];
      const oldRating = rating[0];
      const newRating = rating[1];

      if (oldRating !== newRating) {
        dataSource.push({
          title: (
            <Text>
              <EmployeeAvatarWithName
                className="mr-8"
                employee={log?.updated_by}
                emptyText="An employee"
              />
              updated at
            </Text>
          ),
          created_at: log?.created_at,
          activity: (
            <Text className="mt-8 block-display">
              {oldRating || "None"}
              <ArrowRightOutlined className="mx-8" />
              {newRating || "None"}
            </Text>
          ),
        });
      }
    });
  } else {
    logs.forEach((log) => {
      const changedAnswers = log?.changes?.answers || [];
      const oldAnswers = changedAnswers[0] || {};
      const newAnswers = changedAnswers[1] || {};

      const isOldAnswersEmpty = !Boolean(changedAnswers[0]);
      const isNewAnswersEmpty = !Boolean(changedAnswers[1]);

      if (isOldAnswersEmpty && isNewAnswersEmpty) {
        //Do nothing
      } else {
        if (oldAnswers[questionId] !== newAnswers[questionId]) {
          dataSource.push({
            title: (
              <Text>
                <EmployeeAvatarWithName
                  className="mr-8"
                  employee={log?.updated_by}
                  emptyText="An employee"
                />
                updated at
              </Text>
            ),
            created_at: log?.created_at,
            activity: (
              <Text className="mt-8 block-display">
                {questionType === "PerfReviewMcq"
                  ? questionOptionHash[oldAnswers[questionId]] || "None"
                  : oldAnswers[questionId] || "None"}
                <ArrowRightOutlined className="mx-8" />
                {`${
                  questionType === "PerfReviewMcq"
                    ? questionOptionHash[newAnswers[questionId]] || "None"
                    : newAnswers[questionId] || "None"
                }`}
              </Text>
            ),
          });
        }
      }
    });
  }
  return dataSource;
};

const ViewPerfReviewAnswerHistory = ({
  visible,
  reviewId,
  isRating,
  assessmentId,
  questionId,
  questionType,
  questionOptionHash = {},
  onCancel,
}) => {
  const { data, isLoading, isFetching } = useQuery(
    ["getAnswerDetails", reviewId, assessmentId],
    getAnswerDetailsApi
  );

  const loader = isLoading || isFetching;

  const dataSource = getActivityDataSource(
    data?.logs || [],
    isRating,
    questionId,
    questionType,
    questionOptionHash
  );

  return (
    <Modal
      title="Answer Edit history"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {loader ? (
        <BoxLoader />
      ) : (
        <div
          styles={{
            maxHeight: "500px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="my-8" />
          <Timeline mode="left">
            {dataSource?.map((item) => (
              <Timeline.Item>
                <>
                  <div className="mb-8">
                    <Text>{item?.title || ""}</Text>
                    <Text type="secondary" className="ml-8">
                      {formattedDateTime(item?.created_at)}
                    </Text>
                  </div>
                  {item?.activity || ""}
                </>
              </Timeline.Item>
            ))}
          </Timeline>
          {dataSource?.length === 0 && (
            <Empty description="No Activity to show"></Empty>
          )}
        </div>
      )}
    </Modal>
  );
};

ViewPerfReviewAnswerHistory.propTypes = {
  reviewId: PropTypes.number,
  assessmentId: PropTypes.number,
};

export default ViewPerfReviewAnswerHistory;

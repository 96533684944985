import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";

import { BoxLoader } from "components";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { getSurveyDetailsApi } from "apis/surveyApi";
import AddSurveys from "../add/AddSurveys";
import { errorNotification } from "app/appUtils";

const CreateSurveyFromDraft = (props) => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ["getSurveyDetails", id],
    getSurveyDetailsApi,
    {
      cacheTime: 1,
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <FullScreenLayout
          source={{ path: "/company/surveys", name: "Surveys" }}
        >
          <BoxLoader />
        </FullScreenLayout>
      ) : (
        <AddSurveys
          mode="create_from_draft"
          initialValues={{
            ...data,
            draft: false,
            due_date: moment(data?.due_date, "YYYY-MM-DD"),
            selectedTemplate:
              data?.questions?.length > 0
                ? { name: "Draft Questions", questions: [] }
                : null,
            participants: data?.survey_participants?.map(
              (participation) => participation?.employee?.id
            ),
          }}
        />
      )}
    </>
  );
};

CreateSurveyFromDraft.propTypes = {};

export default CreateSurveyFromDraft;

import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";

import StepFormActionButtons from "../StepFormActionButtons";
import TemplateList from "./TemplateList";
import ComposeQuestionnaireForm from "./ComposeQuestionnaireForm";

const SurveyQuestions = ({
  mode = "new_survey",
  isSaving = false,
  initialValues = {},
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) => {
  const [form] = Form.useForm();

  const handleSelectedTemplate = (template) => {
    form.setFieldsValue({ selectedTemplate: template });
  };

  return (
    <Form
      className="mt-24"
      form={form}
      name="surveyQuestions"
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item shouldUpdate>
        {() => {
          const questions = form.getFieldValue("questions") || [];
          const selectedTemplate =
            form.getFieldValue("selectedTemplate") || null;

          if (!selectedTemplate) {
            return (
              <TemplateList
                handleSelectedTemplate={handleSelectedTemplate}
                setFieldsValue={form.setFieldsValue}
              />
            );
          }
          return (
            <ComposeQuestionnaireForm
              mode={mode}
              survey={initialValues}
              questions={questions}
              template={selectedTemplate}
              handleSelectedTemplate={handleSelectedTemplate}
              setFieldsValue={form.setFieldsValue}
            />
          );
        }}
      </Form.Item>
      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          isSaving={isSaving}
          currentForm={form}
          currentStep={1}
          shouldShowSaveAsDraft={initialValues?.draft}
          handlePreviousClick={handlePreviousClick}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 1 });
          }}
        />
      </Form.Item>
    </Form>
  );
};

SurveyQuestions.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
  handleSaveAsDraft: PropTypes.func,
  handleDone: PropTypes.func,
};

export default SurveyQuestions;

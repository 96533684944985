export const dateFormat = "YYYY-MM-DD";
export const OBJECTIVE_CHECKLIST_ID = 4;
export const REVIEWS_CHECKLIST_ID = 3;

export const degreeUnicodeString = "\u00b0";
export const degreeCharCode = 176;
export const threeSixtyDegreeFeedback = `360${degreeUnicodeString} Feedback`;
export const KPI_FEATURE_NAME = "KPI";

export const FEATURE_NAMES = {
  "perf-reviews": "Reviews",
  surveys: "Surveys",
  okrs: "Objectives and Key Results (OKRs)",
  praise: "Praise",
  "360-feedback": "360° Feedbacks",
  "continuous-feedback": "Continuous Feedbacks",
  "HPR Surveys": "HPR Surveys",
  "career-management": "Career Management",
};

export const plans = [
  {
    title: "FREE Plan",
    id: "free_plan",
    features: [
      "1. UPTO 5 active Users",
      "2. Full Access to all features",
      "3. $0 per month",
    ],
    hint: "You have x active users and they will loose access if you select this plan.",
    hintType: "danger",
    ctaGhost: true,
  },
  {
    id: "paid_plan",
    title: "PAID Plan",
    features: [
      "1. Unlimited active Users",
      "2. Full Access to all features",
      "3. $3 per user per month",
    ],
    hint: "Recommended plan since you have x active users.",
    hintType: "success",
    ctaGhost: false,
  },
  {
    id: "early_adopter_plan",
    title: "EARLY ADOPTER",
    features: [
      "1. Upto 100 active Users",
      "2. Full Access to all features",
      "3. $0 per month",
    ],
  },
];

export const planNames = {
  trial: "trial_plan",
  trialExpired: "trial_expired_plan",
  earlyAdopter: "early_adopter_plan",
  free: "free_plan",
  paid: "paid_plan",
};

export const planViewNames = {
  trial_plan: "Trial",
  trial_expired_plan: "Trial",
  early_adopter_plan: "Early Adopter",
  free_plan: "FREE",
  paid_plan: "PAID",
};

export const currencies = {
  usd: "USD",
  inr: "INR",
};

export const mobileTableScroll = { x: 550 };

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Select,
  Row,
  Col,
  Typography,
  Tooltip,
  Button,
  DatePicker,
} from "antd";

import { formattedDateShortYear } from "app/appUtils";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarIcon.svg";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const TimePeriodSelect = ({
  shouldShowCustom = false,
  periods = [],
  selectedPeriodId = "",
  handleSelectedPeriodChange = () => {},
}) => {
  const onChange = (val) => {
    handleSelectedPeriodChange(val);
  };

  return (
    <Select
      value={selectedPeriodId}
      className={"width-100-percent okr-time-period"}
      onChange={onChange}
      suffixIcon={
        <Row className="mt--4">
          <CalendarIcon />
        </Row>
      }
      style={{ flex: "initial" }}
    >
      {periods?.map((period) => (
        <Option value={period?.id}>
          <Row
            align="middle"
            justify="start"
            gutter={[4]}
            className="height-100-percent okr-time-period-row"
          >
            <Col>
              <Tooltip title={period?.title}>
                <Text>{`${period?.title}`}</Text>
              </Tooltip>
            </Col>
            <Col>
              <Text
                type="secondary"
                className="font-size-14"
                style={{ verticalAlign: "middle" }}
                ellipsis={true}
              >{`(${formattedDateShortYear(
                period?.from
              )} - ${formattedDateShortYear(period?.to)})`}</Text>
            </Col>
          </Row>
        </Option>
      ))}
      <>
        {shouldShowCustom && (
          <div onClick={(e) => e.stopPropagation()}>
            <CustomRangeSelect
              handleSelectedPeriodChange={handleSelectedPeriodChange}
            />
          </div>
        )}
      </>
    </Select>
  );
};

const CustomRangeSelect = ({ handleSelectedPeriodChange = () => {} }) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const handleOnChange = ([fromDate, toDate]) => {
    setFrom(fromDate);
    setTo(toDate);
  };

  const handleClick = () => {
    handleSelectedPeriodChange("custom", from, to);
  };

  return (
    <div className="flex-display">
      <RangePicker onChange={handleOnChange} />
      <Button type="link" onClick={handleClick}>
        Select
      </Button>
    </div>
  );
};

TimePeriodSelect.propTypes = {
  periods: PropTypes.array,
  selectedPeriodId: PropTypes.string,
  handleSelectedPeriodChange: PropTypes.func,
};

export default TimePeriodSelect;

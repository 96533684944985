import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryCache } from "react-query";
import {
  Row,
  Col,
  Typography,
  Space,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Divider,
  Tooltip,
  Grid,
  Checkbox,
  Dropdown,
  Menu,
} from "antd";
import Icon, {
  InfoCircleOutlined,
  PlusOutlined,
  UserOutlined,
  TeamOutlined,
  MoreOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  getObjectiveConfigApi,
  saveObjectiveApi,
  getObjectiveApi,
} from "apis/okrV2Api";
import { useCurrentCompany, useSetupCompletionPercentage } from "store";
import { saveTeamApi } from "apis/teamApi";
import {
  BoxLoader,
  // EmployeeSearchSelect,
  AddTeam,
  KPISelect,
  EmployeeAvatarGroup,
  APIBasedEmployeeSearch,
  InlineSpinner,
  EllipseText,
} from "components";
import AddParentOKRModal from "./AddParentOKRModal";
import ConvertToBooleanKR from "../ConvertToBooleanKR";
import SetDeadline from "../SetDeadline";
import KeyResultDeadlinePicker from "../KeyResultDeadlinePicker";
import { ReactComponent as GreenValidationIcon } from "assets/okrs/GreenValidation.svg";
import { ReactComponent as YellowValidationIcon } from "assets/okrs/YellowValidation.svg";
import { ReactComponent as CompanyIcon } from "assets/icons/company.svg";
import {
  filterOptions,
  // filterOptionsByLabel,
  successNotification,
  errorNotification,
  getInactiveTeamName,
  errorNotificationWithString,
} from "app/appUtils";

import styles from "../../OKRs.module.css";

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const stringHasNumber = (string) => /\d/.test(string);

const ValidationRow = ({ validation = false, text = "" }) => {
  return (
    <div className={styles.validationRow}>
      {validation > 0 ? <GreenValidationIcon /> : <YellowValidationIcon />}
      <Text className="ml-8">{text}</Text>
    </div>
  );
};
ValidationRow.propTypes = {
  validation: PropTypes.bool,
  text: PropTypes.string,
};

const KeyResultFormItemInput = ({
  index = 0,
  isBooleanKr = false,
  value = "",
  onChange = () => {},
}) => {
  return (
    <Row>
      <div>
        <Text>{`Key Result ${index + 1}`}</Text>
        <span
          className={
            isBooleanKr
              ? styles.greenDot
              : stringHasNumber(value)
              ? styles.greenDot
              : styles.yellowDot
          }
        />
      </div>
      <Input.TextArea
        autoSize={{ minRows: 1, maxRows: 3 }}
        className="input-textarea-resize-none"
        placeholder={"Ex: Hit quarterly revenue of  $100000"}
        value={value}
        onChange={onChange}
      />
    </Row>
  );
};
KeyResultFormItemInput.propTypes = {
  index: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const KeyResultOwner = ({
  index = 0,
  formMode = "add",
  initialValues = {},
  value,
  currentUser = {},
  availableOwners = [],
  ...props
}) => {
  let currentOwner = availableOwners?.filter((owner) => owner?.id === value)[0];

  if (formMode === "edit" && !currentOwner) {
    currentOwner = initialValues?.key_results[index]?.owner;
  }
  return (
    <APIBasedEmployeeSearch
      value={value}
      shouldShowAvatar={true}
      currentOwners={currentOwner || currentUser || {}}
      {...props}
    />
  );
};

const getOwnerIds = (initialValues = {}, teams = []) => {
  const typeId = initialValues?.type_id;
  const ownerIds = initialValues?.owner_ids;

  if (typeId === "OrganizationObjective" || typeId === "IndividualObjective")
    return ownerIds;

  const team = teams?.filter((team) => team?.id === typeId)[0];
  return [team?.owner?.id] || ownerIds;
};

const getDefaultOwners = (initialValues, currentUser = {}, teams = []) => {
  const typeId = initialValues?.type_id;

  if (typeId === "OrganizationObjective" || typeId === "IndividualObjective") {
    return [currentUser];
  }

  const team = teams?.filter((team) => team?.id === typeId)[0];
  if (team) {
    return [team?.owner] || [currentUser];
  }

  return initialValues?.currentOwners || [currentUser];
};

const getDefaultKeyResultOwner = (
  initialValues,
  currentUser = {},
  teams = []
) => {
  const typeId = initialValues?.type_id;

  if (typeId === "OrganizationObjective" || typeId === "IndividualObjective")
    return currentUser;

  const team = teams?.filter((team) => team?.id === typeId)[0];
  if (team) {
    return team?.owner || currentUser;
  }

  return initialValues?.currentOwners[0] || currentUser;
};

const OKRForm = ({
  mode = "add",
  initialValues = {},
  currentUserId = "",
  onSave = () => {},
  onCancel = () => {},
}) => {
  const queryCache = useQueryCache();
  const [currentCompany] = useCurrentCompany();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [setupCompletionPercentage] = useSetupCompletionPercentage();

  const [form] = Form.useForm();
  const [isAddTeamVisible, setIsAddTeamVisible] = useState(false);
  const [isAddParentOKRVisible, setIsAddParentOKRVisible] = useState(false);
  const [
    availableSearchedObjectiveOwners,
    setAvailableSearchedObjectiveOwners,
  ] = useState([]);
  const [parentObjective, setParentObjective] = useState(
    initialValues?.parentObjective
  );

  const initialParentObjective = initialValues?.parentObjective;
  useEffect(() => {
    setParentObjective(initialParentObjective);
  }, [initialParentObjective]);

  // const [employeeMap, setEmployeeMap] = useState({});

  const { data, status, isFetching, isLoading, refetch } = useQuery(
    "getObjectiveConfig",
    getObjectiveConfigApi,
    {
      cacheTime: 1,
      // onSuccess: (data) => {
      //   const employees = data?.employees || [];
      //   const empMap = {};
      //   employees.forEach((emp) => {
      //     empMap[emp?.id] = emp;
      //   });
      //   setEmployeeMap(empMap);
      //   return data;
      // },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const {
    status: parentObjectiveStatus,
    isLoading: isParentObjectiveLoading,
  } = useQuery(
    ["getParentObjective", initialValues?.parent_objective_id],
    getObjectiveApi,
    //TODO: Can be replaced with search API if BE accepts id of obj as well.
    {
      cacheTime: 1,
      enabled: mode === "add" && initialValues?.parent_objective_id,
      onSuccess: (data) => {
        setParentObjective({
          id: data?.id,
          objective_period_id: data?.objective_period_id,
          owners: data?.owners,
          owner_ids: data?.owner_ids,
          title: data?.title,
        });
      },
      onError: () => {
        errorNotificationWithString(
          "Something unexpected happened while retrieving parent OKR."
        );
      },
    }
  );

  const [saveTeam] = useMutation(saveTeamApi, {
    onSuccess: () => {
      successNotification("Saved Team");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleSaveTeam = (team) => {
    saveTeam({ team });
    handleAddTeam();
  };

  const handleIsAddParentOKRVisible = () => {
    setIsAddParentOKRVisible(!isAddParentOKRVisible);
  };

  const handleUpdateParentObjective = (newParentObjective) => {
    setParentObjective(newParentObjective);
  };

  const [saveObjective] = useMutation(saveObjectiveApi, {
    onSuccess: (_, requestVariables) => {
      successNotification("Saved objective");
      onSave();
      if (
        requestVariables?.objective?.parent_objective_id !==
        initialValues?.parentObjective
      ) {
        queryCache.invalidateQueries([
          "getObjectiveDetails",
          requestVariables?.objective?.parent_objective_id,
        ]);
      }

      if (mode === "add" && setupCompletionPercentage !== "100") {
        queryCache.invalidateQueries("getCheckList");
      }
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleAddTeam = () => {
    setIsAddTeamVisible(!isAddTeamVisible);
  };

  const handleSubmit = (values) => {
    const type_id = values.type_id;
    delete values?.typeIdError;
    if (type_id === "IndividualObjective") {
      const objective = { ...values, type: "IndividualObjective" };
      delete objective?.type_id;
      saveObjective({ objective });
    } else if (type_id === "OrganizationObjective") {
      const objective = { ...values, type: "OrganizationObjective" };
      delete objective?.type_id;
      saveObjective({ objective });
    } else {
      saveObjective({ objective: { ...values, type: "TeamObjective" } });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const loading = status === "loading" || isLoading || isFetching;

  const activeTimePeriod = data?.objective_periods?.filter(
    (period) => period?.is_active
  )[0];

  if (loading) {
    return <BoxLoader height="75vh" />;
  }

  const activeTeams = data ? data?.teams?.filter((team) => team?.active) : [];
  const inActiveTeams = data
    ? data?.teams?.filter((team) => !team?.active)
    : [];

  const allTopLevelOwners = [
    ...availableSearchedObjectiveOwners,
    ...getDefaultOwners(
      initialValues,
      currentCompany?.user || {},
      data?.teams || [],
      availableSearchedObjectiveOwners
    ),
  ];

  const uniqueAvailableOwners = [
    ...new Set(allTopLevelOwners.map((owner) => owner.id)),
  ].map((id) => {
    return allTopLevelOwners.find((owner) => owner?.id === id);
  });

  return (
    <>
      {isAddTeamVisible && (
        <AddTeam
          visible={isAddTeamVisible}
          onOk={handleSaveTeam}
          onCancel={handleAddTeam}
        />
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          ...initialValues,
          objective_period_id:
            initialValues?.objective_period_id || activeTimePeriod?.id,
          id: initialValues?.id || null,
          owner_ids:
            mode === "add"
              ? getOwnerIds(initialValues, data?.teams || [])
              : initialValues?.owner_ids || [],
          currentOwners: initialValues?.currentOwners || [],
          is_aspirational: initialValues?.is_aspirational || false,
          key_results:
            initialValues?.key_results?.map((kr) => ({
              ...kr,
              owner_id:
                mode === "add"
                  ? getDefaultKeyResultOwner(
                      initialValues,
                      currentCompany?.user || {},
                      data?.teams || []
                    )?.id
                  : kr?.owner_id,
            })) || [],
          key_result_converted_id:
            initialValues?.key_result_converted_id || null,
        }}
      >
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 17 }}
            className={styles.scrollableCol}
          >
            <Form.Item name="id" hidden />
            <Form.Item name="key_result_converted_id" hidden />
            <div>
              <Form.Item
                name="title"
                label="Objective Name"
                rules={[
                  { required: true, message: "Missing Name." },
                  {
                    whitespace: true,
                    message: "Name can't be empty.",
                  },
                ]}
                required={false}
              >
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  className="input-textarea-resize-none"
                  placeholder="Ex: Achieve record revenues while increasing profitability"
                />
              </Form.Item>
              <Row gutter={16}>
                <Col xs={{ span: 24 }} md={{ span: 7 }}>
                  <Form.Item name="type_id" hidden />
                  <Form.Item
                    // name="type_id"
                    label="Type"
                    // rules={[{ required: true, message: "Missing Type." }]}
                    // required={false}
                    shouldUpdate
                  >
                    {({ getFieldValue, setFieldsValue }) => {
                      const type_id = getFieldValue("type_id");
                      const handleChange = (val) => {
                        if (val) {
                          setFieldsValue({ type_id: val });
                          if (
                            val === "IndividualObjective" ||
                            val === "OrganizationObjective"
                          ) {
                            setFieldsValue({ owner_id: currentUserId });
                          } else {
                            const selectedTeam = data?.teams?.filter(
                              (team) => team?.id === val
                            )[0];
                            setFieldsValue({
                              owner_id: selectedTeam?.owner?.id,
                            });
                          }
                        } else {
                          handleAddTeam();
                        }
                      };
                      const [
                        correctedTeamId,
                        isTeamInactive,
                      ] = getInactiveTeamName(
                        type_id,
                        activeTeams,
                        inActiveTeams
                      );
                      return (
                        <>
                          <Select
                            value={correctedTeamId}
                            onChange={handleChange}
                          >
                            <Option value={"IndividualObjective"}>
                              <UserOutlined /> Individual
                            </Option>
                            <Option value={"OrganizationObjective"}>
                              <Icon component={CompanyIcon} /> Organization
                            </Option>
                            <OptGroup label="Teams">
                              {activeTeams?.map((team) => (
                                <Option value={team?.id}>
                                  <TeamOutlined />
                                  {team?.name}
                                </Option>
                              ))}
                            </OptGroup>

                            <div>
                              <Button
                                type="link"
                                size={screens.xxl ? "middle" : "small"}
                              >
                                <PlusOutlined />
                                Add Team
                              </Button>
                            </div>
                          </Select>
                          <Text type="danger">
                            {isTeamInactive && "Team can't be inactive"}
                          </Text>
                        </>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="owner_ids"
                    label={
                      <>
                        <Text className="mr-8">Owner</Text>
                        <Tooltip
                          title={
                            "Type at least 3 characters to search employees"
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    rules={[{ required: true, message: "Missing Owner." }]}
                    required={false}
                  >
                    <APIBasedEmployeeSearch
                      mode="multiple"
                      shouldShowAvatar={true}
                      currentOwners={
                        mode === "add"
                          ? uniqueAvailableOwners
                          : initialValues?.currentOwners || []
                      }
                      setAvailableEmployees={
                        setAvailableSearchedObjectiveOwners
                      }
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  name="objective_period_id"
                  hidden
                  rules={[{ required: true, message: "Missing When." }]}
                />
                <Col xs={{ span: 24 }} md={{ span: 9 }}>
                  <Form.Item
                    shouldUpdate
                    label={
                      <>
                        <Text className="mr-8">OKR Period</Text>
                        <Tooltip
                          title={
                            "OKR Period cannot be changed for child objectives"
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    rules={[
                      { required: true, message: "Missing When." },
                      ({ getFieldValue }) => ({
                        validator(rule, value, callback) {
                          try {
                            const parentObjectivePeriodId = getFieldValue(
                              "parent_objective_id"
                            );
                            const parentObjective = data?.objectives?.filter(
                              (objective) =>
                                objective?.id === parentObjectivePeriodId
                            )[0];
                            if (!parentObjectivePeriodId) {
                              callback();
                            }
                            if (
                              parentObjectivePeriodId &&
                              value !== parentObjective?.objective_period_id
                            ) {
                              callback(
                                "OKR Period cannot be changed for child objectives"
                              );
                            }
                            callback();
                          } catch (err) {
                            callback(err);
                          }
                        },
                      }),
                    ]}
                    required={false}
                  >
                    {/* <Text>{selectedTimePeriod?.name}</Text> */}
                    {({ getFieldValue, setFieldsValue, getFieldError }) => {
                      const parentObjectiveId = getFieldValue(
                        "parent_objective_id"
                      );
                      const objective_period_id = getFieldValue(
                        "objective_period_id"
                      );
                      const parentObjective = data?.objectives?.filter(
                        (objective) => objective?.id === parentObjectiveId
                      )[0];
                      const objectivePeriodIdErrors = getFieldError(
                        "objective_period_id"
                      );
                      const objectivePeriodIdError =
                        objectivePeriodIdErrors?.length > 0
                          ? objectivePeriodIdErrors[0]
                          : "";
                      return (
                        <>
                          <Select
                            className={
                              objectivePeriodIdError
                                ? "should-update-form-item-with-error"
                                : ""
                            }
                            value={objective_period_id}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOptions}
                            onChange={(val) =>
                              setFieldsValue({ objective_period_id: val })
                            }
                          >
                            {data?.objective_periods?.map((period) => (
                              <Option
                                disabled={
                                  parentObjectiveId
                                    ? parentObjective?.objective_period_id !==
                                      period?.id
                                    : false
                                }
                                value={period?.id}
                              >
                                {period?.name}
                              </Option>
                            ))}
                          </Select>
                          <Text type="danger">{objectivePeriodIdError}</Text>
                        </>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="parent_objective_id" hidden />
              <Form.Item
                // label={

                // }
                shouldUpdate
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const selectedPeriodId = getFieldValue("objective_period_id");
                  const currentTitle = getFieldValue("title");

                  const handleUpdateParentOKRId = (parent_objective_id) => {
                    setFieldsValue({ parent_objective_id });
                  };

                  const handleSetParentOKR = (parentObjective) => {
                    handleUpdateParentOKRId(parentObjective?.id);
                    handleUpdateParentObjective(parentObjective);
                    handleIsAddParentOKRVisible();
                  };

                  const handleRemoveParentObjective = () => {
                    setFieldsValue({ parent_objective_id: null });
                    setParentObjective(null);
                  };

                  return (
                    <>
                      {isAddParentOKRVisible && (
                        <AddParentOKRModal
                          currentTitle={currentTitle}
                          visible={isAddParentOKRVisible}
                          selectedPeriodId={selectedPeriodId}
                          onCancel={handleIsAddParentOKRVisible}
                          onSubmit={handleSetParentOKR}
                        />
                      )}
                      <Row align="middle">
                        <Text>Parent OKR (Optional)</Text>
                        <Button
                          type="link"
                          onClick={handleIsAddParentOKRVisible}
                        >
                          Select Parent OKR
                        </Button>
                        {parentObjectiveStatus === "loading" &&
                          isParentObjectiveLoading && (
                            <InlineSpinner fontSize={18} />
                          )}
                      </Row>
                      {parentObjective ? (
                        <Row align="middle" style={{ height: "35px" }}>
                          <EmployeeAvatarGroup
                            className="mr-8"
                            divClassName="inline-display height-90-percent"
                            employees={parentObjective?.owners || []}
                          />
                          <EllipseText
                            text={parentObjective?.title}
                            characterLimit={45}
                          />
                          <DeleteOutlined
                            onClick={handleRemoveParentObjective}
                          />
                        </Row>
                      ) : (
                        <Text type="secondary">No Parent OKR selected</Text>
                      )}
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item name="is_aspirational" hidden />
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const is_aspirational = getFieldValue("is_aspirational");

                  const handleChange = (e) => {
                    setFieldsValue({ is_aspirational: e.target.checked });
                  };

                  return (
                    <Checkbox checked={is_aspirational} onChange={handleChange}>
                      <span
                        className="mr-8"
                        role="img"
                        aria-label="rocket-launch"
                      >
                        🚀
                      </span>
                      Mark Objective as Aspirational
                    </Checkbox>
                  );
                }}
              </Form.Item>
              <Divider />
              <Title level={3}>Key Results</Title>
              <Form.List name="key_results">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => {
                      const allOwners = [
                        ...availableSearchedObjectiveOwners,
                        ...getDefaultOwners(
                          initialValues,
                          currentCompany?.user || {},
                          data?.teams || [],
                          availableSearchedObjectiveOwners
                        ),
                      ];

                      return (
                        <>
                          <Row gutter={16} className="mt-16">
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => {
                                const keyResults = getFieldValue("key_results");
                                const isBooleanKr =
                                  keyResults[index]?.is_boolean;
                                return (
                                  <Col xs={{ span: 24 }} md={{ span: 16 }}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "title"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing title.",
                                        },
                                        {
                                          whitespace: true,
                                          message: "Title can't be empty.",
                                        },
                                      ]}
                                      required={false}
                                    >
                                      <KeyResultFormItemInput
                                        index={index}
                                        isBooleanKr={isBooleanKr}
                                      />
                                    </Form.Item>
                                  </Col>
                                );
                              }}
                            </Form.Item>
                            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                              <Form.Item
                                {...field}
                                name={[field.name, "owner_id"]}
                                labelCol={{ style: { paddingBottom: "0px" } }}
                                label={
                                  <>
                                    <Text className="mr-8">Owner</Text>
                                    <Tooltip
                                      title={
                                        "Type at least 3 characters to search employees"
                                      }
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </>
                                }
                                rules={[
                                  { required: true, message: "Missing Owner." },
                                ]}
                                required={false}
                              >
                                <KeyResultOwner
                                  index={index}
                                  formMode={mode}
                                  initialValues={initialValues}
                                  shouldShowAvatar={true}
                                  availableOwners={allOwners}
                                  currentUser={currentCompany?.user}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={8}>
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue, setFieldsValue }) => {
                                const objectivePeriodId = getFieldValue(
                                  "objective_period_id"
                                );
                                const selectedPeriodObj = data?.objective_periods?.filter(
                                  (objPeriod) =>
                                    objPeriod?.id === objectivePeriodId
                                )[0];

                                const keyResults = getFieldValue("key_results");
                                const isBooleanKr =
                                  keyResults[index]?.is_boolean;
                                const isDeadlineSet =
                                  keyResults[index]?.is_deadline;

                                const handleChange = (krIndex) => {
                                  const keyResults = getFieldValue(
                                    "key_results"
                                  );
                                  const updatedKeyResults = keyResults?.map(
                                    (kr, index) => {
                                      if (krIndex === index) {
                                        return {
                                          ...kr,
                                          is_deadline: false,
                                          due_date: null,
                                        };
                                      }
                                      return kr;
                                    }
                                  );
                                  setFieldsValue({
                                    key_results: updatedKeyResults,
                                  });
                                };

                                const handleRemoveDueDate = (krIndex) => {
                                  const keyResults = getFieldValue(
                                    "key_results"
                                  );
                                  const updatedKeyResults = keyResults?.map(
                                    (kr, index) => {
                                      if (krIndex === index) {
                                        return {
                                          ...kr,
                                          is_deadline: false,
                                          due_date: null,
                                        };
                                      }
                                      return kr;
                                    }
                                  );
                                  setFieldsValue({
                                    key_results: updatedKeyResults,
                                  });
                                };

                                if (isBooleanKr) {
                                  return (
                                    <>
                                      <Col
                                        xs={{ span: 24 }}
                                        md={{ span: 13 }}
                                        className="flex-display align-items-center"
                                      >
                                        <Text>This is a Yes/No Key Result</Text>
                                      </Col>
                                      <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                        {isDeadlineSet && (
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "due_date"]}
                                            label="Due Date"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing Due Date.",
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <KeyResultDeadlinePicker
                                              krIndex={index}
                                              selectedPeriodObj={
                                                selectedPeriodObj
                                              }
                                              handleChange={handleChange}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                      <Col
                                        xs={{ span: 24 }}
                                        md={{ span: 6 }}
                                        className={styles.formThreeDot}
                                        style={{ marginBottom: "2px" }}
                                      >
                                        <Dropdown
                                          overlay={
                                            <Menu mode="vertical">
                                              <Menu.Item
                                                onClick={() => {
                                                  remove(field.name);
                                                }}
                                                key="delete"
                                              >
                                                <Text>Delete</Text>
                                              </Menu.Item>
                                              <Menu.Item key="set-dead-line">
                                                <Form.Item
                                                  noStyle
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "is_deadline",
                                                  ]}
                                                >
                                                  <SetDeadline
                                                    krIndex={index}
                                                    onRemoveDeadline={
                                                      handleRemoveDueDate
                                                    }
                                                  />
                                                </Form.Item>
                                              </Menu.Item>
                                              <Menu.Item key="boolean-kr">
                                                <Form.Item
                                                  noStyle
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "is_boolean",
                                                  ]}
                                                >
                                                  <ConvertToBooleanKR />
                                                </Form.Item>
                                              </Menu.Item>
                                            </Menu>
                                          }
                                          trigger={["click"]}
                                          placement="topLeft"
                                        >
                                          <MoreOutlined />
                                        </Dropdown>
                                      </Col>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "kpi_metric"]}
                                          label={
                                            <>
                                              <Text className="mr-8">
                                                KPI(Metric)
                                              </Text>
                                              <Tooltip title="This will be used as a suffix while checking in the KR. Examples: $, Revenue, %, profits, profit margin, Meetings">
                                                <InfoCircleOutlined />
                                              </Tooltip>
                                            </>
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message: "Missing KPI.",
                                            },
                                            {
                                              whitespace: true,
                                              message: "KPI can't be empty.",
                                            },
                                          ]}
                                          required={false}
                                        >
                                          <KPISelect />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "target"]}
                                          label="Target Value"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Missing Target Value.",
                                            },
                                          ]}
                                          required={false}
                                        >
                                          <InputNumber className="width-100-percent" />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "start"]}
                                          label="Start Value"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Missing Start Value.",
                                            },
                                          ]}
                                          required={false}
                                        >
                                          <InputNumber className="width-100-percent" />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                        {isDeadlineSet && (
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "due_date"]}
                                            label="Due Date"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing Due Date.",
                                              },
                                            ]}
                                            required={false}
                                          >
                                            <KeyResultDeadlinePicker
                                              krIndex={index}
                                              selectedPeriodObj={
                                                selectedPeriodObj
                                              }
                                              handleChange={handleChange}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                      <Col
                                        span={2}
                                        className={`${styles.formThreeDot} mb-24`}
                                      >
                                        <Dropdown
                                          overlay={
                                            <Menu mode="vertical">
                                              <Menu.Item
                                                onClick={() => {
                                                  remove(field.name);
                                                }}
                                                key="delete"
                                              >
                                                Delete
                                              </Menu.Item>
                                              <Menu.Item key="set-dead-line">
                                                <Form.Item
                                                  noStyle
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "is_deadline",
                                                  ]}
                                                >
                                                  <SetDeadline
                                                    krIndex={index}
                                                    onRemoveDeadline={
                                                      handleRemoveDueDate
                                                    }
                                                  />
                                                </Form.Item>
                                              </Menu.Item>

                                              <Menu.Item key="boolean-kr">
                                                <Form.Item
                                                  noStyle
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "is_boolean",
                                                  ]}
                                                >
                                                  <ConvertToBooleanKR />
                                                </Form.Item>
                                              </Menu.Item>
                                            </Menu>
                                          }
                                          trigger={["click"]}
                                          placement="topLeft"
                                        >
                                          <MoreOutlined className="mt-15" />
                                        </Dropdown>
                                      </Col>
                                    </>
                                  );
                                }
                              }}
                            </Form.Item>
                          </Row>
                          <Divider className={styles.keyResultDivider} />
                        </>
                      );
                    })}
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const ownerIds = getFieldValue("owner_ids");
                        const currentOwners = getDefaultOwners(
                          initialValues,
                          currentCompany?.user || {},
                          data?.teams || []
                        );

                        let firstOwner = currentOwners?.filter(
                          (owner) => owner?.id === ownerIds[0]
                        )[0];

                        if (!firstOwner) {
                          firstOwner = availableSearchedObjectiveOwners?.filter(
                            (owner) => owner?.id === ownerIds[0]
                          )[0];
                        }

                        return (
                          <Button
                            className="pl-0"
                            type="link"
                            onClick={() => {
                              add({
                                owner_id: firstOwner?.id || currentUserId,
                                kpi_metric: "%",
                                target: 100,
                                start: 0,
                                is_boolean: false,
                                // is_deadline: false,
                              });
                            }}
                          >
                            Add Another Key Result
                          </Button>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            {/* {mode === "edit" && initialValues?.sub_objectives?.length > 0 && (
              <>
                <Divider className={styles.keyResultDivider} />
                <Row>
                  <Text
                    type="secondary"
                    className="width-100-percent block-display mb-16"
                  >
                    Linked Sub Objectives:
                  </Text>
                  {initialValues?.sub_objectives?.map((obj) => {
                    return (
                      <Text className="width-100-percent block-display">
                        {obj?.title}
                      </Text>
                    );
                  })}
                </Row>
              </>
            )} */}
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 1 }} className={styles.dividerCol}>
            <Divider type="vertical" className={styles.verticalDivider} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => {
                const currentValues = getFieldsValue(true);
                const { title, key_results } = currentValues;

                const titleLessThan80 = title?.length < 80;

                const titleHasNumber = stringHasNumber(title);
                const allKeyResultsHaveNumbers = key_results?.every((kr) =>
                  stringHasNumber(kr?.title)
                );

                return (
                  <Space direction="vertical" size="large">
                    <Title level={3}>Best Practices</Title>
                    <Text>Objective</Text>
                    <ValidationRow
                      validation={title && !titleHasNumber}
                      text="Does not contain a number"
                    />
                    <ValidationRow
                      validation={title && titleLessThan80}
                      text="Should be less than 80 characters"
                    />

                    <Text>Key Results</Text>

                    <ValidationRow
                      validation={key_results?.length > 0}
                      text="At least 1 Key Result"
                    />
                    <ValidationRow
                      validation={
                        key_results?.length > 0 && allKeyResultsHaveNumbers > 0
                      }
                      text="Should be numeric"
                    />
                    <ValidationRow
                      validation={
                        key_results?.length > 0 && key_results?.length < 5
                      }
                      text="Not more than 5 Key results"
                    />
                  </Space>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={handleCancel} type="primary" ghost={true}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {mode === "edit" ? "Update OKR" : "Create OKR"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

OKRForm.propTypes = {
  initialValues: PropTypes.object,
};

export default OKRForm;

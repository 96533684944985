import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Card,
  Row,
  Col,
  Modal,
  Tooltip,
  Tag,
  Upload,
  Menu,
  Dropdown,
} from "antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";

import ViewAssessmentModal from "./ViewAssessmentModal";
import UploadResultModal from "./UploadResultModal";
import {
  DraftRibbon,
  PerfReviewExpandedRowRender,
  CustomExpandTable,
  ButtonWithSpinner,
} from "components";
import { useCurrentCompany } from "store";
import { useWindowWidth } from "hooks";
import AddPeersDrawer from "app/reviews/details/components/AddPeersDrawer";
import {
  deleteReviewApi,
  resetReviewApi,
  notifyParticipantsApi,
  searchReviewsApi,
  savePerfCycleApi,
  bulkDownloadPerfReviewDataApi,
  bulkUploadRatingsApi,
  rejectAppealApi,
  acceptAppealApi,
  submitAsReviewerApi,
} from "apis/perfReviewApi";
import UpdateReviewerModal from "./UpdateReviewerModal";
import NotificationModal from "./NotificationModal";
import AddReviewModal from "./AddReviewModal";
import SummaryRow from "./SummaryRow";
import SearchReviews from "./search/SearchReviews";
import { getNudgedReviews } from "./reviewUtils";
import {
  formattedDate,
  formattedDateShortYear,
  errorNotification,
  successNotification,
  getNameFromEmployeeObj,
  exportToXLSX,
} from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";

const { Title, Text } = Typography;
const { confirm } = Modal;

const ReviewCycleCard = ({
  reviewCycle = {},
  employees = [],
  inActiveEmployees = [],
  disabled = false,
  refetch = () => {},
}) => {
  const history = useHistory();
  const width = useWindowWidth();

  const [currentCompany] = useCurrentCompany();
  const [cycle, setCycle] = useState(reviewCycle || {});
  const [searchReviewsData, setSearchReviewsData] = useState(reviewCycle || {});

  const [viewAssessmentData, setViewAssessmentData] = useState(null);
  const [isAddReviewOpen, setIsAddReviewOpen] = useState(false);
  const [isNotifyEmailModalOpen, setIsNotifyEmailModalOpen] = useState(false);
  const [isEditReviewerModalOpen, setIsEditReviewerModalOpen] = useState(false);
  const [isNudging, setIsNudging] = useState(false);
  const [editReviewerReview, setEditReviewerReview] = useState();
  const [searchedEmployees, setSearchedEmployees] = useState([]);
  const [filters, setFilters] = useState({
    reviewee_statuses: [],
    reviewer_statuses: [],
  });
  const [selectedRowForPeerAddition, setSelectedRowForPeerAddition] =
    useState(null);
  const [uploadResult, setUploadResult] = useState(null);
  const [isUploadSpinning, setIsUploadSpinning] = useState(false);

  useEffect(() => {
    setCycle(reviewCycle);
  }, [reviewCycle]);

  const [resetReview] = useMutation(resetReviewApi, {
    onSuccess: () => {
      successNotification("Review reset Successful");
      refetch();
      handleSearch();
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [savePerfCycle] = useMutation(savePerfCycleApi, {
    onSuccess: () => {
      successNotification("Shared results successfully");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [searchReviews, { status: searchReviewsStatus }] = useMutation(
    searchReviewsApi,
    {
      onSuccess: (data) => {
        setSearchReviewsData(data);
        if (selectedRowForPeerAddition) {
          const selectedData = data?.filter(
            (review) => review.id === selectedRowForPeerAddition?.id
          );
          if (selectedData?.length > 0) {
            setSelectedRowForPeerAddition(selectedData[0]);
          }
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [deleteReview] = useMutation(deleteReviewApi, {
    onSuccess: () => {
      successNotification("Review deleted Successful");
      refetch();
      handleSearch();
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [notifyParticipants, { status: notificationStatus }] = useMutation(
    notifyParticipantsApi,
    {
      onSuccess: () => {
        successNotification("Notified Employees");
        handleNotifyEmailModalOpen();
        refetch();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [bulkDownloadAndExportData, { status: bulkDownloadingStatus }] =
    useMutation(bulkDownloadPerfReviewDataApi, {
      onSuccess: (data, variables) => {
        exportToXLSX(data, reviewCycle?.name);
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const [performBulkUpload] = useMutation(bulkUploadRatingsApi, {
    onSuccess: (data) => {
      successNotification("Successfully updated the rating");
      setUploadResult(data);
      setIsUploadSpinning(false);
    },
    onError: (err) => {
      errorNotification(err);
      setIsUploadSpinning(false);
    },
  });

  const [rejectAppeal] = useMutation(rejectAppealApi, {
    onSuccess: (data) => {
      successNotification("Successfully rejected appeal");
      refetch();
      handleSearch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [acceptAppeal] = useMutation(acceptAppealApi, {
    onSuccess: (data) => {
      successNotification("Successfully accepted appeal");
      refetch();
      handleSearch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [submitAsReviewer] = useMutation(submitAsReviewerApi, {
    onSuccess: (data) => {
      successNotification("Successfully submitted as reviewer");
      refetch();
      handleSearch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleReset = (reviewId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "This will reset the review",
      onOk() {
        resetReview({ reviewCycleId: cycle?.id, reviewId });
      },
      okText: "Reset",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleViewAssessmentOpen = (reviewData = {}) => {
    setViewAssessmentData(reviewData);
  };

  const handleViewAssessmentClose = (reviewData = {}) => {
    setViewAssessmentData(null);
  };

  const handleDeleteReview = (reviewId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "This will delete the review",
      onOk() {
        deleteReview({
          reviewId,
          reviewCycleId: cycle?.id,
        });
      },
      okText: "Delete",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleEditReviewerModalOpen = (review) => {
    setIsEditReviewerModalOpen(!isEditReviewerModalOpen);
    setEditReviewerReview(review);
  };

  const handleIsAddReviewOpen = () => {
    setIsAddReviewOpen(!isAddReviewOpen);
  };

  const handleAddReview = () => {
    handleIsAddReviewOpen();
  };

  const handleNotifyEmailModalOpen = () => {
    setIsNotifyEmailModalOpen(!isNotifyEmailModalOpen);
  };

  const handleSendEmail = () => {
    notifyParticipants({ reviewCycleId: cycle.id });
  };

  const handleNudgeSuccess = (data, type) => {
    const updatedSearchReviewsData = getNudgedReviews(
      searchReviewsData || [],
      data?.review_id,
      type,
      data?.nudge_at
    );

    setSearchReviewsData(updatedSearchReviewsData);
  };

  const handleEditCycle = () => {
    if (cycle?.draft) {
      history.push(`/company/reviews/${cycle?.id}/create_from_draft`);
    } else {
      history.push(`/company/reviews/${cycle?.id}/edit`);
    }
  };

  const handleSearch = () => {
    searchReviews({ reviewCycleId: cycle?.id, searchedEmployees, filters });
  };

  const handleAddAndFinalizePeers = (row) => {
    setSelectedRowForPeerAddition(row);
  };

  const handleRejectAppeal = (reviewId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "This will reject the appeal",
      onOk() {
        rejectAppeal({ reviewCycleId: cycle?.id, reviewId });
      },
      okText: "Reject",
      okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleAcceptAppeal = (reviewId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to accept this appeal",
      onOk() {
        acceptAppeal({ reviewCycleId: cycle?.id, reviewId });
      },
      okText: "Accept",
      // okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleSubmitAsReviewer = (reviewId) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to submit as reviewer?",
      onOk() {
        submitAsReviewer({ reviewCycleId: cycle?.id, reviewId });
      },
      okText: "Submit",
      // okButtonProps: { danger: true },
      onCancel() {},
    });
  };

  const handleGetAndExportReviewData = (params = {}) => {
    bulkDownloadAndExportData({ reviewCycleId: reviewCycle?.id, params });
  };

  const handleBulkUpload = (uploadedFileList) => {
    const formData = new FormData();
    formData.append("file", uploadedFileList[0]);
    performBulkUpload({ formData, reviewCycleId: reviewCycle?.id });
  };

  const handleShareResults = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "After sharing results employees will be able to see the feedback their peers and managers have given them.",
      onOk() {
        savePerfCycle({
          perf_review_cycle: { id: cycle?.id, shared: true },
        });
      },
    });
  };

  const isAddEmployeesDisabled =
    cycle?.summary?.participants_count >= employees?.length;
  const appealWindowStart = cycle?.appeal_window_start_date || null;
  const appealWindowEnd = cycle?.appeal_window_end_date || null;

  const isSearching = searchReviewsStatus === "loading";
  const isUploadResultVisible = Boolean(uploadResult);

  const bulkUploadOrgs =
    process?.env?.REACT_APP_PERF_REVIEW_BULK_UPLOAD_ORGS?.split(", ");

  const DownloadMenu = () => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          handleGetAndExportReviewData({ report_type: "status_report" });
        }}
      >
        Status Report
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          handleGetAndExportReviewData();
        }}
      >
        All Data
      </Menu.Item>
    </Menu>
  );

  return (
    <DraftRibbon hidden={!cycle?.draft}>
      <Card className={clsx("mt-8", "mb-8")}>
        {isUploadResultVisible && (
          <UploadResultModal
            isVisible={isUploadResultVisible}
            data={uploadResult}
            onCancel={() => {
              setUploadResult(null);
            }}
          />
        )}
        {isAddReviewOpen && (
          <AddReviewModal
            cycle={cycle}
            reviewCycleId={cycle?.id}
            visible={isAddReviewOpen}
            refetch={refetch}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
            handleCancel={handleIsAddReviewOpen}
            handleSubmit={handleAddReview}
            handleSearch={handleSearch}
          />
        )}

        {Boolean(selectedRowForPeerAddition) &&
          !selectedRowForPeerAddition?.peer_selection_finalized && (
            <AddPeersDrawer
              revieweeForPeers={selectedRowForPeerAddition}
              refetch={handleSearch}
              onClose={() => {
                handleAddAndFinalizePeers(null);
              }}
            />
          )}

        {Boolean(viewAssessmentData) && (
          <ViewAssessmentModal
            reviewData={viewAssessmentData}
            onCancel={handleViewAssessmentClose}
          />
        )}

        {isNotifyEmailModalOpen && (
          <NotificationModal
            cycle={cycle}
            isLoading={notificationStatus === "loading"}
            visible={isNotifyEmailModalOpen}
            handleCancel={handleNotifyEmailModalOpen}
            handleSubmit={handleSendEmail}
          />
        )}

        {isEditReviewerModalOpen && (
          <UpdateReviewerModal
            visible={isEditReviewerModalOpen}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
            onCancel={handleEditReviewerModalOpen}
            editReview={editReviewerReview}
            reviewCycleId={cycle?.id}
            refetch={refetch}
            handleSearch={handleSearch}
          />
        )}

        <Row justify="space-between" align="center">
          <div className="flex-display align-items-center">
            <Title
              className="inline-display"
              level={3}
            >{`${cycle?.name}`}</Title>
            <Text className="ml-24">{`${formattedDate(
              cycle?.period_from
            )} to ${formattedDate(cycle?.period_to)}`}</Text>
          </div>
          <div>
            {!cycle?.draft ? (
              <>
                {!cycle?.shared ? (
                  <Button
                    className={
                      disabled ? "primary-disabled-btn-text mr-8" : "mr-8"
                    }
                    disabled={disabled}
                    type="primary"
                    // ghost={true}
                    size="small"
                    onClick={handleShareResults}
                  >
                    Share Results
                  </Button>
                ) : (
                  <Tag>Results Shared</Tag>
                )}
                <Button
                  className={disabled ? "primary-disabled-btn-text" : ""}
                  disabled={disabled}
                  type="primary"
                  ghost={true}
                  size="small"
                  onClick={handleEditCycle}
                >
                  Edit Cycle
                </Button>
              </>
            ) : (
              <Button
                className={disabled ? "primary-disabled-btn-text" : ""}
                disabled={disabled}
                type="primary"
                size="small"
                ghost={true}
                onClick={handleEditCycle}
              >
                Continue working on this draft
              </Button>
            )}
          </div>
        </Row>

        <Row className="mt-16 mb-16" gutter={[24]} align="middle">
          {cycle?.has_peer_reviews && (
            <Col>
              <div>
                <Text type="secondary" className="block-display">
                  Adding Peer Due date
                </Text>
                <Text>{formattedDate(cycle?.deadline_for_adding_peer)}</Text>
              </div>
            </Col>
          )}
          <Col>
            <div>
              <Text type="secondary" className="block-display">
                Submit Due date
              </Text>
              <Text>{formattedDate(cycle?.deadline_for_submit)}</Text>
            </div>
          </Col>

          {appealWindowStart && appealWindowEnd && (
            <Col>
              <div>
                <Text type="secondary" className="block-display">
                  Appeal Window
                </Text>
                <Text>
                  {`${formattedDateShortYear(
                    appealWindowStart
                  )} - ${formattedDateShortYear(appealWindowEnd)}`}
                </Text>
              </div>
            </Col>
          )}

          <Col>
            <div>
              <Text type="secondary" className="block-display">
                Sign Due date
              </Text>
              <Text>{formattedDate(cycle?.deadline_for_sign)}</Text>
            </div>
          </Col>

          {cycle?.auto_sign_date && (
            <Col>
              <div>
                <Text type="secondary" className="block-display">
                  Auto Sign date
                </Text>
                <Text>{formattedDate(cycle?.auto_sign_date)}</Text>
              </div>
            </Col>
          )}

          <Col>
            <div>
              <Text type="secondary" className="block-display">
                Status
              </Text>
              <Text>
                {" "}
                {cycle?.draft
                  ? "Draft"
                  : cycle?.summary?.sign_and_close_percent === 100
                  ? "Completed"
                  : "Active"}
              </Text>
            </div>
          </Col>
          <Col>
            <div>
              <Text type="secondary" className="block-display">
                Created By
              </Text>
              <Text>{getNameFromEmployeeObj(cycle?.created_by) || "-"}</Text>
            </div>
          </Col>
          <Col>
            <div>
              <Text type="secondary" className="block-display">
                completion %
              </Text>
              <Text strong style={{ color: "#5330C9" }}>
                {Math.round(cycle?.summary?.sign_and_close_percent) || 0}%
              </Text>
            </div>
          </Col>
        </Row>

        <Row className="mb-24">
          <Dropdown
            overlay={DownloadMenu}
            // trigger={["click"]}
          >
            <ButtonWithSpinner
              disabled={bulkDownloadingStatus === "loading"}
              isSpinning={bulkDownloadingStatus === "loading"}
              spinnerColor={"#5330C9"}
              size="small"
              // type="primary"
              // ghost={true}
              onClick={(e) => e.preventDefault()}
            >
              Bulk Download <DownOutlined />
            </ButtonWithSpinner>
          </Dropdown>
          {bulkUploadOrgs?.includes(`${currentCompany?.id}`) && (
            <Upload
              accept={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              name="file"
              beforeUpload={(file, fileList) => {
                setIsUploadSpinning(true);
                handleBulkUpload(fileList);
                return false;
              }}
              showUploadList={false}
              // fileList={csvFileList}
              // previewFile={(file) => {
              //   console.log(file);
              // }}
              // onRemove={() => {
              //   setCsvFileList([]);
              // }}
            >
              <ButtonWithSpinner
                disabled={isUploadSpinning}
                isSpinning={isUploadSpinning}
                spinnerColor={"#5330C9"}
                className="ml-8"
                size="small"
                type="primary"
                ghost={true}
              >
                {isUploadSpinning ? "Uploading..." : "Bulk Upload"}
              </ButtonWithSpinner>
            </Upload>
          )}
        </Row>

        {!cycle?.draft && (
          <div>
            <Title className="mb-8" level={3}>
              Summary
            </Title>
            <Row>
              <Col span={12}>
                {cycle?.has_peer_reviews && (
                  <SummaryRow
                    isSmallScreen={width < 1100}
                    title="Add Peers to Reviews"
                    type="add_peer_percent"
                    titleClassName={
                      width < 1100 ? "width-120-px" : "width-180-px"
                    }
                    value={cycle?.summary?.add_peer_percent}
                    reviewCycleId={reviewCycle?.id}
                    canNudge={cycle?.summary?.add_peer_percent !== 100}
                  />
                )}
                <SummaryRow
                  isSmallScreen={width < 1100}
                  title="Reviewee Submissions"
                  type="reviewee_submission"
                  titleClassName={
                    width < 1100 ? "width-120-px" : "width-180-px"
                  }
                  value={cycle?.summary?.reviewee_submission_percent}
                  reviewCycleId={reviewCycle?.id}
                  canNudge={cycle?.summary?.reviewee_submission_percent !== 100}
                />
                <SummaryRow
                  isSmallScreen={width < 1100}
                  title="Sign & Close"
                  titleClassName={
                    width < 1100 ? "width-120-px" : "width-180-px"
                  }
                  type="sign_and_close"
                  value={cycle?.summary?.sign_and_close_percent}
                  reviewCycleId={reviewCycle?.id}
                  canNudge={
                    cycle?.summary?.sign_and_close_percent !== 100 &&
                    moment().isAfter(
                      moment(cycle?.deadline_for_submit, "YYYY-MM-DD")?.startOf(
                        "day"
                      )
                    )
                  }
                />
              </Col>
              <Col span={12}>
                {cycle?.has_peer_reviews && (
                  <SummaryRow
                    isSmallScreen={width < 1100}
                    title="Peer Submissions"
                    type="peer_submission_percent"
                    titleClassName={
                      width < 1100 ? "width-120-px" : "width-180-px"
                    }
                    value={cycle?.summary?.peer_submission_percent}
                    reviewCycleId={reviewCycle?.id}
                    canNudge={cycle?.summary?.peer_submission_percent !== 100}
                  />
                )}
                <SummaryRow
                  isSmallScreen={width < 1100}
                  title="Reviewer Submissions"
                  type="reviewer_submission"
                  titleClassName={
                    width < 1100 ? "width-120-px" : "width-180-px"
                  }
                  value={cycle?.summary?.reviewer_submission_percent}
                  reviewCycleId={reviewCycle?.id}
                  canNudge={cycle?.summary?.reviewer_submission_percent !== 100}
                />
              </Col>
            </Row>
          </div>
        )}

        {!cycle?.draft && <Title level={3}>Participants</Title>}
        <Row className="mt-16">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            {!cycle?.draft && (
              <SearchReviews
                isSearching={isSearching}
                searchedEmployees={searchedEmployees}
                filters={filters}
                searchReviewsLength={searchReviewsData?.length}
                participantsLength={cycle?.summary?.participants_count || 0}
                onClick={handleSearch}
                setSearchedEmployees={setSearchedEmployees}
                setFilters={setFilters}
              />
            )}
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Row justify="end" gutter={[16]}>
              <Col>
                {!cycle?.draft && !cycle?.are_emails_sent && (
                  <Button
                    className={
                      disabled
                        ? "primary-disabled-btn-text ml-8 mt-8"
                        : "ml-8 mt-8"
                    }
                    disabled={disabled}
                    type="primary"
                    ghost={true}
                    size="small"
                    onClick={handleNotifyEmailModalOpen}
                  >
                    Notify Employees
                  </Button>
                )}
              </Col>
              <Col>
                {!cycle?.draft && (
                  <>
                    <Button
                      className={
                        disabled
                          ? "primary-disabled-btn-text mt-8 mr-8"
                          : "mt-8 mr-8"
                      }
                      disabled={disabled || isAddEmployeesDisabled}
                      type="primary"
                      ghost={!isAddEmployeesDisabled}
                      size="small"
                      onClick={handleIsAddReviewOpen}
                    >
                      Add Reviewee
                    </Button>
                    {isAddEmployeesDisabled && (
                      <Tooltip title="All employees have already been added">
                        <QuestionCircleOutlined className="mt-8" />
                      </Tooltip>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {searchReviewsData?.length > 0 && (
          <CustomExpandTable
            loading={isNudging || isSearching}
            className={"mt-8 m-16 light-border"}
            columnParams={{
              type: "hr-admin",
              cycle,
              setIsNudging,
              handleEditReviewerModalOpen,
              handleNudgeSuccess,
              handleViewAssessment: handleViewAssessmentOpen,
              handleReset,
              handleDeleteReview,
              handleAcceptAppeal,
              handleRejectAppeal,
              handleSubmitAsReviewer,
            }}
            dataSource={searchReviewsData}
            rowKey={(row) => row?.id}
            expandable={{
              expandedRowClassName: () => "perf-review-expanded-row",
              expandIcon: ({ expanded, onExpand, record }) => null,
              expandedRowRender: (row) => (
                <PerfReviewExpandedRowRender
                  type="hr-admin"
                  cycle={cycle}
                  key={row?.id}
                  hasPeersEnabled={cycle?.has_peer_reviews}
                  row={row}
                  setIsNudging={setIsNudging}
                  handleNudgeSuccess={handleNudgeSuccess}
                  handleAddAndFinalizePeers={() =>
                    handleAddAndFinalizePeers(row)
                  }
                />
              ),
              expandIconColumnIndex: 2,
            }}
            // columns={getTableColumns(
            //   disabled,
            //   cycle,
            //   handleEditReviewerModalOpen,
            //   handleReset,
            //   handleDeleteReview,
            //   setIsNudging,
            //   handleNudgeSuccess,
            //   handleViewAssessmentOpen
            // )}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
            scroll={mobileTableScroll}
          />
        )}
      </Card>
    </DraftRibbon>
  );
};
ReviewCycleCard.propTypes = {
  cycle: PropTypes.object.isRequired,
};

// const getTableColumns = (
//   disabled = false,
//   cycle = {},
//   handleEditReviewerModalOpen = () => {},
//   handleReset = () => {},
//   handleDeleteReview = () => {},
//   setIsNudging = () => {},
//   handleNudgeSuccess = () => {},
//   handleViewAssessment = () => {}
// ) => {};

export default ReviewCycleCard;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Modal, Select, Button } from "antd";

import { ButtonWithSpinner } from "components";
import { updateSettingApi } from "apis/billingApi";
import { currencies } from "app/appConstants";
import { successNotification, errorNotification } from "app/appUtils";

const { Option } = Select;

const ChangeCurrency = ({
  isVisible = false,
  defaultCurrency = "usd",
  onCancel = () => {},
  refetchBillingDetails = () => {},
}) => {
  const [currency, setCurrency] = useState(defaultCurrency);

  const [
    updateSetting,
    {
      status: updatingStatus,
      isLoading: isUpdatingLoading,
      isFetching: isUpdatingFetching,
    },
  ] = useMutation(updateSettingApi, {
    onSuccess: () => {
      successNotification("Successfully updated Address");
      refetchBillingDetails();
      onCancel();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleCurrencyChange = (val) => {
    setCurrency(val);
  };

  const handleSubmit = () => {
    updateSetting({
      currency,
    });
  };

  const spinning =
    updatingStatus === "loading" || isUpdatingLoading || isUpdatingFetching;

  return (
    <Modal
      title="Change Currency"
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <ButtonWithSpinner
          isSpinning={spinning}
          disabled={spinning}
          type="primary"
          onClick={handleSubmit}
        >
          Change Currency
        </ButtonWithSpinner>,
        <Button className="ml-8" type="primary" ghost={true} onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Select defaultValue={currency} onChange={handleCurrencyChange}>
        {Object.entries(currencies)?.map(([key, val]) => (
          <Option value={key}>{val}</Option>
        ))}
      </Select>
    </Modal>
  );
};

ChangeCurrency.propTypes = {
  isVisible: PropTypes.bool,
  currency: PropTypes.string,
  onCancel: PropTypes.func,
  refetchBillingDetails: PropTypes.func,
};

export default ChangeCurrency;

import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Modal, Typography } from "antd";

import {
  BoxLoader,
  EmployeeAvatarWithDate,
  EmployeeAvatarGroup,
  ShowMoreText,
} from "components";
import { getGroupOfRequesterNames } from "../utils";
import { getRequestResponsesApi } from "apis/continuousFeedbackApi";

import styles from "../ContinuousFeedback.module.css";

const { Text } = Typography;

const ViewFeedbackModal = ({
  requestId = null,
  visible = false,
  onCancel = () => {},
}) => {
  const { data = {}, isLoading, isFetching } = useQuery(
    ["getRequestResponses", requestId],
    getRequestResponsesApi
  );

  const loader = isLoading || isFetching;

  const pendingRequests = [];
  const declinedRequests = [];
  const answeredRequests = [];

  if (data?.request_users) {
    data.request_users.forEach((user) => {
      switch (user?.status) {
        case "pending":
          pendingRequests.push(user);
          break;
        case "submitted":
          answeredRequests.push(user);
          break;
        case "declined":
          declinedRequests.push(user);
          break;
        default:
          return;
      }
    });
  }

  const declinedEmployees = declinedRequests?.map(
    (declinedReq) => declinedReq?.employee
  );
  const pendingEmployees = pendingRequests?.map(
    (pendingReq) => pendingReq?.employee
  );

  return (
    <Modal
      width="750px"
      title="View Feedback"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {loader ? (
        <BoxLoader />
      ) : (
        <div className={styles?.viewFeedbackModalBody}>
          <Text>Question: {data?.question}</Text>

          <div className="mt-16">
            {answeredRequests?.map((user) => (
              <div className="mb-16">
                <EmployeeAvatarWithDate
                  employee={user?.employee}
                  date={user?.feedback?.updated_at}
                />
                <div>
                  <ShowMoreText
                    className="mt-8"
                    charactersToShowOnLess={110}
                    text={user?.feedback?.feedback || ""}
                  />
                </div>
              </div>
            ))}
          </div>

          {pendingRequests?.length > 0 && (
            <div className="flex-display align-items-center mb-8">
              <EmployeeAvatarGroup
                divClassName="inline-flex-display"
                employees={pendingEmployees}
                maxCount={2}
              />
              <Text className="ml-8">
                Awaiting reply from {getGroupOfRequesterNames(pendingEmployees)}
                .
              </Text>
            </div>
          )}

          {declinedRequests?.length > 0 && (
            <div className="flex-display align-items-center mb-8">
              <EmployeeAvatarGroup
                divClassName="inline-flex-display"
                employees={declinedEmployees}
                maxCount={2}
              />
              <Text className="ml-8">
                {getGroupOfRequesterNames(declinedEmployees)}{" "}
                {declinedEmployees?.length === 1 ? "has" : "have"} declined your
                request.
              </Text>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

ViewFeedbackModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ViewFeedbackModal;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, DatePicker, Typography } from "antd";
// import { VList } from "virtuallist-antd";
import moment from "moment";

import {
  EmployeeAvatarWithName,
  SelectEmployeesRadioGroup,
  TreeTable,
} from "components";
import { getInitialTreeData } from "app/company/common/utils";
import StepFormActionButtons from "./StepFormActionButtons";

import styles from "../../Survey.module.css";

const { Text } = Typography;

const SurveyBasicsForm = ({
  mode = "create",
  initialValues = {},
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) => {
  const [form] = Form.useForm();

  const [treeData, setTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );
  const [initialTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );

  const handleResetSearch = () => {
    setTreeData(initialTreeData);
  };

  const handleSelect = (updatedKeys = []) => {
    form.setFieldsValue({
      participants: updatedKeys,
    });
  };

  const handleSelectAll = (allEmployeeIds = []) => {
    form.setFieldsValue({
      participants: allEmployeeIds,
    });
  };

  return (
    <Form
      className="mt-24"
      form={form}
      name="surveyBasics"
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Survey Name"
        rules={[
          {
            required: true,
            message: "Missing Survey Name",
          },
          {
            whitespace: true,
            message: "Survey Name cannot be Empty",
          },
        ]}
        required={false}
      >
        <Input className={styles.width75Percent} />
      </Form.Item>
      <Form.Item
        name="description"
        label={<Text type="secondary">Survey Description (Optional)</Text>}
        rules={[
          // {
          //   required: true,
          //   message: "Missing Survey Description",
          // },
          {
            whitespace: true,
            message: "Survey Description cannot be Empty",
          },
        ]}
        required={false}
      >
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          className="input-textarea-resize-none"
        />
      </Form.Item>

      <Form.Item
        name="due_date"
        label="Ends on:"
        rules={[
          {
            required: true,
            message: "Missing Ends on date",
          },
          {
            validator: (rule, value, callback) => {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isBefore(moment().startOf("day"))
                ) {
                  callback("End on cant be in the past");
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          },
        ]}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>

      <Form.Item
        name="for_entire_company"
        label="Who are the participants of this survey?"
      >
        <SelectEmployeesRadioGroup handleResetSearch={handleResetSearch} />
      </Form.Item>

      {/* <Form.Item hidden name="reviews"></Form.Item> */}
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.participants !== curValues?.participants ||
          prevValues?.for_entire_company !== curValues?.for_entire_company
        }
      >
        {() => {
          let participants = form.getFieldValue("participants") || [];
          return !form.getFieldValue("for_entire_company") ? (
            <TreeTable
              className="mb-24"
              columns={[
                {
                  title: "Employee",
                  dataIndex: "key",
                  key: "key",
                  render: (data, row) => {
                    return (
                      <EmployeeAvatarWithName
                        employee={row}
                        shouldShowInactive={true}
                      />
                    );
                  },
                },
              ]}
              selections={participants}
              selectedRowKeys={participants}
              dataSource={treeData}
              initialTreeData={initialTreeData}
              activeEmployees={employees}
              inActiveEmployees={inActiveEmployees}
              setTreeData={setTreeData}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              handleResetSearch={handleResetSearch}
            />
          ) : null;
        }}
      </Form.Item>

      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          currentStep={0}
          shouldShowSaveAsDraft={initialValues?.draft}
          handlePreviousClick={handlePreviousClick}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 0 });
          }}
        />
      </Form.Item>
    </Form>
  );
};

SurveyBasicsForm.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
  employees: PropTypes.array,
  handleSaveAsDraft: PropTypes.func,
  handleDone: PropTypes.func,
};

export default SurveyBasicsForm;

import React, { useState } from "react";
import { useQuery } from "react-query";
import { Popover, Menu, Divider } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import { PerfReviewDownloadAssessment, InlineSpinner } from "components";
import { appealed } from "app/reviews/reviewConstants";
import { getAdminReviewDetailsApi } from "apis/perfReviewApi";
import { errorNotification } from "app/appUtils";

const OptionsPopover = ({
  disabled = false,
  row = {},
  handleViewAssessment = () => {},
  handleReset = () => {},
  handleDeleteReview = () => {},
  handleAcceptAppeal = () => {},
  handleRejectAppeal = () => {},
  handleSubmitAsReviewer = () => {},
}) => {
  const [visible, setVisible] = useState(false);

  const {
    data: reviewData = {},
    status,
    isFetching,
    isLoading,
  } = useQuery(["getAdminReviewDetails", row?.id], getAdminReviewDetailsApi, {
    enabled: visible,
    onError: (error) => {
      errorNotification(error);
    },
  });

  const loader = status === "loading" || isFetching || isLoading;

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const isAppealed = row?.reviewee_status === appealed;

  const isReviewerSubmitted =
    row?.reviewer_status === "Submitted" || row?.reviewer_status === "Signed";

  return (
    <Popover
      trigger="click"
      placement="left"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={() =>
        document.getElementById("perf-review-popover-container")
      }
      content={
        <Menu style={{ border: "0px" }} selectedKeys={[]}>
          <Menu.Item
            key="view"
            disabled={disabled || loader}
            onClick={() => {
              handleViewAssessment(reviewData);
              handleVisibleChange(false);
            }}
          >
            {loader && <InlineSpinner fontSize={20} />}
            View Assessment
          </Menu.Item>
          <Divider className="my-0" />
          <Menu.Item
            key="download"
            disabled={loader}
            onClick={() => {
              handleVisibleChange(false);
            }}
          >
            <PerfReviewDownloadAssessment
              disabled={disabled}
              type="reviewer"
              reviewData={reviewData}
              loader={loader}
            />
          </Menu.Item>
          <Divider className="my-0" />
          {!isReviewerSubmitted && (
            <Menu.Item
              key="submit-as-reviewer"
              disabled={disabled}
              onClick={() => {
                handleSubmitAsReviewer(row?.id);
                handleVisibleChange(false);
              }}
            >
              Submit As Reviewer
            </Menu.Item>
          )}
          {!isReviewerSubmitted && <Divider className="my-0" />}
          <Menu.Item
            key="reset"
            disabled={disabled}
            onClick={() => {
              handleReset(row?.id);
              handleVisibleChange(false);
            }}
          >
            Reset Assessment
          </Menu.Item>
          <Divider className="my-0" />
          <Menu.Item
            key="delete"
            disabled={disabled}
            onClick={() => {
              handleDeleteReview(row?.id);
              handleVisibleChange(false);
            }}
          >
            Delete Assessment
          </Menu.Item>
          {isAppealed && <Divider className="my-0" />}
          {isAppealed && (
            <Menu.Item
              key="reject-appeal"
              disabled={disabled}
              onClick={() => {
                handleRejectAppeal(row?.id);
                handleVisibleChange(false);
              }}
            >
              Reject Appeal
            </Menu.Item>
          )}
          {isAppealed && <Divider className="my-0" />}
          {isAppealed && (
            <Menu.Item
              key="accept-appeal"
              disabled={disabled}
              onClick={() => {
                handleAcceptAppeal(row?.id);
                handleVisibleChange(false);
              }}
            >
              Accept Appeal
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <MoreOutlined />
    </Popover>
  );
};

OptionsPopover.propTypes = {};

export default OptionsPopover;

import React from "react";
import { Result } from "antd";
import { ReactComponent as Icon409 } from "assets/result/409.svg";

import { useCurrentCompany } from "store";

const DisabledFeature = (props) => {
  const [currentCompany] = useCurrentCompany();

  return (
    <Result
      icon={<Icon409 className="result-icon" />}
      title="Feature disabled"
      subTitle={`Sorry this feature is disabled for ${
        currentCompany?.name || "your organization"
      }. Please contact your HR-admin to enable it.`}
    />
  );
};

DisabledFeature.propTypes = {};

export default DisabledFeature;

import React from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip } from "antd";

const { Text } = Typography;

const EllipseText = ({ text = "", className = "", characterLimit = 25 }) => {
  const isMoreThanCharLimit = text?.length > characterLimit;

  return (
    <Tooltip title={isMoreThanCharLimit ? text : null}>
      <Text className={className}>
        {isMoreThanCharLimit ? `${text?.slice(0, characterLimit)}...` : text}
      </Text>
    </Tooltip>
  );
};

EllipseText.propTypes = {
  text: PropTypes.string,
  characterLimit: PropTypes.number,
};

export default EllipseText;

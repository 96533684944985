import React from "react";
import firebaseApp from "firebaseApp";
import { getAuth, signOut as firebaseSignOut } from "firebase/auth";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { capitalize } from "humanize-plus";
import * as JsCookies from "js-cookie";
import { notification } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { KPI_FEATURE_NAME } from "app/appConstants";

const signOut = async ({
  isInAppRedirect = false,
  queryCache = {},
  setCompanyList = () => {},
  setIsSignedOut = () => {},
}) => {
  setCompanyList([]);
  queryCache.clear();
  const linkedInPixel = document.getElementById("linkedin-pixel");
  const linkedInConversionPixel = document.getElementById(
    "linkedin-pixel-conversion"
  );

  localStorage.removeItem("currentCompanyId");
  if (linkedInPixel) {
    linkedInPixel.remove();
  }
  if (linkedInConversionPixel) {
    linkedInConversionPixel.remove();
  }

  const auth = getAuth(firebaseApp);
  await firebaseSignOut(auth).then(() => {
    setIsSignedOut(true);
    if (isInAppRedirect) {
      window.location = "/auth";
    } else {
      window.location = "https://culture.easy.xyz";
    }
  });
};

const errorNotification = (error = {}) => {
  const errorMessage = error?.response?.data?.message;
  if (errorMessage) {
    if (errorMessage === "Not allowed for Free Plan") {
      notification["error"]({
        message: "Error",
        description: (
          <span>
            You already have 5 active employees. Please upgrade your plan to
            <a className="mx-8" href="/settings/billing/plans">
              paid plan
            </a>
            to add unlimited employees or mark existing active employees as
            inactive
          </span>
        ),
        duration: null, // Setting duration to null will never close it
      });
    } else {
      notification["error"]({
        message: "Error",
        description: errorMessage,
        duration: null, // Setting duration to null will never close it
      });
    }
  } else {
    Sentry.captureMessage(
      `${window?.globalCurrentUserEmail} - status: ${error?.response?.status} - API: ${error?.config?.method} ${error?.config?.url} responded without message`,
      { extra: error }
    );
    notification["error"]({
      message: "Error",
      description: "Something unexpected happened please try again later!",
      duration: null, // Setting duration to null will never close it
    });
  }
};

const errorNotificationWithString = (errorString = "") => {
  notification["error"]({
    message: "Error",
    description:
      errorString || "Something unexpected happened please try again later!",
    duration: null, // Setting duration to null will never close it
  });
};

const infoNotification = (message) => {
  notification["info"]({
    message: "Info",
    description: message || "Info text",
  });
};

const warningNotification = (message) => {
  notification["warning"]({
    message: "Warning",
    description: message || "Warning text",
  });
};

const successNotification = (message) => {
  notification["success"]({
    message: "Success",
    description: message || "Success!",
  });
};

const getIsObjectiveCycleActive = (objectiveCycle) => {
  return moment(objectiveCycle?.to_date, "YYYY-MM-DD").isSameOrAfter(moment());
};

const getIsDateActive = (date) => {
  return moment(date, "YYYY-MM-DD").isSameOrAfter(moment().startOf("day"));
};

const formattedDate = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  return moment(dateString, "YYYY-MM-DD").format("MMM Do, YYYY");
};

const formattedDateShortYear = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  return moment(dateString, "YYYY-MM-DD").format("MMM D");
};

const formattedDateTime = (dateTimeString = "") => {
  if (!dateTimeString) {
    return "-";
  }
  const utcDateTime = moment
    .utc(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .toDate();
  return moment(utcDateTime).local().format("MMM Do, YYYY HH:mm");
};

const formattedMonthYear = (dateString = "") => {
  if (!dateString) {
    return "-";
  }
  const utcDateTime = moment
    .utc(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .toDate();
  return moment(utcDateTime).local().format("MMMM, YYYY");
};

const swapObjectKeysWithValues = (obj) => {
  const ret = {};
  for (let key in obj) {
    ret[obj[key]] = key;
  }
  return ret;
};

const getFirstWord = (sentence = "") => {
  if (!sentence) {
    return "";
  }

  const words = sentence?.split(" ") || [];
  if (words?.length > 0) {
    return words[0];
  }
  return "";
};

const getIsCompanyFeatureDisabled = (companyFeature, companyFeatures = []) => {
  const enabledFeatureKeys = [];
  // eslint-disable-next-line no-unused-expressions
  companyFeatures?.forEach((feature) => {
    if (feature?.enabled) {
      enabledFeatureKeys.push(feature?.name);
    }
  });

  return !enabledFeatureKeys?.includes(companyFeature);
};

const getCompletionPercentage = (orderedActiveSteps = []) => {
  const completedSteps = orderedActiveSteps?.filter(
    (step) => step?.completed
  ).length;
  const totalActiveSteps = orderedActiveSteps.length;
  const completePercentage = (
    (completedSteps / totalActiveSteps) *
    100
  ).toFixed(0);
  return completePercentage;
};

const getOrderedActiveSteps = (steps = []) => {
  const activeSteps = steps?.filter((step) => step?.active);
  const orderedActiveSteps = activeSteps?.sort((a, b) => a?.order - b?.order);
  return orderedActiveSteps;
};

const getEmployeeName = (emp = {}) => {
  if (emp?.name?.trim()) {
    return emp?.name?.trim();
  } else if (emp?.first_name?.trim() || emp?.last_name?.trim()) {
    return `${emp?.first_name} ${emp?.last_name}`;
  } else if (emp?.email?.trim()) {
    return emp?.email;
  }
  return "";
};

const getUserInitials = (name = "") => {
  if (name?.includes("@")) {
    const emailName = getNameFromEmail(name)?.slice(0, 2);
    return emailName?.toUpperCase();
  }
  return capitalize(name)
    ?.split(" ")
    .map((nameEl, i, arr) =>
      i === 0 || i + 1 === arr.length ? capitalize(nameEl)[0] : null
    )
    .join("");
};

const getNameFromEmail = (name = "") => {
  const nameFromEmail = name.split("@")[0];
  return nameFromEmail || "";
};

const getNameFromEmployeeObj = (employee) => {
  let name = getEmployeeName(employee);
  if (name?.includes("@")) {
    name = getNameFromEmail(name);
  }
  return name;
};

const getAuthorNameFromComment = (comment) => {
  let authorName =
    comment?.name || comment?.user_first_name || comment?.author_id || "";
  if (authorName.includes("@")) {
    authorName = getNameFromEmail(authorName);
  }

  return authorName;
};

const getCurrentUserName = (currentUser = {}) => {
  if (currentUser?.displayName) {
    return currentUser?.displayName;
  }
  return getNameFromEmail(currentUser?.email);
};

const replaceMacros = (
  originalString = "",
  macroToReplace = "",
  stringToReplace = ""
) => {
  const re = new RegExp(macroToReplace, "g");

  return originalString?.replace(re, stringToReplace);
};

const replaceMacrosWithName = (
  questionText = "",
  userFirstName = "",
  userLastName = ""
) => {
  const firstNameMacro = "%FIRST_NAME%";
  const lastNameMacro = "%LAST_NAME%";
  const fullNameMacro = "%FULL_NAME%";

  const withFirstName = replaceMacros(
    questionText,
    firstNameMacro,
    userFirstName
  );
  const withLastName = replaceMacros(
    withFirstName,
    lastNameMacro,
    userLastName
  );
  const withFullName = replaceMacros(
    withLastName,
    fullNameMacro,
    `${userFirstName} ${userLastName}`
  );

  return withFullName;
};

const getGroupOfNamesFromObjects = (empOjs = []) => {
  if (empOjs) {
    if (empOjs?.length > 1) {
      const empNames = empOjs?.map((emp) => `${getNameFromEmployeeObj(emp)}`);
      return empNames.join(",");
    } else if (empOjs?.length === 1) {
      return getNameFromEmployeeObj(empOjs[0]) || "";
    }
    return "";
  }
  return "";
};

const getGroupOfNames = (empNames = []) => {
  if (empNames) {
    if (empNames?.length > 1) {
      return empNames.join(", ");
    } else if (empNames?.length === 1) {
      return empNames[0] || "";
    }
    return "";
  }
  return "";
};

const filterOptions = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filterOptionsByLabel = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const setCookie = (name, value, days = 365) => {
  JsCookies.set(name, value, { expires: days, domain: ".easy.xyz", path: "/" });
};

const getCookie = (name) => {
  return JsCookies.get(name);
};

const eraseCookie = (name, domain = ".easy.xyz", path = "/") => {
  JsCookies.remove(name, { path, domain });
};

const getInactiveEmployeeName = (
  managerId,
  activeEmployees = [],
  inActiveEmployees = []
) => {
  if (!managerId) {
    return managerId;
  }

  const activeEmployeeIds = activeEmployees?.map((emp) => emp.id);
  if (activeEmployeeIds.includes(managerId)) {
    return managerId;
  }
  const inactiveEmployee = inActiveEmployees?.find(
    (emp) => emp?.id === managerId
  );

  if (inactiveEmployee) {
    return `(Inactive) ${getNameFromEmployeeObj(inactiveEmployee)}`;
  }

  return undefined;
};

const getIsEmpIdInactive = (empId, inActiveEmployees) => {
  const inactiveIds = inActiveEmployees?.map((emp) => emp?.id);
  return inactiveIds?.indexOf(empId) > -1;
};

const getInactiveTeamName = (teamId, activeTeams = [], inactiveTeams = []) => {
  if (!teamId) {
    return [teamId, false];
  }
  if (teamId === "OrganizationObjective" || teamId === "IndividualObjective") {
    return [teamId, false];
  }

  const activeTeamIds = activeTeams?.map((emp) => emp.id);
  if (activeTeamIds.includes(teamId)) {
    return [teamId, false];
  }

  const inactiveTeam = inactiveTeams?.find((team) => team?.id === teamId);
  return [`(Inactive) ${inactiveTeam?.name}`, true];
};

const getInactiveTeamNameForKPI = (
  teamId,
  activeTeams = [],
  inactiveTeams = []
) => {
  if (!teamId) {
    return [teamId, false];
  }
  if (teamId === "OrganizationKpi" || teamId === "IndividualKpi") {
    return [teamId, false];
  }

  const activeTeamIds = activeTeams?.map((emp) => emp.id);
  if (activeTeamIds.includes(teamId)) {
    return [teamId, false];
  }

  const inactiveTeam = inactiveTeams?.find((team) => team?.id === teamId);
  return [`(Inactive) ${inactiveTeam?.name}`, true];
};

const getEmployeeObjectsFromHash = (employeeIds = [], employeeHash = {}) => {
  return employeeIds?.map((id) => employeeHash[id] || {});
};

const getActiveEmployees = (empHash = {}) => {
  const allEmployees = Object.values(empHash);
  const activeEmployees = allEmployees?.filter((emp) => emp?.active);
  return activeEmployees;
};

const getEllipseText = (text = "", characterLimit = 25) => {
  const isMoreThanCharLimit = text?.length > characterLimit;
  return isMoreThanCharLimit ? `${text?.slice(0, characterLimit)}...` : text;
};

const roundToTwoDigits = (val = 0) => {
  return Math.round(((val || 0) + Number.EPSILON) * 100) / 100;
};

const roundToFourDigits = (val = 0) => {
  return Math.round(((val || 0) + Number.EPSILON) * 10000) / 10000;
};

const stripDecimalPoints = (i, digits = 2) => {
  var pow = Math.pow(10, digits);

  return Math.floor(i * pow) / pow;
};

const getIsMobile = (screens) => {
  return (screens?.sm && !screens?.md) || (screens?.xs && !screens?.sm);
};

const exportToXLSX = (csvData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const getKPIFeatureObj = (features = []) => {
  let feature = null;
  features.forEach((feat) => {
    if (feat?.name === KPI_FEATURE_NAME) {
      feature = feat;
    }
  });
  return feature;
};

const getWidthForChart = (width) => {
  if (width > 1075 && width < 1245) {
    return width / 2.25;
  }

  if (width <= 1075) {
    return width / 2.5;
  }

  return 575;
};

export {
  signOut,
  getFirstWord,
  errorNotification,
  errorNotificationWithString,
  infoNotification,
  warningNotification,
  successNotification,
  getIsObjectiveCycleActive,
  formattedDate,
  formattedDateShortYear,
  formattedDateTime,
  formattedMonthYear,
  getAuthorNameFromComment,
  swapObjectKeysWithValues,
  getIsCompanyFeatureDisabled,
  getCompletionPercentage,
  getOrderedActiveSteps,
  getEmployeeName,
  getNameFromEmail,
  getUserInitials,
  getNameFromEmployeeObj,
  getCurrentUserName,
  replaceMacros,
  replaceMacrosWithName,
  getIsDateActive,
  getGroupOfNames,
  getGroupOfNamesFromObjects,
  filterOptions,
  filterOptionsByLabel,
  setCookie,
  getCookie,
  eraseCookie,
  getInactiveEmployeeName,
  getIsEmpIdInactive,
  getInactiveTeamName,
  getInactiveTeamNameForKPI,
  getEmployeeObjectsFromHash,
  getActiveEmployees,
  getEllipseText,
  roundToTwoDigits,
  roundToFourDigits,
  stripDecimalPoints,
  getIsMobile,
  exportToXLSX,
  getKPIFeatureObj,
  getWidthForChart,
};

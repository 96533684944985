import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import {
  Row,
  Button,
  Card,
  Input,
  Col,
  Table,
  Typography,
  Dropdown,
  Menu,
  Form,
  Modal,
  DatePicker,
  Tag,
  Checkbox,
  Tooltip,
  List,
  Tabs,
} from "antd";
import {
  SearchOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
} from "@ant-design/icons";

import {
  BoxLoader,
  VimeoVideoModal,
  DisabledFeatureAlert,
  // TinyTutorialBtn,
} from "components";
// import OKRNavBar from "./common/OKRNavBar";
import OKRNotifications from "./notifications/OKRNotifications";
import OKRBulkUpload from "./bulk-upload/OKRBulkUpload";
import EnableFeatures from "./enable_disable/EnableFeatures";
import { useCurrentCompany, useSetupCompletionPercentage } from "store";
import {
  getObjectivePeriodsApi,
  savePeriodApi,
  deletePeriodApi,
  exportPeriodDataApi,
} from "apis/okrV2Api";
import { updateCheckListApi } from "apis/setupCheckListApi";
import {
  errorNotification,
  successNotification,
  getIsCompanyFeatureDisabled,
  formattedDate,
  exportToXLSX,
} from "app/appUtils";
import { OBJECTIVE_CHECKLIST_ID } from "app/appConstants";
import { mobileTableScroll } from "app/appConstants";

const { Text, Title } = Typography;
const { confirm } = Modal;
const { TabPane } = Tabs;

const prepareColumns = (
  handleDeletePeriod,
  handleEditPeriod,
  handleExport,
  handleMakeCurrent,
  handleLockPeriod,
  isFeatureDisabled
) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, row) => {
      return (
        <>
          <Text>{name}</Text>
          {row?.is_active && <Tag className="ml-8">current</Tag>}
          {row?.locked && (
            <Tooltip title={"This period is locked"}>
              <LockOutlined className="ml-8" />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    title: "Start",
    dataIndex: "start_date",
    key: "start_date",
    render: (start_date) => {
      return <Text>{formattedDate(start_date)}</Text>;
    },
  },
  {
    title: "End",
    dataIndex: "end_date",
    key: "end_date",
    render: (end_date) => {
      return <Text>{formattedDate(end_date)}</Text>;
    },
  },
  {
    title: "",
    render: (data) => (
      <Dropdown
        overlay={
          <Menu>
            {!data?.is_active && (
              <Menu.Item
                key="1"
                disabled={isFeatureDisabled}
                onClick={() => handleMakeCurrent(data)}
              >
                Make Current
              </Menu.Item>
            )}
            <Menu.Item
              key="2"
              disabled={isFeatureDisabled}
              onClick={() => handleLockPeriod(data)}
            >
              {data?.locked ? "Unlock Period" : "Lock Period"}
            </Menu.Item>
            <Menu.Item
              key="3"
              disabled={isFeatureDisabled}
              onClick={() => handleEditPeriod(data)}
            >
              Edit Period
            </Menu.Item>
            <Menu.Item
              key="4"
              disabled={isFeatureDisabled}
              onClick={() => handleExport(data)}
            >
              Export Data
            </Menu.Item>
            {!data?.is_active && (
              <Menu.Item
                key="5"
                disabled={isFeatureDisabled}
                onClick={() => handleDeletePeriod(data?.id)}
              >
                Delete Period
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        {
          <span className="pl-30">
            <MoreOutlined />
          </span>
        }
      </Dropdown>
    ),
  },
];

const AddPeriod = ({
  visible = false,
  period = null,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();
  const currentQuarter = moment().quarter();
  const startDateOfQuarter = moment()
    .quarter(currentQuarter)
    .startOf("quarter");
  const endDateOfQuarter = moment().quarter(currentQuarter).endOf("quarter");

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={period ? "Save Period" : "Add Period"}
      visible={visible}
      destroyOnClose
      width="1000px"
      keyboard={false}
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        className="editManagerForm"
        name="editManager"
        initialValues={{
          id: period?.id || null,
          name: period?.name || null,
          start_date: period?.start_date ? moment(period?.start_date) : null,
          end_date: period?.end_date ? moment(period?.end_date) : null,
          is_active: period?.is_active || false,
        }}
      >
        <Form.Item name="id" hidden />
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input name of the period",
            },
            {
              whitespace: true,
              message: "Period's name can't be empty",
            },
          ]}
          required={false}
        >
          <Input
            className="width-70-percent"
            placeholder={`Q${currentQuarter}-${moment().year()}`}
          />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item
              name="start_date"
              label="Start Date"
              rules={[
                {
                  required: true,
                  message: "Missing Start Date",
                },
              ]}
              required={false}
            >
              <DatePicker
                placeholder={startDateOfQuarter?.format("YYYY-MM-DD")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="end_date"
              label="End Date"
              rules={[
                {
                  required: true,
                  message: "Missing End Date",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value, callback) {
                    const startDate = getFieldValue("start_date");
                    try {
                      if (
                        startDate &&
                        moment(value, "YYYY-MM-DD")
                          .startOf("day")
                          .isBefore(startDate.startOf("day"))
                      ) {
                        callback("End Date cant be before Start Date");
                      }
                      callback();
                    } catch (err) {
                      callback(err);
                    }
                  },
                }),
              ]}
              required={false}
            >
              <DatePicker
                placeholder={endDateOfQuarter?.format("YYYY-MM-DD")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="is_active" hidden />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const periodId = getFieldValue("id");
            const isActive = getFieldValue("is_active");
            if (!periodId) {
              return (
                <Checkbox
                  value={isActive}
                  onChange={(e) => {
                    setFieldsValue({ is_active: e.target.checked });
                  }}
                >
                  Mark this period current?
                </Checkbox>
              );
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};
AddPeriod.propTypes = {};

const OKRs = () => {
  const history = useHistory();
  const { selectedSubTab: defaultSelectedTab = "time-periods" } = useParams();

  const [currentCompany] = useCurrentCompany();
  const [setupCompletionPercentage] = useSetupCompletionPercentage();
  const [isVimeoVideoVisible, setIsVimeoVideoVisible] = useState(true);
  const [isVimeoModalVisible, setIsVimeoModalVisible] = useState(false);
  const [isAddPeriodVisible, setIsAddPeriodVisible] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [editPeriod, setEditPeriod] = useState(null);

  const [selectedSubTab, setSelectedSubTab] = useState(
    defaultSelectedTab || "time-periods"
  );

  const handleTabChange = (teamId) => {
    setSelectedSubTab(teamId);
    history.push(`/company/okrs/${teamId}`);
  };

  useEffect(() => {
    if (!isVimeoVideoVisible) {
      setIsVimeoVideoVisible(true);
    }
  }, [isVimeoVideoVisible]);

  const { data, status, isLoading, isFetching, refetch } = useQuery(
    "getAllObjectivePeriods",
    getObjectivePeriodsApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [savePeriod] = useMutation(savePeriodApi, {
    onSuccess: () => {
      refetch();
      successNotification("Saved Period");
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [exportPeriodData, { status: exportPeriodStatus }] = useMutation(
    exportPeriodDataApi,
    {
      onSuccess: (data, variables) => {
        exportToXLSX(data, `${variables?.period?.name}'s OKRs`);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const [deletePeriod] = useMutation(deletePeriodApi, {
    onSuccess: () => {
      refetch();
      successNotification("Deleted Period");
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [updateCheckList] = useMutation(updateCheckListApi, {
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleIsVimeoModalVisible = () => {
    setIsVimeoModalVisible(!isVimeoModalVisible);
  };

  const handleUpdateCheckList = () => {
    if (setupCompletionPercentage !== "100") {
      updateCheckList({ step: OBJECTIVE_CHECKLIST_ID });
    }
  };

  const handleMakeCurrent = (period) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to mark this period as current period?",
      onOk() {
        savePeriod({ period: { ...period, is_active: true } });
      },
    });
  };

  const handleLockPeriod = (period) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text className="block-display">{`Do you want to ${
            period?.locked ? "unlock" : "lock"
          } this period?`}</Text>
          <div>
            <Text className="block-display mt-8">{`
          ${period?.locked ? "Unlocking" : "Locking"} will ${
              period?.locked ? "enable" : "disable"
            } the following:
          `}</Text>
            <List
              header={null}
              footer={null}
              dataSource={[
                "1. Creating and Editing OKRs",
                "2. Check ins",
                "3. Postponing a key result",
                "4. Changing owner of Objectives & Key results",
              ]}
              renderItem={(item) => (
                <Text className="block-display">{item}</Text>
              )}
            />
          </div>
        </>
      ),
      onOk() {
        savePeriod({ period: { ...period, locked: !period?.locked } });
      },
      okText: `${period?.locked ? "Unlock" : "Lock"}`,
      okButtonProps: { type: "danger" },
    });
  };

  const handleSavePeriod = (period) => {
    console.log({ period });
    savePeriod({
      period: {
        ...period,
        start_date: period?.start_date?.format("YYYY-MM-DD"),
        end_date: period?.end_date?.format("YYYY-MM-DD"),
      },
    });
    handleAddPeriodVisible();
  };

  const handleEditPeriod = (period) => {
    setEditPeriod(period);
    handleAddPeriodVisible();
  };

  const handleExport = (period) => {
    exportPeriodData({ period });
  };

  const handleDeletePeriod = (id) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "This will delete the Objective period. Also, this action cannot be undone",
      onOk() {
        deletePeriod({ id });
      },
      okButtonProps: { danger: true },
    });
  };

  const handleAddPeriodVisible = () => {
    if (isAddPeriodVisible) {
      setEditPeriod(null);
    }
    setIsAddPeriodVisible(!isAddPeriodVisible);
  };

  const handleSearchPeriod = (e) => {
    setSearchTerm(e?.target?.value);
  };

  const isFeatureDisabled = getIsCompanyFeatureDisabled(
    "Objectives and Key Results (OKRs)",
    currentCompany?.features
  );

  const loader = status === "loading" || isLoading || isFetching;

  let filteredPeriods = data ? [...data] : [];
  if (searchTerm) {
    filteredPeriods = data.filter(
      (period) =>
        period?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        formattedDate(period?.start_date)
          .toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        formattedDate(period?.end_date)
          .toLowerCase()
          .includes(searchTerm?.toLowerCase())
    );
  }

  const orderedFilteredProducts = filteredPeriods?.sort((a, b) => {
    if (a?.is_active) {
      return -1;
    }
    if (b?.is_active) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
      {isFeatureDisabled && <DisabledFeatureAlert featureName="Objectives" />}
      {isAddPeriodVisible && (
        <AddPeriod
          visible={isAddPeriodVisible}
          period={editPeriod}
          onOk={handleSavePeriod}
          onCancel={handleAddPeriodVisible}
        />
      )}

      <Row>
        <Col span={8}>
          <Title level={2}>OKRs</Title>
        </Col>
        <Col span={16} className="company-create-btn">
          <Row align="middle" justify="end" gutter={[16, 16]}>
            {/* <Col span={12}>
              <TinyTutorialBtn
                className="width-100-percent"
                text="Watch the tiny tutorial on OKRs"
                onClick={handleIsVimeoModalVisible}
              />
            </Col> */}

            <Col>
              <Button type="primary" disabled={isFeatureDisabled}>
                <Row align="top" onClick={handleAddPeriodVisible}>
                  Add Time Period
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <OKRNavBar /> */}

      {isVimeoModalVisible && (
        <VimeoVideoModal
          title="OKR Tutorial"
          src="https://player.vimeo.com/video/482934015?color=e02c4b&title=0&byline=0&portrait=0"
          visible={isVimeoModalVisible}
          onCancel={handleIsVimeoModalVisible}
          onEnd={() => {
            handleUpdateCheckList();
            handleIsVimeoModalVisible();
          }}
        />
      )}

      <Tabs
        activeKey={selectedSubTab || "time-periods"}
        onChange={handleTabChange}
      >
        <TabPane tab="Time Periods" key="time-periods">
          {loader ? (
            <BoxLoader />
          ) : (
            <Card
              title={
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                    <Text>Time Periods</Text>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Input
                      placeholder="Search Period"
                      onChange={handleSearchPeriod}
                      prefix={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              }
            >
              <Table
                loading={exportPeriodStatus === "loading"}
                columns={prepareColumns(
                  handleDeletePeriod,
                  handleEditPeriod,
                  handleExport,
                  handleMakeCurrent,
                  handleLockPeriod,
                  isFeatureDisabled
                )}
                dataSource={orderedFilteredProducts}
                scroll={mobileTableScroll}
                pagination={{
                  hideOnSinglePage: true,
                  size: "small",
                }}
              />
            </Card>
          )}
        </TabPane>
        <TabPane tab="Notifications" key="notifications">
          <OKRNotifications />
        </TabPane>
        <TabPane tab="Bulk Update OKRs" key="bulk-upload">
          <OKRBulkUpload />
        </TabPane>
        <TabPane tab="Enable/Disable Features" key="enable-disable">
          <EnableFeatures />
        </TabPane>
      </Tabs>
    </div>
  );
};

OKRs.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
};

export default OKRs;

import axios from "axios";

const getAllReviewCycleApi = async () => {
  const { data } = await axios.get("/api/v2/admin/threesixty_feedback_cycles/");
  return data;
};

const saveThreeSixtyFeedbackCycleApi = async ({
  threesixty_feedback_cycle,
}) => {
  const { data } = await axios.post(
    "/api/v2/admin/threesixty_feedback_cycles/",
    {
      threesixty_feedback_cycle,
    }
  );
  return data;
};

const getThreeSixtyCycleApi = async (_, cycleId) => {
  const { data } = await axios.get(
    `/api/v2/admin/threesixty_feedback_cycles/${cycleId}`
  );
  return data;
};

const getMyParticipationsApi = async () => {
  const { data } = await axios.get(
    "/api/v2/threesixty_feedback_participations/"
  );
  return data;
};

const updateReviewersApi = async ({ participationId, updatedReviewers }) => {
  const { data } = await axios.post(
    `/api/v2/threesixty_feedback_participations/${participationId}/reviewers`,
    {
      ...updatedReviewers,
    }
  );
  return data;
};

const updateReviewersAdminApi = async ({
  participationId,
  updatedReviewers,
}) => {
  const { data } = await axios.post(
    `/api/v2/admin/threesixty_feedback_participations/${participationId}/reviewers`,
    {
      ...updatedReviewers,
    }
  );
  return data;
};
const deleteReviewersApi = async ({ participationId, reviewerId }) => {
  const { data } = await axios.delete(
    `api/v2/threesixty_feedback_participations/${participationId}/reviewers/${reviewerId}`
  );
  return data;
};

const getThreeSixtyParticipationDetailsApi = async (_, participationId) => {
  const { data } = await axios.get(
    `/api/v2/threesixty_feedback_participations/${participationId}/my_reviews/`
  );
  return data;
};

const postThreeSixtyResponseApi = async ({
  participationId,
  answers,
  draft,
}) => {
  const { data } = await axios.post(
    `/api/v2/threesixty_feedback_participations/${participationId}/my_reviews/`,
    {
      answers,
      draft,
    }
  );
  return data;
};

const addThreeSixtyParticipantApi = async ({ cycleId, participants }) => {
  const { data } = await axios.post(
    `/api/v2/admin/threesixty_feedback_cycles/`,
    {
      threesixty_feedback_cycle: {
        id: cycleId,
        participants,
      },
    }
  );
  return data;
};

const notifyParticipantsApi = async ({ id }) => {
  const { data } = await axios.put(
    `/api/v2/admin/threesixty_feedback_cycles/${id}/notify_participants`
  );
  return data;
};

const deleteParticipationApi = async ({ cycleId, participationId }) => {
  const { data } = await axios.delete(
    `/api/v2/admin/threesixty_feedback_cycles/${cycleId}/participants/${participationId}`
  );
  return data;
};

const shareFeedbackApi = async ({ cycleId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/threesixty_feedback_cycles/${cycleId}/share_feedback`
  );
  return data;
};

const showAllFeedbackApi = async (_, participationId) => {
  const { data } = await axios.get(
    `/api/v2/threesixty_feedback_participations/${participationId}/`
  );
  return data;
};

const showAllAdminFeedbackApi = async (_, participationId) => {
  const { data } = await axios.get(
    `/api/v2/admin/threesixty_feedback_participations/${participationId}/`
  );
  return data;
};

const nudgeReviewersApi = async ({ participationId }) => {
  const { data } = await axios.put(
    `api/v2/admin/threesixty_feedback_participations/${participationId}/nudge`
  );
  return data;
};

export {
  getAllReviewCycleApi,
  saveThreeSixtyFeedbackCycleApi,
  getThreeSixtyCycleApi,
  getMyParticipationsApi,
  updateReviewersApi,
  deleteReviewersApi,
  getThreeSixtyParticipationDetailsApi,
  postThreeSixtyResponseApi,
  addThreeSixtyParticipantApi,
  notifyParticipantsApi,
  deleteParticipationApi,
  shareFeedbackApi,
  updateReviewersAdminApi,
  showAllFeedbackApi,
  showAllAdminFeedbackApi,
  nudgeReviewersApi,
};

import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const DoubleLineChart = ({ series, xAxisCategories }) => {
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 5],
      curve: "straight",
      dashArray: [8, 0],
      colors: ["grey", "#5330C9"],
    },
    colors: ["grey", "#5330C9"],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      // categories: xAxisCategories,
      // type: "datetime",
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      shared: true,

      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <div
      className="double-line-chart"
      id="chart"
      style={{ maxHeight: "140px" }}
    >
      <Chart
        options={options}
        series={series}
        type="line"
        height={140}
        width={300}
      />
    </div>
  );
};
DoubleLineChart.propTypes = {
  series: PropTypes.array,
  xAxisCategories: PropTypes.array,
};

export default DoubleLineChart;

const getEmployees = () => {
  return {
    active: [
      {
        id: 222,
        first_name: "Pranav",
        last_name: "PB",
        email: "pranav@beautifulcode.in",
        name: "Pranav PB",
        active: true,
        user_first_name: "Pranav PB",
        user_last_name: "",
        user_profile_pic_url:
          "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        manager: null,
      },
      {
        id: 223,
        first_name: "John",
        last_name: "Doe",
        email: "john_doe@easy.xyz",
        name: "John Doe",
        active: true,
        user_first_name: "John Doe",
        user_last_name: "",
        user_profile_pic_url: "https://app.culture.easy.xyz/assets/john.png",
        manager: {
          id: 222,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_first_name: "Pranav PB",
          user_last_name: "",
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
      },
      {
        id: 224,
        first_name: "Jane",
        last_name: "Doe",
        email: "jane_doe@easy.xyz",
        name: "Jane Doe",
        active: true,
        user_first_name: "Jane Doe",
        user_last_name: "",
        user_profile_pic_url: "https://app.culture.easy.xyz/assets/jane.png",
        manager: {
          id: 222,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_first_name: "Pranav PB",
          user_last_name: "",
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
      },
    ],
    inactive: [],
  };
};

export { getEmployees };

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useCurrentUser } from "store";
import { Redirect, useLocation } from "react-router-dom";
import firebaseApp, { samlAuthProvider } from "firebaseApp";
import { getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";

const AuthWithSAML = (props) => {
  const [currentUser] = useCurrentUser();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const client = query.get("q");

  const [firebaseError, setFirebaseError] = useState(null);
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        console.log(1, result);
        if (result?.credential) {
          // const credential = result?.credential || "";
          // const token = credential?.accessToken || "";
        }
        // const user = result?.user || "";
        // The signed-in user info.
        if (!firebaseError && !currentUser) {
          signInWithRedirect(auth, samlAuthProvider(client));
        }
      })
      .catch((error) => {
        console.log(2, error);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.email;
        // const credential = error.credential;
        setFirebaseError(
          error.code === "auth/operation-not-allowed" &&
            (error?.message ||
              "The identity provider configuration is not found.")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentUser) {
    const toPage = query.get("from_page");
    if (toPage) {
      return <Redirect to={toPage} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  if (firebaseError) {
    return <div>Not a valid provider</div>;
  }

  return <div>Redirecting to culture...</div>;
};

AuthWithSAML.propTypes = {};

export default AuthWithSAML;

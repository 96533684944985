import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import {
  Modal,
  Form,
  Divider,
  Typography,
  Select,
  Row,
  Col,
  Input,
  Button,
  Space,
} from "antd";
import "emoji-mart/css/emoji-mart.css";

import {
  BoxLoader,
  ButtonWithSpinner,
  EmployeeSearchSelect,
  EmojiTextInput,
} from "components";
import { iconOptionsMap } from "app/settings/praise-badges/common/constants";
import { savePraiseApi } from "apis/praiseApis";
import {
  errorNotification,
  successNotification,
  getIsEmpIdInactive,
} from "app/appUtils";

import styles from "../Praise.module.css";

const { Text, Title } = Typography;
const { Option } = Select;

const PraiseFormModal = ({
  visible = false,
  mode = "create",
  praise = {},
  badges = [],
  employees = [],
  inActiveEmployees = [],
  refetch = () => {},
  onClose = () => {},
  handleUpdatePaginationItem = () => {},
}) => {
  const [praiseForm] = Form.useForm();
  const [descError, setDescError] = useState("");

  const [savePraise, { status, isLoading, isFetching }] = useMutation(
    savePraiseApi,
    {
      onSuccess: (data) => {
        if (mode === "create") {
          successNotification("Successfully created praise");
          refetch();
        } else {
          successNotification("Successfully saved praise");
          handleUpdatePaginationItem(praise?.id, data);
          // refetch();
        }
        onClose();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  let initialValues = {
    praise_badge_id: badges[0]?.id,
    title: "",
    description: "",
    praised_employee_ids: [],
  };
  if (mode === "edit") {
    const praisedEmployees = praise?.praised_employees || [];
    const editPraisedEmployeeIds = praisedEmployees?.map(
      (empParticipation) => empParticipation?.employee || {}
    );
    initialValues = {
      praise_badge_id: praise?.praise_badge?.id || badges[0]?.id,
      title: `${praise?.title || ""}`,
      description: `${praise?.description || ""}`,
      praised_employee_ids: editPraisedEmployeeIds.map((emp) => emp.id) || [],
    };
  }

  const handleFinish = (values) => {
    const description = praiseForm.getFieldValue("description");
    if (description.trim() === "") {
      setDescError("Description cant be empty");
    } else {
      savePraise({
        praise:
          mode === "create"
            ? {
                ...values,
                description,
              }
            : {
                ...values,
                id: praise?.id,
                description,
              },
      });
    }
  };

  const isSaving = status === "loading" || isLoading || isFetching;

  return (
    <Modal
      visible={visible}
      footer={null}
      title={
        <Title level={3}>
          {mode === "create" ? "Give a Praise!" : "Edit Praise"}
        </Title>
      }
      onCancel={onClose}
      keyboard={false}
      width="1000px"
      destroyOnClose
    >
      {status === "loading" && <BoxLoader height="5vh" />}

      <Form
        form={praiseForm}
        layout="vertical"
        name="praiseForm"
        initialValues={initialValues}
        onFinish={handleFinish}
      >
        <Form.Item
          name="praise_badge_id"
          label="Badge"
          className={styles.width50Percent}
          rules={[
            {
              required: true,
              message: "Badge is required",
            },
          ]}
          required={false}
        >
          <Select
            className={"praise-badge-selector"}
            optionLabelProp="label"
            listHeight={512}
          >
            {badges?.map((badge) => (
              <Option
                value={badge?.id}
                label={
                  <Row
                    className={styles.praiseBadgeHeight}
                    justify="start"
                    align="middle"
                  >
                    <div className="create-praise-badge-icon">
                      {iconOptionsMap[badge?.icon]}
                    </div>
                    <Text className="ml-8">{badge?.title}</Text>
                  </Row>
                }
              >
                <Row justify="space-between">
                  <Col span={3}>
                    <Row
                      className={styles.height100Percent}
                      justify="center"
                      align="middle"
                    >
                      {iconOptionsMap[badge?.icon]}
                    </Row>
                  </Col>
                  <Col span={20}>
                    <Text
                      className="mt-8 block-display break-white-space"
                      strong
                    >
                      {badge?.title || ""}
                    </Text>
                    <Row className="width-100-percent">
                      <p className="break-white-space font-weight-400">
                        {badge?.description || ""}
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="praised_employee_ids"
          label="Who do you want to praise?"
          rules={[
            { required: true, message: "Employees List can't be empty!" },
            () => ({
              validator(rule, value, callback) {
                try {
                  const inActiveValues = value?.filter((empId) =>
                    getIsEmpIdInactive(empId, inActiveEmployees)
                  );
                  if (inActiveValues?.length > 0) {
                    callback("Employees can't be inactive");
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              },
            }),
          ]}
          className="width-60-percent"
          required={false}
        >
          <EmployeeSearchSelect
            mode="multiple"
            shouldShowAvatar={true}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
            {
              whitespace: true,
              message: "Title can't be empty",
            },
          ]}
          className="width-95-percent"
          required={false}
        >
          <Input />
        </Form.Item>
        <Form.Item
          id="praise-description-text-area"
          className="width-100-percent"
          shouldUpdate
        >
          {({ getFieldValue, setFieldsValue }) => {
            const descValue = getFieldValue("description");

            const handleChange = (newValue) => {
              setFieldsValue({
                description: newValue,
              });
            };

            return (
              <EmojiTextInput
                value={descValue}
                validationError={descError}
                onChange={handleChange}
              />
            );
          }}
        </Form.Item>

        <Divider className={styles.praiseDivider} />
        <Row justify="end">
          <Space>
            <Button disabled={isSaving} onClick={onClose}>
              Cancel
            </Button>
            <ButtonWithSpinner
              disabled={isSaving}
              isSpinning={isSaving}
              type="primary"
              htmlType="submit"
            >
              {mode === "create" ? "Create Praise" : "Edit Praise"}
            </ButtonWithSpinner>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

PraiseFormModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
  praise: PropTypes.object,
  badges: PropTypes.array,
  employees: PropTypes.array,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
  handleUpdatePaginationItem: PropTypes.func,
};

export default PraiseFormModal;

import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Card, Typography, Switch, Row } from "antd";

import { useCurrentCompany } from "store";
import { updateEnabledFeatureApi } from "apis/enableFeaturesApi";
import {
  successNotification,
  errorNotification,
  getKPIFeatureObj,
} from "app/appUtils";

const { Text, Title } = Typography;

const EnableFeatures = (props) => {
  const [currentCompany, setCurrentCompany] = useCurrentCompany();
  const [kpiFeature, setKpiFeature] = useState(null);

  const features = currentCompany?.features || [];
  useEffect(() => {
    const kpiFeature = getKPIFeatureObj(features);
    setKpiFeature(kpiFeature);
  }, [features]);

  const [updateEnabledFeature, { isLoading }] = useMutation(
    updateEnabledFeatureApi,
    {
      onSuccess: (data) => {
        const updatedFeatures = data?.features || [];
        setCurrentCompany({
          ...currentCompany,
          features: [...updatedFeatures],
        });
        successNotification("Saved Successfully");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleKPIChange = (checked) => {
    updateEnabledFeature({
      features: [{ id: kpiFeature?.id, enabled: checked }],
    });
  };

  return (
    <Card>
      <Title level={3}>Features in OKRs</Title>
      <Row align="middle">
        <Text className="mr-16">KPIs</Text>
        <Switch
          disabled={isLoading}
          loading={isLoading}
          checked={kpiFeature?.enabled}
          onChange={handleKPIChange}
        />
      </Row>
    </Card>
  );
};

EnableFeatures.propTypes = {};

export default EnableFeatures;

import React from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

import logo from "assets/logo.svg";

import styles from "./PageSpinner.module.css";

const PageSpinner = () => {
  const antIcon = (
    <Loading3QuartersOutlined className={styles.antIcon3Quarters} spin />
  );

  return (
    <div className={styles.spinnerContainer}>
      <Spin indicator={antIcon} className="pb-24" />
      <img src={logo} alt="logo" />
    </div>
  );
};

export default PageSpinner;

import React from "react";
import PropTypes from "prop-types";
import { Row, Typography } from "antd";

const { Text } = Typography;

const OptionalFormItemLabel = ({ label = "" }) => {
  return (
    <Row className="width-100-percent" justify="space-between">
      <Text>{label}</Text>
      <Text type="secondary">(Optional)</Text>
    </Row>
  );
};

OptionalFormItemLabel.propTypes = {
  label: PropTypes.string,
};

export default OptionalFormItemLabel;

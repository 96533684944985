import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useQuery } from "react-query";
import { Card, Typography, Select, Row, Table, Tag, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";

import { BoxLoader } from "components";
import {
  getSuperAdminGroupsApi,
  getSuperAdminGroupUsageApi,
} from "apis/appApi";
import { filterOptionsByLabel, formattedDate } from "app/appUtils";
import { mobileTableScroll, planViewNames } from "app/appConstants";

const { Title, Text } = Typography;
const { Option } = Select;

const okrColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, row) => {
      return (
        <>
          <Text>{name}</Text>
          {row?.is_active && <Tag className="ml-8">current</Tag>}
          {row?.locked && (
            <Tooltip title={"This period is locked"}>
              <LockOutlined className="ml-8" />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    title: "Start",
    dataIndex: "start_date",
    key: "start_date",
    render: (start_date) => {
      return <Text>{formattedDate(start_date)}</Text>;
    },
  },
  {
    title: "End",
    dataIndex: "end_date",
    key: "end_date",
    render: (end_date) => {
      return <Text>{formattedDate(end_date)}</Text>;
    },
  },
  {
    title: "# of Objectives",
    dataIndex: "objective_count",
    key: "objective_count",
    render: (objective_count) => {
      return <Text>{objective_count}</Text>;
    },
  },
  {
    title: "# of KRs",
    dataIndex: "key_result_count",
    key: "key_result_count",
    render: (key_result_count) => {
      return <Text>{key_result_count}</Text>;
    },
  },
  {
    title: "# of Check-ins",
    dataIndex: "checkin_count",
    key: "checkin_count",
    render: (checkin_count) => {
      return <Text>{checkin_count}</Text>;
    },
  },
];

const featuresColumns = [
  {
    title: "Feature Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (active) => {
      return <Text>{active ? "Yes" : "No"}</Text>;
    },
  },
  {
    title: "Cycles",
    dataIndex: "cycles",
    key: "cycles",
    render: (cycles) => {
      return <Text>{cycles !== undefined ? cycles : "N/A"}</Text>;
    },
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const SuperAdmin = (props) => {
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  const {
    data: groups,
    status: groupsStatus,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = useQuery("getSuperAdminGroups", getSuperAdminGroupsApi);

  const { data, status, isLoading, isFetching } = useQuery(
    ["getSuperAdminGroupUsage", selectedOrgId],
    getSuperAdminGroupUsageApi,
    {
      enabled: selectedOrgId,
    }
  );

  const groupsLoader =
    groupsStatus === "loading" || isGroupsLoading || isGroupsFetching;

  const detailsLoader = status === "loading" || isLoading || isFetching;

  const descendingGroups =
    groups?.sort((a, b) => Number(b?.id || 0) - Number(a?.id || 0)) || [];

  if (groupsLoader) {
    return <BoxLoader />;
  }

  console.log({ data });

  return (
    <Card>
      <Title level={4}>Super Admin</Title>

      <Text type="secondary" className="block-display mt-4">
        Org Dropdown
      </Text>
      <Select
        className="width-250-px"
        placeholder="Search Organization"
        value={selectedOrgId}
        showSearch
        allowClear={true}
        optionFilterProp="label"
        filterOption={filterOptionsByLabel}
        onChange={setSelectedOrgId}
      >
        {descendingGroups?.map((org) => {
          const label =
            `${org?.name} (${
              org?.plan === "trial_expired_plan"
                ? "Trial Expired Plan"
                : planViewNames[org?.plan]
            })` || "-";
          return (
            <Option value={org?.id} key={org?.id} label={label}>
              {label}
            </Option>
          );
        })}
      </Select>

      {detailsLoader ? (
        <BoxLoader />
      ) : (
        <>
          {data ? (
            <>
              <Text type="secondary" className="block-display mt-24">
                Org Details
              </Text>
              <div>
                <Row className="my-8">
                  <Text className="width-125-px">Name</Text>
                  <Text className="mx-10">:</Text>
                  <Text>{data?.name}</Text>
                </Row>
                <Row className="my-8">
                  <Text className="width-125-px">Created at</Text>
                  <Text className="mx-10">:</Text>
                  <Text>{formattedDate(data?.created_at)}</Text>
                </Row>
                <Row className="mb-8">
                  <Text className="width-125-px">Admins</Text>
                  <Text className="mx-10">:</Text>
                  <Text>{data?.admins?.join(", ")}</Text>
                </Row>
                <Row className="mb-8">
                  <Text className="width-125-px">Active Employees</Text>
                  <Text className="mx-10">:</Text>
                  <Text>{data?.active_employee_count}</Text>
                </Row>
                <Row className="mb-8">
                  <Text className="width-125-px">Plan</Text>
                  <Text className="mx-10">:</Text>
                  <Text>
                    {`${
                      data?.plan === "trial_expired_plan"
                        ? "Trial Expired Plan"
                        : planViewNames[data?.plan]
                    } ${
                      data?.trial_days_remaining
                        ? `(Remaining Days - ${data?.trial_days_remaining})`
                        : ""
                    }`}
                  </Text>
                </Row>
                <Row className="mb-8">
                  <Text className="width-125-px">Slack Integration</Text>
                  <Text className="mx-10">:</Text>
                  <Text>{data?.slack_integrated ? "Yes" : "No"}</Text>
                </Row>
              </div>

              <Text type="secondary" className="block-display mt-24">
                OKRs
              </Text>
              <Table
                loading={false}
                columns={okrColumns}
                dataSource={data?.okrs}
                scroll={mobileTableScroll}
                pagination={{
                  hideOnSinglePage: true,
                  size: "small",
                }}
              />

              <Text type="secondary" className="block-display mt-24">
                Features
              </Text>
              <Table
                loading={false}
                columns={featuresColumns}
                dataSource={data?.features}
                scroll={mobileTableScroll}
                pagination={{
                  hideOnSinglePage: true,
                  size: "small",
                }}
              />
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};

SuperAdmin.propTypes = {};

export default SuperAdmin;

import React from "react";
import PropTypes from "prop-types";
import { Row, Typography, Space } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

import Objectives from "../../common/objectives/Objectives";
import KeyResult from "../../common/objectives/KeyResult";

import styles from "../../OKRs.module.css";

const { Text, Title } = Typography;

const KeyResultsRowHeader = ({ title = "" }) => {
  return (
    <Row className={styles.keyResultRowHeader}>
      <Space>
        <ArrowUpOutlined />
        <Text>{title || ""}</Text>
      </Space>
    </Row>
  );
};
KeyResultsRowHeader.propTypes = {
  title: PropTypes.string,
};

const KeyResults = ({
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  keyResults = [],
  selectedPeriodObj = {},
  saveKeyResult = () => {},
  saveInitiative = () => {},
  refetch = () => {},
}) => {
  if (keyResults?.length === 0) {
    return null;
  }

  const objective = keyResults[0]?.objective || {};

  return (
    <div className={styles.keyResultRow}>
      <KeyResultsRowHeader title={objective?.title} />
      {keyResults?.map((keyResult) => (
        <KeyResult
          key={keyResult?.id}
          canUpdate={canUpdate}
          isFromReviews={isFromReviews}
          isSmallContainer={isSmallContainer}
          shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
          objectiveTitle={objective?.title || ""}
          objective={objective}
          keyResult={{ ...keyResult, can_update: true }}
          objectiveId={keyResult?.objective?.id}
          taggableKeyResults={keyResult?.key_results_of_objective}
          selectedPeriodObj={selectedPeriodObj}
          initiatives={keyResult?.initiatives}
          saveKeyResult={saveKeyResult}
          saveInitiative={saveInitiative}
          detailsRefetch={refetch}
        />
      ))}
    </div>
  );
};

const MyOKRsList = ({
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  data = {},
  teams = [],
  timePeriods = [],
  progressHash = {},
  currentCompany = {},
  selectedPeriodObj = {},
  refetch = () => {},
  saveKeyResult = () => {},
  saveInitiative = () => {},
  handleUpdateProgressHash = () => {},
}) => {
  const teamIdNameMap = {};
  const objectivesHash = { orgObjectives: [], individualObjectives: [] };
  const keyResultsHash = {
    orgKeyResultObjects: {},
    individualKeyResultObjects: {},
  };
  const teamIds = teams?.map((team) => {
    teamIdNameMap[team?.id] = team?.name;
    objectivesHash[team?.id] = [];
    keyResultsHash[team?.id] = {};

    return team?.id;
  });

  // eslint-disable-next-line no-unused-expressions
  data?.objectives?.forEach((obj) => {
    if (obj?.type === "OrganizationObjective") {
      // eslint-disable-next-line no-unused-expressions
      objectivesHash["orgObjectives"]?.push({ ...obj });
    } else if (obj?.type === "IndividualObjective") {
      // eslint-disable-next-line no-unused-expressions
      objectivesHash["individualObjectives"]?.push({ ...obj });
    } else {
      const objTeamId = obj?.team?.id;
      // eslint-disable-next-line no-unused-expressions
      objectivesHash[objTeamId]?.push({ ...obj });
    }
  });

  // eslint-disable-next-line no-unused-expressions
  data?.key_results?.forEach((keyResult) => {
    const currentKeyResultObjectiveId = keyResult?.objective?.id;
    if (keyResult?.objective?.type === "OrganizationObjective") {
      const updatedKeyResults =
        keyResultsHash["orgKeyResultObjects"][currentKeyResultObjectiveId] ||
        [];
      // eslint-disable-next-line no-unused-expressions
      updatedKeyResults?.push({ ...keyResult });
      // eslint-disable-next-line no-unused-expressions
      keyResultsHash["orgKeyResultObjects"][currentKeyResultObjectiveId] = [
        ...updatedKeyResults,
      ];
    } else if (keyResult?.objective?.type === "IndividualObjective") {
      const updatedKeyResults =
        keyResultsHash["individualKeyResultObjects"][
          currentKeyResultObjectiveId
        ] || [];
      // eslint-disable-next-line no-unused-expressions
      updatedKeyResults?.push({ ...keyResult });
      // eslint-disable-next-line no-unused-expressions
      keyResultsHash["individualKeyResultObjects"][
        currentKeyResultObjectiveId
      ] = [...updatedKeyResults];
    } else {
      const objTeamId = keyResult?.objective?.team?.id;
      const updatedKeyResults =
        keyResultsHash[objTeamId][currentKeyResultObjectiveId] || [];
      // eslint-disable-next-line no-unused-expressions
      updatedKeyResults?.push({ ...keyResult });

      // eslint-disable-next-line no-unused-expressions
      keyResultsHash[objTeamId][currentKeyResultObjectiveId] = [
        ...updatedKeyResults,
      ];
    }
  });

  return (
    <div>
      <div id="progress-popover" style={{ position: "relative" }} />

      {(objectivesHash?.orgObjectives?.length > 0 ||
        Object.keys(keyResultsHash?.orgKeyResultObjects)?.length > 0) && (
        <>
          <Title level={4}>{currentCompany?.name}</Title>

          <Objectives
            className="root-panel"
            canUpdate={canUpdate}
            isFromReviews={isFromReviews}
            isSmallContainer={isSmallContainer}
            shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
            from="my-okrs"
            ghost={false}
            timePeriods={timePeriods}
            objectives={objectivesHash?.orgObjectives}
            percentageProgressData={progressHash}
            selectedPeriodObj={selectedPeriodObj}
            refetch={refetch}
            parentDetailsRefetch={refetch}
            handleUpdateProgressHash={handleUpdateProgressHash}
          />

          {Object.values(keyResultsHash?.orgKeyResultObjects)?.map(
            (keyResults) => (
              <KeyResults
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                keyResults={keyResults}
                canUpdate={canUpdate}
                selectedPeriodObj={selectedPeriodObj}
                saveKeyResult={saveKeyResult}
                saveInitiative={saveInitiative}
                refetch={refetch}
              />
            )
          )}
        </>
      )}

      {teamIds?.map((teamId) => {
        const teamObjectives = objectivesHash[teamId] || [];
        const teamKeyResultsObject = keyResultsHash[teamId] || {};
        if (
          teamObjectives?.length === 0 &&
          Object.keys(teamKeyResultsObject)?.length === 0
        ) {
          return null;
        }

        return (
          <>
            <Title level={4}>{teamIdNameMap[teamId]}</Title>

            <Objectives
              className="root-panel"
              canUpdate={canUpdate}
              isFromReviews={isFromReviews}
              isSmallContainer={isSmallContainer}
              shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
              from="my-okrs"
              ghost={false}
              timePeriods={timePeriods}
              objectives={teamObjectives}
              percentageProgressData={progressHash}
              selectedPeriodObj={selectedPeriodObj}
              refetch={refetch}
              parentDetailsRefetch={refetch}
              handleUpdateProgressHash={handleUpdateProgressHash}
            />

            {Object.values(teamKeyResultsObject)?.map((keyResults) => (
              <KeyResults
                canUpdate={canUpdate}
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                keyResults={keyResults}
                selectedPeriodObj={selectedPeriodObj}
                saveKeyResult={saveKeyResult}
                saveInitiative={saveInitiative}
                refetch={refetch}
              />
            ))}
          </>
        );
      })}

      {(objectivesHash?.individualObjectives?.length > 0 ||
        Object.keys(keyResultsHash?.individualKeyResultObjects)?.length >
          0) && (
        <>
          <Title level={4}>Individual Objectives</Title>
          <Objectives
            className="root-panel"
            canUpdate={canUpdate}
            isFromReviews={isFromReviews}
            isSmallContainer={isSmallContainer}
            shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
            from="my-okrs"
            ghost={false}
            timePeriods={timePeriods}
            objectives={objectivesHash?.individualObjectives}
            percentageProgressData={progressHash}
            selectedPeriodObj={selectedPeriodObj}
            refetch={refetch}
            parentDetailsRefetch={refetch}
            handleUpdateProgressHash={handleUpdateProgressHash}
          />
          {Object.values(keyResultsHash?.individualKeyResultObjects)?.map(
            (keyResults) => (
              <KeyResults
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                keyResults={keyResults}
                canUpdate={canUpdate}
                selectedPeriodObj={selectedPeriodObj}
                saveKeyResult={saveKeyResult}
                saveInitiative={saveInitiative}
                refetch={refetch}
              />
            )
          )}
        </>
      )}
    </div>
  );
};

MyOKRsList.propTypes = {};

export default MyOKRsList;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Table } from "antd";

import { EmployeeAvatarWithName } from "components";
import { getNameFromEmployeeObj } from "app/appUtils";
const { Text } = Typography;

const ChangeInactiveManagerModal = ({
  visible = false,
  employee = {},
  activeEmployees = [],
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const reports = employee?.active
    ? getEmployeeReports(employee?.id, activeEmployees)
    : [];

  const handleSubmit = () => {
    onSubmit({ employee });
  };

  return (
    <Modal
      title={`Mark Employee as ${employee?.active ? "Inactive" : "Active"}`}
      visible={visible}
      onCancel={onCancel}
      okText="Yes"
      okButtonProps={{ type: "danger", disabled: reports?.length > 0 }}
      onOk={handleSubmit}
    >
      {reports?.length > 0 ? (
        <div>
          <Text className="block-display mt-8 mb-8">{`Reports of ${getNameFromEmployeeObj(
            employee
          )}`}</Text>

          <Table
            columns={[
              {
                title: "Report",
                dataIndex: "name",
                key: "name",
                width: "100px",
                render: (data, row) => {
                  return (
                    <>
                      <EmployeeAvatarWithName employee={row} />
                    </>
                  );
                },
              },
            ]}
            dataSource={reports}
            pagination={{
              pageSize: 5,
              hideOnSinglePage: true,
              size: "small",
            }}
          />

          <Text className="block-display mt-16">
            {`You can't mark ${getNameFromEmployeeObj(
              employee
            )} inactive as ${getNameFromEmployeeObj(
              employee
            )} has the above reports.
             Please change the manager for the above reports to mark ${getNameFromEmployeeObj(
               employee
             )} inactive.`}
          </Text>
        </div>
      ) : (
        <div>
          <Text>Are you sure you want to mark</Text>
          <Text strong>{` ${getNameFromEmployeeObj(employee) || ""} `}</Text>
          <Text>as {employee?.active ? "inactive" : "active"}?</Text>
        </div>
      )}
    </Modal>
  );
};

const getEmployeeReports = (employeeId, activeEmployees = []) => {
  const reports = [];

  activeEmployees.forEach((employee) => {
    if (employee?.manager_id === employeeId) {
      reports.push(employee);
    }
  });
  return reports;
};

ChangeInactiveManagerModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ChangeInactiveManagerModal;

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row, Typography } from "antd";
import Icon, { HeartOutlined, HeartFilled } from "@ant-design/icons";

import { InlineSpinner } from "components";
import { likePraiseApi, unLikePraiseApi } from "apis/praiseApis";
import { errorNotification } from "app/appUtils";

const { Link, Text } = Typography;

const PraiseLike = ({ canUpdate = true, praise = {} }) => {
  const [isLiked, setIsLiked] = useState(praise?.have_i_liked);
  const [likesCount, setLikesCount] = useState(praise?.likes);

  const [likePraise, { status: likeStatus }] = useMutation(likePraiseApi, {
    cacheTime: 1,
    onSuccess: async (data) => {
      await setIsLiked(true);
      await setLikesCount(data?.likes || likesCount + 1);
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [unLikePraise, { status: unLikeStatus }] = useMutation(
    unLikePraiseApi,
    {
      cacheTime: 1,
      onSuccess: async (data) => {
        await setIsLiked(false);
        await setLikesCount(data?.likes || likesCount - 1);
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleLike = () => {
    if (canUpdate) {
      if (!isLiked) {
        likePraise({ id: praise?.id });
      } else {
        unLikePraise({ id: praise?.id });
      }
    }
  };

  const getLikeMessage = () => {
    if (likesCount === 0) {
      return "";
    }
    if (isLiked) {
      return likesCount === 1 ? "" : `You and ${likesCount - 1} other liked`;
    }
    return `${likesCount} liked`;
  };

  return (
    <Row className="mt-8" justify="space-between">
      <Link>{getLikeMessage()}</Link>
      <div>
        {(likeStatus === "loading" || unLikeStatus === "loading") && (
          <InlineSpinner fontSize={24} />
        )}
        <Icon
          className="ml-8"
          component={isLiked ? HeartFilled : HeartOutlined}
          disabled={likeStatus === "loading" || unLikeStatus === "loading"}
          onClick={handleLike}
        />
        <Text className={clsx("ml-8", "cursor-pointer")} onClick={handleLike}>
          {isLiked ? "Liked" : "Like"}
        </Text>
      </div>
    </Row>
  );
};

PraiseLike.propTypes = {
  praise: PropTypes.object,
};

export default PraiseLike;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

import { getColumns } from "app/reviews/details/utils";

const CustomExpandTable = ({
  className = "",
  loading = false,
  columnParams = {},
  dataSource = [],
  pagination = {},
  scroll = {},
  expandable = {},
  rowKey = () => {},
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const columns = getColumns({
    ...columnParams,
    expandedRowKeys,
    setExpandedRowKeys,
  });

  return (
    <Table
      className={className}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      scroll={scroll}
      rowKey={rowKey}
      expandable={{ ...expandable, expandedRowKeys: expandedRowKeys }}
    />
  );
};

CustomExpandTable.propTypes = {
  loading: PropTypes.bool,
  getColumns: PropTypes.func,
  dataSource: PropTypes.array,
  pagination: PropTypes.object,
  scroll: PropTypes.object,
  expandable: PropTypes.object,
  rowKey: PropTypes.func,
};

export default CustomExpandTable;

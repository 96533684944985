import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Tooltip } from "antd";

import {
  successNotification,
  errorNotification,
  formattedDate,
  getNameFromEmployeeObj,
} from "app/appUtils";
import {
  nudgeRevieweeApi,
  nudgePeersApi,
  nudgeReviewerApi,
} from "apis/perfReviewApi";
import { ReactComponent as RevieweeNudgePoint } from "assets/reviews/reviewee_nudge.svg";
import { ReactComponent as ReviewerNudgePoint } from "assets/reviews/reviewer_nudge.svg";
import { ReactComponent as NudgeBlock } from "assets/reviews/nudge_block.svg";

const postSubmitPhases = ["Submitted", "Signed"];

const PerfReviewNudge = ({
  className = "",
  direction = "left",
  type = "reviewee",
  shouldShowPeerNudge = true,
  shouldShowNudge = true,
  peerNudgeTooltipTitle = "",
  reviewId = null,
  employee = null,
  nudgedAt = "",
  reviewCycle = {},
  status = "Not Started",
  otherStatus = "Not Started",
  onSuccess = () => {},
  setIsNudging = () => {},
}) => {
  const [nudgeReviewee] = useMutation(nudgeRevieweeApi, {
    onSuccess: (data) => {
      successNotification("Successfully Nudged");
      onSuccess(data, type);
      setIsNudging(false);
      return data;
    },
    onError: (error) => {
      errorNotification(error);
      setIsNudging(false);
    },
  });

  const [nudgeReviewer] = useMutation(nudgeReviewerApi, {
    onSuccess: (data) => {
      successNotification("Successfully Nudged");
      onSuccess(data, type);
      setIsNudging(false);
      return data;
    },
    onError: (error) => {
      errorNotification(error);
      setIsNudging(false);
    },
  });

  const [nudgePeers] = useMutation(nudgePeersApi, {
    onSuccess: (data) => {
      successNotification("Successfully Nudged");
      onSuccess(data, type);
      setIsNudging(false);
      return data;
    },
    onError: (error) => {
      errorNotification(error);
      setIsNudging(false);
    },
  });

  const handleClick = () => {
    setIsNudging(true);
    if (type === "reviewee") {
      nudgeReviewee({ reviewCycleId: reviewCycle?.id, reviewId });
    } else if (type === "reviewer") {
      nudgeReviewer({ reviewCycleId: reviewCycle?.id, reviewId });
    } else {
      nudgePeers({ review_id: reviewId });
    }
  };

  const formattedNudgedAt = nudgedAt
    ? `Last reminded on ${formattedDate(nudgedAt)}`
    : "";

  const isPostSubmit = postSubmitPhases?.includes(status);

  const toolTipTitle =
    type === "peer"
      ? peerNudgeTooltipTitle
      : `${getNameFromEmployeeObj(employee) || "-"}'s ${
          isPostSubmit ? "sign" : "submission"
        } of assessment is due by ${
          isPostSubmit
            ? formattedDate(reviewCycle?.deadline_for_sign)
            : formattedDate(reviewCycle?.deadline_for_submit)
        }.Give a Nudge! ${formattedNudgedAt}`;

  if (otherStatus === "Signed") {
    return <></>;
  }

  if (type === "peer" && !shouldShowPeerNudge) {
    return (
      <Tooltip title="No one to send a reminder message">
        <NudgeBlock
          className={`${className} cursor-pointer secondary-svg-stroke`}
        />
      </Tooltip>
    );
  }

  return (
    <>
      {Boolean(employee?.email) ? (
        <>
          {shouldShowNudge && (
            <Tooltip title={toolTipTitle}>
              {direction === "left" ? (
                <RevieweeNudgePoint
                  className={`${className} cursor-pointer secondary-svg-stroke`}
                  onClick={handleClick}
                />
              ) : (
                <ReviewerNudgePoint
                  className={`${className} cursor-pointer secondary-svg-stroke`}
                  onClick={handleClick}
                />
              )}
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip title="No one to send a reminder message">
          <NudgeBlock
            className={`${className} cursor-pointer secondary-svg-stroke`}
          />
        </Tooltip>
      )}
    </>
  );
};

PerfReviewNudge.propTypes = {
  employee: PropTypes.object,
  type: PropTypes.string,
  nudgedAt: PropTypes.string,
};

export default PerfReviewNudge;

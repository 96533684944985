import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { Space, Typography, Card } from "antd";
import moment from "moment";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import { errorNotification, successNotification } from "app/appUtils";
import { createSurveyApi } from "apis/surveyApi";
import NotificationModal from "../common/NotificationModal";
import SurveyForm from "../common/form-components/SurveyForm";
import FullScreenLayout from "components/layouts/FullScreenLayout";

const { Text } = Typography;

const AddSurveys = ({ mode = "new_survey", initialValues = {} }) => {
  const history = useHistory();

  const [survey, setSurvey] = useState(null);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  useEffect(() => {
    if (
      !survey &&
      !isEmployeesLoading &&
      activeEmployees &&
      activeEmployees?.length > 0
    ) {
      if (mode === "new_survey") {
        const initValues = {
          name: "",
          description: "",
          due_date: moment().add(7, "d"),
          for_entire_company: true,
          participants: [],
          employees: JSON.parse(JSON.stringify(activeEmployees)),
        };
        setSurvey({ ...initValues });
      } else if (mode === "clone_survey") {
        setSurvey({
          ...initialValues,
          due_date: moment().add(7, "d"),
          employees: JSON.parse(JSON.stringify(activeEmployees)),
        });
      } else {
        setSurvey({ ...initialValues });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEmployees, isEmployeesLoading]);

  const handleEmailDialogOpen = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const [createSurvey, { status }] = useMutation(createSurveyApi, {
    onSuccess: (data) => {
      if (!data?.draft) {
        const msg = data.are_emails_sent ? (
          <div>
            <Space direction="vertical">
              <Text>Survey Created</Text>
              <Text>Emails Sent</Text>
            </Space>
          </div>
        ) : (
          "Survey created"
        );
        successNotification(msg);
      }
      history.push("/company/surveys");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleCreatePerfReviewCycle = (send_emails) => {
    const createdSurvey = { ...survey, draft: false, send_emails };
    delete createdSurvey.employees;
    createSurvey(createdSurvey);
  };

  const handleSaveAsDraft = (values) => {
    const createdSurvey = {
      ...values,
      questions: values?.questions ? values.questions : [],
      send_emails: false,
      draft: true,
    };
    delete createdSurvey.employees;
    createSurvey(createdSurvey);
  };

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>

      <NotificationModal
        from="form"
        isLoading={status === "loading"}
        survey={survey}
        visible={isEmailDialogOpen}
        handleCancel={handleEmailDialogOpen}
        handleSubmit={handleCreatePerfReviewCycle}
      />
      <FullScreenLayout
        source={{ path: "/company/surveys", name: "Surveys" }}
        title="Create a Survey"
      >
        {isEmployeesLoading ? (
          <BoxLoader />
        ) : (
          <Card>
            <SurveyForm
              mode={mode}
              draftStep={survey?.draft_step || null}
              survey={survey}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={handleSaveAsDraft}
              setSurvey={setSurvey}
              handleDone={handleEmailDialogOpen}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

AddSurveys.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
};

export default AddSurveys;

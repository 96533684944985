import React from "react";
import PropTypes from "prop-types";
import { Row, Tag, Typography } from "antd";

import { statuses, revieweeStatuses } from "./constants";
import styles from "../../Review.module.css";

const { Text } = Typography;

const SearchAndFilterTags = ({
  className = "",
  filters = {},
  searchReviewsLength = 0,
  participantsLength = 0,
  setFilters = () => {},
}) => {
  const handleRevieweeStatusClose = () => {
    setFilters({ ...filters, reviewee_statuses: [] });
  };

  const handleReviewerStatusClose = () => {
    setFilters({ ...filters, reviewer_statuses: [] });
  };

  return (
    <Row className={className} align="middle">
      <Text className="mr-8">
        Showing :{" "}
        {`${searchReviewsLength} / ${participantsLength} participants`}
      </Text>
      {filters?.reviewee_statuses?.length > 0 && (
        <Tag
          className={styles.filterTag}
          closable
          onClose={handleRevieweeStatusClose}
        >{`Reviewee Status: ${
          filters?.reviewee_statuses?.length === 5
            ? "All"
            : filters?.reviewee_statuses
                ?.map((status) => revieweeStatuses[status])
                ?.join(", ") || ""
        }`}</Tag>
      )}
      {filters?.reviewer_statuses?.length > 0 && (
        <Tag
          className={styles.filterTag}
          closable
          onClose={handleReviewerStatusClose}
        >{`Reviewer Status: ${
          filters?.reviewer_statuses?.length === 5
            ? "All"
            : filters?.reviewer_statuses
                ?.map((status) => statuses[status])
                ?.join(", ") || ""
        }`}</Tag>
      )}
    </Row>
  );
};

SearchAndFilterTags.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default SearchAndFilterTags;

import axios from "axios";

const getAllReviewCycleApi = async () => {
  const { data } = await axios.get("/api/v2/admin/review_cycles/");
  return data;
};

const savePerfCycleApi = async ({ perf_review_cycle }) => {
  const { data } = await axios.post("/api/v2/admin/review_cycles", {
    perf_review_cycle,
  });
  return data;
};

const getMyReviewsApi = async () => {
  const { data } = await axios.get("/api/v2/reviews");
  return data;
};

const getReviewDetailsApi = async (_, reviewId, peerId) => {
  let apiURL = "";
  if (Boolean(reviewId) && Boolean(peerId)) {
    apiURL = `/api/v2/reviews/${peerId}/peer/${reviewId}`;
  } else if (!Boolean(reviewId) && Boolean(peerId)) {
    apiURL = `/api/v2/reviews/${peerId}/peer/`;
  } else if (Boolean(reviewId) && !Boolean(peerId)) {
    apiURL = `/api/v2/reviews/${reviewId}`;
  } else {
    //TODO: Error Case
  }

  const { data } = await axios.get(apiURL);
  return data;
};

const getAdminReviewDetailsApi = async (_, reviewId) => {
  const { data } = await axios.get(`/api/v2/admin/reviews/${reviewId}`);
  return data;
};

const getReviewActivityDetailsApi = async (_, reviewId, month) => {
  const { data } = await axios.get(
    `/api/v2/reviews/${reviewId}/details/${month}`
  );
  return data;
};

const updateReviewApi = async ({
  reviewId,
  actionType,
  answers,
  rating_option_id,
}) => {
  const { data } = await axios.put(`api/v2/reviews/${reviewId}`, {
    action_type: actionType,
    answers,
    rating_option_id,
  });
  return data;
};

const saveAndUnlockReviewApi = async ({ reviewId, answers, saveReview }) => {
  if (saveReview) {
    await axios.put(`api/v2/reviews/${reviewId}`, {
      action_type: "save_as_draft",
      answers,
    });
  }
  const { data } = await axios.put(`api/v2/reviews/${reviewId}`, {
    action_type: "unlock",
    answers,
  });
  return data;
};

const updateReviewReviewerApi = async ({
  reviewCycleId,
  reviewId,
  reviewerId,
}) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}/reviewer`,
    {
      reviewer: {
        id: reviewerId,
      },
    }
  );

  return data;
};

const addReviewCycleParticipantsApi = async ({
  participants,
  reviewCycleId,
}) => {
  const { data } = await axios.post(
    `/api/v2/admin/review_cycles/${reviewCycleId}/add_participants`,
    {
      perf_review_cycle: { participants },
    }
  );
  return data;
};

const resetReviewApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}/reset`
  );
  return data;
};

const deleteReviewApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.delete(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}`
  );
  return data;
};

const notifyParticipantsApi = async ({ reviewCycleId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/notify_participants`
  );
  return data;
};

const getReviewCycleApi = async (_, reviewCycleId) => {
  const { data } = await axios.get(
    `/api/v2/admin/review_cycles/${reviewCycleId}`
  );
  return data;
};

const nudgeRevieweeApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `api/v2/review_cycle/${reviewCycleId}/reviews/${reviewId}/nudge/reviewee`
  );
  return data;
};

const nudgeReviewerApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `api/v2/review_cycle/${reviewCycleId}/reviews/${reviewId}/nudge/reviewer`
  );
  return data;
};

const nudgePeersApi = async ({ review_id }) => {
  const { data } = await axios.put(`/api/v2/reviews/${review_id}/nudge_peers`);
  return data;
};

const bulkNudgeApi = async ({ reviewCycleId, type = "", note = "" }) => {
  const { data } = await axios.post(
    `api/v2/admin/review_cycles/${reviewCycleId}/nudge`,
    {
      nudge_for: type,
      note,
    }
  );
  return data;
};

const getReviewCycleDetailsApi = async (_, reviewCycleId) => {
  const { data } = await axios.get(
    `api/v2/review_cycle_details/${reviewCycleId}`
  );
  return data;
};

const getIndirectReportReviewsApi = async ({ reviewCycleId, report_ids }) => {
  const { data } = await axios.post(
    `api/v2/review_cycle_details/${reviewCycleId}/indirect_report_details`,
    { report_ids }
  );
  return data;
};

const searchReviewsApi = async ({
  reviewCycleId,
  searchedEmployees = [],
  filters = {},
}) => {
  const { data } = await axios.post(
    `api/v2/admin/review_cycles/${reviewCycleId}/search_reviews`,
    { employee_ids: searchedEmployees, ...filters }
  );
  return data;
};

const addPeerApi = async ({ reviewId, employeeId }) => {
  const { data } = await axios.post(
    `api/v2/reviews/${reviewId}/peer/${employeeId}`
  );

  return data;
};

const removePeerApi = async ({ reviewId, employeeId }) => {
  const { data } = await axios.delete(
    `api/v2/reviews/${reviewId}/peer/${employeeId}`
  );

  return data;
};

const finalizePeersApi = async ({ reviewId }) => {
  const { data } = await axios.put(
    `/api/v2/reviews/${reviewId}/finalize_peers`
  );
  return data;
};

const peerAssessmentSubmissionApi = async ({
  assessmentId,
  actionType,
  answers,
}) => {
  const { data } = await axios.post(
    `/api/v2/peer_assessments/${assessmentId}`,
    {
      action_type: actionType,
      answers,
    }
  );
  return data;
};

const bulkFinalizeReportsApi = async ({ reviewCycleId, review_ids }) => {
  const { data } = await axios.put(
    `/api/v2/review_cycle/${reviewCycleId}/bulk_finalize_peers`,
    {
      review_ids,
    }
  );
  return data;
};

const updatePerfReviewAnswerApi = async ({
  reviewId,
  assessmentId,
  answers,
  rating_option_id,
}) => {
  const req = {};

  if (rating_option_id) {
    req["rating_option_id"] = rating_option_id;
  } else {
    req["answers"] = answers;
  }

  const { data } = await axios.put(
    `api/v2/reviews/${reviewId}/assessment/${assessmentId}`,
    {
      ...req,
    }
  );
  return data;
};

const getAnswerDetailsApi = async (_, reviewId, assessmentId) => {
  const { data } = await axios.get(
    `/api/v2/admin/reviews/${reviewId}/assessment/${assessmentId}`
  );
  return data;
};

const bulkDownloadPerfReviewDataApi = async ({
  reviewCycleId,
  params = {},
}) => {
  const { data } = await axios.post(
    `/api/v2/admin/review_cycles/${reviewCycleId}/export`,
    {
      ...params,
    }
  );
  return data;
};

const bulkUploadRatingsApi = async ({ reviewCycleId, formData }) => {
  const { data } = await axios.post(
    `/api/v2/admin/review_cycles/${reviewCycleId}/bulk_update`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

const rejectAppealApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}/reject_appeal`
  );
  return data;
};

const acceptAppealApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}/accept_appeal`
  );
  return data;
};

const submitAsReviewerApi = async ({ reviewCycleId, reviewId }) => {
  const { data } = await axios.put(
    `/api/v2/admin/review_cycles/${reviewCycleId}/reviews/${reviewId}/submit_as_reviewer`
  );
  return data;
};

export {
  getAllReviewCycleApi,
  savePerfCycleApi,
  getMyReviewsApi,
  getReviewDetailsApi,
  getAdminReviewDetailsApi,
  getReviewActivityDetailsApi,
  updateReviewApi,
  saveAndUnlockReviewApi,
  updateReviewReviewerApi,
  addReviewCycleParticipantsApi,
  resetReviewApi,
  deleteReviewApi,
  notifyParticipantsApi,
  getReviewCycleApi,
  nudgeRevieweeApi,
  nudgeReviewerApi,
  nudgePeersApi,
  bulkNudgeApi,
  searchReviewsApi,
  getReviewCycleDetailsApi,
  getIndirectReportReviewsApi,
  addPeerApi,
  removePeerApi,
  finalizePeersApi,
  peerAssessmentSubmissionApi,
  bulkFinalizeReportsApi,
  updatePerfReviewAnswerApi,
  getAnswerDetailsApi,
  bulkDownloadPerfReviewDataApi,
  bulkUploadRatingsApi,
  rejectAppealApi,
  acceptAppealApi,
  submitAsReviewerApi,
};

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { Row, Col, Typography, Space, List, Radio, Form } from "antd";
import Icon from "@ant-design/icons";

import { MacroHighlightedText } from "components";
import { useCurrentCompany } from "store";
import AddMcqQuestionForm from "./AddMcqQuestionForm";
import { ReactComponent as Edit } from "assets/icons/edit.svg";

import styles from "./cycleQuestionnaireForm.module.css";
const { Text } = Typography;

const RatingQuestion = ({ questionsForm = {}, options = [] }) => {
  const [currentCompany] = useCurrentCompany();
  const [isEditOptionsVisible, setIsEditOptionsVisible] = useState(false);

  const handleEdit = () => {
    setIsEditOptionsVisible(!isEditOptionsVisible);
  };

  const handleUpdateQuestion = (name, { values, forms }) => {
    if (name === "ratingQuestionForm") {
      const options = forms?.ratingQuestionForm?.getFieldValue("options") || [];
      questionsForm.setFieldsValue({
        rating_options: options?.map((option, idx) => ({
          ...option,
          id: idx + 1,
        })),
      });
      forms.ratingQuestionForm.resetFields();
      handleEdit();
      return;
    }
  };

  const firstName = currentCompany?.user?.first_name;
  const lastName = currentCompany?.user?.last_name;

  return (
    <div>
      {isEditOptionsVisible && (
        <Form.Provider onFormFinish={handleUpdateQuestion}>
          <AddMcqQuestionForm
            visible={isEditOptionsVisible}
            onCancel={handleEdit}
            isRatingQuestion={true}
            initialValues={{
              reviewee_text: "How do you rate yourself?",
              reviewer_text: "How do you rate %FIRST_NAME%?",
              options,
              is_required: true,
              type: "PerfReviewMcq",
            }}
          />
        </Form.Provider>
      )}
      <Row justify="space-between" className="mb-8">
        <Col span={12}>
          <Text type="secondary">RATING QUESTION</Text>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Col>
              <Space>
                <Icon component={Edit} onClick={handleEdit} />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" className="mb-8">
        <Col span={11} className={styles.questionColumn}>
          <div className="mb-8">
            <MacroHighlightedText
              firstName={firstName || "-"}
              lastName={lastName || "-"}
              text={"How do you rate yourself?"}
            />
          </div>
        </Col>
        <Col span={11} className={styles.questionColumn}>
          <MacroHighlightedText
            firstName={firstName || "-"}
            lastName={lastName || "-"}
            text={"How do you rate %FIRST_NAME%?"}
          />
        </Col>
      </Row>
      <Row justify="space-around" className="mb-30">
        <Col span={18}>
          <List
            className="reviews-mcq-options-list"
            dataSource={options}
            bordered
            renderItem={(item) => (
              <List.Item>
                <Row justify="flex-start">
                  <Radio disabled />
                  <Text strong>{item?.value || ""}</Text>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

RatingQuestion.propTypes = {};

export default RatingQuestion;

import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Collapse, Row, Col, Typography, Divider, Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import { useCurrentCompany } from "store";
import { BoxLoader } from "components";
import { useWindowWidth } from "hooks";
import TimePeriodTitle from "../app/reviews/edit/activity-tabs/common/TimePeriodTitle";
import MyOKRsList from "app/okrs/my-okrs/list/MyOKRsList";
import {
  getObjectiveStatusesFromSectionData,
  getStatus,
  getExpectedPercentageFor,
} from "app/okrs/common/utils";
import { statusColors, statusText } from "app/okrs/common/constants";
import EmptyOKRCard from "app/okrs/common/EmptyOKRCard";
import { postEmployeeObjectivesApi } from "apis/okrV2Api";
import { getAllTeamsApi } from "apis/teamApi";
import { ReactComponent as ObjectivesIcon } from "assets/cold-screens/Objectives.svg";
// import { getMyOKRsApi } from "apis/okrV2Api";

import styles from "app/okrs/OKRs.module.css";

const { Panel } = Collapse;
const { Text } = Typography;

const getDefaultPeriodId = (
  periods = [],
  toDate = "",
  shouldShowCurrentPeriod = false
) => {
  // sort in descending dates
  periods.sort((a, b) => {
    return new Date(b.end_date) - new Date(a.end_date);
  });

  if (!shouldShowCurrentPeriod) {
    const filteredPeriods = periods?.filter((period) => {
      const periodEndDate = period?.end_date;
      if (
        moment(periodEndDate, "YYYY-MM-DD").isSameOrBefore(toDate, "YYYY-MM-DD")
      ) {
        return true;
      }
      return false;
    });

    if (filteredPeriods?.length > 0) {
      return filteredPeriods[0]?.id;
    }
  }

  const currentPeriod = periods?.filter((period) => period?.is_active)[0];
  return currentPeriod?.id;
};

const SummaryPanelHeaderTitle = ({
  overallProgress,
  paceColor,
  pace,
  isCollapsed = true,
}) => {
  return (
    <>
      <Text className={isCollapsed ? "block-display" : ""}>Summary</Text>
      <Text className={isCollapsed ? "" : "ml-8"}>
        {`(Overall Progress: ${Math.round(overallProgress || 0)}%,  Pace: `}
      </Text>
      <Text
        className="ml-4"
        style={{
          color: paceColor,
        }}
      >
        {pace}
      </Text>
      <Text>)</Text>
    </>
  );
};

const SummaryPanelHeader = ({
  data = {},
  selectedPeriodObj = {},
  overallProgress = 0,
  pace = "",
  paceColor = "",
}) => {
  const objectivesCount = data?.objectives?.length || 0;

  const statuses = getObjectiveStatusesFromSectionData(
    data?.objectives || [],
    selectedPeriodObj
  );

  return (
    <Row align="middle" gutter={[8]}>
      <Col xs={{ span: 24 }} md={{ span: 8 }}>
        <SummaryPanelHeaderTitle
          isCollapsed={true}
          overallProgress={overallProgress}
          pace={pace}
          paceColor={paceColor}
        />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 4 }}>{`${objectivesCount} OKRs`}</Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <div>
          <Text type="secondary" className="block-display">
            OKRs BY STATUS
          </Text>
          <div className="flex-display align-items-center flex-wrap">
            <div className="inline-flex-display align-items-center">
              <span className={styles.onTrackDot} />
              <Text className="mr-16">{`${statuses["on_track"]} ON TRACK`}</Text>
            </div>
            <div className="inline-flex-display align-items-center">
              <span className={styles.behindDot} />
              <Text>{`${statuses["behind"]} BEHIND`}</Text>
            </div>
          </div>
          <div className="flex-display align-items-center flex-wrap">
            <div className="inline-flex-display align-items-center">
              <span className={styles.atRiskDot} />
              <Text className="mr-16">{`${statuses["at_risk"]} AT RISK`}</Text>
            </div>
            <div className="inline-flex-display align-items-center">
              <span className={styles.doneDot} />
              <Text className="mr-16">{`${statuses["done"]} DONE`}</Text>
            </div>
            <div className="inline-flex-display align-items-center">
              <span className={styles.notStartedDot} />
              <Text className="mr-16">{`${statuses["not_started"]} NOT STARTED`}</Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const OKRsActivity = ({
  okrPeriodLoading = false,
  shouldShowCurrentPeriod = false,
  defaultOKRPeriodId = null,
  periods = [],
  title = "",
  showDividerAfterTitle = false,
  employeeId = null,
  panelClassName = "perf-review-okr-summary-panel",
  toDate = "",
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
}) => {
  const width = useWindowWidth();
  const [currentCompany] = useCurrentCompany();
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    defaultOKRPeriodId ||
      getDefaultPeriodId(periods, toDate, shouldShowCurrentPeriod)
  );
  const [activeKeys, setActiveKeys] = useState([]);

  const [postEmployeeObjectives, { data, status, isLoading }] = useMutation(
    postEmployeeObjectivesApi,
    // getMyOKRsApi,
    {
      enabled: !!selectedPeriodId,
    }
  );

  const {
    data: teams,
    status: teamsStatus,
    isLoading: isTeamsLoading,
    // isFetching: isTeamsFetching,
  } = useQuery("getAllTeams", getAllTeamsApi);

  useEffect(() => {
    setSelectedPeriodId(
      defaultOKRPeriodId ||
        getDefaultPeriodId(periods, toDate, shouldShowCurrentPeriod)
    );
  }, [defaultOKRPeriodId, toDate, periods, shouldShowCurrentPeriod]);

  useEffect(() => {
    if (employeeId && selectedPeriodId) {
      postEmployeeObjectives({
        employee_id: employeeId,
        period_id: selectedPeriodId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, selectedPeriodId]);

  const handleSelectedPeriodChange = (periodId) => {
    setSelectedPeriodId(periodId);
  };

  const handleActiveKeys = () => {
    if (activeKeys?.length === 0) {
      setActiveKeys(["okrs-activity-collapse"]);
    } else {
      setActiveKeys([]);
    }
  };

  const okrsLoading =
    status === "loading" ||
    isLoading ||
    teamsStatus === "loading" ||
    isTeamsLoading;

  const isEmpty =
    data?.objectives?.length === 0 &&
    (!data?.key_results ||
      (data?.key_results && data?.key_results?.length === 0));

  const selectedPeriodObj =
    periods?.filter((period) => period?.id === selectedPeriodId)[0] || {};

  const overallProgress = data?.overall_progress || 0;

  const pace =
    statusText[
      getStatus(overallProgress, getExpectedPercentageFor(selectedPeriodObj))
    ];

  const paceColor =
    statusColors[
      getStatus(overallProgress, getExpectedPercentageFor(selectedPeriodObj))
    ];

  return (
    <div>
      {okrPeriodLoading ? (
        <BoxLoader height="10vh" />
      ) : (
        <>
          <TimePeriodTitle
            title={title}
            selectedTab="okrs"
            periods={periods}
            selectedPeriodId={selectedPeriodId}
            handleSelectedPeriodChange={handleSelectedPeriodChange}
          />
          {showDividerAfterTitle && <Divider />}

          <div className="mt-8">
            {okrsLoading ? (
              <BoxLoader height="10vh" />
            ) : (
              <>
                {isEmpty ? (
                  <Card>
                    <SummaryPanelHeader
                      data={data}
                      selectedPeriodObj={selectedPeriodObj}
                      overallProgress={overallProgress}
                      pace={pace}
                      paceColor={paceColor}
                    />
                  </Card>
                ) : (
                  <Collapse
                    className="perf-review-okr-summary"
                    activeKey={activeKeys}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined
                        onClick={handleActiveKeys}
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                    onClick={handleActiveKeys}
                  >
                    <Panel
                      className={panelClassName}
                      header={
                        <div onClick={handleActiveKeys}>
                          {activeKeys[0] !== "okrs-activity-collapse" ? (
                            <SummaryPanelHeader
                              data={data}
                              selectedPeriodObj={selectedPeriodObj}
                              overallProgress={overallProgress}
                              pace={pace}
                              paceColor={paceColor}
                            />
                          ) : (
                            <SummaryPanelHeaderTitle
                              isCollapsed={false}
                              overallProgress={overallProgress}
                              pace={pace}
                              paceColor={paceColor}
                            />
                          )}
                        </div>
                      }
                      key="okrs-activity-collapse"
                    >
                      {isEmpty ? (
                        <div className="my-16">
                          <EmptyOKRCard
                            canUpdate={false}
                            Icon={<ObjectivesIcon />}
                            text="No objectives to display."
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            id="objective-panel-header-three-dot-container"
                            style={{ position: "relative" }}
                          />
                          <div
                            id="key-results-initiatives-three-dot-container"
                            style={{ position: "relative" }}
                          />
                          <MyOKRsList
                            canUpdate={false}
                            isFromReviews={
                              isSmallContainer ? true : true && width < 1650
                            }
                            isSmallContainer={isSmallContainer}
                            shouldNotExpandOnBigScreen={
                              shouldNotExpandOnBigScreen
                            }
                            data={data}
                            teams={teams}
                            timePeriods={[]}
                            currentCompany={currentCompany}
                            activeEmployees
                            inActiveEmployees
                            selectedPeriodObj={selectedPeriodObj}
                          />
                        </>
                      )}
                    </Panel>
                  </Collapse>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

OKRsActivity.propTypes = {};

export default OKRsActivity;

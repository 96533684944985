import React from "react";
// import PropTypes from 'prop-types';
import { Typography } from "antd";

const { Text } = Typography;

const SetDeadline = ({
  krIndex,
  value,
  disabled = false,
  onChange,
  onRemoveDeadline,
}) => {
  return (
    <Text
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          if (value) {
            onRemoveDeadline(krIndex);
          } else {
            onChange(!value);
          }
        }
      }}
    >
      {value ? "Remove Optional Due Date" : "Set Optional Due Date"}
    </Text>
  );
};

SetDeadline.propTypes = {};

export default SetDeadline;

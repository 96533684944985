import { useEffect } from "react";

function useSetQuestionFormValues({ type, form, values }) {
  useEffect(() => {
    if (values.type === type) {
      form.setFieldsValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
}

export default useSetQuestionFormValues;

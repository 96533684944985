import React from "react";
import PropTypes from "prop-types";
import { useVirtual } from "react-virtual";
import { Row, Typography, Tooltip } from "antd";

import { EmployeeAvatarGroup } from "components";

const { Text } = Typography;

const RowVirtualizer = ({ data = [], onSubmit = () => {} }) => {
  const parentRef = React.useRef();

  const rowVirtualizer = useVirtual({
    size: data?.length,
    parentRef,
    estimateSize: React.useCallback(() => 74, []),
  });

  return (
    <>
      <div
        ref={parentRef}
        className="List"
        style={{
          maxHeight: "400px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          className="ListInner"
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            const objective = data[virtualRow.index];
            return (
              <div
                key={objective?.id}
                className={
                  virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"
                }
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <Row
                  align="middle"
                  className={`cursor-pointer parentObjectiveSelectRow`}
                  onClick={() => onSubmit(objective)}
                >
                  <EmployeeAvatarGroup
                    className="mr-8"
                    divClassName="inline-display height-90-percent"
                    employees={objective?.owners}
                  />
                  <Tooltip title={objective?.title}>
                    <Text style={{ maxWidth: "70%" }} ellipsis={true}>
                      {objective?.title}
                    </Text>
                  </Tooltip>
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

RowVirtualizer.propTypes = {
  data: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default RowVirtualizer;

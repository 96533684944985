import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Form, DatePicker } from "antd";

import StepFormActionButtons from "./StepFormActionButtons";

const CycleTimeLineForm = ({
  mode = "create",
  isSaving = false,
  initialValues = {},
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) => {
  const [timelineForm] = Form.useForm();
  const canEmployeeNominate = initialValues["employee_can_nominate"] || false;
  initialValues["nominate_due_date"] = canEmployeeNominate
    ? initialValues["nominate_due_date"]
    : null;

  return (
    <Form
      className="mt-24"
      name="timeline"
      form={timelineForm}
      layout="vertical"
      initialValues={initialValues}
    >
      {canEmployeeNominate && (
        <Form.Item
          name="nominate_due_date"
          label="Due Date to nominate reviewers"
          shouldUpdate
          rules={[
            {
              required: true,
              message: "Missing nomination deadline",
            },
            {
              validator: (rule, value, callback) => {
                try {
                  if (
                    moment(value, "YYYY-MM-DD").isBefore(
                      moment().startOf("day")
                    )
                  ) {
                    callback("Date cant be in the past");
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              },
            },
          ]}
          required={false}
          initialValue={initialValues.deadline_for_submit}
        >
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
          />
        </Form.Item>
      )}
      <Form.Item
        name="finalize_due_date"
        label="Due Date for the manager to finalize reviewers"
        rules={[
          {
            required: true,
            message: "Missing finalizing deadline",
          },
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              const nominatedStartDate = getFieldValue("nominate_due_date");
              try {
                if (
                  moment(value, "YYYY-MM-DD")
                    .startOf("day")
                    .isBefore(moment().startOf("day"))
                ) {
                  callback("Date cant be in the past");
                }
                if (
                  nominatedStartDate &&
                  moment(value, "YYYY-MM-DD")
                    .startOf("day")
                    .isBefore(nominatedStartDate.startOf("day"))
                ) {
                  callback(
                    "Due Date for Finalizing cant be before Due Date for nominating"
                  );
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          }),
        ]}
        initialValue={initialValues.deadline_for_sign}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) =>
            current && current < moment().startOf("day")
          }
        />
      </Form.Item>
      <Form.Item
        name="submit_due_date"
        label="Due Date for the reviewers to submit their reviews"
        rules={[
          {
            required: true,
            message: "Missing submission deadline",
          },
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isBefore(moment().startOf("day"))
                ) {
                  callback("Date cant be in the past");
                }
                if (
                  moment(value, "YYYY-MM-DD").isBefore(
                    getFieldValue("finalize_due_date")
                  )
                ) {
                  callback(
                    "Due Date for Submission cant be before Due Date to Finalizing"
                  );
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          }),
        ]}
        initialValue={initialValues.deadline_for_sign}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) =>
            current && current < moment().startOf("day")
          }
        />
      </Form.Item>
      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          currentStep={2}
          isSaving={isSaving}
          shouldShowSaveAsDraft={initialValues?.draft}
          currentForm={timelineForm}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({
              ...timelineForm.getFieldsValue(true),
              draft_step: 2,
            });
          }}
          handlePreviousClick={handlePreviousClick}
        />
      </Form.Item>
    </Form>
  );
};

CycleTimeLineForm.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
  handleSaveAsDraft: PropTypes.func,
  handlePreviousClick: PropTypes.func,
};

export default CycleTimeLineForm;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";

import VimeoVideo from "./VimeoVideo";

const VimeoVideoModal = ({
  title = "",
  src = "",
  visible = false,
  onCancel = () => {},
  onEnd = () => {},
}) => {
  return (
    <Modal
      width="880px"
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button type="primary" ghost={true} onClick={onCancel}>
          Close
        </Button>,
      ]}
    >
      <VimeoVideo shouldAutoPlay={true} src={src} onEnd={onEnd} />
    </Modal>
  );
};

VimeoVideoModal.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default VimeoVideoModal;

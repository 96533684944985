import axios from "axios";

const getAllChannelsApi = async () => {
  const { data } = await axios.get("api/v2/admin/integrations/slack/");
  return data;
};

const integrateSlackApi = async ({ code }) => {
  const { data } = await axios.post("api/v2/admin/integrations/slack/", {
    code,
  });
  return { data };
};

const updateSelectedChannelApi = async ({ slack_channel_id }) => {
  const { data } = await axios.post("api/v2/admin/integrations/slack/update", {
    slack_channel_id,
  });
  return data;
};

const removeIntegrationApi = async () => {
  const { data } = await axios.delete("api/v2/admin/integrations/slack/remove");
  return data;
};

export {
  getAllChannelsApi,
  integrateSlackApi,
  updateSelectedChannelApi,
  removeIntegrationApi,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Button,
  Alert,
  Checkbox,
  Select,
  Row,
  Modal,
  Card,
} from "antd";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";

import { BoxLoader, ButtonWithSpinner } from "components";
import { successNotification, errorNotification } from "app/appUtils";
import {
  getAllChannelsApi,
  integrateSlackApi,
  updateSelectedChannelApi,
  removeIntegrationApi,
} from "apis/slackIntegrationApi";
import { ReactComponent as CompletedActionItem } from "assets/reviews/completed_action_item.svg";
import { ReactComponent as SlackIcon } from "assets/settings/slack.svg";

import styles from "../Company.module.css";

const { Text, Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const SlackIntroMessage = (props) => {
  return (
    <div>
      <Text className="mt-28 block-display">
        Integrate with SLACK to send notification/reminders to your employees.
      </Text>
      <Text className="block-display">
        There are two types of notifications that culture.easy sends to your
        employees:
      </Text>

      <div className="my-16">
        <Text className="block-display">
          1. <b>Direct Message -</b> Employee will receive a direct SLACK
          message from culture.easy bot in the following scenarios
        </Text>
        <div className="ml-8">
          <Text className="block-display">
            a. When a Perf Review Cycle is created, counter party submits or
            signs, unlocks the perf review
          </Text>
          <Text className="block-display">
            b. When an Employee is nudged to complete his action items like
            submitting or signing a perf review.
          </Text>
          <Text className="block-display">
            c. When there is a new comment on the Key Results owned by an
            Employee.
          </Text>
        </div>
        <br />
        <Text className="block-display">
          2. <b>Channel Messages -</b> Employees will receive a group(slack
          channel) message.
        </Text>
        <div className="ml-8">
          <Text className="block-display">
            a. When an Employee praises someone in your organization.
          </Text>
        </div>
      </div>
    </div>
  );
};

SlackIntroMessage.propTypes = {};

const SuccessfulSlackIntegration = ({
  channels = [],
  slackChannelId = "",
  slackWorkspace = "",
  shouldShowAlert = false,
  refetch,
}) => {
  const [
    updateSelectedChannel,
    { status: updateSelectedChannelStatus, isLoading: isUpdateChannelLoading },
  ] = useMutation(updateSelectedChannelApi, {
    onSuccess: () => {
      refetch();
      successNotification("Updated the selected channel");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [removeIntegration, { status: removeIntegrationStatus }] = useMutation(
    removeIntegrationApi,
    {
      onSuccess: () => {
        refetch();
        successNotification("Removed the integration");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [shouldPostToPublicChannel, setShouldPostToPublicChannel] = useState(
    Boolean(slackChannelId)
  );
  const [publicChannelToPost, setPublicChannelToPost] = useState(
    slackChannelId
  );

  useEffect(() => {
    setPublicChannelToPost(slackChannelId);
    if (!slackChannelId) {
      setShouldPostToPublicChannel(false);
    }
  }, [slackChannelId]);

  const handlePostPublicPraise = () => {
    setShouldPostToPublicChannel(!shouldPostToPublicChannel);
  };

  const filterOptions = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handlePublicChannelToPost = (channel = "") => {
    setPublicChannelToPost(channel);
  };

  const handleRemoveIntegration = () => {
    confirm({
      title: "Remove Integration",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Are you sure you want to </Text>
          <Text strong>remove </Text>
          <Text>slack integration?</Text>
        </>
      ),
      onOk() {
        removeIntegration();
      },
      width: "1000px",
      okText: "Yes",
      okButtonProps: { danger: true },
      cancelText: "Cancel",
      onCancel() {},
    });
  };

  const handleSaveChanges = () => {
    updateSelectedChannel({
      slack_channel_id: shouldPostToPublicChannel ? publicChannelToPost : "",
    });
  };

  const loader =
    updateSelectedChannelStatus === "loading" ||
    removeIntegrationStatus === "loading";

  const isUpdatingChannel =
    updateSelectedChannelStatus === "loading" || isUpdateChannelLoading;

  const channelIds = channels?.map((channel) => channel?.id) || [];

  return (
    <>
      {shouldShowAlert && (
        <Alert
          className="mb-34"
          type="info"
          message="The integration has successfully been setup."
        />
      )}

      {loader && <BoxLoader />}

      {!loader && (
        <>
          <SlackIntroMessage />

          <div className="mt-32 block-display">
            <Text strong>Status</Text>
            <div>
              <Icon component={CompletedActionItem} />
              <Text className="ml-8">
                {`Integrated with Slack workspace ${slackWorkspace}`}
              </Text>
            </div>
          </div>

          <Text strong className=" mt-56 block-display">
            Additional Configuration
          </Text>

          <Checkbox
            checked={shouldPostToPublicChannel}
            onChange={handlePostPublicPraise}
          >
            Post notifications to Slack Channel
          </Checkbox>
          {shouldPostToPublicChannel && (
            <Select
              showSearch
              optionFilterProp="children"
              className="mt-8 pl-16 width-250-px "
              value={publicChannelToPost}
              filterOption={filterOptions}
              onChange={handlePublicChannelToPost}
            >
              {channels.map(({ name, id }) => (
                <Option value={id}>{name}</Option>
              ))}
              {!channelIds?.includes(publicChannelToPost) && (
                <Option value={publicChannelToPost}></Option>
              )}
            </Select>
          )}

          <Row className="mt-80" justify="space-between">
            <ButtonWithSpinner
              isSpinning={isUpdatingChannel}
              disabled={isUpdatingChannel}
              type="primary"
              onClick={handleSaveChanges}
            >
              Save
            </ButtonWithSpinner>
            <Button
              className="ml-24"
              danger
              ghost={true}
              onClick={handleRemoveIntegration}
            >
              Remove Integration
            </Button>
          </Row>
        </>
      )}
    </>
  );
};
SuccessfulSlackIntegration.propTypes = {
  channels: PropTypes.array,
};

const Slack = (props) => {
  // const history = useHistory();

  const [channelData, setChannelData] = useState(null);

  const {
    status: channelStatus,
    isFetching: channelStatusIsFetching,
    refetch,
  } = useQuery("getAllChannels", getAllChannelsApi, {
    cacheTime: 0,
    onSuccess: (data) => {
      setChannelData(data);
    },
    onError: () => {
      setChannelData(null);
    },
  });

  const [integrateSlack, { status: integrationStatus }] = useMutation(
    integrateSlackApi,
    {
      onSuccess: () => {
        refetch();
        successNotification("Integrated Slack App.");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const error = query.get("error");

  useEffect(() => {
    if (code) {
      integrateSlack({ code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader =
    channelStatus === "loading" ||
    integrationStatus === "loading" ||
    channelStatusIsFetching;

  if (error) {
    errorNotification(error === "access_denied" ? "Access denied" : "");
  }

  return (
    <div>
      <Helmet>
        <title>Settings - culture.easy</title>
      </Helmet>

      <Title level={2}>Slack Integration</Title>

      <Card>
        {loader && <BoxLoader />}

        {!loader && (
          <>
            {channelData?.channels ? (
              <SuccessfulSlackIntegration
                channels={channelData?.channels}
                slackChannelId={channelData?.slack_channel_id}
                slackWorkspace={channelData?.slack_workspace}
                shouldShowAlert={Boolean(code)}
                refetch={refetch}
              />
            ) : (
              <>
                <SlackIntroMessage />

                <Text strong className="mt-80 mb-8 block-display">
                  Press the button to set up the integration.
                </Text>

                <a href="https://slack.com/oauth/v2/authorize?client_id=3444048808.1484041062919&scope=channels:read,chat:write,chat:write.customize,chat:write.public,app_mentions:read,commands,im:history,im:read,im:write,users:read,users:read.email&user_scope=">
                  <Button type="primary" ghost={true}>
                    <SlackIcon className={styles.slackIconMini} /> Integrate
                    with Slack
                  </Button>
                </a>
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

Slack.propTypes = {};

export default Slack;

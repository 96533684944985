import React from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from "antd";
import { ReactComponent as Icon403 } from "assets/result/403.svg";

const Forbidden403 = (props) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/home");
  };

  return (
    <div>
      <Result
        icon={<Icon403 className="result-icon" />}
        title="403"
        subTitle="Sorry, you are not authorized to access this page. Please contact your HR admin"
        extra={
          <Button type="primary" onClick={handleGoBack}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

Forbidden403.propTypes = {};

export default Forbidden403;

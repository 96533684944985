import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { MultiLineText } from "components";
// const { Text } = Typography;

const idealLength = 125;

const ShowMoreText = ({
  className = "",
  text = "",
  shouldBeBlockDisplay = true,
  charactersToShowOnLess = idealLength - 1,
}) => {
  const [shouldShowMore, setShouldShowMore] = useState(false);

  const handleShouldShowMore = () => {
    setShouldShowMore(!shouldShowMore);
  };

  if (text?.length <= charactersToShowOnLess) {
    return <MultiLineText className={className} text={text} />;
  }

  return (
    <span className={shouldBeBlockDisplay ? "block-display" : ""}>
      {shouldShowMore ? (
        <>
          <MultiLineText className={className} text={text} />
          <Button
            className="pl-4 pt-0"
            type="link"
            onClick={handleShouldShowMore}
          >
            Show Less
          </Button>
        </>
      ) : (
        <>
          <MultiLineText
            className={className}
            text={`${text?.slice(0, charactersToShowOnLess)}...`}
          />

          <Button
            className="pl-4 pt-0"
            type="link"
            onClick={handleShouldShowMore}
          >
            Show More
          </Button>
        </>
      )}
    </span>
  );
};

ShowMoreText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default ShowMoreText;

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useMutation } from "react-query";
import moment from "moment";
import {
  Typography,
  Row,
  Button,
  Col,
  Card,
  Popover,
  Dropdown,
  Menu,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ImportOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import {
  EllipseText,
  InlineSpinner,
  ButtonWithSpinner,
  OptionalFormItemLabel,
} from "components";
import { useCurrentCompany } from "store";
import SaveKPIDrawer from "./save/SaveKPIDrawer";
import ShowKPI from "./show/ShowKPI";
import { deleteKPIApi, updateProgressApi } from "apis/kpis";
import { successNotification, errorNotification } from "app/appUtils";

import styles from "../OKRs.module.css";

const { Text } = Typography;
const { confirm } = Modal;

// eslint-disable-next-line no-unused-vars
const getLastTargetValueDescription = (kpi) => {
  const targetValueCheckins = kpi?.kpi_target_value_checkins || [];
  const lastTargetValueCheckin =
    targetValueCheckins[targetValueCheckins?.length - 1];

  return `${lastTargetValueCheckin?.operator} ${lastTargetValueCheckin?.target_value}`;
};

const StatusText = ({ kpi = {} }) => {
  const currentValueCheckins = kpi?.kpi_current_value_checkins || [];
  const lastCurrentValueCheckins =
    currentValueCheckins[currentValueCheckins?.length - 1];

  let previousValue = 0;
  if (currentValueCheckins?.length > 1) {
    const previousValueCheckins =
      currentValueCheckins[currentValueCheckins?.length - 2];
    previousValue = previousValueCheckins?.current_value;
  }

  const differenceBetweenLastTwoCheckins =
    lastCurrentValueCheckins?.current_value - previousValue;

  const targetValueCheckins = kpi?.kpi_target_value_checkins || [];
  const lastTargetValueCheckin =
    targetValueCheckins[targetValueCheckins?.length - 1];

  const operator = lastTargetValueCheckin?.operator;

  const isProgressAsExpected =
    (operator === ">=" && differenceBetweenLastTwoCheckins >= 0) ||
    (operator === "<=" && differenceBetweenLastTwoCheckins <= 0);

  return (
    <div>
      <Text strong={true}>{lastCurrentValueCheckins?.current_value}</Text>
      {currentValueCheckins?.length > 1 && (
        <Text
          className="ml-8"
          type={isProgressAsExpected ? "success" : "danger"}
        >
          {differenceBetweenLastTwoCheckins > 0 ? (
            <ArrowUpOutlined />
          ) : (
            <ArrowDownOutlined />
          )}
          {differenceBetweenLastTwoCheckins}
        </Text>
      )}
    </div>
  );
};

const KPIProgressForm = ({
  kpi = {},
  isUpdatingProgress = false,
  handleFinish = () => {},
  handleClose = () => {},
}) => {
  const [form] = Form.useForm();
  const [isBackDateUpdate, setIsBackDateUpdate] = useState(false);

  const handleBackdateUpdate = () => {
    setIsBackDateUpdate(!isBackDateUpdate);
  };

  const onClose = () => {
    setIsBackDateUpdate(false);
    form.setFieldsValue({
      current_value: lastCheckin?.current_value,
      comment: "",
      created_at: null,
    });
    handleClose();
  };

  const onFinish = (values) => {
    handleFinish(values);
    form.resetFields();
    setIsBackDateUpdate(false);
  };

  const lastCheckin =
    kpi?.kpi_current_value_checkins[
      kpi?.kpi_current_value_checkins?.length - 1
    ];

  return (
    <Form
      layout="vertical"
      initialValues={{ current_value: lastCheckin?.current_value }}
      onFinish={onFinish}
    >
      <Text>Progress</Text>
      <Row className="mb-16" align="middle">
        <Form.Item
          name="current_value"
          className="inline-display mb-0"
          rules={[
            {
              required: true,
              message: "Missing progress",
            },
          ]}
          required={false}
        >
          <InputNumber />
        </Form.Item>
        <Text className="ml-8">{kpi?.kpi_metric}</Text>
      </Row>
      <Form.Item
        className="mt-8 width-250-px"
        label={<OptionalFormItemLabel label="Comment" />}
        name="comment"
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 6 }}
          className="input-textarea-resize-none"
        />
      </Form.Item>
      {isBackDateUpdate ? (
        <>
          <Text>Date (Optional)</Text>
          <div>
            <Form.Item name="created_at" noStyle>
              <DatePicker
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>
            <CloseCircleOutlined
              className="ml-8"
              onClick={handleBackdateUpdate}
            />
          </div>
        </>
      ) : (
        <Button className="p-0" type="link" onClick={handleBackdateUpdate}>
          Backdate Update
        </Button>
      )}

      <Divider className="my-8" />

      <Row justify="end">
        <Button size="small" type="primary" ghost={true} onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithSpinner
          size="small"
          className="ml-8"
          isSpinning={isUpdatingProgress}
          disable={isUpdatingProgress}
          type="primary"
          htmlType="submit"
        >
          Update Progress
        </ButtonWithSpinner>
      </Row>
    </Form>
  );
};

const KPICard = ({ kpi = {}, refetch = () => {} }) => {
  const [currentCompany] = useCurrentCompany();
  const [isMoreDropdownVisible, setIsMoreDropdownVisible] = useState(false);
  const [isUpdateProgressPopoverVisible, setIsUpdateProgressPopoverVisible] =
    useState(false);
  const [isEditKPIVisible, setIsEditKPIVisible] = useState(false);
  const [isShowKPIVisible, setIsShowKPIVisible] = useState(false);

  const [deleteKPI, { isLoading: isDeleting }] = useMutation(deleteKPIApi, {
    onSuccess: () => {
      successNotification("Successfully Deleted KPI");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [updateKPIProgress, { isLoading: isUpdatingProgress }] = useMutation(
    updateProgressApi,
    {
      onSuccess: () => {
        successNotification("Successfully Updated progress");
        setIsUpdateProgressPopoverVisible(false);
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleEditKPI = () => {
    setIsMoreDropdownVisible(false);
    setIsEditKPIVisible(!isEditKPIVisible);
  };

  const handleDelete = () => {
    setIsMoreDropdownVisible(false);
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "You will loose all checkins made on this kpi and this action can't be undone.",
      onOk() {
        deleteKPI(kpi?.id);
      },
      okButtonProps: { danger: true },
    });
  };

  const onUpdateProgress = (values) => {
    updateKPIProgress({ id: kpi?.id, checkin: { ...values } });
  };

  const handleIsShowKPIVisible = () => {
    setIsShowKPIVisible((prevValue) => !prevValue);
  };

  const handleShowKPI = () => {
    setIsMoreDropdownVisible(false);
    handleIsShowKPIVisible();
  };

  // const lastTargetDesc = getLastTargetValueDescription(kpi);
  const isAdmin = currentCompany?.role === "admin";
  const isOwner = kpi?.owner?.id === currentCompany?.user?.id;

  const areActionsVisible = isAdmin || isOwner;

  return (
    <div>
      {isEditKPIVisible && (
        <SaveKPIDrawer
          mode="edit"
          initialValues={{
            id: kpi?.id,
            title: kpi?.title,
            description: kpi?.description,
            current_value:
              kpi?.kpi_current_value_checkins[
                kpi?.kpi_current_value_checkins?.length - 1
              ]?.current_value,
            target_value:
              kpi?.kpi_target_value_checkins[
                kpi?.kpi_target_value_checkins?.length - 1
              ]?.target_value,
            operator:
              kpi?.kpi_target_value_checkins[
                kpi?.kpi_target_value_checkins?.length - 1
              ]?.operator,
            kpi_metric: kpi?.kpi_metric,
            type_id: kpi?.type === "TeamKpi" ? kpi?.type_id : kpi?.type,
            owner_id: kpi?.owner?.id,
          }}
          currentOwner={kpi?.owner}
          visible={isEditKPIVisible}
          onClose={handleEditKPI}
          refetch={refetch}
        />
      )}

      <ShowKPI
        kpi={kpi}
        visible={isShowKPIVisible}
        onClose={handleIsShowKPIVisible}
      />

      <Card className={`mt-8 width-250-px mr-8 ${styles.kpiCard}`}>
        <div id="kpi-more-menu-container" className={styles.kpiMenuContainer} />
        <div
          id="kpi-update-progress-popover-container"
          className={styles.kpiMenuContainer}
        />
        <Row
          justify="space-between"
          align="middle"
          className="width-100-percent"
        >
          <Col span={20}>
            <div>
              <EllipseText
                className="text-secondary"
                characterLimit={17}
                text={kpi?.title}
              />
            </div>
            <div>
              <StatusText kpi={kpi} />
            </div>
          </Col>
          <Col
            span={4}
            className={
              isMoreDropdownVisible || isUpdateProgressPopoverVisible
                ? ""
                : styles.kpiActions
            }
          >
            {areActionsVisible && (
              <Popover
                visible={isUpdateProgressPopoverVisible}
                onVisibleChange={setIsUpdateProgressPopoverVisible}
                trigger={["click"]}
                title="Update Progress"
                content={
                  isUpdateProgressPopoverVisible ? (
                    <KPIProgressForm
                      kpi={kpi}
                      isUpdatingProgress={isUpdatingProgress}
                      handleClose={() => {
                        setIsUpdateProgressPopoverVisible(false);
                      }}
                      handleFinish={onUpdateProgress}
                    />
                  ) : (
                    <></>
                  )
                }
                getPopupContainer={() =>
                  document.getElementById(
                    "kpi-update-progress-popover-container"
                  )
                }
              >
                <ImportOutlined className="cursor-pointer" />
              </Popover>
            )}

            <Dropdown
              visible={isMoreDropdownVisible}
              overlay={
                <Menu mode="vertical">
                  <Menu.Item key="edit-kpi" onClick={handleShowKPI}>
                    Show Progress
                  </Menu.Item>
                  {areActionsVisible && (
                    <Menu.Item key="edit-kpi" onClick={handleEditKPI}>
                      Edit KPI
                    </Menu.Item>
                  )}
                  {areActionsVisible && (
                    <Menu.Item key="delete-kpi" onClick={handleDelete}>
                      {isDeleting && <InlineSpinner />}
                      Delete KPI
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={["click"]}
              placement="topLeft"
              getPopupContainer={() =>
                document.getElementById("kpi-more-menu-container")
              }
              onVisibleChange={setIsMoreDropdownVisible}
            >
              <MoreOutlined />
            </Dropdown>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

KPICard.propTypes = {};

export default KPICard;

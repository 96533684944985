import React, { useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";

import { BoxLoader } from "components";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { errorNotification } from "app/appUtils";
import { getReviewCycleApi } from "apis/perfReviewApi";
import AddReviewCycle from "../add/AddReviewCycle";

const CreateReviewCycleFromDraft = (props) => {
  const { id } = useParams();
  const [cycleData, setCycleData] = useState(null);

  const { isLoading, isFetching } = useQuery(
    ["getReviewCycleForEdit", id],
    getReviewCycleApi,
    {
      cacheTime: 1,
      onSuccess: (data) => {
        const managerMappings = {};
        const participants = data?.participants || [];
        participants.forEach((review) => {
          managerMappings[review?.reviewee_id] = review?.reviewer_id;
        });
        const initValues = {
          ...data,
          period: [
            moment(data?.period_from, "YYYY-MM-DD"),
            moment(data?.period_to, "YYYY-MM-DD"),
          ],
          appeal_window: [
            data?.appeal_window_start_date
              ? moment(data?.appeal_window_start_date, "YYYY-MM-DD")
              : null,
            data?.appeal_window_end_date
              ? moment(data?.appeal_window_end_date, "YYYY-MM-DD")
              : null,
          ],
          reviews:
            data?.participants?.map((review) => ({
              reviewee_id: review?.reviewee_id,
              reviewer_id: review?.reviewer_id,
            })) || [],
          managerMappings,
          deadline_for_adding_peer: data?.deadline_for_adding_peer
            ? moment(data?.deadline_for_adding_peer, "YYYY-MM-DD")
            : null,
          deadline_for_submit: moment(data?.deadline_for_submit, "YYYY-MM-DD"),
          deadline_for_sign: moment(data?.deadline_for_sign, "YYYY-MM-DD"),
          auto_sign_date: data?.auto_sign_date
            ? moment(data?.auto_sign_date, "YYYY-MM-DD")
            : null,
        };
        delete initValues.summary;
        setCycleData(initValues);
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = isLoading || isFetching;

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      {loader ? (
        <FullScreenLayout
          source={{ path: "/company/reviews", name: "Reviews" }}
        >
          <BoxLoader />
        </FullScreenLayout>
      ) : (
        <AddReviewCycle mode="create_from_draft" initialValues={cycleData} />
      )}
    </>
  );
};

CreateReviewCycleFromDraft.propTypes = {};

export default CreateReviewCycleFromDraft;

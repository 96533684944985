import React from "react";
import PropTypes from "prop-types";

import {
  PerfReviewViewMCQSelectionsList,
  EmployeeAvatar,
  PerfReviewEditMCQAnswer,
  PerfReviewMCQRadioGroup,
} from "components";
import { getNameFromEmployeeObj } from "app/appUtils";

const getRatingOptionId = (type, reviewData) => {
  if (type === "reviewee_text") {
    return reviewData?.reviewee?.rating_option_id;
  }
  return reviewData?.reviewer?.rating_option_id;
};

const PerfReviewMCQAnswer = ({
  reviewId,
  type = "",
  isFromEdit = false,
  isFromHrAdmin = false,
  shouldShowEdit = false,
  disabled = false,
  isPeer = false,
  isRating = false,
  shouldEditRevieweeAnswer = false,
  reviewData = {},
  question = {},
  questionOptionHash = {},
  value = null,
  onChange = () => {},
  revieweeAnswers = {},
  reviewerAnswers = {},
  peersAnswers = [],
}) => {
  const isReviewee = type === "reviewee_text";

  if (isReviewee && !isFromHrAdmin) {
    return (
      <>
        {disabled ? (
          <PerfReviewViewMCQSelectionsList
            isRating={isRating}
            question={question}
            revieweeAnswers={revieweeAnswers}
            reviewerAnswers={reviewerAnswers}
            peersAnswers={peersAnswers}
            reviewData={reviewData}
          />
        ) : (
          <>
            <div className="flex-display mb-8">
              <EmployeeAvatar
                className="mr-8 mt-4 min-width-32-px"
                emptyText="No Reviewer"
                employee={
                  type === "reviewee_text" || isPeer
                    ? reviewData?.reviewee
                    : reviewData?.reviewer
                }
              />
              <div className="width-100-percent">
                <PerfReviewMCQRadioGroup
                  isRating={isRating}
                  ratingOptions={reviewData?.perf_review_cycle?.rating_options}
                  question={question}
                  value={value}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {!isPeer &&
        !isRating &&
        peersAnswers?.map((peer) => {
          const peerAnswers = peer?.answers || {};
          const isNamePresent = peer?.name;
          return (
            <PerfReviewEditMCQAnswer
              reviewId={reviewId}
              assessmentId={peer?.id}
              isFromHrAdmin={isFromHrAdmin}
              ratingOptions={reviewData?.perf_review_cycle?.rating_options}
              question={question}
              isRating={isRating}
              employee={isNamePresent ? peer : { name: "Anonymous" }}
              employeeDisplayName={
                isNamePresent ? getNameFromEmployeeObj(peer) : "Anonymous"
              }
              employeeRelation={" (Peer)"}
              shouldShowEdit={!isReviewee}
              questionOptionHash={questionOptionHash}
              answer={peerAnswers[question.id]}
            />
          );
        })}

      {(type === "reviewer_text" || isFromHrAdmin) && (
        <PerfReviewEditMCQAnswer
          reviewId={reviewId}
          isFromHrAdmin={isFromHrAdmin}
          assessmentId={reviewData?.reviewee?.assessment_id}
          ratingOptions={reviewData?.perf_review_cycle?.rating_options}
          question={question}
          isRating={isRating}
          employee={reviewData?.reviewee}
          employeeDisplayName={getNameFromEmployeeObj(reviewData?.reviewee)}
          employeeRelation={" (Report)"}
          shouldShowEdit={shouldEditRevieweeAnswer}
          questionOptionHash={questionOptionHash}
          answer={
            isRating
              ? getRatingOptionId("reviewee_text", reviewData)
              : revieweeAnswers[question.id]
          }
        />
      )}

      {isFromEdit ? (
        <div className="flex-display mb-8">
          <EmployeeAvatar
            className="mr-8 mt-4 min-width-32-px"
            emptyText="No Reviewer"
            employee={
              type === "reviewee_text" || isPeer
                ? reviewData?.reviewee
                : reviewData?.reviewer
            }
          />
          <div className="width-100-percent">
            <PerfReviewMCQRadioGroup
              question={question}
              ratingOptions={reviewData?.perf_review_cycle?.rating_options}
              isRating={isRating}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      ) : (
        <PerfReviewEditMCQAnswer
          reviewId={reviewId}
          isFromHrAdmin={isFromHrAdmin}
          assessmentId={reviewData?.reviewer?.assessment_id}
          question={question}
          ratingOptions={reviewData?.perf_review_cycle?.rating_options}
          isRating={isRating}
          employee={
            type === "reviewee_text" || isPeer
              ? reviewData?.reviewee
              : reviewData?.reviewer
          }
          employeeDisplayName={getNameFromEmployeeObj(
            type === "reviewee_text" || isPeer
              ? reviewData?.reviewee
              : reviewData?.reviewer
          )}
          employeeRelation={isPeer ? "" : " (Manager)"}
          shouldShowEdit={shouldShowEdit}
          questionOptionHash={questionOptionHash}
          answer={
            isRating
              ? getRatingOptionId(type, reviewData)
              : reviewerAnswers[question.id]
          }
        />
      )}
    </>
  );
};

PerfReviewMCQAnswer.propTypes = {
  employee: PropTypes.object,
};

export default PerfReviewMCQAnswer;

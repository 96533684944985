import React, { useState, useEffect, useCallback } from "react";
// import PropTypes from 'prop-types';
import { isEqual } from "lodash";
import { Alert, Row, Col, Card, Typography, Button, Grid } from "antd";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { useAutosave } from "react-autosave";
import { useParams, useHistory } from "react-router-dom";

import {
  EmployeeAvatar,
  WriteAnswersV2,
  BoxLoader,
  ViewAnswers,
} from "components";
import SeeOKRsDrawer from "app/home/common/SeeOKRsDrawer";
import { useCurrentUser, useCurrentCompany } from "store";
import { useButtonWithSpinner } from "hooks";
import SubmissionButtons from "./SubmissionButtons";
import { appealed, appealRejected, appealAccepted } from "../reviewConstants";
import {
  getReviewDetailsApi,
  updateReviewApi,
  saveAndUnlockReviewApi,
  peerAssessmentSubmissionApi,
} from "apis/perfReviewApi";
import {
  formattedDate,
  formattedDateShortYear,
  errorNotification,
  getNameFromEmployeeObj,
  successNotification,
  errorNotificationWithString,
} from "app/appUtils";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

// TODO: update appealed string
const ACTION_TYPES = {
  save: "save_as_draft",
  save_and_close: "save_as_draft",
  submit: "submit",
  unlock: "unlock",
  sign: "sign",
  appeal: "appeal",
};

const getSelectedPeerAssessment = (reviewData, peerId) => {
  const peerAssessments = reviewData?.peer_assessments || [];
  const selectedPeerAssessments =
    peerAssessments?.filter((peer) => `${peer?.id}` === peerId) || [];

  const selectedPeerAssessment =
    selectedPeerAssessments?.length > 0 ? selectedPeerAssessments[0] : {};

  return selectedPeerAssessment;
};

const ReviewsEditV2 = ({
  enabled = false,
  setUrlSwitchIds = () => {},
  setSelectedReviewCycle = () => {},
}) => {
  const screens = useBreakpoint();
  const history = useHistory();
  const { reviewId, peerId } = useParams();
  const [currentUser] = useCurrentUser();
  const [currentCompany] = useCurrentCompany();

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [lastAutoSavedAnswers, setLastAutoSavedAnswers] = useState({});
  const [lastAutoSavedRatingOptionId, setLastAutoSavedRatingOptionId] =
    useState(null);
  const [updatedAnswers, setUpdatedAnswers] = useState({});
  const [isFromAutoSave, setIsFromAutoSave] = useState(false);
  const [ratingOptionId, setRatingOptionId] = useState(null);
  const [buttonActionType, setButtonActionType] = useButtonWithSpinner();

  const isPeer = Boolean(peerId);

  const {
    data: reviewData = {},
    status: reviewDataStatus,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["reviewDetails", reviewId, peerId], getReviewDetailsApi, {
    enabled: enabled && (Boolean(reviewId) || Boolean(peerId)),
    cacheTime: 0,
    onSuccess: (data) => {
      setSelectedReviewCycle(data?.perf_review_cycle);
      setUrlSwitchIds({
        navigateTo: "overview",
        reviewCycleId: data?.perf_review_cycle?.id,
      });
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  useEffect(() => {
    if (!isLoading && reviewDataStatus === "success") {
      if (Boolean(peerId)) {
        const selectedPeerAssessmentFromData =
          getSelectedPeerAssessment(reviewData, peerId) || {};

        setUpdatedAnswers(selectedPeerAssessmentFromData?.answers || {});
        setLastAutoSavedAnswers(selectedPeerAssessmentFromData?.answers || {});
      } else {
        if (reviewData?.my_role === "reviewee") {
          setUpdatedAnswers(reviewData?.reviewee?.answers || {});
          setRatingOptionId(reviewData?.reviewee?.rating_option_id || null);
        } else if (reviewData?.my_role === "reviewer") {
          setUpdatedAnswers(reviewData?.reviewer?.answers || {});
          setRatingOptionId(reviewData?.reviewer?.rating_option_id || null);
        }

        setLastAutoSavedAnswers(
          reviewData?.my_role === "reviewee"
            ? reviewData?.reviewee?.answers || {}
            : reviewData?.reviewer?.answers || {}
        );
        setLastAutoSavedRatingOptionId(
          reviewData?.my_role === "reviewee"
            ? reviewData?.reviewee?.rating_option_id || null
            : reviewData?.reviewer?.rating_option_id || null
        );
      }
    }
  }, [reviewData, isLoading, peerId, reviewDataStatus]);

  const [
    updateReview,
    { isLoading: isUpdatingReview, status: updateReviewStatus },
  ] = useMutation(updateReviewApi, {
    onSuccess: (data, variables) => {
      if (variables?.autoSave) {
        // successNotification("Auto saved your review");
        setLastAutoSavedAnswers(variables?.answers || {});
        setLastAutoSavedRatingOptionId(variables?.rating_option_id || null);
        // Not re-fetching after auto save
      } else {
        successNotification("Saved the Review");
        // if (["save_and_close", "submit", "sign"].includes(buttonActionType)) {
        //   history.push(
        //     `/reviews/${reviewData?.perf_review_cycle?.id}/details`
        //   );
        // }
        refetch();
      }
    },
    onError: (error, variables) => {
      if (error && !variables?.autoSaved) {
        errorNotification(error);
      }
    },
  });

  const [
    peerAssessmentSubmission,
    { isLoading: isUpdatingPeerReview, status: updatePeerReviewStatus },
  ] = useMutation(peerAssessmentSubmissionApi, {
    onSuccess: (data, variables) => {
      if (variables?.autoSave) {
        // successNotification("Auto saved your review");
        setLastAutoSavedAnswers(variables?.answers || {});
        setLastAutoSavedRatingOptionId(variables?.rating_option_id || null);
        // Not re-fetching after auto save
      } else {
        successNotification("Saved the Review");
        // if (["save_and_close", "submit", "sign"].includes(buttonActionType)) {
        //   history.push(
        //     `/reviews/${reviewData?.perf_review_cycle?.id}/details`
        //   );
        // }
        refetch();
      }
    },
    onError: (error, variables) => {
      if (error && !variables?.autoSaved) {
        errorNotification(error);
      }
    },
  });

  const [saveAndUnlockReview, { isLoading: isUnlockLoading }] = useMutation(
    saveAndUnlockReviewApi,
    {
      onSuccess: (data, { saveReview }) => {
        refetch();
        successNotification(
          saveReview ? "Saved and Updated the Review" : "Saved the Review"
        );
      },
      onError: (error) => {
        if (error) {
          errorNotification(error);
        }
      },
    }
  );

  const myRole = reviewData?.my_role;
  const otherRole = myRole === "reviewee" ? "reviewer" : "reviewee";
  const [checkSubmit, checkSign, , otherSubmit, myStatus] =
    myRole === "reviewee"
      ? //TODO: add peers condition here as well
        [
          reviewData?.reviewee?.check_submit || false,
          reviewData?.reviewee?.check_sign || false,
          "Your Review" || "",
          reviewData?.reviewer?.check_submit || false,
          reviewData?.reviewee?.status || "",
        ]
      : [
          reviewData?.reviewer?.check_submit || false,
          reviewData?.reviewer?.check_sign || false,
          `${reviewData?.reviewee?.name}'s Review` || "",
          reviewData?.reviewee?.check_submit || false,
          reviewData?.reviewer?.status || "",
        ];

  const handleAutoSave = useCallback(
    (data) => {
      const answers = data?.answers || {};

      const isSubmitted = isPeer
        ? selectedPeerAssessment?.status === "Submitted"
        : checkSubmit;

      if (
        !isSubmitted &&
        (!isEqual(answers, data?.lastAutoSavedAnswers) ||
          data?.rating_option_id !== data?.lastAutoSavedRatingOptionId) &&
        !data?.isLoading &&
        !isUpdatingPeerReview &&
        !isUpdatingReview &&
        !isUnlockLoading
      ) {
        setIsFromAutoSave(true);
        if (isPeer) {
          peerAssessmentSubmission({
            assessmentId: data?.id,
            actionType: ACTION_TYPES["save"],
            answers,
            autoSave: true,
          });
        } else {
          updateReview({
            reviewId: data?.id,
            actionType: ACTION_TYPES["save"],
            answers: answers,
            rating_option_id: data?.rating_option_id,
            autoSave: true,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkSubmit]
  );

  useAutosave({
    data: {
      isLoading,
      actionType: ACTION_TYPES["save"],
      answers: updatedAnswers,
      rating_option_id: ratingOptionId,
      id: isPeer ? peerId : reviewId,
      lastAutoSavedAnswers,
      lastAutoSavedRatingOptionId,
      autoSaved: true,
    },
    onSave: handleAutoSave,
    // interval: 60000, // Auto save after ever 1 min ~ 60 secs ~ 60000
    // interval: 30000, // Auto save after ever 30 secs ~ 30000
    interval: 3000, // Auto save after ever 3 secs ~ 3000
  });

  const handleSelfReviewNavigation = (selfReviewId) => {
    history.push(`/reviews/review/${selfReviewId}/`);
    // NO need to update answers as there will be another api call
    // setUpdatedAnswers(
    //   isReviewee ? reviewData?.reviewee?.answers : reviewData?.reviewer?.answers
    // );
  };

  const handlePeerReviewNavigation = (peerId, reviewDataId) => {
    history.push(`/reviews/peer/${peerId}/review/${reviewDataId || ""}`);
    // NO need to update answers as there will be another api call
    // const selectedPeerAssessment = getSelectedPeerAssessment(
    //   reviewData,
    //   peerId
    // );
    // setUpdatedAnswers(selectedPeerAssessment?.answers || {});
  };

  const handleTextAnswerChange = (e, questionId) => {
    const newAnswer = { [questionId]: e.target.value };

    setUpdatedAnswers({ ...updatedAnswers, ...newAnswer });
  };

  const handleMcqAnswerChange = (e, questionId) => {
    const newAnswer = { [questionId]: parseInt(e.target.value) };
    setUpdatedAnswers({ ...updatedAnswers, ...newAnswer });
  };

  const handleRatingChange = (e) => {
    setRatingOptionId(e?.target?.value);
  };

  const handleSeeEmployeeOkrs = (employee = null) => {
    setSelectedEmployee(employee);
  };

  const handleUpdateReview = (type) => {
    setIsFromAutoSave(false);
    setButtonActionType(type);
    const actionType = ACTION_TYPES[type];
    let answers = updatedAnswers;

    Object.keys(answers).forEach((key) => {
      if (typeof answers[key] === "string" && answers[key].trim() === "") {
        delete answers[key];
      }
    });

    if (type === ACTION_TYPES["unlock"]) {
      saveAndUnlockReview({
        answers: answers,
        reviewId,
        saveReview:
          reviewData?.status && !reviewData?.status[myRole].check_submit,
      });
    } else if (actionType === ACTION_TYPES["submit"]) {
      const requiredQuestions =
        reviewData?.questions?.filter((question) => question?.is_required) ||
        [];

      const questionsLength =
        myRole === "reviewee" && !isPeer
          ? reviewData?.questions?.length
          : requiredQuestions?.length;

      if (questionsLength === Object.keys(answers)?.length) {
        if (isPeer) {
          peerAssessmentSubmission({
            assessmentId: peerId,
            actionType,
            answers,
          });
        } else {
          updateReview({
            actionType: actionType,
            answers: answers,
            rating_option_id: ratingOptionId,
            reviewId,
          });
        }
      } else {
        errorNotificationWithString(
          "Few questions are empty. Please answer them up to submit!"
        );
      }
    } else {
      if (isPeer) {
        peerAssessmentSubmission({
          assessmentId: peerId,
          actionType,
          answers,
        });
      } else {
        updateReview({
          actionType: actionType,
          answers: answers,
          rating_option_id: ratingOptionId,
          reviewId,
        });
      }
    }
  };

  const isReviewee = myRole === "reviewee";
  let answersType = isReviewee ? "reviewee_text" : "reviewer_text";
  if (isPeer) {
    answersType = "peer_text";
  }
  let areAnswersDisabled = false;

  const selectedPeerAssessment = getSelectedPeerAssessment(reviewData, peerId);

  if (!Boolean(myRole) && !isPeer) {
    areAnswersDisabled = true;
  } else if (answersType === "reviewee_text") {
    areAnswersDisabled =
      myRole === "reviewer" || reviewData?.reviewee?.check_submit;
  } else if (answersType === "reviewer_text") {
    areAnswersDisabled =
      myRole === "reviewee" || reviewData?.reviewer?.check_submit;
  } else if (isPeer) {
    areAnswersDisabled =
      selectedPeerAssessment?.status === "Submitted" ||
      myRole === "reviewer" ||
      reviewData?.reviewee?.email !== currentUser?.email;
  }

  const whoIsThisReviewFor = isPeer
    ? getNameFromEmployeeObj(selectedPeerAssessment)
    : getNameFromEmployeeObj(reviewData?.reviewee);

  const reviewForProfilePic = isPeer
    ? selectedPeerAssessment?.user_profile_pic_url
    : reviewData?.reviewee?.user_profile_pic_url;

  const selectedEmployeeObj = isPeer
    ? selectedPeerAssessment
    : { ...reviewData?.reviewee, employee_id: reviewData?.reviewee?.id };

  const isSelfReviewPresent = Boolean(reviewData?.id);

  let reviewsCount = 0;
  if (isSelfReviewPresent) {
    reviewsCount += 1;
  }
  if (reviewData?.peer_assessments?.length > 0) {
    reviewsCount += reviewData?.peer_assessments?.length;
  }

  const isSelfReviewDone =
    reviewData?.reviewee?.status === "Signed" &&
    reviewData?.reviewer?.status === "Signed";

  const isPerfReviewShared = reviewData?.perf_review_cycle?.shared;
  const appealWindowStart =
    reviewData?.perf_review_cycle?.appeal_window_start_date || null;
  const appealWindowEnd =
    reviewData?.perf_review_cycle?.appeal_window_end_date || null;

  const extraMsgOrgs =
    process?.env?.REACT_APP_PERF_REVIEW_BULK_UPLOAD_ORGS?.split(", ");

  return (
    <>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <>
          <SeeOKRsDrawer
            screens={screens}
            employee={selectedEmployee}
            onClose={() => handleSeeEmployeeOkrs(null)}
          />
          <Row gutter={[16]}>
            <Col span={6}>
              <Card
                title={`Reviews (${reviewsCount})`}
                className="reviews-edit-side-card"
              >
                {isSelfReviewPresent && (
                  <>
                    <Text strong className="block-display pl-40 pt-16 pb-8">
                      Self-review
                    </Text>
                    <div
                      style={{
                        borderLeft: !isPeer ? "3px solid #5330c9" : "",
                        marginBottom:
                          reviewData?.peer_assessments?.length > 0
                            ? ""
                            : "16px",
                      }}
                    >
                      {isSelfReviewDone ? (
                        <CheckOutlined
                          style={{
                            color: "green",
                            fontSize: "15px",
                            paddingLeft: !isPeer ? "17px" : "20px",
                            paddingRight: "5px",
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            display: "block",
                            float: "left",
                            height: "15px",
                            paddingLeft: !isPeer ? "32px" : "35px",
                            paddingRight: "5px",
                          }}
                        />
                      )}
                      <Text
                        className={`cursor-pointer ${
                          !isPeer ? "text-primary" : ""
                        }`}
                        onClick={() =>
                          handleSelfReviewNavigation(reviewData?.id)
                        }
                      >
                        {getNameFromEmployeeObj(reviewData?.reviewee)}
                      </Text>
                    </div>
                  </>
                )}
                {reviewData?.peer_assessments?.length > 0 && (
                  <div className="mt-24 mb-16">
                    <Text strong className="block-display pl-40 mb-4">
                      Peer reviews
                    </Text>
                    {reviewData?.peer_assessments?.map((peer) => (
                      <div
                        style={{
                          borderLeft:
                            `${peer?.id}` === peerId ? "3px solid #5330c9" : "",
                          marginBottom: "4px",
                        }}
                      >
                        {peer?.status === "Submitted" ? (
                          <CheckOutlined
                            style={{
                              color: "green",
                              fontSize: "15px",
                              paddingLeft:
                                `${peer?.id}` === peerId ? "17px" : "20px",
                              paddingRight: "5px",
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              display: "block",
                              float: "left",
                              height: "15px",
                              paddingLeft:
                                `${peer?.id}` === peerId ? "32px" : "35px",
                              paddingRight: "5px",
                            }}
                          />
                        )}
                        <Text
                          className={`cursor-pointer ${
                            `${peer?.id}` === peerId ? "text-primary" : ""
                          }`}
                          onClick={() =>
                            handlePeerReviewNavigation(peer?.id, reviewData?.id)
                          }
                        >
                          {getNameFromEmployeeObj(peer)}
                        </Text>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
              <Card className="mt-16" title="Review details">
                <Text strong className="block-display">
                  Milestones
                </Text>
                <Text className="ml-8 mt-4 block-display">
                  <Text type="secondary" className="block-display">
                    Due date for submission
                  </Text>
                  <Text strong>
                    {formattedDate(
                      reviewData?.perf_review_cycle?.deadline_for_submit
                    )}
                  </Text>
                </Text>

                {appealWindowStart && appealWindowEnd && (
                  <Text className="ml-8 mt-4 block-display">
                    <Text type="secondary" className="block-display">
                      Appeal Window
                    </Text>
                    <Text strong>
                      {`${formattedDateShortYear(
                        appealWindowStart
                      )} - ${formattedDateShortYear(appealWindowEnd)}`}
                    </Text>
                  </Text>
                )}

                <Text className="ml-8 mt-8 block-display">
                  <Text type="secondary" className="block-display">
                    Due date for sign
                  </Text>
                  <Text strong>
                    {formattedDate(
                      reviewData?.perf_review_cycle?.deadline_for_sign
                    )}
                  </Text>
                </Text>

                {reviewData?.perf_review_cycle?.auto_sign_date && (
                  <Text className="ml-8 mt-8 block-display">
                    <Text type="secondary" className="block-display">
                      Auto Sign date
                    </Text>
                    <Text strong>
                      {formattedDate(
                        reviewData?.perf_review_cycle?.auto_sign_date
                      )}
                    </Text>
                  </Text>
                )}

                <Text strong className="mt-16 block-display">
                  Description
                </Text>
                <Text
                  className="block-display ml-8"
                  type="secondary"
                  style={{ fontSize: "0.9rem", color: "#626262" }}
                >
                  {reviewData?.perf_review_cycle?.description}
                </Text>
              </Card>
            </Col>
            <Col span={18}>
              {extraMsgOrgs?.includes(`${currentCompany?.id}`) &&
                myRole === "reviewee" &&
                !isPeer &&
                !checkSubmit && (
                  <Alert
                    className="mb-8"
                    message={
                      <Row align="middle">
                        <InfoCircleOutlined className="mr-8" />
                        <Text>
                          Please write your Assessment after completing your
                          OKRs with a focus on this review period.
                        </Text>
                      </Row>
                    }
                  />
                )}
              <Card>
                <Row gutter={[30]} align="middle">
                  <Col>
                    <EmployeeAvatar
                      height={"80px"}
                      width={"80px"}
                      employee={{
                        name: whoIsThisReviewFor,
                        user_profile_pic_url: reviewForProfilePic,
                      }}
                    />
                  </Col>
                  <Col>
                    <Title
                      level={3}
                      className="inline-display"
                      style={{ fontSize: "22px", fontWeight: "400" }}
                    >
                      {`${whoIsThisReviewFor}'s Review`}
                    </Title>
                    <Button
                      size="small"
                      className="ml-16"
                      onClick={() =>
                        handleSeeEmployeeOkrs({
                          ...selectedEmployeeObj,
                          id: selectedEmployeeObj?.employee_id,
                        })
                      }
                    >
                      {"See OKRs"}
                    </Button>
                  </Col>
                </Row>
              </Card>
              {isFetching ? (
                <BoxLoader />
              ) : (
                <Card className="mt-16">
                  {!isPeer &&
                  ((checkSubmit && !checkSign) ||
                    (checkSubmit && checkSign) ||
                    myStatus === appealed ||
                    myStatus === appealRejected ||
                    myStatus === appealAccepted) ? (
                    <ViewAnswers
                      reviewData={reviewData}
                      revieweeAnswers={reviewData?.reviewee?.answers || {}}
                      reviewerAnswers={reviewData?.reviewer?.answers || {}}
                    />
                  ) : (
                    <WriteAnswersV2
                      isPeer={isPeer}
                      reviewData={reviewData}
                      selectedPeerAssessment={selectedPeerAssessment}
                      answers={updatedAnswers}
                      ratingOptionId={ratingOptionId}
                      type={answersType}
                      disabled={areAnswersDisabled}
                      shouldShowCard={false}
                      handleTextAnswerChange={handleTextAnswerChange}
                      handleMcqAnswerChange={handleMcqAnswerChange}
                      handleRatingChange={handleRatingChange}
                    />
                  )}

                  <SubmissionButtons
                    isPeer={isPeer}
                    myRole={myRole || "peer"}
                    otherRole={otherRole}
                    reviewData={reviewData}
                    checkSubmit={
                      isPeer
                        ? selectedPeerAssessment?.status === "Submitted"
                        : checkSubmit
                    }
                    myStatus={myStatus}
                    isPerfReviewShared={isPerfReviewShared}
                    otherSubmit={otherSubmit}
                    checkSign={checkSign}
                    updateReviewStatus={updateReviewStatus}
                    updatePeerReviewStatus={updatePeerReviewStatus}
                    isUpdatingPeerReview={isUpdatingPeerReview}
                    isFromAutoSave={isFromAutoSave}
                    isLoading={isUpdatingReview}
                    buttonActionType={buttonActionType}
                    isUnlockLoading={isUnlockLoading}
                    setIsFromAutoSave={setIsFromAutoSave}
                    handleUpdateReview={handleUpdateReview}
                  />
                </Card>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

ReviewsEditV2.propTypes = {};

export default ReviewsEditV2;

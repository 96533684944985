const questions = [
  {
    allow_comments: true,
    description:
      "I understand what my company is focussed on to achieve in the next 3 to 6 months",
    order: 1,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "ALIGNMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "I agree that my company has set the right company goals to be achieved in the next 3 to 6 months",
    order: 2,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "ALIGNMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "I know what I have to do in achieving the company's goals in the next 3 to 6 months.",
    order: 3,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "ALIGNMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "I like what my company wants to achieve in the long term (6 to 24 months)",
    order: 4,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "ALIGNMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "We have the right leadership to lead the execution of the company goals",
    order: 1,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "EXECUTION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "We have the right teams and staff to execute the company goals",
    order: 2,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "EXECUTION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "We have the right tools and processes to plan and manage the execution of our goals",
    order: 3,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "EXECUTION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I dont see any barriers for executing on my goals.",
    order: 4,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "EXECUTION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "I know the progress of my company's execution at any point of time.",
    order: 5,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "EXECUTION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "I get constructive feedback on how to improve my individual performance.",
    order: 1,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I get the support I need to excel in my role.",
    order: 2,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I feel safe in giving feedback to my manager and higher ups.",
    order: 3,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I act on the feedback I get.",
    order: 4,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "It is easy to give feedback for an entire team.",
    order: 5,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "Teams act on the feedback they get.",
    order: 6,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "Teams dont have inter team conflict",
    order: 7,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "BETTERMENT",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "My Company takes effort to recognize the performers.",
    order: 1,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "RECOGNITION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I get recognized fairly for my efforts and contributions.",
    order: 2,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "RECOGNITION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I frequently recognize the efforts of my coworkers.",
    order: 3,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "RECOGNITION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I get compensated appropriately for my contributions.",
    order: 4,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "RECOGNITION",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "Management puts efforts in understanding the challenges faced by the employees.",
    order: 1,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "LISTEN",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description: "I can give feedback easily on any concern.",
    order: 2,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "LISTEN",
    type: "HprSurveyMcq",
    is_required: true,
  },
  {
    allow_comments: true,
    description:
      "Management acts on the feedback collected and addresses serious issues in a timely manner",
    order: 3,
    options: {
      1: "Strongly disagree",
      2: "Partially Disagree",
      3: "Not Sure",
      4: "Partially Agree",
      5: "Strongly Agree",
    },
    hpr_dimension: "LISTEN",
    type: "HprSurveyMcq",
    is_required: true,
  },
];

export default questions;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import {
  Drawer,
  Form,
  Button,
  Row,
  Input,
  Select,
  Typography,
  Grid,
  Tooltip,
  InputNumber,
} from "antd";
import Icon, {
  PlusOutlined,
  UserOutlined,
  TeamOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { useCurrentCompany } from "store";
import {
  KPISelect,
  BoxLoader,
  AddTeam,
  APIBasedEmployeeSearch,
  OptionalFormItemLabel,
} from "components";
import { getAllTeamsApi, saveTeamApi } from "apis/teamApi";
import { saveKPIApi } from "apis/kpis";
import {
  getInactiveTeamNameForKPI,
  successNotification,
  errorNotification,
} from "app/appUtils";

import { ReactComponent as CompanyIcon } from "assets/icons/company.svg";

import styles from "../../OKRs.module.css";

const { Option, OptGroup } = Select;
const { Text } = Typography;

const SaveKPIDrawer = ({
  visible = false,
  mode = "create",
  initialValues = {},
  currentOwner = null,
  onClose = () => {},
  refetch: refetchKPIs = () => {},
}) => {
  const [isAddTeamVisible, setIsAddTeamVisible] = useState(false);

  const [form] = Form.useForm();
  const [currentCompany] = useCurrentCompany();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const {
    data: teams,
    isLoading: isTeamsLoading,
    // isFetching: isTeamsFetching,
    refetch,
  } = useQuery("getAllTeams", getAllTeamsApi);

  const [saveTeam] = useMutation(saveTeamApi, {
    onSuccess: () => {
      successNotification("Saved Team");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [saveKpi] = useMutation(saveKPIApi, {
    onSuccess: () => {
      successNotification("Saved KPI");
      onClose();
      refetchKPIs();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const currentUserId = currentCompany?.user?.id;

  const activeTeams = teams?.filter((team) => team?.active) || [];
  const inActiveTeams = teams?.filter((team) => !team?.active) || [];

  const handleAddTeam = () => {
    setIsAddTeamVisible(!isAddTeamVisible);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleFinish = (values) => {
    const type_id = values.type_id;
    delete values?.typeIdError;
    if (type_id === "IndividualKpi") {
      const kpi = { ...values, type: "IndividualKpi" };
      delete kpi?.type_id;
      saveKpi({ kpi });
    } else if (type_id === "OrganizationKpi") {
      const kpi = { ...values, type: "OrganizationKpi" };
      delete kpi?.type_id;
      saveKpi({ kpi });
    } else {
      saveKpi({ kpi: { ...values, type: "TeamKpi" } });
    }
  };

  const handleSaveTeam = (team) => {
    saveTeam({ team });
    handleAddTeam();
  };

  return (
    <>
      {isAddTeamVisible && (
        <AddTeam
          visible={isAddTeamVisible}
          onOk={handleSaveTeam}
          onCancel={handleAddTeam}
        />
      )}

      <Drawer
        width={"70%"}
        title={mode === "create" ? "Create KPI" : "Edit KPI"}
        visible={visible}
        onClose={onClose}
        footer={
          <Row justify="start">
            <Button type="primary" ghost={true} onClick={onClose}>
              Cancel
            </Button>
            <Button className="ml-8" type="primary" onClick={handleSubmit}>
              Save KPI
            </Button>
          </Row>
        }
      >
        <div className={styles.kpiDrawerContent}>
          <div
            style={{
              width: "70%",
              paddingRight: "16px",
              borderRight: "1px solid #e3e3e3",
            }}
          >
            {isTeamsLoading ? (
              <BoxLoader />
            ) : (
              <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleFinish}
              >
                <Form.Item name={"id"} hidden={true} />

                <Form.Item
                  name="title"
                  label="KPI"
                  rules={[
                    {
                      required: true,
                      message: "Missing KPI.",
                    },
                    {
                      whitespace: true,
                      message: "KPI can't be empty.",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder={"NPS > 8"} />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={<OptionalFormItemLabel label="Description" />}
                >
                  <Input.TextArea
                    className="input-textarea-resize-none"
                    rows={{ minRows: 4, maxRows: 6 }}
                    placeholder={
                      "Why do you think this is a KPI and give the formulae to calculate"
                    }
                  />
                </Form.Item>
                <Row>
                  <Form.Item
                    name="current_value"
                    label="Current Value"
                    rules={[
                      {
                        required: true,
                        message: "Missing current value.",
                      },
                    ]}
                    required={false}
                  >
                    <InputNumber className="width-150-px" />
                  </Form.Item>
                  <Row className="ml-16">
                    <Form.Item
                      name="target_value"
                      label="Target Value"
                      rules={[
                        {
                          required: true,
                          message: "Missing target value.",
                        },
                      ]}
                      required={false}
                    >
                      <InputNumber className="width-150-px" />
                    </Form.Item>
                    <Form.Item
                      className="ml-8"
                      name="operator"
                      label={<></>}
                      rules={[
                        {
                          required: true,
                          message: "Missing operator.",
                        },
                      ]}
                      required={false}
                    >
                      <Select>
                        <Option value=">=">{`>=`}</Option>
                        <Option value="<=">{`<=`}</Option>
                      </Select>
                    </Form.Item>
                  </Row>
                </Row>
                <Row>
                  <Form.Item
                    className="width-150-px"
                    name="kpi_metric"
                    label="Metric"
                    rules={[
                      {
                        required: true,
                        message: "Missing metric.",
                      },
                      {
                        whitespace: true,
                        message: "Metric can't be empty.",
                      },
                    ]}
                    required={false}
                  >
                    <KPISelect />
                  </Form.Item>
                  <Form.Item name="type_id" hidden />
                  <Form.Item
                    className="width-150-px ml-16"
                    label="Type"
                    // rules={[{ required: true, message: "Missing Type." }]}
                    // required={false}
                    shouldUpdate
                  >
                    {({ getFieldValue, setFieldsValue }) => {
                      const type = getFieldValue("type_id");
                      const handleChange = (val) => {
                        if (val) {
                          setFieldsValue({ type_id: val });
                          if (
                            val === "IndividualKpi" ||
                            val === "OrganizationKpi"
                          ) {
                            setFieldsValue({ owner_id: currentUserId });
                          }
                        } else {
                          handleAddTeam();
                        }
                      };
                      const [correctedTeamId, isTeamInactive] =
                        getInactiveTeamNameForKPI(
                          type,
                          activeTeams,
                          inActiveTeams
                        );
                      return (
                        <>
                          <Select
                            value={correctedTeamId}
                            onChange={handleChange}
                          >
                            <Option value={"IndividualKpi"}>
                              <UserOutlined /> Individual
                            </Option>
                            <Option value={"OrganizationKpi"}>
                              <Icon component={CompanyIcon} /> Organization
                            </Option>
                            <OptGroup label="Teams">
                              {activeTeams?.map((team) => (
                                <Option value={team?.id}>
                                  <TeamOutlined />
                                  {team?.name}
                                </Option>
                              ))}
                            </OptGroup>

                            <div>
                              <Button
                                type="link"
                                size={screens.xxl ? "middle" : "small"}
                              >
                                <PlusOutlined />
                                Add Team
                              </Button>
                            </div>
                          </Select>
                          <Text type="danger">
                            {isTeamInactive && "Team can't be inactive"}
                          </Text>
                        </>
                      );
                    }}
                  </Form.Item>
                </Row>
                <Form.Item
                  className="width-250-px"
                  name="owner_id"
                  label={
                    <>
                      <Text className="mr-8">Owner</Text>
                      <Tooltip
                        title={"Type at least 3 characters to search employees"}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  rules={[{ required: true, message: "Missing Owner." }]}
                  required={false}
                >
                  <APIBasedEmployeeSearch
                    shouldShowAvatar={true}
                    currentOwners={currentOwner || currentCompany?.user}
                  />
                </Form.Item>
              </Form>
            )}
          </div>
          <div style={{ width: "30%", paddingLeft: "16px" }}>
            <Text className="block-display">
              Key Performance Indicators or KPIs are measurements that show how
              your team or company is performing on its core responsibilities.
            </Text>

            <Text className="block-display mt-16">
              When defining KPIs consider:
            </Text>
            <ul className="block-display mt-8 ml-8">
              <li>
                These metrics will influence daily behavior and decision-making
              </li>
              <li>
                Their status indicates if performance is healthy or unhealthy
              </li>
            </ul>
          </div>{" "}
        </div>
      </Drawer>
    </>
  );
};

SaveKPIDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SaveKPIDrawer;

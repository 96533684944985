import { Server } from "miragejs";
import { appInit, firebaseApiMock } from "./appApi.mock";
import { getEmployees } from "./commonApi.mock.js";
import {
  getPerfReviewCycles,
  postPerfReviewCycle,
  getMyReviews,
  getReviewDetails,
  updateReview,
} from "./reviews.mock.js";
import {
  deletePraise,
  unlikePraise,
  savePraise,
  likePraise,
  getPraises,
  postComment,
  getAllComments,
  updateComment,
  deleteComment,
  savePraiseBadge,
  getAllPraiseBadges,
  deletePraiseBadge,
} from "./praiseApi.mock.js";
import { getBillingDetailsMock, getSetupIntentMock } from "./billing.mock";
import {
  getChecklistApiMock,
  updateCheckListMock,
} from "./setupCheckList.mock";
import {
  getAllThreeSixtyCycles,
  saveThreeSixtyCycle,
  getCycleDetails,
  getMyParticipationsApi,
  updateReviewersApi,
  deleteReviewerApi,
  getThreeSixtyFeedbackParticipationMockApi,
  postThreeSixtyFeedbackParticipationMockApi,
  showFeedbackMockApi,
} from "./threeSixtyApi.mock";
import {
  getAllTeamsMockApi,
  saveAllTeamMockApi,
  deleteTeamMockApi,
} from "./settings.mock";
import {
  getAllObjectivePeriodsMockApi,
  saveObjectivePeriodMockApi,
  deleteObjectivePeriodMockApi,
  getObjectiveConfigMockApi,
  getCompanyObjectivesMockApi,
  deleteObjectiveMockApi,
  getObjectiveMockApi,
  saveObjectiveMockApi,
  postCheckInMockApi,
  getMyOKRsMockApi,
  getOKRDetailsMockApi,
} from "./okrV2.mock";

function makeServer({ environment = "development" }) {
  let server = new Server({
    environment,

    routes() {
      this.urlPrefix = "http://localhost:8080";
      this.namespace = "/api/v2";

      this.get(
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=AIzaSyD0pasoSaTsjxNbaQuSsTKTuaA9kDKYc-8I4",
        firebaseApiMock
      );
      this.get("http://localhost:8080/api/init", appInit);

      this.get("/employees", getEmployees);

      this.get("/review_cycles", getPerfReviewCycles);
      this.post("/review_cycles", postPerfReviewCycle);
      this.get("/reviews", getMyReviews);
      this.get("/reviews/13", getReviewDetails);
      this.put("/reviews/13", updateReview);

      // Admin Praise APIs
      this.post("praise_badges/", savePraiseBadge);
      this.get("praise_badges", getAllPraiseBadges);
      this.delete("praise_badges/1", deletePraiseBadge);

      // User Praise APIs
      this.post("/praises/", savePraise);
      this.get("praises/all/9/0", getPraises);
      this.get("praises/all/9/1", getPraises);
      this.delete("praises/:id", deletePraise);
      this.post("praises/:id/like", likePraise);
      this.delete("praises/:id/unlike", unlikePraise);

      // User Praise Comments
      this.post("/praises/:praise_id/comments", postComment);
      this.get("praises/:praise_id/comments/", getAllComments);
      this.put("/praises/:praise_id/comments/:id", updateComment);
      this.delete("/praises/:praise_id/comments/:id", deleteComment);

      //Setup Checklist
      this.get("/admin/checklist", getChecklistApiMock);
      this.put("/admin/checklist/step", updateCheckListMock);

      //Threesixty feedback
      this.get("/admin/threesixty_feedback_cycles/", getAllThreeSixtyCycles);
      this.post("/admin/threesixty_feedback_cycles/", saveThreeSixtyCycle);
      this.get("/admin/threesixty_feedback_cycles/1", getCycleDetails);
      this.get("/threesixty_feedback_participations/", getMyParticipationsApi);
      this.post(
        "/threesixty_feedback_participations/1/reviewers",
        updateReviewersApi
      );
      this.delete(
        "/threesixty_feedback_participations/1/reviewers/1",
        deleteReviewerApi
      );
      this.get(
        "/threesixty_feedback_participations/1/my_reviews/",
        getThreeSixtyFeedbackParticipationMockApi
      );
      this.post(
        "/threesixty_feedback_participations/1/my_reviews/",
        postThreeSixtyFeedbackParticipationMockApi
      );
      this.get("/threesixty_feedback_participations/1/", showFeedbackMockApi);

      //Settings - Team
      this.get("/teams/", getAllTeamsMockApi);
      this.post("/admin/teams/", saveAllTeamMockApi);
      this.delete("admin/teams/1", deleteTeamMockApi);

      //OKR V2
      this.get("/objective_periods/", getAllObjectivePeriodsMockApi);
      this.post("/admin/objective_periods/", saveObjectivePeriodMockApi);
      this.delete("/objectives/config", deleteObjectivePeriodMockApi);

      this.get("/objectives/config", getObjectiveConfigMockApi);
      this.get("/objectives/company_objectives", getCompanyObjectivesMockApi);
      this.get("/objective/1/details", getOKRDetailsMockApi);
      this.delete("/objective/1", deleteObjectiveMockApi);
      this.get("/objectives/1", getObjectiveMockApi);
      this.post("/objective/", saveObjectiveMockApi);
      this.post("/objective/1/key_result/1/ckeckin", postCheckInMockApi);
      this.get("/objectives/my_objectives/1", getMyOKRsMockApi);

      // Billing
      this.get("/admin/billing/", getBillingDetailsMock);
      this.get("/admin/billing/setup_intent/", getSetupIntentMock);
    },
  });

  return server;
}

export { makeServer };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import {
  Alert,
  Button,
  Card,
  Row,
  Col,
  Typography,
  Select,
  Checkbox,
  Form,
  Switch,
} from "antd";

import { BoxLoader, TimeZoneSelect, ButtonWithSpinner } from "components";
// import OKRNavBar from "../common/OKRNavBar";
import {
  getNotificationConfigApi,
  saveNotificationConfigApi,
} from "apis/okrV2Api";
import { timezones, atTimes, days } from "../common/constants";
import {
  successNotification,
  errorNotification,
  filterOptionsByLabel,
} from "app/appUtils";

const { Text, Title } = Typography;
const { Option } = Select;

const getAt = (hour = 0, minute = 0) => {
  return `${hour < 10 ? `0${hour}` : hour}:${
    minute < 10 ? `0${minute}` : minute
  }`;
};

const dailyUpdateActivities = [
  "Creation and deletion of Objective, Key Result and Initiative",
  "Changes in the titles of Objective, Key Result and Initiative",
  "Changes in Objective weightage or Objective alignment",
  "Changes in the KPI metrics of Key Result",
];

const FormItemCheckBox = ({ value, onChange }) => {
  return (
    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
  );
};

const WeekButtons = ({ disabled = false, value = [], onChange = () => {} }) => {
  const handleClick = (day) => {
    const index = value?.indexOf(day);
    console.log({ index, value, day });
    if (index > -1) {
      value.splice(index, 1);
      onChange([...value]);
    } else {
      onChange([...value, day]);
    }
  };

  return (
    <div>
      {days?.map((day) => (
        <Button
          disabled={disabled}
          className={
            disabled
              ? "primary-disabled-btn-text primary-disabled-btn-background mr-8 px-16 okr-notification-btn"
              : "mr-8 px-16 okr-notification-btn"
          }
          type={value?.includes(day?.value) ? "primary" : "default"}
          // ghost={value?.includes(day?.value)}
          onClick={() => {
            handleClick(day?.value);
          }}
        >
          {day?.display}
        </Button>
      ))}
    </div>
  );
};
WeekButtons.propTypes = {
  activeDays: PropTypes.array,
};

const DailyEmailCard = ({
  id = null,
  isReFetching = false,
  notifier = "",
  title = "",
  subtitle = "",
  activeDays = [],
  refetch = () => {},
}) => {
  const [shouldSpin, setShouldSpin] = useState(false);

  useEffect(() => {
    if (!isReFetching) {
      setShouldSpin(false);
    }
  }, [isReFetching]);

  const [saveNotificationConfig, { isLoading }] = useMutation(
    saveNotificationConfigApi,
    {
      onSuccess: () => {
        successNotification("Saved Config");
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleUpdate = (checked) => {
    saveNotificationConfig({
      config: {
        notifier,
        days: checked ? days?.map((day) => day?.value) : [],
      },
      id,
    });
    setShouldSpin(true);
  };

  return (
    <Card
      className="mb-64"
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <Title level={4} className="block-display">
                {title}
              </Title>
              <Text className="block-display">{subtitle}</Text>
            </Row>
          </Col>
          <Col>
            <Switch
              loading={(isReFetching || isLoading) && shouldSpin}
              disabled={isReFetching || isLoading}
              checked={activeDays?.length === days?.length}
              onChange={handleUpdate}
            />
          </Col>
        </Row>
      }
    >
      <div>
        <Text className="block-display">
          Daily activity emails will contain the following:
        </Text>
        <div className="ml-8">
          {dailyUpdateActivities?.map((activity, index) => {
            return (
              <Text className="block-display">{`${
                index + 1
              }. ${activity}`}</Text>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

const NotificationCard = ({
  id = null,
  isReFetching = false,
  disabled = false,
  notifier = "",
  title = "",
  subtitle = "",
  activeDays = [],
  at = "",
  timezoneUtcOffset = "",
  shouldShowCheckBox = false,
  unIncludedDays = 1,
  filterEnabled = false,
  refetch = () => {},
}) => {
  const [form] = Form.useForm();
  const [shouldSpin, setShouldSpin] = useState(false);

  useEffect(() => {
    if (!isReFetching) {
      setShouldSpin(false);
    }
  }, [isReFetching]);

  const [saveNotificationConfig] = useMutation(saveNotificationConfigApi, {
    onSuccess: () => {
      successNotification("Saved Config");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleSave = (values) => {
    const options = {};
    if (values.un_included_days) {
      options["last_updated_day_more_than"] = values.un_included_days;
      options["filter_enabled"] = values.filter_enabled;
    }
    const [hours, minutes] = values?.at?.split(":");

    saveNotificationConfig({
      config: {
        notifier,
        days: values?.days,
        hour: Number(hours),
        minute: Number(minutes),
        timezone_utc_offset: values.timezone_utc_offset,
        options,
      },
      id,
    });
    setShouldSpin(true);
  };

  return (
    <Card
      className="mb-64"
      title={
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <Title level={4} className="block-display">
            {title}
          </Title>
          <Text className="block-display">{subtitle}</Text>
        </Row>
      }
    >
      {disabled && (
        <Alert
          className="mb-16"
          message="Please integrate slack to enable these notifications"
        />
      )}
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          days: activeDays,
          at,
          timezone_utc_offset: timezoneUtcOffset,
          filter_enabled: filterEnabled,
          un_included_days: unIncludedDays,
        }}
        onFinish={handleSave}
      >
        <Row>
          <Col lg={{ span: 11 }} xxl={{ span: 8 }}>
            <Form.Item
              name="days"
              label={
                <Title className="mb-8" level={4}>
                  On
                </Title>
              }
            >
              <WeekButtons disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Form.Item
              name="at"
              label={
                <Title level={4} className="block-display mb-8">
                  At
                </Title>
              }
              className="width-100-px"
              rules={[
                {
                  required: true,
                  message: "At is required",
                },
              ]}
              required={false}
            >
              <Select
                disabled={disabled}
                showSearch
                optionLabelProp="label"
                filterOption={filterOptionsByLabel}
              >
                {atTimes?.map((time) => (
                  <Option key={time} label={time}>
                    {time}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xxl={{ span: 6 }}>
            <Form.Item
              name="timezone_utc_offset"
              label={
                <Title level={4} className="block-display mb-8">
                  Time Zone
                </Title>
              }
              className="width-100-percent"
              rules={[
                {
                  required: true,
                  message: "Timezone is required",
                },
              ]}
              required={false}
            >
              <TimeZoneSelect disabled={disabled} timezones={timezones} />
            </Form.Item>
          </Col>
        </Row>

        {shouldShowCheckBox && !disabled && (
          <Row align="middle" className="mt-24">
            <Form.Item name="filter_enabled" noStyle>
              <FormItemCheckBox />
            </Form.Item>
            <Form.Item name="un_included_days" hidden></Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const filter_enabled = getFieldValue("filter_enabled");
                const unIncludedDays = getFieldValue("un_included_days");

                const handleChange = (val) => {
                  setFieldsValue({ un_included_days: Number(val) || 1 });
                };

                const handleTextClick = () => {
                  setFieldsValue({
                    filter_enabled: !filter_enabled,
                  });
                };

                // const value = enabledDays ? unIncludedDays : 1;
                return (
                  <>
                    <Text
                      className="cursor-pointer ml-8"
                      onClick={handleTextClick}
                    >
                      Do not include OKRs updated within the last
                    </Text>

                    <Select
                      disabled={!filter_enabled}
                      size="small"
                      className="px-8"
                      value={unIncludedDays}
                      onChange={handleChange}
                    >
                      <Option key={1}>1</Option>
                      <Option key={2}>2</Option>
                      <Option key={3}>3</Option>
                      <Option key={4}>4</Option>
                      <Option key={5}>5</Option>
                      <Option key={6}>6</Option>
                      <Option key={7}>7</Option>
                    </Select>
                    <Text className="cursor-pointer" onClick={handleTextClick}>
                      {unIncludedDays === 1 ? "day" : "days"}
                    </Text>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        )}

        <ButtonWithSpinner
          isSpinning={isReFetching && shouldSpin}
          disabled={disabled || isReFetching}
          type="primary"
          htmlType="submit"
          className="mt-24"
        >
          Save
        </ButtonWithSpinner>
      </Form>
    </Card>
  );
};
NotificationCard.propTypes = {
  isDailyEmail: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  activeDays: PropTypes.array,
  at: PropTypes.string,
  timeZone: PropTypes.string,
  unIncludedDays: PropTypes.number,
};

const OKRNotifications = (props) => {
  const { data = [], status, isLoading, isFetching, refetch } = useQuery(
    "getNotificationConfig",
    getNotificationConfigApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = status === "loading" || isLoading;

  const checkInReminder = data[0] || {};
  const summaryEmail = data[1] || {};
  const dailyEmail = data[2] || {};

  return (
    <>
      {/* <Row>
        <Col span={8}>
          <Title level={2}>OKRs</Title>
        </Col>
      </Row> */}
      {/* <OKRNavBar /> */}
      {loader ? (
        <BoxLoader />
      ) : (
        <>
          <NotificationCard
            id={checkInReminder?.id}
            isReFetching={isFetching}
            notifier={checkInReminder?.notifier}
            title="Individual Check-in Reminders"
            subtitle="(Sent to owners of OKRs)"
            activeDays={checkInReminder?.days}
            at={getAt(checkInReminder?.hour, checkInReminder?.minute)}
            timezoneUtcOffset={checkInReminder?.timezone_utc_offset}
            shouldShowCheckBox={true}
            unIncludedDays={
              checkInReminder?.options?.last_updated_day_more_than
            }
            filterEnabled={checkInReminder?.options?.filter_enabled || false}
            refetch={refetch}
          />
          <NotificationCard
            id={summaryEmail?.id}
            notifier={summaryEmail?.notifier}
            isReFetching={isFetching}
            title="Weekly Summary Emails"
            subtitle="(Sent to everyone in the Company)"
            activeDays={summaryEmail?.days}
            at={getAt(summaryEmail?.hour, summaryEmail?.minute)}
            timezoneUtcOffset={summaryEmail?.timezone_utc_offset}
            refetch={refetch}
          />
          <DailyEmailCard
            id={dailyEmail?.id}
            isReFetching={isFetching}
            notifier={dailyEmail?.notifier}
            title="Daily Activity Emails"
            subtitle="(Sent to Managers about their report activities)"
            activeDays={dailyEmail?.days}
            refetch={refetch}
          />
        </>
      )}
    </>
  );
};

OKRNotifications.propTypes = {};

export default OKRNotifications;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Row, Col, Drawer } from "antd";

import { useCurrentCompany } from "store";
import { getEllipseText } from "app/appUtils";
import OKRForm from "../common/form/OKRForm";

const ChangeToObjective = ({
  visible = false,
  keyResult = {},
  parentObjective = {},
  onSave = () => {},
  onCancel = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Helmet>
        <title>OKRs - culture.easy</title>
      </Helmet>
      <Drawer
        title={`Changing Key Result ${getEllipseText(
          keyResult?.title
        )} to an Objective`}
        visible={visible}
        footer={null}
        width="80%"
        centered={true}
        onClose={onCancel}
      >
        <Row>
          <Col span={1} />
          <Col span={22}>
            <OKRForm
              mode="convert-to-kr"
              initialValues={{
                title: keyResult?.title,
                owner_ids: [keyResult?.owner?.id],
                currentOwners: [keyResult?.owner],
                type_id: parentObjective?.type,
                objective_period_id: parentObjective?.objective_period_id,
                key_results: [
                  {
                    owner_id: currentCompany?.user?.id,
                    currentOwner: currentCompany?.user,
                    kpi_metric: "%",
                    target: 100,
                    start: 0,
                    is_boolean: false,
                    // is_deadline: false,
                  },
                ],
                parent_objective_id: parentObjective?.id,
                parentObjective: parentObjective,
                key_result_converted_id: keyResult?.id,
              }}
              currentUserId={currentCompany?.user?.id}
              onSave={onSave}
              onCancel={onCancel}
            />
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

ChangeToObjective.propTypes = {
  okrId: PropTypes.number,
};

export default ChangeToObjective;

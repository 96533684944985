import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Button, Grid } from "antd";

import { APIBasedEmployeeSearch, InlineSpinner } from "components";
import { getIsMobile } from "app/appUtils";
import SearchFilters from "./SearchFilters";
import SearchFilterTags from "./SearchFilterTags";

const { useBreakpoint } = Grid;

const SearchReviews = ({
  isSearching = false,
  searchedEmployees = [],
  filters = {},
  searchReviewsLength = 0,
  participantsLength = 0,
  setSearchedEmployees = () => {},
  setFilters = () => {},
  onClick = () => {},
}) => {
  const screens = useBreakpoint();
  const [availableEmployees, setAvailableEmployees] = useState([]);

  const handleSearchEmployees = (val = []) => {
    setSearchedEmployees(val);
  };

  const handleAvailableIndirectReports = (availableEmployees = []) => {
    const uniqueEmployeeIds = [
      ...new Set(availableEmployees.map((emp) => emp?.id)),
    ];
    const uniqueEmployees = uniqueEmployeeIds.map((empId) =>
      availableEmployees?.find((emp) => emp?.id === empId)
    );
    setAvailableEmployees(uniqueEmployees);
  };

  const handleClick = () => {
    onClick({ searchedEmployees, filters });
  };

  const isMobile = getIsMobile(screens);

  return (
    <>
      <Row align="middle">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <APIBasedEmployeeSearch
            mode="multiple"
            className={`${isMobile ? "width-200-px" : "width-250-px"} mr-8`}
            value={searchedEmployees}
            currentOwners={availableEmployees}
            placeholder="Employee or Manager name"
            showArrow={false}
            shouldShowInactiveEmployees={true}
            allowClear={true}
            shouldShowAvatar={true}
            onChange={handleSearchEmployees}
            setAvailableEmployees={handleAvailableIndirectReports}
          />
        </div>
        <SearchFilters filters={filters} setFilters={setFilters} />
        <Button
          style={{ height: "38px", paddingTop: "7px", paddingBottom: "7px" }}
          type="primary"
          ghost={true}
          onClick={handleClick}
        >
          Search
        </Button>
        {isSearching && <InlineSpinner className="ml-8" fontSize={24} />}
      </Row>
      <SearchFilterTags
        className="mt-8"
        filters={filters}
        searchReviewsLength={searchReviewsLength}
        participantsLength={participantsLength}
        setFilters={setFilters}
      />
    </>
  );
};

SearchReviews.propTypes = {
  onClick: PropTypes.func,
};

export default SearchReviews;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useQuery } from "react-query";
import { Drawer } from "antd";

import { EllipseText, LineChart, BoxLoader } from "components";
import TargetChange from "./TargetChange";
import ActivityCommentsSection from "./activity-comments/ActivityCommentsSection";
import { dateFormat } from "app/appConstants";
import { getProgressOverTimeApi } from "apis/kpis";

const getProgressData = (currentValues = {}) => {
  const progressData = Object.entries(currentValues)?.map(([key, value]) => ({
    x: key,
    y: value,
  }));

  progressData.sort((item1, item2) => {
    if (moment(item1?.x, dateFormat)?.isBefore(moment(item2?.x, dateFormat))) {
      return -1;
    } else if (
      moment(item2?.x, dateFormat)?.isBefore(moment(item1?.x, dateFormat))
    ) {
      return 1;
    }
    return 0;
  });

  return progressData;
};

const getTickAmount = (progressData = [], targetData = []) => {
  const allYValues = [];
  progressData.forEach((item) => {
    allYValues.push(item?.y);
  });
  targetData.forEach((item) => {
    allYValues.push(item?.y);
  });

  const minCheckinCount = Math.min(...allYValues);
  const maxCheckinCount = Math.max(...allYValues);

  if (maxCheckinCount - minCheckinCount < 5) {
    return maxCheckinCount - minCheckinCount;
  }
  return 5;
};

const getContinuousTargetValueHash = (targetValues) => {
  const finalHash = {};
  const allTargetValueKeys = Object.keys(targetValues);

  allTargetValueKeys.sort((date1, date2) => {
    if (moment(date1, "M-YYYY")?.isBefore(moment(date2, "M-YYYY"))) {
      return -1;
    } else if (moment(date2, "M-YYYY")?.isBefore(moment(date1, "M-YYYY"))) {
      return 1;
    }
    return 0;
  });

  let firstAvailableDate = moment(allTargetValueKeys[0], "M-YYYY");
  const lastAvailableDate = moment(
    allTargetValueKeys[allTargetValueKeys?.length - 1],
    "M-YYYY"
  );

  let prevVal = targetValues[firstAvailableDate?.format("M-YYYY")];
  while (firstAvailableDate?.isSameOrBefore(lastAvailableDate)) {
    if (targetValues[firstAvailableDate?.format("M-YYYY")]) {
      prevVal = targetValues[firstAvailableDate?.format("M-YYYY")];
      finalHash[firstAvailableDate?.format("M-YYYY")] = prevVal;
    } else {
      finalHash[firstAvailableDate?.format("M-YYYY")] = prevVal;
    }
    firstAvailableDate.add(1, "m");
  }

  return finalHash;
};

const getTargetData = (targetValues = {}, currentValues = {}) => {
  const requiredTargetValues = [];
  const continuousTargetValues = [];

  const continuousTargetValueHash = getContinuousTargetValueHash(targetValues);
  const targetValueKeys = Object.keys(continuousTargetValueHash);

  const availableDates = Object.keys(currentValues) || [];
  availableDates.sort((date1, date2) => {
    if (moment(date1, dateFormat)?.isBefore(moment(date2, dateFormat))) {
      return -1;
    } else if (moment(date2, dateFormat)?.isBefore(moment(date1, dateFormat))) {
      return 1;
    }
    return 0;
  });

  const firstAvailableDate = moment(availableDates[0], dateFormat);
  const lastAvailableDate = moment(
    availableDates[availableDates?.length - 1],
    dateFormat
  );

  while (firstAvailableDate?.isSameOrBefore(lastAvailableDate, "month")) {
    const formattedFirstDate = firstAvailableDate?.format("M-YYYY");

    if (targetValueKeys?.includes(formattedFirstDate)) {
      requiredTargetValues.push({
        x: formattedFirstDate,
        y: continuousTargetValueHash[formattedFirstDate],
      });
    }
    firstAvailableDate.add(1, "month");
  }

  requiredTargetValues.forEach((entry) => {
    const daysInMonth = moment(entry?.x, "M-YYYY")?.daysInMonth();
    let i = 1;
    while (i <= daysInMonth) {
      continuousTargetValues.push({
        x: moment(`${i}-${entry?.x}`, "DD-MM-YYYY")?.format(dateFormat),
        y: entry?.y?.target_value,
      });
      i += 1;
    }
  });

  return continuousTargetValues;
};

const ShowKPIContent = ({ visible = false, kpi = {}, onClose = () => {} }) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ["getProgressOverTime", kpi?.id],
    getProgressOverTimeApi
  );

  const progressData = getProgressData(data?.current_values);
  const targetData = getTargetData(data?.target_values, data?.current_values);

  return (
    <div>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <>
          <LineChart
            xTitle="KPI Progress over time"
            width={"100%"}
            strokeWidth={[4, 4]}
            strokeColors={["#5330C9", "#e3e3e3"]}
            series={[
              {
                name: "progress",
                data: progressData,
              },
              {
                name: "target",
                data: targetData,
              },
            ]}
            tickAmount={getTickAmount(progressData, targetData)}
          />

          <TargetChange
            isFetching={isFetching}
            kpiId={kpi?.id}
            metric={kpi?.kpi_metric}
            kpiOwnerId={kpi?.owner?.id}
            targetValues={data?.target_values || {}}
            refetch={refetch}
          />
          <div className="mt-40">
            <ActivityCommentsSection kpi={kpi} />
          </div>
        </>
      )}
    </div>
  );
};

const ShowKPI = ({ visible = false, kpi = {}, onClose = () => {} }) => {
  return (
    <Drawer
      width={"65%"}
      visible={visible}
      title={<EllipseText characterLimit={60} text={kpi?.title} />}
      onClose={onClose}
    >
      {visible ? (
        <ShowKPIContent visible={visible} kpi={kpi} onClose={onClose} />
      ) : null}
    </Drawer>
  );
};

ShowKPI.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ShowKPI;

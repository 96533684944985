import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import { useHistory } from "react-router-dom";
import {
  PageHeader,
  Button,
  Card,
  Row,
  Col,
  Input,
  Table,
  Dropdown,
  Menu,
  Modal,
  Space,
  Typography,
} from "antd";
import {
  SearchOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";

import { BoxLoader, EmployeeAvatarWithName, AddTeam } from "components";
import { useEmployees } from "hooks";
import DirectoryNavBar from "../common/DirectoryNavBar";
import { getAllTeamsApi, saveTeamApi } from "apis/teamApi";
import { mobileTableScroll } from "app/appConstants";
import { errorNotification, successNotification } from "app/appUtils";

const { confirm } = Modal;
const { Title } = Typography;

const prepareColumns = (isActive, handleTeamActive, handleEditTeam) => [
  {
    title: "Team",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Owner",
    dataIndex: "owner",
    key: "owner",
    render: (owner) => {
      return (
        <Space>
          <EmployeeAvatarWithName employee={owner} shouldShowInactive={true} />
        </Space>
      );
    },
  },
  {
    title: "",
    render: (data) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="1"
              onClick={() => handleTeamActive(data?.id, data?.active)}
            >
              {data?.active ? "Mark Team Inactive" : "Mark Team Active"}
            </Menu.Item>
            {data?.active && (
              <Menu.Item key="2" onClick={() => handleEditTeam(data)}>
                Edit Team
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        {
          <span className="pl-30">
            <MoreOutlined />
          </span>
        }
      </Dropdown>
    ),
  },
];

const Teams = () => {
  // const history = useHistory();
  const [isAddTeamVisible, setIsAddTeamVisible] = useState(false);
  const [editTeam, setEditTeam] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const { data, status, isLoading, isFetching, refetch } = useQuery(
    "getAllTeams",
    getAllTeamsApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [saveTeam] = useMutation(saveTeamApi, {
    onSuccess: () => {
      successNotification("Saved Team");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleSaveTeam = (team) => {
    saveTeam({ team });
    handleAddTeamVisible();
  };

  const handleEditTeam = (team) => {
    setEditTeam(team);
    handleAddTeamVisible();
  };

  const handleTeamActive = (id, isActive) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleFilled />,
      content: `This will mark the team ${isActive ? "inactive" : "active"}`,
      okButtonProps: { danger: true },
      onOk() {
        saveTeam({ team: { id, active: !isActive } });
      },
    });
  };

  const handleAddTeamVisible = () => {
    if (isAddTeamVisible) {
      setEditTeam(null);
    }
    setIsAddTeamVisible(!isAddTeamVisible);
  };

  const handleSearchTeam = (e) => {
    setSearchTerm(e?.target?.value);
  };

  const loader =
    status === "loading" || isLoading || isFetching || isEmployeesLoading;

  const activeTeams = data ? data?.filter((team) => team?.active) : [];
  const inActiveTeams = data ? data?.filter((team) => !team?.active) : [];

  let filteredTeams = data ? [...activeTeams] : [];
  if (searchTerm) {
    filteredTeams = activeTeams.filter(
      (team) =>
        team?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        team?.owner?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }

  let filteredInactiveTeams = data ? [...inActiveTeams] : [];
  if (searchTerm) {
    filteredInactiveTeams = inActiveTeams.filter(
      (team) =>
        team?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        team?.owner?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }

  return (
    <>
      <Helmet>
        <title>Settings - culture.easy</title>
      </Helmet>
      <PageHeader
        className="directory-page-header pl-0 pt-0 pr-0"
        title={<Title level={2}>People</Title>}
        // onBack={() => history.push("/settings")}
        extra={[
          <Button type="primary" onClick={handleAddTeamVisible}>
            Add Team
          </Button>,
        ]}
      />
      {isAddTeamVisible && (
        <AddTeam
          visible={isAddTeamVisible}
          team={editTeam}
          employees={activeEmployees}
          inActiveEmployees={inActiveEmployees}
          onOk={handleSaveTeam}
          onCancel={handleAddTeamVisible}
        />
      )}
      <DirectoryNavBar />
      {loader && <BoxLoader />}
      {!loader && (
        <Space direction="vertical" className="width-100-percent" size="large">
          {/* <CompanyNameInput /> */}
          <Card>
            <Row justify="end" className="mb-8" gutter={[{ xs: 8 }, { xs: 8 }]}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Input
                  placeholder="Search Team"
                  onChange={handleSearchTeam}
                  prefix={<SearchOutlined />}
                />
              </Col>
            </Row>
            <Table
              columns={prepareColumns(true, handleTeamActive, handleEditTeam)}
              dataSource={filteredTeams || []}
              scroll={mobileTableScroll}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
            />

            {inActiveTeams?.length > 0 && (
              <div className="mt-24">
                <Title level={3}>Inactive Teams</Title>
                <Table
                  columns={prepareColumns(
                    false,
                    handleTeamActive,
                    handleEditTeam
                  )}
                  dataSource={filteredInactiveTeams || []}
                  scroll={mobileTableScroll}
                  pagination={{
                    hideOnSinglePage: true,
                    size: "small",
                  }}
                />
              </div>
            )}
          </Card>
        </Space>
      )}
    </>
  );
};

Teams.propTypes = {};

export default Teams;

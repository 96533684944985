import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { Space, Typography, Card } from "antd";
import moment from "moment";

import { errorNotification, successNotification } from "app/appUtils";
import { createHprSurveyApi } from "apis/hprSurveyApi";
import { useEmployees } from "hooks";
import { BoxLoader } from "components";
import NotificationModal from "../common/NotificationModal";
import questions from "../common/form-components/questions/questionsData";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import HprSurveyBasicsForm from "../common/form-components/HprSurveyBasicsForm";

const { Text } = Typography;

const AddHprSurveys = ({ mode = "create" }) => {
  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const history = useHistory();

  const [hprSurvey, setHprSurvey] = useState(null);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  useEffect(() => {
    if (
      !hprSurvey &&
      !isEmployeesLoading &&
      activeEmployees &&
      activeEmployees?.length > 0
    ) {
      const initValues = {
        title: "",
        due_date: moment().add(7, "d"),
        for_entire_company: true,
        participants: [],
        employees: activeEmployees,
      };
      setHprSurvey({ ...initValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEmployees, isEmployeesLoading]);

  const [createHprSurvey, { status, isLoading: isSaving }] = useMutation(
    createHprSurveyApi,
    {
      onSuccess: (data) => {
        if (!data?.draft) {
          const msg = data.are_emails_sent ? (
            <div>
              <Space direction="vertical">
                <Text>HPR Survey Created</Text>
                <Text>Emails Sent</Text>
              </Space>
            </div>
          ) : (
            "HPR Survey created"
          );
          successNotification(msg);
        }
        history.push("/company/hpr-surveys");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleEmailDialogOpen = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const handleCreateHprSurvey = () => {
    const createdHprSurvey = {
      ...hprSurvey,
      is_draft: false,
      questions: questions,
      send_emails: true,
    };
    delete createdHprSurvey.employees;
    createHprSurvey(createdHprSurvey);
  };

  const handleSaveAsDraft = (values) => {
    const createdHprSurvey = {
      ...values,
      questions: questions,
      send_emails: false,
      is_draft: true,
    };
    delete createdHprSurvey.employees;
    createHprSurvey(createdHprSurvey);
  };

  const loader = isEmployeesLoading || !hprSurvey;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>

      {isEmailDialogOpen && (
        <NotificationModal
          from="form"
          isLoading={status === "loading"}
          hprSurvey={hprSurvey}
          visible={isEmailDialogOpen}
          handleCancel={handleEmailDialogOpen}
          handleSubmit={handleCreateHprSurvey}
        />
      )}
      <FullScreenLayout
        source={{ path: "/company/hpr-surveys", name: "HPR Surveys" }}
        title="Create a HPR Survey"
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            <HprSurveyBasicsForm
              mode={mode}
              initialValues={{ ...hprSurvey }}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={handleSaveAsDraft}
              handleDone={handleEmailDialogOpen}
              setHprSurvey={setHprSurvey}
              isSaving={isSaving}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

AddHprSurveys.propTypes = {
  mode: PropTypes.string,
};

export default AddHprSurveys;

//initialValues = {title: "Asdcasdc"}

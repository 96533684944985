import React from "react";
import { Layout, Typography, Row, Col } from "antd";

import BackLink from "../BackLink";
import styles from "./FullScreenLayout.module.css";
import AppLayoutV3 from "./AppLayoutV3";

const { Content } = Layout;
const { Title } = Typography;

function FullScreenLayout({
  source,
  title,
  children,
  headerLgMainSpan = 12,
  headerLgSideColSpan = 6,
  contentLgSpan = 14,
  contentGapSpan = 5,
  containerStyles = {},
  contentContainerStyles = {},
}) {
  return (
    <AppLayoutV3>
      <Layout className={styles.container} style={containerStyles}>
        {/* <Header className={styles.header}>
        <Row>
          <Col xs={{ span: 0 }} lg={{ span: headerLgSideColSpan }}>
            <Link to={source.path}>
              <Icon component={BackArrow} />
              <Text className="ml-16" type="secondary">
                Back to {source.name}
              </Text>
            </Link>
          </Col>
          <Col
            xs={{ span: 22 }}
            lg={{ span: headerLgMainSpan }}
            className="text-align-center"
          >
            <Space>
              <Title level={3}>{title}</Title>
            </Space>
          </Col>
          <Col
            xs={{ span: 2 }}
            lg={{ span: headerLgSideColSpan }}
            className="text-align-right"
          >
            <Link to={source.path}>
              <CloseOutlined className={styles.icon} />
            </Link>
          </Col>
        </Row>
      </Header> */}
        <Row>
          {/* <Col xs={{ span: 2 }} lg={{ span: contentGapSpan }}></Col> */}
          <Col span={24}>
            <BackLink to={source.path} name={`Back to ${source.name}`} />
            <Title level={3}>{title}</Title>
          </Col>
        </Row>
        <Content
          className={styles.contentContainer}
          style={contentContainerStyles}
        >
          <Row>
            {/* <Col xs={{ span: 2 }} lg={{ span: contentGapSpan }}></Col> */}
            <Col span={24}>{children}</Col>
            {/* <Col xs={{ span: 2 }} lg={{ span: contentGapSpan }}></Col> */}
          </Row>
        </Content>
      </Layout>
    </AppLayoutV3>
  );
}

export default FullScreenLayout;

import React from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

import { replaceMacrosWithName } from "app/appUtils";

const MacroHighlightedText = ({
  className = "",
  highlightClassName = "",
  firstName = "",
  lastName = "",
  text = "",
}) => {
  const questionDescriptionArray = text?.split("\n") || [];

  return (
    <>
      {questionDescriptionArray?.map((descSentence) => (
        <div>
          <Highlighter
            className={className}
            highlightClassName={
              highlightClassName
                ? highlightClassName
                : "macro-question-highlight"
            }
            searchWords={[firstName, lastName, `${firstName} ${lastName}`]}
            autoEscape={true}
            textToHighlight={replaceMacrosWithName(
              descSentence,
              firstName,
              lastName
            )}
          />
        </div>
      ))}
    </>
  );
};

MacroHighlightedText.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  text: PropTypes.string,
};

export default MacroHighlightedText;

import React from "react";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "antd";

import { EmployeeSearchSelect } from "components";
import { updateReviewReviewerApi } from "apis/perfReviewApi";
import { errorNotification, getNameFromEmployeeObj } from "app/appUtils";

import styles from "../Review.module.css";

const UpdateReviewerModal = ({
  visible = false,
  editReview = {},
  employees = [],
  inActiveEmployees = [],
  reviewCycleId = {},
  refetch = () => {},
  handleSearch = () => {},
  onCancel = () => {},
  onUpdate = () => {},
}) => {
  const [form] = Form.useForm();

  const [updateReviewReviewer, { status }] = useMutation(
    updateReviewReviewerApi,
    {
      onSuccess: () => {
        onCancel();
        refetch();
        handleSearch();
      },
      onError: (error) => {
        if (error?.response?.status === 304) {
          onCancel();
          refetch();
          handleSearch();
        } else {
          errorNotification(error);
        }
      },
    }
  );

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateReviewReviewer({
          reviewCycleId,
          reviewId: editReview?.id,
          reviewerId: values?.reviewer || null,
        });
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const filteredEmployees = employees?.filter(
    (emp) => emp?.id !== editReview?.revieweeObj?.id
  );

  const isLoading = status === "loading";

  return (
    <Modal
      visible={visible}
      title={
        "Edit " + getNameFromEmployeeObj(editReview?.reviewee) + "'s Reviewer"
      }
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      onOk={handleSubmit}
      footer={[
        <Button disabled={isLoading} type="primary" onClick={handleSubmit}>
          Update
        </Button>,
        <Button
          disabled={isLoading}
          type="primary"
          ghost
          onClick={handleCancel}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="editManager"
        initialValues={{
          reviewer: editReview?.reviewer?.id,
        }}
      >
        <Form.Item name="reviewer" label="Reviewer">
          <EmployeeSearchSelect
            disabled={isLoading}
            placeholder="Select Reviewer"
            className={styles.updateReviewerSelectWidth}
            shouldShowAvatar={true}
            employees={filteredEmployees}
            inActiveEmployees={inActiveEmployees}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
UpdateReviewerModal.propTypes = {
  visible: PropTypes.bool,
  reviewee: PropTypes.object,
  employee: PropTypes.object,
  employees: PropTypes.array,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default UpdateReviewerModal;

import React from "react";
import PropTypes from "prop-types";
import { List, Row, Col, Typography } from "antd";

import { EmployeeAvatarWithName, EmployeeAvatarGroup } from "components";

const { Text } = Typography;

const PerfReviewViewMCQSelectionsList = ({
  isRating = false,
  question = {},
  ratingOptionId = null,
  peersAnswers = [],
  revieweeAnswers = {},
  reviewerAnswers = {},
  reviewData = {},
}) => {
  return (
    <List
      bordered
      dataSource={
        isRating
          ? reviewData?.perf_review_cycle?.rating_options
          : question?.options
      }
      renderItem={(option) => {
        const answeredPeers = peersAnswers?.filter(
          (peer) => peer?.answers[question?.id] === option?.id
        );
        return (
          <List.Item>
            <Row className="width-100-percent">
              <Col span={4}>
                <Text>{option?.value}</Text>
              </Col>
              <Col span={20}>
                <Row align="middle">
                  {!isRating && revieweeAnswers[question.id] === option.id && (
                    <EmployeeAvatarWithName
                      className="mr-8"
                      employee={reviewData?.reviewee}
                    />
                  )}
                  {isRating &&
                    reviewData?.reviewee?.rating_option_id === option.id && (
                      <EmployeeAvatarWithName
                        className="mr-8"
                        employee={reviewData?.reviewee}
                      />
                    )}
                  {!isRating && reviewerAnswers[question.id] === option.id && (
                    <EmployeeAvatarWithName
                      emptyText="No Manager"
                      employee={reviewData?.reviewer}
                    />
                  )}
                  {isRating &&
                    reviewData?.reviewer?.rating_option_id === option.id && (
                      <EmployeeAvatarWithName
                        emptyText="No Manager"
                        employee={reviewData?.reviewer}
                      />
                    )}

                  {!isRating ? (
                    <EmployeeAvatarGroup
                      divClassName={`inline-display ${
                        revieweeAnswers[question.id] === option.id ||
                        reviewerAnswers[question.id] === option.id
                          ? "ml-8"
                          : ""
                      }`}
                      backgroundColor={
                        answeredPeers?.length > 0 && answeredPeers[0]?.name
                          ? ""
                          : "#bebebe"
                      }
                      employees={answeredPeers?.map((peer) => {
                        return peer?.name
                          ? { ...peer, name: `${peer?.name} (Peer)` }
                          : { name: "Anonymous" };
                      })}
                    />
                  ) : null}
                </Row>
              </Col>
            </Row>
          </List.Item>
        );
      }}
    />
  );
};

PerfReviewViewMCQSelectionsList.propTypes = {
  question: PropTypes.object,
  revieweeAnswers: PropTypes.object,
  reviewerAnswers: PropTypes.object,
  reviewData: PropTypes.object,
};

export default PerfReviewViewMCQSelectionsList;

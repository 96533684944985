import React from "react";
import PropTypes from "prop-types";
import { Row, Typography } from "antd";
import ReactSlider from "react-slider";

import styles from "../Review.module.css";
import PerfReviewBulkNudge from "./PerfReviewBulkNudge";

const { Text } = Typography;

const SummaryRow = ({
  isSmallScreen = false,
  title = "",
  value = 0,
  type = "reviewer_submission",
  canNudge = true,
  titleClassName = "",
  reviewCycleId = null,
}) => {
  return (
    <Row className="mb-16" align="middle">
      <Text className={`mr-16 ${titleClassName}`}>{title}</Text>
      <ReactSlider
        className={`${
          isSmallScreen ? styles.perfReviewSliderSmall : styles.perfReviewSlider
        }
             "cursor-not-allowed"
           `}
        value={value}
        trackClassName={styles.rcProgressTrack}
        disabled={true}
        renderThumb={(props) => {
          return <div {...props} className={styles.rcProgressDisabledThumb} />;
        }}
        renderTrack={(props, state) => {
          return (
            <div
              {...props}
              style={{
                ...props?.style,
                height: "20px",
                backgroundColor: state.index === 0 ? "#5330C955" : "#cbd0d6",
                borderRadius: "5px",
              }}
              index={state.index}
            />
          );
        }}
      />
      <Text className="ml-8 mr-8">{Math.round(value)}%</Text>
      <PerfReviewBulkNudge
        type={type}
        reviewCycleId={reviewCycleId}
        canNudge={canNudge}
      />
    </Row>
  );
};

SummaryRow.propTypes = {
  value: PropTypes.number,
  reviewCycleId: PropTypes.number,
};

export default SummaryRow;

import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const xAxisFormatter = (val, labelOptions) => {
  const label = labelOptions[val] || "";
  const labelWords = label?.split(" ");

  return labelWords?.length > 1 ? labelWords : labelWords[0];
};

const BarChart = ({ title, seriesName, seriesData, labelOptions = {} }) => {
  const series = [
    {
      name: seriesName,
      data: seriesData,
    },
  ];

  const maxValue = seriesData.reduce((a, b) => {
    return Math.max(a, b);
  });
  const minValue = seriesData.reduce((a, b) => {
    return Math.min(a, b);
  });

  const options = {
    chart: {
      height: 50,
      type: "bar",
      toolbar: { show: false },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "center", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      style: {
        fontSize: "12px",
      },
    },

    xaxis: {
      categories: Object.keys(labelOptions),
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        // show: true,
        rotate: 0,
        // rotateAlways: true,
        formatter: (val) => xAxisFormatter(val, labelOptions),
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      // tooltip: {
      //   enabled: true,
      // },
    },
    yaxis: {
      tickAmount: maxValue - minValue,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: (val) => {
          return Math.round(val * 100) / 100;
        },
      },
    },
    colors: ["#a48be8"],
  };

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height={275}
      // width={"100%"}
    />
  );
};

BarChart.propTypes = {
  title: PropTypes.string,
  seriesName: PropTypes.string,
  seriesData: PropTypes.array,
};

export default BarChart;

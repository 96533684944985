import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Tabs, PageHeader, Row, Typography } from "antd";

import { BackLink } from "components";
import ReviewsEditV2 from "../edit-v2/ReviewsEditV2";
import ReviewDetailsOverview from "./ReviewDetailsOverview";
import { errorNotificationWithString, formattedDate } from "app/appUtils";

const { TabPane } = Tabs;
const { Text } = Typography;

const ReviewDetails = ({ selectedTab = "overview" }) => {
  const history = useHistory();

  const [urlSwitchIds, setUrlSwitchIds] = useState({});
  const [selectedReviewCycle, setSelectedReviewCycle] = useState({});

  const handleTabChange = (tab) => {
    const navigateTo = urlSwitchIds?.navigateTo || "";

    if (
      (navigateTo === "overview" && tab === "overview") ||
      (tab === "edit" && navigateTo !== "overview")
    ) {
      if (navigateTo === "overview") {
        history.push(`/reviews/overview/${urlSwitchIds?.reviewCycleId}/`);
      } else if (navigateTo === "peer-write" && urlSwitchIds?.peerId) {
        history.push(
          `/reviews/peer/${urlSwitchIds?.peerId}/review/${
            urlSwitchIds?.reviewId ? urlSwitchIds?.reviewId || "" : ""
          }`
        );
      } else if (navigateTo === "write" && urlSwitchIds?.reviewId) {
        history.push(`/reviews/review/${urlSwitchIds?.reviewId}/`);
      } else {
        errorNotificationWithString("We were not able to switch tab for you");
        //TODO: Sentry error capture
      }
    } else {
      //No need to navigate on same screen
    }
  };

  return (
    <div>
      <Helmet>My Review</Helmet>
      <BackLink to="/reviews" name="Back to Perf Review cycles" />

      <PageHeader
        className="pl-0 pt-0"
        title={
          <>
            {selectedReviewCycle?.name ? (
              <Row align="middle">
                <Text className="mb-0 inline-display font-weight-300 font-size-24">
                  {`${selectedReviewCycle?.name}`}
                </Text>
                <Text
                  type="secondary"
                  className="ml-8 font-size-14"
                >{`(${formattedDate(
                  selectedReviewCycle?.period_from
                )} to ${formattedDate(selectedReviewCycle?.period_to)})`}</Text>
              </Row>
            ) : (
              ""
            )}
          </>
        }
        // onBack={() => history.push("/reviews")}
      />
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        destroyInactiveTabPane={true}
      >
        <TabPane tab="Overview" key="overview">
          <ReviewDetailsOverview
            enabled={selectedTab === "overview"}
            setUrlSwitchIds={setUrlSwitchIds}
            setSelectedReviewCycle={setSelectedReviewCycle}
          />
        </TabPane>
        <TabPane tab="Write" key="edit">
          <ReviewsEditV2
            enabled={selectedTab === "edit"}
            setUrlSwitchIds={setUrlSwitchIds}
            setSelectedReviewCycle={setSelectedReviewCycle}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

ReviewDetails.propTypes = {};

export default ReviewDetails;

import React, { useState, useEffect } from "react";
import { Alert, Steps, Form, DatePicker, Typography } from "antd";
import moment from "moment";

import CycleBasicsForm from "./CycleBasicsForm";
import CycleQuestionnaireForm from "./questions/CycleQuestionnaireForm";
import StepFormActionButtons from "./StepFormActionButtons";
import { errorNotificationWithString } from "app/appUtils";

const { RangePicker } = DatePicker;
const { Step } = Steps;
const { Text } = Typography;

const TimeLine = ({
  mode = "create",
  isSaving = false,
  initialValues = {},
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) => {
  const [timelineForm] = Form.useForm();
  return (
    <Form
      className="mt-24"
      name="timeline"
      form={timelineForm}
      layout="vertical"
      initialValues={initialValues}
    >
      {initialValues?.has_peer_reviews && (
        <Form.Item
          name="deadline_for_adding_peer"
          label="Due Date to Adding Peers"
          rules={[
            {
              required: true,
              message: "Missing submit deadline",
            },
            {
              validator: (rule, value, callback) => {
                try {
                  if (
                    moment(value, "YYYY-MM-DD").isBefore(
                      moment().startOf("day")
                    )
                  ) {
                    callback("Date cant be in the past");
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              },
            },
          ]}
          required={false}
          initialValue={initialValues.deadline_for_submit}
        >
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={(current) =>
              current && current < moment().endOf("day")
            }
          />
        </Form.Item>
      )}
      <Form.Item
        name="deadline_for_submit"
        label="Due Date for submission of review"
        rules={[
          {
            required: true,
            message: "Missing submit deadline",
          },
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isBefore(moment().startOf("day"))
                ) {
                  callback("Date cant be in the past");
                }
                if (
                  initialValues?.has_peer_reviews &&
                  moment(value, "YYYY-MM-DD").isSameOrBefore(
                    getFieldValue("deadline_for_adding_peer")
                  )
                ) {
                  callback(
                    "Due date to Sign cant be before Due date to adding peers"
                  );
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          }),
        ]}
        required={false}
        initialValue={initialValues.deadline_for_submit}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>
      <Form.Item
        name="appeal_window"
        label={
          <Text>
            Appeal Window <Text type="secondary">(Optional)</Text>
          </Text>
        }
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              if (value) {
                const [startDate, endDate] = value;
                try {
                  if (
                    startDate &&
                    moment(startDate, "YYYY-MM-DD").isSameOrBefore(
                      getFieldValue("deadline_for_submit")
                    )
                  ) {
                    callback(
                      "Appeal window cant start before Due date to submit"
                    );
                  }
                  if (
                    endDate &&
                    moment(endDate, "YYYY-MM-DD").isSameOrAfter(
                      getFieldValue("deadline_for_sign")
                    )
                  ) {
                    callback("Appeal window cant end after sign date");
                  }
                  callback();
                } catch (err) {
                  callback(err);
                }
              } else {
                callback();
              }
            },
          }),
        ]}
        initialValue={initialValues.appeal_window}
        required={false}
      >
        <RangePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>
      <Form.Item
        name="deadline_for_sign"
        label="Due Date for signing the review"
        rules={[
          {
            required: true,
            message: "Missing sign deadline",
          },
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isBefore(moment().startOf("day"))
                ) {
                  callback("Date cant be in the past");
                }
                if (
                  moment(value, "YYYY-MM-DD").isSameOrBefore(
                    getFieldValue("deadline_for_submit")
                  )
                ) {
                  callback(
                    "Due date to Sign cant be before Due date to submit"
                  );
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          }),
        ]}
        initialValue={initialValues.deadline_for_sign}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>
      <Form.Item
        name="auto_sign_date"
        label={
          <Text>
            Date on which reviews will be auto signed{" "}
            <Text type="secondary">(Optional)</Text>
          </Text>
        }
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value, callback) {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isSameOrBefore(
                    getFieldValue("deadline_for_sign")
                  )
                ) {
                  callback("Auto sign date cant be before due date to sign");
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          }),
        ]}
        initialValue={initialValues.auto_sign_date}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>
      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          isSaving={isSaving}
          currentStep={2}
          shouldShowSaveAsDraft={initialValues?.draft}
          currentForm={timelineForm}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({
              ...timelineForm.getFieldsValue(true),
              draft_step: 2,
            });
          }}
          handlePreviousClick={handlePreviousClick}
        />
      </Form.Item>
    </Form>
  );
};

const CreateReviewCycleForm = ({
  mode = "create",
  cycleData = {},
  isSaving = false,
  draftStep = null,
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  setCycleData = () => {},
  handleDone = () => {},
}) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (draftStep) {
      setCurrent(draftStep);
    }
  }, [draftStep]);

  const handlePreviousClick = (form) => {
    const values = form.getFieldsValue(true);
    setCycleData({ ...cycleData, ...values });
    setCurrent(current - 1);
  };

  const handleSubFormSubmit = (formName, { values, forms }) => {
    if (formName === "cycleBasics") {
      const managerMappings = values?.managerMappings || {};
      const reviews = values?.reviews || [];
      const reviewsWithUpdatedManagers = reviews?.map((review) => ({
        ...review,
        reviewer_id:
          managerMappings[review?.reviewee_id] || review?.reviewer_id || null,
      }));
      if (
        mode !== "edit" &&
        !values?.for_entire_company &&
        reviewsWithUpdatedManagers?.length === 0
      ) {
        errorNotificationWithString("Please select participants");
      } else {
        setCycleData({
          ...cycleData,
          ...values,
          participants: reviewsWithUpdatedManagers,
          has_peer_reviews: Boolean(values?.has_peer_reviews),
        });
        setCurrent(1);
      }
    } else if (formName === "questionnaire") {
      //TODO: Find out why values is empty: Answer: Form.Item with shouldUpdate is problematic
      const questions = forms?.questionnaire?.getFieldValue("questions") || [];

      const rating_enabled =
        forms?.questionnaire?.getFieldValue("rating_enabled") || false;
      const rating_options =
        forms?.questionnaire?.getFieldValue("rating_options") || [];

      if (questions.length > 0) {
        setCycleData({
          ...cycleData,
          questions,
          rating_options,
          rating_enabled,
        });
        setCurrent(2);
      } else {
        errorNotificationWithString("Questions cant be empty.");
      }
    } else if (formName === "timeline") {
      const deadline_for_submit =
        values.deadline_for_submit.format("YYYY-MM-DD");
      const deadline_for_adding_peer =
        values.deadline_for_adding_peer?.format("YYYY-MM-DD");
      const deadline_for_sign = values.deadline_for_sign.format("YYYY-MM-DD");
      const auto_sign_date = values.auto_sign_date?.format("YYYY-MM-DD");
      const appeal_window = values.appeal_window;

      if (mode === "create") {
        const finalData = {
          ...cycleData,
          ...values,
        };
        delete finalData.employees;
        setCycleData(finalData);
        handleDone();
      } else {
        handleDone({
          ...cycleData,
          deadline_for_adding_peer,
          deadline_for_submit,
          deadline_for_sign,
          auto_sign_date,
          appeal_window,
        });
      }
    }
  };

  return (
    <>
      <Steps className="ml-40" current={current}>
        <Step key={1} title="Cycle Basics" />
        <Step key={2} title="Compose Questionnaire" />
        <Step className="mr-40" key={3} title="Set Timeline" />
      </Steps>
      <Form.Provider onFormFinish={handleSubFormSubmit}>
        {mode === "edit" && (
          <Alert
            className="my-8"
            type="error"
            message={"Warning! You are editing a Review cycle."}
          />
        )}
        {cycleData && current === 0 && (
          <CycleBasicsForm
            mode={mode}
            initialValues={{ ...cycleData }}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
        {current === 1 && (
          <CycleQuestionnaireForm
            mode={mode}
            initialValues={cycleData}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
        {current === 2 && (
          <TimeLine
            mode={mode}
            isSaving={isSaving}
            initialValues={cycleData}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
      </Form.Provider>
    </>
  );
};

CreateReviewCycleForm.propTypes = {};

export default CreateReviewCycleForm;

const getPerfReviewCycles = () => {
  return [
    {
      id: "2345",
      name: "Q1 Review Cycle",
      description: "Description about the review cycle",
      period_from: "01/01/2020",
      period_to: "02/28/2020",
      is_active: true,
      are_emails_sent: false,
      deadline_to_submit: "10/04/2020",
      deadline_to_sign: "12/04/2020",
      summary: {
        total_reviews: 20,
        no_of_reviews_signed_by_reviewee: 3,
        no_of_reviews_signed_by_reviewer: 2,
      },
      reviews: [
        {
          id: "21415",
          reviewee: {
            name: "Revanth",
            status: "NOT_STARTED",
          },
          reviewer: {
            name: "AK",
            status: "IN_PROGRESS",
          },
        },
        {
          id: "21415",
          reviewee: {
            name: "AK",
            status: "SUBMITTED",
          },
          reviewer: {
            name: "Ravi",
            status: "NOT_STARTED",
          },
        },
        {
          id: "21415",
          reviewee: {
            name: "GP",
            status: "IN_PROGRESS",
          },
          reviewer: {
            name: "Ravi",
            status: "SUBMITTED",
          },
        },
      ],
      questions: [
        {
          id: "12354",
          type: "FREE_TEXT",
          reviewee: {
            id: "12",
            description: "How did you perform?",
          },
          reviewer: {
            id: "12",
            description: "How did the team member do?",
          },
        },
        {
          id: "1325426",
          type: "MCQ",
          reviewee: {
            id: "12",
            description: "How did you perform?",
          },
          reviewer: {
            id: "12",
            description: "How did the team member do?",
          },
          options: ["", ""],
        },
      ],
    },

    {
      id: "2345",
      name: "Q1 Review Cycle",
      description: "Description about the review cycle",
      period_from: "01/01/2020",
      period_to: "02/31/2020",
      are_emails_sent: false,
      is_active: false,
      deadline_to_submit: "10/04/2020",
      deadline_to_sign: "14/04/2020",
      summary: {
        total_reviews: 20,
        no_of_reviews_signed_by_reviewee: 3,
        no_of_reviews_signed_by_reviewer: 2,
      },
      reviews: [
        {
          id: "21415",
          reviewee: {
            name: "Revanth",
            status: "NOT_STARTED",
          },
          reviewer: {
            name: "AK",
            status: "IN_PROGRESS",
          },
        },
        {
          id: "21415",
          reviewee: {
            name: "AK",
            status: "SUBMITTED",
          },
          reviewer: {
            name: "Ravi",
            status: "NOT_STARTED",
          },
        },
        {
          id: "21415",
          reviewee: {
            name: "GP",
            status: "IN_PROGRESS",
          },
          reviewer: {
            name: "Ravi",
            status: "SUBMITTED",
          },
        },
      ],
      questions: [
        {
          id: "12354",
          type: "FREE_TEXT",
          reviewee: {
            id: "12",
            description: "How did you perform?",
          },
          reviewer: {
            id: "12",
            description: "How did the team member do?",
          },
        },
        {
          id: "1325426",
          type: "MCQ",
          reviewee: {
            id: "12",
            description: "How did you perform?",
          },
          reviewer: {
            id: "12",
            description: "How did the team member do?",
          },
          options: ["", ""],
        },
      ],
    },
  ];
};

const postPerfReviewCycle = () => {
  return {
    name: "Q1 Review Cycle",
    description: "Description about the review cycle",
    period_from: "01/01/2020",
    period_to: "02/31/2020",
    should_trigger_emails: true,
    deadline_to_submit: "10/04/2020",
    deadline_to_sign: "14/04/2020",
    reviews: [
      {
        reviewee: {
          name: "1234",
          status: "NOT_STARTED",
        },
        reviewer: {
          name: "123455",
          status: "NOT_STARTED",
        },
      },
    ],
    questions: [
      {
        type: "FREE_TEXT",
        reviewee_text: "How did you perform?",
        reviewer_text: "How did the team member do?",
      },
      {
        type: "MCQ",
        options: ["asd", "dasd"],
        reviewee_text: "How did you perform?",
        reviewer_text: "How did the team member do?",
      },
    ],
  };
};

const getMyReviews = () => {
  return [
    {
      id: 1,
      name: "Review Cycle Q2/2020",
      description:
        "Please write your Assessment with a focus on this review period. Highlight your achievements and accomplishments.",
      period_from: "2020-07-01",
      period_to: "2020-12-30",
      deadline_for_submit: "2020-09-21",
      deadline_for_sign: "2020-09-27",
      self_review: {
        id: 13,
        reviewee: { name: "Pranav PB", status: "In Progress" },
        reviewer: null,
      },
      team_reviews: [],
    },
  ];
};

const getReviewDetails = () => {
  return {
    id: 223,
    reviewee: {
      name: "Michael Scott",
      id: 205,
      user_first_name: "Michael Scott",
      user_last_name: "",
      user_profile_pic_url:
        "https://upload.wikimedia.org/wikipedia/en/d/dc/MichaelScott.png",
      answers: null,
      check_submit: false,
      check_sign: false,
      status: "Not Started",
    },
    reviewer: {
      name: "Toby Flenderson",
      id: 206,
      user_first_name: "Toby Flenderson",
      user_last_name: "",
      user_profile_pic_url:
        "https://upload.wikimedia.org/wikipedia/en/1/18/Toby_Flenderson_promo_picture.jpg",
      answers: null,
      check_submit: false,
      check_sign: false,
      status: "Not Started",
    },
    my_role: "reviewee",
    show_unlock_button: false,
    questions: [
      {
        id: 251,
        type: "PerfReviewText",
        order: 1,
        is_required: true,
        allow_comments: false,
        reviewee_text: "What did I do well?",
        reviewer_text: "What did the team member do well?",
      },
      {
        id: 252,
        type: "PerfReviewText",
        order: 2,
        is_required: true,
        allow_comments: false,
        reviewee_text: "What did I improve on?",
        reviewer_text: "What did the team member improve on?",
      },
      {
        id: 253,
        type: "PerfReviewMcq",
        order: 3,
        is_required: true,
        allow_comments: false,
        reviewee_text: "I consistently treated people with respect.",
        reviewer_text: "Team member consistently treated people with respect.",
        options: [
          { id: 1, value: "Strongly Agree" },
          { id: 2, value: "Agree" },
          { id: 3, value: "Disagree" },
        ],
      },
    ],
    perf_review_cycle: {
      name: "Employee Review Cycle",
      description:
        "Thank you for participating in the performance review process! Please write your Assessment with a focus on this review period. Highlight your achievements and accomplishments. In what area(s) do you feel you could benefit from additional support or direction?",
      period_from: "2020-11-01",
      period_to: "2020-12-31",
      deadline_for_submit: "2020-12-29",
      deadline_for_sign: "2020-12-31",
    },
  };
};

const updateReview = () => {
  return {
    id: 322,
    reviewee: { name: "Pranav PB", id: 242, answers: null },
    reviewer: { name: "Abhilash Reddy Kotte", id: 230, answers: null },
    status: {
      reviewee: {
        check_submit: false,
        check_sign: false,
        submit_message: "Complete your self assessment by 2020-09-30 EOD.",
        sign_message: null,
      },
      reviewer: {
        check_submit: false,
        check_sign: false,
        submit_message: null,
        sign_message: null,
      },
    },
    my_role: "reviewee",
    show_unlock_button: false,
    questions: [
      {
        id: 905,
        reviewee_text: "What did i do well?",
        reviewer_text: "What did the team member do well?",
        order: 1,
        options: null,
        type: "PerfReviewText",
      },
      {
        id: 906,
        reviewee_text: "What can i improve on?",
        reviewer_text: "What can the team member improve on?",
        order: 2,
        options: null,
        type: "PerfReviewText",
      },
      {
        id: 907,
        reviewee_text: "I consistently treat people with respect.",
        reviewer_text: "Team member consistently treats people with respect.",
        order: 3,
        options: [
          { id: 1, value: "Strongly Agree", description: "" },
          { id: 2, value: "Agree", description: "" },
          { id: 3, value: "Disagree", description: "" },
        ],
        type: "PerfReviewMcq",
      },
    ],
    perf_review_cycle: {
      name: "Review Cycle Q2/2020",
      description:
        "Please write your Assessment with a focus on this review period. Highlight your achievements and accomplishments.",
      period_from: "2020-07-01",
      period_to: "2020-09-30",
      deadline_for_submit: "2020-09-30",
      deadline_for_sign: "2020-10-06",
    },
  };
};

export {
  getPerfReviewCycles,
  postPerfReviewCycle,
  getMyReviews,
  getReviewDetails,
  updateReview,
};

import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import { Input, Modal, Form } from "antd";

import { APIBasedEmployeeSearch } from "components";

const AddTeam = ({
  visible = false,
  team = null,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={team ? "Save Team" : "Add Team"}
      visible={visible}
      destroyOnClose
      width="1000px"
      keyboard={false}
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        className="editManagerForm"
        name="editManager"
        initialValues={{
          id: team?.id || null,
          name: team?.name || null,
          owner_id: team?.owner?.id || null,
        }}
      >
        <Form.Item name="id" hidden />
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input name of the team",
            },
            {
              whitespace: true,
              message: "Team's name can't be empty",
            },
          ]}
          required={false}
        >
          <Input className="width-250-px" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          name="owner_id"
          label="Owner"
          rules={[{ required: true, message: "Missing Owner." }]}
          required={false}
        >
          <APIBasedEmployeeSearch shouldShowAvatar={true} currentOwners={{}} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
AddTeam.propTypes = {};

export default AddTeam;

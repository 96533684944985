import AppLayoutV3 from "components/layouts/AppLayoutV3";
import Praise from "./Praise";

const routes = [
  {
    exact: true,
    path: "/praise",
    layout: AppLayoutV3,
    component: Praise,
  },
];

export default routes;

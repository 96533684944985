import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Chart from "react-apexcharts";

const LineChart = ({
  width = null,
  series,
  xTitle,
  strokeWidth = [2],
  strokeColors = ["#5330C9"],
  tickAmount = 5,
}) => {
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: strokeWidth,
      curve: "straight",
      dashArray: [0],
      colors: strokeColors,
    },
    colors: ["#5330C9"],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        formatter: (val) => {
          return moment(val)?.format("MMM Do");
        },
      },
      tooltip: {
        enabled: false,
      },
      title: {
        text: xTitle,
        offsetY: 16,
        style: {
          color: "#a3a3a3",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass:
            "apexcharts-xaxis-title ant-typography ant-typography-secondary",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return Number(val);
        },
      },
      tickAmount,
    },
    tooltip: {
      shared: true,

      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <div className="line-chart" id="chart" style={{ maxHeight: "350px" }}>
      <Chart
        options={options}
        series={series}
        type="line"
        height={250}
        width={width || 575}
      />
    </div>
  );
};
LineChart.propTypes = {
  series: PropTypes.array,
  xAxisCategories: PropTypes.array,
};

export default LineChart;

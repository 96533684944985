import React from "react";
// import PropTypes from "prop-types";
import { Radio } from "antd";

const SelectEmployeesRadioGroup = ({
  value,
  onChange,
  handleResetSearch = () => {},
}) => {
  const handleChange = (val) => {
    handleResetSearch();
    onChange(val);
  };

  return (
    <Radio.Group
      className="company-form-radio-group"
      value={value}
      onChange={handleChange}
    >
      <Radio value={true}>Entire Company</Radio>
      <Radio value={false}>Selected People</Radio>
    </Radio.Group>
  );
};
SelectEmployeesRadioGroup.propTypes = {};

export default SelectEmployeesRadioGroup;

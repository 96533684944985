import React, { useState, useEffect } from "react";
import { Alert, Steps, Form } from "antd";

import CycleBasicsForm from "./CycleBasicsForm";
import CycleQuestionnaireForm from "./questions/CycleQuestionnaireForm";
import CycleTimeLineForm from "./CycleTimeLineForm";

import { errorNotificationWithString } from "app/appUtils";
import { threeSixtyDegreeFeedback } from "app/appConstants";

const { Step } = Steps;

const ThreeSixtyFeedbackCycleForm = ({
  mode = "create",
  cycleData = {},
  isSaving = false,
  draftStep = null,
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  setCycleData = () => {},
  handleDone = () => {},
}) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (draftStep) {
      setCurrent(draftStep);
    }
  }, [draftStep]);

  const handlePreviousClick = (form) => {
    const values = form.getFieldsValue(true);
    setCycleData({ ...cycleData, ...values });
    setCurrent(current - 1);
  };

  const handleSubFormSubmit = (formName, { values, forms }) => {
    if (formName === "cycleBasics") {
      if (
        mode !== "edit" &&
        !values?.for_entire_company &&
        values?.participants?.length === 0
      ) {
        errorNotificationWithString("Please select participants");
      } else {
        const employee_can_nominate = forms?.cycleBasics?.getFieldValue(
          "employee_can_nominate"
        );
        setCycleData({
          ...cycleData,
          ...values,
          employee_can_nominate,
          from_date: values?.period[0]?.format("YYYY-MM-DD"),
          to_date: values?.period[1]?.format("YYYY-MM-DD"),
        });
        setCurrent(1);
      }
    } else if (formName === "questionnaire") {
      //TODO: Find out why values is empty: Answer: Form.Item with shouldUpdate is problematic
      const questions = forms?.questionnaire?.getFieldValue("questions") || [];
      if (questions.length > 0) {
        setCycleData({ ...cycleData, questions });
        setCurrent(2);
      } else {
        errorNotificationWithString("Questions cant be empty.");
      }
    } else if (formName === "timeline") {
      let nominate_due_date = forms?.timeline?.getFieldValue(
        "nominate_due_date"
      );
      if (nominate_due_date) {
        nominate_due_date = nominate_due_date.format("YYYY-MM-DD");
      }
      const finalize_due_date = values.finalize_due_date.format("YYYY-MM-DD");
      const submit_due_date = values.submit_due_date.format("YYYY-MM-DD");

      const finalCycleData = {
        ...cycleData,
        finalize_due_date,
        submit_due_date,
      };
      if (cycleData?.employee_can_nominate) {
        finalCycleData["nominate_due_date"] = nominate_due_date;
      } else {
        delete finalCycleData["nominate_due_date"];
      }

      if (mode === "create") {
        setCycleData({ ...finalCycleData });
        handleDone();
      } else {
        handleDone(finalCycleData);
      }
    }
  };

  return (
    <>
      <Steps className="ml-40" current={current}>
        <Step key={1} title="Cycle Basics" />
        <Step key={2} title="Questionnaire" />
        <Step className="mr-40" key={3} title="Time Line" />
      </Steps>
      <Form.Provider onFormFinish={handleSubFormSubmit}>
        {mode === "edit" && (
          <Alert
            className="my-8"
            type="error"
            message={`Warning! You are editing a ${threeSixtyDegreeFeedback} cycle.`}
          />
        )}
        {cycleData && current === 0 && (
          <CycleBasicsForm
            mode={mode}
            initialValues={{ ...cycleData }}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
        {current === 1 && (
          <CycleQuestionnaireForm
            mode={mode}
            initialValues={cycleData}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
        {current === 2 && (
          <CycleTimeLineForm
            mode={mode}
            isSaving={isSaving}
            initialValues={cycleData}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
      </Form.Provider>
    </>
  );
};

ThreeSixtyFeedbackCycleForm.propTypes = {};

export default ThreeSixtyFeedbackCycleForm;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Modal, Card } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import FullScreenLayout from "components/layouts/FullScreenLayout";
import { BoxLoader } from "components";
import { ratingOptions } from "../add/common/constants";
import { getReviewCycleApi, savePerfCycleApi } from "apis/perfReviewApi";
import { errorNotification, successNotification } from "app/appUtils";
import ReviewCycleForm from "../common/form-components/ReviewCycleForm";

const { confirm } = Modal;

const EditReviewCycle = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [cycleData, setCycleData] = useState(null);

  const { isLoading } = useQuery(
    ["getReviewCycleForEdit", id],
    getReviewCycleApi,
    {
      cacheTime: 1,
      onSuccess: (data) => {
        const initValues = {
          ...data,
          period: [
            moment(data?.period_from, "YYYY-MM-DD"),
            moment(data?.period_to, "YYYY-MM-DD"),
          ],
          appeal_window: [
            data?.appeal_window_start_date
              ? moment(data?.appeal_window_start_date, "YYYY-MM-DD")
              : null,
            data?.appeal_window_end_date
              ? moment(data?.appeal_window_end_date, "YYYY-MM-DD")
              : null,
          ],
          deadline_for_adding_peer: data?.deadline_for_adding_peer
            ? moment(data?.deadline_for_adding_peer, "YYYY-MM-DD")
            : null,
          deadline_for_submit: moment(data?.deadline_for_submit, "YYYY-MM-DD"),
          deadline_for_sign: moment(data?.deadline_for_sign, "YYYY-MM-DD"),
          auto_sign_date: data?.auto_sign_date
            ? moment(data?.auto_sign_date, "YYYY-MM-DD")
            : null,
          rating_enabled: Boolean(data?.rating_enabled),
          rating_options: data?.rating_options || ratingOptions,
        };
        delete initValues.reviews;
        delete initValues.summary;
        setCycleData(initValues);
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [savePerfCycle, { isLoading: isSaving }] = useMutation(
    savePerfCycleApi,
    {
      onSuccess: () => {
        successNotification("Perf Review Cycle updated successfully");
        history.push("/company/reviews");
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleSubmit = (values) => {
    const period = values?.period || [];
    let startDate = "";
    let endDate = "";
    if (period[0] && period[1]) {
      startDate = period[0].format("YYYY-MM-DD");
      endDate = period[1].format("YYYY-MM-DD");
    }
    const appealWindow = values?.appeal_window || [];
    let appealWindowStart = "";
    let appealWindowEnd = "";
    if (appealWindow[0] && appealWindow[1]) {
      appealWindowStart = appealWindow[0].format("YYYY-MM-DD");
      appealWindowEnd = appealWindow[1].format("YYYY-MM-DD");
    }
    const perf_review_cycle = {
      ...values,
      period_from: startDate,
      period_to: endDate,
      deadline_for_submit: values?.deadline_for_submit,
      appeal_window_start_date: appealWindowStart,
      appeal_window_end_date: appealWindowEnd,
      deadline_for_sign: values?.deadline_for_sign,
      auto_sign_date: values?.auto_sign_date || null,
      deadline_for_adding_peer: values?.has_peer_reviews
        ? values?.deadline_for_adding_peer
        : null,
      questions: values.questions.map((question, index) => ({
        ...question,
        order: index + 1,
      })),
    };
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will updated the performance review cycle (${
        values?.name || ""
      }).`,
      onOk() {
        savePerfCycle({
          id,
          perf_review_cycle,
        });
      },
      okText: "Update",
      onCancel() {},
    });
  };

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      <FullScreenLayout
        source={{ path: "/company/reviews", name: "Reviews" }}
        title="Edit Review Cycle."
      >
        {isLoading ? (
          <BoxLoader />
        ) : (
          <Card>
            <ReviewCycleForm
              mode="edit"
              cycleData={cycleData}
              isSaving={isSaving}
              employees={[]}
              inActiveEmployees={[]}
              handleSaveAsDraft={() => {}}
              setCycleData={setCycleData}
              handleDone={handleSubmit}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

EditReviewCycle.propTypes = {};

export default EditReviewCycle;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { Alert, Card } from "antd";
import PropTypes from "prop-types";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import questions from "../common/form-components/questions/questionsData";
import { getHprSurveyDetailsApi, createHprSurveyApi } from "apis/hprSurveyApi";
import { errorNotification, successNotification } from "app/appUtils";
import HprSurveyBasicsForm from "../common/form-components/HprSurveyBasicsForm";
import NotificationModal from "../common/NotificationModal";

const CreateFromDraftHprSurvey = ({ mode = "create_from_draft" }) => {
  const { id } = useParams();
  const history = useHistory();
  const [hprSurveyDetails, setHprSurveyDetails] = useState(null);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const { status, isLoading, isFetching } = useQuery(
    ["getHprSurveyDetails", id],
    getHprSurveyDetailsApi,
    {
      onSuccess: (data) => {
        const details = {
          ...data,
          due_date: moment(data?.due_date, "YYYY-MM-DD"),
          for_entire_company: data?.for_entire_company ? true : false,
        };
        setHprSurveyDetails({ ...details });
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [
    createHprSurvey,
    { status: createStatus, isLoading: isSaving },
  ] = useMutation(createHprSurveyApi, {
    onSuccess: () => {
      successNotification("HPR Survey updated");
      history.push("/company/hpr-surveys");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleEmailDialogOpen = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const handleCreateHprSurvey = (send_emails) => {
    const createdHprSurvey = {
      ...hprSurveyDetails,
      is_draft: false,
      questions: questions,
      send_emails: true,
    };
    delete createdHprSurvey.employees;
    createHprSurvey(createdHprSurvey);
  };

  const handleSaveAsDraft = (values) => {
    const createdHprSurvey = {
      ...values,
      questions: questions,
      send_emails: false,
      is_draft: true,
    };
    delete createdHprSurvey.employees;
    createHprSurvey(createdHprSurvey);
  };

  const loader =
    status === "loading" || isFetching || isLoading || isEmployeesLoading;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>

      {isEmailDialogOpen && (
        <NotificationModal
          from="form"
          isLoading={createStatus === "loading"}
          hprSurvey={{ ...hprSurveyDetails }}
          visible={isEmailDialogOpen}
          handleCancel={handleEmailDialogOpen}
          handleSubmit={handleCreateHprSurvey}
        />
      )}
      <FullScreenLayout
        source={{ path: "/company/hpr-surveys", name: "HPR Surveys" }}
        title={
          hprSurveyDetails?.is_draft ? "Create HPR Survey" : "Edit HPR Survey"
        }
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            {!hprSurveyDetails?.is_draft && (
              <Alert
                message="You are editing a live HPR Survey"
                type="warning"
                showIcon
                closable
              />
            )}

            <HprSurveyBasicsForm
              mode={mode}
              initialValues={{ ...hprSurveyDetails }}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={handleSaveAsDraft}
              handleDone={handleEmailDialogOpen}
              setHprSurvey={setHprSurveyDetails}
              isSaving={isSaving}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

CreateFromDraftHprSurvey.propTypes = {
  mode: PropTypes.string,
};

export default CreateFromDraftHprSurvey;

import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Row } from "antd";

const { Title } = Typography;

const EmptyCard = ({ Icon = <></>, text = "" }) => {
  return (
    <Card className="mt-40">
      <Row
        justify="center"
        align="middle"
        className="flex-direction-column my-48"
      >
        <div>{Icon}</div>
        <Title level={3} className="mt-64 text-primary">
          {text}
        </Title>
      </Row>
    </Card>
  );
};

EmptyCard.propTypes = {
  Icon: PropTypes.func,
  text: PropTypes.string,
};

export default EmptyCard;

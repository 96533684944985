import React, { useEffect } from "react";
// import { Typography, Button, Card, Row, Col } from "antd";
// import { useQuery } from "react-query";
import { Redirect } from "react-router-dom";

import {
  useIsGettingStartedVisible,
  //  useSetupCompletionPercentage, useCurrentUser
} from "store";
// import {
//   // getCurrentUserName,
//   getCompletionPercentage,
//   getOrderedActiveSteps,
//   errorNotification,
// } from "app/appUtils";
// import { getCheckListApi } from "apis/setupCheckListApi";
// import { BoxLoader } from "components";
// import { ReactComponent as CompletedStep } from "assets/settings/setup-progress/completed_step.svg";
// import { ReactComponent as InCompletedStep } from "assets/settings/setup-progress/incomplete_step.svg";
// import styles from "./SetupProgress.module.css";

// const { Title, Text } = Typography;

const SetupProgress = (props) => {
  const [, setIsGettingStartedVisible] = useIsGettingStartedVisible();
  // const [
  //   setupCompletionPercentage,
  //   setSetupCompletionPercentage,
  // ] = useSetupCompletionPercentage();
  // const [currentUser] = useCurrentUser();
  // const [orderedActiveSteps, setOrderedActiveSteps] = useState();

  // const { status, isLoading, isFetching } = useQuery(
  //   "getCheckList",
  //   getCheckListApi,
  //   {
  //     onSuccess: (data) => {
  //       const orderedActiveSetupSteps = getOrderedActiveSteps(
  //         data?.steps || []
  //       );
  //       setOrderedActiveSteps(orderedActiveSetupSteps);
  //       setSetupCompletionPercentage(
  //         getCompletionPercentage(orderedActiveSetupSteps)
  //       );
  //     },
  //     onError: (error) => {
  //       errorNotification(error);
  //     },
  //   }
  // );

  // if (status === "loading" || isLoading || isFetching) {
  //   return <BoxLoader />;
  // }

  // return (
  //   <div>
  //     <div>
  //       <Title className="inline-display" type="secondary" level={3}>
  //         Getting Started -
  //       </Title>
  //       <Title
  //         className="text-primary inline-display"
  //         level={3}
  //       >{` ${setupCompletionPercentage}%`}</Title>
  //     </div>

  //     <Card className="mt-24">
  //       <Title level={3}>{`Welcome to culture.easy, ${getCurrentUserName(
  //         currentUser
  //       )}.`}</Title>
  //       <div className="mt-24 mb-36">
  //         <Text>
  //           We are thrilled to have you. Complete the following items to become
  //           a power user in no time.
  //         </Text>
  //       </div>
  //       <div>
  //         {orderedActiveSteps?.map((step) => (
  //           <Link key={step?.id} to={step?.link_extension}>
  //             <Row className={`cursor-pointer ${styles.row}`} align="middle">
  //               <Col xs={{ span: 4 }} lg={{ span: 2 }}>
  //                 {step?.completed ? <CompletedStep /> : <InCompletedStep />}
  //               </Col>
  //               <Col xs={{ span: 20 }} lg={{ span: 15 }}>
  //                 <Text className="block-display">{step?.name}</Text>
  //                 <Text type="secondary">{step?.description}</Text>
  //               </Col>
  //               <Col xs={{ span: 24 }} lg={{ span: 7 }}>
  //                 <Button
  //                   className={styles.linkDescriptionBtn}
  //                   type="primary"
  //                   ghost={true}
  //                   size="small"
  //                 >
  //                   {step?.link_description}
  //                 </Button>
  //               </Col>
  //             </Row>
  //           </Link>
  //         ))}
  //       </div>
  //     </Card>
  //   </div>
  // );

  useEffect(() => {
    setIsGettingStartedVisible(true);
  }, [setIsGettingStartedVisible]);

  return <Redirect to="/home" />;
};

SetupProgress.propTypes = {};

export default SetupProgress;

import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { BoxLoader } from "components";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import AddSurveys from "../add/AddSurveys";
import { getSurveyDetailsApi } from "apis/surveyApi";
import { errorNotification } from "app/appUtils";

const CloneSurvey = (props) => {
  const { id } = useParams();
  const [surveyDetails, setSurveyDetails] = useState(null);

  const { status, isLoading, isFetching } = useQuery(
    ["getSurveyDetails", id],
    getSurveyDetailsApi,
    {
      onSuccess: (data) => {
        const newQuestions = data?.questions?.map((question) => {
          const newQuestion = { ...question };
          delete newQuestion.id;
          return newQuestion;
        });
        setSurveyDetails({
          name: data?.name || "",
          description: data?.description || "",
          for_entire_company: data?.for_entire_company,
          participants:
            data?.survey_participants?.map(
              (participation) => participation?.employee?.id
            ) || [],
          questions: newQuestions || [],
        });
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = status === "loading" || isFetching || isLoading;

  return (
    <>
      {loader ? (
        <FullScreenLayout
          source={{ path: "/company/surveys", name: "Surveys" }}
        >
          <BoxLoader />
        </FullScreenLayout>
      ) : (
        <AddSurveys
          mode="clone_survey"
          initialValues={{
            ...surveyDetails,
            selectedTemplate:
              surveyDetails?.questions?.length > 0
                ? { name: "Cloned Questions", questions: [] }
                : null,
          }}
        />
      )}
    </>
  );
};

CloneSurvey.propTypes = {};

export default CloneSurvey;

import React from "react";
import PropTypes from "prop-types";
import { Space, Typography, Row } from "antd";

import {
  EmployeeAvatarWithName,
  ThreeSixthViewMCQSelectionsList,
  MultiLineText,
} from "components";
import MacroHighlightedText from "./MacroHighlightedText";
import { TEXT_TYPE } from "app/company/three_sixty_feedback/threeSixtyConstants";
import BarChart from "./BarChart";

const { Text } = Typography;

const getQuestionResponses = (questionId = "", responses = {}) => {
  if (!responses) {
    return [];
  }
  const questionResponses = responses[questionId];
  if (!questionResponses) {
    return [];
  }
  return questionResponses;
};

const getQuestionOptions = (options = []) => {
  const labelOptions = {};
  options.forEach(({ value }, index) => {
    labelOptions[index + 1] = value;
  });
  return labelOptions;
};

const getSeriesData = (question = {}, responses = []) => {
  const { options } = question;
  const seriesData = options.map(() => 0);
  if (responses) {
    responses.forEach((resp) => {
      seriesData[resp - 1] += 1;
    });
  }
  return seriesData;
};

const TextAnswers = ({ isAdmin = false, responses = [] }) => {
  return (
    <div>
      {responses?.map((response, index) => (
        <div className="mb-8">
          {isAdmin && (
            <div>
              <EmployeeAvatarWithName employee={response?.employee || {}} />
            </div>
          )}
          <MultiLineText
            text={`${index + 1}. ${isAdmin ? response?.answer : response}`}
          />
        </div>
      ))}
    </div>
  );
};
TextAnswers.propTypes = {
  questionId: PropTypes.number,
  responses: PropTypes.object,
};

const View360FeedbackAnswers = ({ isAdmin = false, data = {} }) => {
  return (
    <div>
      {data?.threesixty_feedback_cycle?.questions?.map((question, index) => {
        const questionResponses = getQuestionResponses(
          question?.id,
          isAdmin ? data?.detailed_responses : data?.responses
        );
        return (
          <Space direction="vertical" className="width-100-percent">
            <div className="mt-24">
              <Space direction="vertical" className="width-100-percent">
                <Row align="middle">
                  <MacroHighlightedText
                    className={"font-size-24"}
                    isTitle={true}
                    highlightClassName="transparent-macro-question-highlight "
                    firstName={data?.employee?.first_name || "-"}
                    lastName={data?.employee?.last_name || "-"}
                    text={`${index + 1}. ${question?.description}`}
                  />
                  {!question?.is_required ? (
                    <Text className="ml-8" type="secondary">
                      (Optional)
                    </Text>
                  ) : (
                    <></>
                  )}
                </Row>
                <Text type="secondary">{`Answered by ${
                  questionResponses?.length || 0
                } out of ${data?.reviewers_count || 0} reviewers`}</Text>
                {question?.type === TEXT_TYPE ? (
                  <TextAnswers
                    isAdmin={isAdmin}
                    responses={questionResponses}
                  />
                ) : (
                  <div className="width-60-percent">
                    {isAdmin ? (
                      <ThreeSixthViewMCQSelectionsList
                        questionOptions={question?.options || []}
                        responses={questionResponses}
                      />
                    ) : (
                      <BarChart
                        title=""
                        seriesName=""
                        labelOptions={getQuestionOptions(
                          question?.options || []
                        )}
                        seriesData={getSeriesData(
                          question || {},
                          questionResponses
                        )}
                      />
                    )}
                  </div>
                )}
              </Space>
            </div>
          </Space>
        );
      })}
    </div>
  );
};

View360FeedbackAnswers.propTypes = {};

export default View360FeedbackAnswers;

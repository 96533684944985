import React, { useState } from "react";
import { Form, Input, DatePicker, Typography, Checkbox, Tooltip } from "antd";

import {
  EmployeeAvatarWithName,
  SelectEmployeesRadioGroup,
  TreeTable,
} from "components";
import { getInitialTreeData } from "app/company/common/utils";
import StepFormActionButtons from "./StepFormActionButtons";
import { ReactComponent as QuestionMarkOutlined } from "assets/three_sixty_feedback/QuestionMarkOutlined.svg";

import styles from "../../ThreeSixtyFeedback.module.css";

const { RangePicker } = DatePicker;
const { Text } = Typography;

function CycleBasicsForm({
  mode = "create",
  initialValues = {},
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) {
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );
  const [initialTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );

  const handleResetSearch = () => {
    setTreeData(initialTreeData);
  };

  const handleSelect = (updatedKeys = []) => {
    form.setFieldsValue({
      participants: updatedKeys,
    });
  };

  const handleSelectAll = (allEmployeeIds = []) => {
    form.setFieldsValue({
      participants: allEmployeeIds,
    });
  };

  return (
    <Form
      className="mt-24"
      form={form}
      name="cycleBasics"
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Cycle Name"
        rules={[
          {
            required: true,
            message: "Missing Cycle Name",
          },
          {
            whitespace: true,
            message: "Cycle Name cannot be Empty",
          },
        ]}
        className={styles.cycleBasicsName}
        required={false}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="reviewer_message"
        label="Message to Reviewers"
        rules={[
          {
            required: true,
            message: "Missing Cycle Description",
          },
          {
            whitespace: true,
            message: "Cycle Description cannot be Empty",
          },
        ]}
        required={false}
      >
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          className="input-textarea-resize-none"
        />
      </Form.Item>

      <Form.Item
        name="period"
        label="Cycle Period"
        rules={[
          {
            required: true,
            message: "Missing Cycle Period",
          },
        ]}
        required={false}
      >
        <RangePicker />
      </Form.Item>

      <Form.Item
        name="for_entire_company"
        label="Who will be reviewed in this cycle?"
        hidden={mode === "edit"}
      >
        <SelectEmployeesRadioGroup handleResetSearch={handleResetSearch} />
      </Form.Item>

      <Form.Item hidden name="participants"></Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.participants !== curValues?.participants ||
          prevValues?.for_entire_company !== curValues?.for_entire_company
        }
        hidden={mode === "edit"}
        noStyle
      >
        {() => {
          let participants = form.getFieldValue("participants") || [];
          return !form.getFieldValue("for_entire_company") ? (
            <TreeTable
              className="mb-24"
              columns={[
                {
                  title: "Employee",
                  dataIndex: "key",
                  key: "key",
                  render: (data, row) => {
                    return (
                      <EmployeeAvatarWithName
                        employee={row}
                        shouldShowInactive={true}
                      />
                    );
                  },
                },
              ]}
              selections={participants}
              selectedRowKeys={participants}
              dataSource={treeData}
              initialTreeData={initialTreeData}
              activeEmployees={employees}
              inActiveEmployees={inActiveEmployees}
              setTreeData={setTreeData}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              handleResetSearch={handleResetSearch}
            />
          ) : null;
        }}
      </Form.Item>

      <Form.Item shouldUpdate>
        {() => {
          const checked = form.getFieldValue("employee_can_nominate") || false;
          return (
            <>
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  form.setFieldsValue({
                    employee_can_nominate: e.target.checked,
                  });
                }}
              >
                <Text className="ml-8">
                  Allow employees to nominate reviewers.{" "}
                  <Tooltip title="Enabling this option lets the employees nominate a group of reviewers. Their manager can modify this group.">
                    <QuestionMarkOutlined className="mb--4" />
                  </Tooltip>
                </Text>
              </Checkbox>
            </>
          );
        }}
      </Form.Item>

      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          currentStep={0}
          shouldShowSaveAsDraft={initialValues?.draft}
          handlePreviousClick={handlePreviousClick}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 0 });
          }}
        />
      </Form.Item>
    </Form>
  );
}

export default CycleBasicsForm;

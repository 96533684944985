import { useState } from "react";
import { useQuery } from "react-query";

import { getAllEmployeesApi } from "apis/commonApi";
import { errorNotification } from "app/appUtils";

const useEmployees = () => {
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [inActiveEmployees, setInActiveEmployees] = useState([]);

  const { data: allEmployeesHash, isLoading, isFetching, refetch } = useQuery(
    "getAllEmployees",
    getAllEmployeesApi,
    {
      onSuccess: (data) => {
        const employees = Object.values(data);
        const activeEmployees = employees?.filter((emp) => emp?.active);
        const inActiveEmployees = employees?.filter((emp) => !emp?.active);
        setActiveEmployees(activeEmployees);
        setInActiveEmployees(inActiveEmployees);
        return data;
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  return {
    allEmployeesHash,
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading: isLoading,
    isEmployeesFetching: isFetching,
    refetch,
  };
};

export default useEmployees;

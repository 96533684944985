import { useState } from "react";

const useButtonWithSpinner = (defaultButtonWithSpinner = null) => {
  const [buttonWithSpinner, setButtonWithSpinner] = useState(
    defaultButtonWithSpinner
  );

  const handleButtonWithSpinnerChange = (newButtonWithSpinner = "") => {
    setButtonWithSpinner(newButtonWithSpinner);
  };

  return [buttonWithSpinner, handleButtonWithSpinnerChange];
};

export default useButtonWithSpinner;

import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import {
  Modal,
  Row,
  Col,
  Button,
  Input,
  Form,
  Empty,
  // Typography
} from "antd";

import { searchObjectivesApi } from "apis/okrV2Api";
import {
  APIBasedEmployeeSearch,
  BoxLoader,
  // EmployeeAvatarGroup,
  RowVirtualizer,
} from "components";

// import styles from "../../OKRs.module.css";

// const { Text } = Typography;

const AddParentOKRModal = ({
  currentTitle = "",
  visible = false,
  selectedPeriodId = null,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const [form] = Form.useForm();

  const [searchObjectives, { data, isLoading }] = useMutation(
    searchObjectivesApi
  );

  const handleSearch = (values) => {
    searchObjectives(values);
  };

  return (
    <Modal
      width="1000px"
      visible={visible}
      onCancel={onCancel}
      title={`Select Parent OKR for ${currentTitle || "New OKR"}`}
      footer={null}
    >
      <Form
        className="mb-8"
        form={form}
        layout="vertical"
        onFinish={handleSearch}
        initialValues={{
          objective_period_id: selectedPeriodId,
          query: "",
          owner_id: null,
        }}
      >
        <Form.Item name="objective_period_id" hidden />
        <Row gutter={2} align="middle">
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <Form.Item name="owner_id" label="Owner">
              <APIBasedEmployeeSearch
                placeholder="Type owner"
                showArrow={false}
                allowClear={true}
                shouldShowAvatar={true}
                currentOwners={[]}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            label="Objective Title"
            offset={1}
          >
            <Form.Item name="query" label="Objective Title">
              <Input placeholder="Type title" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button htmlType="submit" type="primary">
            Search OKRs
          </Button>
        </Row>
      </Form>
      {isLoading && <BoxLoader />}
      {data?.length === 0 && (
        <Empty description="No Objectives in the selected OKR period, please change the period and search again" />
      )}
      <RowVirtualizer data={data} onSubmit={onSubmit} />
      {/* <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        {data?.map((objective) => (
          <Row
            align="middle"
            className={`cursor-pointer ${styles.parentObjectiveRow}`}
            onClick={() => onSubmit(objective)}
          >
            <EmployeeAvatarGroup
              className="mr-8"
              divClassName="inline-display height-90-percent"
              employees={objective?.owners}
            />
            <Text style={{ maxWidth: "70%" }} ellipsis={true}>
              {objective?.title}
            </Text>
          </Row>
        ))}
       </div> */}
    </Modal>
  );
};

AddParentOKRModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddParentOKRModal;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, DatePicker, Typography, Button } from "antd";
import moment from "moment";

import {
  EmployeeAvatarWithName,
  SelectEmployeesRadioGroup,
  TreeTable,
} from "components";
import { getInitialTreeData } from "app/company/common/utils";
import StepFormActionButtons from "./StepFormActionButtons";
import PreviewModal from "./questions/PreviewModal";

import styles from "../../HprSurvey.module.css";

const { Text } = Typography;

const HprSurveyBasicsForm = ({
  mode = "create",
  initialValues = {},
  employees = [],
  inActiveEmployees = [],
  isSaving = false,
  handleSaveAsDraft = () => {},
  handleDone = () => {},
  setHprSurvey = () => {},
}) => {
  const [form] = Form.useForm();

  const [treeData, setTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );
  const [initialTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const handleResetSearch = () => {
    setTreeData(initialTreeData);
  };

  const handleSelect = (updatedKeys = []) => {
    form.setFieldsValue({
      participants: updatedKeys,
    });
  };

  const handleSelectAll = (allEmployeeIds = []) => {
    form.setFieldsValue({
      participants: allEmployeeIds,
    });
  };

  const handleFormSubmit = () => {
    const hpr_survey = { ...form.getFieldsValue(true) };
    setHprSurvey(hpr_survey);
    handleDone(hpr_survey);
  };

  const showPreviewModal = () => {
    const hpr_survey = { ...form.getFieldsValue(true) };
    setHprSurvey(hpr_survey);
    setShowPreviewDialog(true);
  };

  const handlePreviewDialog = () => {
    setShowPreviewDialog(false);
  };

  return (
    <Form
      className="mt-24"
      form={form}
      name="surveyBasics"
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        name="title"
        label="HPR Survey Name"
        rules={[
          {
            required: true,
            message: "Missing HPR Survey Name",
          },
          {
            whitespace: true,
            message: "HPR Survey Name cannot be Empty",
          },
        ]}
        required={false}
      >
        <Input className={styles.width75Percent} />
      </Form.Item>

      <Form.Item
        name="due_date"
        label="Ends on:"
        rules={[
          {
            required: true,
            message: "Missing Ends on date",
          },
          {
            validator: (rule, value, callback) => {
              try {
                if (
                  moment(value, "YYYY-MM-DD").isBefore(moment().startOf("day"))
                ) {
                  callback("End on cant be in the past");
                }
                callback();
              } catch (err) {
                callback(err);
              }
            },
          },
        ]}
        required={false}
      >
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().endOf("day")}
        />
      </Form.Item>

      <Form.Item name="preview_hpr_survey">
        <Button type="primary" onClick={showPreviewModal}>
          Preview HPR Survey
        </Button>
      </Form.Item>

      <Form.Item
        name="for_entire_company"
        label="Who are the participants of this survey?"
      >
        <SelectEmployeesRadioGroup handleResetSearch={handleResetSearch} />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.participants !== curValues?.participants ||
          prevValues?.for_entire_company !== curValues?.for_entire_company
        }
      >
        {() => {
          let participants = form.getFieldValue("participants") || [];
          return !form.getFieldValue("for_entire_company") ? (
            <TreeTable
              className="mb-24"
              columns={[
                {
                  title: "Employee",
                  dataIndex: "key",
                  key: "key",
                  render: (data, row) => {
                    return (
                      <>
                        <EmployeeAvatarWithName employee={row} />
                        {!row?.active ? (
                          <Text type="secondary"> (Inactive)</Text>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  },
                },
              ]}
              selections={participants}
              selectedRowKeys={participants}
              dataSource={treeData}
              initialTreeData={initialTreeData}
              activeEmployees={employees}
              inActiveEmployees={inActiveEmployees}
              setTreeData={setTreeData}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              handleResetSearch={handleResetSearch}
            />
          ) : null;
        }}
      </Form.Item>

      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          currentStep={0}
          shouldShowSaveAsDraft={initialValues?.draft}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 0 });
          }}
          isSaving={isSaving}
        />
      </Form.Item>

      <PreviewModal
        visible={showPreviewDialog}
        hprSurvey={initialValues}
        handleCancel={handlePreviewDialog}
      />
    </Form>
  );
};

HprSurveyBasicsForm.propTypes = {
  mode: PropTypes.string,
  initialValues: PropTypes.object,
  employees: PropTypes.array,
  handleSaveAsDraft: PropTypes.func,
  handleDone: PropTypes.func,
  setHprSurvey: PropTypes.func,
  isSaving: PropTypes.bool,
};

export default HprSurveyBasicsForm;

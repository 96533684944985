import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Modal, Button, Typography, Steps, Row, Col, Form } from "antd";

import HprDimensionQuestionForm from "components/HprDimensionQuestionForm";
import questions from "./questionsData";

const { Text, Title } = Typography;
const { Step } = Steps;

const PreviewModal = ({
  visible = false,
  hprSurvey = {},
  handleCancel = () => {},
  mode = "preview",
}) => {
  const [current, setCurrent] = useState(0);

  const dimensions = questions
    .map((question) => question.hpr_dimension)
    .filter((dimension, index, self) => self.indexOf(dimension) === index)
    .sort();

  const getDimensionQuestions = (dimension) => {
    return questions.filter((question) => question.hpr_dimension === dimension);
  };

  const handleSubFormSubmit = () => {
    if (current !== dimensions.length - 1) setCurrent(current + 1);
  };

  const handleSaveAsDraft = () => {};
  const handlePreviousClick = () => {
    if (current !== 0) setCurrent(current - 1);
  };

  return (
    <Modal
      visible={visible}
      title={"HPR Survey Preview"}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      footer={[
        <Button
          type="primary"
          ghost
          onClick={() => {
            setCurrent(0);
            handleCancel();
          }}
        >
          Close
        </Button>,
      ]}
    >
      <Row>
        <Col span={3} />
        <Col span={18}>
          <Alert
            className="mb-8"
            type="info"
            message={
              "This is a preview of how the HPR survey will look to participants."
            }
          />
          <Title level={2}>{hprSurvey?.title || "HPR Survey Title"}</Title>
          <Text type="secondary">{`Due date: ${hprSurvey?.due_date?.format(
            "YYYY/MM/DD"
          )}`}</Text>
        </Col>
        <Col span={3} />
      </Row>

      <Steps
        responsive={true}
        size="small"
        progressDot
        className="mt-32"
        style={{ width: "100%" }}
        current={current}
      >
        {dimensions.map((dimension, index) => {
          return <Step key={index} title={dimension} />;
        })}
      </Steps>

      <div className="ml-30">
        <Form.Provider onFormFinish={handleSubFormSubmit}>
          {dimensions[current] && (
            <HprDimensionQuestionForm
              mode={mode}
              title={dimensions[current]}
              key={current}
              name={dimensions[current]}
              questions={getDimensionQuestions(dimensions[current])}
              handleSaveAsDraft={handleSaveAsDraft}
              handlePreviousClick={handlePreviousClick}
              currentStep={current}
              lastStep={dimensions.length - 1}
            />
          )}
        </Form.Provider>
      </div>
    </Modal>
  );
};

PreviewModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hpr_survey: PropTypes.object,
  questions: PropTypes.array,
  mode: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PreviewModal;

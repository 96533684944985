import React from "react";
import AppLayoutV3 from "components/layouts/AppLayoutV3";
import Reviews from "./Reviews";
// import ReviewsEditV2 from "./edit-v2/ReviewsEditV2";
import ReviewDetails from "./details/ReviewDetails";

const routes = [
  {
    exact: true,
    path: "/reviews/",
    layout: AppLayoutV3,
    component: Reviews,
  },
  {
    exact: true,
    path: "/reviews/overview/:reviewCycleId/",
    layout: AppLayoutV3,
    component: () => <ReviewDetails selectedTab="overview" />,
  },
  {
    exact: true,
    path: "/reviews/peer/:peerId/review/:reviewId",
    layout: AppLayoutV3,
    component: () => <ReviewDetails selectedTab="edit" />,
  },
  {
    exact: true,
    path: "/reviews/peer/:peerId/review/",
    layout: AppLayoutV3,
    component: () => <ReviewDetails selectedTab="edit" />,
  },
  {
    exact: true,
    path: "/reviews/review/:reviewId/",
    layout: AppLayoutV3,
    component: () => <ReviewDetails selectedTab="edit" />,
  },
  // {
  //   exact: true,
  //   path: "/reviews/:selectedTab/:reviewId/:typeOfReview",
  //   layout: AppLayoutV3,
  //   component: ReviewDetails,
  // },
  // {
  //   exact: true,
  //   path: "/reviews/:selectedTab/:reviewId",
  //   layout: AppLayoutV3,
  //   component: ReviewDetails,
  // },
  // {
  //   exact: true,
  //   path: "/reviews/:reviewId/edit",
  //   component: ReviewsEditV2,
  // },
];

export default routes;

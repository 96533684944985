import React, { useState } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Typography, Row, Button, Card, Space } from "antd";
import { Link } from "react-router-dom";

import { BoxLoader, EmptyCard } from "components";
import { getMySurveysApi } from "apis/surveyApi";
import { errorNotification, formattedDate } from "app/appUtils";
import { ReactComponent as SurveysIcon } from "assets/cold-screens/Surveys.svg";

const { Title, Text } = Typography;

const Surveys = (props) => {
  const { data, status, isLoading, isFetching } = useQuery(
    "getMySurveys",
    getMySurveysApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [showCompletedSurveys, setShowCompletedSurveys] = useState(false);

  const handleShowCompletedSurveys = () => {
    setShowCompletedSurveys(!showCompletedSurveys);
  };

  const activeSurveys = data?.filter((survey) => survey?.survey?.is_active);
  const completedSurveys = data?.filter((survey) => !survey?.survey?.is_active);

  const loader = status === "loading" || isLoading || isFetching;

  return (
    <div>
      <Helmet>
        <title>Surveys - culture.easy</title>
      </Helmet>

      <Title level={2}>Surveys</Title>
      {loader && <BoxLoader />}

      {!loader &&
        activeSurveys?.length === 0 &&
        completedSurveys?.length === 0 && (
          <EmptyCard
            Icon={<SurveysIcon />}
            text="There are no surveys for you to answer."
          />
        )}

      {!loader &&
        activeSurveys?.map((activeSurvey) => (
          <Card className="mt-8">
            <Row justify="space-between">
              <Space direction="vertical">
                <Title level={3}>{activeSurvey?.survey?.name}</Title>
                <Text type="secondary">
                  {`Due date: ${formattedDate(activeSurvey?.survey?.due_date)}`}
                </Text>
              </Space>
              <Link to={`/surveys/${activeSurvey?.id}/edit`}>
                <Button type="primary" size="small" ghost={true}>
                  {activeSurvey?.answered
                    ? "Edit your Answers"
                    : "Answer Survey"}
                </Button>
              </Link>
            </Row>
          </Card>
        ))}
      {!loader && completedSurveys?.length > 0 && (
        <Row className={clsx("mt-16", "mb-16")} justify="center">
          <Button
            type="primary"
            ghost={true}
            onClick={handleShowCompletedSurveys}
          >
            {showCompletedSurveys
              ? "Hide Completed Surveys"
              : "Show Completed Surveys"}
          </Button>
        </Row>
      )}
      {showCompletedSurveys &&
        completedSurveys?.map((completedSurvey) => (
          <Card className="my-8">
            <Row justify="space-between">
              <Space direction="vertical">
                <Title level={3}>{completedSurvey?.survey?.name}</Title>
                <Text type="secondary">
                  {completedSurvey?.answered_on
                    ? `Answered on: ${formattedDate(
                        completedSurvey?.answered_on
                      )}`
                    : `Due date: ${formattedDate(
                        completedSurvey?.survey?.due_date
                      )}`}
                </Text>
              </Space>
              <Link to={`/surveys/${completedSurvey?.id}/edit`}>
                <Button type="primary" size="small" ghost={true}>
                  View Survey
                </Button>
              </Link>
            </Row>
          </Card>
        ))}
    </div>
  );
};

Surveys.propTypes = {};

export default Surveys;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { BoxLoader } from "components";

import Vimeo from "@u-wave/react-vimeo";

const VimeoVideo = ({ shouldAutoPlay = false, src = "", onEnd = () => {} }) => {
  const [isVimeoIFrameLoading, setIsVimeoIFrameLoading] = useState(true);

  const handleIsVimeoIFrameLoading = () => {
    setIsVimeoIFrameLoading(false);
  };

  return (
    <>
      {isVimeoIFrameLoading && <BoxLoader height="10px" />}

      <Vimeo
        className="mt-48 mb-40 vimeo-video"
        video={src}
        autoplay={shouldAutoPlay}
        // width="806"
        height="400"
        allow="autoplay; fullscreen"
        allowfullscreen
        onLoaded={handleIsVimeoIFrameLoading}
        onEnd={onEnd}
      />
    </>
  );
};

VimeoVideo.propTypes = {
  src: PropTypes.string,
};

export default VimeoVideo;

import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { DueDateText } from "components";

const { Text } = Typography;

const PerfReviewTodoPanelHeader = ({
  title = "",
  differenceOfDates = 0,
  isDueDateInPast = false,
  todosCompletedCount = 0,
  totalTodosCompletedCount = 0,
}) => {
  return (
    <Row align="middle">
      <Col span={8}>
        <Space>
          {todosCompletedCount === totalTodosCompletedCount ? (
            <CheckOutlined />
          ) : (
            <span style={{ height: "14px", width: "15px", display: "block" }} />
          )}
          <div>
            <Text className="block-display">{title}</Text>
            {todosCompletedCount !== totalTodosCompletedCount && (
              <DueDateText
                differenceOfDates={differenceOfDates}
                isDueDateInPast={isDueDateInPast}
              />
            )}
          </div>
        </Space>
      </Col>
      <Col>{`${todosCompletedCount} / ${totalTodosCompletedCount}`}</Col>
    </Row>
  );
};

PerfReviewTodoPanelHeader.propTypes = {
  title: PropTypes.string,
  differenceOfDates: PropTypes.number,
  isDueDateInPast: PropTypes.bool,
  todosCompletedCount: PropTypes.number,
  totalTodosCompletedCount: PropTypes.number,
};

export default PerfReviewTodoPanelHeader;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import {
  Row,
  Col,
  Typography,
  Menu,
  Dropdown,
  // Modal,
  Space,
  Tag,
  Grid,
  Tooltip,
} from "antd";
import Icon, {
  MoreOutlined,
  // ExclamationCircleFilled,
  UserOutlined,
  TeamOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";

import { EmployeeAvatarGroup } from "components";
import EditOKR from "../../edit/EditOKR";
import ChangeOwnerModal from "./ChangeOwnerModal";
import ChangeObjectiveWeights from "./ChangeObjectiveWeights";
import CloneObjectiveModal from "./CloneObjectiveModal";
import ObjectiveSummaryModal from "./ObjectiveSummaryModal";
import DeleteObjectiveWarning from "./DeleteObjectiveWarning";
import ProgressSlider from "./ProgressSlider";
import { getStatus, getExpectedPercentageFor, getActionStyles } from "../utils";
import { saveObjectiveApi, deleteObjectiveApi } from "apis/okrV2Api";
import { successNotification, errorNotification } from "app/appUtils";
import { ReactComponent as CompanyIcon } from "assets/icons/company.svg";

const { Text } = Typography;
// const { confirm } = Modal;
const { useBreakpoint } = Grid;

const ObjectiveOptionsMenu = ({
  disabled = false,
  canClone = true,
  handleChangeWeightsVisible = () => {},
  handleEditObjective = () => {},
  handleCloneObjectiveVisible = () => {},
  handleShowSummaryVisible = () => {},
  handleDeleteObjective = () => {},
  ...props
}) => {
  return (
    <Menu
      {...props}
      onClick={({ domEvent: e }) => {
        e.stopPropagation();
      }}
    >
      <Menu.Item disabled={disabled} onClick={handleEditObjective}>
        Edit Objective
      </Menu.Item>
      <Menu.Item disabled={disabled} onClick={handleShowSummaryVisible}>
        Show Summary
      </Menu.Item>
      <Menu.Item disabled={disabled} onClick={handleChangeWeightsVisible}>
        Change KR Weights
      </Menu.Item>
      <Menu.Item disabled={!canClone} onClick={handleCloneObjectiveVisible}>
        Clone Objective
      </Menu.Item>
      <Menu.Item disabled={disabled} onClick={handleDeleteObjective}>
        Delete
      </Menu.Item>
    </Menu>
  );
};
ObjectiveOptionsMenu.propTypes = {
  handleEditObjective: PropTypes.func,
  handleCloneObjectiveVisible: PropTypes.func,
  handleDeleteObjective: PropTypes.func,
};

const ObjectivePanelHeader = ({
  currentLevelPaddingLeft = "0px",
  from = "my-okrs",
  canUpdate = true,
  isActive = false,
  selectedTeamId = "",
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  objective = {},
  parentObjectiveId = null,
  percentageProgressData = {},
  selectedPeriodObj = {},
  timePeriods = [],
  refetch = () => {},
  detailsRefetch = () => {},
  refetchAncestorProgress = () => {},
  parentDetailsRefetch = () => {},
  parentAncestorRefetch = () => {},
}) => {
  const screens = useBreakpoint();
  const [
    isDeleteObjectiveWarningVisible,
    setIsDeleteObjectiveWarningVisible,
  ] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isChangeWeightsVisible, setIsChangeWeightsVisible] = useState(false);

  const [saveObjective] = useMutation(saveObjectiveApi, {
    onSuccess: () => {
      successNotification("Successfully changed owner of objective");
      refetch();
      parentDetailsRefetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [deleteObjective] = useMutation(deleteObjectiveApi, {
    onSuccess: () => {
      successNotification("Successfully deleted objective");
      refetch();
      parentDetailsRefetch();
      parentAncestorRefetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [isChangeOwnerModalVisible, setIsChangeOwnerModalVisible] = useState(
    false
  );
  const [isCloneObjectiveVisible, setIsCloneObjectiveVisible] = useState(false);
  const [isShowSummaryVisible, setIsShowSummaryVisible] = useState(false);

  const handleChangeWeightsVisible = () => {
    setIsChangeWeightsVisible(!isChangeWeightsVisible);
    if (isDropdownVisible) {
      handleIsDropdownVisible();
    }
  };

  const handleIsChangeOwnerModalVisible = () => {
    setIsChangeOwnerModalVisible(!isChangeOwnerModalVisible);
  };

  const handleIsDeleteObjectiveWarningVisible = () => {
    setIsDeleteObjectiveWarningVisible(!isDeleteObjectiveWarningVisible);
    if (isDropdownVisible) {
      handleIsDropdownVisible();
    }
  };

  const handleDelete = () => {
    deleteObjective({ objectiveId: objective?.id });
  };

  const handleIsEditVisible = () => {
    setIsEditVisible(!isEditVisible);
    if (isDropdownVisible) {
      handleIsDropdownVisible();
    }
  };

  const handleShowSummaryVisible = () => {
    setIsShowSummaryVisible(!isShowSummaryVisible);
    if (isDropdownVisible) {
      handleIsDropdownVisible();
    }
  };

  const handleIsDropdownVisible = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleSaveObjective = () => {
    refetch();
    detailsRefetch();
    parentDetailsRefetch();
    parentAncestorRefetch();
    refetchAncestorProgress();
    handleIsEditVisible();
  };

  const handleChangeOwner = (values) => {
    saveObjective({
      objective: {
        id: objective?.id,
        owner_ids: values?.owner_ids,
        parent_objective_id: parentObjectiveId,
      },
    });
    handleIsChangeOwnerModalVisible();
  };

  const handleCloneObjectiveVisible = () => {
    setIsCloneObjectiveVisible(!isCloneObjectiveVisible);
    if (isDropdownVisible) {
      handleIsDropdownVisible();
    }
  };

  const current =
    Math.round(percentageProgressData[objective?.id] || 0) ||
    Math.round(objective?.progress || 0);

  const isPeriodUnlocked = !selectedPeriodObj?.locked;

  const finalCanUpdate = canUpdate && isPeriodUnlocked;

  return (
    <>
      {finalCanUpdate && isChangeWeightsVisible && (
        <ChangeObjectiveWeights
          visible={isChangeWeightsVisible}
          objective={objective}
          onCancel={handleChangeWeightsVisible}
          refetch={refetch}
          refetchAncestorProgress={refetchAncestorProgress}
        />
      )}
      {finalCanUpdate && isEditVisible && (
        <EditOKR
          visible={isEditVisible}
          okrId={objective?.id}
          onSave={handleSaveObjective}
          onCancel={handleIsEditVisible}
        />
      )}
      {finalCanUpdate && isChangeOwnerModalVisible && (
        <ChangeOwnerModal
          mode="multiple"
          canUpdate={canUpdate && objective?.can_update}
          title={objective?.title}
          owner={objective?.owners}
          type="objective"
          visible={isChangeOwnerModalVisible}
          onCancel={handleIsChangeOwnerModalVisible}
          onOk={handleChangeOwner}
        />
      )}
      {isShowSummaryVisible && (
        <ObjectiveSummaryModal
          objectiveId={objective?.id}
          visible={isShowSummaryVisible}
          selectedPeriodObj={selectedPeriodObj}
          onCancel={handleShowSummaryVisible}
        />
      )}
      {canUpdate && isCloneObjectiveVisible && (
        <CloneObjectiveModal
          visible={isCloneObjectiveVisible}
          timePeriods={timePeriods}
          objective={objective}
          onCancel={handleCloneObjectiveVisible}
          onOk={handleCloneObjectiveVisible}
        />
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {finalCanUpdate && isDeleteObjectiveWarningVisible && (
          <DeleteObjectiveWarning
            objectiveId={objective?.id}
            objectiveTitle={objective?.title}
            visible={isDeleteObjectiveWarningVisible}
            onCancel={handleIsDeleteObjectiveWarningVisible}
            onDelete={handleDelete}
          />
        )}
      </div>
      <Row align="middle" className="ml-8">
        <Col
          xs={{ span: 24 }}
          lg={{ span: isSmallContainer ? 23 : 11 }}
          style={{ paddingLeft: currentLevelPaddingLeft }}
          // xxl={{
          //   span: isSmallContainer ? 23 : shouldNotExpandOnBigScreen ? 11 : 15,
          // }}
        >
          <Space>
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
            <EmployeeAvatarGroup
              divClassName="height-32-px"
              employees={objective?.owners || []}
              onClick={(e) => {
                e.stopPropagation();
                handleIsChangeOwnerModalVisible();
              }}
            />
            {objective?.type === "OrganizationObjective" && (
              <Icon component={CompanyIcon} />
            )}
            {objective?.type === "IndividualObjective" && <UserOutlined />}
            {objective?.type === "TeamObjective" && <TeamOutlined />}

            {objective?.type === "TeamObjective" && (
              <Tag>
                <Text
                  style={{ maxWidth: "110px" }}
                  ellipsis={{ tooltip: objective?.team?.name }}
                >
                  {objective?.team?.name}
                </Text>
              </Tag>
            )}
            <Text
              style={{
                display: "block",
                // width: width >= 1200 && width <= 1350 ? "70%" : "100%",
              }}
            >
              {objective?.title}
            </Text>
            {objective?.is_aspirational && (
              <Tooltip title="This is an Aspirational OKR.">
                <span role="img" aria-label="rocket-launch">
                  🚀
                </span>
              </Tooltip>
            )}
          </Space>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{
            span: isSmallContainer ? 23 : 12,
            offset: isSmallContainer ? 2 : 1,
          }}
          // xxl={{
          //   span: isSmallContainer ? 23 : shouldNotExpandOnBigScreen ? 12 : 8,
          //   offset: isSmallContainer ? 2 : 1,
          // }}
        >
          <div
            style={getActionStyles(
              screens,
              isSmallContainer,
              objective?.owners?.length
            )}
          >
            <div className="mr-16">
              <ProgressSlider
                current={current}
                currentStatus={
                  current > 0
                    ? getStatus(
                        current,
                        getExpectedPercentageFor(selectedPeriodObj),
                        ""
                      )
                    : "not_started"
                }
                isFromReviews={isFromReviews}
                selectedPeriodObj={selectedPeriodObj}
                disabled={true}
              />
            </div>

            <Text
              style={{
                minWidth: current >= 100 || screens.xxl ? "71px" : "71px",
                marginRight: "30px",
              }}
            >{`${current}%`}</Text>

            {canUpdate ? (
              <Dropdown
                trigger="click"
                visible={isDropdownVisible}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIsDropdownVisible();
                }}
                onVisibleChange={(visible) => {
                  setIsDropdownVisible(visible);
                }}
                overlay={
                  <ObjectiveOptionsMenu
                    disabled={
                      !canUpdate || !objective?.can_update || !isPeriodUnlocked
                    }
                    canClone={canUpdate}
                    handleEditObjective={handleIsEditVisible}
                    handleChangeWeightsVisible={handleChangeWeightsVisible}
                    handleCloneObjectiveVisible={handleCloneObjectiveVisible}
                    handleShowSummaryVisible={handleShowSummaryVisible}
                    handleDeleteObjective={
                      handleIsDeleteObjectiveWarningVisible
                    }
                  />
                }
                getPopupContainer={() =>
                  document.getElementById(
                    "objective-panel-header-three-dot-container"
                  )
                }
                className="ml-16 mt-4"
              >
                <MoreOutlined />
              </Dropdown>
            ) : (
              <span
                style={{ width: "0px", height: "18px", marginLeft: "29px" }}
              ></span>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
ObjectivePanelHeader.propTypes = {
  objective: PropTypes.object,
};
export default ObjectivePanelHeader;

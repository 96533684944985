import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Tooltip, Modal, Input, Button, Typography } from "antd";

import { ButtonWithSpinner } from "components";
import { successNotification, errorNotification } from "app/appUtils";
import { bulkNudgeApi } from "apis/perfReviewApi";
import { ReactComponent as LeftNudgePoint } from "assets/reviews/reviewee_nudge.svg";
import { ReactComponent as NudgeBlock } from "assets/reviews/nudge_block.svg";

const { Text } = Typography;

const getToolTipTitle = (type = "reviewee_submission") => {
  switch (type) {
    case "add_peer_percent":
      return "This action will send a email & slack notification to all manager to add peers.";
    case "reviewee_submission":
      return "This action will send a email & slack notification to all participants who have not submitted their self assessment.";
    case "peer_submission_percent":
      return "This action will send a email & slack notification to all peers who have not submitted their team member’s assessments.";
    case "reviewer_submission":
      return "This action will send a email & slack notification to all managers who have not submitted their team member’s assessments.";
    case "sign_and_close":
      return "This action will send a email & slack to all participants who have not signed their review.";
    default:
      return "This action will send a email & slack notification to all participants who have not submitted their self assessment.";
  }
};

const NudgeModal = ({
  reviewCycleId,
  type = "reviewee_submission",
  visible = false,
  onCancel = () => {},
}) => {
  const [bulkNudge] = useMutation(bulkNudgeApi, {
    onSuccess: (data) => {
      successNotification("Successfully Nudged");
      onCancel();
      setIsLoading(false);
      return data;
    },
    onError: (error) => {
      errorNotification(error);
      setIsLoading(false);
    },
  });

  const [noteText, setNoteText] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const handleNoteChange = (e) => {
    setNoteText(e?.target?.value);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Nudge participants"
      footer={[
        <ButtonWithSpinner
          type="primary"
          disabled={isLoading}
          isSpinning={isLoading}
          onClick={() => {
            setIsLoading(true);
            let finalNudgeType = type;
            if (finalNudgeType === "peer_submission_percent") {
              finalNudgeType = "peer_submission";
            } else if (finalNudgeType === "add_peer_percent") {
              finalNudgeType = "add_peers";
            }

            bulkNudge({ reviewCycleId, type: finalNudgeType, note: noteText });
          }}
        >
          Nudge
        </ButtonWithSpinner>,
        <Button disabled={isLoading} type="primary" ghost onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Text className="block-display mb-16">{getToolTipTitle(type)}</Text>
      <Text className="block-display">Additional Note: </Text>
      <Input.TextArea
        autoSize={{ minRows: 2, maxRows: 6 }}
        className="input-textarea-resize-none"
        value={noteText}
        onChange={handleNoteChange}
      />
    </Modal>
  );
};
NudgeModal.propTypes = {};

const PerfReviewBulkNudge = ({
  className = "",
  type = "reviewee_submission",
  reviewCycleId = null,
  canNudge = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleIsModalVisible = () => {
    setIsModalVisible(!isModalVisible);
  };

  const toolTipTitle = getToolTipTitle(type);

  return (
    <>
      {isModalVisible && (
        <NudgeModal
          type={type}
          reviewCycleId={reviewCycleId}
          visible={isModalVisible}
          onCancel={handleIsModalVisible}
        />
      )}
      {canNudge ? (
        <Tooltip title={toolTipTitle}>
          <LeftNudgePoint
            className={`${className} cursor-pointer secondary-svg-stroke`}
            onClick={handleIsModalVisible}
          />
        </Tooltip>
      ) : (
        <Tooltip title="No one to send a reminder message">
          <NudgeBlock
            className={`${className} cursor-pointer secondary-svg-stroke`}
          />
        </Tooltip>
      )}
    </>
  );
};

PerfReviewBulkNudge.propTypes = {
  employee: PropTypes.object,
  type: PropTypes.string,
  nudgedAt: PropTypes.string,
};

export default PerfReviewBulkNudge;

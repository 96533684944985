import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  // useHistory
} from "react-router-dom";
import styles from "./AppLayoutV3.module.css";
import {
  Alert,
  Row,
  Layout,
  Menu,
  Typography,
  Space,
  Dropdown,
  Divider,
  Button,
} from "antd";
import Icon, {
  SyncOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import {
  EmployeeAvatarWithName,
  PageSpinner,
  TrialExpiredModel,
  GettingStartedModal,
  GettingStartedBtn,
} from "components";
import { useWindowWidth, useLocalStorage } from "hooks";
import {
  useCurrentUser,
  useCompanyList,
  useCurrentCompany,
  // useSetNewOrgSetup,
  useIsSignedOut,
  // useIsSidebarCollapsed,
  useIsGettingStartedVisible,
} from "store";

import { signOut } from "app/appUtils";

import { ReactComponent as ReviewIcon } from "assets/sidebar-icons/reviews.svg";
import { ReactComponent as SurveyIcon } from "assets/sidebar-icons/surveys.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as OKRIcon } from "assets/sidebar-icons/okr.svg";
import { ReactComponent as PraiseIcon } from "assets/sidebar-icons/praise.svg";
import { useQueryCache } from "react-query";
import logo192 from "assets/logo192.png";

const { Header, Content, Sider } = Layout;
const { Text, Link: TypographyLink } = Typography;
// const { useBreakpoint } = Grid;
const MenuList = [
  {
    key: "home",
    path: "/home",
    icon: <HomeOutlined />,
    title: "Home",
    role: "general",
    enableFeatureKey: "Home",
  },
  {
    key: "okr",
    path: "/okrs/",
    icon: <Icon component={OKRIcon} />,
    title: "OKRs",
    role: "general",
    enableFeatureKey: "Objectives and Key Results (OKRs)",
  },
  {
    key: "okr-insights",
    path: "/insights/",
    icon: <LineChartOutlined />,
    title: "Insights",
    role: "admin",
    enableFeatureKey: "Objectives and Key Results (OKRs)",
  },
  {
    key: "praise",
    path: "/praise",
    icon: <Icon component={PraiseIcon} />,
    title: "Praise",
    role: "general",
    enableFeatureKey: "Praise",
  },
  {
    key: "reviews",
    path: "/reviews",
    icon: <Icon component={ReviewIcon} />,
    title: "Perf Reviews",
    role: "general",
    enableFeatureKey: "Reviews",
  },
  {
    key: "continuous-feedback",
    path: "/continuous-feedback",
    icon: <SyncOutlined />,
    title: "Continuous Feedback",
    role: "general",
    enableFeatureKey: "Continuous Feedbacks",
  },
  {
    key: "surveys",
    path: "/surveys",
    icon: <Icon component={SurveyIcon} />,
    title: "Surveys",
    role: "general",
    enableFeatureKey: "Surveys",
  },
];

function AppLayout({ children }) {
  // const screens = useBreakpoint();
  // const history = useHistory();
  const [currentUser] = useCurrentUser();
  const width = useWindowWidth();
  const [collapsed, setCollapsed] = useLocalStorage(
    "is-sidebar-collapsed",
    width <= 1100
  );
  // const [collapsed, setCollapsed] = useIsSidebarCollapsed(false);
  const queryCache = useQueryCache();
  const currentPath = useLocation().pathname;
  const [active, setActive] = useLocalStorage("active-tab-name", null);

  const [, setCompanyList] = useCompanyList();
  const [
    currentCompany,
    // setCurrentCompany
  ] = useCurrentCompany();
  const [isSignedOut, setIsSignedOut] = useIsSignedOut();
  const [isAdmin] = useState(currentCompany?.role === "admin");
  // const [, setIsSetNewOrgSetup] = useSetNewOrgSetup();
  const [isGettingStartedVisible, setIsGettingStartedVisible] =
    useIsGettingStartedVisible(false);

  useEffect(() => {
    let activeTab = "";
    MenuList.forEach((menuItem) => {
      if (currentPath === "/") {
        activeTab = "okr";
      } else if (currentPath.startsWith(menuItem.path)) {
        activeTab = menuItem.key;
      }
    });
    setActive(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  // useEffect(() => {
  // setCollapsed(width <= 1100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [width]);

  const onCollapse = (eventCollapsed, type) => {
    if (type === "clickTrigger") {
      setCollapsed(eventCollapsed);
    }
  };

  const handleSignOut = () => {
    signOut({
      queryCache,
      setCompanyList,
      setIsSignedOut,
    });
  };

  const handleIsGettingStartedVisible = () => {
    setIsGettingStartedVisible(!isGettingStartedVisible);
  };

  const enabledFeatureKeys = ["Home"]; // Home is enabled by default
  // eslint-disable-next-line no-unused-expressions
  currentCompany?.features?.forEach((feature) => {
    if (feature?.enabled) {
      enabledFeatureKeys.push(feature?.name);
    }
  });

  // const getContentClassName = (currentUserEmail = "", screens = {}) => {
  //   if (!screens?.lg) {
  //     if (currentUserEmail === "culture-demo@easy.xyz") {
  //       return collapsed ? "ml-90 mr-10 mt-80 mb-80" : "ml-210 mr-10 mt-80";
  //     } else {
  //       return collapsed ? "ml-90 mr-10 mt-80 mb-80" : "ml-210 mr-10 mt-80";
  //     }
  //   } else {
  //     if (currentUserEmail === "culture-demo@easy.xyz") {
  //       return collapsed ? "ml-120 mr-40 mb-40 mt-80" : "ml-240 mr-40 mt-80";
  //     } else {
  //       return collapsed ? "ml-120 mr-40 mb-40 mt-80" : "ml-240 mr-40 mt-80";
  //     }
  //   }
  // };

  if (isSignedOut) {
    return <PageSpinner />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        className={styles.sidebar}
        collapsedWidth="65"
      >
        <Space size={0} style={{ padding: "10px", paddingRight: "0px" }}>
          <TypographyLink
            href={
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? "https://app.culture.easy.xyz/"
                : "https://app.culture-staging.easy.xyz/"
            }
          >
            <img
              className={styles.logo}
              src={currentCompany?.logo_url || logo192}
              alt="logo"
            />
          </TypographyLink>
          {!collapsed && (
            <div className={styles.logoText}>
              culture.easy
              <div>{currentCompany?.name}</div>
            </div>
          )}
        </Space>
        <Menu
          theme="dark"
          selectedKeys={[active]}
          mode="inline"
          className={styles.siderMenu}
          style={{ width: collapsed ? "65px" : "200px" }}
        >
          {MenuList.map((item) => {
            if (
              item.enableFeatureKey &&
              enabledFeatureKeys?.includes(item.enableFeatureKey)
            ) {
              if (item?.role === "admin") {
                if (isAdmin) {
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link to={item.path}>{item.title}</Link>
                    </Menu.Item>
                  );
                }
                return null;
              }
              return (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.path}>{item.title}</Link>
                </Menu.Item>
              );
            }
            return null;
          })}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? "65px" : "200px" }}
      >
        <Header className={styles.header} style={{ padding: 0 }}>
          <Space align="end" className={styles.profileSpace}>
            <div className="flex-display align-items-center flex-direction-row">
              {/* {setupCompletionPercentage !== "100" && ( */}
              {isAdmin && (
                <div>
                  <GettingStartedBtn onClick={handleIsGettingStartedVisible} />
                </div>
              )}
              {/* )} */}

              <div>
                <QuestionCircleOutlined style={{ color: "#5330c9" }} />
                <TypographyLink
                  className="ml-8"
                  target="_blank"
                  href="https://help.culture.easy.xyz/"
                >
                  {collapsed ? "Help" : "Help Guide"}
                </TypographyLink>
              </div>

              <div className="flex-display align-items-center">
                <EmployeeAvatarWithName
                  className="ml-16"
                  size={32}
                  height="32px"
                  width="32px"
                  employee={currentCompany?.user}
                  emptyText="No User"
                />
              </div>
              <div>
                <Dropdown
                  overlayClassName={styles.settingsOverlay}
                  overlay={
                    <Menu
                      style={{
                        padding: "24px",
                      }}
                    >
                      {isAdmin && (
                        <Menu.ItemGroup key="g1" title="Admin Settings">
                          <Menu.Item key="company_settings">
                            <Link to="/settings/company-settings">
                              Company Settings
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="people">
                            <Link to="/settings/directory">People</Link>
                          </Menu.Item>
                          <Menu.Item key="people_new">
                            <Link to="/settings/directory/new-employee">
                              Add People
                            </Link>
                          </Menu.Item>
                          {/* <Menu.Item key="praise">
                            <Link to="/settings/praise-badges">
                              Praise Badges
                            </Link>
                          </Menu.Item> */}
                          <Menu.Item key="features">
                            <Link to="/company/overview">Features</Link>
                          </Menu.Item>
                          <Divider className="my-8" />
                        </Menu.ItemGroup>
                      )}
                      <Menu.Item
                        key="logout"
                        icon={<></>}
                        onClick={handleSignOut}
                      >
                        Log Out
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Link className={`flex-display ${styles.settingsIconDiv}`}>
                    <SettingsIcon />
                  </Link>
                </Dropdown>
              </div>
            </div>
          </Space>
        </Header>
        <div className="flex-display justify-content-center">
          <Content
            //  className={getContentClassName(currentUser?.email, screens)}
            style={{
              margin: "80px 10px 40px",
              maxWidth: "1060px",
              width: "100%",
              padding: "10px",
            }}
          >
            <>
              <>
                {/* {true ? ( */}
                {currentUser?.email === "culture-demo@easy.xyz" ? (
                  <Alert
                    type="info"
                    className="mt-16 mb-16"
                    message={
                      <Row justify="center" align="middle">
                        <Text>
                          You are signed into the demo account. Get started for
                          free
                        </Text>
                        <Button
                          className="ml-16"
                          type="primary"
                          size="small"
                          onClick={handleSignOut}
                        >
                          Setup your own account
                        </Button>
                      </Row>
                    }
                  />
                ) : (
                  <>
                    {/* {true && ( */}
                    {(currentCompany?.trial_days_remaining !== null ||
                      currentCompany?.trial_days_remaining !== undefined) &&
                      currentCompany?.trial_days_remaining > 0 && (
                        <Alert
                          type="info"
                          className="mt-16 mb-16"
                          closable
                          message={
                            <Row justify="start" align="middle">
                              <Text>
                                {`You have ${
                                  currentCompany?.trial_days_remaining
                                } days left in your trial period. ${
                                  isAdmin
                                    ? "Please select a plan to continue using culture.easy."
                                    : "Contact your HR Admin to upgrade."
                                }`}
                              </Text>
                              {isAdmin && (
                                <Link
                                  to="/settings/company-settings/billing"
                                  className="ml-16"
                                >
                                  SELECT NOW
                                </Link>
                              )}
                            </Row>
                          }
                        />
                      )}
                  </>
                )}
                {/* {true && ( */}
                {(currentCompany?.trial_days_remaining !== null ||
                  currentCompany?.trial_days_remaining !== undefined) &&
                  typeof currentCompany?.trial_days_remaining === "number" &&
                  currentCompany?.trial_days_remaining <= 0 && (
                    <TrialExpiredModel isAdmin={isAdmin} />
                  )}
                {isGettingStartedVisible && (
                  <GettingStartedModal
                    visible={isGettingStartedVisible}
                    onCancel={handleIsGettingStartedVisible}
                  />
                )}
              </>
              {children}
            </>
          </Content>
        </div>
      </Layout>
    </Layout>
  );
}

export default AppLayout;

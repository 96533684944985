import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Typography, Button, Popover } from "antd";
import { NimblePicker } from "emoji-mart";
import appleEmojiData from "emoji-mart/data/apple.json";
import "emoji-mart/css/emoji-mart.css";

const { Text } = Typography;

const EmojiTextInput = ({
  value,
  validationError,
  autoSize = { minRows: 2, maxRows: 4 },
  pickerContainerStyles = {
    position: "fixed",
    zIndex: 1050,
    marginTop: "-470px",
    marginLeft: "-150px",
  },
  onChange,
}) => {
  const [cursorPosition, setCursorPosition] = useState(0);
  const [descError, setDescError] = useState("");

  const handleCursorPositionChange = (e) => {
    const selection = e.target.selectionStart;
    if (selection >= 0) {
      setCursorPosition(selection);
    }
  };

  const textArea = document.getElementById("emoji-text-area");

  if (textArea) {
    textArea.addEventListener("click", handleCursorPositionChange);
  }

  const handleChange = (e) => {
    const newValue = e.target.value;
    const currentPosition = e.target.selectionStart;
    if (newValue.trim() === "") {
      setDescError("Description cant be empty");
    } else {
      setDescError("");
    }
    onChange(newValue);
    setCursorPosition(currentPosition);
  };

  const handleSelectEmoji = (emoji, value, onChange) => {
    const [descValueFirst, descValueLast] = [
      value.slice(0, cursorPosition),
      value.slice(cursorPosition),
    ];
    onChange(`${descValueFirst}${emoji.native}${descValueLast}`);
    setCursorPosition(cursorPosition + emoji.native.length);
  };

  return (
    <Row align="middle">
      <Col xs={{ span: 18 }} lg={{ span: 21 }}>
        <Input.TextArea
          className="input-textarea-resize-none"
          id="emoji-text-area"
          autoSize={autoSize}
          value={value}
          onChange={handleChange}
        />
        {(!value || value?.trim()?.length === 0 || validationError) && (
          <div>
            <Text className="mt-8" type="danger">
              {descError || validationError}
            </Text>
          </div>
        )}
      </Col>
      <Col xs={{ span: 6 }} lg={{ span: 3 }}>
        <Row justify="center">
          <Popover
            content={
              <div>
                {/* <Picker
                  set="apple"
                  color="#5330C9"
                  sheetSize={16}
                  title="Pick your emoji…"
                  emoji="point_up"
                  onSelect={(emoji) =>
                    handleSelectEmoji(emoji, value, onChange)
                  }
                />
                Using NimblePicker for speed
                */}
                <NimblePicker
                  set="apple"
                  data={appleEmojiData}
                  color="#5330C9"
                  sheetSize={32}
                  title="Pick your emoji…"
                  emoji="point_up"
                  onSelect={(emoji) =>
                    handleSelectEmoji(emoji, value, onChange)
                  }
                />
              </div>
            }
            title="Choose an Emoji"
            trigger="click"
          >
            <Button
              // className={"emoji-picker-btn"}
              size="large"
              // onClick={handleEmojiPicker}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 12px",
              }}
            >
              <span
                className={"cursor-pointer font-size-40"}
                role="img"
                aria-label="emoji"
              >
                🙂
              </span>
            </Button>
          </Popover>
        </Row>
      </Col>
    </Row>
  );
};

EmojiTextInput.propTypes = {
  autoSize: PropTypes.object,
  onChange: PropTypes.func,
};

export default EmojiTextInput;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { Redirect, useLocation } from "react-router-dom";

import {
  useCurrentCompany,
  useCompanyList,
  useSetNewOrgSetup,
  useCurrentUser,
} from "store";
import { getOrganizationApi } from "apis/appApi";
import ChooseAOrg from "app/choose-a-org/ChooseAOrg";
import PageSpinner from "./PageSpinner";

const OnboardGuardV2 = ({ children }) => {
  const location = useLocation();
  const [isNoOrgsPresent, setIsNoOrgsPresent] = useState(false);
  const [shouldChoseOne, setShouldChoseOne] = useState(false);
  const [companyList, setCompanyList] = useCompanyList();
  const [currentCompany, setCurrentCompany] = useCurrentCompany();
  const [isNewOrgSetup] = useSetNewOrgSetup();
  const [currentUser] = useCurrentUser();

  const currentCompanyIdInLocalStorage = localStorage.getItem(
    "currentCompanyId"
  );

  const { isLoading, isFetching } = useQuery(
    "getOrganization",
    getOrganizationApi,
    {
      enabled: Boolean(!companyList?.length) && currentUser,
      onError: (error) => {
        const status = error?.response?.status;
        if (status === 308) {
          setIsNoOrgsPresent(true);
          localStorage.removeItem("currentCompanyId");
        }
      },
      onSuccess: (data) => {
        setCompanyList(data);

        if (isNewOrgSetup) {
          localStorage.removeItem("currentCompanyId");
        } else {
          // const currentCompanyId = localStorage.getItem("currentCompanyId");
          if (data?.length === 1) {
            localStorage.setItem("currentCompanyId", data[0]?.id || "");
            setCurrentCompany(data[0]);
          } else if (
            !currentCompanyIdInLocalStorage ||
            currentCompanyIdInLocalStorage === "undefined" ||
            currentCompanyIdInLocalStorage === "null"
          ) {
            setShouldChoseOne(true);
          } else {
            // data?.length > 0 && currentCompanyIdInLocalStorage is present
            const currentComp = data?.find(
              (org) => org.id === parseInt(currentCompanyIdInLocalStorage)
            );
            if (currentComp) {
              setCurrentCompany(currentComp);
            } else {
              setShouldChoseOne(true);
            }
          }
          // if (
          //   currentCompanyId &&
          //   currentCompanyId !== "undefined" &&
          //   currentCompanyId !== "null"
          // ) {
          //   const currentComp = data?.find(
          //     (org) => org.id === parseInt(currentCompanyId)
          //   );
          //   if (currentComp) {
          //     setCurrentCompany(currentComp);
          //   } else {
          //     setCurrentCompany(data[0]);
          //   }
          // } else {
          //   setCurrentCompany(data[0]);
          //   localStorage.setItem("currentCompanyId", data[0]?.id || "");
          // }
        }
      },
    }
  );

  if (isLoading || isFetching) {
    return <PageSpinner />;
  }

  if (isNoOrgsPresent && !location.pathname.includes("no-orgs-found")) {
    return <Redirect to="/no-orgs-found" />;
  }

  if (isNewOrgSetup && !location.pathname.includes("signup")) {
    return <Redirect to="/signup" />;
  }

  if (shouldChoseOne && !currentCompany && !currentCompanyIdInLocalStorage) {
    return (
      <ChooseAOrg
        currentUser={currentUser}
        companyList={companyList}
        setIsMultipleOrgsPresent={setShouldChoseOne}
        setCurrentCompany={setCurrentCompany}
      />
    );
  }

  if (currentCompany && location.pathname.includes("/signup")) {
    return <Redirect to="/" />;
  }

  return children;
};

OnboardGuardV2.propTypes = {
  children: PropTypes.any,
};

export default OnboardGuardV2;

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import styles from "app/okrs/OKRs.module.css";

const { Text, Title } = Typography;

const CountCard = ({
  className = "",
  title = "",
  count = 0,
  hasMiniCount = false,
  miniCount = 0,
}) => {
  return (
    <div className={`${className} ${styles.countCard}`}>
      <Text type="secondary" className="block-display">
        {title}
      </Text>

      <div className={hasMiniCount ? "mt-8" : ""}>
        <Title
          className={styles.countCardTitle}
          level={4}
          style={{ display: hasMiniCount ? "inline" : "block" }}
        >
          {count || 0}
        </Title>
        {hasMiniCount && <Text className="ml-8">{miniCount}</Text>}
      </div>
    </div>
  );
};
CountCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  hasMiniCount: PropTypes.bool,
  miniCount: PropTypes.number,
};

export default CountCard;

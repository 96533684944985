const getChecklistApiMock = () => {
  return [
    {
      id: 1,
      name: "Add employees to the Company Directory",
      description: "",
      order: 1,
      link: "/settings/directory",
      link_description: "Go to Directory",
      active: false,
      completed: false,
    },
    {
      id: 2,
      name: "Company name set to BeautifulCode LLP",
      description: "",
      order: 2,
      link: "/settings",
      link_description: "Go to Settings",
      completed: true,
    },
    {
      id: 3,
      name: "Explore Reviews",
      description: "Watch the Reviews video or create a Review cycle.",
      order: 3,
      link: "/company/reviews",
      link_description: "Go to Reviews",
      completed: false,
    },
    {
      id: 4,
      name: "Explore Objectives and Key Results(OKRs)",
      description: "Watch Objectives video or create an Objective cycle.",
      order: 4,
      link: "/company/objectives",
      link_description: "Go to Objectives",
      completed: false,
    },
    {
      id: 5,
      name: "Create your first Praise",
      description: "",
      order: 5,
      link: "/praise",
      link_description: "Go to Praise",
      completed: false,
    },
  ];
};

const updateCheckListMock = () => {
  return [
    {
      id: 1,
      name: "Add employees to the Company Directory",
      description: "",
      order: 1,
      link: "/settings/directory",
      link_description: "",
      completed: false,
    },
    {
      id: 2,
      name: "Company name set to BeautifulCode LLP",
      description: "",
      order: 2,
      link: "/settings",
      link_description: "",
      completed: true,
    },
    {
      id: 3,
      name: "Explore Reviews",
      description: "Watch the Reviews video or create a Review cycle.",
      order: 3,
      link: "/company/reviews",
      link_description: "",
      completed: true,
    },
    {
      id: 4,
      name: "Explore Objectives and Key Results(OKRs)",
      description: "Watch Objectives video or create an Objective cycle.",
      order: 4,
      link: "/company/objectives",
      link_description: "",
      completed: false,
    },
    {
      id: 5,
      name: "Create your first Praise",
      description: "",
      order: 5,
      link: "/praise",
      link_description: "",
      completed: false,
    },
  ];
};

export { getChecklistApiMock, updateCheckListMock };

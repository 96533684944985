import axios from "axios";
import moment from "moment";

const getObjectivePeriodsApi = async () => {
  const { data } = await axios.get("/api/v2/objective_periods/");
  return data;
};

const savePeriodApi = async ({ period }) => {
  const { data } = await axios.post("/api/v2/admin/objective_periods/", {
    ...period,
  });
  return data;
};

const exportPeriodDataApi = async ({ period }) => {
  const { data } = await axios.get(
    `/api/v2/admin/objective_periods/${period?.id}/export`
  );
  return data;
};

const deletePeriodApi = async ({ id }) => {
  const { data } = await axios.delete(`/api/v2/admin/objective_periods/${id}`);
  return data;
};

const getObjectiveConfigApi = async () => {
  const { data } = await axios.get("/api/v2/objectives/config");
  return data;
};

const saveObjectiveApi = async ({ objective }) => {
  const { data } = await axios.post("/api/v2/objective/", {
    objective: {
      ...objective,
      key_results: objective?.key_results?.map((kr) => ({
        ...kr,
        due_date: kr?.due_date
          ? moment(kr?.due_date, "YYYY-MM-DD")?.format("YYYY-MM-DD")
          : null,
      })),
    },
  });
  return data;
};

const cloneObjectiveApi = async ({ objectiveId, periodId, owner_ids }) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/clone/${periodId}`,
    {
      objective: {
        owner_ids,
      },
    }
  );
  return data;
};

const getCompanyObjectivesApi = async (_, period_id, team_id) => {
  const { data } = await axios.post("/api/v2/objectives/company_objectives", {
    period_id,
    team_id: team_id === "company-okrs" ? null : team_id,
  });
  return data;
};

const getMyOKRsApi = async (_, period_id) => {
  const { data } = await axios.get(
    `/api/v2/objectives/my_objectives/${period_id}`
  );
  return data;
};

const getSummaryApi = async (summaryParams) => {
  const { data } = await axios.post(`/api/v2/objectives/summary`, {
    ...summaryParams,
  });
  return data;
};

const getObjectiveDetailsApi = async (_, objectiveId, isTreeView = false) => {
  const { data } = await axios.get(
    `/api/v2/objective/${objectiveId}/${
      isTreeView ? "tree_view_details" : "details"
    }/`
  );
  return data;
};

const saveKeyResultApi = async ({ key_result, objectiveId }) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/key_result`,
    {
      key_result,
    }
  );
  return data;
};

const saveInitiativeApi = async ({ initiative, objectiveId }) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/initiative`,
    {
      initiative,
    }
  );
  return data;
};

const deleteKeyResultApi = async ({ objectiveId, keyResultId }) => {
  const { data } = await axios.delete(
    `/api/v2/objective/${objectiveId}/key_result/${keyResultId}`
  );
  return data;
};

const deleteInitiativeApi = async ({ objectiveId, initiativeId }) => {
  const { data } = await axios.delete(
    `/api/v2/objective/${objectiveId}/initiative/${initiativeId}`
  );
  return data;
};

const deleteObjectiveApi = async ({ objectiveId }) => {
  const { data } = await axios.delete(`/api/v2/objective/${objectiveId}`);
  return data;
};

const getObjectiveApi = async (_, objectiveId) => {
  const { data } = await axios.get(`/api/v2/objective/${objectiveId}`);
  return data;
};

const postKeyResultCheckInApi = async ({
  objectiveId,
  keyResultId,
  checkin,
}) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/key_result/${keyResultId}/checkin`,
    {
      ...checkin,
    }
  );
  return data;
};

const postInitiativeCheckInApi = async ({
  objectiveId,
  initiativeId,
  checkin,
}) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/initiative/${initiativeId}/checkin`,
    {
      ...checkin,
    }
  );
  return data;
};

const getNotificationConfigApi = async () => {
  const { data } = await axios.get("/api/v2/admin/notifications/");
  return data;
};

const saveNotificationConfigApi = async ({ config, id }) => {
  const { data } = await axios.post(`/api/v2/admin/notifications/${id}`, {
    ...config,
  });
  return data;
};

const getAllCommentsApi = async (_, objectiveId, itemId, isKeyResult) => {
  const { data } = await axios.get(
    `/api/v2/objective/${objectiveId}/${
      isKeyResult ? "key_result" : "initiative"
    }/${itemId}/comments/`
  );
  return data;
};

const updateCommentApi = async ({
  objectiveId,
  itemId,
  isKeyResult,
  commentId,
  comment,
}) => {
  const { data } = await axios.put(
    `/api/v2/objective/${objectiveId}/${
      isKeyResult ? "key_result" : "initiative"
    }/${itemId}/comments/${commentId}`,
    {
      comment,
    }
  );
  return data;
};

const postCommentApi = async ({
  objectiveId,
  itemId,
  isKeyResult,
  comment,
}) => {
  const { data } = await axios.post(
    `/api/v2/objective/${objectiveId}/${
      isKeyResult ? "key_result" : "initiative"
    }/${itemId}/comments/`,
    {
      comment,
    }
  );
  return data;
};

const deleteCommentApi = async ({
  objectiveId,
  itemId,
  isKeyResult,
  commentId,
}) => {
  const { data } = await axios.delete(
    `/api/v2/objective/${objectiveId}/${
      isKeyResult ? "key_result" : "initiative"
    }/${itemId}/comments/${commentId}`
  );
  return data;
};

const getItemActivityDataApi = async (_, objectiveId, itemId, isKeyResult) => {
  const { data } = await axios.get(
    `/api/v2/objective/${objectiveId}/${
      isKeyResult ? "key_result" : "initiative"
    }/${itemId}/activities/`
  );
  return data;
};

const searchObjectivesApi = async (details) => {
  const { data } = await axios.post("/api/v2/objectives/search", {
    ...details,
  });

  return data;
};

const performBulkUploadOkrApi = async ({
  objective_period_id,
  file,
  formData,
}) => {
  const { data } = await axios.post(
    "/api/v2/admin/objectives/perform_bulk_upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

const checkBulkUploadOkrApi = async ({
  objective_period_id,
  file,
  formData,
}) => {
  const { data } = await axios.post(
    "/api/v2/admin/objectives/check_bulk_upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

const getProgressHashApi = async (_, objectiveId) => {
  const { data } = await axios.get(
    `/api/v2/objective/${objectiveId}/ancestor_progress`
  );
  return data;
};

const postEmployeeObjectivesApi = async ({ employee_id, period_id }) => {
  const { data } = await axios.post("api/v2/objectives/employee_objectives", {
    employee_id,
    period_id,
  });
  return data;
};

const postUpdateWeightageApi = async ({ weightage, objectiveId }) => {
  const { data } = await axios.post(
    `api/v2/objective/${objectiveId}/update_weightage`,
    {
      weightage,
    }
  );
  return data;
};

const getInsightsSummaryApi = async (_, selectedPeriodObjId) => {
  const { data } = await axios.get(
    `/api/v2/objectives/okr_insights_summary/${selectedPeriodObjId}`
  );
  return data;
};

const getEmployeesWithoutCheckinsApi = async ({
  selectedPeriodObjId,
  days = 7,
}) => {
  const { data } = await axios.post(`/api/v2/objectives/emp_without_checkin`, {
    period_id: selectedPeriodObjId,
    inactive_days: days,
  });
  return data;
};

const getEmployeesWithoutOKRsApi = async ({ selectedPeriodObjId }) => {
  const { data } = await axios.post(
    `/api/v2/objectives/employees_with_no_objective_or_key_results/`,
    {
      period_id: selectedPeriodObjId,
    }
  );
  return data;
};

const getEmployeesWithOKRStatusApi = async ({
  selectedPeriodObjId,
  status = "at_risk",
}) => {
  const { data } = await axios.post(`/api/v2/objectives/status/`, {
    period_id: selectedPeriodObjId,
    status,
  });
  return data;
};

const bulkNudgeInsightsApi = async ({
  selectedPeriodObjId,
  type = "",
  note = "",
  employee_ids = [],
}) => {
  const { data } = await axios.post(`/api/v2/objectives/nudge/`, {
    period_id: selectedPeriodObjId,
    employee_ids,
    nudge_for: type,
    note,
  });
  return data;
};

export {
  getObjectivePeriodsApi,
  savePeriodApi,
  deletePeriodApi,
  exportPeriodDataApi,
  getObjectiveConfigApi,
  saveObjectiveApi,
  cloneObjectiveApi,
  getCompanyObjectivesApi,
  getMyOKRsApi,
  getSummaryApi,
  getObjectiveDetailsApi,
  saveKeyResultApi,
  saveInitiativeApi,
  deleteObjectiveApi,
  getObjectiveApi,
  postKeyResultCheckInApi,
  postInitiativeCheckInApi,
  deleteKeyResultApi,
  deleteInitiativeApi,
  getNotificationConfigApi,
  saveNotificationConfigApi,
  getAllCommentsApi,
  postCommentApi,
  updateCommentApi,
  deleteCommentApi,
  getItemActivityDataApi,
  searchObjectivesApi,
  checkBulkUploadOkrApi,
  performBulkUploadOkrApi,
  getProgressHashApi,
  postEmployeeObjectivesApi,
  postUpdateWeightageApi,
  getInsightsSummaryApi,
  getEmployeesWithoutCheckinsApi,
  getEmployeesWithoutOKRsApi,
  getEmployeesWithOKRStatusApi,
  bulkNudgeInsightsApi,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Modal, Typography } from "antd";
import { useQuery } from "react-query";

import { SelectedSetupStep, BoxLoader } from "components";
import { useSetupCompletionPercentage } from "store";
import { useWindowHeight } from "hooks";
import {
  getCompletionPercentage,
  getOrderedActiveSteps,
  errorNotification,
} from "app/appUtils";
import { getCheckListApi } from "apis/setupCheckListApi";
import { ReactComponent as CompletedStep } from "assets/settings/setup-progress/completed_step.svg";
import { ReactComponent as InCompletedStep } from "assets/settings/setup-progress/incomplete_step.svg";

import styles from "./GettingStartedModal.module.css";

const { Text } = Typography;

const GettingStartedModal = ({ visible = false, onCancel = () => {} }) => {
  const height = useWindowHeight();

  const [
    setupCompletionPercentage,
    setSetupCompletionPercentage,
  ] = useSetupCompletionPercentage();
  const [orderedActiveSteps, setOrderedActiveSteps] = useState();
  const [selectedStep, setSelectedStep] = useState(0);
  const { status, isLoading, isFetching } = useQuery(
    "getCheckList",
    getCheckListApi,
    {
      onSuccess: (data) => {
        const orderedActiveSetupSteps = getOrderedActiveSteps(
          data?.steps || []
        );
        setOrderedActiveSteps(orderedActiveSetupSteps);
        setSetupCompletionPercentage(
          getCompletionPercentage(orderedActiveSetupSteps)
        );
        let firstUnselectedIndex = 0;
        orderedActiveSetupSteps.forEach((step, index) => {
          if (!step?.completed && firstUnselectedIndex === 0) {
            firstUnselectedIndex = index;
          }
        });
        setSelectedStep(firstUnselectedIndex);
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleSelectStep = (val) => {
    setSelectedStep(val);
  };

  const loader = status === "loading" || isLoading || isFetching;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        <span className="font-size-20">{`Getting Started - ${setupCompletionPercentage}%`}</span>
      }
      width={"95%"}
      footer={null}
      centered
    >
      {loader ? (
        <BoxLoader height={0.75 * height} />
      ) : (
        <Row gutter={24}>
          <Col span={6}>
            {orderedActiveSteps?.map((step, index) => (
              <Card
                className={`mb-8 cursor-pointer ${
                  index === selectedStep ? styles.selectedSetupStep : ""
                } ${styles.stepCard}`}
                onClick={() => handleSelectStep(index)}
              >
                <Row justify="space-between" align="middle">
                  <div className="flex-display align-items-center">
                    <Text className="block-display">{step?.name}</Text>
                    {/* <Text type="secondary">{step?.description}</Text> */}
                  </div>
                  <div className="flex-display align-items-center">
                    {step?.completed ? <CompletedStep /> : <InCompletedStep />}
                  </div>
                </Row>
              </Card>
            ))}
          </Col>
          <Col span={18} style={{ borderLeft: "1px solid #e3e3e3" }}>
            <div
              style={{
                minHeight: 0.75 * height,
                maxHeight: 0.75 * height,
                overflowY: "auto",
                overflowX: "hidden",
                marginLeft: "80px",
              }}
            >
              <SelectedSetupStep
                selectedStep={selectedStep}
                onCancel={onCancel}
              />
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

GettingStartedModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default GettingStartedModal;

import { atom, useRecoilState } from "recoil";

const currentUserState = atom({
  key: "currentUserState",
  default: null,
  dangerouslyAllowMutability: true,
});

const useCurrentUser = () => {
  const [user, setUser] = useRecoilState(currentUserState);
  return [user, setUser];
};

const companyListState = atom({
  key: "companyListState",
  default: null,
});

const useCompanyList = () => {
  const [companyList, setCompanyList] = useRecoilState(companyListState);
  return [companyList, setCompanyList];
};

const currentCompanyState = atom({
  key: "currentCompanyState",
  default: null,
});

const useCurrentCompany = () => {
  const [currentCompany, setStoreCurrentCompany] = useRecoilState(
    currentCompanyState
  );
  const setCurrentCompany = (company) => {
    setStoreCurrentCompany(company);
    localStorage.setItem("currentCompanyId", company?.id || "");
  };
  return [currentCompany, setCurrentCompany];
};

const tokenState = atom({
  key: "tokenState",
  default: null,
});

const useToken = () => {
  const [token, setToken] = useRecoilState(tokenState);
  return [token, setToken];
};

const isNewOrgSetupState = atom({
  key: "isNewOrgSetup",
  default: false,
});

const useSetNewOrgSetup = () => {
  const [isSetNewOrgSetup, setIsSetNewOrgSetup] = useRecoilState(
    isNewOrgSetupState
  );
  return [isSetNewOrgSetup, setIsSetNewOrgSetup];
};

const companySubTabState = atom({
  key: "companySubTab",
  default: "okrs",
});

const useCompanySubTab = () => {
  const [companySubTab, setCompanySubTab] = useRecoilState(companySubTabState);

  return [companySubTab, setCompanySubTab];
};

const isSignedOutState = atom({
  key: "isSignedOut",
  default: false,
});

const useIsSignedOut = () => {
  const [isSignedOut, setIsSignedOut] = useRecoilState(isSignedOutState);

  return [isSignedOut, setIsSignedOut];
};

const setupCompletionPercentageState = atom({
  key: "setupCompletion",
  default: 0,
});

const useSetupCompletionPercentage = () => {
  const [
    setupCompletionPercentage,
    setSetupCompletionPercentage,
  ] = useRecoilState(setupCompletionPercentageState);

  return [setupCompletionPercentage, setSetupCompletionPercentage];
};

const openSubMenuState = atom({
  key: "openAppLayoutSubMenu",
  default: [],
});

const useOpenSubMenu = () => {
  const [openSubMenu, setOpenSubMenu] = useRecoilState(openSubMenuState);

  return [openSubMenu, setOpenSubMenu];
};

const isSidebarCollapsedState = atom({
  key: "isSidebarCollapsed",
  default: false,
});

const useIsSidebarCollapsed = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useRecoilState(
    isSidebarCollapsedState
  );

  return [isSidebarCollapsed, setIsSidebarCollapsed];
};

const isGettingStartedVisibleState = atom({
  key: "isGettingStartedVisible",
  default: false,
});

const useIsGettingStartedVisible = () => {
  const [isGettingStartedVisible, setIsGettingStartedVisible] = useRecoilState(
    isGettingStartedVisibleState
  );

  return [isGettingStartedVisible, setIsGettingStartedVisible];
};

export {
  useCurrentUser,
  useCurrentCompany,
  useToken,
  useCompanyList,
  useSetNewOrgSetup,
  useCompanySubTab,
  useIsSignedOut,
  useSetupCompletionPercentage,
  useOpenSubMenu,
  useIsSidebarCollapsed,
  useIsGettingStartedVisible,
};

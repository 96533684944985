import { getTreeFromFlatData } from "react-sortable-tree";

const filterInactiveNonManagerRootEmployees = (treeData = []) => {
  return treeData?.filter((node) => node?.active || node?.children?.length > 0);
};

const getInitialTreeData = (employees = [], inActiveEmployees = []) => {
  const inActiveWithKeys = inActiveEmployees?.map((emp) => ({
    ...emp,
    key: emp?.id,
  }));

  const treeData = getTreeFromFlatData({
    // flatData: inActiveManagerCorrectedEmployees,
    flatData: [...employees, ...inActiveWithKeys],
    getKey: (node) => node.id,
    getParentKey: (node) => node?.manager_id || null,
    rootKey: null,
  });

  //Filter inactive employees who are at the root level and don't have any children
  return filterInactiveNonManagerRootEmployees(treeData);
};

const getInitialTreeDataForPerfReview = (
  managerMapping = {},
  employees = [],
  inActiveEmployees = []
) => {
  const inActiveWithKeys = inActiveEmployees?.map((emp) => ({
    ...emp,
    key: emp?.id,
  }));

  const treeData = getTreeFromFlatData({
    // flatData: inActiveManagerCorrectedEmployees,
    flatData: [...employees, ...inActiveWithKeys],
    getKey: (node) => node.id,
    getParentKey: (node) =>
      managerMapping[node?.id] || node?.manager_id || null,
    rootKey: null,
  });

  //Filter inactive employees who are at the root level and don't have any children
  return filterInactiveNonManagerRootEmployees(treeData);
};

export { getInitialTreeData, getInitialTreeDataForPerfReview };

import React, { useEffect } from "react";
import { Alert, Modal, Form, Button } from "antd";

import { APIBasedEmployeeSearch } from "components";

const ChangeOwnerModal = ({
  canUpdate = true,
  visible = false,
  title = "",
  mode = null,
  owner = {},
  type = "objective",
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
    form.resetFields();
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    form
      .validateFields()
      .then((values) => {
        let finalValues = {};
        if (mode === "multiple") {
          finalValues["owner_ids"] = values?.owner_id;
        } else {
          finalValues = values;
        }
        onOk(finalValues);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const initialValues = {};

  if (mode === "multiple") {
    initialValues.owner_id = owner?.map((own) => own?.id);
  } else {
    initialValues.owner_id = owner?.id;
  }

  console.log(initialValues);

  return (
    <Modal
      title={`Change Owner of ${
        type === "objective" ? "Objective" : "Key Result"
      }: ${title}`}
      visible={visible}
      destroyOnClose
      width="1000px"
      keyboard={false}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" ghost={true} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button type="primary" onClick={handleSubmit} disabled={!canUpdate}>
          Submit
        </Button>,
      ]}
    >
      <Alert
        className="mb-24"
        message={
          canUpdate
            ? `The new assignee will be responsible for checkins and accomplising the
          ${type === "objective" ? "objective" : "key result"}.`
            : `You don't have access to update owner of this ${
                type === "objective" ? "objective" : "key result"
              }.`
        }
      />

      <Form
        form={form}
        layout="vertical"
        className="editManagerForm"
        name="editManager"
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden />
        <Form.Item
          name="owner_id"
          label="Owner"
          rules={[{ required: true, message: "Missing Owner." }]}
          required={false}
        >
          <APIBasedEmployeeSearch
            mode={mode}
            disabled={!canUpdate}
            shouldShowAvatar={true}
            currentOwners={mode === "multiple" ? [...owner] : { ...owner }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
ChangeOwnerModal.propTypes = {};

export default ChangeOwnerModal;

const getAllThreeSixtyCycles = () => {
  return [
    {
      id: 1,
      name: "Draft Cycle",
      from_date: "2021-01-01",
      to_date: "2021-03-31",
      for_entire_company: false,
      employee_can_nominate: true,
      draft: true,
      draft_step: 0,
      nominate_due_date: "2021-03-31",
      finalize_due_date: "2021-03-31",
      submit_due_date: "2021-03-31",
      are_emails_sent: false,
      completed: false,
      threesixty_cycle_participants: [
        {
          id: 1,
          employee: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
          status: "not_started",
          reviewers: [
            {
              id: 1,
              employee: {
                id: 2,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
              status: "not_started",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "360 Feedback Cycle Q1/2021",
      from_date: "2021-01-01",
      to_date: "2021-03-31",
      for_entire_company: false,
      employee_can_nominate: true,
      draft: false,
      draft_step: 0,
      nominate_due_date: "2021-03-31",
      finalize_due_date: "2021-03-31",
      submit_due_date: "2021-03-31",
      are_emails_sent: false,
      completed: false,
      threesixty_cycle_participants: [
        {
          id: 1,
          employee: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
          status: "not_started",
          reviewers: [
            {
              id: 1,
              employee: {
                id: 2,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
              status: "not_started",
            },
          ],
        },
      ],
    },
  ];
};

const saveThreeSixtyCycle = () => {
  return {
    id: 1,
    name: "360 Feedback Cycle Q1/2021",
    reviewer_message:
      "Please provide feedback on the reviewee's performance during the review period",
    from_date: "2021-01-01",
    to_date: "2021-03-31",
    for_entire_company: false,
    participants: [
      {
        id: 1,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
        },
      },
    ],
    employee_can_nominate: true,
    questions: [
      {
        id: 1,
        type: "ThreesixtyFeedbackText",
        order: 1,
        description: "What did %FIRST_NAME% do well?",
      },
      {
        id: 2,
        type: "ThreesixtyFeedbackMcq",
        order: 2,
        description: "%FIRST_NAME% treat people with respect consistently",
        options: {
          1: "Agree",
          2: "Disagree",
        },
      },
    ],
    nominate_due_date: "2021-03-31",
    finalize_due_date: "2021-03-31",
    submit_due_date: "2021-03-31",
    are_emails_sent: false,
    completed: false,
    draft: false,
    draft_step: 0,
  };
};

const getCycleDetails = () => {
  return {
    id: 1,
    name: "Draft Cycle",
    reviewer_message:
      "Please provide feedback on the reviewee's performance during the review period",
    from_date: "2021-01-01",
    to_date: "2021-03-31",
    for_entire_company: false,
    participants: [
      {
        id: 1,
        employee: {
          id: 222,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
        },
      },
    ],
    employee_can_nominate: true,
    questions: [
      {
        id: 1,
        type: "ThreesixtyFeedbackText",
        order: 1,
        description: "What did %FIRST_NAME% do well?",
      },
      {
        id: 2,
        type: "ThreesixtyFeedbackMcq",
        order: 2,
        description: "%FIRST_NAME% treat people with respect consistently",
        options: {
          1: "Agree",
          2: "Disagree",
        },
      },
    ],
    nominate_due_date: "2021-03-31",
    finalize_due_date: "2021-03-31",
    submit_due_date: "2021-03-31",
    are_emails_sent: false,
    completed: false,
    draft: false,
    draft_step: 0,
  };
};

const getMyParticipationsApi = () => {
  return {
    my_feedbacks: [
      {
        id: 1,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "feedback_shared",
        employee_nominations: ["John Doe"],
        threesixty_feedback_cycle: {
          id: 1,
          name: "360 Feedback Cycle Q1/2021",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 223,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 224,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
    ],
    my_feedbacks_to_other: [
      {
        id: 1,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 1,
          name: "360 Feedback Cycle Q1/2021",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 2,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 3,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 4,
          name: "Cycle 4",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 4,
        employee: {
          id: 1,
          first_name: "Jan 2",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 2 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 4,
          name: "Cycle 4",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 5,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 6,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 7,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 8,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 9,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 10,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
      {
        id: 11,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "not_started",
        threesixty_reviewers: [
          {
            status: "not_started",
          },
        ],
        threesixty_feedback_cycle: {
          id: 2,
          name: "Cycle 2",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
      },
    ],
    my_team_feedbacks: [
      {
        id: 1,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "feedback_shared",
        threesixty_feedback_cycle: {
          id: 1,
          name: "360 Feedback Cycle Q1/2021",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 2,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "submitted",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 3,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "submitted",
        threesixty_feedback_cycle: {
          id: 4,
          name: "Cycle 4",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 4,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "submitted",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 5,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "finalizing_reviewers",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 6,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "finalizing_reviewers",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 7,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "finalizing_reviewers",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 8,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "finalizing_reviewers",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
      {
        id: 9,
        employee: {
          id: 1,
          first_name: "Jan",
          last_name: "Levinson",
          email: "jan_levinson@easy.xyz",
          name: "Jan 3 Levinson",
          active: true,
          manager: {
            id: 1,
            first_name: "Jan",
            last_name: "Levinson",
            email: "jan_levinson@easy.xyz",
            name: "Jan Levinson",
            active: true,
          },
        },
        status: "finalizing_reviewers",
        threesixty_feedback_cycle: {
          id: 3,
          name: "Cycle 3",
          reviewer_message:
            "Please provide feedback on the reviewee's performance during the review period",
          from_date: "2021-01-01",
          to_date: "2021-03-31",
          employee_can_nominate: true,
          nominate_due_date: "2021-03-31",
          finalize_due_date: "2021-03-31",
          submit_due_date: "2021-03-31",
          completed: false,
        },
        threesixty_reviewers: [
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
          {
            id: 1,
            employee: {
              id: 222,
              first_name: "Jan",
              last_name: "Levinson",
              email: "jan_levinson@easy.xyz",
              name: "Jan Levinson",
              active: true,
              manager: {
                id: 1,
                first_name: "Jan",
                last_name: "Levinson",
                email: "jan_levinson@easy.xyz",
                name: "Jan Levinson",
                active: true,
              },
            },
          },
        ],
      },
    ],
  };
};

const updateReviewersApi = () => {
  return { message: "Updated" };
};

const deleteReviewerApi = () => {
  return { message: "Deleted" };
};

const getThreeSixtyFeedbackParticipationMockApi = () => {
  return {
    id: 1,
    status: "collecting_feedback",
    threesixty_reviewers: [
      {
        id: 3,
        status: "in_progress",
        responses: {
          "1": "Yo",
          "2": "Ho",
          "3": "1",
        },
        employee: {
          id: 1,
          first_name: "Ritesh",
          last_name: "Malani",
          email: "ritesh@beautifulcode.in",
          name: "Ritesh Malani",
          active: true,
          user_first_name: "Ritesh Malani",
          user_last_name: "",
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/a-/AOh14GgpS_NkODNus6kzGyBgTc8Q6d2cCPNrHxsEfbA46w=s96-c",
        },
      },
    ],
    threesixty_feedback_cycle: {
      id: 1,
      name: "360° Feedback Cycle Q1/2021",
      reviewer_message:
        "Please provide feedback on the reviewee's performance during the review period.",
      from_date: "2021-01-01",
      to_date: "2021-03-31",
      for_entire_company: true,
      employee_can_nominate: true,
      draft: false,
      draft_step: 0,
      nominate_due_date: "2021-01-21",
      finalize_due_date: "2021-01-21",
      submit_due_date: "2021-01-21",
      are_emails_sent: false,
      completed: false,
      questions: [
        {
          id: 1,
          type: "ThreesixtyFeedbackText",
          order: 1,
          is_required: true,
          allow_comments: false,
          description: "What did %FIRST_NAME% do well?",
        },
        {
          id: 2,
          type: "ThreesixtyFeedbackText",
          order: 2,
          is_required: true,
          allow_comments: false,
          description: "What did %FIRST_NAME% improve on?",
        },
        {
          id: 3,
          type: "ThreesixtyFeedbackMcq",
          order: 3,
          is_required: true,
          allow_comments: false,
          description: "%FIRST_NAME% treats people with respect consistently.",
          options: [
            {
              id: 1,
              value: "Strongly Agree",
            },
            {
              id: 2,
              value: "Agree",
            },
            {
              id: 3,
              value: "Disagree",
            },
            {
              id: 4,
              value: "Strongly Disagree",
            },
          ],
        },
      ],
    },
    employee: {
      id: 1,
      first_name: "Ritesh",
      last_name: "Malani",
      email: "ritesh@beautifulcode.in",
      name: "Ritesh Malani",
      active: true,
      user_first_name: "Ritesh Malani",
      user_last_name: "",
      user_profile_pic_url:
        "https://lh3.googleusercontent.com/a-/AOh14GgpS_NkODNus6kzGyBgTc8Q6d2cCPNrHxsEfbA46w=s96-c",
    },
  };
};

const postThreeSixtyFeedbackParticipationMockApi = () => {
  return {
    message: "Submitted",
  };
};

const showFeedbackMockApi = () => {
  return {
    id: 1,
    responses: {
      1: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqu",
        "ip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident",
        ", sunt in culpa qui officia deserunt mollit anim id est laborum.",
      ],
      2: [
        "ip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident",
        ", sunt in culpa qui officia deserunt mollit anim id est laborum.",
      ],
      3: [1, 1, 1, 2, 2, 4, 1, 1, 4, 2],
    },
    reviewers_count: 3,
    threesixty_feedback_cycle: {
      id: 1,
      name: "360° Feedback Cycle Q1/2021",
      reviewer_message:
        "Please provide feedback on the reviewee's performance during the review period.",
      from_date: "2021-01-01",
      to_date: "2021-03-31",
      for_entire_company: true,
      employee_can_nominate: true,
      draft: false,
      draft_step: 0,
      nominate_due_date: "2021-01-21",
      finalize_due_date: "2021-01-21",
      submit_due_date: "2021-01-21",
      are_emails_sent: false,
      completed: false,
      questions: [
        {
          id: 1,
          type: "ThreesixtyFeedbackText",
          order: 1,
          is_required: true,
          allow_comments: false,
          description: "What did %FIRST_NAME% do well?",
        },
        {
          id: 2,
          type: "ThreesixtyFeedbackText",
          order: 2,
          is_required: true,
          allow_comments: false,
          description: "What did %FIRST_NAME% improve on?",
        },
        {
          id: 3,
          type: "ThreesixtyFeedbackMcq",
          order: 3,
          is_required: true,
          allow_comments: false,
          description: "%FIRST_NAME% treats people with respect consistently.",
          options: [
            {
              id: 1,
              value: "Strongly Agree",
            },
            {
              id: 2,
              value: "Agree",
            },
            {
              id: 3,
              value: "Disagree",
            },
            {
              id: 4,
              value: "Strongly Disagree",
            },
          ],
        },
      ],
    },
    employee: {
      id: 1,
      first_name: "Ritesh",
      last_name: "Malani",
      email: "ritesh@beautifulcode.in",
      name: "Ritesh Malani",
      active: true,
      user_first_name: "Ritesh Malani",
      user_last_name: "",
      user_profile_pic_url:
        "https://lh3.googleusercontent.com/a-/AOh14GgpS_NkODNus6kzGyBgTc8Q6d2cCPNrHxsEfbA46w=s96-c",
    },
  };
};

export {
  getAllThreeSixtyCycles,
  saveThreeSixtyCycle,
  getCycleDetails,
  getMyParticipationsApi,
  updateReviewersApi,
  deleteReviewerApi,
  getThreeSixtyFeedbackParticipationMockApi,
  postThreeSixtyFeedbackParticipationMockApi,
  showFeedbackMockApi,
};

import React from "react";
import PropTypes from "prop-types";
import { useQueryCache } from "react-query";
import { Layout, Card, Button, Typography, Row } from "antd";

import { PageSpinner } from "components";
import { useCompanyList, useIsSignedOut } from "store";
import { getCurrentUserName } from "app/appUtils";
import { signOut } from "app/appUtils";
import logo from "assets/logo.svg";

const { Title, Link } = Typography;

const ChooseAOrg = ({
  currentUser = {},
  companyList = [],
  setIsMultipleOrgsPresent = () => {},
  setCurrentCompany = () => {},
}) => {
  const queryCache = useQueryCache();

  const [, setCompanyList] = useCompanyList();
  const [isSignedOut, setIsSignedOut] = useIsSignedOut();

  const handleClick = (company) => {
    setCurrentCompany(company);
    setIsMultipleOrgsPresent(false);
    localStorage.setItem("currentCompanyId", company?.id || "");
  };

  const handleSignOut = () => {
    signOut({
      isInAppRedirect: true,
      queryCache,
      setCompanyList,
      setIsSignedOut,
    });
  };

  if (isSignedOut) {
    return <PageSpinner />;
  }

  return (
    <Layout className="height-100-vh">
      <Card className="no-org-card">
        <Row justify="center">
          <Link href="https://culture.easy.xyz/">
            <img className="block-display" src={logo} alt="logo" />
          </Link>

          <Title level={4} className="mt-16 mb-24">
            {`Hi ${getCurrentUserName(currentUser)} (${
              currentUser?.email
            }), You are part of multiple organizations, please select one to continue`}
          </Title>
        </Row>
        <div className="flex-display flex-direction-column align-items-center">
          {companyList?.map((company) => (
            <Button
              className="width-250-px mb-16 white-space-normal auto-height"
              type="primary"
              ghost={true}
              onClick={() => handleClick(company)}
            >
              {company?.name}
            </Button>
          ))}
        </div>
        <Row justify="center">
          <Button
            className="mb-24 font-weight-300 width-300-px"
            onClick={handleSignOut}
          >
            Sign In with a different email
          </Button>
        </Row>
      </Card>
    </Layout>
  );
};

ChooseAOrg.propTypes = {
  companyList: PropTypes.array,
  setIsMultipleOrgsPresent: PropTypes.func,
};

export default ChooseAOrg;

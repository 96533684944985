const getAllObjectivePeriodsMockApi = () => {
  return [
    {
      id: 1,
      name: "Test Period",
      start_date: "01/02/2021",
      end_date: "01/03/2021",
      is_active: true,
    },
    {
      id: 2,
      name: "New Period",
      start_date: "01/02/2021",
      end_date: "01/03/2021",
      is_active: false,
    },
  ];
};

const saveObjectivePeriodMockApi = () => {
  return {
    id: 1,
    name: "Test Period",
    start_date: "01/02/2021",
    end_date: "01/03/2021",
    is_active: true,
  };
};

const deleteObjectivePeriodMockApi = () => {
  return {
    message: "Successfully Deleted!",
  };
};

const getObjectiveConfigMockApi = () => {
  return {
    employees: [
      {
        id: "1245",
        first_name: "Abhilash",
        last_name: "K",
        email: "ak@435.com",
        active: true,
      },
      {
        id: "54245",
        first_name: "Phonix",
        last_name: "K",
        email: "bteam@435.com",
      },
    ],
    teams: [
      {
        id: 1,
        name: "HR",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        active: true,
      },
      {
        id: 2,
        name: "HR 2",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        active: true,
      },
    ],
    objective_periods: [
      {
        id: 1,
        name: "Test Period",
        start_date: "01/02/2021",
        end_date: "01/03/2021",
        is_active: true,
      },
      {
        id: 2,
        name: "New Period",
        start_date: "01/02/2021",
        end_date: "01/03/2021",
        is_active: false,
      },
    ],
    objectives: [
      {
        id: 1,
        title: "Test Objective",
      },
      {
        id: 2,
        title: "New Objective",
      },
    ],
  };
};

const getCompanyObjectivesMockApi = () => {
  return {
    summary: {
      group_summary_data: {},
      objectives_percentage_progress_data: { "1": 0 },
    },
    objectives: [
      {
        id: 1,
        title: "asdf",
        type: "OrganizationObjective",
        is_aspirational: false,
        owner_id: 1,
        can_update: true,
        key_results_count: 1,
        sub_objectives_count: 0,
        objective_period_id: 1,
        owner: {
          id: 1,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
      },
    ],
  };
};
const getOKRDetailsMockApi = () => {
  return {
    summary: {
      group_summary_data: {},
      objectives_percentage_progress_data: {},
    },
    objectives: [],
    key_results: [
      {
        id: 1,
        title: "qwer",
        kpi_metric: "%",
        start: 0.0,
        target: 100.0,
        last_checkin: null,
        owner: {
          id: 1,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
        is_boolean: false,
        due_date: null,
        owner_id: 1,
        can_update: true,
        comments: [],
        objective: {
          id: 1,
          title: "asdf",
          type: "OrganizationObjective",
          is_aspirational: false,
          owner_id: 1,
          can_update: true,
          key_results_count: null,
          sub_objectives_count: null,
          objective_period_id: 1,
        },
      },
    ],
    initiatives: [
      {
        id: 1,
        can_update: true,
        owner: {
          id: 1,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
        tagged_key_result_id: 1,
        is_boolean: true,
        last_checkin: {
          progress: 25,
          datetime: "2021-05-18",
        },
        comments: [],
        title: "description",
        kpi_metric: "%",
        start: 0,
        target: 100,
      },
    ],
  };
};

const getMyOKRsMockApi = () => {
  return {
    summary: {
      group_summary_data: {},
      objectives_percentage_progress_data: { "1": 0 },
    },
    objectives: [
      {
        id: 1,
        title: "asdf",
        type: "OrganizationObjective",
        is_aspirational: false,
        owner_id: 1,
        can_update: true,
        key_results_count: null,
        sub_objectives_count: null,
        objective_period_id: 1,
        owner: {
          id: 1,
          first_name: "Pranav",
          last_name: "PB",
          email: "pranav@beautifulcode.in",
          name: "Pranav PB",
          active: true,
          user_profile_pic_url:
            "https://lh3.googleusercontent.com/-FzjAu9_fyRI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclkXyLwWH8CMXDQJ_V290RGYa_0NQ/s96-c/photo.jpg",
        },
      },
    ],
  };
};

const deleteObjectiveMockApi = () => {
  return {
    message: "Successfully Deleted!",
  };
};

const getObjectiveMockApi = () => {
  return {
    id: 1,
    title: "Test Objective",
    type: "TeamObjective",
    owner: {
      id: "1245",
      first_name: "Abhilash",
      last_name: "K",
      email: "ak@435.com",
      manager_id: null,
    },
    team: {
      id: 1,
      name: "HR",
      owner: {
        id: "1245",
        first_name: "Abhilash",
        last_name: "K",
        email: "ak@435.com",
        manager_id: null,
      },
      active: true,
    },
    objective_period: {
      id: 1,
      name: "Test Period",
      start_date: "01/02/2021",
      end_date: "01/03/2021",
      is_active: true,
    },
    parent_objective: null,
    key_results: [
      {
        id: 1,
        title: "KR 1",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        kpi_metric: "$",
        start: 0,
        target: 1000,
        last_checkin: {
          progress: 10,
          status: "on_track",
        },
      },
      {
        id: 2,
        title: "KR 2",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        kpi_metric: "$",
        start: 0,
        target: 100,
      },
    ],
    sub_objectives: [
      {
        id: 2,
        title: "sub Objective",
      },
      {
        id: 3,
        title: "Another Objective",
      },
      {
        id: 4,
        title: "One more Objective",
      },
    ],
  };
};

const saveObjectiveMockApi = () => {
  return {
    id: 1,
    title: "Test Objective",
    type: "TeamObjective",
    owner: {
      id: "1245",
      first_name: "Abhilash",
      last_name: "K",
      email: "ak@435.com",
      manager_id: null,
    },
    team: {
      id: 1,
      name: "HR",
      owner: {
        id: "1245",
        first_name: "Abhilash",
        last_name: "K",
        email: "ak@435.com",
        manager_id: null,
      },
    },
    objective_period: {
      id: 1,
      name: "Test Period",
      start_date: "01/02/2021",
      end_date: "01/03/2021",
      is_active: true,
    },
    parent_objective: null,
    key_results: [
      {
        id: 1,
        title: "KR 1",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        kpi_metric: "$",
        start: 0,
        target: 1000,
        last_checkin: {
          progress: 10,
          status: "on_track",
        },
      },
      {
        id: 2,
        title: "KR 2",
        owner: {
          id: "1245",
          first_name: "Abhilash",
          last_name: "K",
          email: "ak@435.com",
          manager_id: null,
        },
        kpi_metric: "$",
        start: 0,
        target: 100,
      },
    ],
    sub_objectives: [
      {
        id: 2,
        title: "sub Objective",
      },
    ],
  };
};

const postCheckInMockApi = () => {
  return {
    progress: 10,
    status: "on_track",
  };
};

export {
  getAllObjectivePeriodsMockApi,
  saveObjectivePeriodMockApi,
  deleteObjectivePeriodMockApi,
  getObjectiveConfigMockApi,
  getCompanyObjectivesMockApi,
  deleteObjectiveMockApi,
  getObjectiveMockApi,
  saveObjectiveMockApi,
  postCheckInMockApi,
  getMyOKRsMockApi,
  getOKRDetailsMockApi,
};

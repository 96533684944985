import React, { useState } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Space, Input, Row, Col, Button, Card } from "antd";

import { BoxLoader, ButtonWithSpinner, MultiLineText } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";
import {
  getSurveyParticipationDetailsApi,
  postSurveyResponseApi,
} from "apis/surveyApi";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import {
  errorNotification,
  successNotification,
  formattedDate,
  errorNotificationWithString,
} from "app/appUtils";
import "./SurveyEdit.css";

import styles from "../Survey.module.css";

const { Text, Title } = Typography;

const surveyQuestionTypes = {
  TEXT: "SurveyText",
  MCQ: "SurveyMcq",
};

const SurveyEdit = (props) => {
  const { surveyId } = useParams();
  const history = useHistory();

  const [myResponse, setMyResponse] = useState(null);
  const [myAnswers, setMyAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  const { status, isLoading, isFetching } = useQuery(
    ["getMySurveys", surveyId],
    getSurveyParticipationDetailsApi,
    {
      cacheTime: 1,
      onSuccess: (data) => {
        setMyResponse(data);
        if (data?.responses) {
          setMyAnswers(data?.responses);
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );
  const [postSurveyResponse, { isLoading: isPosting }] = useMutation(
    postSurveyResponseApi,
    {
      onSuccess: (data) => {
        history.push("/surveys");
        if (data?.answered) {
          successNotification("Submitted the survey");
        } else {
          successNotification("Saved the survey");
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const checkHasNotBeenAnswered = (myAnswers, question) => {
    return (
      !myAnswers[question?.id] ||
      !myAnswers[question?.id]?.answer ||
      (question?.type === surveyQuestionTypes.TEXT &&
        myAnswers[question?.id]?.answer === "")
    );
  };

  const handleTextAnswerChange = (value, questionId) => {
    const updatedAnswers = { ...myAnswers };
    if (value) {
      updatedAnswers[questionId] = { answer: `${value}` };
      setMyAnswers({ ...updatedAnswers });
    } else {
      delete updatedAnswers[questionId];
      setMyAnswers({ ...updatedAnswers });
    }
  };

  const handleCommentChange = (questionId, value) => {
    const updatedAnswers = { ...myAnswers };
    if (value || value === "") {
      updatedAnswers[questionId] = {
        ...updatedAnswers[questionId],
        comment: `${value}`,
      };
    } else {
      updatedAnswers[questionId] = {
        ...updatedAnswers[questionId],
        comment: null,
      };
    }
    setMyAnswers({ ...updatedAnswers });
  };

  const handleSelectOption = (questionId, option) => {
    const updatedAnswers = { ...myAnswers };
    updatedAnswers[questionId] = {
      ...updatedAnswers[questionId],
      answer: option,
    };
    setMyAnswers({ ...updatedAnswers });
  };

  const handleSaveAndClose = () => {
    setButtonWithSpinner("save-and-close");
    postSurveyResponse({
      id: surveyId,
      response: myAnswers,
      draft: true,
    });
  };

  const getIsSubmitDisabled = () => {
    let result = false;
    const allQuestions = myResponse?.survey?.questions || [];
    allQuestions.some((question) => {
      const hasNotBeenAnswered = checkHasNotBeenAnswered(myAnswers, question);
      if (question.is_required && hasNotBeenAnswered) {
        result = true;
        return true;
      }
      return false;
    });
    return result;
  };

  const handleSubmitSurvey = () => {
    setButtonWithSpinner("submit-and-update");
    let areAllRequiredQuestionsAnswered = true;
    const allQuestions = myResponse?.survey?.questions || [];
    allQuestions.forEach((question) => {
      const hasNotBeenAnswered = checkHasNotBeenAnswered(myAnswers, question);
      if (question.is_required && hasNotBeenAnswered) {
        areAllRequiredQuestionsAnswered = false;
      }
    });
    setIsSubmitted(true);
    if (areAllRequiredQuestionsAnswered) {
      postSurveyResponse({ id: surveyId, response: myAnswers });
    } else {
      errorNotificationWithString(
        "Some Required questions haven't been answered, please fill all of them"
      );
    }
  };

  const loader = isLoading || isFetching || status === "loading";

  const isReadOnly = !myResponse?.survey?.is_active || false;

  return (
    <>
      <Helmet>
        <title>Surveys - culture.easy</title>
      </Helmet>
      <FullScreenLayout
        source={{ path: "/surveys", name: "Surveys" }}
        title={isReadOnly ? "View Survey" : "Answer the Survey anonymously"}
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            {!myResponse && (
              <Title level={4}>
                Please check the organization for which the survey belongs to
                and retry by switching to that organization!!!
              </Title>
            )}
            <Space direction="vertical" className="width-100-percent">
              <Title level={2}>{myResponse?.survey?.name}</Title>
              <Text
                className="block-display"
                type="secondary"
              >{`Due date: ${formattedDate(
                myResponse?.survey?.due_date
              )}`}</Text>
              {myResponse?.survey?.description && (
                <Text
                  className="block-display"
                  type="secondary"
                >{`Description: ${myResponse?.survey?.description}`}</Text>
              )}

              {myResponse?.survey?.questions?.map((question) => {
                const hasNotBeenAnswered = checkHasNotBeenAnswered(
                  myAnswers,
                  question
                );
                const isError =
                  isSubmitted && hasNotBeenAnswered && question?.is_required;

                const answerValue = `${myAnswers[question?.id]?.answer || ""}`;
                const commentValue = myAnswers[question?.id]
                  ? myAnswers[question?.id].comment || ""
                  : "";

                return (
                  <div className="mt-48">
                    <Row justify="space-between">
                      <span>
                        <Text
                          className={clsx("mr-8", "font-weight-500")}
                        >{`Q${question?.order}. ${question?.description}`}</Text>
                        <Text type="secondary">
                          {question?.is_required ? "" : "(Optional)"}
                        </Text>
                      </span>
                    </Row>
                    {question?.type === surveyQuestionTypes?.TEXT ? (
                      <>
                        {isReadOnly && !answerValue ? (
                          <div className="ml-32">-</div>
                        ) : (
                          <>
                            {isReadOnly ? (
                              <MultiLineText text={answerValue || ""} />
                            ) : (
                              <Input.TextArea
                                placeholder="Your Answer"
                                disabled={isReadOnly}
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                className="input-textarea-resize-none"
                                value={answerValue}
                                onChange={(e) => {
                                  handleTextAnswerChange(
                                    e.target.value,
                                    question?.id
                                  );
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Row className={clsx("mb-16", "mt-8")} justify="center">
                          <Row justify="center" gutter={[30]}>
                            {Object.keys(question.options)?.map((option) => (
                              <Col key={option} className={styles.optionColumn}>
                                <Button
                                  shape="circle"
                                  size="large"
                                  type={
                                    answerValue === option
                                      ? "primary"
                                      : "default"
                                  }
                                  className={
                                    isReadOnly &&
                                    answerValue !== option &&
                                    "read-only-btn"
                                  }
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    border: "1px solid #A3A3A3",
                                    backgroundColor:
                                      answerValue === option
                                        ? isReadOnly
                                          ? "#5330c977"
                                          : "#5330c9"
                                        : "white",
                                    color:
                                      answerValue === option
                                        ? "white"
                                        : "#A3A3A3",
                                  }}
                                  disabled={isReadOnly}
                                  onClick={() => {
                                    if (!isReadOnly) {
                                      handleSelectOption(question?.id, option);
                                    }
                                  }}
                                >
                                  {option}
                                </Button>
                                <Text className={styles.optionText}>
                                  {question.options[option]}
                                </Text>
                              </Col>
                            ))}
                          </Row>
                        </Row>
                        {question?.allow_comments && (
                          <>
                            {isReadOnly && !commentValue ? null : (
                              <>
                                {isReadOnly ? (
                                  <MultiLineText text={commentValue || ""} />
                                ) : (
                                  <Input.TextArea
                                    className={clsx(
                                      "mt-8",
                                      "input-textarea-resize-none"
                                    )}
                                    placeholder="Your Comment (Optional)"
                                    disabled={isReadOnly}
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    value={commentValue}
                                    onChange={(e) => {
                                      handleCommentChange(
                                        question?.id,
                                        e.target.value
                                      );
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isError && <Text type="danger">Required</Text>}
                  </div>
                );
              })}
              {myResponse?.survey?.is_active && (
                <Row className={clsx("mt-32", "mb-16")} justify="end">
                  {!myResponse?.answered && (
                    <ButtonWithSpinner
                      isSpinning={
                        isPosting && buttonWithSpinner === "save-and-close"
                      }
                      spinnerColor={"#5330C9"}
                      disabled={isPosting}
                      className="mr-8"
                      type="primary"
                      ghost={true}
                      variant="contained"
                      onClick={handleSaveAndClose}
                    >
                      Save and Close
                    </ButtonWithSpinner>
                  )}
                  <ButtonWithSpinner
                    isSpinning={
                      isPosting && buttonWithSpinner === "submit-and-update"
                    }
                    type="primary"
                    disabled={getIsSubmitDisabled() || isPosting}
                    onClick={handleSubmitSurvey}
                  >
                    {myResponse?.answered
                      ? "Update your Answers"
                      : "Submit your Answers"}
                  </ButtonWithSpinner>
                </Row>
              )}
            </Space>
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
};

SurveyEdit.propTypes = {};

export default SurveyEdit;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { useQuery, useMutation } from "react-query";
import { pluralize } from "humanize-plus";
import {
  Row,
  Typography,
  Input,
  Modal,
  Col,
  Divider,
  Dropdown,
  Menu,
  Space,
  Button,
} from "antd";
import {
  ExclamationCircleOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { useCurrentUser, useCurrentCompany } from "store";
import { EmployeeAvatar, InlineSpinner } from "components";
import {
  getAllCommentsApi,
  postCommentApi,
  updateCommentApi,
  deleteCommentApi,
} from "apis/praiseApis";
import {
  errorNotification,
  errorNotificationWithString,
  getAuthorNameFromComment,
} from "app/appUtils";

const { Text } = Typography;
const { confirm } = Modal;

const PraiseCommentItem = ({
  canUpdate = true,
  praise = {},
  comment = {},
  refetchComments = () => {},
}) => {
  const [currentUser] = useCurrentUser();

  const [isEdit, setIsEdit] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(comment?.content);

  const commentContent = comment?.content || "";
  useEffect(() => {
    setUpdatedComment(commentContent);
  }, [commentContent]);

  const [updateComment, { status: updateCommentStatus }] = useMutation(
    updateCommentApi,
    {
      onSuccess: () => {
        refetchComments();
      },
      onError: (error) => {
        setUpdatedComment(comment?.content);
        errorNotification(error);
      },
    }
  );

  const [deleteComment, { status: deleteCommentStatus }] = useMutation(
    deleteCommentApi,
    {
      onSuccess: () => {
        refetchComments();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this comment (${comment?.content || ""}).`,
      onOk() {
        deleteComment({ praiseId: praise?.id, commentId: comment?.id });
      },
      okText: "Delete",
      onCancel() {},
    });
  };

  const handleCancel = () => {
    setUpdatedComment(comment?.content);
    setIsEdit(false);
  };

  const handleUpdate = () => {
    if (updatedComment && updatedComment.trim()) {
      setIsEdit(false);
      updateComment({
        praiseId: praise?.id,
        commentId: comment?.id,
        comment: updatedComment,
      });
    } else {
      errorNotificationWithString("Comment cant be empty!");
    }
  };

  const handleChangeComment = (e) => {
    setUpdatedComment(e.target.value);
  };

  const authorName = getAuthorNameFromComment(comment);

  return (
    <div className={"mt-8 mb-16"}>
      <Row justify="space-between" gutter={[16]}>
        <Col xs={{ span: 4 }} lg={{ span: 2 }} className="my-auto">
          <EmployeeAvatar
            employee={{
              name: authorName || "",
              user_profile_pic_url: comment?.user_profile_pic_url || "",
              designation: comment?.designation || "",
              department: comment?.department || "",
              sub_department: comment?.sub_department || "",
            }}
          />
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 14 }} className="ml-8">
          <Space>
            <Text type="secondary" className="block-display comment-name">
              {authorName || ""}
            </Text>
            {(updateCommentStatus === "loading" ||
              deleteCommentStatus === "loading") && (
              <InlineSpinner fontSize={24} />
            )}
          </Space>

          <Moment
            className="ant-typography ant-typography-secondary block-display font-size-14"
            fromNow
          >
            {comment?.created_at || new Date()}
          </Moment>
        </Col>
        <Col span={6}>
          {currentUser?.email === comment?.author_id && (
            <Dropdown
              disabled={!canUpdate}
              overlay={
                <Menu mode="inline">
                  <Menu.Item key="Edit" onClick={handleEdit}>
                    <EditOutlined /> Edit
                  </Menu.Item>
                  <Menu.Item key="delete" onClick={handleDelete}>
                    <DeleteOutlined /> Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="topRight"
            >
              <span className="pl-30">
                <MoreOutlined />
              </span>
            </Dropdown>
          )}
        </Col>
      </Row>
      <Row className="mt-8">
        <Col span={3} />
        <Col span={20} className="ml-8">
          {isEdit ? (
            <>
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 2 }}
                className="input-textarea-resize-none"
                value={updatedComment}
                onChange={handleChangeComment}
              />
              <Row className="mt-8" justify="end">
                <Space>
                  <Button size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button size="small" color="primary" onClick={handleUpdate}>
                    Update
                  </Button>
                </Space>
              </Row>
            </>
          ) : (
            <>
              <Text>{updatedComment}</Text>
              <>
                {comment?.created_at !== comment?.updated_at && (
                  <Text type="secondary" className="ml-8 font-size-14">
                    (edited)
                  </Text>
                )}
              </>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

PraiseCommentItem.propTypes = {
  praise: PropTypes.object,
};

const PraiseComments = ({ canUpdate = true, praise = {} }) => {
  const [currentCompany] = useCurrentCompany();
  const [isExpanded, setIsExpanded] = useState(false);
  const [comments, setComments] = useState(praise?.comments || []);
  const [newComment, setNewComment] = useState("");

  const praiseComments = praise?.comments || [];
  useEffect(() => {
    setComments([...praiseComments]);
  }, [praiseComments]);

  const { status: allCommentsStatus, refetch: refetchAllComments } = useQuery(
    ["getAllComments", praise?.id],
    getAllCommentsApi,
    {
      enabled: false,
      onSuccess: (data) => {
        if (data) {
          setComments([...data]);
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [postComment, { status: postCommentsStatus }] = useMutation(
    postCommentApi,
    {
      onSuccess: () => {
        setNewComment("");
        refetchAllComments();
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleKeyDown = (e) => {
    // KeyCode of enter is 13
    if (e.keyCode === 13) {
      if (e.target.value && e.target.value.trim() !== "") {
        postComment({ praiseId: praise?.id, comment: e.target.value });
      } else {
        errorNotificationWithString("Comment cant be empty");
      }
    }
  };

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const getCommentsLength = () => {
    let commentsLength = 0;
    comments.forEach((comment) => {
      if (!comment.deleted) {
        commentsLength += 1;
      }
    });
    return commentsLength;
  };

  return (
    <div>
      <Row justify="space-around" align="middle" gutter={[8]}>
        <Col span={5}>
          <Divider />
        </Col>
        <Col span={11}>
          <Row justify="center">
            <Button type="link" onClick={handleExpansion} className="px-0">
              {!isExpanded
                ? `${getCommentsLength() || 0} ${pluralize(
                    getCommentsLength() || 0,
                    "comment"
                  )}`
                : "Hide Comments"}
              {(postCommentsStatus === "loading" ||
                allCommentsStatus === "loading") && <InlineSpinner />}
            </Button>
          </Row>
        </Col>
        <Col span={6}>
          <Divider />
        </Col>
      </Row>
      {isExpanded && (
        <>
          {comments?.map((comment) => {
            if (!comment?.deleted) {
              return (
                <PraiseCommentItem
                  praise={praise}
                  canUpdate={canUpdate}
                  comment={comment}
                  refetchComments={refetchAllComments}
                />
              );
            }
            return null;
          })}
        </>
      )}
      {canUpdate && (
        <Row justify="space-around">
          <Col span={2}>
            <EmployeeAvatar employee={currentCompany?.user} />
          </Col>
          <Col span={20} className="ml-16">
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              className="input-textarea-resize-none"
              placeholder="Click to comment..."
              value={newComment}
              onChange={handleNewCommentChange}
              onKeyDown={handleKeyDown}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

PraiseComments.propTypes = {
  praise: PropTypes.object,
};

export default PraiseComments;

import React, { useState } from "react";
import { useMutation, useQueryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { Form, Input, Tabs, Card, PageHeader, Typography } from "antd";
import { pluralize } from "humanize-plus";

import {
  BoxLoader,
  BulkCreateEmployeesStepper,
  ButtonWithSpinner,
  EmployeeSearchSelect,
  OptionalFormItemLabel,
  EmailInput,
} from "components";
import { useSetupCompletionPercentage } from "store";
import { useEmployees } from "hooks";
import { addEmployeeApi } from "apis/commonApi";
import { addEmployeesApi } from "apis/onboardApi";
import {
  errorNotification,
  successNotification,
  errorNotificationWithString,
} from "app/appUtils";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const handleErrors = (data) => {
  const takenEmails = [];
  const emptyFirstNames = [];
  const emptyLastNames = [];
  const emptyEmails = [];
  // eslint-disable-next-line no-unused-expressions
  data?.errors?.forEach((err) => {
    const emailError = err?.email || "";
    const firstNameError = err?.first_name || "";
    const lastNameError = err?.last_name || "";
    if (
      emailError &&
      emailError[0] &&
      emailError[0].error === "taken" &&
      emailError[0]?.value
    ) {
      takenEmails.push(emailError[0]?.value);
    }
    if (emailError && emailError[0] && emailError[0].error === "blank") {
      emptyEmails.push(emailError[0]?.error);
    }
    if (
      firstNameError &&
      firstNameError[0] &&
      firstNameError[0].error === "blank"
    ) {
      emptyEmails.push(firstNameError[0]?.error);
    }
    if (
      lastNameError &&
      lastNameError[0] &&
      lastNameError[0].error === "blank"
    ) {
      emptyEmails.push(lastNameError[0]?.error);
    }
  });
  if (takenEmails.length > 0) {
    const errorMessage = `These users are already present in the directory: ${takenEmails.join(
      ", "
    )}`;
    errorNotificationWithString(errorMessage);
  }
  const totalEmptyRecords =
    emptyEmails.length + emptyFirstNames.length + emptyLastNames.length;
  if (totalEmptyRecords > 0) {
    errorNotificationWithString(
      `Couldn't add ${totalEmptyRecords} records because of insufficient data!`
    );
  }
};

function AddEmployee({
  defaultShouldNavigate = true,
  description = "",
  headerClassName = "",
  headerTitle = "Add People",
  titleLevel = 2,
}) {
  let history = useHistory();
  const queryCache = useQueryCache();

  const [setupCompletionPercentage] = useSetupCompletionPercentage();

  const { activeEmployees, isEmployeesLoading } = useEmployees();

  const [
    addEmployees,
    {
      status: addEmployeesStatus,
      isLoading: addEmployeesIsLoading,
      isFetching: addEmployeesIsFetching,
    },
  ] = useMutation(addEmployeesApi, {
    onSuccess: (data) => {
      let shouldNavigate = defaultShouldNavigate;
      if (data?.saved_employees?.length > 0) {
        successNotification(
          `Successfully Added ${data?.saved_employees?.length || 0} ${
            pluralize(data?.saved_employees?.length, "Employee") || "Employees"
          } `
        );
        if (setupCompletionPercentage !== "100") {
          queryCache.invalidateQueries("getCheckList");
        }
      }
      if (data?.errors?.length > 0) {
        shouldNavigate = false;
        handleErrors(data);
      } else if (data?.saved_employees?.length === 0) {
        successNotification(`Updated Employees`);
      }
      if (shouldNavigate) {
        history.push("/settings/directory");
      }
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    addEmployee,
    {
      status: addEmployeeStatus,
      isLoading: addEmployeeIsLoading,
      isFetching: addEmployeeIsFetching,
    },
  ] = useMutation(addEmployeeApi, {
    onSuccess: () => {
      successNotification("Employee Added Successfully");
      if (setupCompletionPercentage !== "100") {
        queryCache.invalidateQueries("getCheckList");
      }
      if (defaultShouldNavigate) {
        history.push("/settings/directory");
      }
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [selectedCsvEmployees, setSelectedCsvEmployees] = useState([]);

  const [form] = Form.useForm();

  const bulkUploadEmployees = () => {
    addEmployees({
      employees: selectedCsvEmployees,
      isSave: true,
      isOnboarding: false,
    });
  };

  const onFinish = (values) => {
    addEmployee({
      employee: { ...values, manager_id: values.manager || null },
    });
  };

  if (isEmployeesLoading) {
    return <BoxLoader />;
  }

  const isAddEmployeesPosting =
    addEmployeesStatus === "loading" ||
    addEmployeesIsLoading ||
    addEmployeesIsFetching;

  const isAddEmployeePosting =
    addEmployeeStatus === "loading" ||
    addEmployeeIsLoading ||
    addEmployeeIsFetching;

  return (
    <>
      <PageHeader
        className={`pl-0 pt-0 ${description ? "pb-0" : ""} ${headerClassName}`}
        title={
          <Title level={titleLevel} className="mb-0">
            {headerTitle}
          </Title>
        }
      />
      {description && (
        <Text className="block-display mb-16">{description}</Text>
      )}
      <Card>
        <Tabs defaultActiveKey="bulk-create">
          <TabPane tab="Bulk Create" key="bulk-create">
            <BulkCreateEmployeesStepper
              numberOfRows={8}
              isAddEmployeesPosting={isAddEmployeesPosting}
              selectedCsvEmployees={selectedCsvEmployees}
              bulkUploadEmployees={bulkUploadEmployees}
              setSelectedCsvEmployees={setSelectedCsvEmployees}
            />
          </TabPane>
          <TabPane tab="Add a Single Employee" key="add-employee">
            <Form
              form={form}
              layout="vertical"
              name="new_employee"
              onFinish={onFinish}
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Missing First Name",
                  },
                  {
                    whitespace: true,
                    message: "First Name cannot be Empty",
                  },
                ]}
                className="width-65-percent"
                required={false}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Missing Last Name",
                  },
                  {
                    whitespace: true,
                    message: "Last Name cannot be Empty",
                  },
                ]}
                className="width-65-percent"
                required={false}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  { required: true, message: "Missing Email" },
                ]}
                className="width-65-percent"
                required={false}
              >
                <EmailInput />
              </Form.Item>
              <Form.Item
                name="manager"
                label={<OptionalFormItemLabel label="Manager" />}
                className="width-250-px"
              >
                <EmployeeSearchSelect
                  shouldShowAvatar={true}
                  employees={activeEmployees}
                  inActiveEmployees={[]}
                  placeholder="Select Manager"
                />
              </Form.Item>
              <Form.Item
                className="width-65-percent"
                label={<OptionalFormItemLabel label="Employee Code" />}
                name="department"
                required={false}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="width-65-percent"
                label={<OptionalFormItemLabel label="Department" />}
                name="company_employee_id"
                required={false}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="width-65-percent"
                label={<OptionalFormItemLabel label="Sub Department" />}
                name="sub_department"
                required={false}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="width-65-percent"
                label={<OptionalFormItemLabel label="Designation" />}
                name="designation"
                required={false}
              >
                <Input />
              </Form.Item>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <ButtonWithSpinner
                    disabled={isAddEmployeePosting}
                    isSpinning={isAddEmployeePosting}
                    type="primary"
                    htmlType="submit"
                  >
                    Add Employee
                  </ButtonWithSpinner>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
}

export default AddEmployee;

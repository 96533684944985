import axios from "axios";

const getBillingPlanDetailsApi = async () => {
  const { data } = await axios.get("/api/v2/admin/billing");
  return data;
};

const getSetupIntentApi = async () => {
  const { data } = await axios.get("/api/v2/admin/billing/setup_intent");
  return data;
};

const updatePaymentDetailsApi = async ({ payment_details }) => {
  const { data } = await axios.put(
    "/api/v2/admin/billing/update_payment_details",
    {
      payment_details,
    }
  );
  return data;
};

const updatePlanApi = async ({ planName }) => {
  const { data } = await axios.put("/api/v2/admin/billing/group_plan", {
    plan: {
      name: planName,
    },
  });
  return data;
};

const updateSettingApi = async ({ address, currency }) => {
  const { data } = await axios.put("/api/v2/admin/billing/update_setting", {
    address,
    currency,
  });
  return data;
};

export {
  getBillingPlanDetailsApi,
  getSetupIntentApi,
  updatePaymentDetailsApi,
  updatePlanApi,
  updateSettingApi,
};

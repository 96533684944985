import axios from "axios";

const getContinuousFeedbackApi = async (
  _,
  employee_id,
  last_id = null,
  limit = 20
) => {
  const { data } = await axios.post("/api/v2/continuous_feedbacks/show", {
    employee_id,
    last_id,
    limit,
  });
  return data;
};

const postFeedbackApi = async ({ continuous_feedback }) => {
  const { data } = await axios.post("/api/v2/continuous_feedbacks", {
    continuous_feedback,
  });
  return data;
};

const getFeedbackRequestApi = async () => {
  const { data } = await axios.get("/api/v2/continuous_feedback_requests");
  return data;
};

const postRequestFeedbackApi = async ({ continuous_feedback_request }) => {
  const { data } = await axios.post("/api/v2/continuous_feedback_requests", {
    continuous_feedback_request,
  });
  return data;
};

const nudgeRequestorsApi = async ({ requestId }) => {
  const { data } = await axios.put(
    `api/v2/continuous_feedback_requests/${requestId}/nudge`
  );
  return data;
};

const declineRequestApi = async ({ requestUserId }) => {
  const { data } = await axios.put(
    `api/v2/continuous_feedback_requests/${requestUserId}/decline`
  );
  return data;
};

const getRequestResponsesApi = async (_, requestId) => {
  const { data } = await axios.get(
    `/api/v2/continuous_feedback_requests/${requestId}/show`
  );
  return data;
};

export {
  getContinuousFeedbackApi,
  postFeedbackApi,
  getFeedbackRequestApi,
  postRequestFeedbackApi,
  nudgeRequestorsApi,
  declineRequestApi,
  getRequestResponsesApi,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import Tree from "react-d3-tree";
import { linkHorizontal } from "d3-shape";
import {
  Card,
  Typography,
  Row,
  Tooltip,
  Space,
  Modal,
  Button,
  Col,
  Empty,
} from "antd";
import Icon, {
  UserOutlined,
  TeamOutlined,
  // ExportOutlined,
  // MenuUnfoldOutlined,
} from "@ant-design/icons";

import { useCenteredTree } from "hooks";
import {
  EmployeeAvatar,
  InlineSpinner,
  EmployeeAvatarGroup,
  BoxLoader,
} from "components";
import { ReactComponent as InitiativesIcon } from "assets/icons/i_icon.svg";
import { getObjectiveDetailsApi } from "apis/okrV2Api";
import { statusColors } from "./constants";
import {
  getExpectedPercentageFor,
  getStatus,
  getTreeDataFromSectionData,
  getPercentageFromValue,
} from "./utils";
import {
  formattedDate,
  getEmployeeName,
  errorNotification,
} from "app/appUtils";
import { ReactComponent as CompanyIcon } from "assets/icons/company.svg";
import styles from "../OKRs.module.css";

const { Text, Title } = Typography;

const getUpdatedTreeData = (currentNode, nodeId, subObjectives) => {
  if (currentNode?.id === nodeId) {
    const currentChildren = currentNode?.children || [];
    return { ...currentNode, children: [...currentChildren, ...subObjectives] };
  }
  return {
    ...currentNode,
    children: currentNode?.children?.map((node) =>
      getUpdatedTreeData(node, nodeId, subObjectives)
    ),
  };
};

const getOnlyOwnerName = (owner) => {
  const ownerNameArray = getEmployeeName(owner)?.split(" ") || "";

  if (ownerNameArray?.length > 1) {
    const secondWord = ownerNameArray[1];
    return `${ownerNameArray[0]} ${
      secondWord?.length > 0 ? secondWord[0] : ""
    }`;
  } else if (ownerNameArray?.length === 1) {
    return ownerNameArray[0];
  }
  return "";
};

const ObjectiveDetailsModel = ({
  node = {},
  isDetailsVisible = false,
  handleIsDetailsVisible = () => {},
}) => {
  const { data, isLoading, isFetching } = useQuery(
    ["getObjectiveDetails", node?.id],
    getObjectiveDetailsApi
  );

  const loader = isLoading || isFetching;

  console.log({ data });

  return (
    <Modal
      visible={isDetailsVisible}
      onCancel={handleIsDetailsVisible}
      footer={null}
      width={"600px"}
      title={
        <Tooltip title={node?.title}>
          <Title level={4}>
            Title: {node?.title?.substring(0, 45)}
            {node?.title?.length > 44 && "...."}
          </Title>
        </Tooltip>
      }
    >
      {loader && <BoxLoader height="10vh" />}
      <div
        className={styles.treeKeyResultsList}
        style={{ maxHeight: "400px", overflow: "auto" }}
      >
        {data?.key_results?.map((kr) => {
          const currentProgress = kr?.last_checkin
            ? kr?.last_checkin?.progress
            : kr?.start || 0;
          return (
            <Row className="mb-8" justify="space-between" align="middle">
              <Space>
                <EmployeeAvatar employee={kr?.owner || {}} />
                <Text type="secondary">KR</Text>
                <Tooltip title={kr?.title}>
                  <Text>
                    {kr?.title?.substring(0, 45)}
                    {kr?.title?.length > 44 && "...."}
                  </Text>
                </Tooltip>
              </Space>
              <Text style={{ color: statusColors[kr?.last_checkin?.status] }}>
                {Math.round(
                  getPercentageFromValue(
                    currentProgress,
                    kr?.start || 0,
                    kr?.target || 100
                  )
                ) || 0}
                %
              </Text>
            </Row>
          );
        })}
        {data?.initiatives?.map((initiative) => (
          <Row className="mb-8" justify="space-between" align="middle">
            <Space>
              <EmployeeAvatar employee={initiative?.owner || {}} />
              <InitiativesIcon
                className="vertical-align-middle"
                // component={InitiativesIcon}
              />
              <Tooltip title={initiative?.title}>
                <Text>
                  {initiative?.title?.substring(0, 45)}
                  {initiative?.title?.length > 44 && "...."}
                </Text>
              </Tooltip>
            </Space>
            <Text style={{ color: "#007bff" }}>
              {initiative?.last_checkin?.progress || 0}%
            </Text>
          </Row>
        ))}
        {((!loader && !data) ||
          (data?.key_results?.length === 0 &&
            data?.initiatives?.length === 0)) && <Empty />}
      </div>
    </Modal>
  );
};

const OKRNode = ({ node }) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const expectedProgress = getExpectedPercentageFor(node?.selectedPeriodObj);
  const status = getStatus(Math.round(node?.progress || 0), expectedProgress);

  const owners = node?.owners || [];

  const handleIsDetailsVisible = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  return (
    <>
      {isDetailsVisible && (
        <ObjectiveDetailsModel
          node={node}
          isDetailsVisible={isDetailsVisible}
          handleIsDetailsVisible={handleIsDetailsVisible}
        />
      )}
      <Card
        className="okr-tree-view-card-v2 align-items-center"
        style={{ width: "640px", height: "99px" }}
      >
        {node?.node_type === "time_period" ? (
          <div>
            <Title level={4}>Time Period</Title>
            <Title level={3}>{node?.selectedPeriodObj?.name}</Title>
            <Text type="secondary">{`(${formattedDate(
              node?.selectedPeriodObj?.start_date
            )} - ${formattedDate(node?.selectedPeriodObj?.end_date)})`}</Text>
          </div>
        ) : (
          <>
            <Row justify="space-between" align="middle">
              <Col
                span={4}
                className="flex-display flex-direction-column justify-content-center"
              >
                <div className="flex-display align-items-center">
                  <EmployeeAvatarGroup
                    divClassName="height-32-px"
                    employees={node?.owners}
                    onClick={(e) => {}}
                  />
                  {node?.type === "OrganizationObjective" && (
                    <Icon
                      style={{ strokeWidth: "0.1px" }}
                      component={CompanyIcon}
                    />
                  )}
                  {node?.type === "IndividualObjective" && <UserOutlined />}
                  {node?.type === "TeamObjective" && <TeamOutlined />}
                </div>
                <div className="mt-8">
                  {owners?.length > 1 ? (
                    <Tooltip
                      title={owners
                        ?.map((owner) => getEmployeeName(owner))
                        ?.join(", ")}
                    >
                      <Text type="secondary" className="block-display">
                        {`${owners?.length} owners`}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text type="secondary" className="block-display">
                      {getOnlyOwnerName(owners[0])}
                    </Text>
                  )}
                </div>
              </Col>
              <Col span={16}>
                <Tooltip title={node?.title}>
                  <Text>
                    {node?.title?.substring(0, 80)}
                    {node?.title?.length > 79 && "...."}
                  </Text>
                </Tooltip>
              </Col>
              <Col
                span={3}
                className="flex-display flex-direction-column align-items-center"
              >
                <Title style={{ color: statusColors[status] }} level={4}>
                  {Math.round(node?.progress || 0) || 0}%
                </Title>
                <Button
                  size="small"
                  type="primary"
                  ghost={true}
                  onClick={handleIsDetailsVisible}
                >
                  KRs
                </Button>
              </Col>
            </Row>

            {/* {node?.key_results_count === 0 && (
            <Text className="mt-8 block-display" type="secondary">
              No Key Results
            </Text>
          )}
          <div
            className={styles.treeKeyResultsList}
            style={{ height: "80px", overflow: "auto" }}
          >
            {node?.key_results?.map((kr) => (
              <Row className="mb-8" justify="space-between" align="middle">
                <Space>
                  <EmployeeAvatar employee={kr?.owner || {}} />
                  <Tooltip title={kr?.title}>
                    <Text>
                      {kr?.title?.substring(0, 55)}
                      {kr?.title?.length > 54 && "...."}
                    </Text>
                  </Tooltip>
                </Space>
                <Text style={{ color: statusColors[kr?.last_checkin?.status] }}>
                  {kr?.last_checkin?.progress || 0}%
                </Text>
              </Row>
            ))}
          </div> */}
          </>
        )}
      </Card>
    </>
  );
};
OKRNode.propTypes = {
  nodeDatum: PropTypes.object,
};

const ToggleText = ({
  subObjectiveCount = 0,
  childrenLength = 0,
  isCollapsed = true,
}) => {
  if (subObjectiveCount === childrenLength && childrenLength > 0) {
    return isCollapsed ? subObjectiveCount : "-";
  }
  if (subObjectiveCount > 0 && childrenLength === 0) {
    return subObjectiveCount;
  }
  return subObjectiveCount;
};
ToggleText.propTypes = {
  subObjectiveCount: PropTypes.number,
  childrenLength: PropTypes.number,
  isCollapsed: PropTypes.bool,
};

const getShouldShowCount = (subObjectiveCount, childrenLength, isCollapsed) => {
  if (subObjectiveCount === childrenLength && childrenLength > 0) {
    return isCollapsed ? true : false;
  }
  if (subObjectiveCount > 0 && childrenLength === 0) {
    return true;
  }
  return true;
};

const renderForeignObjectNode = ({
  treeData,
  loaderNodeId,
  nodeDatum,
  selectedPeriodObj,
  toggleNode,
  foreignObjectProps,
  setTreeData = () => {},
  setNodeId = () => {},
}) => {
  const subObjectiveCount = nodeDatum?.sub_objectives_count || 0;
  const isCollapsed = nodeDatum?.__rd3t?.collapsed;
  const childrenLength = nodeDatum?.children?.length || 0;

  const shouldShowCount = getShouldShowCount(
    subObjectiveCount,
    childrenLength,
    isCollapsed
  );

  const onToggle = () => {
    if (
      nodeDatum?.node_type !== "time_period" &&
      nodeDatum?.children?.length === 0
    ) {
      setNodeId(nodeDatum?.id);
    }
    if (nodeDatum?.children?.length > 0) {
      toggleNode();
    }
  };

  const isSubObjectiveCountNotZero = subObjectiveCount > 0;

  return (
    <g>
      {(isSubObjectiveCountNotZero ||
        nodeDatum?.node_type === "time_period") && (
        <circle
          r={10}
          style={{ fill: "#5330C9", opacity: ".2" }}
          onClick={onToggle}
        ></circle>
      )}
      {nodeDatum?.node_type === "time_period" ? (
        <text
          onClick={onToggle}
          transform={isCollapsed ? "translate(-4, 4)" : "translate(-2, 3)"}
          style={{ color: "#5330C9", fontSize: "13px" }}
        >
          {isCollapsed ? childrenLength : "-"}
        </text>
      ) : (
        <>
          {loaderNodeId === nodeDatum?.id ? (
            <>
              {isSubObjectiveCountNotZero && (
                <foreignObject
                  height={"26px"}
                  width={"13px"}
                  transform={"translate(-7, -12)"}
                >
                  <InlineSpinner fontSize={13} color="black" />
                </foreignObject>
              )}
            </>
          ) : (
            <>
              {isSubObjectiveCountNotZero && (
                <text
                  onClick={onToggle}
                  transform={
                    shouldShowCount ? "translate(-4, 4)" : "translate(-2, 3)"
                  }
                  style={{ color: "#5330C9", fontSize: "13px" }}
                >
                  {shouldShowCount ? subObjectiveCount : "-"}
                </text>
              )}
            </>
          )}
        </>
      )}
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject
        {...foreignObjectProps}
        width={
          nodeDatum?.node_type === "time_period"
            ? 320
            : foreignObjectProps?.width
        }
        x={
          nodeDatum?.node_type === "time_period" ? -325 : foreignObjectProps?.x
        }
        height={
          foreignObjectProps?.height
          // nodeDatum?.node_type === "time_period"
          //   ? 120
          //   : 97 +
          //     (nodeDatum?.key_results_count <= 2
          //       ? 40 * (nodeDatum?.key_results_count || 1)
          //       : 160) // Dynamic height of OKR Node card's foreign object
        }
        y={
          foreignObjectProps?.y
          // nodeDatum?.node_type === "time_period"
          //   ? -60
          //   : -(
          //       97 +
          //       (nodeDatum?.key_results_count <= 2
          //         ? 40 * (nodeDatum?.key_results_count || 1)
          //         : 160)
          //     ) / 2
        }
      >
        <div
          style={{
            position: "fixed",
            border: "2px solid #e0e4ea",
            borderLeft: "4px solid #5330C9",
            backgroundColor: "white",
          }}
        >
          <OKRNode
            currentTreeData={treeData}
            node={nodeDatum}
            selectedPeriodObj={selectedPeriodObj}
            onToggle={toggleNode}
            setTreeData={setTreeData}
          />
        </div>
      </foreignObject>
    </g>
  );
};

const OKRTreeViewV2 = ({ data = {}, selectedPeriodObj = {} }) => {
  const nodeSize = { x: 640, y: 99 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -(nodeSize.x + 10),
    y: -(nodeSize.y / 2),
  };

  const [treeData, setTreeData] = useState(data);
  const [nodeId, setNodeId] = useState(null);
  const [translate, containerRef] = useCenteredTree(treeData?.children?.length);

  const { isLoading, isFetching } = useQuery(
    ["getObjectiveDetails", nodeId, true],
    getObjectiveDetailsApi,
    {
      enabled: Boolean(nodeId),
      onSuccess: (details) => {
        setTreeData(
          getUpdatedTreeData(
            treeData,
            nodeId,
            getTreeDataFromSectionData(
              details?.objectives || [],
              selectedPeriodObj,
              details?.summary?.objectives_percentage_progress_data
            ) || []
          )
        );
        setNodeId(null);
        return details;
      },
      onError: (err) => {
        setNodeId(null);
        errorNotification(err);
      },
    }
  );

  const straightPathFunc = (linkDatum, orientation) => {
    const { source, target } = linkDatum;

    return linkHorizontal()({
      source: [source.y + 8, source.x],
      target: [target.y - (foreignObjectProps?.width + 10), target.x],
    });
  };

  const pathClassFunc = ({ source }) => {
    if (source?.data?.node_type === "time_period") {
      return "display-none";
    }

    return styles?.horizontalOKRTreePath;
  };

  useEffect(() => {
    setTreeData(data);
  }, [data]);

  const loader = isLoading || isFetching;

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <Tree
        data={treeData}
        // initialDepth={1}
        translate={translate}
        nodeSize={nodeSize}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({
            ...rd3tProps,
            treeData,
            selectedPeriodObj,
            foreignObjectProps,
            setTreeData,
            loaderNodeId: nodeId,
            loader,
            setNodeId,
          })
        }
        // zoom={0.8}
        rootNodeClassName="display-none"
        separation={{ nonSiblings: 1.1, siblings: 1.1 }}
        depthFactor={750}
        enableLegacyTransitions={true}
        pathClassFunc={pathClassFunc}
        pathFunc={straightPathFunc}
        // orientation="horizontal"
      />
    </div>
  );
};

OKRTreeViewV2.propTypes = {
  data: PropTypes.object,
};

export default OKRTreeViewV2;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Button, Divider } from "antd";

import {
  ButtonWithSpinner,
  TreeTable,
  EmployeeAvatarWithName,
  EmployeeSearchSelect,
  BoxLoader,
} from "components";
import {
  getInitialTreeData,
  getInitialTreeDataForPerfReview,
} from "app/company/common/utils";

const prepareColumns = (
  employees,
  inActiveEmployees,
  managerMap,
  setManagerMappings,
  setParticipants
) => [
  {
    title: "Employee",
    dataIndex: "key",
    key: "key",
    render: (data, row) => {
      // return <Text>{getNameFromEmployeeObj(row)}</Text>;
      return (
        <>
          <EmployeeAvatarWithName employee={row} shouldShowInactive={true} />
        </>
      );
    },
  },
  {
    title: "Reviewer",
    render: (data, row) => {
      const handleChange = (managerId) => {
        const empId = data?.id;
        const newManagerMapping = { ...managerMap };
        newManagerMapping[empId] = managerId;
        setManagerMappings({ ...newManagerMapping });
        setParticipants((participants) =>
          participants?.map((participant) => ({
            ...participant,
            reviewer_id: newManagerMapping[participant?.reviewee_id],
          }))
        );
      };

      return (
        <EmployeeSearchSelect
          value={managerMap[data?.id]}
          onChange={handleChange}
          shouldShowAvatar={true}
          placeholder="Select Reviewer"
          className={"width-250-px"}
          defaultValue={managerMap ? managerMap[data?.id] : null}
          employees={employees?.filter((emp) => emp?.id !== data?.id)}
          inActiveEmployees={inActiveEmployees}
        />
      );
    },
  },
];

const BulkAddReviews = ({
  isAddingParticipants = false,
  activeEmployees = [],
  inActiveEmployees = [],
  reviewCycleId = null,
  addReviewCycleParticipants = () => {},
  handleCancel = () => {},
}) => {
  const [participants, setParticipants] = useState([]);
  const [managerMappings, setManagerMappings] = useState({});
  const [loader, setLoader] = useState(false);

  const [treeData, setTreeData] = useState(
    getInitialTreeData(activeEmployees, inActiveEmployees)
  );
  const [initialTreeData, setInitialTreeData] = useState([]);

  useEffect(() => {
    setLoader(true);
    const value = {};
    activeEmployees.forEach((employee) => {
      value[employee.id] = employee.manager_id;
    });
    setManagerMappings(value);

    setInitialTreeData(
      getInitialTreeDataForPerfReview(value, activeEmployees, inActiveEmployees)
    );
    setLoader(false);
  }, [activeEmployees, inActiveEmployees]);

  const handleEmployeeSelection = (selectedRowKeys, managerMappings) => {
    const reviews = selectedRowKeys?.map((key) => ({
      reviewee_id: key,
      reviewer_id: managerMappings[key] || null,
    }));
    setParticipants(reviews);
  };

  const handleResetSearch = () => {
    setTreeData(initialTreeData);
  };

  const handleSelect = (updatedKeys) => {
    handleEmployeeSelection(updatedKeys, managerMappings);
  };

  const handleSelectAll = (employeeIds) => {
    handleEmployeeSelection(employeeIds, managerMappings);
  };

  const handleAddReviews = () => {
    addReviewCycleParticipants({
      participants: participants || [],
      reviewCycleId,
    });
  };

  if (loader) {
    return <BoxLoader />;
  }

  return (
    <div>
      <TreeTable
        selections={participants}
        selectedRowKeys={participants?.map((review) => review?.reviewee_id)}
        initialTreeData={initialTreeData}
        activeEmployees={activeEmployees}
        inActiveEmployees={inActiveEmployees}
        columns={prepareColumns(
          activeEmployees,
          inActiveEmployees,
          managerMappings,
          setManagerMappings,
          setParticipants
        )}
        dataSource={treeData}
        setTreeData={setTreeData}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        handleResetSearch={handleResetSearch}
      />

      <Divider className="my-8" />
      <Row>
        <ButtonWithSpinner
          disabled={isAddingParticipants}
          isSpinning={isAddingParticipants}
          type="primary"
          onClick={handleAddReviews}
        >
          Add Participants
        </ButtonWithSpinner>

        <Button className="ml-8" type="primary" ghost onClick={handleCancel}>
          Cancel
        </Button>
      </Row>
    </div>
  );
};

BulkAddReviews.propTypes = { handleCancel: PropTypes.func };

export default BulkAddReviews;

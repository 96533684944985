import React, { useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Typography,
  Button,
  Row,
  Col,
  Divider,
  Space,
  Select,
  Input,
  Switch,
  Checkbox,
  Modal,
} from "antd";
import Icon, { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ReactComponent as Drag } from "assets/icons/drag.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import PreviewModal from "./PreviewModal";

import styles from "../../../Survey.module.css";

const { Text, Title } = Typography;
const { confirm } = Modal;

const surveyQuestionTypes = {
  TEXT: "SurveyText",
  MCQ: "SurveyMcq",
};

const questionTypes = [
  {
    name: "Free Text",
    id: surveyQuestionTypes.TEXT,
  },
  {
    name: "Multiple Choice Question",
    id: surveyQuestionTypes.MCQ,
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((question, index) => ({ ...question, order: index + 1 }));
};

const NumberOptions = ({ options = {}, handleOptions = () => {} }) => {
  const handleOptionDescriptionChange = (key, value) => {
    const updatedOptions = { ...options };
    updatedOptions[key] = value;
    handleOptions(updatedOptions);
  };

  const handleAddOption = () => {
    const updatedOptions = { ...options };
    const keys = Object.keys(updatedOptions);
    let lastKey = 0;
    if (keys?.length) {
      const lastKeyId = keys.length - 1;
      lastKey = keys[lastKeyId > 0 ? lastKeyId : 0];
      lastKey = Number(lastKey);
      if (isNaN(lastKey)) {
        lastKey = 0;
      }
    }
    updatedOptions[lastKey + 1] = "Option Text";
    handleOptions(updatedOptions);
  };

  const handleRemoveLastOption = () => {
    const updatedOptions = { ...options };
    delete updatedOptions[Object.keys(options)?.length];
    handleOptions(updatedOptions);
  };

  return (
    <Row className="mb-16" gutter={[16, 8]} justify="center">
      {Object.keys(options)?.map((option) => (
        <Col key={option} span={4} className={styles.createOptionCol}>
          <Button
            shape="circle"
            size="large"
            className={styles.createOptionBtn}
          >
            {option}
          </Button>
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 2 }}
            className={styles.createOptionTextArea}
            value={options[option]}
            onChange={(e) => {
              handleOptionDescriptionChange(option, e.target.value);
            }}
          />
          {Number(option) > 2 &&
            Number(option) === Object.keys(options)?.length && (
              <Button type="link" danger onClick={handleRemoveLastOption}>
                Remove
              </Button>
            )}
        </Col>
      ))}
      <Col key="add-option" span={4} className={styles.createOptionCol}>
        <Button
          shape="circle"
          size="large"
          className={styles.createOptionBtn}
          onClick={handleAddOption}
        >
          <PlusOutlined />
        </Button>
      </Col>
    </Row>
  );
};

const Question = ({
  mode = "",
  index = 0,
  question = {},
  provided,
  handleUpdateQuestion = () => {},
  handleDeleteQuestion = () => {},
}) => {
  const [error, setError] = useState("");

  const type = question?.type || surveyQuestionTypes.TEXT;
  const description = question?.description || "";
  const allow_comments = question?.allow_comments || false;
  const is_required = question?.is_required || false;
  const options = question?.options || {};

  const handleTypeChange = (value) => {
    if (type === surveyQuestionTypes.MCQ) {
      const updatedQuestions = { ...question, type: value };
      delete updatedQuestions.options;
      handleUpdateQuestion(updatedQuestions);
    } else {
      handleUpdateQuestion({
        ...question,
        type: value,
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
      });
    }
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.trim() === "") {
      setError("Question cant be empty");
    }
    handleUpdateQuestion({ ...question, description: e.target.value });
  };

  const handleAllowCommentsChange = (e) => {
    handleUpdateQuestion({ ...question, allow_comments: e.target.checked });
  };

  const handleIsRequired = (checked) => {
    handleUpdateQuestion({ ...question, is_required: checked });
  };

  const handleOptions = (updatedOptions) => {
    handleUpdateQuestion({ ...question, options: { ...updatedOptions } });
  };

  return (
    <>
      <Row className="mb-16">
        <Col span={18}>
          <Space>
            <Text>{index + 1}.</Text>
            <Select
              disabled={mode === "edit" && question?.id}
              className={styles.questionTypeSelectWidth}
              value={type}
              onChange={handleTypeChange}
            >
              {questionTypes.map((type) => (
                <Select.Option value={type.id}>{type?.name}</Select.Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col span={6}>
          <Row justify="end">
            <Space>
              <Space>
                <Text type="secondary">Required</Text>
                <Switch checked={is_required} onChange={handleIsRequired} />
              </Space>
              <Icon
                className="cursor-move"
                component={Drag}
                {...provided.dragHandleProps}
              />
              <Icon
                component={Delete}
                onClick={() => {
                  handleDeleteQuestion(question?.order);
                }}
              />
            </Space>
          </Row>
        </Col>
      </Row>

      <Row className="mb-16" align="middle">
        <Text type="secondary" className="mr-8">
          Question
        </Text>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          className={styles.questionDescTextArea}
          value={description}
          onChange={handleDescriptionChange}
        />
      </Row>
      {(!description || description?.trim()?.length === 0) && (
        <div>
          <Text className="mt-8" type="danger">
            {error}
          </Text>
        </div>
      )}

      {type === surveyQuestionTypes.MCQ && (
        <>
          <NumberOptions options={options} handleOptions={handleOptions} />
          <Space>
            <Checkbox
              checked={allow_comments}
              onChange={handleAllowCommentsChange}
            >
              <Text type="secondary">Allow optional comments</Text>
            </Checkbox>
          </Space>
        </>
      )}

      <Divider />
    </>
  );
};
Question.propTypes = {
  question: PropTypes.object,
  provided: PropTypes.any,
  handleUpdateQuestion: PropTypes.func,
  handleDeleteQuestion: PropTypes.func,
};

const ComposeQuestionnaireForm = ({
  mode = "",
  survey = {},
  questions = [],
  template = {},
  handleSelectedTemplate = () => {},
  setFieldsValue = () => {},
}) => {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const handlePreviewDialog = () => {
    setIsPreviewDialogOpen(!isPreviewDialogOpen);
  };

  const handleUpdateQuestion = (question = {}) => {
    const updatedQuestions = questions.map((qst) => {
      if (qst?.order === question?.order) {
        return { ...question };
      }
      return { ...qst };
    });
    setFieldsValue({ questions: updatedQuestions });
  };

  const handleAddNewQuestion = () => {
    const updatedQuestions = [
      ...questions,
      {
        order: questions.length + 1,
        type: surveyQuestionTypes.TEXT,
        description: "",
        allow_comments: false,
        is_required: true,
        options: {
          "1": "Strongly Disagree",
          "2": "Partially disagree",
          "3": "Not sure",
          "4": "Partially agree",
          "5": "Strongly Agree",
        },
      },
    ];
    setFieldsValue({ questions: updatedQuestions });
  };

  const handleDeleteQuestion = (order) => {
    const filteredQuestions = questions.filter((qst) => qst?.order !== order);
    const updatedQuestions = filteredQuestions.map((question, index) => ({
      ...question,
      order: index + 1,
    }));
    setFieldsValue({ questions: updatedQuestions });
  };

  const handleGoBack = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Any changes made to the current questionnaire will be lost.",
      onOk() {
        handleSelectedTemplate(null);
        setFieldsValue({ questions: [] });
      },
      okText: "Go Back",
      onCancel() {},
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    setFieldsValue({ questions: orderedQuestions });
  };

  return (
    <>
      <PreviewModal
        visible={isPreviewDialogOpen}
        survey={survey}
        questions={questions}
        handleCancel={handlePreviewDialog}
      />
      <Space className="mt-8 width-100-percent" direction="vertical">
        <Space
          className="cursor-pointer"
          onClick={() => {
            handleGoBack();
          }}
        >
          <ArrowLeftOutlined />
          <Text type="secondary">back to templates</Text>
        </Space>
        <Row className="mb-16" justify="space-between">
          <Title level={3}>{template?.name || "Custom Questionnaire"}</Title>
          <Button
            size="small"
            type="primary"
            ghost={true}
            onClick={handlePreviewDialog}
          >
            Preview Survey
          </Button>
        </Row>
        {questions?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {questions?.map((question, index) => (
                    <Draggable draggableId={index.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Question
                            mode={mode}
                            index={index}
                            question={question}
                            handleUpdateQuestion={handleUpdateQuestion}
                            handleDeleteQuestion={handleDeleteQuestion}
                            provided={provided}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Row justify="center">
          <Button type="primary" ghost={true} onClick={handleAddNewQuestion}>
            Add Question
          </Button>
        </Row>
        <Divider />
      </Space>
    </>
  );
};

ComposeQuestionnaireForm.propTypes = {
  mode: PropTypes.string,
  questions: PropTypes.array,
  handleSelectedQuestions: PropTypes.func,
};

export default ComposeQuestionnaireForm;

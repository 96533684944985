import axios from "axios";

const getUsersApi = async () => {
  const { data, status } = await axios.get("/api/v2/admin/employees");
  return { data, status };
};

const getHprSurveyDetailsApi = async (_, id) => {
  const { data } = await axios.get(`/api/v2/admin/hpr_surveys/${id}`);
  return data;
};

const getHprResponseAnalyticsForQuestion = async (_, input_data) => {
  const { data } = await axios.get(`/api/v2/admin/hpr_surveys/${input_data.hpr_survey_id}/questions/${input_data.question_id}`);
  return data;
};

const getHprSurveysApi = async () => {
  const { data } = await axios.get("/api/v2/admin/hpr_surveys/");
  return data;
};

const getHprSurveyAllDeptDetailsApi = async (_, input_data) => {
  console.log("what we got", input_data)
  const { data } = await axios.get(`api/v2/admin/hpr_surveys/${input_data.hpr_survey_id}/departments/${input_data.analytics_dept}`);
  return data;
};

const getHprSurveyIndivDeptDetailsApi = async (id, dept_name) => {
  const { data } = await axios.get(`api/v2/admin/hpr_surveys/${id}/departments/${dept_name}`);
  return data;
};

const createHprSurveyApi = async (hpr_survey) => {
  const { data } = await axios.post("/api/v2/admin/hpr_surveys/", { hpr_survey });
  return data;
};

const notifyParticipantsApi = async (id) => {
  const { data } = await axios.put(
    `/api/v2/admin/hpr_surveys/${id}/notify_pending_participants`
  );
  return data;
};

const closeHprSurveyApi = async (id) => {
  const { data } = await axios.put(
    `/api/v2/admin/hpr_surveys/${id}/close`
  );
  return data;
};

const getMyHprSurveysApi = async () => {
  const { data } = await axios.get("/api/v2/my_hpr_survey_participations");
  return data;
};

const getHprSurveyParticipationDetailsApi = async (_, id) => {
  const { data } = await axios.get(`/api/v2/my_hpr_survey_participations/${id}`);
  return data;
};

const postHprSurveyResponseApi = async ({ id, response, is_draft = false }) => {
  const { data } = await axios.post(`/api/v2/my_hpr_survey_participations/${id}`, {
    response,
    is_draft,
  });
  return data;
};

export {
  getHprSurveyDetailsApi,
  getHprSurveysApi,
  createHprSurveyApi,
  getUsersApi,
  notifyParticipantsApi,
  getMyHprSurveysApi,
  getHprSurveyParticipationDetailsApi,
  postHprSurveyResponseApi,
  closeHprSurveyApi,
  getHprSurveyAllDeptDetailsApi,
  getHprSurveyIndivDeptDetailsApi,
  getHprResponseAnalyticsForQuestion
};

import React, { useState } from "react";
// import PropTypes from "prop-types";
// import * as PapaParse from "papaparse";
import { useQuery, useMutation } from "react-query";
import {
  Alert,
  Typography,
  Upload,
  Button,
  Card,
  Steps,
  Row,
  Col,
  Tabs,
  Table,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import {
  OKRTimePeriodSelect,
  BoxLoader,
  ButtonWithSpinner,
  EmployeeAvatar,
  EmployeeAvatarGroup,
} from "components";
import { ReactComponent as InitiativesIcon } from "assets/icons/i_icon.svg";
// import OKRNavBar from "../common/OKRNavBar";
import {
  getObjectivePeriodsApi,
  checkBulkUploadOkrApi,
  performBulkUploadOkrApi,
} from "apis/okrV2Api";
// import { parseObjectivesCsv } from "../common/utils";
import {
  successNotification,
  errorNotification,
  errorNotificationWithString,
} from "app/appUtils";

const { Title, Text, Link } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;

const UploadCsv = ({
  periods = [],
  csvFileList = [],
  selectedPeriodId,
  handleSelectedPeriodChange = () => {},
  setCsvFileList = () => {},
  checkBulkUpload,
}) => {
  // const [fileData, setFileData] = useState(null);

  return (
    <>
      <Title level={3}>Upload XLSX File that has the OKRs.</Title>
      <div className="mb-24">
        <Title level={3} className="inline-display">
          Use the
        </Title>
        <Title level={3} className="inline-display">
          <Link href="/sample_okrs.xlsx" className="mx-8 inline-display">
            sample XLSX file link
          </Link>
        </Title>
        <Title level={3} className="inline-display">
          to download template.
        </Title>
      </div>
      <Upload
        accept={
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        name="file"
        beforeUpload={(file, fileList) => {
          setCsvFileList(fileList);
          return false;
        }}
        fileList={csvFileList}
        previewFile={(file) => {
          console.log(file);
        }}
        onRemove={() => {
          setCsvFileList([]);
        }}
      >
        <Button
          icon={<UploadOutlined />}
          size="large"
          type="primary"
          ghost={true}
        >
          Upload OKRs
        </Button>
      </Upload>

      <Title level={5} className="mt-50 block-display">
        Select the OKR period to add the uploaded the OKR File.
      </Title>

      <OKRTimePeriodSelect
        className="width-400-px mb-48"
        periods={periods}
        selectedPeriodId={selectedPeriodId}
        handleSelectedPeriodChange={handleSelectedPeriodChange}
      />
    </>
  );
};
UploadCsv.propTypes = {};

const PreviewCsv = ({ errors = [], newOkrs = [] }) => {
  return (
    <>
      <Title level={5}>
        Your file hasn’t been applied yet. Below is the preview of OKRs in the
        file.
      </Title>
      <Tabs
        className="mb-16"
        defaultActiveKey={errors?.length > 0 ? "errors" : "new-okrs"}
      >
        <TabPane tab={`Errors (${errors?.length})`} key="errors">
          <Table
            columns={[
              {
                title: "Sl. No",
                dataIndex: "index",
                key: "index",
                render: (text, row, index) => {
                  return <Text>{index + 1}</Text>;
                },
                width: "15%",
              },
              {
                title: "OKR Title",
                dataIndex: "objective",
                key: "objective",
                width: "25%",
              },
              {
                title: "Errors",
                dataIndex: "errors",
                key: "errors",
                render: (value) => {
                  return (
                    <>
                      {value?.map((error, index) => (
                        <Text className="block-display">{`${
                          index + 1
                        }. ${error}`}</Text>
                      ))}
                    </>
                  );
                },
              },
            ]}
            scroll={{ y: 240 }}
            dataSource={errors}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        </TabPane>
        <TabPane tab={`New OKRs (${newOkrs?.length})`} key="new-okrs">
          <Table
            scroll={{ y: 240 }}
            columns={[
              {
                title: "Sl. No",
                dataIndex: "index",
                key: "index",
                render: (text, row, index) => {
                  return <Text>{index + 1}</Text>;
                },
                width: "15%",
              },
              {
                title: "OKR Title",
                dataIndex: "title",
                key: "title",
                width: "25%",
                render: (val, row) => {
                  return (
                    <Space>
                      <EmployeeAvatarGroup employees={row?.owners || []} />
                      <Text>{val}</Text>
                    </Space>
                  );
                },
              },
              {
                title: "Key Results & Initiatives",
                dataIndex: "key_results",
                key: "key_results",
                render: (value, row) => {
                  return (
                    <Space direction="vertical">
                      {value?.map((keyResult, index) => (
                        <Space>
                          <Text type="secondary">KR</Text>
                          <EmployeeAvatar employee={keyResult?.owner} />
                          <Text>{keyResult?.title}</Text>
                        </Space>
                      ))}
                      {row?.initiatives?.map((initiative) => (
                        <Space>
                          <InitiativesIcon
                            className="vertical-align-middle"
                            // component={InitiativesIcon}
                          />
                          <EmployeeAvatar employee={initiative?.owner} />
                          <Text>{initiative?.title}</Text>
                        </Space>
                      ))}
                    </Space>
                  );
                },
              },
            ]}
            dataSource={newOkrs}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
PreviewCsv.propTypes = {};

const OKRBulkUpload = (props) => {
  const {
    data: periods,
    status: periodStatus,
    isLoading: isPeriodLoading,
    isFetching: isPeriodFetching,
  } = useQuery("getAllObjectivePeriods", getObjectivePeriodsApi, {
    onSuccess: (periods) => {
      const currentPeriod = periods
        ? periods?.filter((period) => period?.is_active)[0]
        : null;
      if (!selectedPeriodId) {
        setSelectedPeriodId(currentPeriod?.id);
      }
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const [
    checkBulkUpload,
    { data: checkBulkUploadData, isLoading: isBulkUploadChecking },
  ] = useMutation(checkBulkUploadOkrApi, {
    onSuccess: () => {
      handleNext();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [performBulkUploadOkr, { isLoading: isBulkUploadProcessing }] =
    useMutation(performBulkUploadOkrApi, {
      onSuccess: (data) => {
        if (data?.created_objectives_count > 0) {
          successNotification(
            `Successfully Uploaded ${data?.created_objectives_count || 0} OKRs`
          );
          setCurrentStep(0);
          setCsvFileList([]);
        } else if (data?.errors?.length > 0) {
          errorNotificationWithString(
            `There were ${data?.errors?.length} errors. Couldn't Upload these OKRs`
          );
        }
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const [currentStep, setCurrentStep] = useState(0);
  const [csvFileList, setCsvFileList] = useState([]);
  const [selectedPeriodId, setSelectedPeriodId] = useState();

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleSelectedPeriodChange = (val) => {
    setSelectedPeriodId(val);
  };

  const handleCheckBulkUpload = () => {
    const formData = new FormData();
    formData.append("file", csvFileList[0]);
    formData.append("objective_period_id", selectedPeriodId);
    checkBulkUpload({
      formData,
    });
  };

  const handlePerformBulkUpload = () => {
    const formData = new FormData();
    formData.append("file", csvFileList[0]);
    formData.append("objective_period_id", selectedPeriodId);
    performBulkUploadOkr({
      formData,
    });
  };

  const loader =
    periodStatus === "loading" || isPeriodLoading || isPeriodFetching;

  const csvFile = csvFileList[0] || {};
  const isFileLarge = csvFile?.size > 512000;

  return (
    <div>
      {/* <Title level={2}>OKRs</Title> */}
      {/* <OKRNavBar /> */}

      <Card className="mt-24">
        <div style={{ width: "75%", margin: "auto" }}>
          <Steps className="mb-48" current={currentStep}>
            <Step title="Upload XLSX" />
            <Step title="Preview and Add OKRs" />
          </Steps>

          {loader ? (
            <BoxLoader />
          ) : (
            <>
              {currentStep === 0 && (
                <UploadCsv
                  periods={periods}
                  selectedPeriodId={selectedPeriodId}
                  handleSelectedPeriodChange={handleSelectedPeriodChange}
                  csvFileList={csvFileList}
                  setCsvFileList={setCsvFileList}
                  checkBulkUpload={checkBulkUpload}
                />
              )}
              {currentStep === 1 && (
                <PreviewCsv
                  errors={checkBulkUploadData?.errors}
                  newOkrs={checkBulkUploadData?.created_objectives}
                />
              )}
            </>
          )}

          {isFileLarge && (
            <Alert
              className="mb-8"
              message="This file is more than 500KB. We are unable to process it, please try with a smaller file."
              type="error"
            />
          )}
          <Row justify="space-between">
            <Col span={12}>
              {currentStep === 1 ? (
                <Button type="primary" ghost={true} onClick={handlePrevious}>
                  Previous
                </Button>
              ) : (
                <></>
              )}
            </Col>
            <Col span={12} className="flex-display justify-content-flex-end">
              {currentStep === 0 ? (
                <ButtonWithSpinner
                  isSpinning={isBulkUploadChecking}
                  disabled={
                    isBulkUploadProcessing ||
                    isFileLarge ||
                    csvFileList?.length === 0
                  }
                  type="primary"
                  onClick={handleCheckBulkUpload}
                >
                  Next
                </ButtonWithSpinner>
              ) : (
                <ButtonWithSpinner
                  isSpinning={isBulkUploadProcessing}
                  disabled={isBulkUploadProcessing || isFileLarge}
                  type="primary"
                  onClick={handlePerformBulkUpload}
                >
                  Add OKRs
                </ButtonWithSpinner>
              )}
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

OKRBulkUpload.propTypes = {};

export default OKRBulkUpload;

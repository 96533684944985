import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Typography, Empty, Card, Space, Row, Button } from "antd";
import { useQuery } from "react-query";

import { getMyReviewsApi } from "apis/perfReviewApi";
import { BoxLoader, EmptyCard } from "components";
import { ReactComponent as ReviewIcon } from "assets/cold-screens/Reviews.svg";
import { errorNotification, formattedDate } from "app/appUtils";

import styles from "./reviews.module.css";

const { Title, Text } = Typography;

const ReviewCard = ({ review = {} }) => {
  const history = useHistory();

  const handleNavigationToDetails = () => {
    history.push(`/reviews/overview/${review?.id}`);
  };

  return (
    <Card
      className={clsx(
        "mt-16",
        "mb-16",
        "cursor-pointer",
        styles.reviewCycleCard
      )}
      onClick={handleNavigationToDetails}
    >
      <Row justify="space-between">
        <Space direction="vertical">
          <Text className="font-size-24">{review?.name}</Text>
          <Text type="secondary">{`Period under review: ${formattedDate(
            review?.period_from
          )} to ${formattedDate(review?.period_to)}`}</Text>
        </Space>
        <Button type="primary" ghost={true} size="small">
          View
        </Button>
      </Row>
    </Card>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.object,
};

const Reviews = (props) => {
  const { status: myReviewsStatus, data: myReviewsData } = useQuery(
    "allReviews",
    getMyReviewsApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [showCompletedReviews, setShowCompletedReviews] = useState(false);

  const handleShowCompletedReviews = () => {
    setShowCompletedReviews(!showCompletedReviews);
  };

  const activeReviews =
    myReviewsData?.filter((review) =>
      moment(review?.deadline_for_sign).isSameOrAfter(moment().startOf("day"))
    ) || [];
  const completedReviews =
    myReviewsData?.filter(
      (review) =>
        !moment(review?.deadline_for_sign).isSameOrAfter(
          moment().startOf("day")
        )
    ) || [];

  return (
    <div>
      <Helmet>
        <title>Reviews - culture.easy</title>
      </Helmet>

      <Title className="mb-8" level={2}>
        Perf Reviews
      </Title>
      {myReviewsStatus === "loading" && <BoxLoader />}

      {myReviewsStatus !== "loading" &&
        activeReviews?.length === 0 &&
        completedReviews?.length === 0 && (
          <EmptyCard
            Icon={<ReviewIcon />}
            text="You are not part of any active Review cycles. Nothing to do for now."
          />
        )}
      {activeReviews?.map((review) => (
        <ReviewCard review={review} />
      ))}
      {completedReviews?.length > 0 && (
        <Row justify="center" className="mt-32">
          <Button size="small" onClick={handleShowCompletedReviews}>
            {showCompletedReviews
              ? "Hide Completed Reviews"
              : "Show Completed Reviews"}
          </Button>
        </Row>
      )}
      {showCompletedReviews && completedReviews?.length === 0 && (
        <Empty description="No completed reviews for you yet" />
      )}
      {showCompletedReviews &&
        completedReviews?.map((review) => <ReviewCard review={review} />)}
    </div>
  );
};

Reviews.propTypes = {};

export default Reviews;

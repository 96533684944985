import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";

import EmployeeAvatar from "./EmployeeAvatar";

const EmployeeAvatarGroup = ({
  employees = [],
  backgroundColor = "",
  divClassName = "",
  maxCount = null,
  ...props
}) => {
  return (
    <div className={divClassName} onClick={props.onClick}>
      <Avatar.Group
        maxCount={maxCount ? maxCount : employees?.length === 2 ? 2 : 1}
        maxStyle={{
          color: "#191919",
          backgroundColor: "white",
          borderColor: "#191919",
        }}
        {...props}
      >
        {employees?.map((employee) => (
          <EmployeeAvatar
            employee={employee}
            backgroundColor={backgroundColor}
          />
        ))}
      </Avatar.Group>
    </div>
  );
};

EmployeeAvatarGroup.propTypes = {
  employees: PropTypes.array,
};

export default EmployeeAvatarGroup;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useMutation, useQueryCache } from "react-query";
// import { useHistory } from "react-router-dom";
import { PageHeader, Typography, Space, Switch, Row, Col } from "antd";
import Icon, { SyncOutlined } from "@ant-design/icons";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { useCurrentCompany } from "store";
import { InlineSpinner } from "components";
import { errorNotification, successNotification } from "app/appUtils";
import { FEATURE_NAMES } from "app/appConstants";
import { updateEnabledFeatureApi } from "apis/enableFeaturesApi";
import { ReactComponent as PraiseIcon } from "assets/icons/praise.svg";
import { ReactComponent as ReviewIcon } from "assets/icons/reviews.svg";
import { ReactComponent as SurveyIcon } from "assets/icons/surveys.svg";

import styles from "./EnableFeatures.module.css";

const { Text, Title } = Typography;

const FEATURE_IDS = {
  "perf-reviews": 1,
  surveys: 2,
  okrs: 3,
  praise: 4,
  "continuous-feedback": 5,
};

const FeatureSwitch = ({
  id = null,
  feature = "",
  enabledState = {},
  description = "",
  shouldRefetchCheckList = false,
  icon = <></>,
}) => {
  const queryCache = useQueryCache();
  const [currentCompany, setCurrentCompany] = useCurrentCompany();

  const [updateEnabledFeature, { status, isLoading, isFetching }] = useMutation(
    updateEnabledFeatureApi,
    {
      onSuccess: (data) => {
        const updatedFeatures = data?.features || [];
        setCurrentCompany({
          ...currentCompany,
          features: [...updatedFeatures],
        });
        successNotification("Saved Successfully");
        if (shouldRefetchCheckList) {
          queryCache.invalidateQueries("getCheckList");
        }
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleToggle = () => {
    const updatedEnabledState = { ...enabledState };
    updatedEnabledState[id] = !updatedEnabledState[id];
    const enabledFeatureArray =
      Object.entries(updatedEnabledState).map(([id, enabled]) => ({
        id: Number(id),
        enabled,
      })) || [];
    updateEnabledFeature({ features: enabledFeatureArray });
  };

  const loader = status === "loading" || isLoading || isFetching;

  return (
    <div className={styles.feature}>
      <Space>
        <Switch
          checked={enabledState[id]}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onClick={() => {
            handleToggle(id);
          }}
        />
        <Icon component={icon} />
        <Text>{feature}</Text>
        {loader && <InlineSpinner />}
      </Space>
      <Text className="block-display mt-16">{description}</Text>
    </div>
  );
};
FeatureSwitch.propTypes = {
  feature: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.func,
};

const PillarColumn = ({ title = "", features = [], enabledState = {} }) => {
  return (
    <Col lg={{ span: 7 }} className={styles.pillarCol}>
      <Title className={styles.pillarTitle} level={3}>
        {title}
      </Title>
      {features?.map((feature) => (
        <FeatureSwitch
          id={feature?.id}
          enabledState={enabledState}
          feature={feature?.feature}
          description={feature?.description}
          icon={feature?.icon}
          shouldRefetchCheckList={feature?.shouldRefetchCheckList}
        />
      ))}
    </Col>
  );
};
PillarColumn.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
};

const EnableFeatures = ({ description = "", shouldShowHeader = true }) => {
  // const history = useHistory();
  const [currentCompany] = useCurrentCompany();

  const [enabledState, setEnabledState] = useState({});

  useEffect(() => {
    const features = currentCompany?.features || [];
    const enabledStateObj = {};
    features.forEach(({ editable, enabled, id }) => {
      if (editable) {
        enabledStateObj[id] = enabled;
      }
    });
    Object.entries(FEATURE_NAMES).forEach(([key, value]) => {
      FEATURE_IDS[key] = features?.filter(
        (filter) => filter?.name === value
      )[0]?.id;
    });
    setEnabledState(enabledStateObj);
  }, [currentCompany]);

  return (
    <>
      <Helmet>
        <title>Settings - culture.easy</title>
      </Helmet>

      {shouldShowHeader && (
        <PageHeader
          className="pt-0 pl-0 pb-0"
          title="Select Features"
          // onBack={() => history.push("/settings")}
        />
      )}
      {description && (
        <Text className="block-display mb-16">{description}</Text>
      )}
      <Row gutter={[8]} justify="space-between">
        <PillarColumn
          title="RECOGNITION"
          enabledState={enabledState}
          features={[
            {
              id: FEATURE_IDS["praise"],
              feature: "Praise",
              description:
                "Celebrate individual contributions and team milestones",
              icon: PraiseIcon,
            },
          ]}
        />
        <PillarColumn
          title="BETTERMENT"
          enabledState={enabledState}
          features={[
            {
              id: FEATURE_IDS["perf-reviews"],
              feature: "Performance Reviews",
              description:
                "Impactful employee performance reviews for retrospection and for planning the next big thing.",
              icon: ReviewIcon,
              shouldRefetchCheckList: true,
            },
            {
              id: FEATURE_IDS["continuous-feedback"],
              feature: "Continuous Feedback",
              description:
                "Take continuous feedback from anyone at any point of time.",
              icon: SyncOutlined,
            },
          ]}
        />
        <PillarColumn
          title="LISTEN"
          enabledState={enabledState}
          features={[
            {
              id: FEATURE_IDS["surveys"],
              feature: "Surveys",
              description:
                "Listen to the frontline voices that are executing your business strategy.",
              icon: SurveyIcon,
            },
          ]}
        />
      </Row>
    </>
  );
};

EnableFeatures.propTypes = {};

export default EnableFeatures;

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import DonutChart from "./DonutChart";

const { Title } = Typography;

const DonutCard = ({ chartData = {} }) => {
  return (
    <div className="donut-card">
      <Title className="ml-8" level={4}>
        {chartData?.title || ""}
      </Title>
      <div className="mx-16">
        <DonutChart
          series={chartData?.series || []}
          showLegend={true}
          showLabels={true}
          labelText="Total"
          formatter={(w) => {
            return w.globals.seriesTotals.reduce((a, b) => {
              return a + b;
            }, 0);
          }}
          colors={chartData?.colors}
          labels={chartData?.labels}
        />
      </div>
    </div>
  );
};

DonutCard.propTypes = { chartData: PropTypes.object.isRequired };

export default DonutCard;

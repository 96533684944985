import React from "react";
import { Dropdown, Menu, Tag, Button } from "antd";

import { MoreOutlined, EditOutlined } from "@ant-design/icons";

import { EmployeeAvatarWithName } from "components";

const prepareColumns = (
  currentUser,
  allEmployeesHash,
  handleEditEmployeeDetails,
  handleUpdateEmployeeManager,
  handleActiveChange,
  handleAddAsAdmin,
  handleRemoveAsAdmin
) => {
  return [
    {
      title: "Employee",
      dataIndex: "name",
      key: "name",
      render: (data, row) => {
        return (
          <>
            <EmployeeAvatarWithName employee={row} />
            {row?.role === "admin" && <Tag className="ml-8">admin</Tag>}
          </>
        );
      },
    },
    {
      title: "Manager",
      render: (data, row) => {
        return (
          <>
            {row?.manager_id ? (
              <>
                <EmployeeAvatarWithName
                  emptyText="No Manager"
                  employee={allEmployeesHash[row?.manager_id]}
                  shouldShowInactive={true}
                />
                <EditOutlined
                  className="cursor-pointer ml-8"
                  onClick={() => handleUpdateEmployeeManager(row)}
                />
              </>
            ) : (
              <Button
                className="pl-0 py-0"
                type="link"
                onClick={() => handleUpdateEmployeeManager(row)}
              >
                Add Manager
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "",
      render: (data) => {
        const isAdmin = data?.role === "admin";
        return (
          <Dropdown
            overlay={
              <Menu>
                {currentUser?.email !== data?.email && (
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      handleActiveChange(data);
                    }}
                  >
                    {data?.active ? "Mark Inactive" : "Mark Active"}
                  </Menu.Item>
                )}
                <Menu.Item
                  key="3"
                  onClick={() => handleEditEmployeeDetails(data)}
                >
                  Edit Employee
                </Menu.Item>
                {isAdmin ? (
                  <Menu.Item key="4" onClick={() => handleRemoveAsAdmin(data)}>
                    Remove as Admin
                  </Menu.Item>
                ) : (
                  <Menu.Item key="5" onClick={() => handleAddAsAdmin(data)}>
                    Add as Admin
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="pl-30">
                <MoreOutlined />
              </a>
            }
          </Dropdown>
        );
      },
    },
  ];
};

export { prepareColumns };

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row, Empty, Typography } from "antd";

import { APIBasedEmployeeSearch } from "components";
import { searchObjectivesApi } from "apis/okrV2Api";
import SearchAndFilters from "../common/SearchAndFilters";
import SearchAndFilterTags from "../common/SearchAndFilterTags";
import Objectives from "../common/objectives/Objectives";
import { errorNotification } from "app/appUtils";

const { Text } = Typography;

const SearchOKRs = ({
  selectedPeriodId = null,
  selectedPeriodObj = {},
  timePeriods = [],
}) => {
  const [progressHash, setProgressHash] = useState({});
  const [searchData, setSearchData] = useState([]);

  const [searchObjectives, { isLoading: isSearching }] = useMutation(
    searchObjectivesApi,
    {
      onSuccess: (data) => {
        setSearchData(data);
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [ownerId, setOwnerId] = useState(null);

  const [filters, setFilters] = useState({
    search: "",
    selectedStatuses: [],
    alignment: ["aligned", "not_aligned"],
  });

  const handleSearch = () => {
    let aligned = undefined;

    const alignment = filters?.alignment || [];
    if (alignment?.length > 0) {
      if (
        alignment?.includes("aligned") &&
        alignment?.includes("not_aligned")
      ) {
        // Do nothing
      } else if (alignment?.includes("aligned")) {
        aligned = true;
      } else {
        aligned = false;
      }
    }

    searchObjectives({
      objective_period_id: selectedPeriodId,
      query: filters?.search,
      owner_id: ownerId,
      aligned,
      status: filters?.selectedStatuses,
      with_access: true,
    });
  };

  const handleUpdateProgressHash = (updatedProgressHash) => {
    setProgressHash(updatedProgressHash);
  };

  return (
    <div>
      <Row align="bottom">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text type="secondary">Owner</Text>
          <APIBasedEmployeeSearch
            className="width-250-px"
            placeholder="Type Owner"
            showArrow={false}
            allowClear={true}
            shouldShowAvatar={true}
            currentOwners={[]}
            value={ownerId}
            onChange={(val) => setOwnerId(val)}
          />
        </div>
        <SearchAndFilters
          isSearching={isSearching}
          filters={filters}
          setFilters={setFilters}
          handleAPISearch={handleSearch}
        />
      </Row>
      <SearchAndFilterTags
        className="mt-8"
        filters={filters}
        setFilters={setFilters}
      />

      {searchData?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Objectives matching the given filters"
        />
      )}

      <div id="progress-popover" style={{ position: "relative" }} />
      <div className="mt-16">
        <Objectives
          from="search-okrs"
          selectedTeamId={"search-okrs"}
          className="root-panel"
          ghost={false}
          isFromSearch={true}
          timePeriods={timePeriods}
          objectives={searchData}
          percentageProgressData={progressHash}
          selectedPeriodObj={selectedPeriodObj}
          refetch={handleSearch}
          parentDetailsRefetch={handleSearch}
          handleUpdateProgressHash={handleUpdateProgressHash}
        />
      </div>
    </div>
  );
};

SearchOKRs.propTypes = {
  selectedPeriodId: PropTypes.number,
};

export default SearchOKRs;

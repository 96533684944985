import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { Tabs, PageHeader, Typography } from "antd";

import EnableFeatures from "./enable_features/EnableFeatures";
import OKRs from "./okrs/OKRs";
import Reviews from "./reviews/Reviews";
import Surveys from "./surveys/Surveys";
import PraiseBadges from "app/settings/praise-badges/PraiseBadges";
import Slack from "./integrations/Slack";

const { TabPane } = Tabs;
const { Title } = Typography;

const Company = (props) => {
  const history = useHistory();

  const { selectedTab: defaultSelectedTab } = useParams();
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    history.push(`/company/${tab}`);
  };

  const divClassName = "mt-16 ml-8";
  return (
    <div>
      <PageHeader
        className="pl-0 mt-0 p-0 mb-8"
        title={
          <Title className="mb-0 font-weight-300" level={1}>
            Features
          </Title>
        }
      />
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        destroyInactiveTabPane={true}
      >
        <TabPane tab="Overview" key="overview">
          <div className={divClassName}>
            <EnableFeatures shouldShowHeader={false} />
          </div>
        </TabPane>
        <TabPane tab="OKRs" key="okrs">
          <div className={divClassName}>
            <OKRs shouldShowHeader={false} />
          </div>
        </TabPane>
        <TabPane tab="Perf Reviews" key="reviews">
          <div className={divClassName}>
            <Reviews shouldShowHeader={false} />
          </div>
        </TabPane>
        <TabPane tab="Surveys" key="surveys">
          <div className={divClassName}>
            <Surveys shouldShowHeader={false} />
          </div>
        </TabPane>
        <TabPane tab="Praise Badges" key="praise-badges">
          <div className={divClassName}>
            <PraiseBadges shouldShowHeader={true} />
          </div>
        </TabPane>
        <TabPane tab="Integrations" key="integrations">
          <div className={divClassName}>
            <Slack shouldShowHeader={false} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

Company.propTypes = {};

export default Company;

import React from "react";
import { Layout, Card, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useQueryCache } from "react-query";

import { useCurrentUser, useIsSignedOut, useCompanyList } from "store";
import { getCurrentUserName, signOut } from "app/appUtils";
import logo from "assets/logo.svg";

import styles from "./NoOrgsFound.module.css";

const { Text, Title, Link } = Typography;

const NoOrgsFound = (props) => {
  const history = useHistory();

  const [currentUser] = useCurrentUser();
  const [, setIsSignedOut] = useIsSignedOut();
  const [, setCompanyList] = useCompanyList();
  const queryCache = useQueryCache();

  const handleSignUp = () => {
    history.push("/signup");
  };

  const handleLogout = async () => {
    signOut({
      isInAppRedirect: true,
      queryCache,
      setCompanyList,
      setIsSignedOut,
    });
  };

  const isSamlUser =
    currentUser?.providerData[0]?.providerId?.startsWith("saml") || false;

  return (
    <Layout className={styles.fullHeight}>
      <Card className={styles.loginCard}>
        <div className={styles.loginDiv}>
          <Link href="https://culture.easy.xyz/">
            <img className="block-display" src={logo} alt="logo" />
          </Link>

          <Title className="mt-16" level={5}>
            {currentUser &&
              `Hey ${getCurrentUserName(currentUser)}(${currentUser?.email}), ${
                isSamlUser
                  ? "you don’t have access to culture easy"
                  : "we don't recognize you."
              }`}
          </Title>
          <>
            {isSamlUser ? (
              <Text className="my-40">
                Please contact your HR Admin to give you access.
              </Text>
            ) : (
              <>
                <Button
                  className="mt-32 mb-24 font-weight-300 width-300-px"
                  onClick={handleSignUp}
                >
                  Sign Up for your Company
                </Button>

                <Button
                  className="mt-8 mb-24 font-weight-300 width-300-px"
                  onClick={handleLogout}
                >
                  Sign In with a different email
                </Button>
              </>
            )}
          </>
          <Text
            className={`mt-8 mb-26 width-300-px ${styles.footerText}`}
            type="secondary"
          >
            By clicking Continue with Google, I accept the easy.xyz{" "}
            <span className="text-decoration-underline">Terms Of Service</span>{" "}
            and have read the{" "}
            <span className="text-decoration-underline">Privacy Policy</span>
          </Text>
        </div>
      </Card>
    </Layout>
  );
};

NoOrgsFound.propTypes = {};

export default NoOrgsFound;

import React from "react";
import { Row, Col, Button, Space } from "antd";

import { ButtonWithSpinner } from "components";

function StepFormActionButtons({
  mode = "create",
  isSaving = false,
  currentStep,
  handlePreviousClick,
  handleSaveAsDraft,
  currentForm,
}) {
  return (
    <Row>
      <Col span={6}>
        {currentStep !== 0 && (
          <Button
            type="primary"
            ghost
            onClick={() => {
              handlePreviousClick(currentForm);
            }}
          >
            Previous
          </Button>
        )}
      </Col>
      <Col span={18} className="text-align-right">
        <Space size="large">
          {mode === "create" && (
            <Button type="primary" ghost onClick={handleSaveAsDraft}>
              Save as Draft
            </Button>
          )}
          <ButtonWithSpinner
            isSpinning={isSaving}
            disabled={isSaving}
            type="primary"
            htmlType="submit"
          >
            {currentStep === 2
              ? mode === "create"
                ? "Create Cycle"
                : "Update Cycle"
              : "Next"}
          </ButtonWithSpinner>
        </Space>
      </Col>
    </Row>
  );
}

export default StepFormActionButtons;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Space, Typography, Tooltip, Alert, Row } from "antd";
import {
  CloudOutlined,
  CloudServerOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { InlineSpinner, TimedMessage, ButtonWithSpinner } from "components";
import { useCurrentCompany } from "store";
import { appealed, appealRejected, appealAccepted } from "../reviewConstants";

const { Text } = Typography;

const getAppealMsg = (isReviewee, myStatus) => {
  switch (myStatus) {
    case "Appealed":
      return isReviewee
        ? "Waiting for HR Admin to respond to your appeal"
        : "Reviewee has raised an appeal request";
    case "Appeal Rejected":
      return `HR Admin has rejected ${
        isReviewee ? "your" : "reviewee's"
      } appeal`;
    case "Appeal Accepted":
      return `HR Admin has accepted ${
        isReviewee ? "your" : "reviewee's"
      } appeal`;
    default:
      return "";
  }
};

const getRevieweeStatusAppealed = (revieweeStatus) => {
  return (
    revieweeStatus === "Appealed" ||
    revieweeStatus === "Appeal Rejected" ||
    revieweeStatus === "Appeal Accepted"
  );
};

const SubmissionButtons = ({
  isPeer = false,
  myRole = "reviewee",
  otherRole = "reviewer",
  reviewData = {},
  checkSubmit = false,
  otherSubmit = false,
  checkSign = false,
  myStatus = "",
  isPerfReviewShared = false,
  updateReviewStatus = "",
  updatePeerReviewStatus = "",
  isUpdatingPeerReview = false,
  isFromAutoSave = false,
  isLoading = false,
  buttonActionType = "save",
  isUnlockLoading = false,
  setIsFromAutoSave = () => {},
  handleUpdateReview = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  let appealWindowStartDate = "";
  appealWindowStartDate = moment(
    reviewData?.perf_review_cycle?.appeal_window_start_date,
    "YYYY-MM-DD"
  );
  let appealWindowEndDate = "";
  appealWindowEndDate = moment(
    reviewData?.perf_review_cycle?.appeal_window_end_date,
    "YYYY-MM-DD"
  );
  const isAppealWindowPresent =
    reviewData?.perf_review_cycle?.appeal_window_start_date &&
    reviewData?.perf_review_cycle?.appeal_window_end_date;
  const isTodayInAppealWindow =
    isAppealWindowPresent &&
    moment().isSameOrAfter(appealWindowStartDate) &&
    moment().isSameOrBefore(appealWindowEndDate);

  const isInAnAppealState =
    myStatus === appealed ||
    myStatus === appealRejected ||
    myStatus === appealAccepted;

  const hasRevieweeAppealed = getRevieweeStatusAppealed(
    reviewData?.reviewee?.status
  );

  const shouldntShowUnlockOrgs =
    process?.env?.REACT_APP_PERF_REVIEW_BULK_UPLOAD_ORGS?.split(", ");

  return (
    <div className="mt-16">
      {hasRevieweeAppealed && (
        <Alert
          className="mb-8"
          message={getAppealMsg(
            myRole === "reviewee",
            reviewData?.reviewee?.status
          )}
        />
      )}
      {Boolean(myRole) && (
        <>
          <div>
            {(updateReviewStatus === "loading" ||
              updatePeerReviewStatus === "loading") &&
              isFromAutoSave && (
                <Space className="mb-8">
                  <CloudOutlined />
                  <Text>Auto saving your review...</Text>
                  <InlineSpinner fontSize={24} />
                </Space>
              )}
            {(updateReviewStatus === "success" ||
              updatePeerReviewStatus === "loading") &&
              isFromAutoSave && (
                <Space>
                  <TimedMessage
                    message={
                      <Space className="mb-8">
                        <CloudServerOutlined />
                        <Typography>Auto saved your review</Typography>
                      </Space>
                    }
                    onTimeOut={() => {
                      setIsFromAutoSave(false);
                    }}
                  />
                </Space>
              )}
          </div>
          <Space className="mb-32">
            {checkSubmit === false && !isInAnAppealState && (
              <div className="flex-display flex-direction-column">
                <Space>
                  <ButtonWithSpinner
                    isSpinning={isLoading && buttonActionType === "save"}
                    disabled={
                      isUnlockLoading || isLoading || isUpdatingPeerReview
                    }
                    type="primary"
                    onClick={() => handleUpdateReview("save")}
                  >
                    Save
                  </ButtonWithSpinner>
                  <ButtonWithSpinner
                    isSpinning={
                      (isLoading || isUpdatingPeerReview) &&
                      buttonActionType === "submit"
                    }
                    disabled={isUnlockLoading || isLoading}
                    type="primary"
                    onClick={() => handleUpdateReview("submit")}
                  >
                    Submit
                  </ButtonWithSpinner>
                </Space>
              </div>
            )}
            {!shouldntShowUnlockOrgs?.includes(`${currentCompany?.id}`) &&
              reviewData?.show_unlock_button &&
              !isPeer && (
                <Tooltip
                  placement="top"
                  title={`Clicking this button will enable ${
                    myRole === "reviewee"
                      ? reviewData?.reviewer?.name
                      : reviewData?.reviewee?.name
                  } to edit their answers. They will have to resubmit their assessment again`}
                >
                  <ButtonWithSpinner
                    isSpinning={
                      isUnlockLoading && buttonActionType === "unlock"
                    }
                    disabled={isUnlockLoading || isLoading}
                    type="primary"
                    onClick={() => handleUpdateReview("unlock")}
                  >
                    Unlock
                    {` ${
                      reviewData && reviewData[otherRole]?.name
                    }'s Assessment`}
                  </ButtonWithSpinner>
                </Tooltip>
              )}
            {(checkSubmit || isInAnAppealState) &&
              otherSubmit &&
              !checkSign &&
              !isPeer && (
                <>
                  <ButtonWithSpinner
                    isSpinning={isLoading && buttonActionType === "sign"}
                    disabled={isUnlockLoading || isLoading}
                    type="primary"
                    onClick={() => handleUpdateReview("sign")}
                  >
                    {myRole === "reviewee"
                      ? "Sign Your Assessment"
                      : `Sign ${
                          reviewData && reviewData[otherRole]?.name
                        }'s Assessment`}
                  </ButtonWithSpinner>
                  {myRole === "reviewee" &&
                    isTodayInAppealWindow &&
                    !isInAnAppealState && (
                      <ButtonWithSpinner
                        isSpinning={isLoading && buttonActionType === "appeal"}
                        disabled={isUnlockLoading || isLoading}
                        type="primary"
                        onClick={() => handleUpdateReview("appeal")}
                      >
                        Appeal
                      </ButtonWithSpinner>
                    )}
                </>
              )}
            {myStatus === "Submitted" && !isPerfReviewShared && !isPeer && (
              <Row align="middle">
                <InfoCircleOutlined className="mr-8 font-size-18" />
                <Alert message={"Waiting for HR admin to share results"} />
              </Row>
            )}
            {checkSubmit && !otherSubmit && !isPeer && (
              <Row align="middle">
                <InfoCircleOutlined className="mr-8 font-size-18" />
                <Alert
                  message={`Waiting for ${
                    (reviewData && reviewData[otherRole]?.name) ||
                    "other participant"
                  } to submit`}
                />
              </Row>
            )}
          </Space>
        </>
      )}
    </div>
  );
};

SubmissionButtons.propTypes = {
  reviewData: PropTypes.object,
  myRole: PropTypes.string,
  otherRole: PropTypes.string,
  updateReviewStatus: PropTypes.string,
  buttonActionType: PropTypes.string,
  checkSubmit: PropTypes.bool,
  otherSubmit: PropTypes.bool,
  checkSign: PropTypes.bool,
  isFromAutoSave: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUnlockLoading: PropTypes.bool,
  setIsFromAutoSave: PropTypes.func,
  handleUpdateReview: PropTypes.func,
};

export default SubmissionButtons;

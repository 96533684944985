import React from "react";

import PropTypes from "prop-types";
import { Alert, Typography, Button, Space, Modal } from "antd";

import { ButtonWithSpinner } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";
import styles from "./NotificationModal.module.css";
import { formattedDate } from "app/appUtils";

const { Title, Text, Link } = Typography;

const NotificationModal = ({
  from = "index",
  isLoading = false,
  hprSurvey = {},
  visible = false,
  handleCancel = () => {},
  handleSubmit = () => {},
}) => {
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();
  const getFooterButtons = () => {
    if (from === "form") {
      return [
        <ButtonWithSpinner
          type="primary"
          onClick={() => {
            setButtonWithSpinner("now");
            handleSubmit();
          }}
          disabled={isLoading}
          isSpinning={isLoading && buttonWithSpinner === "now"}
        >
          Send emails and create HPR Survey
        </ButtonWithSpinner>,
        <Button
          disabled={isLoading}
          type="primary"
          ghost
          onClick={handleCancel}
        >
          Cancel
        </Button>,
      ];
    }
    return [
      <ButtonWithSpinner
        disabled={isLoading}
        type="primary"
        onClick={() => {
          setButtonWithSpinner("now");
          handleSubmit(true);
        }}
        isSpinning={isLoading && buttonWithSpinner === "now"}
      >
        Email Now
      </ButtonWithSpinner>,
      <Button disabled={isLoading} type="primary" ghost onClick={handleCancel}>
        Cancel
      </Button>,
    ];
  };

  return (
    <Modal
      visible={visible}
      title={"Inform Participants by Email"}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      onOk={handleSubmit}
      footer={getFooterButtons()}
    >
      <Alert
        className="mb-16"
        type="warning"
        message="This is just a preview with sample data. The names, links and dates will be replaced"
      />
      <div className={styles.notificationBody}>
        <Space direction="vertical">
          <Title level={4}>
            Subject: A new High Performance Report Survey is shared with you
          </Title>
          <Text>Hi Joe,</Text>
          <Text>
            {`A new HPR survey titled ${
              hprSurvey?.title
            } is shared with you. This survey helps in providing a High Performance Report and identify areas of improvement to enable high performance in teams. Please provide your responses by ${formattedDate(
              hprSurvey?.due_date
            )}`}
          </Text>
          <Text>
            You can fill the HPR survey by clicking the following link
          </Text>
          <Link>(a link to the newly created HPR survey)</Link>
        </Space>
      </div>
    </Modal>
  );
};

NotificationModal.propTypes = {
  hprSurvey: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default NotificationModal;

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { useMutation } from "react-query";
import {
  Card,
  Modal,
  Col,
  Typography,
  Avatar,
  Row,
  Dropdown,
  Menu,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import styles from "../Praise.module.css";
import { EmployeeAvatar, InlineSpinner, ShowMoreText } from "components";
import { useCurrentUser } from "store";
import { deletePraiseApi } from "apis/praiseApis";
import { iconOptionsMap } from "app/settings/praise-badges/common/constants";
import PraiseComments from "./PraiseComments";
import PraiseLike from "./PraiseLike";
import PraiseFormModal from "./PraiseFormModal";
import {
  errorNotification,
  successNotification,
  getNameFromEmployeeObj,
} from "app/appUtils";

const { confirm } = Modal;
const { Text, Title } = Typography;

const PraiseCard = ({
  canUpdate = true,
  praise = {},
  badges = [],
  employees = [],
  inActiveEmployees = [],
  refetch = () => {},
  handleUpdatePaginationItem = () => {},
  handleDeletePaginationItem = () => {},
}) => {
  const [currentUser] = useCurrentUser();

  const [isEdit, setIsEdit] = useState(false);

  const [deletePraise, { status: deletePraiseStatus }] = useMutation(
    deletePraiseApi,
    {
      onSuccess: () => {
        // refetch();
        handleDeletePaginationItem(praise?.id);
        successNotification("Successfully deleted praise.");
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this praise (${praise?.title || ""}).`,
      onOk() {
        deletePraise({ id: praise?.id });
      },
      okText: "Delete",
      onCancel() {},
    });
  };

  const getPraisedEmployeeNames = () => {
    if (praise?.praised_employees?.length > 1) {
      return `${praise?.praised_employees?.length} people`;
    } else if (praise?.praised_employees?.length === 1) {
      const praiseEmployeeWords = praise?.praised_employees[0]?.employee?.first_name?.split(
        " "
      ) || [""];
      return `${praiseEmployeeWords[0] || ""}`;
    }
    return "";
  };

  const getPraisedEmployeesCombinedNames = () => {
    if (praise && praise.praised_employees) {
      if (praise.praised_employees?.length > 1) {
        const empNames = praise?.praised_employees?.map(
          (emp) => `${getNameFromEmployeeObj(emp?.employee)}`
        );
        return (
          <Title level={3} className={clsx("ml8", "mb8", "mt8", "mr8")}>
            {empNames.join(",")}
          </Title>
        );
      } else if (praise.praised_employees?.length === 1) {
        return (
          <Title level={3} className={clsx("ml8", "mb8", "mt8", "mr8")}>
            {getNameFromEmployeeObj(praise.praised_employees[0]?.employee) ||
              ""}
          </Title>
        );
      }
      return "";
    }
    return "";
  };

  return (
    <div key={praise?.id}>
      {isEdit && (
        <PraiseFormModal
          visible={isEdit}
          mode="edit"
          praise={praise}
          badges={badges}
          employees={employees}
          inActiveEmployees={inActiveEmployees}
          refetch={refetch}
          handleUpdatePaginationItem={handleUpdatePaginationItem}
          onClose={handleEdit}
        />
      )}
      <Row>
        <Col span={16}>
          <Text>{`${getNameFromEmployeeObj(
            praise?.praised_by
          )} praised ${getPraisedEmployeeNames()}`}</Text>
        </Col>
        <Col span={8}>
          <Row justify="end" align="bottom" className={styles.height100Percent}>
            <Moment
              className="ant-typography ant-typography-secondary text-align-right "
              fromNow
            >
              {praise?.created_at || new Date()}
            </Moment>
          </Row>
        </Col>
      </Row>
      <Card className="mt-4 praise-card">
        <Row
          className="praise-card-header"
          justify="space-between"
          align="middle"
        >
          <Col span={20}>
            <div className={styles.cardHead}>
              {iconOptionsMap[praise?.praise_badge?.icon]}
              <Text className={clsx("font-weight-500", "mt-8", "ml-8")}>
                {praise?.praise_badge?.title || ""}
              </Text>
              {deletePraiseStatus === "loading" && <InlineSpinner />}
            </div>
          </Col>
          <Col span={4}>
            {canUpdate && currentUser?.email === praise?.praised_by?.email && (
              <Dropdown
                disabled={!canUpdate}
                overlay={
                  <Menu mode="inline">
                    <Menu.Item key="Edit" onClick={handleEdit}>
                      <EditOutlined /> Edit
                    </Menu.Item>
                    <Menu.Item key="delete" onClick={handleDelete}>
                      <DeleteOutlined /> Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomCenter"
              >
                <span className={clsx("cursor-pointer", "pl-30")}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Row justify="center" className={styles.cardAvatars}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "white",
              backgroundColor: "#5330C9",
              height: "64px",
              width: "64px",
              fontSize: "24px",
              alignItems: "center",
              display: "flex",
            }}
          >
            {praise?.praised_employees?.map((emp) => (
              <EmployeeAvatar
                size={64}
                height="64px"
                width="64px"
                textFontSize="24px"
                employee={emp?.employee || {}}
              />
            ))}
          </Avatar.Group>
        </Row>
        <Row justify="center" className={styles.cardNames}>
          {getPraisedEmployeesCombinedNames()}
        </Row>
        <Title className={clsx("font-weight-500", "mt-16")} level={4}>
          {praise?.title}
        </Title>
        {canUpdate ? (
          <Text className="font-weight-300 mt-8">{praise?.description}</Text>
        ) : (
          <ShowMoreText
            className="font-weight-300 mt-8"
            text={praise?.description || ""}
          />
        )}

        {canUpdate && (
          <>
            <PraiseLike canUpdate={canUpdate} praise={praise} />

            <PraiseComments canUpdate={canUpdate} praise={praise} />
          </>
        )}
      </Card>
    </div>
  );
};

PraiseCard.propTypes = {
  praise: PropTypes.object,
  badges: PropTypes.array,
  employees: PropTypes.array,
  refetch: PropTypes.func,
};

export default PraiseCard;

import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { useQuery, useMutation } from "react-query";
import HSBar from "react-horizontal-stacked-bar-chart";
import { debounce, throttle } from "lodash";
import { Card, Row, Col, Typography, Table, Input, Select } from "antd";

import {
  BoxLoader,
  CountCard,
  InlineSpinner,
  EmployeeAvatarWithName,
} from "components";
import SeeKPIsDrawer from "./common/SeeKPIsDrawer";
import KPISearch from "./common/KPISearch";
import { getAllTeamsApi } from "apis/teamApi";
import {
  getKPIInsightsSummary,
  getEmployeesWithKPIStatusApi,
  getKPITeamInsightsApi,
} from "apis/kpis";
import { errorNotification, getNameFromEmployeeObj } from "app/appUtils";

import styles from "app/okrs/OKRs.module.css";

const { Text, Title } = Typography;
const { Option } = Select;

const KPIInsights = (props) => {
  const [selectedStatus, setSelectedStatus] = useState("behind");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [searchString, setSearchString] = useState({});
  const [isLessThanThreeChars, setIsLessThanThreeChars] = useState("");
  const [teamInsightsDataSource, setTeamInsightsDataSource] = useState([]);
  const [statusDataSource, setStatusDataSource] = useState([]);
  const [filters, setFilters] = useState({
    checkin: [],
    status: [],
  });

  const { data, isLoading } = useQuery(
    "getKPIInsightsSummary",
    getKPIInsightsSummary
  );

  const {
    data: teams,
    isLoading: isTeamsLoading,
    // isFetching: isTeamsFetching,
  } = useQuery("getAllTeams", getAllTeamsApi);

  const [
    getEmployeesWithKPIStatus,
    {
      data: employeesOKRData,
      isLoading: isEmployeesKPILoading,
      isFetching: isEmployeesKPIFetching,
    },
  ] = useMutation(getEmployeesWithKPIStatusApi, {
    onSuccess: (data) => {
      setStatusDataSource(data);
      setSearchString((prevState) => ({ ...prevState, statusTable: "" }));
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const {
    data: teamKPIInsightsData,
    isLoading: isTeamKPIInsightsLoading,
    isFetching: isTeamKPIInsightsFetching,
  } = useQuery("getKPITeamInsightsApi", getKPITeamInsightsApi, {
    onSuccess: (data) => {
      setTeamInsightsDataSource(data);
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const statusKpiTitleQuery = searchString?.statusTableTitle || "";
  const statusFilters = filters?.status || [];
  useEffect(() => {
    getEmployeesWithKPIStatus({
      status: selectedStatus,
      kpi_title_query: statusKpiTitleQuery,
      filters: statusFilters,
    });
  }, [
    getEmployeesWithKPIStatus,
    selectedStatus,
    statusKpiTitleQuery,
    statusFilters,
  ]);

  const handleTeamSearchThrottled = (e) => {
    throttle(() => {
      handleTeamSearch(e);
    }, 750)();
  };

  const handleTeamSearch = (e) => {
    let value = e?.target?.value || "";

    if (!value) {
      setSearchString((prevState) => ({ ...prevState, teamTable: "" }));
      setIsLessThanThreeChars("");
      setTeamInsightsDataSource(teamKPIInsightsData);
      return;
    }

    if (value?.length > 2) {
      const filteredData = teamInsightsDataSource?.filter((row) => {
        return row?.team_name?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setTeamInsightsDataSource(filteredData);
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("checkin-table");
    }
    setSearchString((prevState) => ({
      ...prevState,
      teamTable: value,
    }));
  };

  const handleWithoutStatusKPITitleSearch = (e) => {
    let value = e?.target?.value || "";

    if (!value) {
      setSearchString((prevState) => ({ ...prevState, statusTableTitle: "" }));
      setIsLessThanThreeChars("");
      return;
    }

    if (value?.length > 2) {
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("checkin-table");
    }
    setSearchString((prevState) => ({ ...prevState, statusTableTitle: value }));
  };

  const handleStatusSearch = (e) => {
    let value = e.target.value;
    setSearchString((prevState) => ({ ...prevState, statusTable: value }));

    if (!value) {
      setStatusDataSource(employeesOKRData);
      setIsLessThanThreeChars("");
      return;
    }

    if (value?.length > 2) {
      const filteredData = employeesOKRData?.filter((row) => {
        const employeeName = getNameFromEmployeeObj(row?.employee) || "";
        return employeeName?.toLowerCase()?.includes(value?.toLowerCase());
      });
      setStatusDataSource(filteredData);
      setIsLessThanThreeChars("");
    } else {
      setIsLessThanThreeChars("status-table");
    }
  };

  const handleStatusSearchThrottle = (e) => {
    throttle(() => {
      handleStatusSearch(e);
    }, 750)();
  };

  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleSeeEmployeeKpis = (employee = null) => {
    setSelectedEmployee(employee);
  };

  const onTrackKPIsLength = data?.on_track || 0;
  const behindKPIs = data?.behind || 0;

  const teamKPIInsightsLoader =
    isTeamKPIInsightsLoading || isTeamKPIInsightsFetching;
  const employeesWithKPIsLoader =
    isEmployeesKPIFetching || isEmployeesKPILoading;

  return (
    <>
      {Boolean(selectedEmployee) && (
        <SeeKPIsDrawer
          employee={selectedEmployee}
          onClose={() => handleSeeEmployeeKpis(null)}
        />
      )}
      <div>
        <Card>
          {isLoading ? (
            <BoxLoader />
          ) : (
            <div className="flex-display">
              <CountCard
                className="ml-8 width-150-px"
                title="KPIs"
                count={data?.total_count || 0}
              />

              <div className="ml-16">
                <Text className="font-size-14 mb-8 block-display">
                  KPIs BY STATUS
                </Text>
                <div>
                  {(onTrackKPIsLength > 0 || behindKPIs > 0) && (
                    <HSBar
                      height={25}
                      data={[
                        { value: onTrackKPIsLength, color: "#52C41A" },
                        { value: behindKPIs, color: "#FAAD14" },
                      ]}
                      // outlineWidth={getOutlineWidth([
                      //   statuses["not_started"],
                      //   statuses["on_track"],
                      //   statuses["behind"],
                      //   statuses["at_risk"],
                      //   statuses["done"],
                      // ])}
                      outlineColor="white"
                    />
                  )}

                  <Row className="min-width-250px">
                    <Col span={12}>
                      <Row className="height-100-percent mb-8" align="middle">
                        <span className={styles.onTrackDot} />
                        <Text className="font-size-14 line-height-16px">{`${onTrackKPIsLength} ON TRACK`}</Text>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row className="height-100-percent mb-8" align="middle">
                        <span className={styles.behindDot} />
                        <Text className="font-size-14 line-height-16px">{`${behindKPIs} BEHIND`}</Text>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>

      <div className="mt-40">
        <Card
          className="insights-card-table team-kpi-insights-card-table"
          title={
            <div className="flex-display justify-content-space-between align-items-center mt-8">
              <div style={{ marginBottom: "22px" }}>
                <Text>Team KPI Insights</Text>
              </div>
              <div style={{ minHeight: "66px" }}>
                <div className="flex-display height-42-px mb-8">
                  <div>
                    <Input
                      allowClear
                      className="width-200-px mr-4 "
                      placeholder="Search Team"
                      value={searchString?.teamTable || ""}
                      onChange={handleTeamSearchThrottled}
                    />
                    {isLessThanThreeChars === "checkin-table" ? (
                      <Text
                        className="block-display width-200-px"
                        type="danger"
                      >
                        Type at least 3 characters
                      </Text>
                    ) : (
                      <div style={{ minHeight: "22px" }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div>
            {teamKPIInsightsLoader ? (
              <BoxLoader />
            ) : (
              <Row>
                {teamInsightsDataSource?.map((teamInfo) => {
                  const behindCount = teamInfo?.behind;
                  const onTrackCount = teamInfo?.on_track;
                  return (
                    <Card className="width-300-px ml-8 mb-8">
                      <Text className="block-display">
                        {teamInfo?.team_name}
                      </Text>
                      <div>
                        <Text>Total KPIs - </Text>
                        <Title
                          level={4}
                          style={{ display: "inline-block", marginTop: "8px" }}
                        >
                          {behindCount + onTrackCount}
                        </Title>
                      </div>
                      {(behindCount > 0 || onTrackCount > 0) && (
                        <div>
                          <HSBar
                            height={25}
                            data={[
                              { value: onTrackCount, color: "#52C41A" },
                              { value: behindCount, color: "#FAAD14" },
                            ]}
                            outlineColor="white"
                          />

                          <Row className="min-width-250px">
                            <Col span={12}>
                              <Row
                                className="height-100-percent mb-8"
                                align="middle"
                              >
                                <span className={styles.onTrackDot} />
                                <Text className="font-size-14 line-height-16px">{`${onTrackCount} ON TRACK`}</Text>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row
                                className="height-100-percent mb-8"
                                align="middle"
                              >
                                <span className={styles.behindDot} />
                                <Text className="font-size-14 line-height-16px">{`${behindCount} BEHIND`}</Text>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Card>
                  );
                })}
              </Row>
            )}
          </div>
        </Card>
      </div>

      <div className="mt-40">
        <Card
          className="insights-card-table"
          title={
            <div className="flex-display justify-content-space-between align-items-center mt-8">
              <div style={{ marginBottom: "22px" }}>
                <Text>{`(${
                  employeesOKRData?.length || 0
                }) Employees with KPIs `}</Text>
                <Select
                  className="width-150-px"
                  value={selectedStatus}
                  onChange={handleSelectedStatus}
                >
                  <Option value="behind">Behind</Option>
                  <Option value="on_track">On Track</Option>
                </Select>
                {employeesWithKPIsLoader && (
                  <InlineSpinner className="ml-8" fontSize={22} />
                )}
              </div>
              <div style={{ minHeight: "66px" }}>
                <div className="flex-display height-42-px">
                  <Input
                    allowClear
                    className="width-200-px mr-4"
                    placeholder="Search KPI Title"
                    onChange={(e) => {
                      e.persist();

                      debounce(() => {
                        handleWithoutStatusKPITitleSearch(e);
                      }, 1000)();
                    }}
                  />
                  <div>
                    <Input
                      allowClear
                      className="width-200-px mr-4"
                      placeholder="Search Employee"
                      value={searchString?.statusTable || ""}
                      onChange={handleStatusSearchThrottle}
                    />
                    {isLessThanThreeChars === "status-table" ? (
                      <Text
                        className="block-display width-200-px"
                        type="danger"
                      >
                        Type at least 3 characters
                      </Text>
                    ) : (
                      <div style={{ minHeight: "22px" }}></div>
                    )}
                  </div>
                  <KPISearch
                    isTeamsLoading={isTeamsLoading}
                    type="status"
                    teams={teams}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </div>
              </div>
            </div>
          }
        >
          <div>
            <Table
              loading={employeesWithKPIsLoader}
              className="mt-4"
              dataSource={statusDataSource}
              columns={[
                {
                  title: "Employee Name",
                  dataIndex: "employee",
                  key: "employee",
                  render: (employee) => (
                    <>
                      <EmployeeAvatarWithName employee={employee} />
                    </>
                  ),
                },
                {
                  title: "Number of KPIs",
                  dataIndex: "kpis",
                  key: "kpis",
                  defaultSortOrder: "ascend",
                  sorter: (a, b) => a?.kpis - b?.kpis,
                },
                {
                  title: "Actions",
                  render: (_, row) => {
                    return (
                      <Text
                        className="text-decoration-underline text-primary cursor-pointer"
                        onClick={() => {
                          handleSeeEmployeeKpis({
                            ...row?.employee,
                          });
                        }}
                      >
                        See KPIs
                      </Text>
                    );
                  },
                },
              ]}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

KPIInsights.propTypes = {};

export default KPIInsights;

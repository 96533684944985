import AppLayoutV3 from "components/layouts/AppLayoutV3";
import Surveys from "./Surveys";
import SurveyEdit from "./edit/SurveyEdit";

const routes = [
  {
    exact: true,
    path: "/surveys/",
    layout: AppLayoutV3,
    component: Surveys,
  },
  {
    exact: true,
    path: "/surveys/:surveyId/edit",
    component: SurveyEdit,
  },
];

export default routes;

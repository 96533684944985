import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Input, Typography } from "antd";

import {
  ButtonWithSpinner,
  OptionalFormItemLabel,
  EmailInput,
} from "components";
import { useCurrentUser } from "store";
import { getNameFromEmployeeObj } from "app/appUtils";

const { Text } = Typography;

const ProfileDetail = ({ label = "", value = "" }) => {
  return (
    <div className="mb-16">
      <Text type="secondary" className="block-display">
        {label}
      </Text>
      <Text className="block-display">{value || "-"}</Text>
    </div>
  );
};

const EditEmployeeNameModal = ({
  isProfile = false,
  visible = false,
  isUpdating = false,
  employee = {},
  onCancel = () => {},
  onUpdate = () => {},
}) => {
  const [form] = Form.useForm();
  const [currentUser] = useCurrentUser();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onUpdate(employee, values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const isCurrentUser = currentUser?.email === employee?.email;

  const defaultClassName = "width-60-percent";

  return (
    <Modal
      visible={visible}
      title={"Edit " + getNameFromEmployeeObj(employee) + "'s Details"}
      destroyOnClose
      onCancel={handleCancel}
      width="1000px"
      keyboard={false}
      maskClosable={false}
      onOk={handleSubmit}
      footer={[
        <ButtonWithSpinner
          isSpinning={isUpdating}
          disabled={isUpdating}
          type="primary"
          onClick={handleSubmit}
        >
          Update
        </ButtonWithSpinner>,
        <Button type="primary" ghost onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className="editManagerForm"
        name="editManager"
        initialValues={{
          first_name: employee?.first_name || null,
          last_name: employee?.last_name || null,
          email: employee?.email || null,
          department: employee?.department || null,
          sub_department: employee?.sub_department || null,
          designation: employee?.designation || null,
          company_employee_id: employee?.company_employee_id || null,
        }}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input employee First Name",
            },
            {
              whitespace: true,
              message: "First Name can't be empty",
            },
          ]}
          required={false}
          className={defaultClassName}
        >
          <Input placeholder="Enter Employee First Name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input employee Last Name",
            },
            {
              whitespace: true,
              message: "Last Name can't be empty",
            },
          ]}
          required={false}
          className={defaultClassName}
        >
          <Input placeholder="Enter Employee Last Name" />
        </Form.Item>
        {isProfile ? (
          <ProfileDetail label="Email" value={employee?.email} />
        ) : (
          <Form.Item
            className={defaultClassName}
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input employee email",
              },
              {
                whitespace: true,
                message: "Email can't be empty",
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
            required={false}
          >
            <EmailInput disabled={isCurrentUser} />
          </Form.Item>
        )}
        {isProfile ? (
          <ProfileDetail
            label="Employee Code"
            value={employee?.company_employee_id}
          />
        ) : (
          <Form.Item
            label={<OptionalFormItemLabel label="Employee Code" />}
            name="company_employee_id"
            required={false}
            className={defaultClassName}
          >
            <Input
            // placeholder="Enter Employee Department"
            />
          </Form.Item>
        )}
        {isProfile ? (
          <ProfileDetail label="Department" value={employee?.department} />
        ) : (
          <Form.Item
            label={<OptionalFormItemLabel label="Department" />}
            name="department"
            required={false}
            className={defaultClassName}
          >
            <Input
            // placeholder="Enter Employee Department"
            />
          </Form.Item>
        )}
        {isProfile ? (
          <ProfileDetail
            label="Sub Department"
            value={employee?.sub_department}
          />
        ) : (
          <Form.Item
            label={<OptionalFormItemLabel label="Sub Department" />}
            name="sub_department"
            required={false}
            className={defaultClassName}
          >
            <Input
            // placeholder="Enter Employee Sub Department"
            />
          </Form.Item>
        )}
        {isProfile ? (
          <ProfileDetail label="Designation" value={employee?.designation} />
        ) : (
          <Form.Item
            label={<OptionalFormItemLabel label="Designation" />}
            name="designation"
            required={false}
            className={defaultClassName}
          >
            <Input
            // placeholder="Enter Employee Designation"
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
EditEmployeeNameModal.propTypes = {
  visible: PropTypes.bool,
  employee: PropTypes.object,
  employees: PropTypes.array,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default EditEmployeeNameModal;

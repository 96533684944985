import axios from "axios";

const createOrgApi = async ({
  name,
  domain,
  admin_first_name,
  admin_last_name,
}) => {
  const { data } = await axios.post("/api/v2/groups", {
    name,
    domain,
    admin_first_name,
    admin_last_name,
  });
  return data;
};

const addEmployeesApi = async ({ employees, isOnboarding, isSave }) => {
  const { data } = await axios.post("/api/v2/admin/employees/bulk_create", {
    employees: employees,
    is_onboarding: isOnboarding,
    save: isSave,
  });
  return data;
};

export { createOrgApi, addEmployeesApi };

import axios from "axios";

const getOrganizationApi = async () => {
  const { data } = await axios.get("/api/init");
  return data;
};

const updateGroupNameApi = async ({ name }) => {
  const { data } = await axios.put("/api/v2/admin/groups", { name });
  return data;
};

const updateGroupLogoApi = async ({ formData }) => {
  const { data } = await axios.post(
    "/api/v2/admin/groups/update_logo",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

const getSuperAdminGroupsApi = async () => {
  const { data } = await axios.get("/api/groups");
  return data;
};

const getSuperAdminGroupUsageApi = async (_, groupId) => {
  const { data } = await axios.get(`/api/group_usage/${groupId}`);
  return data;
};

export {
  getOrganizationApi,
  updateGroupNameApi,
  updateGroupLogoApi,
  getSuperAdminGroupsApi,
  getSuperAdminGroupUsageApi,
};

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Avatar, Typography, Popover, Space } from "antd";

import { getEmployeeName, getUserInitials } from "app/appUtils";
import "./EmployeeAvatar.css";

const { Text, Title } = Typography;

const stringToHslColor = (str = "", s, l) => {
  if (!str) {
    return "hsl(0, 0%, 89%)";
  }
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const EmployeeDetails = ({
  className = "",
  employee = {},
  height = "64px",
  width = "64px",
  textFontSize = "36px",
  backgroundColor = "",
}) => {
  return (
    <Space>
      <Avatar
        className={
          className ? clsx(className, "employee-avatar") : "employee-avatar"
        }
        size={48}
        src={employee?.user_profile_pic_url || employee?.profile_pic_url || ""}
        style={{
          backgroundColor:
            backgroundColor ||
            stringToHslColor(getEmployeeName(employee), 60, 50),
          height,
          width,
        }}
      >
        <Text style={{ fontSize: textFontSize, color: "white" }}>
          {getUserInitials(getEmployeeName(employee))}
        </Text>
      </Avatar>
      <div style={{ maxWidth: "360px" }}>
        <Title level={4}>{getEmployeeName(employee)}</Title>
        <Text type="secondary" className="block-display font-size-14">
          {employee?.designation}
        </Text>
        <Text type="secondary" className="block-display font-size-14">
          {employee?.department}
        </Text>
        <Text type="secondary" className="block-display font-size-14">
          {employee?.sub_department}
        </Text>
      </div>
    </Space>
  );
};

const EmployeeAvatar = ({
  className = "",
  employee = {},
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  backgroundColor = "",
  size = "default",
  onClick = () => {},
  ...props
}) => {
  return (
    <Avatar
      {...props}
      className={
        className ? clsx(className, "employee-avatar") : "employee-avatar"
      }
      size={size}
      src={employee?.user_profile_pic_url || employee?.profile_pic_url || ""}
      style={{
        backgroundColor:
          backgroundColor ||
          stringToHslColor(getEmployeeName(employee), 60, 50),
        height,
        width,
      }}
      onClick={onClick}
    >
      <Text style={{ fontSize: textFontSize, color: "white" }}>
        {getUserInitials(getEmployeeName(employee))}
      </Text>
    </Avatar>
  );
};

const EmployeeAvatarWrapper = ({
  className = "",
  employee = {},
  height = "32px",
  width = "32px",
  textFontSize = "18px",
  size = "default",
  backgroundColor = "",
  shouldShowDetails = true,
  onClick = () => {},
}) => {
  const employeeAvatarProps = {
    className,
    employee,
    height,
    width,
    textFontSize,
    backgroundColor,
    size,
    onClick,
  };

  if (shouldShowDetails) {
    return (
      <Popover
        title={null}
        zIndex={1050}
        content={
          <EmployeeDetails
            backgroundColor={backgroundColor}
            employee={employee}
          />
        }
      >
        <EmployeeAvatar {...employeeAvatarProps} />
      </Popover>
    );
  }
  return <EmployeeAvatar {...employeeAvatarProps} />;
};

EmployeeAvatarWrapper.propTypes = {
  employee: PropTypes.object,
};

export default EmployeeAvatarWrapper;

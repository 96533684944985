import React from "react";
import PropTypes from "prop-types";

import SettingsFeatureAlert from "./SettingsFeatureAlert";

const DisabledFeatureAlert = ({ featureName = "" }) => {
  return (
    <SettingsFeatureAlert
      text={`${featureName} is disabled. Please navigate to settings to enable it.`}
    />
  );
};

DisabledFeatureAlert.propTypes = {
  featureName: PropTypes.string,
};

export default DisabledFeatureAlert;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useInfiniteQuery, useQuery } from "react-query";
import { Row, Col, Button, Typography, Empty } from "antd";
import Masonry from "react-masonry-css";

import { BoxLoader, VimeoVideoModal } from "components";
import { useEmployees } from "hooks";
import PraiseFormModal from "./common/PraiseFormModal";
import PraiseCard from "./common/PraiseCard";
import { getBadgesApi, getPraisesApi } from "apis/praiseApis";
import { errorNotification } from "app/appUtils";
import "./Praise.css";

const { Title } = Typography;

const getLastItemId = (lastGroup = []) => {
  const morePagesExist = lastGroup?.length === 9;
  if (!morePagesExist) return false;
  return lastGroup[lastGroup.length - 1]?.id;
};

const Praise = (props) => {
  const {
    data,
    status,
    isFetchingMore,
    canFetchMore,
    fetchMore,
    refetch,
  } = useInfiniteQuery("getPraises", getPraisesApi, {
    getFetchMore: (lastGroup, allGroups) => {
      return getLastItemId(lastGroup);
    },
    cacheTime: 1,
    onError: (error) => {
      errorNotification(error);
    },
  });
  const { data: badges, status: badgesStatus } = useQuery(
    "getBadges",
    getBadgesApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );
  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
  } = useEmployees();

  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isFromShowMore, setIsFromShowMore] = useState(false);
  const [isVimeoModalVisible, setIsVimeoModalVisible] = useState(false);

  useEffect(() => {
    if (status === "success" && data) {
      if (isFromShowMore) {
        const updatedPaginatedData = [...paginatedData];
        let latestBatch = [];
        if (data.length > 0) {
          latestBatch = data[data.length - 1];
        }
        updatedPaginatedData.push([...latestBatch]);
        setPaginatedData(updatedPaginatedData);
        setIsFromShowMore(false);
      } else {
        setPaginatedData([...data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status]);

  const handleUpdatePaginationItem = (id, newPraise) => {
    const updatedPaginationItems = paginatedData.map((page) => {
      const containsItem = page.filter((e) => e?.id === id).length > 0;
      if (containsItem) {
        const updatedPage = page.map((item) => {
          if (item?.id === id) {
            return { ...newPraise };
          } else {
            if (item) {
              return { ...item };
            } else {
              return null;
            }
          }
        });
        return updatedPage;
      }
      return [...page];
    });
    setPaginatedData(updatedPaginationItems);
  };

  const handleDeletePaginationItem = (id) => {
    const updatedPaginationItems = paginatedData.map((page) => {
      const containsItem = page.filter((e) => e?.id === id).length > 0;
      if (containsItem) {
        // eslint-disable-next-line array-callback-return
        const updatedPage = page.map((item) => {
          if (!item) {
            return null;
          }
          if (item.id !== id) {
            return { ...item };
          }
          return null;
        });
        return updatedPage;
      }
      return [...page];
    });
    setPaginatedData(updatedPaginationItems);
  };

  const getLastAvailableId = () => {
    for (let i = paginatedData.length - 1; i >= 0; i--) {
      const page = paginatedData[i];
      for (let j = page.length - 1; j >= 0; j--) {
        if (page[j]) {
          return page[j].id;
        }
      }
    }
    return 0;
  };

  const handleFetchMore = () => {
    if (paginatedData?.length > 0) {
      const lastGroup = paginatedData[paginatedData?.length - 1];
      const lastItem = getLastItemId(lastGroup);
      if (lastItem) {
        fetchMore(lastItem.id);
      } else {
        // Incase the last item was deleted
        const lastItemId = getLastAvailableId();
        fetchMore(lastItemId);
      }
    } else {
      fetchMore(0);
    }
    setIsFromShowMore(true);
  };

  const handleFormDialogOpen = () => {
    setIsFormDialogOpen(!isFormDialogOpen);
  };

  const handleIsVimeoModalVisible = () => {
    setIsVimeoModalVisible(!isVimeoModalVisible);
  };

  const arrayOfPraisesJSX = () => {
    const returnData = [];
    if (paginatedData) {
      paginatedData.forEach((praises) =>
        praises?.forEach((praise) => {
          if (praise) {
            returnData.push(
              <PraiseCard
                praise={praise}
                refetch={refetch}
                badges={badges}
                employees={activeEmployees}
                inActiveEmployees={inActiveEmployees}
                handleUpdatePaginationItem={handleUpdatePaginationItem}
                handleDeletePaginationItem={handleDeletePaginationItem}
              />
            );
          }
        })
      );
    }

    return returnData;
  };

  if (
    status === "loading" ||
    isEmployeesLoading ||
    badgesStatus === "loading"
  ) {
    return <BoxLoader />;
  }

  const praiseCardData = arrayOfPraisesJSX();

  return (
    <div>
      <Helmet>
        <title>Praise - culture.easy</title>
      </Helmet>

      <Row>
        <Col span={8}>
          <Title level={2}>Praise</Title>
        </Col>
        <Col span={16} className="company-create-btn">
          <Row align="middle" justify="end" gutter={[16, 16]}>
            <Col xxl={{ span: 8 }} lg={{ span: 11 }}>
              {/* <TinyTutorialBtn
                text="Watch the tiny tutorial on Praise"
                onClick={handleIsVimeoModalVisible}
              /> */}
            </Col>
            <Col>
              <Button type="primary" onClick={handleFormDialogOpen}>
                Give Praise
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {isVimeoModalVisible && (
        <VimeoVideoModal
          title="Praise Tutorial"
          src="https://player.vimeo.com/video/488404271?color=e02c4b&title=0&byline=0&portrait=0"
          visible={isVimeoModalVisible}
          onCancel={handleIsVimeoModalVisible}
        />
      )}

      {isFormDialogOpen && (
        <PraiseFormModal
          visible={isFormDialogOpen}
          badges={badges}
          employees={activeEmployees}
          inActiveEmployees={inActiveEmployees}
          refetch={refetch}
          onClose={handleFormDialogOpen}
        />
      )}

      {praiseCardData?.length === 0 && (
        <Empty className="mb-8" description="No Praises yet." />
      )}

      <Masonry
        breakpointCols={{
          default: 3,
          1500: 3,
          1150: 2,
          700: 1,
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {praiseCardData}
      </Masonry>

      {isFetchingMore && (
        <div className="my-16">
          <BoxLoader />
        </div>
      )}

      {canFetchMore && (
        <Row justify="center">
          <Button
            size="small"
            disabled={!canFetchMore}
            onClick={handleFetchMore}
          >
            Show more
          </Button>
        </Row>
      )}
    </div>
  );
};

Praise.propTypes = {};

export default Praise;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row, Col, Divider, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import ChangeToObjective from "../../change-to-objective/ChangeToObjective";
import Initiative from "./Initiative";
import ItemActions from "./ItemActions";
import ItemTitle from "./ItemTitle";
import ChangeOwnerModal from "./ChangeOwnerModal";
import EditItem from "./EditItem";
import ActivityCommentsModal from "./activity-comments/ActivityCommentsModal";
import { deleteKeyResultApi } from "apis/okrV2Api";
import { successNotification, errorNotification } from "app/appUtils";

const { confirm } = Modal;

const KeyResult = ({
  childPaddingLeft = "0px",
  from = "my-okrs",
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  keyResult = {},
  objectiveTitle = "",
  objectiveId = "",
  objective = {},
  taggableKeyResults = [],
  selectedPeriodObj = {},
  initiatives = [],
  saveKeyResult = () => {},
  saveInitiative = () => {},
  refetch = () => {},
  detailsRefetch = () => {},
  refetchAncestorProgress = () => {},
  parentAncestorRefetch = () => {},
  parentDetailsRefetch = () => {},
}) => {
  const [activityCommentsString, setActivityCommentsString] = useState("");

  const [commentsCount, setCommentsCount] = useState(
    keyResult?.comments_count || 0
  );

  const parentCommentsCount = keyResult?.comments_count || 0;
  useEffect(() => {
    setCommentsCount(parentCommentsCount);
  }, [parentCommentsCount]);

  const [deleteKeyResult] = useMutation(deleteKeyResultApi, {
    onSuccess: () => {
      successNotification("Successfully deleted objective");
      refetch();
      detailsRefetch();
      refetchAncestorProgress();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [isChangeOwnerModalVisible, setIsChangeOwnerModalVisible] = useState(
    false
  );
  const [isEditKeyResultVisible, setIsEditKeyResultVisible] = useState(false);
  const [isChangeToObjectiveVisible, setIsChangeToObjectiveVisible] = useState(
    false
  );

  const handleIsChangeOwnerModalVisible = () => {
    setIsChangeOwnerModalVisible(!isChangeOwnerModalVisible);
  };

  const handleChangeOwner = (values) => {
    saveKeyResult({
      objectiveId,
      key_result: {
        id: keyResult?.id,
        owner_id: values?.owner_id,
      },
    });
    handleIsChangeOwnerModalVisible();
  };

  const handleDeleteKeyResult = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleFilled />,
      content: `This will delete key result. All progress will be lost. This can't be undone`,
      okButtonProps: { danger: true },
      onOk() {
        deleteKeyResult({
          objectiveId: objectiveId,
          keyResultId: keyResult?.id,
        });
      },
    });
  };

  const handleSaveKeyResult = (values) => {
    saveKeyResult({
      objectiveId,
      key_result: {
        id: keyResult?.id,
        ...values,
      },
    });
    handleIsEditVisible();
  };

  const handlePostponeKR = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleFilled />,
      content: `This will ${
        keyResult?.postponed ? "un postpone" : "postpone"
      } key result. This key result ${
        keyResult?.postponed ? "will be" : "will not be"
      } considered while calculating objective's percentage and status.`,
      okButtonProps: { danger: true },
      onOk() {
        saveKeyResult({
          objectiveId,
          key_result: {
            id: keyResult?.id,
            postponed: !keyResult?.postponed,
          },
        });
      },
    });
  };

  const handleSaveObjective = () => {
    refetch();
    detailsRefetch();
    parentDetailsRefetch();
    parentAncestorRefetch();
    refetchAncestorProgress();
    handleChangeToObjective();
  };

  const handleChangeToObjective = () => {
    setIsChangeToObjectiveVisible(!isChangeToObjectiveVisible);
  };

  const handleShowActivityComments = (tab = "comments") => {
    setActivityCommentsString(tab);
  };

  const handleCancelActivityComments = () => {
    setActivityCommentsString("");
  };

  const handleIsEditVisible = () => {
    setIsEditKeyResultVisible(!isEditKeyResultVisible);
  };

  const handleUpdateCommentsCount = (updatedCommentsCount) => {
    setCommentsCount(updatedCommentsCount);
  };

  const taggedInitiatives = initiatives?.filter((initiative) => {
    return initiative?.tagged_key_result_id === keyResult?.id;
  });

  return (
    <>
      {canUpdate && isChangeOwnerModalVisible && (
        <ChangeOwnerModal
          canUpdate={canUpdate && keyResult?.can_update}
          title={keyResult?.title}
          owner={keyResult?.owner}
          type="keyResult"
          visible={isChangeOwnerModalVisible}
          onCancel={handleIsChangeOwnerModalVisible}
          onOk={handleChangeOwner}
        />
      )}
      {canUpdate && isEditKeyResultVisible && (
        <EditItem
          selectedPeriodObj={selectedPeriodObj}
          keyResult={keyResult}
          objectiveTitle={objectiveTitle}
          visible={isEditKeyResultVisible}
          onCancel={handleIsEditVisible}
          onOk={handleSaveKeyResult}
        />
      )}
      {canUpdate && isChangeToObjectiveVisible && (
        <ChangeToObjective
          visible={isChangeToObjectiveVisible}
          keyResult={keyResult}
          parentObjective={objective}
          onSave={handleSaveObjective}
          onCancel={handleChangeToObjective}
        />
      )}
      {activityCommentsString && (
        <ActivityCommentsModal
          type="key_result"
          defaultSelectedTab={activityCommentsString}
          visible={activityCommentsString !== ""}
          objectiveId={objectiveId}
          keyResult={keyResult}
          commentsCount={commentsCount}
          onCancel={handleCancelActivityComments}
          handleUpdateCommentsCount={handleUpdateCommentsCount}
        />
      )}
      <Row className="pl-19 py-16 pr-16" align="middle">
        <Col
          xs={{ span: 24 }}
          lg={{ span: isSmallContainer ? 24 : 11 }}
          style={{ paddingLeft: childPaddingLeft }}
          // xxl={{
          //   span: isSmallContainer ? 24 : shouldNotExpandOnBigScreen ? 11 : 15,
          // }}
        >
          <ItemTitle
            keyResult={keyResult}
            shouldShrink={true}
            handleIsChangeOwnerModalVisible={handleIsChangeOwnerModalVisible}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{
            span: isSmallContainer ? 23 : 12,
            offset: isSmallContainer ? 2 : 1,
          }}
          // xxl={{
          //   span: isSmallContainer ? 23 : shouldNotExpandOnBigScreen ? 12 : 8,
          //   offset: isSmallContainer ? 2 : 1,
          // }}
        >
          <ItemActions
            from={from}
            canUpdate={canUpdate}
            isFromReviews={isFromReviews}
            isSmallContainer={isSmallContainer}
            isKRPostponed={keyResult?.postponed || false}
            type="key_result"
            objectiveId={objectiveId}
            keyResult={keyResult}
            commentsCount={commentsCount}
            selectedPeriodObj={selectedPeriodObj}
            refetch={refetch}
            detailsRefetch={detailsRefetch}
            saveItem={saveKeyResult}
            handleShowActivityComments={handleShowActivityComments}
            handleEdit={handleIsEditVisible}
            handlePostponeKR={handlePostponeKR}
            handleChangeToObjective={handleChangeToObjective}
            handleDelete={handleDeleteKeyResult}
            refetchAncestorProgress={refetchAncestorProgress}
          />
        </Col>
      </Row>
      {taggedInitiatives?.map((initiative) => (
        <div className="pl-48 py-16 pr-16">
          <Initiative
            from={from}
            childPaddingLeft={childPaddingLeft}
            canUpdate={canUpdate}
            isFromReviews={isFromReviews}
            objectiveId={objectiveId}
            objectiveTitle={objectiveTitle}
            initiative={initiative}
            taggableKeyResults={taggableKeyResults}
            selectedPeriodObj={selectedPeriodObj}
            saveInitiative={saveInitiative}
            detailsRefetch={detailsRefetch}
          />
        </div>
      ))}
      <Divider className="my-8" />
    </>
  );
};
KeyResult.propTypes = {
  keyResult: PropTypes.object,
  currentValue: PropTypes.number,
};

export default KeyResult;

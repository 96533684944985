const getStatusType = (status) => {
  if (status === "Not Started") {
    return ["danger", "Not Started"];
  }
  if (status === "In Progress") {
    return ["warning", "In Progress"];
  }
  if (status === "Submitted") {
    return ["success", "Submitted"];
  }
  if (status === "Unlocked") {
    return ["warning", "Unlocked"];
  }
  if (status === "Signed") {
    return ["primary", "Signed"];
  }
  return "";
};

const getReviewCycleTableDataSource = (cycle = {}) => {
  const reviews = cycle?.reviews || [];
  const tableData = reviews.map((review) => ({
    id: review?.id,
    reviewee: review?.reviewee?.name,
    revieweeStatus: review?.reviewee?.status,
    nudges: {
      revieweeNudgeAt: review?.reviewee?.nudge_at,
      reviewerNudgeAt: review?.reviewer?.nudge_at,
    },
    reviewerStatus: review?.reviewer?.status,
    reviewerNudgeAt: review?.reviewer?.nudge_at,
    revieweeObj: review?.reviewee,
    reviewerObj: review?.reviewer,
  }));
  return tableData;
};

const getNudgedReviews = (
  reviews = [],
  reviewId = "",
  who = "reviewee",
  nudgedAt = ""
) => {
  const updatedReviews = reviews?.map((review) => {
    if (review?.id === reviewId) {
      if (who === "reviewee") {
        return { ...review, reviewee_nudge_at: nudgedAt };
      } else {
        return { ...review, reviewer_nudge_at: nudgedAt };
      }
    }
    return { ...review };
  });
  return updatedReviews;
};

const getReviewCycleStatusCount = (cycle = {}) => {
  let revieweeNotStarted = 0;
  let revieweeStarted = 0;
  let revieweeSubmitted = 0;
  let revieweeSigned = 0;
  let reviewerNotStarted = 0;
  let reviewerStarted = 0;
  let reviewerSubmitted = 0;
  let reviewerSigned = 0;
  const reviews = cycle?.reviews || [];
  reviews.forEach((review) => {
    if (review?.reviewee?.status === "Not Started") {
      revieweeNotStarted += 1;
    }
    if (
      review?.reviewee?.status === "Unlocked" ||
      review?.reviewee?.status === "In Progress"
    ) {
      revieweeStarted += 1;
    }
    if (review?.reviewee?.status === "Submitted") {
      revieweeSubmitted += 1;
    }
    if (review?.reviewee?.status === "Signed") {
      revieweeSigned += 1;
    }

    if (review?.reviewer?.status === "Not Started") {
      reviewerNotStarted += 1;
    }
    if (
      review?.reviewer?.status === "Unlocked" ||
      review?.reviewer?.status === "In Progress"
    ) {
      reviewerStarted += 1;
    }
    if (review?.reviewer?.status === "Submitted") {
      reviewerSubmitted += 1;
    }
    if (review?.reviewer?.status === "Signed") {
      reviewerSigned += 1;
    }
  });

  return [
    {
      revieweeNotStarted,
      revieweeStarted,
      revieweeSubmitted,
      revieweeSigned,
    },
    {
      reviewerNotStarted,
      reviewerStarted,
      reviewerSubmitted,
      reviewerSigned,
    },
  ];
};

export {
  getStatusType,
  getNudgedReviews,
  getReviewCycleTableDataSource,
  getReviewCycleStatusCount,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { Collapse, Button, Row, Col, Form, Input, Grid } from "antd";
import { PlusOutlined, CaretRightOutlined } from "@ant-design/icons";

import { BoxLoader, ButtonWithSpinner, InlineSpinner } from "components";
import { useCurrentCompany } from "store";
import AddOKR from "../../add/AddOKR";
import ObjectivePanelHeader from "./ObjectivePanelHeader";
import KeyResult from "./KeyResult";
import Initiative from "./Initiative";
import {
  getObjectiveDetailsApi,
  saveKeyResultApi,
  saveInitiativeApi,
  getProgressHashApi,
} from "apis/okrV2Api";
import { successNotification, errorNotification } from "app/appUtils";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const ObjectivePanel = ({
  level = 0,
  className = "",
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  from = "my-okrs",
  selectedTeamId = "",
  objective = {},
  index = 0,
  percentageProgressData = {},
  parentObjectiveId = null,
  timePeriods = [],
  selectedPeriodObj,
  refetch,
  parentDetailsRefetch = () => {},
  parentAncestorRefetch = () => {},
  handleUpdateProgressHash = () => {},
  ...props
}) => {
  const screens = useBreakpoint();
  const [currentCompany] = useCurrentCompany();

  const [isAddingKeyResult, setIsAddingKeyResult] = useState(false);
  const [isAddingInitiative, setIsAddingInitiative] = useState(false);
  const [isAddOKRVisible, setIsAddOKRVisible] = useState(false);

  const { data, isLoading, isFetching, refetch: detailsRefetch } = useQuery(
    ["getObjectiveDetails", objective?.id],
    getObjectiveDetailsApi,
    {
      enabled: props?.isActive,
    }
  );

  const { refetch: refetchAncestorProgress } = useQuery(
    ["getProgressHash", objective?.id],
    getProgressHashApi,
    {
      enabled: false,
      onSuccess: (data) => {
        handleUpdateProgressHash({ ...percentageProgressData, ...data });
      },
    }
  );

  const [saveKeyResult, { isLoading: isSaveLoading }] = useMutation(
    saveKeyResultApi,
    {
      onSuccess: (data, variables) => {
        successNotification("Successfully saved key result");
        refetch();
        detailsRefetch();
        setIsAddingKeyResult(false);
        refetchAncestorProgress();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleAddKeyResult = () => {
    setIsAddingKeyResult(!isAddingKeyResult);
  };

  const handleIsAddOKRVisible = () => {
    setIsAddOKRVisible(!isAddOKRVisible);
  };

  const handleSaveNewObjective = () => {
    refetch();
    detailsRefetch();
    refetchAncestorProgress();
    handleIsAddOKRVisible();
  };

  const handleCancelForm = () => {
    setIsAddingKeyResult(false);
    setIsAddingInitiative(false);
  };

  const handleFormSubmit = (values, objectiveId) => {
    if (isAddingKeyResult) {
      saveKeyResult({
        key_result: {
          ...values,
          owner_id: currentCompany?.user?.id,
          kpi_metric: "%",
          start: 0,
          target: 100,
        },
        objectiveId,
      });
    } else if (isAddingInitiative) {
      saveInitiative({
        initiative: {
          ...values,
          owner_id: currentCompany?.user?.id,
          kpi_metric: "%",
          start: 0,
          target: 100,
        },
        objectiveId,
      });
    }
  };

  const [saveInitiative, { isLoading: isSaveInitiativeLoading }] = useMutation(
    saveInitiativeApi,
    {
      onSuccess: () => {
        successNotification("Successfully saved initiative");
        refetch();
        detailsRefetch();
        setIsAddingInitiative(false);
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleAddInitiative = () => {
    setIsAddingInitiative(!isAddingInitiative);
  };

  const unTaggedInitiatives = data?.initiatives?.filter((initiative) => {
    return !initiative?.tagged_key_result_id;
  });

  const deducedCanUpdate =
    canUpdate && objective?.can_update && !selectedPeriodObj?.locked;

  const shouldShiftTitleOnly = screens?.lg && !isSmallContainer;
  const shouldShiftAllChildren = !screens?.lg || isSmallContainer;

  const currentLevelPaddingLeft = shouldShiftTitleOnly
    ? `${level * 40}px`
    : "0px";
  const childPaddingLeft = shouldShiftTitleOnly
    ? `${(level + 1) * 40}px`
    : "0px";

  return (
    <>
      {isAddOKRVisible && (
        <AddOKR
          visible={isAddOKRVisible}
          selectedPeriodId={selectedPeriodObj?.id}
          selectedTeamId={selectedTeamId}
          parentObjectiveId={objective?.id}
          onSave={handleSaveNewObjective}
          onCancel={handleIsAddOKRVisible}
        />
      )}
      <Panel
        {...props}
        key={objective?.id}
        showArrow={false}
        header={
          <ObjectivePanelHeader
            from={from}
            currentLevelPaddingLeft={currentLevelPaddingLeft}
            isActive={props?.isActive}
            canUpdate={canUpdate}
            isFromReviews={isFromReviews}
            isSmallContainer={isSmallContainer}
            shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
            selectedTeamId={selectedTeamId}
            objective={objective}
            parentObjectiveId={parentObjectiveId}
            percentageProgressData={percentageProgressData}
            timePeriods={timePeriods}
            selectedPeriodObj={selectedPeriodObj}
            progressPercentage={objective?.progress_percentage || 0}
            refetch={refetch}
            detailsRefetch={detailsRefetch}
            refetchAncestorProgress={refetchAncestorProgress}
            parentDetailsRefetch={parentDetailsRefetch}
            parentAncestorRefetch={parentAncestorRefetch}
          />
        }
        className={`custom-collapse-panel ${className}`}
      >
        <div style={{ paddingLeft: shouldShiftAllChildren ? "40px" : "0px" }}>
          {isLoading && <BoxLoader height="10vh" />}
          {data?.key_results?.map((keyResult) => {
            return (
              <KeyResult
                from={from}
                childPaddingLeft={childPaddingLeft}
                canUpdate={canUpdate}
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                key={keyResult?.id}
                keyResult={keyResult}
                objectiveTitle={objective?.title || ""}
                objectiveId={objective?.id}
                objective={objective}
                selectedPeriodObj={selectedPeriodObj}
                initiatives={data?.initiatives}
                saveKeyResult={saveKeyResult}
                taggableKeyResults={data?.key_results}
                saveInitiative={saveInitiative}
                refetch={refetch}
                detailsRefetch={detailsRefetch}
                refetchAncestorProgress={refetchAncestorProgress}
                parentAncestorRefetch={parentAncestorRefetch}
                parentDetailsRefetch={parentDetailsRefetch}
              />
            );
          })}
          {data?.objectives?.length > 0 && (
            <>
              <Objectives
                ghost={true}
                from={from}
                level={level + 1}
                canUpdate={canUpdate}
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                selectedTeamId={selectedTeamId}
                objectives={data?.objectives}
                parentObjectiveId={objective?.id}
                percentageProgressData={percentageProgressData}
                timePeriods={timePeriods}
                selectedPeriodObj={selectedPeriodObj}
                refetch={refetch}
                parentDetailsRefetch={detailsRefetch}
                parentAncestorRefetch={refetchAncestorProgress}
                handleUpdateProgressHash={handleUpdateProgressHash}
              />
            </>
          )}
          {unTaggedInitiatives?.map((initiative) => (
            <div className="pl-19 py-16 pr-16">
              <Initiative
                childPaddingLeft={childPaddingLeft}
                from={from}
                canUpdate={canUpdate}
                isFromReviews={isFromReviews}
                isSmallContainer={isSmallContainer}
                shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
                objectiveId={objective?.id}
                objectiveTitle={objective?.title || ""}
                initiative={initiative}
                taggableKeyResults={data?.key_results}
                selectedPeriodObj={selectedPeriodObj}
                saveInitiative={saveInitiative}
                detailsRefetch={detailsRefetch}
              />
            </div>
          ))}
          <div style={{ paddingLeft: childPaddingLeft }}>
            {isAddingKeyResult || isAddingInitiative ? (
              <Form
                className="mt-16 pl-19"
                onFinish={(values) => handleFormSubmit(values, objective?.id)}
              >
                <Row align="middle" gutter={[16]}>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item noStyle name="title">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Button
                      size="small"
                      type="primary"
                      ghost={true}
                      onClick={handleCancelForm}
                    >
                      Cancel
                    </Button>
                    <ButtonWithSpinner
                      isSpinning={isSaveLoading || isSaveInitiativeLoading}
                      disabled={isSaveLoading || isSaveInitiativeLoading}
                      spinnerFontSize={15}
                      className="ml-8 inline-flex-display align-items-center"
                      size="small"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </ButtonWithSpinner>
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                {deducedCanUpdate && (
                  <Row className="mt-8 pl-19" gutter={[8]} justify="start">
                    <Col>
                      <Button
                        type="link"
                        className="pl-0"
                        onClick={handleAddKeyResult}
                      >
                        <PlusOutlined /> Add Key Result
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        className="pl-0"
                        onClick={handleIsAddOKRVisible}
                      >
                        <PlusOutlined /> Add Nested Objective
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        className="pl-0"
                        onClick={handleAddInitiative}
                      >
                        <PlusOutlined /> Add Initiative
                      </Button>
                    </Col>
                    <Col className="flex-display align-items-center">
                      {!isLoading && isFetching && (
                        <InlineSpinner fontSize={24} />
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        </div>
      </Panel>
    </>
  );
};
ObjectivePanel.propTypes = {};

const Objectives = ({
  level = 0,
  ghost = false,
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  from = "my-okrs",
  selectedTeamId = "",
  className = "",
  objectives = [],
  parentObjectiveId = null,
  percentageProgressData = {},
  timePeriods = [],
  selectedPeriodObj = {},
  refetch = () => {},
  parentDetailsRefetch = () => {},
  parentAncestorRefetch = () => {},
  handleUpdateProgressHash = () => {},
}) => {
  return (
    <>
      <Collapse
        ghost={ghost}
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className={`custom-collapse ${
          className === "root-panel" ? "root-collapse" : ""
        }`}
      >
        {objectives?.map((objective, index) => {
          return (
            <ObjectivePanel
              level={level}
              key={objective?.id}
              className={className}
              canUpdate={canUpdate}
              from={from}
              isFromReviews={isFromReviews}
              isSmallContainer={isSmallContainer}
              shouldNotExpandOnBigScreen={shouldNotExpandOnBigScreen}
              parentObjectiveId={parentObjectiveId}
              selectedTeamId={selectedTeamId}
              objective={objective}
              index={index}
              percentageProgressData={percentageProgressData}
              timePeriods={timePeriods}
              selectedPeriodObj={selectedPeriodObj}
              refetch={refetch}
              parentDetailsRefetch={parentDetailsRefetch}
              parentAncestorRefetch={parentAncestorRefetch}
              handleUpdateProgressHash={handleUpdateProgressHash}
            />
          );
        })}
      </Collapse>
    </>
  );
};
Objectives.propTypes = {
  sectionData: PropTypes.object,
  activeEmployees: PropTypes.array,
  inActiveEmployees: PropTypes.array,
};

export default Objectives;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row, Col, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import ItemTitle from "./ItemTitle";
import EditItem from "./EditItem";
import ItemActions from "./ItemActions";
import ChangeOwnerModal from "./ChangeOwnerModal";
import ActivityCommentsModal from "./activity-comments/ActivityCommentsModal";
import { successNotification, errorNotification } from "app/appUtils";
import { deleteInitiativeApi } from "apis/okrV2Api";

const { confirm } = Modal;

const Initiative = ({
  from = "my-okrs",
  canUpdate = true,
  childPaddingLeft = "0px",
  isFromReviews = false,
  isSmallContainer = false,
  shouldNotExpandOnBigScreen = false,
  objectiveId = "",
  objectiveTitle = "",
  initiative = {},
  taggableKeyResults = [],
  selectedPeriodObj = {},
  saveInitiative = () => {},
  detailsRefetch = () => {},
}) => {
  const [isChangeOwnerModalVisible, setIsChangeOwnerModelVisible] = useState(
    false
  );
  const [isEditKeyResultVisible, setIsEditKeyResultVisible] = useState(false);
  const [activityCommentsString, setActivityCommentsString] = useState("");
  const [commentsCount, setCommentsCount] = useState(
    initiative?.comments_count || 0
  );

  const parentCommentsCount = initiative?.comments_count || 0;
  useEffect(() => {
    setCommentsCount(parentCommentsCount);
  }, [parentCommentsCount]);

  const [deleteInitiative] = useMutation(deleteInitiativeApi, {
    onSuccess: () => {
      successNotification("Successfully deleted initiative");
      detailsRefetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleIsInitiativeChangeOwnerModalVisible = () => {
    setIsChangeOwnerModelVisible(!isChangeOwnerModalVisible);
  };

  const handleChangeOwner = (values) => {
    saveInitiative({
      objectiveId,
      initiative: {
        id: initiative?.id,
        owner_id: values?.owner_id,
        tagged_key_result_id: initiative?.tagged_key_result_id || null,
      },
    });
    handleIsInitiativeChangeOwnerModalVisible();
  };

  const handleSaveInitiative = (values) => {
    saveInitiative({
      objectiveId,
      initiative: {
        id: initiative?.id,
        ...values,
        tagged_key_result_id: values?.tagged_key_result_id || null,
      },
    });
    handleIsEditVisible();
  };

  const handleIsEditVisible = () => {
    setIsEditKeyResultVisible(!isEditKeyResultVisible);
  };

  const handleShowActivityComments = (tab = "comments") => {
    setActivityCommentsString(tab);
  };

  const handleCancelActivityComments = () => {
    setActivityCommentsString("");
  };

  const handleDeleteKeyResult = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleFilled />,
      content: `This will delete key result. All progress will be lost. This can't be undone`,
      okButtonProps: { danger: true },
      onOk() {
        deleteInitiative({
          objectiveId: objectiveId,
          initiativeId: initiative?.id,
        });
      },
    });
  };

  const handleUpdateCommentsCount = (updatedCommentsCount) => {
    setCommentsCount(updatedCommentsCount);
  };

  return (
    <>
      {isEditKeyResultVisible && (
        <EditItem
          type="initiative"
          selectedPeriodObj={selectedPeriodObj}
          objectiveTitle={objectiveTitle}
          initiative={initiative}
          visible={isEditKeyResultVisible}
          taggableKeyResults={taggableKeyResults}
          onCancel={handleIsEditVisible}
          onOk={handleSaveInitiative}
        />
      )}
      {isChangeOwnerModalVisible && (
        <ChangeOwnerModal
          canUpdate={canUpdate && initiative?.can_update}
          title={initiative?.title}
          owner={initiative?.owner}
          type="initiative"
          visible={isChangeOwnerModalVisible}
          onCancel={handleIsInitiativeChangeOwnerModalVisible}
          onOk={handleChangeOwner}
        />
      )}
      {activityCommentsString && (
        <ActivityCommentsModal
          defaultSelectedTab={activityCommentsString}
          visible={activityCommentsString !== ""}
          objectiveId={objectiveId}
          type="initiative"
          initiative={initiative}
          commentsCount={commentsCount}
          onCancel={handleCancelActivityComments}
          handleUpdateCommentsCount={handleUpdateCommentsCount}
        />
      )}
      <Row align="middle">
        <Col
          xs={{ span: 24 }}
          lg={{ span: isSmallContainer ? 24 : 11 }}
          style={{ paddingLeft: childPaddingLeft }}
          // xxl={{
          //   span: isSmallContainer ? 24 : shouldNotExpandOnBigScreen ? 11 : 15,
          // }}
        >
          <ItemTitle
            type="initiative"
            initiative={initiative}
            shouldShrink={true}
            handleIsChangeOwnerModalVisible={
              handleIsInitiativeChangeOwnerModalVisible
            }
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{
            span: isSmallContainer ? 23 : 12,
            offset: isSmallContainer ? 2 : 1,
          }}
          // xxl={{
          //   span: isSmallContainer ? 23 : shouldNotExpandOnBigScreen ? 12 : 8,
          //   offset: isSmallContainer ? 2 : 1,
          // }}
        >
          <ItemActions
            type="initiative"
            canUpdate={canUpdate}
            from={from}
            isFromReviews={isFromReviews}
            isSmallContainer={isSmallContainer}
            objectiveId={objectiveId}
            initiative={initiative}
            commentsCount={commentsCount}
            selectedPeriodObj={selectedPeriodObj}
            refetch={() => {}}
            saveItem={saveInitiative}
            detailsRefetch={detailsRefetch}
            handleShowActivityComments={handleShowActivityComments}
            handleEdit={handleIsEditVisible}
            handleDelete={handleDeleteKeyResult}
          />
        </Col>
      </Row>
    </>
  );
};

Initiative.propTypes = {
  initiative: PropTypes.object,
  activeEmployees: PropTypes.array,
  inActiveEmployees: PropTypes.array,
};

export default Initiative;

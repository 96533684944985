import React from "react";
import PropTypes from "prop-types";
import { Row, Input, Dropdown, Button, Menu, Checkbox, Typography } from "antd";
import { debounce } from "lodash";
import { FilterOutlined } from "@ant-design/icons";

import { ButtonWithSpinner } from "components";
const { Text } = Typography;

const MenuFilters = ({ filters = {}, setFilters = () => {}, ...props }) => {
  const statuses = filters?.selectedStatuses || [];
  const alignment = filters?.alignment || [];

  const handleStatusChange = (selectedStatuses) => {
    setFilters({ ...filters, selectedStatuses });
  };

  const handleAlignmentChange = (selectedAlignment) => {
    setFilters({ ...filters, alignment: selectedAlignment });
  };

  // const handleIsAlignedChange = (isAligned) => {
  //   setFilters({ ...filters, aligned: isAligned });
  // };

  return (
    <>
      <div id="okr-filter-menu"></div>
      <Menu
        getPopupContainer={() => document.getElementById("okr-filter-menu")}
        triggerSubMenuAction="click"
        {...props}
      >
        <Menu.SubMenu key="status" title="Status">
          <div
            className="m-16 width-150-px"
            onClick={(e) => e.stopPropagation()}
          >
            <Checkbox.Group value={statuses} onChange={handleStatusChange}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox
                  className="ml-8"
                  key="not_started"
                  value="not_started"
                >
                  Not Started
                </Checkbox>
                <Checkbox key="at_risk" value="at_risk">
                  At Risk
                </Checkbox>
                <Checkbox key="behind" value="behind">
                  Behind
                </Checkbox>
                <Checkbox key="on_track" value="on_track">
                  On Track
                </Checkbox>
                {/* <Checkbox key="postponed" value="postponed">
                  Postponed
                </Checkbox> */}
                <Checkbox key="done" value="done">
                  Done
                </Checkbox>
              </Row>
            </Checkbox.Group>
          </div>
        </Menu.SubMenu>
        {/* <Menu.Item key="aligned" title="Aligned">
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              key="isAligned"
              value="isAligned"
              checked={filters?.aligned}
              onChange={(e) => {
                handleIsAlignedChange(e?.target?.checked);
              }}
            >
              Aligned
            </Checkbox>
          </div>
        </Menu.Item> */}

        <Menu.SubMenu key="alignment" title="Alignment">
          <div
            className="m-16 width-150-px"
            onClick={(e) => e.stopPropagation()}
          >
            <Checkbox.Group value={alignment} onChange={handleAlignmentChange}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox className="ml-8" key="aligned" value="aligned">
                  Aligned
                </Checkbox>
                <Checkbox key="not_aligned" value="not_aligned">
                  Not Aligned
                </Checkbox>
              </Row>
            </Checkbox.Group>
          </div>
        </Menu.SubMenu>
      </Menu>
    </>
  );
};
MenuFilters.propTypes = {
  filters: PropTypes.object,
};

const SearchAndFilters = ({
  isSearching = false,
  filters = {},
  setFilters = () => {},
  handleAPISearch = () => {},
}) => {
  const handleSearch = (e) => {
    const searchTerm = e?.target?.value || "";
    debounce(() => {
      setFilters({ ...filters, search: searchTerm });
    }, 500)();
  };

  return (
    <>
      <div
        className="mx-16"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Text type="secondary">Keyword</Text>
        <Input
          className="width-400-px height-42-px"
          value={filters?.searchTerm}
          placeholder="Search Objective Title"
          size="small"
          onChange={handleSearch}
        />
      </div>
      <div id="okr-search-filter" />
      <Dropdown
        overlay={<MenuFilters filters={filters} setFilters={setFilters} />}
        getPopupContainer={() => document.getElementById("okr-search-filter")}
        trigger={"click"}
        placement="bottomCenter"
        arrow
      >
        <Button
          className="mr-16"
          // style={{ height: "40px", paddingTop: "9px", marginTop: "24px" }}
        >
          <FilterOutlined />
        </Button>
      </Dropdown>

      <ButtonWithSpinner
        // style={{ height: "40px", paddingTop: "10px", marginTop: "24px" }}
        isSpinning={isSearching}
        spinnerColor={"#5330C9"}
        disabled={isSearching}
        type="primary"
        ghost={true}
        onClick={handleAPISearch}
      >
        Search
      </ButtonWithSpinner>
    </>
  );
};

SearchAndFilters.propTypes = {};

export default SearchAndFilters;

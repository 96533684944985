import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { Space, Typography, Tag, Tooltip } from "antd";
// import { MenuUnfoldOutlined } from "@ant-design/icons";
// import Icon from "@ant-design/icons";

import { EmployeeAvatar } from "components";
import { ReactComponent as InitiativesIcon } from "assets/icons/i_icon.svg";
// import { useWindowWidth } from "hooks";

const { Text } = Typography;

const ItemTitle = ({
  ellipsis = false,
  type = "key_result",
  className = "",
  shouldShrink = false,
  keyResult = {},
  initiative = {},
  handleIsChangeOwnerModalVisible = () => {},
}) => {
  // const width = useWindowWidth();

  let owner = {};
  let title = "";

  const isKeyResult = type === "key_result";
  if (isKeyResult) {
    owner = keyResult?.owner;
    title = keyResult?.title;
  } else {
    owner = initiative?.owner;
    title = initiative?.title;
  }

  return (
    <Space className={className}>
      {isKeyResult ? (
        <Text type="secondary">KR</Text>
      ) : (
        <InitiativesIcon
          className="vertical-align-middle"
          // component={InitiativesIcon}
        />
      )}
      <EmployeeAvatar
        className="mr-8"
        employee={owner}
        onClick={(e) => {
          e.stopPropagation();
          handleIsChangeOwnerModalVisible();
        }}
      />
      <Tooltip title={ellipsis ? title : null}>
        <Text
          style={{
            display: "block",
            // width:
            //   shouldShrink && width >= 1200 && width <= 1350 ? "70%" : "100%",
          }}
        >
          {ellipsis
            ? `${title?.length > 45 ? `${title?.slice(0, 45)}...` : title}`
            : title}
        </Text>
      </Tooltip>
      {isKeyResult && keyResult?.due_date && (
        <Tag>
          Due Date: <Moment format="MMM D">{keyResult?.due_date}</Moment>
        </Tag>
      )}
    </Space>
  );
};

ItemTitle.propTypes = {
  keyResult: PropTypes.object,
  handleIsChangeOwnerModalVisible: PropTypes.func,
};

export default ItemTitle;

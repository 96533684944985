import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Modal, Select, Form, Typography } from "antd";

import { ButtonWithSpinner, APIBasedEmployeeSearch } from "components";
import { cloneObjectiveApi } from "apis/okrV2Api";
import { useCurrentCompany } from "store";
import {
  successNotification,
  errorNotification,
  filterOptionsByLabel,
  formattedDate,
} from "app/appUtils";

const { Option } = Select;
const { Text } = Typography;

const TimePeriodSelect = ({
  value,
  timePeriods = [],
  objective = {},
  onChange = () => {},
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        value={value}
        onChange={onChange}
        showSearch
        optionFilterProp="label"
        filterOption={filterOptionsByLabel}
      >
        {timePeriods?.map((period) => {
          if (period?.id === objective?.objective_period?.id) {
            return null;
          }
          return (
            <Option value={period?.id} label={period?.name}>
              <Text>{`${period?.name}`}</Text>
              <Text
                type="secondary"
                className="font-size-14 ml-8"
              >{`(${formattedDate(period?.start_date)} - ${formattedDate(
                period?.end_date
              )})`}</Text>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

const CloneObjectiveModal = ({
  visible = false,
  timePeriods = [],
  objective = {},
  onCancel = () => {},
  onOk = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  const [
    cloneObjective,
    {
      status: saveStatus,
      isLoading: isSaveLoading,
      isFetching: isSaveFetching,
    },
  ] = useMutation(cloneObjectiveApi, {
    onSuccess: () => {
      successNotification("Cloned objective");
      onOk();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [form] = Form.useForm();

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        cloneObjective({
          objectiveId: objective?.id,
          periodId: values?.objective_period_id,
          owner_ids: values?.owner_ids,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const isSaving = saveStatus === "loading" || isSaveLoading || isSaveFetching;

  return (
    <Modal
      title="Clone Objective"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <ButtonWithSpinner
          disabled={isSaving}
          type="primary"
          ghost={true}
          onClick={handleCancel}
        >
          Cancel
        </ButtonWithSpinner>,
        <ButtonWithSpinner
          disabled={isSaving}
          isSpinning={isSaving}
          type="primary"
          onClick={handleSubmit}
        >
          Clone
        </ButtonWithSpinner>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          owner_ids: [currentCompany?.user?.id],
        }}
      >
        <Form.Item
          name="objective_period_id"
          label="Time Period"
          rules={[
            {
              required: true,
              message: "Please select a time period",
            },
          ]}
          required={false}
        >
          <TimePeriodSelect timePeriods={timePeriods} objective={objective} />
        </Form.Item>
        <Form.Item
          name="owner_ids"
          label="Owner"
          rules={[{ required: true, message: "Missing Owner." }]}
          required={false}
        >
          <APIBasedEmployeeSearch
            mode={"multiple"}
            shouldShowAvatar={true}
            currentOwners={[currentCompany?.user]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

CloneObjectiveModal.propTypes = {
  timePeriods: PropTypes.array,
  objective: PropTypes.object,
};

export default CloneObjectiveModal;

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import { getStatusType } from "app/company/reviews/common/reviewUtils";

const { Text } = Typography;

const StatusText = ({ text = "" }) => {
  const [type, displayText] = getStatusType(text);
  return (
    <Text type={type} className={type === "primary" && "primary-typography"}>
      {displayText}
    </Text>
  );
};

StatusText.propTypes = {
  text: PropTypes.string,
};

export default StatusText;

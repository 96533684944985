import { threeSixtyDegreeFeedback } from "app/appConstants";

const firebaseApiMock = () => {
  return {
    kind: "identitytoolkit#GetAccountInfoResponse",
    users: [
      {
        localId: "rPuSOsn63YOOF9YGvviwTn38Ikn1",
        email: "revanth@beautifulcode.in",
        displayName: "Revanth Boyapally",
        photoUrl:
          "https://lh3.googleusercontent.com/a-/AOh14GgbMgSaZW5tA3Tq-eXPN4ifZzOBf2xrouIX5aSZ",
        emailVerified: true,
        providerUserInfo: [
          {
            providerId: "google.com",
            displayName: "Revanth Boyapally",
            photoUrl:
              "https://lh3.googleusercontent.com/a-/AOh14GgbMgSaZW5tA3Tq-eXPN4ifZzOBf2xrouIX5aSZ",
            federatedId: "113782542757407756511",
            email: "revanth@beautifulcode.in",
            rawId: "113782542757407756511",
          },
        ],
        validSince: "1599560735",
        lastLoginAt: "1599564519428",
        createdAt: "1599560735626",
        lastRefreshAt: "2020-09-09T05:27:18.277Z",
      },
    ],
  };
};

const appInit = () => {
  return [
    {
      id: 1,
      name: "localOrg1",
      trial_days_remaining: null,
      logo_url: "",
      domain: "",
      website: "",
      role: "admin",
      features: [
        {
          id: 1,
          enabled: true,
          name: "Reviews",
          editable: true,
        },
        {
          id: 2,
          enabled: true,
          name: "Surveys",
          editable: true,
        },
        {
          id: 3,
          enabled: true,
          name: "Objectives and Key Results (OKRs)",
          editable: true,
        },
        {
          id: 4,
          enabled: true,
          name: "Praise",
          editable: false,
        },
        {
          id: 5,
          enabled: true,
          name: `${threeSixtyDegreeFeedback}s`,
          editable: true,
        },
      ],
      steps: [
        {
          id: 1,
          name: "Add employees to the Company Directory",
          description: "",
          order: 1,
          link: "http://localhost:3000/settings/directory",
          link_extension: "/settings/directory",
          link_description: "Go to Directory",
          active: true,
          completed: false,
        },
        {
          id: 2,
          name: "Company name set to localOrg1",
          description: "",
          order: 2,
          link: "http://localhost:3000/settings/directory",
          link_extension: "/settings/directory",
          link_description: "Go to Directory",
          active: true,
          completed: true,
        },
        {
          id: 3,
          name: "Explore Reviews",
          description: "Watch the Reviews video or create a Review cycle.",
          order: 3,
          link: "http://localhost:3000/company/reviews",
          link_extension: "/company/reviews",
          link_description: "Go to Reviews",
          active: true,
          completed: true,
        },
        {
          id: 4,
          name: "Explore Objectives and Key Results (OKRs)",
          description:
            "Watch the Objectives video or create a Objective cycle.",
          order: 4,
          link: "http://localhost:3000/company/objectives",
          link_extension: "/company/objectives",
          link_description: "Go to Objectives",
          active: true,
          completed: false,
        },
        {
          id: 5,
          name: "Create your first Praise",
          description: "",
          order: 5,
          link: "http://localhost:3000/praise",
          link_extension: "/praise",
          link_description: "Go to Praise",
          active: true,
          completed: true,
        },
      ],
      user: {
        id: 1,
        first_name: "Pranav",
        last_name: "PB",
        email: "pranav@beautifulcode.in",
        name: "Pranav PB",
        active: true,
        user_first_name: "",
        user_last_name: "",
        user_profile_pic_url: "",
        manager: null,
      },
    },
  ];
};

export { appInit, firebaseApiMock };

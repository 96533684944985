import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Row, Typography, Dropdown, Grid, Tooltip, Switch, Badge } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import { MoreOutlined } from "@ant-design/icons";

import ProgressSlider from "./ProgressSlider";
import ItemMenu from "./ItemMenu";
import {
  postKeyResultCheckInApi,
  postInitiativeCheckInApi,
} from "apis/okrV2Api";
import { getPercentageFromValue, getActionStyles } from "../utils";
import { successNotification, errorNotification } from "app/appUtils";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const getProgress = (isKeyResult, start = 0, lastCheckIn = {}) => {
  return lastCheckIn?.progress ? lastCheckIn?.progress : start;
};

const ItemActions = ({
  from = "my-okrs",
  canUpdate = true,
  isFromReviews = false,
  isSmallContainer = false,
  isKRPostponed = false,
  type = "key_result",
  objectiveId = "",
  keyResult = {},
  commentsCount = 0,
  selectedPeriodObj = {},
  initiative = {},
  refetch = () => {},
  detailsRefetch = () => {},
  saveItem = () => {},
  handleShowActivityComments = () => {},
  handleEdit = () => {},
  handlePostponeKR = () => {},
  handleChangeToObjective = () => {},
  handleDelete = () => {},
  refetchAncestorProgress = () => {},
}) => {
  const screens = useBreakpoint();
  const isKeyResult = type === "key_result";

  const [isProgressPopoverVisible, setIsProgressPopoverVisible] = useState(
    false
  );

  const [
    postKeyResultCheckIn,
    {
      status: checkInStatus,
      isLoading: checkInIsLoading,
      isFetching: checkInISFetching,
    },
  ] = useMutation(postKeyResultCheckInApi, {
    onSuccess: () => {
      successNotification("Saved Checkin");
      refetch();
      detailsRefetch();
      refetchAncestorProgress();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [
    postInitiativeCheckIn,
    {
      status: initiativeCheckInStatus,
      isLoading: initiativeCheckInIsLoading,
      isFetching: initiativeCheckInISFetching,
    },
  ] = useMutation(postInitiativeCheckInApi, {
    onSuccess: () => {
      successNotification("Saved Checkin");
      detailsRefetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleBooleanCheckIn = (typeId, checked) => {
    if (isKeyResult) {
      postKeyResultCheckIn({
        objectiveId,
        keyResultId: typeId,
        checkin: {
          progress: checked ? 1 : 0,
          status: checked ? "done" : "behind",
        },
      });
    } else {
      postInitiativeCheckIn({
        objectiveId,
        initiativeId: typeId,
        checkin: {
          progress: checked ? 1 : 0,
        },
      });
    }
  };

  const handlePopover = () => {
    setIsProgressPopoverVisible(!isProgressPopoverVisible);
  };

  const handleConvertYesNoItem = (isYesNoKR = true) => {
    const updatedItem = {
      objectiveId,
    };

    if (isKeyResult) {
      updatedItem["key_result"] = {
        id: keyResult?.id,
        is_boolean: isYesNoKR,
      };
    } else {
      updatedItem["initiative"] = {
        id: initiative?.id,
        is_boolean: isYesNoKR,
      };
    }

    saveItem({ ...updatedItem });
  };

  const {
    start,
    target,
    kpi_metric,
    postponed,
    last_checkin: lastCheckIn,
  } = isKeyResult ? keyResult : initiative;
  const [progressValue, setProgressValue] = useState(
    getProgress(isKeyResult, start, lastCheckIn)
  );
  const currentStatus = postponed
    ? "postponed"
    : lastCheckIn?.status
    ? lastCheckIn?.status || "not_started"
    : "not_started";
  const currentProgressPercentage = getPercentageFromValue(
    progressValue,
    start,
    target
  );
  useEffect(() => {
    setProgressValue(getProgress(isKeyResult, start, lastCheckIn));
  }, [isKeyResult, lastCheckIn, start]);

  // const getCommentsLength = () => {
  //   let commentsLength = 0;
  //   comments.forEach((comment) => {
  //     if (!comment.deleted) {
  //       commentsLength += 1;
  //     }
  //   });
  //   return commentsLength;
  // };

  // const commentsLength = getCommentsLength();
  const hasComments = commentsCount > 0 || false;

  let isItemBoolean = isKeyResult
    ? keyResult?.is_boolean
    : initiative?.is_boolean;

  let isDisabled = false;
  if (isKeyResult) {
    isDisabled =
      !canUpdate || !keyResult?.can_update || selectedPeriodObj?.locked;
  } else {
    isDisabled =
      !canUpdate || !initiative?.can_update || selectedPeriodObj?.locked;
  }

  return (
    <div style={getActionStyles(screens, isSmallContainer, 1)}>
      {isItemBoolean ? (
        <Switch
          className={`mr-16 ${!isKeyResult && "initiative-switch"} ${
            keyResult?.postponed && "postponed-key-result"
          }`}
          size={isKeyResult ? "default" : "small"}
          disabled={isDisabled}
          checked={currentProgressPercentage === 100}
          loading={
            checkInStatus === "loading" ||
            checkInIsLoading ||
            checkInISFetching ||
            initiativeCheckInStatus === "loading" ||
            initiativeCheckInISFetching ||
            initiativeCheckInIsLoading
          }
          onChange={(checked) => {
            handleBooleanCheckIn(
              isKeyResult ? keyResult?.id : initiative?.id,
              checked
            );
          }}
        />
      ) : (
        <div
          className="mr-16"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isKeyResult ? "flex-start" : "flex-end",
          }}
        >
          <ProgressSlider
            disabled={isDisabled}
            isKeyResult={isKeyResult}
            isFromReviews={isFromReviews}
            isProgressPopoverVisible={isProgressPopoverVisible}
            setIsProgressPopoverVisible={setIsProgressPopoverVisible}
            objectiveId={objectiveId}
            itemId={isKeyResult ? keyResult?.id : initiative?.id}
            metric={kpi_metric}
            current={progressValue}
            currentStatus={currentStatus}
            start={isKeyResult ? keyResult?.start : initiative?.start}
            target={isKeyResult ? keyResult?.target : initiative?.target}
            dueDate={isKeyResult ? keyResult?.due_date : null}
            selectedPeriodObj={selectedPeriodObj}
            refetch={refetch}
            detailsRefetch={detailsRefetch}
            setProgressValue={setProgressValue}
            refetchAncestorProgress={refetchAncestorProgress}
          />

          {kpi_metric !== "%" ? (
            <Row
              className="mt-4 max-width-330px"
              align="middle"
              justify="start"
            >
              <Text className="font-size-12">
                {`${kpi_metric}: ${progressValue} (Target: ${
                  isKeyResult ? keyResult?.target : initiative?.target
                })`.toUpperCase()}
              </Text>
              {isKeyResult && <div className="grey-dot" />}
              {isKeyResult && lastCheckIn ? (
                <>
                  <Text className="font-size-12">LAST CHECK-IN:</Text>
                  <Moment className="ml-4 ant-typography font-size-12" fromNow>
                    {lastCheckIn?.created_at || new Date()}
                  </Moment>
                </>
              ) : (
                <Text className="font-size-12">
                  {isKeyResult && "No Check-ins yet".toUpperCase()}
                </Text>
              )}
            </Row>
          ) : (
            <>
              {isKeyResult && lastCheckIn ? (
                <Row className="mt-4 max-width-330px">
                  <Text className="font-size-12">LAST CHECK-IN:</Text>
                  <Moment className="ml-4 ant-typography font-size-12" fromNow>
                    {lastCheckIn?.created_at || new Date()}
                  </Moment>
                </Row>
              ) : (
                <Text className="font-size-12">
                  {isKeyResult && "No Check-ins yet".toUpperCase()}
                </Text>
              )}
            </>
          )}
        </div>
      )}

      {isItemBoolean ? (
        <Text
          style={{
            minWidth:
              currentProgressPercentage >= 100 || screens.xxl ? "71px" : "66px",
            marginRight: hasComments ? "12px" : "30px",
          }}
        >
          {currentProgressPercentage === 100 ? "Yes" : "No"}
        </Text>
      ) : (
        <>
          {currentProgressPercentage <= 120 ? (
            <Text
              style={{
                minWidth:
                  currentProgressPercentage >= 100 || screens.xxl
                    ? "71px"
                    : "71px",
                marginRight: hasComments ? "12px" : "30px",
              }}
            >{`${Math.round(currentProgressPercentage)}%`}</Text>
          ) : (
            <span
              style={{
                minWidth: "79px",
                maxWidth: "79px",
                marginRight: hasComments ? "4px" : "22px",
              }}
            >
              <Text className="width-50-px mr-8">{`${Math.round(
                currentProgressPercentage
              )}%`}</Text>
              {isKeyResult && (
                <Tooltip title="Overachieved key results (>120%) are marked with the emoji">
                  <span role="img" aria-label="flex-bicep">
                    💪
                  </span>
                </Tooltip>
              )}
            </span>
          )}
        </>
      )}

      {hasComments && (
        <span className="mt-4">
          <Badge
            size="small"
            style={{
              color: "#999",
              backgroundColor: "#fff",
              fontSize: "12px",
              border: "1px solid",
              // padding: 4,
            }}
            count={commentsCount}
          >
            <CommentOutlined
              onClick={() => handleShowActivityComments("comments")}
            />
          </Badge>
        </span>
      )}

      <Dropdown
        trigger="click"
        overlay={
          <ItemMenu
            canUpdate={!isDisabled}
            isKeyResult={isKeyResult}
            isItemBoolean={isItemBoolean}
            isKRPostponed={isKRPostponed}
            handleIsEditVisible={handleEdit}
            handlePostponeKR={handlePostponeKR}
            handleChangeToObjective={handleChangeToObjective}
            handleIsCommentsModalVisible={() =>
              handleShowActivityComments("comments")
            }
            handleIsActivityModalVisible={() =>
              handleShowActivityComments("activity")
            }
            handlePopover={handlePopover}
            handleDelete={handleDelete}
            handleConvertYesNoItem={handleConvertYesNoItem}
          />
        }
        getPopupContainer={() =>
          document.getElementById("key-results-initiatives-three-dot-container")
        }
        className="ml-16 mt-4"
      >
        <MoreOutlined />
      </Dropdown>
    </div>
  );
};

ItemActions.propTypes = {
  type: PropTypes.string,
  objectiveId: PropTypes.string,
  keyResult: PropTypes.object,
  selectedPeriodObj: PropTypes.object,
  initiative: PropTypes.object,
  refetch: PropTypes.func,
  detailsRefetch: PropTypes.func,
  handleShowActivityComments: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ItemActions;

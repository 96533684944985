import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useMutation, useQueryCache } from "react-query";
import { Typography, Button, Row, Popover, InputNumber, Form } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { useCurrentCompany } from "store";
import { ButtonWithSpinner, InlineSpinner } from "components";
import { saveTargetValueApi, removeTargetValueApi } from "apis/kpis";
import { successNotification, errorNotification } from "app/appUtils";

const { Text } = Typography;

const yearFormat = "YYYY";

const thisYear = moment()?.format(yearFormat);

const months = [
  { month: "1", label: "Jan" },
  { month: "2", label: "Feb" },
  { month: "3", label: "Mar" },
  { month: "4", label: "Apr" },
  { month: "5", label: "May" },
  { month: "6", label: "Jun" },
  { month: "7", label: "Jul" },
  { month: "8", label: "Aug" },
  { month: "9", label: "Sep" },
  { month: 10, label: "Oct" },
  { month: 11, label: "Nov" },
  { month: 12, label: "Dec" },
];

const constructContinuousTargetValues = (
  targetValues = {},
  currentYear = thisYear
) => {
  const results = [];

  let previousValue = null;
  const availableDates = Object.keys(targetValues) || [];
  availableDates.sort((date1, date2) => {
    if (moment(date1, "MM-YYYY")?.isBefore(moment(date2, "MM-YYYY"))) {
      return -1;
    } else if (moment(date2, "MM-YYYY")?.isBefore(moment(date1, "MM-YYYY"))) {
      return 1;
    }
    return 0;
  });
  for (let i = 0; i < availableDates?.length; i++) {
    const lastAvailableDate = availableDates[i];
    if (
      moment(lastAvailableDate, "MM-YYYY")?.isBefore(
        moment(`01-${currentYear}`, "MM-YYYY")
      )
    ) {
      previousValue = targetValues[lastAvailableDate];
    }
  }

  months.forEach(({ month, label }) => {
    if (targetValues[`${month}-${currentYear}`] !== undefined) {
      previousValue = targetValues[`${month}-${currentYear}`];
      results.push({
        label,
        dateString: `${month}-${currentYear}`,
        id: targetValues[`${month}-${currentYear}`]?.id,
        value: targetValues[`${month}-${currentYear}`]?.target_value,
        operator: targetValues[`${month}-${currentYear}`]?.operator,
        isFromPreviousValue: false,
      });
    } else {
      results.push({
        label,
        dateString: `${month}-${currentYear}`,
        value: previousValue?.target_value,
        operator: previousValue?.operator || ">=",
        isFromPreviousValue: true,
      });
    }
  });

  return results;
};

const TargetUpdateContent = ({
  kpiId = null,
  targetValueId = null,
  checkinId = null,
  metric,
  date = "",
  operator = ">=",
  currentValue,
  setIsPopoverVisible = () => {},
  refetch = () => {},
}) => {
  const queryCache = useQueryCache();

  const [targetForm] = Form.useForm();
  const [targetValue, setTargetValue] = useState(currentValue);

  const [saveTargetValue, { isLoading }] = useMutation(saveTargetValueApi, {
    onSuccess: (data, variables) => {
      successNotification("Successfully updated target value");
      queryCache.invalidateQueries("getKPIActivityData");
      setIsPopoverVisible(false);
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [removeTargetValue, { isLoading: isRemovingTargetValue }] = useMutation(
    removeTargetValueApi,
    {
      onSuccess: () => {
        successNotification("Successfully removed target value");
        queryCache.invalidateQueries("getKPIActivityData");
        setIsPopoverVisible(false);
        refetch();
        targetForm.resetFields();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleChange = (value) => {
    setTargetValue(value);
  };

  const handleChangeOperator = (val) => {
    targetForm.setFieldsValue({ operator: val });
  };

  const handleRemoveTarget = () => {
    removeTargetValue({
      kpiId,
      checkinId,
    });
  };

  const handleSubmit = (values) => {
    saveTargetValue({ kpiId, checkin: { ...values } });
  };

  return (
    <Form
      form={targetForm}
      initialValues={{
        id: targetValueId,
        target_value: currentValue,
        operator,
        date,
      }}
      onFinish={handleSubmit}
    >
      <Text className="block-display">This KPI should be</Text>
      <Row className="mb-24" align="middle">
        <Form.Item noStyle visible={false} name="id" />
        <Form.Item noStyle visible={false} name="operator" />
        <Form.Item noStyle visible={false} name="date" />
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const selectedOperator = getFieldValue("operator");

            return (
              <>
                <Button
                  size="small"
                  type={selectedOperator === ">=" ? "primary" : ""}
                  ghost={selectedOperator === ">=" ? "primary" : ""}
                  onClick={() => handleChangeOperator(">=")}
                >{`>=`}</Button>
                <Button
                  size="small"
                  className="mr-8"
                  type={selectedOperator === "<=" ? "primary" : ""}
                  ghost={selectedOperator === "<=" ? "primary" : ""}
                  onClick={() => handleChangeOperator("<=")}
                >{`<=`}</Button>
              </>
            );
          }}
        </Form.Item>
        <Form.Item
          className="mb-0"
          name="target_value"
          rules={[{ required: true, message: "Target can't be empty" }]}
          required={false}
        >
          <InputNumber value={targetValue} onChange={handleChange} />
        </Form.Item>
        <Text>{metric}</Text>
      </Row>
      <Row className="mt-8" justify="space-between">
        <Button type="text" className="pl-0" onClick={handleRemoveTarget}>
          Remove Target
        </Button>
        <ButtonWithSpinner
          isSpinning={isLoading || isRemovingTargetValue}
          disabled={isLoading || isRemovingTargetValue}
          htmlType="submit"
          size="small"
          type="primary"
        >
          Done
        </ButtonWithSpinner>
      </Row>
    </Form>
  );
};

const TargetChangeBtn = ({
  isFromPreviousValue = false,
  label = "",
  kpiId = null,
  kpiOwnerId = null,
  metric = "",
  id = null,
  dateString = "",
  operator = ">=",
  value = null,
  refetch = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const isAdmin = currentCompany?.role === "admin";
  const currentUserId = currentCompany?.user?.id;
  const isOwner = currentUserId === kpiOwnerId;

  const canUpdate = isAdmin || isOwner;

  return (
    <div>
      <Text className="block-display">{label}</Text>
      <Popover
        visible={isPopoverVisible && canUpdate}
        onVisibleChange={setIsPopoverVisible}
        title={"Update Target"}
        content={
          <>
            {isPopoverVisible ? (
              <TargetUpdateContent
                kpiId={kpiId}
                metric={metric}
                checkinId={id}
                date={dateString}
                targetValueId={id}
                operator={operator}
                currentValue={value}
                refetch={refetch}
                setIsPopoverVisible={setIsPopoverVisible}
              />
            ) : null}
          </>
        }
        trigger={["click"]}
      >
        <Button
          className={`${isFromPreviousValue ? "text-secondary" : ""} ${
            !canUpdate && "cursor-not-allowed"
          }`}
        >
          {value ? `${operator} ${value}` : "+"}
        </Button>
      </Popover>
    </div>
  );
};

const TargetChange = ({
  kpiId = null,
  isFetching = false,
  metric = "",
  kpiOwnerId = null,
  targetValues = {},
  refetch = () => {},
}) => {
  const [currentYear, setCurrentYear] = useState(thisYear);

  const handleReduceYear = () => {
    setCurrentYear((prevYear) =>
      moment(prevYear, yearFormat)?.subtract(1, "year")?.format(yearFormat)
    );
  };

  const handleIncreaseYear = () => {
    setCurrentYear((prevYear) =>
      moment(prevYear, yearFormat)?.add(1, "year")?.format(yearFormat)
    );
  };

  const continuousTargetValues = constructContinuousTargetValues(
    targetValues,
    currentYear
  );

  return (
    <div>
      <Row justify="space-between">
        <div>
          <Text>Targets</Text>
          {isFetching && <InlineSpinner fontSize={28} className="ml-8" />}
        </div>
        <div className="flex-display align-items-center">
          <Button size="small" onClick={handleReduceYear}>
            <LeftOutlined />
          </Button>
          <Text className="mx-8">{currentYear}</Text>
          <Button size="small" onClick={handleIncreaseYear}>
            <RightOutlined />
          </Button>
        </div>
      </Row>
      <Row className="mt-8" style={{ wrap: "no-wrap" }}>
        {continuousTargetValues?.map(
          ({ dateString, label, id, value, operator, isFromPreviousValue }) => (
            <TargetChangeBtn
              isFromPreviousValue={isFromPreviousValue}
              label={label}
              kpiId={kpiId}
              kpiOwnerId={kpiOwnerId}
              metric={metric}
              id={id}
              dateString={dateString}
              operator={operator}
              value={value}
              refetch={refetch}
            />
          )
        )}
      </Row>
    </div>
  );
};

TargetChange.propTypes = {
  targetValues: PropTypes.array,
};

export default TargetChange;

import React, { useState } from "react";
import { Typography, Row } from "antd";
import firebaseApp from "firebaseApp";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useCurrentUser } from "store";
import HubspotForm from "react-hubspot-form";
import { Redirect } from "react-router-dom";

import logo from "assets/logo.svg";
import { InlineSpinner } from "components";
import demoOrgChart from "assets/demo/demochart.png";
import styles from "./Demo.module.css";
import { setCookie } from "app/appUtils";
const { Title, Text, Link } = Typography;

const Demo = (props) => {
  const [currentUser] = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);

  const demoLogin = async () => {
    try {
      setIsLoading(true);
      const auth = getAuth(firebaseApp);
      signInWithEmailAndPassword(auth, "culture-demo@easy.xyz", "culture.easy")
        .then(() => {
          handleAddLinkedInPixel();
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddLinkedInPixel = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      const pixelElement = document.getElementById("linkedin-pixel");
      const linkedInConversionPixel = document.getElementById(
        "linkedin-pixel-conversion"
      );
      if (linkedInConversionPixel) {
        linkedInConversionPixel.remove();
      }

      if (!pixelElement) {
        const linkedInImg = document.createElement("img");
        linkedInImg.id = "linkedin-pixel";
        linkedInImg.height = "1";
        linkedInImg.width = "1";
        linkedInImg.style.cssText = "display:none;";
        linkedInImg.alt = "";
        linkedInImg.src =
          "https://px.ads.linkedin.com/collect/?pid=2845156&conversionId=3339868&fmt=gif";
        document.body.appendChild(linkedInImg);
      }
    }
  };

  if (currentUser) {
    return <Redirect to="/" />;
  }

  window.addEventListener("message", (event) => {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmit"
    ) {
      demoLogin();
    }
  });

  return (
    <div className={styles.parentRow}>
      <Link href="https://culture.easy.xyz/">
        <img className="mt-64 ml-15-percent" src={logo} alt="logo" />
      </Link>
      <Row align="middle" className="flex-direction-column">
        <Title className="mt-24" level={2}>
          Your demo experience is setup
        </Title>
        <Text className="mt-16">with the following org structure</Text>
        <img className={styles.demoChart} src={demoOrgChart} alt="mug" />
        <HubspotForm
          portalId="8958933"
          formId="b50e64e7-24b0-4755-9dd4-d177c10f4770"
          loading={
            <div>
              Loading...
              <InlineSpinner className="ml-8" />
            </div>
          }
          onSubmit={(formData) => {
            const email = formData[0]?.value;
            if (email && process.env.REACT_APP_ENVIRONMENT === "production") {
              setCookie("t_he", email, 365);
            }
          }}
        />
        {isLoading && <InlineSpinner />}
      </Row>
    </div>
  );
};

Demo.propTypes = {};

export default Demo;

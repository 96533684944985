import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Row, Col, Drawer } from "antd";

import { useCurrentCompany } from "store";
import OKRForm from "../common/form/OKRForm";

const AddOKR = ({
  visible = false,
  parentObjectiveId = null,
  selectedPeriodId = null,
  selectedTeamId = null,
  onSave = () => {},
  onCancel = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  let urlTypeId = "OrganizationObjective";
  if (!selectedTeamId || selectedTeamId === "company-okrs") {
    urlTypeId = "OrganizationObjective";
  } else if (selectedTeamId === "my-okrs") {
    urlTypeId = "IndividualObjective";
  } else {
    urlTypeId = Number(selectedTeamId);
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Helmet>
        <title>OKRs - culture.easy</title>
      </Helmet>
      <Drawer
        visible={visible}
        onClose={onCancel}
        footer={null}
        width="80%"
        centered={true}
        title={"Create OKRs"}
      >
        <Row>
          <Col span={1} />
          <Col span={22}>
            <OKRForm
              initialValues={{
                type_id: urlTypeId || "OrganizationObjective",
                owner_ids: [currentCompany?.user?.id],
                currentOwners: [currentCompany?.user],
                is_aspirational: false,
                parent_objective_id: parentObjectiveId
                  ? Number(parentObjectiveId)
                  : null,
                key_results: [
                  {
                    owner_id: currentCompany?.user?.id,
                    currentOwner: currentCompany?.user,
                    kpi_metric: "%",
                    target: 100,
                    start: 0,
                    is_boolean: false,
                    // is_deadline: false,
                  },
                ],
                objective_period_id: Number(selectedPeriodId) || null,
              }}
              currentUserId={currentCompany?.user?.id}
              onSave={onSave}
              onCancel={onCancel}
            />
          </Col>
          {/* <Col span={2} /> */}
        </Row>
      </Drawer>
    </div>
  );
};

AddOKR.propTypes = {
  visible: PropTypes.bool,
  parentObjectiveId: PropTypes.number,
  selectedPeriodId: PropTypes.number,
  selectedTeamId: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddOKR;

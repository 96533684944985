import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Row, Col, Typography, Button, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  BoxLoader,
  OKRTimePeriodSelect,
  SettingsFeatureAlert,
} from "components";
import { useCurrentCompany } from "store";
import { getAllTeamsApi } from "apis/teamApi";
import MyOKRs from "./my-okrs/MyOKRs";
import CompanyOKRs from "./company-okrs/CompanyOKRs";
import SearchOKRs from "./search-okrs/SearchOKRs";
import AddOKR from "./add/AddOKR";
import KPIs from "./kpis/KPIs.jsx";
import { getObjectivePeriodsApi } from "apis/okrV2Api";
import { errorNotification, getKPIFeatureObj } from "app/appUtils";

const { Title } = Typography;
const { TabPane } = Tabs;

const getSelectedTab = (selectedTab, selectedTeamId) => {
  if (selectedTab === "my-okrs") {
    return selectedTab;
  } else if (selectedTab === "company-okrs") {
    return selectedTeamId || "company-okrs";
  } else {
    return selectedTab || "company-okrs";
  }
};

const SelectedTab = ({
  selectedTeamId = "",
  selectedPeriodId = "",
  selectedPeriodObj = {},
  teams = [],
  timePeriods = [],
  shouldRefetch = false,
  handleIsAddOKRVisible = () => {},
  handleShouldRefetch = () => {},
}) => {
  switch (selectedTeamId) {
    case "my-okrs":
      return (
        <MyOKRs
          selectedPeriodId={selectedPeriodId}
          selectedPeriodObj={selectedPeriodObj}
          teams={teams}
          timePeriods={timePeriods}
          shouldRefetch={shouldRefetch}
          handleIsAddOKRVisible={handleIsAddOKRVisible}
          handleShouldRefetch={handleShouldRefetch}
        />
      );
    case "search-okrs":
      return (
        <SearchOKRs
          timePeriods={timePeriods}
          selectedPeriodId={selectedPeriodId}
          selectedPeriodObj={selectedPeriodObj}
        />
      );
    default:
      return (
        <CompanyOKRs
          selectedPeriodId={selectedPeriodId}
          selectedTeamId={selectedTeamId}
          timePeriods={timePeriods}
          selectedPeriodObj={selectedPeriodObj}
          shouldRefetch={shouldRefetch}
          handleIsAddOKRVisible={handleIsAddOKRVisible}
          handleShouldRefetch={handleShouldRefetch}
        />
      );
  }
};
SelectedTab.propTypes = {};

const OKRs = (props) => {
  const { selectedTab } = useParams();

  const query = new URLSearchParams(useLocation().search);
  const urlSelectedPeriodId = query.get("selectedPeriodId");
  const urlSelectedTeamId = query.get("selectedTeamId");

  const [currentCompany] = useCurrentCompany();

  const history = useHistory();
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    Number(urlSelectedPeriodId) || null
  );
  const [selectedTeamId, setSelectedTeamId] = useState(
    getSelectedTab(selectedTab, urlSelectedTeamId)
  );
  const [isAddOKRVisible, setIsAddOKRVisible] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [kpiFeature, setKpiFeature] = useState(null);

  const features = currentCompany?.features || [];
  useEffect(() => {
    const kpiFeature = getKPIFeatureObj(features);
    setKpiFeature(kpiFeature);
  }, [features]);

  const {
    data: periods,
    status: periodStatus,
    isLoading: isPeriodLoading,
    // isFetching: isPeriodFetching,
  } = useQuery("getAllObjectivePeriods", getObjectivePeriodsApi, {
    onSuccess: (periods) => {
      const currentPeriod = periods
        ? periods?.filter((period) => period?.is_active)[0]
        : null;
      if (!selectedPeriodId) {
        setSelectedPeriodId(currentPeriod?.id);
      }
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const {
    data: teams,
    status: teamsStatus,
    isLoading: isTeamsLoading,
    // isFetching: isTeamsFetching,
  } = useQuery("getAllTeams", getAllTeamsApi);

  const handleSelectedPeriodChange = (val) => {
    setSelectedPeriodId(val);
  };

  const periodLoader =
    periodStatus === "loading" ||
    isPeriodLoading ||
    teamsStatus === "loading" ||
    isTeamsLoading;

  if (periodLoader) {
    return <BoxLoader />;
  }

  const selectedPeriodObj = periods?.filter(
    (period) => period?.id === selectedPeriodId
  )[0];

  const handleTabChange = (teamId) => {
    setSelectedTeamId(teamId);
    history.push(`/okrs/${teamId}`);
  };

  const handleIsAddOKRVisible = () => {
    if (isAddOKRVisible) {
      handleShouldRefetch();
    }
    setIsAddOKRVisible(!isAddOKRVisible);
  };

  const handleShouldRefetch = () => {
    setShouldRefetch(!shouldRefetch);
  };

  const activeTeams = teams ? teams?.filter((team) => team?.active) : [];

  const isKpiFeatureEnabled = kpiFeature?.enabled;

  return (
    <>
      <Helmet>
        <title>{"culture.easy"}</title>
      </Helmet>
      {isAddOKRVisible && (
        <AddOKR
          visible={isAddOKRVisible}
          selectedPeriodId={selectedPeriodId}
          selectedTeamId={selectedTeamId}
          onSave={handleIsAddOKRVisible}
          onCancel={handleIsAddOKRVisible}
        />
      )}

      <Tabs activeKey={selectedTeamId} onChange={handleTabChange}>
        <TabPane key="my-okrs" tab="Individual" />
        <TabPane key="company-okrs" tab={currentCompany?.name}></TabPane>
        {activeTeams?.map((team) => (
          <TabPane tab={team?.name} key={team?.id}></TabPane>
        ))}
        <TabPane
          key="search-okrs"
          tab={
            <>
              <SearchOutlined />
              Search OKRs
            </>
          }
        />
      </Tabs>

      {isKpiFeatureEnabled && selectedTeamId !== "search-okrs" && (
        <KPIs selectedTeamId={selectedTeamId} />
      )}

      <Row justify="space-between" align="top">
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <Title level={2}>OKRs</Title>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 18 }}>
          <Row align="middle" justify="end" gutter={[16, 16]}>
            <Col span={5}>
              {/* <TinyTutorialBtn
                text="Watch the tiny tutorial on OKRs"
                onClick={handleIsVimeoModalVisible}
              /> */}
            </Col>
            <Col>
              <OKRTimePeriodSelect
                periods={periods}
                selectedPeriodId={selectedPeriodId}
                handleSelectedPeriodChange={handleSelectedPeriodChange}
              />
            </Col>
            <Col xxl={{ span: 3 }} lg={{ span: 4 }}>
              <Button type="primary">
                <Row align="top" onClick={handleIsAddOKRVisible}>
                  Create OKR
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {selectedPeriodObj?.locked && (
        <SettingsFeatureAlert
          text={"This Objective Period is locked"}
          settingsLink={"/company/okrs"}
          settingsButtonText={"Go to OKR Settings"}
        />
      )}

      <div
        id="objective-panel-header-three-dot-container"
        style={{ position: "relative" }}
      />
      <div
        id="key-results-initiatives-three-dot-container"
        style={{ position: "relative" }}
      />

      <SelectedTab
        selectedTeamId={selectedTeamId}
        selectedPeriodId={selectedPeriodId}
        selectedPeriodObj={selectedPeriodObj}
        teams={teams}
        timePeriods={periods}
        shouldRefetch={shouldRefetch}
        handleIsAddOKRVisible={handleIsAddOKRVisible}
        handleShouldRefetch={handleShouldRefetch}
      />
    </>
  );
};

OKRs.propTypes = {};

export default OKRs;

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { useQuery } from "react-query";
import { Empty } from "antd";
import { useLocation } from "react-router-dom";

import { BoxLoader } from "components";
import { useEmployees } from "hooks";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { errorNotification } from "app/appUtils";
import { getTreeDataFromSectionData } from "../common/utils";
import OKRTreeViewV2 from "../common/OKRTreeViewV2";
import { getObjectivePeriodsApi, getCompanyObjectivesApi } from "apis/okrV2Api";
import styles from "../OKRs.module.css";

const TreeView = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const selectedPeriodId = query.get("selectedPeriodId");
  const selectedTeamId = query.get("selectedTeamId");

  const [selectedPeriodObj, setSelectedPeriodObj] = useState(null);

  const { isEmployeesLoading } = useEmployees();

  const {
    // data: periods,
    status: periodStatus,
    isLoading: isPeriodLoading,
    // isFetching: isPeriodFetching,
  } = useQuery("getAllObjectivePeriods", getObjectivePeriodsApi, {
    onSuccess: (periods) => {
      const currentPeriod = periods
        ? periods?.filter(
            (period) => period?.id === Number(selectedPeriodId)
          )[0]
        : null;
      if (!selectedPeriodObj) {
        setSelectedPeriodObj(currentPeriod);
      }
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const { data, status, isLoading, isFetching } = useQuery(
    ["getCompanyObjectives", selectedPeriodId, selectedTeamId],
    getCompanyObjectivesApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const treeData = getTreeDataFromSectionData(
    data?.objectives || [],
    selectedPeriodObj,
    data?.objectives_percentage_progress_data
  );

  const finalTreeData =
    treeData && selectedPeriodObj && selectedPeriodObj?.name
      ? [
          {
            node_type: "time_period",
            selectedPeriodObj: { ...selectedPeriodObj },
            children: [...treeData],
          },
        ]
      : [];

  const loader =
    status === "loading" ||
    isLoading ||
    isFetching ||
    isEmployeesLoading ||
    periodStatus === "loading" ||
    isPeriodLoading;

  return (
    <FullScreenLayout
      source={{
        path: `/okrs/company-okrs?selectedPeriodId=${selectedPeriodId}&selectedTeamId=${selectedTeamId}`,
        name: "OKRs",
      }}
      title={`OKRs Alignment View for ${
        selectedPeriodObj?.name
      } (Overall Progress: ${Math.round(data?.overall_progress || 0)}%)`}
      headerLgMainSpan={14}
      headerLgSideColSpan={5}
      contentLgSpan={24}
      contentGapSpan={0}
      containerStyles={{ paddingBottom: "0px" }}
      contentContainerStyles={{ marginTop: "0px" }}
    >
      {loader ? (
        <BoxLoader />
      ) : (
        <>
          {treeData?.length > 0 ? (
            <div className={styles.verticalTree}>
              <OKRTreeViewV2
                data={finalTreeData[0] || {}}
                // treeData={finalTreeData}
                // summary={data?.summary?.objectives_percentage_progress_data}
                selectedPeriodObj={selectedPeriodObj}
              />
            </div>
          ) : (
            <Empty />
          )}
        </>
      )}
    </FullScreenLayout>
  );
};

TreeView.propTypes = {};

export default TreeView;

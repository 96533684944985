import React from "react";
import PropTypes from "prop-types";
import momentTimeZone from "moment-timezone";
import { Select, Button } from "antd";

import { errorNotificationWithString } from "app/appUtils";
// import { filterOptionsByLabel } from "app/appUtils";

const { Option } = Select;

const TimeZoneSelect = ({
  timezones = {},
  disabled = false,
  value,
  onChange = () => {},
}) => {
  // const timeZones = momentTimeZone.tz.names();

  // const timeZoneWithOffset = timeZones?.map((zone) => {
  //   const offset = momentTimeZone.tz(zone).format("Z");
  //   return `UTC${offset} ${zone}`;
  // });

  const handleSelectMyTimeZone = () => {
    const userTimeZone = momentTimeZone.tz.guess();
    const userTimeZoneOffset = momentTimeZone.tz(userTimeZone).format("Z");
    const timeZoneKeys = Object.keys(timezones);
    const filteredKeys = timeZoneKeys.filter((key) =>
      key?.includes(userTimeZoneOffset)
    );
    if (filteredKeys?.length > 0) {
      onChange(filteredKeys[0]);
    } else {
      errorNotificationWithString(
        "Can't guess your timezone please select from list"
      );
    }
  };

  // timeZoneWithOffset.sort((a, b) => {
  //   const [ahh, amm] = a.split("UTC")[1].split(" ")[0].split(":");
  //   const [bhh, bmm] = b.split("UTC")[1].split(" ")[0].split(":");
  //   return +ahh * 60 + amm - (+bhh * 60 + bmm);
  // });

  return (
    <>
      <Select disabled={disabled} value={value} onChange={onChange}>
        {Object.entries(timezones)?.map(([timeZoneKey, timeZoneValue]) => (
          <Option value={timeZoneKey}>{timeZoneValue}</Option>
        ))}
      </Select>
      <Button type="link" className="pl-0" onClick={handleSelectMyTimeZone}>
        Select my timezone
      </Button>
    </>
  );
};

TimeZoneSelect.propTypes = {
  onChange: PropTypes.func,
};

export default TimeZoneSelect;

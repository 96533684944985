import React from "react";
// import PropTypes from 'prop-types';
import { Typography } from "antd";

const { Text } = Typography;

const ConvertToBooleanKR = ({ isKeyResult = true, value, onChange }) => {
  return (
    <Text
      onClick={() => {
        onChange(!value);
      }}
    >
      {value
        ? `Convert to Metric based ${isKeyResult ? "KR" : "Initiative"}`
        : `Convert to Yes/No ${isKeyResult ? "KR" : "Initiative"}`}
    </Text>
  );
};

ConvertToBooleanKR.propTypes = {};

export default ConvertToBooleanKR;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";

import { BoxLoader } from "components";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { threeSixtyDegreeFeedback } from "app/appConstants";
import { errorNotification } from "app/appUtils";
import { getThreeSixtyCycleApi } from "apis/threeSixtyFeedbackApi";
import AddThreeSixtyFeedbackCycle from "../add/AddThreeSixtyFeedbackCycle";

const CreateThreeSixtyFeedbackCycleFromDraft = (props) => {
  const { id } = useParams();
  const [cycleData, setCycleData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data, status, isLoading, isFetching } = useQuery(
    ["getThreeSixtyCycleForEdit", id],
    getThreeSixtyCycleApi,
    {
      cacheTime: 1,
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  useEffect(() => {
    if (data && status === "success") {
      const period = [
        data?.from_date ? moment(data?.from_date, "YYYY-MM-DD") : null,
        data?.to_date ? moment(data?.to_date, "YYYY-MM-DD") : null,
      ];
      const initValues = {
        ...data,
        participants: data?.threesixty_participants?.map(
          (participant) => participant?.employee?.id
        ),
      };
      // setCycleData(initValues);
      // const updatedQuestions = data?.questions?.map((question) => {
      //   if (question?.type === TEXT_TYPE) {
      //     return { ...question };
      //   }
      //   return {
      //     ...question,
      //     options: Object.entries(question?.options)?.map(([id, value]) => ({
      //       id,
      //       value,
      //     })),
      //   };
      // });
      const nominate_due_date = data?.nominate_due_date
        ? moment(data?.nominate_due_date, "YYYY-MM-DD")
        : null;
      const finalize_due_date = data?.finalize_due_date
        ? moment(data?.finalize_due_date, "YYYY-MM-DD")
        : null;
      const submit_due_date = data?.submit_due_date
        ? moment(data?.submit_due_date, "YYYY-MM-DD")
        : null;
      // initValues["questions"] = [...updatedQuestions];
      initValues["period"] = period;
      initValues["nominate_due_date"] = nominate_due_date;
      initValues["finalize_due_date"] = finalize_due_date;
      initValues["submit_due_date"] = submit_due_date;
      setCycleData({ ...initValues });
      setIsProcessing(false);
    }
  }, [data, status]);

  const loader =
    status === "loading" ||
    isLoading ||
    isFetching ||
    isProcessing ||
    !cycleData;

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      {loader ? (
        <FullScreenLayout
          source={{
            path: "/company/360-feedback",
            name: threeSixtyDegreeFeedback,
          }}
        >
          <BoxLoader />{" "}
        </FullScreenLayout>
      ) : (
        <AddThreeSixtyFeedbackCycle
          mode="create_from_draft"
          initialValues={{
            ...cycleData,
          }}
        />
      )}
    </>
  );
};

CreateThreeSixtyFeedbackCycleFromDraft.propTypes = {};

export default CreateThreeSixtyFeedbackCycleFromDraft;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useInfiniteQuery } from "react-query";
import {
  Row,
  Card,
  Timeline,
  Typography,
  Empty,
  Tooltip,
  Tag,
  Divider,
  Grid,
} from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";

import {
  BoxLoader,
  APIBasedEmployeeSearch,
  EmployeeAvatarWithName,
  ShowMoreText,
  EllipseText,
} from "components";
import { useIntersectionObserver } from "hooks";
import { useCurrentCompany } from "store";
import GiveFeedbackDrawer from "../give-feedback/GiveFeedbackDrawer";
import {
  formattedDate,
  getNameFromEmployeeObj,
  getIsMobile,
} from "app/appUtils";
import { searchReportsChain } from "apis/commonApi";
import { getContinuousFeedbackApi } from "apis/continuousFeedbackApi";

import { ReactComponent as RaiseRequestIcon } from "assets/icons/raised_request.svg";
import styles from "../ContinuousFeedback.module.css";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const FeedbackWall = ({
  isGiveFeedbackOpen = false,
  editGiveFeedback = false,
  handleCloseFeedbackDrawer = () => {},
  handleEditGiveFeedback = () => {},
  setFeedbackRequestsCount = () => {},
}) => {
  const screens = useBreakpoint();
  const [currentCompany] = useCurrentCompany();

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    currentCompany?.user?.id
  );
  const [selectedEmployee, setSelectedEmployee] = useState(
    currentCompany?.user
  );
  const [selectedTypes, setSelectedTypes] = useState(["given", "received"]);
  const [givenFeedbackCount, setGivenFeedbackCount] = useState(0);
  const [receivedFeedbackCount, setReceivedFeedbackCount] = useState(0);
  const [totalFeedbackCount, setTotalFeedbackCount] = useState(0);

  const {
    data,
    isLoading,
    isFetching,
    isFetchingMore,
    canFetchMore,
    fetchMore,
    refetch,
  } = useInfiniteQuery(
    ["getContinuousFeedback", selectedEmployeeId],
    getContinuousFeedbackApi,
    {
      getFetchMore: (lastGroup) => {
        const lastGroupContinuousFeedback =
          lastGroup?.continuous_feedback || [];
        const morePagesExists = lastGroupContinuousFeedback?.length === 20;

        if (!morePagesExists) return false;

        return lastGroupContinuousFeedback[
          lastGroupContinuousFeedback?.length - 1
        ]?.id;
      },
      onSuccess: (data) => {
        if (typeof data[0]?.feedback_given_count === "number") {
          setGivenFeedbackCount(data[0]?.feedback_given_count);
        }
        if (typeof data[0]?.feedback_requests_count === "number") {
          setFeedbackRequestsCount(data[0]?.feedback_requests_count);
        }
        if (typeof data[0]?.feedback_recived_count === "number") {
          setReceivedFeedbackCount(data[0]?.feedback_recived_count);
        }
        if (typeof data[0]?.group_feedback_count === "number") {
          setTotalFeedbackCount(data[0]?.group_feedback_count);
        }
      },
    }
  );

  const loadMoreButtonRef = useRef();

  const handleFetchMore = () => {
    const last_id = getLastFeedbackId(data);
    fetchMore(last_id);
  };

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: handleFetchMore,
    enabled: canFetchMore,
  });

  const handleRefetch = () => {
    refetch();
  };

  const handleSearchEmployees = (val = null, selectedEmployee) => {
    setSelectedEmployeeId(val);
    setSelectedEmployee(selectedEmployee);
    // getContinuousFeedback({ employee_id: val });
  };

  const handleCheckboxChange = (type) => {
    const checked = !selectedTypes?.includes(type);

    const updatedSelectedTypes = [...selectedTypes];
    if (checked) {
      updatedSelectedTypes.push(type);
      setSelectedTypes(updatedSelectedTypes);
    } else if (updatedSelectedTypes?.length === 2) {
      const indexOfType = updatedSelectedTypes.indexOf(type);
      if (indexOfType > -1) {
        updatedSelectedTypes.splice(indexOfType, 1);
      }
      setSelectedTypes(updatedSelectedTypes);
    }
  };

  const handleEdit = (feedbackObj) => {
    handleEditGiveFeedback({
      id: feedbackObj?.id,
      isRequestFeedback: Boolean(feedbackObj?.request_user),
      question: feedbackObj?.request_user?.request?.question || undefined,
      requested_on: feedbackObj?.request_user?.request?.created_at || undefined,
      employee: Boolean(feedbackObj?.request_user)
        ? feedbackObj?.request_user?.request?.requested_by
        : feedbackObj?.employee,
      request_user_id: feedbackObj?.request_user?.id || undefined,
      feedback: feedbackObj?.feedback,
      created_at: feedbackObj?.created_at,
    });
  };

  const loader = isLoading;

  const isMobile = getIsMobile(screens);

  return (
    <div>
      {(isGiveFeedbackOpen || Boolean(editGiveFeedback)) && (
        <GiveFeedbackDrawer
          feedback={editGiveFeedback}
          visible={isGiveFeedbackOpen || Boolean(editGiveFeedback)}
          refetch={handleRefetch}
          onClose={handleCloseFeedbackDrawer}
        />
      )}
      <Row className={isMobile ? "flex-wrap" : "flex-nowrap"}>
        <Card className="flex-grow-1">
          {loader ? (
            <BoxLoader />
          ) : (
            <>
              <Row className="mb-24" justify="center">
                <Tag
                  color={
                    selectedTypes?.includes("received")
                      ? "#5330c91a"
                      : "#E3E3E3"
                  }
                  className={`${styles.tagBtn} mr-0`}
                  style={{
                    color: selectedTypes?.includes("received")
                      ? "#1890ff"
                      : "black",
                    borderColor: selectedTypes?.includes("received")
                      ? "#5330c9cc"
                      : "white",
                  }}
                  onClick={() => handleCheckboxChange("received")}
                >
                  <EllipseText
                    text={
                      isMobile
                        ? "Received"
                        : `Feedback received by ${getNameFromEmployeeObj(
                            selectedEmployee
                          )}`
                    }
                    characterLimit={screens?.xl ? 35 : 25}
                  />
                </Tag>
                <div className={isMobile ? "" : "width-250-px"}>
                  <Tag
                    color={
                      selectedTypes?.includes("given") ? "#5330c91a" : "#E3E3E3"
                    }
                    className={styles.tagBtn}
                    style={{
                      color: selectedTypes?.includes("given")
                        ? "#1890ff"
                        : "black",
                      borderColor: selectedTypes?.includes("given")
                        ? "#5330c9cc"
                        : "white",
                    }}
                    onClick={() => handleCheckboxChange("given")}
                  >
                    <EllipseText
                      text={
                        isMobile
                          ? "Given"
                          : `Feedback given by ${getNameFromEmployeeObj(
                              selectedEmployee
                            )}`
                      }
                      characterLimit={screens?.xl ? 35 : 25}
                    />
                  </Tag>
                </div>
              </Row>
              {isFetching && <BoxLoader height="10vh" />}
              {getIsEmpty(
                selectedTypes,
                givenFeedbackCount,
                receivedFeedbackCount,
                totalFeedbackCount
              ) ? (
                <Empty description="No continuous feedback to show for this employee" />
              ) : (
                <div className={styles.timeline}>
                  <Timeline mode="alternate">
                    {getContinuousFeedbackArray(data)?.map((feedbackObj) => {
                      const isFeedbackGiven =
                        feedbackObj?.created_by?.id === selectedEmployeeId;

                      if (feedbackObj?.type === "monthTag") {
                        return (
                          <Timeline.Item
                            key={feedbackObj?.title}
                            position={isFeedbackGiven ? "left" : "right"}
                            className="mb-16"
                            dot={
                              <Tag className="py-4 px-8 mb-8">
                                {feedbackObj?.title}
                              </Tag>
                            }
                          ></Timeline.Item>
                        );
                      }

                      if (
                        (isFeedbackGiven && selectedTypes?.includes("given")) ||
                        (!isFeedbackGiven &&
                          selectedTypes?.includes("received"))
                      ) {
                        return (
                          <Timeline.Item
                            key={feedbackObj?.id}
                            position={isFeedbackGiven ? "left" : "right"}
                            className="mb-8"
                          >
                            <div>
                              <div className="mt--4">
                                <EmployeeAvatarWithName
                                  textClassName="text-secondary"
                                  employee={
                                    isFeedbackGiven
                                      ? feedbackObj?.employee
                                      : feedbackObj?.created_by
                                  }
                                />

                                <Text type="secondary" className="ml-8">
                                  {`on ${formattedDate(
                                    feedbackObj?.updated_at
                                  )}`}
                                </Text>
                              </div>
                              {Boolean(feedbackObj?.request_user) ? (
                                <div className="inline-flex-display align-items-center">
                                  <Tooltip title="Feedback Requested">
                                    <RaiseRequestIcon className="mr-8" />
                                  </Tooltip>
                                  <EllipseText
                                    text={
                                      feedbackObj?.request_user?.request
                                        ?.question
                                    }
                                  />
                                </div>
                              ) : null}
                              <div className="mt-8">
                                <ShowMoreText
                                  shouldBeBlockDisplay={false}
                                  charactersToShowOnLess={110}
                                  text={feedbackObj?.feedback || ""}
                                />
                                <Text
                                  type="secondary"
                                  className="font-size-14 ml-4"
                                >
                                  {feedbackObj?.updated_at !==
                                  feedbackObj?.created_at
                                    ? "(edited)"
                                    : ""}
                                </Text>
                                {feedbackObj?.created_by?.id ===
                                  currentCompany?.user?.id &&
                                  moment()?.isSameOrBefore(
                                    moment(
                                      feedbackObj?.created_at,
                                      "YYYY-MM-DD"
                                    )?.add("14", "days")
                                  ) && (
                                    <EditOutlined
                                      className="ml-8"
                                      onClick={() => handleEdit(feedbackObj)}
                                    />
                                  )}
                              </div>
                            </div>
                          </Timeline.Item>
                        );
                      }
                      return null;
                    })}
                  </Timeline>
                  <Row justify="center">
                    {selectedTypes?.length === 2 ? (
                      <button
                        ref={loadMoreButtonRef}
                        // onClick={() => fetchMore()}
                        disabled={
                          !canFetchMore ||
                          isFetchingMore ||
                          isFetching ||
                          isLoading
                        }
                      >
                        {isFetchingMore
                          ? "Loading more..."
                          : canFetchMore
                          ? "Load Newer"
                          : "Nothing more to load"}
                      </button>
                    ) : (
                      <Row align="middle">
                        <button disabled={true}>
                          Can't load more if only one type of feedback is
                          selected
                        </button>
                        <Tooltip
                          className="ml-8"
                          title={`To load more feedback click on ${
                            selectedTypes?.includes("received")
                              ? `Feedback given by ${getNameFromEmployeeObj(
                                  selectedEmployee
                                )}`
                              : `Feedback received by ${getNameFromEmployeeObj(
                                  selectedEmployee
                                )}`
                          } the top`}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    )}
                  </Row>
                </div>
              )}
            </>
          )}
        </Card>

        <div className={isMobile ? "mt-16" : "width-250px ml-16"}>
          <Card className="mb-16">
            <div>
              <Row align="middle" className="mb-8">
                <Text type="secondary">Continuous Feedback for</Text>
                <Tooltip
                  className="ml-8"
                  title="You can only search for your direct & indirect reports"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Row>
              <APIBasedEmployeeSearch
                className={isMobile ? "width-100-percent" : "width-250-px mr-8"}
                showArrow={false}
                allowClear={false}
                shouldShowAvatar={true}
                shouldSendSelectedEmployee={true}
                placeholder="Search for your direct or indirect reports"
                currentOwners={currentCompany?.user}
                value={selectedEmployeeId}
                onChange={handleSearchEmployees}
                searchApi={searchReportsChain}
              />
              <Text type="secondary">Type at least three characters</Text>
            </div>
          </Card>
          <Card title="Insights">
            {loader ? (
              <BoxLoader />
            ) : (
              <>
                <EmployeeAvatarWithName employee={selectedEmployee} />
                <div className="flex-display mt-8">
                  <div>
                    <Title level={2}>{receivedFeedbackCount}</Title>
                    <Text type="secondary">Total Received</Text>
                  </div>
                  <Divider className="auto-height mx-16" type="vertical" />
                  <div>
                    <Title level={2}>{givenFeedbackCount}</Title>
                    <Text type="secondary">Total Given</Text>
                  </div>
                </div>
                <Divider />
                <div className="mb-8">
                  <Title level={4}>Organization</Title>
                  <Text type="secondary">Total Feedback :</Text>
                  <Title className="inline-display ml-8" level={2}>
                    {totalFeedbackCount}
                  </Title>
                </div>
              </>
            )}
          </Card>
        </div>
      </Row>
    </div>
  );
};

FeedbackWall.propTypes = {
  isGiveFeedbackOpen: PropTypes.bool,
  editGiveFeedback: PropTypes.bool,
  handleCloseFeedbackDrawer: PropTypes.func,
};

const getIsEmpty = (
  selectedTypes = [],
  givenFeedbackCount = 0,
  receivedFeedbackCount = 0,
  totalFeedbackCount = 0
) => {
  if (selectedTypes?.length === 0) {
    return true;
  }
  if (selectedTypes?.length === 1) {
    return selectedTypes[0] === "given"
      ? givenFeedbackCount === 0
      : receivedFeedbackCount === 0;
  }
  if (selectedTypes?.length === 2 && totalFeedbackCount === 0) {
    return true;
  }
  return false;
};

const getContinuousFeedbackArray = (data = []) => {
  const continuousFeedback = [];

  data.forEach((page) => {
    page.continuous_feedback.forEach((feedbackObj) => {
      const currentLastElement =
        continuousFeedback[continuousFeedback?.length - 1] || null;

      const lastElementMonthYear = Boolean(currentLastElement)
        ? moment(currentLastElement?.created_at, "YYYY-MM-DD")?.format(
            "MMM, YYYY"
          ) || ""
        : "";

      const feedbackObjMonthYear = moment(
        feedbackObj?.created_at,
        "YYYY-MM-DD"
      )?.format("MMM, YYYY");

      if (lastElementMonthYear !== feedbackObjMonthYear) {
        continuousFeedback.push({
          type: "monthTag",
          title: feedbackObjMonthYear,
        });
      }
      continuousFeedback.push(feedbackObj);
    });
  });
  return continuousFeedback;
};

const getLastFeedbackId = (data = []) => {
  if (data?.length === 0) {
    return null;
  }
  const lastAvailablePage = data[data?.length - 1];
  const lastAvailableContinuousFeedbackArray =
    lastAvailablePage?.continuous_feedback || [];
  const lastAvailableContinuousFeedback =
    lastAvailableContinuousFeedbackArray[
      lastAvailableContinuousFeedbackArray?.length - 1
    ] || {};

  return lastAvailableContinuousFeedback?.id || null;
};

export default FeedbackWall;

import React, { useState, useEffect } from "react";
import { useMutation, useQueryCache } from "react-query";
import firebaseApp, {
  googleAuthProvider,
  microsoftProvider,
} from "firebaseApp";
import { getAuth, signInWithPopup } from "firebase/auth";
import { Layout, Card, Typography, Input, Button } from "antd";
import Icon from "@ant-design/icons";

import { ButtonWithSpinner, InlineSpinner, PageSpinner } from "components";
import {
  //  useCurrentCompany,
  useIsSignedOut,
  useCompanyList,
  useCurrentUser,
  useSetNewOrgSetup,
} from "store";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";
import { getCurrentUserName, errorNotification, signOut } from "app/appUtils";
import { createOrgApi } from "apis/onboardApi";
import logo from "assets/logo.svg";
import { ReactComponent as GoogleImage } from "assets/auth/googleImage.svg";
import microsoftImage from "assets/auth/microsoftImage.png";

import styles from "./SignUp.module.css";

const { Text, Title, Link } = Typography;

const SignUp = (props) => {
  // const [, setCurrentCompany] = useCurrentCompany();
  const queryCache = useQueryCache();
  const auth = getAuth(firebaseApp);

  const [, setCompanyList] = useCompanyList();
  const [isSignedOut, setIsSignedOut] = useIsSignedOut();
  const [, setIsNewOrgSetup] = useSetNewOrgSetup();
  const [hasCompanyOnBoarded, setHasCompanyOnBoarded] = useState(false);

  const [createOrg, { status, isLoading, isFetching }] = useMutation(
    createOrgApi,
    {
      onSuccess: (data) => {
        setIsNewOrgSetup(false);
        // setCurrentCompany(data);
        setHasCompanyOnBoarded(true);
        // localStorage.setItem("currentCompanyId", data?.id || "");
        window.location = "/";
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const getInitialFirstName = () => {
    const isUserNamePresent = Boolean(currentUser?.displayName || false);
    if (isUserNamePresent) {
      const userName = currentUser?.displayName;
      const useNameArray = userName.split(" ");
      const useNameArrayLength = useNameArray?.length;
      if (useNameArrayLength >= 1) {
        return useNameArray[0];
      }
      return "";
    } else {
      return "";
    }
  };

  const getInitialLastName = () => {
    const isUserNamePresent = Boolean(currentUser?.displayName || false);
    if (isUserNamePresent) {
      const userName = currentUser?.displayName;
      const useNameArray = userName.split(" ");
      const useNameArrayLength = useNameArray?.length;
      if (useNameArrayLength >= 2) {
        return useNameArray?.slice(1)?.join(" ");
      }
      return "";
    } else {
      return "";
    }
  };

  const hasUserName = () => {
    const isUserNamePresent = Boolean(currentUser?.displayName || false);
    if (isUserNamePresent) {
      const userName = currentUser?.displayName;
      const useNameArray = userName.split(" ");
      const useNameArrayLength = useNameArray?.length;
      if (useNameArrayLength >= 2) {
        // setFirstName(useNameArray[0]);
        // setLastName(useNameArray[useNameArrayLength - 1]);
        return true;
      } else if (useNameArrayLength === 1) {
        // setFirstName(useNameArray[0]);
        return false;
      }
      return false;
    } else {
      return false;
    }
  };

  const [currentUser] = useCurrentUser();
  const [orgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState(getInitialFirstName());
  const [lastName, setLastName] = useState(getInitialLastName());
  const [companyError, setCompanyError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isNamePresent, setIsNamePresent] = useState(hasUserName());
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  const checkAndCreateOrg = (value) => {
    const isUserNamePresent = Boolean(value?.user?.displayName || false);
    if (isUserNamePresent) {
      const userName = value?.user?.displayName;
      const useNameArray = userName.split(" ");
      const useNameArrayLength = useNameArray?.length;
      if (useNameArrayLength >= 2) {
        const admin_first_name = useNameArray[0];
        const admin_last_name = useNameArray?.slice(1)?.join(" ");
        setFirstName(admin_first_name);
        setLastName(admin_last_name);
        if (orgName) {
          createOrg({
            name: orgName,
            admin_first_name,
            admin_last_name,
          });
        } else {
          setCompanyError("Company name cant be empty");
        }
      } else if (useNameArrayLength === 1) {
        const admin_first_name = useNameArray[0];
        setFirstName(admin_first_name);
        setIsNamePresent(false);
      } else {
        setIsNamePresent(false);
      }
    } else {
      setIsNamePresent(false);
    }
  };

  const signInWithGoogle = async () => {
    if (!orgName || orgName.trim() === "") {
      setCompanyError("Company name cant be empty");
    } else {
      try {
        setButtonWithSpinner("google");

        await signInWithPopup(auth, googleAuthProvider).then(checkAndCreateOrg);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const signInWithMicrosoft = async () => {
    if (!orgName || orgName.trim() === "") {
      setCompanyError("Company name cant be empty");
    } else {
      setButtonWithSpinner("msft");
      try {
        await signInWithPopup(auth, microsoftProvider).then(checkAndCreateOrg);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const currentUserEmail = currentUser?.email;

  useEffect(() => {
    setIsNamePresent(hasUserName());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserEmail]);

  const handleOrgNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setCompanyError("Company name cant be empty");
    } else {
      setCompanyError("");
    }
    setOrgName(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setFirstNameError("First name cant be empty");
    } else {
      setFirstNameError("");
    }
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setLastNameError("Last name cant be empty");
    } else {
      setLastNameError("");
    }
    setLastName(e.target.value);
  };

  const handleCreateOrgWithName = () => {
    if (!orgName) {
      setCompanyError("Company name cant be empty");
      return;
    }
    if (!firstName) {
      setFirstNameError("First Name cant be empty");
      return;
    }
    if (!lastName) {
      setLastNameError("Last Name cant be empty");
      return;
    }
    createOrg({
      name: orgName,
      admin_first_name: firstName,
      admin_last_name: lastName,
    });
  };

  const handleSignOut = () => {
    signOut({
      isInAppRedirect: true,
      queryCache,
      setCompanyList,
      setIsSignedOut,
    });
  };

  const isCreatingOrg =
    status === "loading" || isLoading || isFetching || hasCompanyOnBoarded;

  if (isSignedOut) {
    return <PageSpinner />;
  }

  return (
    <Layout className={styles.fullHeight}>
      <Card className={styles.loginCard}>
        <div className={styles.loginDiv}>
          <Link href="https://culture.easy.xyz/">
            <img className="block-display" src={logo} alt="logo" />
          </Link>

          <div className="width-400-px text-align-center">
            <Title className="mt-16" level={3}>
              {!currentUser && "Sign up"}
            </Title>

            <Title className="mt-16" level={5}>
              {currentUser &&
                `Hey ${getCurrentUserName(currentUser)}(${
                  currentUser?.email
                }), setup culture.easy for your Company`}
            </Title>
          </div>

          <div className="mt-24">
            <Text type="secondary">Enter Company name</Text>
            <Input value={orgName} onChange={handleOrgNameChange} />
            {(!orgName || orgName?.trim()?.length === 0) && (
              <div>
                <Text className="mt-8" type="danger">
                  {companyError}
                </Text>
              </div>
            )}
          </div>

          {isCreatingOrg ? (
            <div className="mt-32">
              <Text>Setting up your Org...</Text>
              <InlineSpinner />
            </div>
          ) : (
            <>
              {currentUser ? (
                <>
                  {isNamePresent ? (
                    <>
                      <ButtonWithSpinner
                        disabled={isCreatingOrg || companyError}
                        isSpinning={isCreatingOrg}
                        className="mt-32 mb-24 font-weight-300 width-300-px"
                        onClick={() => {
                          if (orgName) {
                            createOrg({
                              name: orgName,
                              admin_first_name: firstName,
                              admin_last_name: lastName,
                            });
                          } else {
                            setCompanyError("Company name cant be empty");
                          }
                        }}
                      >
                        Setup for your Company
                      </ButtonWithSpinner>
                      <Button
                        className="mb-24 font-weight-300 width-300-px"
                        onClick={handleSignOut}
                      >
                        Sign In with a different email
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="width-400-px">
                        <Text className="block-display mt-16" type="secondary">
                          We need your First and Last Name for optimal
                          experience
                        </Text>
                        <Text className="mt-8" type="secondary">
                          Enter First name
                        </Text>
                        <Input
                          value={firstName}
                          onChange={handleFirstNameChange}
                        />
                        {(!firstName || firstName?.trim()?.length === 0) && (
                          <div>
                            <Text className="mt-8" type="danger">
                              {firstNameError}
                            </Text>
                          </div>
                        )}
                        <Text className="mt-8" type="secondary">
                          Enter Last name
                        </Text>
                        <Input
                          value={lastName}
                          onChange={handleLastNameChange}
                        />
                        {(!lastName || lastName?.trim()?.length === 0) && (
                          <div>
                            <Text className="mt-8" type="danger">
                              {lastNameError}
                            </Text>
                          </div>
                        )}
                      </div>
                      <ButtonWithSpinner
                        disabled={
                          isCreatingOrg ||
                          companyError ||
                          firstNameError ||
                          lastNameError
                        }
                        isSpinning={isCreatingOrg}
                        className="mt-32 mb-24 font-weight-300 width-300-px"
                        onClick={() => {
                          handleCreateOrgWithName();
                        }}
                      >
                        Setup for your Company
                      </ButtonWithSpinner>
                      <Button
                        className="mb-24 font-weight-300 width-300-px"
                        onClick={handleSignOut}
                      >
                        Sign In with a different email
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <ButtonWithSpinner
                    disabled={isCreatingOrg || companyError}
                    isSpinning={isCreatingOrg && buttonWithSpinner === "google"}
                    className="mt-32 mb-24 font-weight-300 width-300-px"
                    onClick={signInWithGoogle}
                  >
                    <Icon component={GoogleImage} />
                    Continue with Google
                  </ButtonWithSpinner>

                  <ButtonWithSpinner
                    disabled={isCreatingOrg || companyError}
                    isSpinning={isCreatingOrg && buttonWithSpinner === "msft"}
                    className="mt-8 mb-24 font-weight-300 width-300-px"
                    onClick={signInWithMicrosoft}
                  >
                    <img
                      className={`mr-8 mt--3 ${styles.msftLogo}`}
                      src={microsoftImage}
                      alt="msft"
                    />
                    Continue with Microsoft
                  </ButtonWithSpinner>
                </>
              )}
            </>
          )}

          <Text
            className={`mt-8 mb-26 width-300-px ${styles.footerText}`}
            type="secondary"
          >
            By clicking Continue with Google, I accept the easy.xyz{" "}
            <span className="text-decoration-underline">Terms Of Service</span>{" "}
            and have read the{" "}
            <span className="text-decoration-underline">Privacy Policy</span>
          </Text>
        </div>
      </Card>
    </Layout>
  );
};

SignUp.propTypes = {};

export default SignUp;

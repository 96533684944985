import { useCallback, useState } from "react";

export const useCenteredTree = (rootChildrenLength) => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  // console.log({ rootChildrenLength });
  const containerRef = useCallback(
    (containerElem) => {
      if (containerElem !== null) {
        // const {
        //    width,
        //   height,
        // } = containerElem.getBoundingClientRect();
        setTranslate({ x: 0, y: (rootChildrenLength / 2) * 109 + 12 });
        // setTranslate({ x: width, y: height });
      }
    },
    [rootChildrenLength]
  );
  return [translate, containerRef];
};

export default useCenteredTree;

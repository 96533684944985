import React from "react";
import { Row, Col, Space } from "antd";

import { ButtonWithSpinner } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";

function StepFormActionButtons({
  mode = "",
  isSaving = false,
  currentStep,
  handleSaveAsDraft,
}) {
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  return (
    <Row>
      <Col span={24} className="text-align-right">
        <Space size="large">
          {mode !== "edit" && (
            <ButtonWithSpinner
              type="primary"
              spinnerColor={"#5330c9"}
              isSpinning={isSaving && buttonWithSpinner === "draft"}
              ghost
              onClick={(e) => {
                setButtonWithSpinner("draft");
                handleSaveAsDraft();
              }}
            >
              Save as Draft
            </ButtonWithSpinner>
          )}
          <ButtonWithSpinner
            isSpinning={isSaving && buttonWithSpinner === "submit"}
            disabled={isSaving}
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              setButtonWithSpinner("submit");
            }}
          >
            {currentStep === 0
              ? mode === "edit"
                ? "Update HPR Survey"
                : "Create HPR Survey"
              : "Next"}
          </ButtonWithSpinner>
        </Space>
      </Col>
    </Row>
  );
}

export default StepFormActionButtons;

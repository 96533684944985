import React from "react";
import { Row, Col, Button, Space } from "antd";

import { ButtonWithSpinner } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";

function StepFormActionButtons({
  mode = "create",
  isSaving = false,
  currentStep,
  handleSaveAsDraft,
  handlePreviousClick,
  lastStep,
  currentForm,
}) {
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  return (
    <Row className="mt-16">
      <Col span={4}>
        {currentStep !== 0 && (
          <Button
            type="primary"
            ghost
            onClick={() => {
              handlePreviousClick(currentForm);
            }}
          >
            Previous
          </Button>
        )}
      </Col>
      <Col span={20} className="text-align-right">
        <Space size="large">
          {mode !== "preview" && (
            <ButtonWithSpinner
              type="primary"
              spinnerColor={"#5330c9"}
              isSpinning={isSaving && buttonWithSpinner === "draft"}
              ghost
              onClick={(e) => {
                setButtonWithSpinner("draft");
                handleSaveAsDraft();
              }}
            >
              Save as Draft
            </ButtonWithSpinner>
          )}
          <ButtonWithSpinner
            isSpinning={isSaving && buttonWithSpinner === "submit"}
            disabled={
              isSaving || (mode === "preview" && currentStep === lastStep)
            }
            type="primary"
            htmlType="submit"
            onClick={(e) => {
              setButtonWithSpinner("submit");
            }}
          >
            {currentStep === lastStep ? "Submit Response" : "Next"}
          </ButtonWithSpinner>
        </Space>
      </Col>
    </Row>
  );
}

export default StepFormActionButtons;

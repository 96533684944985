import React from "react";
import PropTypes from "prop-types";
import { Select, Row, Col, Typography, Tooltip } from "antd";

import { ReactComponent as CalendarIcon } from "assets/icons/calendarIcon.svg";
import { formattedDateShortYear } from "app/appUtils";

const { Option } = Select;
const { Text } = Typography;

const OKRTimePeriodSelect = ({
  className = "",
  periods = [],
  selectedPeriodId,
  handleSelectedPeriodChange = () => {},
}) => {
  return (
    <Select
      value={selectedPeriodId}
      className={className ? className : "width-100-percent okr-time-period"}
      onChange={handleSelectedPeriodChange}
      suffixIcon={
        <Row className="mt--4">
          <CalendarIcon />
        </Row>
      }
      style={{ flex: "initial" }}
    >
      {periods?.map((period) => (
        <Option value={period?.id}>
          <Row
            align="middle"
            justify="start"
            gutter={[4]}
            className="height-100-percent okr-time-period-row"
          >
            <Col>
              <Tooltip title={period?.name}>
                <Text>{`${period?.name}`}</Text>
              </Tooltip>
            </Col>
            <Col>
              <Text
                type="secondary"
                className="font-size-14"
                style={{ verticalAlign: "middle" }}
                ellipsis={true}
              >{`(${formattedDateShortYear(
                period?.start_date
              )} - ${formattedDateShortYear(period?.end_date)})`}</Text>
            </Col>
          </Row>
        </Option>
      ))}
    </Select>
  );
};

OKRTimePeriodSelect.propTypes = {
  periods: PropTypes.array,
  selectedPeriodId: PropTypes.number,
  handleSelectedPeriodChange: PropTypes.func,
};

export default OKRTimePeriodSelect;

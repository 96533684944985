import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SortableTree from "react-sortable-tree";
import {
  Row,
  Dropdown,
  Menu,
  Typography,
  Input,
  Button,
  Modal,
  Tag,
} from "antd";
import {
  MoreOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { EmployeeAvatarWithName, EditEmployeeNameModal } from "components";
import EditManagerModel from "../common/EditManagerModel";
import ChangeInactiveManagerModal from "../common/ChangeInactiveManagerModal";
import { getInitialTreeData } from "app/company/common/utils";
import { getNameFromEmployeeObj } from "app/appUtils";

const { Text } = Typography;
const { confirm } = Modal;

const EmployeeNode = ({
  node,
  updatingEmployee = false,
  employees = [],
  inActiveEmployees = [],
  updateEmployee,
  addAdmin = () => {},
  removeAdmin = () => {},
}) => {
  const [isEditEmployeeVisible, setIsEditEmployeeVisible] = useState(false);
  const [isChangeManagerVisible, setIsChangeManagerVisible] = useState(false);
  const [isMarkInactiveVisible, setIsMarkInactiveVisible] = useState(false);

  const handleIsEditEmployeeVisible = () => {
    setIsEditEmployeeVisible(!isEditEmployeeVisible);
  };

  const handleIsChangeManagerVisible = () => {
    setIsChangeManagerVisible(!isChangeManagerVisible);
  };

  const handleIsMarkInactiveVisible = () => {
    setIsMarkInactiveVisible(!isMarkInactiveVisible);
  };

  const handleEmployeeNameChange = (
    employee,
    {
      first_name,
      last_name,
      email,
      department,
      sub_department,
      designation,
      company_employee_id,
    }
  ) => {
    updateEmployee({
      employeeId: employee.id,
      employee: {
        first_name,
        last_name,
        email,
        department,
        sub_department,
        designation,
        company_employee_id,
      },
    });
    setIsEditEmployeeVisible(false);
  };

  const handleUpdateManager = ({ employeeId, managerId }) => {
    updateEmployee({
      employeeId,
      employee: {
        manager_id: managerId || null,
      },
    });
    handleIsChangeManagerVisible();
  };

  const handleMarkInActiveSubmit = ({ employee = {} }) => {
    updateEmployee({
      employeeId: employee?.id,
      employee: {
        active: !employee?.active,
      },
    });
    handleIsMarkInactiveVisible();
  };

  const handleAddAsAdmin = (employee) => {
    confirm({
      title: "Add Admin",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Are you sure you want to add</Text>
          <Text strong>{` ${getNameFromEmployeeObj(employee)} `}</Text>
          <Text>{`as an admin?`}</Text>
        </>
      ),
      onOk() {
        addAdmin({ userEmail: employee?.email });
      },
      width: "1000px",
      okText: "Yes",
      // okButtonProps: { danger: true },
      cancelText: "Cancel",
      onCancel() {},
    });
  };

  const handleRemoveAsAdmin = (employee) => {
    confirm({
      title: "Remove Admin",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Are you sure you want to remove</Text>
          <Text strong>{` ${getNameFromEmployeeObj(employee)} `}</Text>
          <Text>{`as an admin?`}</Text>
        </>
      ),
      onOk() {
        removeAdmin({ userEmail: employee?.email });
      },
      width: "1000px",
      okText: "Yes",
      okButtonProps: { danger: true },
      cancelText: "Cancel",
      onCancel() {},
    });
  };

  const isAdmin = node?.role === "admin";

  return (
    <Row justify="space-between" align="middle">
      {isEditEmployeeVisible && (
        <EditEmployeeNameModal
          visible={isEditEmployeeVisible}
          onCancel={handleIsEditEmployeeVisible}
          onUpdate={handleEmployeeNameChange}
          employee={node}
        />
      )}

      {isChangeManagerVisible && (
        <EditManagerModel
          visible={isChangeManagerVisible}
          updatingEmployee={updatingEmployee}
          employee={node}
          activeEmployees={employees}
          inActiveEmployees={inActiveEmployees}
          onSubmit={handleUpdateManager}
          onCancel={handleIsChangeManagerVisible}
        />
      )}

      {isMarkInactiveVisible && (
        <ChangeInactiveManagerModal
          visible={isMarkInactiveVisible}
          employee={node}
          activeEmployees={employees}
          onSubmit={handleMarkInActiveSubmit}
          onCancel={handleIsMarkInactiveVisible}
        />
      )}

      <EmployeeAvatarWithName employee={node} shouldShowInactive={true} />
      {isAdmin && <Tag className="ml-8">admin</Tag>}

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="edit-employee"
              onClick={handleIsEditEmployeeVisible}
            >
              <Text>Edit Employee</Text>
            </Menu.Item>
            <Menu.Item
              key="change-manager"
              onClick={handleIsChangeManagerVisible}
            >
              <Text>Change Manager</Text>
            </Menu.Item>
            <Menu.Item
              key="mark-inactive"
              onClick={handleIsMarkInactiveVisible}
            >
              Mark Inactive
            </Menu.Item>
            {isAdmin ? (
              <Menu.Item
                key="remove-admin"
                onClick={() => handleRemoveAsAdmin(node)}
              >
                Remove as Admin
              </Menu.Item>
            ) : (
              <Menu.Item key="add-admin" onClick={() => handleAddAsAdmin(node)}>
                Add as Admin
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <MoreOutlined />
      </Dropdown>
    </Row>
  );
};

const DirectoryTreeViewV2 = ({
  isEmployeesFetching = false,
  employees = [],
  inActiveEmployees = [],
  updateEmployee = () => {},
  addAdmin = () => {},
  removeAdmin = () => {},
}) => {
  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [treeData, setTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );

  useEffect(() => {
    if (!isEmployeesFetching) {
      setTreeData(getInitialTreeData(employees, inActiveEmployees));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeesFetching]);

  const handleCustomSearch = ({ node, searchQuery }) => {
    return (
      searchQuery &&
      node?.name?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  };

  const handleSelectPrevMatch = () => {
    setSearchFocusIndex((prevState) =>
      prevState !== null
        ? (searchFoundCount + prevState - 1) % searchFoundCount
        : searchFoundCount - 1
    );
  };
  const handleSelectNextMatch = () => {
    setSearchFocusIndex((prevState) =>
      prevState !== null ? (prevState + 1) % searchFoundCount : 0
    );
  };

  const getNodeKey = ({ node: { id } }) => id;

  return (
    <div>
      <Row className="mb-16" align="middle">
        <Input
          className="width-250-px"
          id="find-box"
          type="text"
          placeholder="Search..."
          style={{ fontSize: "1rem" }}
          value={searchString}
          allowClear
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
        />

        <Button
          className="ml-8"
          size="small"
          disabled={!searchFoundCount}
          onClick={handleSelectPrevMatch}
        >
          <ArrowLeftOutlined />
        </Button>

        <Button
          type="submit"
          size="small"
          disabled={!searchFoundCount}
          onClick={handleSelectNextMatch}
        >
          <ArrowRightOutlined />
        </Button>

        <Text className="ml-8">
          {searchFoundCount > 0 ? searchFocusIndex + 1 : 0} /
          <Text className="ml-8">{searchFoundCount || 0}</Text>
        </Text>
      </Row>

      <div className="directory-tree-view-v2" style={{ height: 300 }}>
        <SortableTree
          treeData={treeData}
          onChange={(newTreeData) => setTreeData(newTreeData)}
          canDrag={() => false}
          canDrop={() => false}
          getNodeKey={getNodeKey}
          generateNodeProps={({ node, path }) => {
            return {
              title: ({ node }) => (
                <EmployeeNode
                  node={node}
                  employees={employees}
                  inActiveEmployees={inActiveEmployees}
                  updateEmployee={updateEmployee}
                  addAdmin={addAdmin}
                  removeAdmin={removeAdmin}
                />
              ),
              onClick: () => {},
            };
          }}
          //
          // Custom comparison for matching during search.
          // This is optional, and defaults to a case sensitive search of
          // the title and subtitle values.
          // see `defaultSearchMethod` in https://github.com/frontend-collective/react-sortable-tree/blob/master/src/utils/default-handlers.js
          searchMethod={handleCustomSearch}
          //
          // The query string used in the search. This is required for searching.
          searchQuery={searchString}
          //
          // When matches are found, this property lets you highlight a specific
          // match and scroll to it. This is optional.
          searchFocusOffset={searchFocusIndex}
          //
          // This callback returns the matches from the search,
          // including their `node`s, `treeIndex`es, and `path`s
          // Here I just use it to note how many matches were found.
          // This is optional, but without it, the only thing searches
          // do natively is outline the matching nodes.
          searchFinishCallback={(matches) => {
            setSearchFoundCount(matches.length);
            setSearchFocusIndex(
              matches.length > 0 ? searchFocusIndex % matches.length : 0
            );
          }}
          //
          // This prop only expands the nodes that are seached.
          onlyExpandSearchedNodes
        />
      </div>
    </div>
    // <TreeTable
    //   className="mb-24"
    //   columns={[
    //     {
    //       title: "Employee",
    //       dataIndex: "key",
    //       key: "key",
    //       render: (data, row) => {
    //         return (
    //           <EmployeeAvatarWithName
    //             employee={row}
    //             shouldShowInactive={true}
    //           />
    //         );
    //       },
    //     },
    //     {
    //       title: "Email",
    //       dataIndex: "email",
    //       key: "email",
    //       render: (data) => {
    //         return <Text>{data}</Text>;
    //       },
    //     },
    //   ]}
    //   shouldShowRowSelection={false}
    //   //   selections={[]}
    //   //   selectedRowKeys={[]}
    //   dataSource={treeData}
    //   initialTreeData={initialTreeData}
    //   activeEmployees={employees}
    //   inActiveEmployees={inActiveEmployees}
    //   setTreeData={setTreeData}
    //   // handleSelect={handleSelect}
    //   // handleSelectAll={handleSelectAll}
    //   handleResetSearch={handleResetSearch}
    // />
  );
};

DirectoryTreeViewV2.propTypes = {
  employees: PropTypes.array,
  inActiveEmployees: PropTypes.array,
};

export default DirectoryTreeViewV2;

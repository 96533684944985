import React from "react";
import PropTypes from "prop-types";
import { List, Row, Col, Typography, Popover, Space } from "antd";

import { EmployeeAvatar, EmployeeAvatarWithName } from "components";

const { Text } = Typography;

const Responders = ({ employees = [] }) => {
  return (
    <Popover
      content={
        <Space direction="vertical">
          {employees?.map((employee) => (
            <Space>
              <EmployeeAvatarWithName
                employee={employee}
                shouldShowYou={true}
              />
            </Space>
          ))}
        </Space>
      }
    >
      <div className="reviewer-avatars">
        {employees?.map((employee) => (
          <EmployeeAvatar
            shouldShowDetails={false}
            className="ml-4 mt-8"
            employee={employee}
          />
        ))}
      </div>
    </Popover>
  );
};

const ThreeSixthViewMCQSelectionsList = ({
  questionOptions = [],
  responses = [],
}) => {
  const optionsMap = {};
  const responseMap = {};

  questionOptions.forEach((option) => {
    optionsMap[option?.id] = option?.value;
    responseMap[option?.id] = [];
  });

  responses.forEach((response) => {
    const existingEmployees = responseMap[response?.answer] || [];
    const updatedEmployees = [...existingEmployees];
    updatedEmployees.push(response?.employee);
    responseMap[response?.answer] = updatedEmployees;
  });

  console.log(Object.entries(responseMap));

  return (
    <List
      bordered
      dataSource={Object.entries(responseMap) || []}
      renderItem={([answerId, responders]) => (
        <List.Item>
          <Row className="width-100-percent">
            <Col span={8}>
              <Text>{optionsMap[answerId] || ""}</Text>
            </Col>
            <Col span={16}>
              <Responders employees={responders} />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

ThreeSixthViewMCQSelectionsList.propTypes = {
  question: PropTypes.object,
  responses: PropTypes.array,
};

export default ThreeSixthViewMCQSelectionsList;

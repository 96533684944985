import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useQuery, useMutation } from "react-query";
import { Typography, Button, Card, Row, Col } from "antd";
import { Link } from "react-router-dom";

import {
  BoxLoader,
  // VimeoVideo,
  VimeoVideoModal,
  DisabledFeatureAlert,
} from "components";
import { useEmployees } from "hooks";
import { useCurrentCompany, useSetupCompletionPercentage } from "store";
import { getIsCompanyFeatureDisabled, errorNotification } from "app/appUtils";
import ReviewCycleCard from "./common/ReviewCycleCard";
import { getAllReviewCycleApi } from "apis/perfReviewApi";
import { updateCheckListApi } from "apis/setupCheckListApi";
import { REVIEWS_CHECKLIST_ID } from "app/appConstants";

const { Title, Text } = Typography;

function Reviews() {
  const [currentCompany] = useCurrentCompany();
  const [setupCompletionPercentage] = useSetupCompletionPercentage();

  const [isCompleteReviewsOpen, setIsCompletedReviewsOpen] = useState(false);
  const [isVimeoModalVisible, setIsVimeoModalVisible] = useState(false);
  const [isVimeoVideoVisible, setIsVimeoVideoVisible] = useState(true);

  useEffect(() => {
    if (!isVimeoVideoVisible) {
      setIsVimeoVideoVisible(true);
    }
  }, [isVimeoVideoVisible]);

  const { data, status, isFetching, refetch } = useQuery(
    "getReviewCycles",
    getAllReviewCycleApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
    isEmployeesFetching,
  } = useEmployees();

  const [updateCheckList] = useMutation(updateCheckListApi, {
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleCompletedReviewsOpen = () => {
    setIsCompletedReviewsOpen(!isCompleteReviewsOpen);
  };

  const handleIsVimeoModalVisible = () => {
    setIsVimeoModalVisible(!isVimeoModalVisible);
  };

  const handleUpdateCheckList = () => {
    if (setupCompletionPercentage !== "100") {
      updateCheckList({ step: REVIEWS_CHECKLIST_ID });
    }
  };

  const activeCycles =
    data?.filter(
      (cycle) => cycle?.summary?.sign_and_close_percent !== 100 || cycle.draft
    ) || [];
  const completedCycles =
    data?.filter(
      (cycle) => cycle?.summary?.sign_and_close_percent === 100 && !cycle.draft
    ) || [];

  const isLoading =
    status === "loading" || isEmployeesLoading || isEmployeesFetching;
  const isFeatureDisabled = getIsCompanyFeatureDisabled(
    "Reviews",
    currentCompany?.features
  );

  return (
    <>
      {isFeatureDisabled && <DisabledFeatureAlert featureName="Reviews" />}

      {isVimeoModalVisible && (
        <VimeoVideoModal
          title="Perf Review Tutorial"
          src="https://player.vimeo.com/video/486355266?color=e02c4b&title=0&byline=0&portrait=0"
          visible={isVimeoModalVisible}
          onCancel={handleIsVimeoModalVisible}
          onEnd={() => {
            handleUpdateCheckList();
            handleIsVimeoModalVisible();
          }}
        />
      )}

      <Row>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <Title level={2}>Perf Reviews</Title>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 14 }} className="company-create-btn">
          <Row align="middle" justify="end" gutter={[8]}>
            {!isLoading && activeCycles?.length > 0 && (
              <Col>
                {/* <TinyTutorialBtn
                  text="Watch tutorial"
                  onClick={handleIsVimeoModalVisible}
                /> */}
              </Col>
            )}
            <Col>
              <Link to="/company/reviews/add">
                <Button type="primary" disabled={isFeatureDisabled}>
                  Create Perf Review Cycle
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {isLoading && <BoxLoader />}
      {isFetching && !isLoading && <BoxLoader />}

      <div
        id="perf-review-popover-container"
        style={{ position: "relative" }}
      />
      <div id="okr-filter-menu" />
      <div id="okr-search-filter" />

      {!isLoading &&
        activeCycles?.length === 0 &&
        completedCycles?.length === 0 && (
          <Row>
            <Col span={24}>
              <Card>
                <Row justify="center" align="middle">
                  <Text>
                    {`There are no review cycles at  ${
                      currentCompany?.name || "your company"
                    }.`}
                  </Text>
                  {/*
                  {isVimeoVideoVisible && (
                    <VimeoVideo
                      shouldAutoPlay={false}
                      src="https://player.vimeo.com/video/486355266?color=e02c4b&title=0&byline=0&portrait=0"
                      onEnd={() => {
                        handleUpdateCheckList();
                        setIsVimeoVideoVisible(false);
                      }}
                    />
                  )} */}

                  <Link to="/company/reviews/add">
                    <Button
                      className="white-space-normal auto-height pl-0"
                      type="link"
                    >
                      Create your first Review Cycle
                    </Button>
                  </Link>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

      {!isLoading && activeCycles?.length > 0 && (
        <div className="mb-16 ">
          {activeCycles?.map((cycle) => (
            <ReviewCycleCard
              key={cycle?.id}
              disabled={isFeatureDisabled}
              reviewCycle={cycle}
              employees={activeEmployees}
              inActiveEmployees={inActiveEmployees}
              refetch={refetch}
            />
          ))}
        </div>
      )}

      {!isLoading && completedCycles?.length > 0 && (
        <Row
          justify="center"
          className={clsx("mt-8", isCompleteReviewsOpen ? "mb-8" : "mb-32")}
        >
          <Col size={6}>
            <Button size="medium" onClick={handleCompletedReviewsOpen}>
              {isCompleteReviewsOpen
                ? "Hide Completed Reviews"
                : "Show Completed Reviews"}
            </Button>
          </Col>
        </Row>
      )}

      {!isLoading && completedCycles?.length > 0 && isCompleteReviewsOpen && (
        <>
          {completedCycles?.map((cycle) => (
            <ReviewCycleCard
              key={cycle?.id}
              disabled={isFeatureDisabled}
              reviewCycle={cycle}
              employees={activeEmployees}
              inActiveEmployees={inActiveEmployees}
              refetch={refetch}
            />
          ))}
        </>
      )}
    </>
  );
}

export default Reviews;

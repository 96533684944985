import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { Row, Button } from "antd";

import { BoxLoader } from "components";
import EmptyOKRCard from "../common/EmptyOKRCard";
import MyOKRsList from "./list/MyOKRsList";
import Summary from "../common/Summary";
import { useCurrentCompany } from "store";
import { ReactComponent as ObjectivesIcon } from "assets/cold-screens/Objectives.svg";
import {
  getMyOKRsApi,
  saveKeyResultApi,
  saveInitiativeApi,
} from "apis/okrV2Api";
import { successNotification, errorNotification } from "app/appUtils";

const MyOKRs = ({
  canUpdate = true,
  selectedPeriodId = null,
  selectedPeriodObj = {},
  teams = [],
  timePeriods = [],
  shouldRefetch = false,
  handleIsAddOKRVisible = () => {},
  handleShouldRefetch = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();

  const [progressHash, setProgressHash] = useState({});

  const { data, status, isFetching, refetch } = useQuery(
    ["getMyOKRs", selectedPeriodId],
    getMyOKRsApi,
    {
      enabled: !!selectedPeriodId,
      onSuccess: (data) => {
        if (shouldRefetch) {
          handleShouldRefetch();
        }
        return data;
      },
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const [saveKeyResult] = useMutation(saveKeyResultApi, {
    onSuccess: () => {
      successNotification("Successfully saved key result");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [saveInitiative] = useMutation(saveInitiativeApi, {
    onSuccess: () => {
      successNotification("Successfully saved initiative");
      refetch();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch, refetch]);

  const handleUpdateProgressHash = (updatedProgressHash) => {
    setProgressHash(updatedProgressHash);
  };

  const myOkrLoader = status === "loading";

  const isEmpty =
    !isFetching &&
    Object.keys(data?.summary?.group_summary_data || {})?.length === 0 &&
    data?.objectives?.length === 0 &&
    (!data?.key_results ||
      (data?.key_results && data?.key_results?.length === 0));

  return (
    <>
      {myOkrLoader && <BoxLoader />}

      {!myOkrLoader && (
        <>
          {isEmpty && (
            <div className="my-16">
              <EmptyOKRCard
                canUpdate={canUpdate}
                Icon={<ObjectivesIcon />}
                text="No objectives to display."
                handleCreateObjective={handleIsAddOKRVisible}
              />
            </div>
          )}

          {(data?.objectives?.length !== 0 ||
            (data?.key_results && data?.key_results?.length !== 0)) && (
            <>
              {canUpdate ? (
                <Summary
                  overallProgress={data?.overall_progress}
                  objectiveProgressPercentage={
                    data?.objectives_percentage_progress_data
                  }
                  isMyOKR={true}
                  objectives={data?.objectives}
                  selectedPeriodObj={selectedPeriodObj}
                  shouldShowViews={false}
                />
              ) : (
                <></>
              )}
            </>
          )}

          <div id="progress-popover" style={{ position: "relative" }} />

          <MyOKRsList
            canUpdate={canUpdate}
            data={data}
            teams={teams}
            timePeriods={timePeriods}
            progressHash={progressHash}
            currentCompany={currentCompany}
            selectedPeriodObj={selectedPeriodObj}
            refetch={refetch}
            saveKeyResult={saveKeyResult}
            saveInitiative={saveInitiative}
            handleUpdateProgressHash={handleUpdateProgressHash}
          />
          {canUpdate && !isEmpty && (
            <Button type="primary" ghost={true}>
              <Row align="top" onClick={handleIsAddOKRVisible}>
                Create OKR
              </Row>
            </Button>
          )}
        </>
      )}
    </>
  );
};

MyOKRs.propTypes = {
  selectedPeriodId: PropTypes.number,
  selectedPeriodObj: PropTypes.object,
  teams: PropTypes.array,
  empData: PropTypes.object,
  periodLoader: PropTypes.bool,
  empStatus: PropTypes.string,
};

export default MyOKRs;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Typography, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

import {
  EmployeeAvatar,
  ButtonWithSpinner,
  PerfReviewMCQRadioGroup,
  ViewPerfReviewAnswerHistory,
} from "components";
import { errorNotification, successNotification } from "app/appUtils";
import { updatePerfReviewAnswerApi } from "apis/perfReviewApi";
import styles from "app/reviews/reviews.module.css";

const { Text } = Typography;

const PerfReviewEditMCQAnswer = ({
  reviewId,
  assessmentId,
  isRating = false,
  ratingOptions = [],
  question = {},
  employee = {},
  employeeDisplayName = "",
  employeeRelation = "",
  shouldShowEdit = false,
  isFromHrAdmin = false,
  questionOptionHash = {},
  answer = null,
}) => {
  const [
    updatePerfReviewAnswer,
    {
      isLoading: isUpdatingPerfReviewAnswer,
      status: updatePerfReviewAnswerStatus,
    },
  ] = useMutation(updatePerfReviewAnswerApi, {
    onSuccess: (data, variables) => {
      successNotification("Successfully updated the answer");
    },
    onError: (error, variables) => {
      errorNotification(error);
    },
  });

  const [isViewHistory, setIsViewHistory] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedAnswer, setUpdatedAnswer] = useState(answer);

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdateAnswer = (e) => {
    setUpdatedAnswer(e?.target?.value);
  };

  const handleIsViewHistory = () => {
    setIsViewHistory(!isViewHistory);
  };

  const handleSaveAnswer = () => {
    const questionId = question?.id;
    updatePerfReviewAnswer({
      reviewId,
      assessmentId,
      answers: { [questionId]: updatedAnswer },
    });
    handleIsEditing();
  };

  const handleUpdateRating = () => {
    updatePerfReviewAnswer({
      reviewId,
      assessmentId,
      rating_option_id: updatedAnswer,
    });
  };

  const loader =
    isUpdatingPerfReviewAnswer || updatePerfReviewAnswerStatus === "loading";

  return (
    <>
      {isViewHistory && (
        <ViewPerfReviewAnswerHistory
          visible={isViewHistory}
          reviewId={reviewId}
          isRating={isRating}
          questionId={question?.id}
          questionType={question?.type}
          questionOptionHash={questionOptionHash}
          assessmentId={assessmentId}
          onCancel={handleIsViewHistory}
        />
      )}

      <div className="flex-display mb-8">
        <EmployeeAvatar
          className="mr-8 mt-4 min-width-32-px"
          emptyText="No Reviewer"
          employee={employee}
        />
        <div className="width-100-percent">
          <div className={styles.empNameDiv}>
            <Text type="secondary">
              {employeeDisplayName}
              {employeeRelation}
            </Text>
            {shouldShowEdit && answer && (
              <>
                <EditOutlined
                  className={`${styles.editIcon} ml-8 text-secondary`}
                  onClick={handleIsEditing}
                />
                {isFromHrAdmin && (
                  <Button
                    className={`text-secondary font-size-12 p-0 px-4 ml-8 ${styles.editIcon}`}
                    style={{ height: "20px" }}
                    size="small"
                    // type="primary"
                    // ghost={true}
                    onClick={handleIsViewHistory}
                  >
                    View Edit History
                  </Button>
                )}
              </>
            )}
          </div>

          {answer ? (
            <>
              {isEditing ? (
                <div>
                  <div>
                    <>
                      <div className="width-100-percent">
                        <PerfReviewMCQRadioGroup
                          isRating={isRating}
                          question={question}
                          ratingOptions={ratingOptions}
                          value={updatedAnswer}
                          onChange={handleUpdateAnswer}
                        />
                      </div>
                    </>
                  </div>
                  <div>
                    <ButtonWithSpinner
                      isSpinning={loader}
                      size="small"
                      type="primary"
                      ghost={true}
                      onClick={isRating ? handleUpdateRating : handleSaveAnswer}
                    >
                      Update
                    </ButtonWithSpinner>
                    <Button
                      size="small"
                      className="ml-8"
                      onClick={handleIsEditing}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <Text>{questionOptionHash[updatedAnswer] || "-"}</Text>
              )}
            </>
          ) : (
            <Text type="secondary" className="italic-font">
              No answer yet
            </Text>
          )}
        </div>
      </div>
    </>
  );
};

PerfReviewEditMCQAnswer.propTypes = {
  employee: PropTypes.object,
};

export default PerfReviewEditMCQAnswer;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Modal, Button, Row, Divider, Input, Typography, Select } from "antd";
import * as Sentry from "@sentry/react";

import { BoxLoader, ButtonWithSpinner } from "components";
import { ReactComponent as PoweredByStripe } from "assets/billing/powered_by_stripe.svg";
import { useCurrentCompany } from "store";
import { currencies } from "app/appConstants";
import {
  errorNotificationWithString,
  errorNotification,
  successNotification,
} from "app/appUtils";
import {
  getSetupIntentApi,
  updatePaymentDetailsApi,
  updateSettingApi,
} from "apis/billingApi";

import styles from "../Billing.module.css";

const { Text, Title } = Typography;
const { Option } = Select;

const CardDetailsModel = ({
  type = null,
  currentAddress = "",
  shouldAskAddress = false,
  shouldAskCurrencyType = false,
  onCancel = () => {},
  refetchBillingDetails = () => {},
  handleUpdate = () => {},
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [currentCompany] = useCurrentCompany();

  const [isSubmittingToStripe, setIsSubmittingToStripe] = useState(false);
  const [address, setAddress] = useState(currentAddress);
  const [error, setError] = useState("");
  const [currency, setCurrency] = useState("usd");

  const {
    data: setupIntentData,
    status,
    isLoading,
    isFetching,
  } = useQuery("getSetupIntent", getSetupIntentApi, {
    onError: () => {
      errorNotification({});
      onCancel();
    },
  });

  const handleSuccess = () => {
    if (type === "add-card") {
      handleUpdate("paid_plan");
    } else {
      successNotification("Successfully updated Payment Details & Address");
      refetchBillingDetails();
    }
  };

  const [updateSetting, { isLoading: isUpdatingSettingLoading }] = useMutation(
    updateSettingApi,
    {
      onSuccess: () => {
        successNotification("Successfully updated Payment Details & Address");
        refetchBillingDetails();
        onCancel();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const [
    updatePaymentDetails,
    {
      status: updatingStatus,
      isLoading: isUpdatingLoading,
      isFetching: isUpdatingFetching,
    },
  ] = useMutation(updatePaymentDetailsApi, {
    onSuccess: () => {
      if (shouldAskAddress) {
        handleSuccess();
        updateSetting({ address, currency });
      } else {
        successNotification("Successfully updated Payment Details");
        refetchBillingDetails();
      }
      onCancel();
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const handleAddress = (e) => {
    const addressVal = e.target.value;
    setAddress(addressVal);
    if (!addressVal) {
      setError("Address is required");
    } else if (!addressVal?.trim()) {
      setError("Address cant be empty.");
    } else {
      setError("");
    }
  };

  const handleCurrencyChange = (val) => {
    setCurrency(val);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (type === "add-card" && (!address || error)) {
      errorNotificationWithString("Address can't be empty");
      return;
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    setIsSubmittingToStripe(true);
    const result = await stripe.confirmCardSetup(
      setupIntentData?.client_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: { name: currentCompany?.user?.name },
        },
        // return_url: "http://localhost:3000/settings/billing",
      },
      {
        handleActions: true,
      }
    );
    setIsSubmittingToStripe(false);

    if (result?.error) {
      if (result?.error?.code === "setup_intent_unexpected_state") {
        updatePaymentDetails({
          payment_details: {
            setup_intent_id: result?.error?.setup_intent?.id,
            payment_method_id: result?.error?.setup_intent?.payment_method,
          },
        });
      } else {
        errorNotificationWithString(result?.error?.message);
        Sentry.captureMessage(
          `${currentCompany?.user?.name} (${currentCompany?.user?.email}) - Adding card through stripe failed for the user`,
          { extra: result?.error }
        );
      }
    }
    if (result?.setupIntent) {
      updatePaymentDetails({
        payment_details: {
          setup_intent_id: result?.setupIntent?.id,
          payment_method_id: result?.setupIntent?.payment_method,
        },
      });
    }
  };

  const loader = status === "loading" || isLoading || isFetching;

  const spinning =
    !stripe ||
    !elements ||
    isSubmittingToStripe ||
    updatingStatus === "loading" ||
    isUpdatingLoading ||
    isUpdatingFetching ||
    isUpdatingSettingLoading;

  return (
    <Modal
      title={`${type === "add-card" ? "Add" : "Change"} Card Details`}
      visible={Boolean(type)}
      footer={null}
      onCancel={onCancel}
    >
      {loader ? (
        <BoxLoader />
      ) : (
        <>
          <div>
            {type === "change-card" && <Text>Enter details of new card</Text>}
            {/* <Input disabled={true} value={currentCompany?.user?.name} /> */}
            <CardElement className={styles.cardElement} />

            {shouldAskAddress && (
              <>
                <Title level={4}>Billing Address</Title>
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  className={`mb-8 input-textarea-resize-none ${
                    error && "input-text-area-error"
                  }`}
                  value={address}
                  onChange={handleAddress}
                  placeholder={`Joh Doe,
ABC Inc,
UITE 5A-1204,
799 E DRAGRAM,
TUCSON AZ 85705,
USA`}
                />
                {error && (
                  <Text className="block-display" type="danger">
                    {error}
                  </Text>
                )}
              </>
            )}
            {shouldAskCurrencyType && (
              <>
                <Title className="mb-0 mt-8" level={4}>
                  Currency
                </Title>

                <Select
                  className="block-display mt-8 mb-16 width-250-px"
                  defaultValue={currency}
                  onChange={handleCurrencyChange}
                >
                  {Object.entries(currencies)?.map(([key, val]) => (
                    <Option value={key}>{val}</Option>
                  ))}
                </Select>
              </>
            )}
            <PoweredByStripe style={{ height: "24px" }} />
            <Divider className="my-8" />
            <Row justify="end">
              <ButtonWithSpinner
                isSpinning={spinning}
                disabled={spinning}
                type="primary"
                onClick={handleSubmit}
              >
                {`${type === "add-card" ? "Add" : "Change"} Card Details`}
              </ButtonWithSpinner>
              <Button
                className="ml-8"
                type="primary"
                ghost={true}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Row>
          </div>
        </>
      )}
    </Modal>
  );
};

CardDetailsModel.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CardDetailsModel;

import axios from "axios";

const updateEnabledFeatureApi = async ({ features }) => {
  const { data } = await axios.put("/api/v2/admin/features/update", {
    features,
  });
  return data;
};

export { updateEnabledFeatureApi };

import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const DonutChart = ({
  series = [],
  labels = [],
  showLegend = false,
  showLabels = false,
  labelText = "",
  showName = true,
  valueOffsetY = 0,
  colors = [],
  totalFontSize = "16",
  valueFontSize = "16",
  width = 120,
  height = 90,
  formatter = () => {},
}) => {
  const options = {
    plotOptions: {
      pie: {
        // customScale: 1.2,
        // size: 150,
        donut: {
          labels: {
            show: showLabels,
            name: {
              show: showName,
            },
            value: {
              show: true,
              offsetY: valueOffsetY,
              fontSize: valueFontSize,
            },
            total: {
              show: true,
              label: labelText,
              fontSize: totalFontSize,
              formatter,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: { show: showLegend },
  };

  if (colors?.length > 0) {
    options.colors = colors;
  }

  return (
    <Chart
      options={options}
      series={series}
      type="donut"
      width={width}
      height={height}
    />
  );
};

DonutChart.propTypes = {
  seriesData: PropTypes.array,
};

export default DonutChart;

import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import {
  Typography,
  Button,
  Table,
  PageHeader,
  Modal,
  Tooltip,
  Row,
} from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { PlanCardsRow, BoxLoader, PlanCard } from "components";
import { useEmployees } from "hooks";
import { useCurrentCompany } from "store";
import CardDetailsModel from "./models/CardDetailsModel";
import ChangeAddress from "./models/ChangeAddress";
import ChangeCurrency from "./models/ChangeCurrency";
import { planNames, plans, planViewNames } from "app/appConstants";
import {
  formattedMonthYear,
  errorNotification,
  successNotification,
} from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";
import { getBillingPlanDetailsApi, updatePlanApi } from "apis/billingApi";

const { Text, Title, Link } = Typography;
const { confirm } = Modal;

const PlanBenefits = ({ name, charge = 0 }) => {
  const features =
    plans?.filter((plan) => plan?.id === name)[0]?.features || [];

  if (name === planNames?.paid) {
    features[features?.length - 1] = `3. USD ${charge} per user per month`;
  }

  return (
    <div className="flex-display flex-direction-column">
      <Text>{`You have the following benefits as part of ${planViewNames[name]} plan`}</Text>

      <div className="mt-16" style={{ width: "100%" }}>
        {features?.map((feature) => (
          <Text className="block-display">{feature}</Text>
        ))}
      </div>
    </div>
  );
};

const getStatusText = (status) => {
  switch (status) {
    case "success":
      return "Payment Successful";
    case "failed":
      return "Payment Failed";
    case "errored":
      return "Transaction Error";
    case "pending":
      return "Payment Pending";
    default:
      return "No Status";
  }
};

const invoiceColumns = [
  {
    title: "Month",
    dataIndex: "to_date",
    key: "to_date",
    render: (val) => <Text>{formattedMonthYear(val)}</Text>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (val, row) => <Text>{`${val} `}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (val) => (
      <Text type={val === "success" ? "success" : "danger"}>
        {getStatusText(val)}
      </Text>
    ),
  },
  {
    title: "Invoice",
    dataIndex: "pdf_link",
    key: "pdf_link",
    render: (val) => (
      <>
        {val ? (
          <Link href={val} target="_blank">
            View Invoice
          </Link>
        ) : (
          <Text>-</Text>
        )}
      </>
    ),
  },
];

const Plans = ({ shouldShowHeader = true }) => {
  const [currentCompany, setCurrentCompany] = useCurrentCompany();
  const [detailsModelType, setDetailsModelType] = useState(null);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [isCurrencyModalVisible, setIsCurrencyModalVisible] = useState(false);

  const { activeEmployees, isEmployeesLoading } = useEmployees();

  const { data, status, isLoading, isFetching, refetch } = useQuery(
    "getBillingPlanDetails",
    getBillingPlanDetailsApi
  );

  const [updatePlan, { isLoading: updateLoading }] = useMutation(
    updatePlanApi,
    {
      onSuccess: () => {
        successNotification("Successfully updated plan.");
        refetch();
        setCurrentCompany({ ...currentCompany, trial_days_remaining: null });
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handleUpdate = (name) => {
    updatePlan({ planName: name });
  };

  const handlesIsDetailsModelOpen = (type = "") => {
    if (!type) {
      setDetailsModelType(null);
    } else {
      setDetailsModelType(type);
    }
  };

  const openAddCard = () => {
    handlesIsDetailsModelOpen("add-card");
  };

  const openChangeCard = () => {
    handlesIsDetailsModelOpen("change-card");
  };

  const closeCard = () => {
    handlesIsDetailsModelOpen(null);
  };

  const handlesAddressModelVisible = () => {
    setIsAddressModalVisible(!isAddressModalVisible);
  };

  const handlesCurrencyModelVisible = () => {
    setIsCurrencyModalVisible(!isCurrencyModalVisible);
  };

  const loader =
    status === "loading" || isLoading || isFetching || isEmployeesLoading;

  if (loader || updateLoading) {
    return <BoxLoader />;
  }

  return (
    <>
      {shouldShowHeader && <PageHeader title="Billing" />}
      {detailsModelType && (
        <CardDetailsModel
          type={detailsModelType}
          currentAddress={data?.address}
          shouldAskAddress={detailsModelType === "add-card"}
          shouldAskCurrencyType={false}
          onCancel={closeCard}
          refetchBillingDetails={refetch}
          handleUpdate={handleUpdate}
        />
      )}
      {isAddressModalVisible && (
        <ChangeAddress
          isVisible={isAddressModalVisible}
          currentAddress={data?.address}
          onCancel={handlesAddressModelVisible}
          refetchBillingDetails={refetch}
        />
      )}
      {isCurrencyModalVisible && (
        <ChangeCurrency
          isVisible={isCurrencyModalVisible}
          defaultCurrency={data?.currency}
          onCancel={handlesCurrencyModelVisible}
          refetchBillingDetails={refetch}
        />
      )}

      {(data?.plan_name === planNames?.trial ||
        data?.plan_name === planNames?.trialExpired) && (
        <PlanCardsRow
          className="mt-32"
          onUpgrade={openAddCard}
          refetch={refetch}
        />
      )}

      {data?.plan_name === planNames?.earlyAdopter && (
        <>
          <Text>{`Current Plan: ${planViewNames[data?.plan_name]}`}</Text>
          <PlanBenefits name={planNames?.earlyAdopter} />
          <div className="mt-32">
            <Title level={4}>Invoices</Title>
            <Table
              pagination={{ size: "small" }}
              columns={invoiceColumns}
              dataSource={data?.invoices || []}
            />
          </div>
        </>
      )}

      {data?.plan_name === planNames?.free && (
        <>
          <Text>{`Current Plan: ${planViewNames[data?.plan_name]}`}</Text>

          <PlanBenefits name={planNames?.free} />
          <div className="mt-32">
            <Title level={4}>Invoices</Title>
            <Table
              pagination={{ size: "small" }}
              columns={invoiceColumns}
              dataSource={data?.invoices || []}
              scroll={mobileTableScroll}
            />
          </div>

          <PlanCard
            className="mt-40"
            plan={{
              ...plans[1],
              hint: `Recommended plan since you have ${activeEmployees?.length} active users.`,
            }}
            shouldShowHint={activeEmployees?.length > 5}
            ctaText="UPGRADE NOW"
            onSelect={() => {
              if (data?.card_last_4_digits) {
                handleUpdate("paid_plan");
              } else {
                openAddCard();
              }
            }}
          />
        </>
      )}

      {data?.plan_name === planNames?.paid && (
        <>
          <Text>{`Current Plan: ${planViewNames[data?.plan_name]}`}</Text>

          <PlanBenefits
            name={planNames?.paid}
            charge={data?.charge_per_user_per_month}
          />

          <div className="mt-40">
            <div>
              <Title level={4}>Current Payment Details</Title>
              <>
                {data?.invoice_payment_method === "stripe" && data?.currency === "usd" ? (
                  <>
                    {data?.card_last_4_digits ? (
                      <div>
                        <div>
                          <Text type="secondary">Payment Method: </Text>
                          <Text>Card</Text>
                        </div>
                        <div>
                          <Text type="secondary">Saved Card</Text>
                          <Button type="link" onClick={openChangeCard}>
                            Edit
                          </Button>
                        </div>
                        <Text>**** **** **** {data?.card_last_4_digits}</Text>
                      </div>
                    ) : (
                      <Button
                        className={"mt-4"}
                        type={"primary"}
                        ghost={true}
                        onClick={openAddCard}
                      >
                        Add Card Details
                      </Button>
                    )}
                  </>
                ) : (
                  <div className="mt-8">
                    <div>
                      <Text type="secondary">Payment Method: </Text>
                      <Text>Offline Payment</Text>
                    </div>
                    <Text type="secondary">Recipient: </Text>
                    <Text>{data?.recipient_email}</Text>
                  </div>
                )}
              </>

              <div className="mt-8">
                <Row align="middle">
                  <Text type="secondary">Billing Address</Text>
                  <Tooltip title="This address will be shown on the invoice.">
                    <InfoCircleOutlined className="ml-8" />
                  </Tooltip>
                  <Button type="link" onClick={handlesAddressModelVisible}>
                    Edit
                  </Button>
                </Row>
                {data?.address ? (
                  <pre
                    style={{
                      fontFamily: "Proxima Nova Lt",
                      lineHeight: 1.15,
                      fontSize: "18px",
                      fontWeight: 300,
                      color: "#191919",
                      fontVariant: "tabular-nums",
                    }}
                  >
                    {data?.address}
                  </pre>
                ) : (
                  <Text type="secondary">No Billing Address</Text>
                )}
              </div>

              <div className="mt-8">
                <Row align="middle">
                  <Text type="secondary">Billing Currency</Text>
                  <Tooltip title="The currency in which you want to pay the invoices.">
                    <InfoCircleOutlined className="ml-8" />
                  </Tooltip>
                </Row>

                {data?.currency?.toUpperCase() || "-"}
              </div>
            </div>
          </div>
          <div className="mt-32">
            <Title level={4}>Invoices</Title>
            <Table
              pagination={{ size: "small" }}
              columns={invoiceColumns}
              dataSource={data?.invoices || []}
            />
          </div>
          <PlanCard
            className="mt-40"
            plan={{
              ...plans[0],
              ctaDanger: true,
              ctaGhost: false,
              hint: `You have ${activeEmployees?.length} active users and ${
                activeEmployees?.length - 5
              } will loose access if you select this plan.`,
            }}
            shouldShowHint={activeEmployees?.length > 5}
            ctaText="DOWNGRADE"
            onSelect={() => {
              confirm({
                title: "Are you sure?",
                icon: <ExclamationCircleOutlined />,
                content: `This action will downgrade your company to a free plan.${
                  activeEmployees?.length > 5
                    ? ` You have ${activeEmployees?.length} active users and ${
                        activeEmployees?.length - 5
                      } will loose access if you select this plan.`
                    : ""
                }`,
                onOk() {
                  handleUpdate(planNames?.free);
                },
                okText: "Downgrade",
                okButtonProps: { danger: true },
                onCancel() {},
              });
            }}
          />
        </>
      )}
    </>
  );
};

Plans.propTypes = {};

export default Plans;

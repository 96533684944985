import React from "react";
import { Redirect } from "react-router";

import AppLayoutV3 from "components/layouts/AppLayoutV3";
import ContinuousFeedback from "./ContinuousFeedback";

const routes = [
  {
    exact: true,
    path: "/continuous-feedback",
    layout: AppLayoutV3,
    component: () => <Redirect to="/continuous-feedback/feedback-wall" />,
  },
  {
    exact: true,
    path: "/continuous-feedback/:feedbackTab", //feedbackTab can be feedback-wall or feedback-requests
    layout: AppLayoutV3,
    component: ContinuousFeedback,
  },
];

export default routes;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Steps, Form } from "antd";

import SurveyBasicsForm from "./SurveyBasicsForm";
import SurveyQuestions from "./questions/SurveyQuestions";
import { errorNotificationWithString } from "app/appUtils";

const { Step } = Steps;

const allQuestionsArentEmpty = (questions = []) => {
  let returnValue = true;
  questions.forEach((question) => {
    const questionDescription = question?.description || "";
    if (questionDescription?.trim() === "") {
      returnValue = false;
    }
  });
  return returnValue;
};

const SurveyForm = ({
  mode = "create",
  isSaving = false,
  draftStep = null,
  survey = {},
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  setSurvey = () => {},
  handleDone = () => {},
}) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (draftStep) {
      setCurrent(draftStep);
    }
  }, [draftStep]);

  const handlePreviousClick = (form) => {
    const values = form.getFieldsValue(true);
    setSurvey({ ...survey, ...values });
    setCurrent(current - 1);
  };

  const handleSubFormSubmit = (formName, { values, forms }) => {
    if (formName === "surveyBasics") {
      const participants = forms?.surveyBasics?.getFieldValue("participants");
      setSurvey({
        ...survey,
        ...values,
        participants,
      });

      setCurrent(1);
    } else if (formName === "surveyQuestions") {
      //TODO: Find out why values is empty: Answer: Form.Item with shouldUpdate is problematic
      const questions =
        forms?.surveyQuestions?.getFieldValue("questions") || [];
      if (questions.length > 0) {
        if (allQuestionsArentEmpty(questions)) {
          handleDone({ ...survey, questions });
          setSurvey({ ...survey, questions });
        } else {
          errorNotificationWithString(
            "Some questions have empty text please check."
          );
        }
      } else {
        errorNotificationWithString("Questions cant be empty.");
      }
    }
  };

  return (
    <>
      <Steps className="ml-40" current={current}>
        <Step key={1} title="Basics" />
        <Step className="mr-40" key={2} title="Questionnaire" />
      </Steps>
      <Form.Provider onFormFinish={handleSubFormSubmit}>
        {mode === "edit" && (
          <Alert
            className="my-8"
            type="error"
            message={"You are editing a live survey."}
          />
        )}
        {survey && current === 0 && (
          <SurveyBasicsForm
            mode={mode}
            initialValues={{ ...survey }}
            employees={employees}
            inActiveEmployees={inActiveEmployees}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
        {current === 1 && (
          <SurveyQuestions
            mode={mode}
            isSaving={isSaving}
            initialValues={survey}
            handleSaveAsDraft={handleSaveAsDraft}
            handlePreviousClick={handlePreviousClick}
          />
        )}
      </Form.Provider>
    </>
  );
};

SurveyForm.propTypes = {
  mode: PropTypes.string,
  survey: PropTypes.object,
  employees: PropTypes.array,
  setSurvey: PropTypes.func,
  handleSaveAsDraft: PropTypes.func,
  handleDone: PropTypes.func,
};

export default SurveyForm;

import React from "react";
// import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Empty, PageHeader, Button, Row, Typography } from "antd";

import CompanyDetails from "app/settings/directory/common/CompanyDetails";
import AddEmployee from "app/settings/directory/add-employee/AddEmployee";
import EnableFeatures from "app/company/enable_features/EnableFeatures";
import { ReactComponent as Bulb } from "assets/praise/bulb.svg";

const { Text, Link } = Typography;

const SelectedSetupStep = ({ selectedStep = 0, onCancel = () => {} }) => {
  const history = useHistory();

  const handleNavigateToOKRs = () => {
    onCancel();
    history.push("/okrs");
  };

  const handleNavigateToPerfReviews = () => {
    onCancel();
    history.push("/company/reviews");
  };

  if (selectedStep === 0) {
    return (
      <>
        <PageHeader className="pl-0 pt-0 pb-0" title="Update Company Details" />
        <Text className="block-display mb-24">
          This information will be used inside app, email communications, and
          Invoices.
        </Text>
        <CompanyDetails shouldShowCancel={false} />
      </>
    );
  }

  if (selectedStep === 1) {
    return (
      <AddEmployee
        defaultShouldNavigate={false}
        headerClassName="pt-0"
        titleLevel={4}
        headerTitle={"Onboard your employees"}
        description={
          "You could bulk upload via CSV upload or by adding them individually."
        }
      />
    );
  }

  if (selectedStep === 2) {
    return (
      <EnableFeatures
        description={
          "culture.easy is rich in its feature set and by default all the features are enabled. Feel free to disable any number of features if you don't need them currently."
        }
      />
    );
  }
  if (selectedStep === 3) {
    return (
      <>
        <Row justify="space-between" className={"max-width-500px"}>
          <PageHeader className="pl-0 pt-0 pb-0" title="OKRs" />
          <Button type="primary" size="small" onClick={handleNavigateToOKRs}>
            Go To OKRs
          </Button>
        </Row>
        <Text className="block-display mt-24 mb-8">
          The goal of this step is to roll out OKRs in your organization.
        </Text>
        <div className="block-display mt-16 mb-8">
          <Bulb style={{ height: "24px", width: "24px", marginLeft: "-5px" }} />
          <Text>
            If you are new to OKRs we have added a few resources links below to
            help you get started.
          </Text>
        </div>
        <Text className="block-display mb-8">
          1. Checkout our{" "}
          <Link href="https://culture.easy.xyz/okr-guide" target="_blank">
            detailed guide to OKRs
          </Link>
          .
        </Text>
        <Text className="block-display mb-8">
          2. Learn{" "}
          <Link
            href="https://culture.easy.xyz/how-to-write-good-okrs"
            target="_blank"
          >
            how to write good OKRs
          </Link>
          .
        </Text>
      </>
    );
  }
  if (selectedStep === 4) {
    return (
      <>
        <Row justify="space-between" className={"max-width-500px"}>
          <PageHeader className="pl-0 pt-0 pb-0" title="Performance Reviews" />
          <Button
            type="primary"
            size="small"
            onClick={handleNavigateToPerfReviews}
          >
            Go To Perf Reviews
          </Button>
        </Row>
        <Text className="block-display mt-24 mb-8">
          The goal of this step is to roll out Perf reviews across your
          organization.
        </Text>
        <div className="mt-16 mb-8">
          <Bulb style={{ height: "24px", width: "24px", marginLeft: "-5px" }} />
          <Text>
            We have added a few useful links below to help you get started.
          </Text>
        </div>
        <Text className="block-display mb-8">
          1. Checkout{" "}
          <Link
            href="https://culture.easy.xyz/top-10-questions-to-ask-employees-during-performance-reviews/"
            target="_blank"
          >
            Top 10 questions to ask in performance reviews
          </Link>
          .
        </Text>
        <Text className="block-display mb-8">
          2. Share this with the participants for{" "}
          <Link
            href="https://culture.easy.xyz/5-tips-for-effective-performance-reviews/"
            target="_blank"
          >
            effective performance reviews
          </Link>
          .
        </Text>
      </>
    );
  }

  return <Empty description="Something unexpected happened" />;
};

SelectedSetupStep.propTypes = {};

export default SelectedSetupStep;

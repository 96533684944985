import React from "react";
// import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Space } from "antd";
import styles from "app/company/common/CompanyNavBar.module.css";

const DirectoryNavBar = () => {
  return (
    <Space direction="vertical" className="mb-24">
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <NavLink
            to="/settings/directory"
            exact
            className={styles.link}
            activeClassName={styles.active}
          >
            Employees
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            to="/settings/directory/teams"
            className={styles.link}
            activeClassName={styles.active}
          >
            Teams
          </NavLink>
        </li>
      </ul>
    </Space>
  );
};

DirectoryNavBar.propTypes = {};

export default DirectoryNavBar;

import React from "react";
import PropTypes from "prop-types";
import { Row, Dropdown, Menu, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { ButtonWithSpinner } from "components";

const MenuFilters = ({
  type = "",
  teams = [],
  filters = {},
  setFilters = () => {},
  ...props
}) => {
  const statuses = filters[type] || [];

  const handleStatusChange = (selectedStatuses) => {
    setFilters((prevValues) => ({ ...prevValues, [type]: selectedStatuses }));
  };

  return (
    <>
      <Menu triggerSubMenuAction="click" {...props}>
        <Menu.SubMenu key="type" title="Type">
          <div
            className="m-16 width-150-px"
            onClick={(e) => e.stopPropagation()}
          >
            <Checkbox.Group value={statuses} onChange={handleStatusChange}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox
                  className="ml-8"
                  key="IndividualKPI"
                  value="IndividualKPI"
                >
                  Individual
                </Checkbox>
                <Checkbox key="OrganizationKPI" value="OrganizationKPI">
                  Company
                </Checkbox>
                {teams?.map((team) => (
                  <Checkbox key={team?.id} value={team?.id}>
                    {team?.name || ""}
                  </Checkbox>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        </Menu.SubMenu>
      </Menu>
    </>
  );
};
MenuFilters.propTypes = {
  filters: PropTypes.object,
};

const KPISearch = ({
  isTeamsLoading = false,
  type = "",
  teams = [],
  filters = {},
  setFilters = () => {},
}) => {
  return (
    <>
      <div style={{ display: "inline" }} id="okr-search-filter" />
      <Dropdown
        overlay={
          <MenuFilters
            teams={teams}
            type={type}
            filters={filters}
            setFilters={setFilters}
          />
        }
        getPopupContainer={() => document.getElementById("okr-search-filter")}
        trigger={"click"}
        placement="bottomCenter"
        arrow
      >
        <ButtonWithSpinner
          className="mr-16"
          disabled={isTeamsLoading}
          isSpinning={isTeamsLoading}
        >
          <FilterOutlined />
        </ButtonWithSpinner>
      </Dropdown>
    </>
  );
};

KPISearch.propTypes = {};

export default KPISearch;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQueryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { Typography, Space, Card } from "antd";
import moment from "moment";

import { BoxLoader } from "components";
import { useSetupCompletionPercentage } from "store";
import { useEmployees } from "hooks";
import { savePerfCycleApi } from "apis/perfReviewApi";
import FullScreenLayout from "components/layouts/FullScreenLayout";
import { ratingOptions } from "./common/constants";
import NotificationModal from "../common/NotificationModal";
import ReviewCycleForm from "../common/form-components/ReviewCycleForm";
import { errorNotification, successNotification } from "app/appUtils";

const { Text } = Typography;

const DEFAULT_QUESTIONS = [
  {
    type: "PerfReviewText",
    order: 1,
    reviewee_text: "What did I do well?",
    reviewer_text: "What did %FIRST_NAME% do well?",
    is_required: true,
  },
  {
    type: "PerfReviewText",
    order: 2,
    reviewee_text: "What can I improve on?",
    reviewer_text: "What can %FIRST_NAME% improve on?",
    is_required: true,
  },
  {
    type: "PerfReviewMcq",
    order: 3,
    reviewee_text: "I consistently treated people with respect.",
    reviewer_text: "%FIRST_NAME% consistently treated people with respect.",
    is_required: true,
    options: [
      { id: 1, value: "Strongly Agree" },
      { id: 2, value: "Agree" },
      { id: 3, value: "Disagree" },
    ],
  },
];

function AddReviewCycle({ mode = "new_cycle", initialValues = {} }) {
  const history = useHistory();
  const queryCache = useQueryCache();

  const [setupCompletionPercentage] = useSetupCompletionPercentage();

  const [cycleData, setCycleData] = useState(null);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const {
    activeEmployees,
    inActiveEmployees,
    isEmployeesLoading,
    isEmployeesFetching,
  } = useEmployees();

  useEffect(() => {
    if (
      !cycleData &&
      !isEmployeesLoading &&
      !isEmployeesFetching &&
      activeEmployees &&
      activeEmployees?.length > 0
    ) {
      if (mode === "new_cycle") {
        const currentQuarter = moment().quarter();
        const startDateOfQuarter = moment()
          .quarter(currentQuarter)
          .startOf("quarter");
        const endDateOfQuarter = moment()
          .quarter(currentQuarter)
          .endOf("quarter");

        const initValues = {
          name: `Perf Review Cycle Q${currentQuarter}/${moment().year()}`,
          description:
            // eslint-disable-next-line no-multi-str
            `Thank you for participating in the performance review process! \
Please write your Assessment with a focus on this review period. Highlight \
your achievements and accomplishments. In what area(s) do you feel you could \
benefit from additional support or direction?
`,
          rating_enabled: false,
          rating_options: ratingOptions,
          period: [startDateOfQuarter, endDateOfQuarter],
          deadline_for_adding_peer: moment().add(7, "d"),
          deadline_for_submit: moment().add(14, "d"),
          appeal_window: [null, null],
          deadline_for_sign: moment().add(22, "d"),
          auto_sign_date: null,
          for_entire_company: true,
          has_peer_reviews: false,
          participants: [],
          employees: JSON.parse(JSON.stringify(activeEmployees)),
          questions: DEFAULT_QUESTIONS.map((question) => ({ ...question })),
        };
        setCycleData({ ...initValues });
      } else {
        setCycleData({ ...initialValues });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEmployees, isEmployeesLoading]);

  const handleEmailDialogOpen = () => {
    setIsEmailDialogOpen(!isEmailDialogOpen);
  };

  const [savePerfCycle, { status, isLoading }] = useMutation(savePerfCycleApi, {
    onSuccess: (data) => {
      if (!data?.draft) {
        const msg = data.should_trigger_emails ? (
          <div>
            <Space direction="vertical">
              <Text>Perf Review Cycle Created</Text>
              <Text>Emails Sent</Text>
            </Space>
          </div>
        ) : (
          "Perf Review Cycle created"
        );
        successNotification(msg);
        if (setupCompletionPercentage !== "100") {
          queryCache.invalidateQueries("getCheckList");
        }
      }
      history.push("/company/reviews");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleCreatePerfReviewCycle = (should_trigger_emails) => {
    const period = cycleData?.period || [];
    let startDate = "";
    let endDate = "";
    if (period[0] && period[1]) {
      startDate = period[0].format("YYYY-MM-DD");
      endDate = period[1].format("YYYY-MM-DD");
    }
    const appealWindow = cycleData?.appeal_window || [];
    let appealWindowStart = "";
    let appealWindowEnd = "";
    if (appealWindow[0] && appealWindow[1]) {
      appealWindowStart = appealWindow[0].format("YYYY-MM-DD");
      appealWindowEnd = appealWindow[1].format("YYYY-MM-DD");
    }
    const managerMappings = cycleData?.managerMappings || {};
    const reviewsWithUpdatedManagers = cycleData?.reviews?.map((review) => ({
      ...review,
      reviewer_id:
        managerMappings[review?.reviewee_id] || review?.reviewer_id || null,
    }));
    const perf_review_cycle = {
      ...cycleData,
      draft: false,
      period_from: startDate,
      period_to: endDate,
      deadline_for_submit:
        cycleData["deadline_for_submit"].format("YYYY-MM-DD"),
      participants: reviewsWithUpdatedManagers || [],
      deadline_for_sign: cycleData["deadline_for_sign"].format("YYYY-MM-DD"),
      auto_sign_date: cycleData["auto_sign_date"]?.format("YYYY-MM-DD"),
      appeal_window_start_date: appealWindowStart,
      appeal_window_end_date: appealWindowEnd,
      deadline_for_adding_peer: cycleData["has_peer_reviews"]
        ? cycleData["deadline_for_adding_peer"].format("YYYY-MM-DD")
        : null,
      should_trigger_emails,
    };
    delete perf_review_cycle["managerMappings"];
    delete perf_review_cycle["employees"];
    delete perf_review_cycle["reviews"];
    savePerfCycle({ perf_review_cycle });
  };

  const handleSaveAsDraft = (values) => {
    const period = values?.period || [];
    let startDate = "";
    let endDate = "";
    if (period[0] && period[1]) {
      startDate = period[0].format("YYYY-MM-DD");
      endDate = period[1].format("YYYY-MM-DD");
    }
    const appealWindow = values?.appeal_window || [];
    let appealWindowStart = "";
    let appealWindowEnd = "";
    if (appealWindow[0] && appealWindow[1]) {
      appealWindowStart = appealWindow[0].format("YYYY-MM-DD");
      appealWindowEnd = appealWindow[1].format("YYYY-MM-DD");
    }
    const managerMappings = values?.managerMappings || {};
    const reviews = values?.reviews || [];
    const reviewsWithUpdatedManagers = reviews?.map((review) => ({
      ...review,
      reviewer_id:
        managerMappings[review?.reviewee_id] || review?.reviewer_id || null,
    }));
    const perf_review_cycle = {
      ...values,
      period_from: startDate,
      period_to: endDate,
      deadline_for_submit: values?.deadline_for_submit?.format("YYYY-MM-DD"),
      appeal_window_start_date: appealWindowStart,
      appeal_window_end_date: appealWindowEnd,
      deadline_for_sign: values?.deadline_for_sign?.format("YYYY-MM-DD"),
      auto_sign_date: values?.auto_sign_date?.format("YYYY-MM-DD"),
      deadline_for_adding_peer: values?.has_peer_reviews
        ? values?.deadline_for_adding_peer?.format("YYYY-MM-DD")
        : null,
      participants: reviewsWithUpdatedManagers,
      should_send_emails: false,
      draft: true,
    };
    delete perf_review_cycle["employees"];
    delete perf_review_cycle["reviews"];
    savePerfCycle({ perf_review_cycle });
  };

  const loader = isEmployeesLoading || isEmployeesFetching;

  const empDataWithKeys =
    activeEmployees?.map((emp) => ({ ...emp, key: emp?.id })) || [];

  return (
    <>
      <Helmet>
        <title>Company - culture.easy</title>
      </Helmet>
      <NotificationModal
        from="form"
        cycle={cycleData}
        isLoading={status === "loading" || isLoading}
        visible={isEmailDialogOpen}
        handleCancel={handleEmailDialogOpen}
        handleSubmit={handleCreatePerfReviewCycle}
      />
      <FullScreenLayout
        source={{ path: "/company/reviews", name: "Reviews" }}
        title="Create Review Cycle."
      >
        {loader ? (
          <BoxLoader />
        ) : (
          <Card>
            <ReviewCycleForm
              mode="create"
              draftStep={cycleData?.draft_step || null}
              cycleData={cycleData}
              employees={empDataWithKeys}
              inActiveEmployees={inActiveEmployees}
              handleSaveAsDraft={handleSaveAsDraft}
              setCycleData={setCycleData}
              handleDone={handleEmailDialogOpen}
            />
          </Card>
        )}
      </FullScreenLayout>
    </>
  );
}

export default AddReviewCycle;

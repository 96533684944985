import axios from "axios";

const adminListApi = async () => {
  const { data } = await axios.get(`/api/v2/admin/groups/admins`);
  return data;
};

const addAdminApi = async ({ userEmail }) => {
  const { data } = await axios.post(`/api/v2/admin/groups/add_admin`, {
    user_email: userEmail,
  });
  return data;
};

const removeAdminApi = async ({ userEmail }) => {
  const { data } = await axios.post(`/api/v2/admin/groups/delete_admin`, {
    user_email: userEmail,
  });
  return data;
};

export { adminListApi, addAdminApi, removeAdminApi };

import React, { useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { Button, PageHeader, Empty, Row, Col, Typography } from "antd";

import { BoxLoader, VimeoVideoModal } from "components";
import BadgeForm from "./common/BadgeForm";
import BadgeListItem from "./common/BadgeListItem";
import { getAllBadgesApi } from "apis/praiseApis";
import { errorNotification, errorNotificationWithString } from "app/appUtils";

const { Title } = Typography;

const PraiseBadges = ({ shouldShowHeader = true }) => {
  const [isCreateNewBadge, setIsCreateNewBadge] = useState(false);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isVimeoModalVisible, setIsVimeoModalVisible] = useState(false);

  const { data, status, isLoading, isFetching, refetch } = useQuery(
    "getAllBadges",
    getAllBadgesApi,
    {
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const canCreateOrEdit = !isCreateNewBadge && !isBeingEdited;

  const handleCreateNewBadge = () => {
    if (isCreateNewBadge) {
      setIsCreateNewBadge(false);
    } else {
      if (canCreateOrEdit) {
        setIsCreateNewBadge(true);
      } else {
        errorNotificationWithString(
          "Cant create new badge while editing others!"
        );
      }
    }
  };

  const handleIsVimeoModalVisible = () => {
    setIsVimeoModalVisible(!isVimeoModalVisible);
  };

  const loader = status === "loading" || isLoading || isFetching;

  return (
    <div>
      <Helmet>
        <title>Settings - culture.easy</title>
      </Helmet>

      {shouldShowHeader ? (
        <PageHeader
          className="pl-0 pt-0"
          title={<Title level={2}>Praise Badges</Title>}
          // onBack={() => window.history.back()}
          extra={[
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                {/* <TinyTutorialBtn
                  text="Watch tutorial"
                  onClick={handleIsVimeoModalVisible}
                /> */}
              </Col>
              <Col>
                <Button type="primary" onClick={handleCreateNewBadge}>
                  Create New Badge
                </Button>
              </Col>
            </Row>,
          ]}
        />
      ) : (
        <div className="flex-display justify-content-flex-end">
          <Button type="primary" onClick={handleCreateNewBadge}>
            Create New Badge
          </Button>
        </div>
      )}

      {loader && <BoxLoader />}

      {isVimeoModalVisible && (
        <VimeoVideoModal
          title="Praise Tutorial"
          src="https://player.vimeo.com/video/487977084?color=e02c4b&title=0&byline=0&portrait=0"
          visible={isVimeoModalVisible}
          onCancel={handleIsVimeoModalVisible}
        />
      )}

      {!loader && isCreateNewBadge ? (
        <BadgeForm
          refetch={refetch}
          onCancel={handleCreateNewBadge}
          onSave={handleCreateNewBadge}
        />
      ) : null}

      {!loader && data?.length === 0 && <Empty description="No Badges!!" />}

      {!loader &&
        data?.map((badge) => (
          <BadgeListItem
            refetch={refetch}
            badge={badge}
            canCreateOrEdit={canCreateOrEdit}
            setIsBeingEdited={setIsBeingEdited}
          />
        ))}
    </div>
  );
};

PraiseBadges.propTypes = {};

export default PraiseBadges;

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, Tooltip } from "antd";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";

import { PerfReviewNudge } from "components";
import { getNameFromEmployeeObj } from "app/appUtils";

const { Text } = Typography;

const PerfReviewExpandedRowRender = ({
  type = "self",
  cycle = {},
  hasPeersEnabled = false,
  shouldShowRevieweeNudge = true,
  shouldShowReviewerNudge = true,
  row = {},
  setIsNudging = () => {},
  handleNudgeSuccess = () => {},
  handleAddAndFinalizePeers = () => {},
}) => {
  const submittedPeersLength = row?.peers?.filter(
    (peer) => peer?.status === "Submitted"
  )?.length;

  return (
    <Row key={row?.id} style={{ backgroundColor: "#f7f7f7" }}>
      <Col span={8}>
        <Text className="block-display mb-16" strong>
          Self Review
        </Text>
        <Row align="middle">
          <Text>{row?.reviewee_status}</Text>
          {row?.reviewee_status !== "Submitted" &&
            row?.reviewee_status !== "Signed" && (
              <PerfReviewNudge
                type="reviewee"
                className="ml-8"
                shouldShowNudge={shouldShowRevieweeNudge}
                employee={row?.reviewee}
                nudgedAt={row?.reviewee_nudge_at}
                reviewCycle={cycle}
                reviewId={row?.id}
                status={row?.reviewee_status}
                otherStatus={row?.reviewer_status}
                setIsNudging={setIsNudging}
                onSuccess={handleNudgeSuccess}
              />
            )}
        </Row>
      </Col>
      {hasPeersEnabled && (
        <Col span={8}>
          <Text className="block-display  mb-16" strong>
            Peer Reviews
          </Text>
          <Row align="middle">
            <Tooltip
              title={
                row?.peers?.length > 0 ? (
                  <div>
                    {row?.peers?.map((peer) => (
                      <div className="flex-display align-items-center">
                        {peer?.status === "Submitted" ? (
                          <CheckOutlined />
                        ) : null}
                        <Text
                          className="text-color-white ml-8"
                          style={{
                            marginLeft:
                              peer?.status !== "Submitted" ? "22px" : "",
                          }}
                        >
                          {type === "self"
                            ? "Anonymous"
                            : getNameFromEmployeeObj(peer)}
                        </Text>
                      </div>
                    ))}
                  </div>
                ) : null
              }
            >
              <Text className="cursor-pointer">{`${submittedPeersLength} / ${row?.peers?.length} Submitted`}</Text>
            </Tooltip>
            {type === "hr-admin" && (
              <EditOutlined
                className="ml-8 mr-8 cursor-pointer"
                onClick={handleAddAndFinalizePeers}
              />
            )}
            {row?.peer_selection_finalized &&
              submittedPeersLength !== row?.peers?.length && (
                <PerfReviewNudge
                  className="ml-8"
                  type="peer"
                  shouldShowPeerNudge={
                    row?.peers?.filter((peer) => peer?.status === "Submitted")
                      ?.length !== row?.peers?.length
                  }
                  peerNudgeTooltipTitle={
                    row?.peers?.filter((peer) => peer?.status === "Submitted")
                      ?.length === row?.peers?.length
                      ? ""
                      : "Nudge the peers who have not yet submitted to write their review"
                  }
                  employee={row?.reviewer}
                  reviewId={row?.id}
                  nudgedAt={row?.reviewer_nudge_at}
                  reviewCycle={cycle}
                  status={row?.reviewer_status}
                  otherStatus={row?.reviewee_status}
                  setIsNudging={setIsNudging}
                  onSuccess={handleNudgeSuccess}
                />
              )}
            {type === "hr-admin" && row?.peer_selection_finalized && (
              <Text type="secondary" className="font-size-14 ml-8">
                (finalized)
              </Text>
            )}
          </Row>
        </Col>
      )}
      {Boolean(row?.reviewer) && (
        <Col span={8}>
          <Text className="block-display  mb-16" strong>
            Manager
          </Text>
          <Row align="middle">
            <Text>{row?.reviewer_status}</Text>
            {row?.reviewer_status !== "Submitted" &&
              row?.reviewer_status !== "Signed" && (
                <PerfReviewNudge
                  className="ml-8"
                  type="reviewer"
                  shouldShowNudge={shouldShowReviewerNudge}
                  employee={row?.reviewer}
                  reviewId={row?.id}
                  nudgedAt={row?.reviewer_nudge_at}
                  reviewCycle={cycle}
                  status={row?.reviewer_status}
                  otherStatus={row?.reviewee_status}
                  setIsNudging={setIsNudging}
                  onSuccess={handleNudgeSuccess}
                />
              )}
          </Row>
        </Col>
      )}
    </Row>
  );
};

PerfReviewExpandedRowRender.propTypes = {
  type: PropTypes.string,
  hasPeersEnabled: PropTypes.bool,
  row: PropTypes.object,
};

export default PerfReviewExpandedRowRender;

import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import moment from "moment";
import { Typography, Timeline, Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { BoxLoader, EmployeeAvatarWithName } from "components";
import { useEmployees } from "hooks";
import { formattedDateTime } from "app/appUtils";
import { getKPIActivityDataApi } from "apis/kpis";

import styles from "../../../OKRs.module.css";

const { Text } = Typography;

const getType = (type) => {
  if (type === "OrganizationKpi") {
    return "Organization";
  }

  if (type === "IndividualKpi") {
    return "Individual";
  }

  return "Team";
};

const getActivityDataSource = (data = {}, empHash = {}) => {
  const {
    kpi_current_value_checkins: currentValueCheckins = [],
    kpi_target_value_checkins: targetValueCheckins = [],
    comments = [],
    change_logs: changeLogs = [],
  } = data;

  const dataSource = [];

  comments.forEach((comment) => {
    if (!comment?.deleted) {
      dataSource.push({
        created_at: comment?.created_at,
        title: (
          <Text>
            <EmployeeAvatarWithName
              emptyText="An employee"
              className="mr-8"
              employee={comment}
            />
            made a comment at
          </Text>
        ),
        activity: (
          <span>
            <Text className="mt-8">{comment?.content}</Text>
            {comment?.created_at !== comment?.updated_at && !comment?.deleted && (
              <Text type="secondary" className="ml-8 mt-8 font-size-14">
                (edited)
              </Text>
            )}
          </span>
        ),
      });
    }
  });

  for (let i = currentValueCheckins?.length - 1; i >= 0; i--) {
    if (i !== 0) {
      dataSource.push({
        created_at: currentValueCheckins[i]?.created_at,
        title: (
          <>
            {currentValueCheckins[i]?.updated_by ? (
              <Text>
                <EmployeeAvatarWithName
                  className="mr-8"
                  emptyText="An employee"
                  employee={currentValueCheckins[i]?.updated_by}
                />
                updated progress at
              </Text>
            ) : (
              <Text>A progress update was made at</Text>
            )}
          </>
        ),
        activity: (
          <span>
            <Text className="mt-8">
              {`Checkin: ${currentValueCheckins[i - 1]?.current_value} `}
            </Text>
            <ArrowRightOutlined className="mx-8" />
            <Text className="mt-8">
              {`
              ${currentValueCheckins[i]?.current_value} `}
            </Text>
          </span>
        ),
      });
    } else {
      dataSource.push({
        created_at: currentValueCheckins[i]?.created_at,
        title: (
          <>
            {currentValueCheckins[i]?.updated_by ? (
              <Text>
                <EmployeeAvatarWithName
                  className="mr-8"
                  emptyText="An employee"
                  employee={currentValueCheckins[i]?.updated_by}
                />
                made a checkin at
              </Text>
            ) : (
              <Text>A checkin was made at</Text>
            )}
          </>
        ),
        activity: (
          <span>
            <Text className="mt-8">{"Checkin: - "}</Text>
            <ArrowRightOutlined className="mx-8" />
            <Text className="mt-8">
              {currentValueCheckins[i]?.current_value}
            </Text>
          </span>
        ),
      });
    }
  }

  targetValueCheckins.forEach((targetValueCheckin) => {
    dataSource.push({
      created_at: targetValueCheckin?.created_at,
      title: (
        <>
          {targetValueCheckin?.updated_by ? (
            <Text>
              <EmployeeAvatarWithName
                className="mr-8"
                emptyText="An employee"
                employee={targetValueCheckin?.updated_by}
              />
              updated target value at
            </Text>
          ) : (
            <Text>A target value was updated at</Text>
          )}
        </>
      ),
      activity: (
        <span>
          <Text className="mt-8">{`Target value for ${targetValueCheckin?.target_month}/${targetValueCheckin?.target_year} was set as ${targetValueCheckin?.operator} ${targetValueCheckin?.target_value}`}</Text>
        </span>
      ),
    });
  });

  changeLogs.forEach((change) => {
    dataSource.push({
      created_at: change?.created_at,
      title: (
        <Text>
          <EmployeeAvatarWithName
            className="mr-8"
            employee={change?.updated_by}
            emptyText="An employee"
          />
          made the following changes at
        </Text>
      ),
      activity: (
        <>
          {change?.changes?.title && (
            <Text className="mt-8 block-display">
              {`Title: ${change?.changes?.title[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {`${change?.changes?.title[1]}`}
            </Text>
          )}

          {change?.changes?.description && (
            <Text className="mt-8 block-display">
              {`Description: ${change?.changes?.description[0] || "-"}`}
              <ArrowRightOutlined className="mx-8" />
              {`${change?.changes?.description[1]}`}
            </Text>
          )}

          {change?.changes?.kpi_metric && (
            <Text className="mt-8 block-display">
              {`KPI Metric: ${change?.changes?.kpi_metric[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {change?.changes?.kpi_metric[1]}
            </Text>
          )}

          {change?.changes?.type && (
            <Text className="mt-8 block-display">
              {`Type: ${getType(change?.changes?.type[0])}`}
              <ArrowRightOutlined className="mx-8" />
              {getType(change?.changes?.type[1])}
            </Text>
          )}

          {change?.changes?.owner_id && (
            <span className="block-display">
              <Text className="mt-8">{`Owner: `}</Text>
              <EmployeeAvatarWithName
                employee={empHash[change?.changes?.owner_id[0]]}
              />
              <ArrowRightOutlined className="mx-8" />

              <EmployeeAvatarWithName
                employee={empHash[change?.changes?.owner_id[1]]}
              />
            </span>
          )}
        </>
      ),
    });
  });

  dataSource.sort((a, b) => {
    const aCreatedAt = a?.created_at;
    const bCreatedAt = b?.created_at;

    if (
      moment(aCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").isAfter(
        moment(bCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return -1;
    }
    if (
      moment(aCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").isBefore(
        moment(bCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return 1;
    }
    return 0;
  });

  return dataSource;
};

const ItemActivity = ({ kpi = {} }) => {
  const { data, status, isLoading, isFetching } = useQuery(
    ["getKPIActivityData", kpi?.id],
    getKPIActivityDataApi
  );

  const { allEmployeesHash, isEmployeesLoading, isEmployeesFetching } =
    useEmployees();

  const loader =
    status === "loading" ||
    isLoading ||
    isFetching ||
    isEmployeesLoading ||
    isEmployeesFetching;

  const dataSource = getActivityDataSource(data || {}, allEmployeesHash || {});

  return (
    <div>
      {loader ? (
        <BoxLoader />
      ) : (
        <div className={styles.activityCommentsTabPane}>
          <div className="my-8" />
          <Timeline mode="left">
            {dataSource?.map((item) => (
              <Timeline.Item>
                <>
                  <div className="mb-8">
                    <Text>{item?.title || ""}</Text>
                    <Text type="secondary" className="ml-8">
                      {formattedDateTime(item?.created_at)}
                    </Text>
                  </div>
                  {item?.activity || ""}
                </>
              </Timeline.Item>
            ))}
          </Timeline>
          {dataSource?.length === 0 && (
            <Empty description="No Activity to show"></Empty>
          )}
        </div>
      )}
    </div>
  );
};

ItemActivity.propTypes = {
  objectiveId: PropTypes.number,
  keyResultId: PropTypes.number,
  keyResultComments: PropTypes.array,
  handleUpdateComments: PropTypes.func,
};

export default ItemActivity;

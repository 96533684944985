import React from "react";
import PropTypes from "prop-types";
import { Modal, Tabs, Table } from "antd";

const { TabPane } = Tabs;

const UploadResultModal = ({
  isVisible = false,
  data = {},
  onCancel = () => {},
}) => {
  const { success, errors } = data;
  return (
    <Modal
      title="Upload Result"
      width={"65%"}
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Tabs>
        <TabPane tab={`Errors (${errors?.length})`} key={1}>
          <Table
            columns={[{ title: "Errors", dataIndex: "error", key: "error" }]}
            dataSource={errors?.map((err) => ({ error: err }))}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        </TabPane>
        <TabPane tab={`Success (${success?.length})`} key={2}>
          <Table
            columns={[
              {
                title: "Reviewee Email",
                dataIndex: "reviewee_email",
                key: "reviewee_email",
              },
              {
                title: "Updated Reviewer Rating",
                dataIndex: "reviewer_rating",
                key: "reviewer_rating",
              },
            ]}
            dataSource={success?.map((item) => ({
              reviewee_email: item[0],
              reviewer_rating: item[1],
            }))}
            pagination={{
              hideOnSinglePage: true,
              size: "small",
            }}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

UploadResultModal.propTypes = {
  isVisible: PropTypes.bool,
  data: PropTypes.object,
  onCancel: PropTypes.func,
};

export default UploadResultModal;

import React from "react";
import { Redirect } from "react-router-dom";
import AppLayoutV3 from "components/layouts/AppLayoutV3";
import AddSurveys from "./surveys/add/AddSurveys";
import EditSurvey from "./surveys/edit/EditSurvey";
import CloneSurvey from "./surveys/clone/CloneSurvey";
import SurveyCreateFromDraft from "./surveys/create_from_draft/CreateSurveyFromDraft";
import AddReviewCycle from "./reviews/add/AddReviewCycle";
import EditReviewCycle from "./reviews/edit/EditReviewCycle";
import CreateReviewCycleFromDraft from "./reviews/create_from_draft/CreateReviewCycleFromDraft";
import AddThreeSixtyFeedbackCycle from "./three_sixty_feedback/add/AddThreeSixtyFeedbackCycle";
import CreateThreeSixtyFeedbackCycleFromDraft from "./three_sixty_feedback/create_from_draft/CreateThreeSixtyFeedbackCycleFromDraft";
import AddHprSurveys from "./hpr_surveys/add/AddHprSurveys";
import EditHprSurvey from "./hpr_surveys/edit/EditHprSurvey";
import CreateFromDraftHprSurvey from "./hpr_surveys/create_from_draft/CreateFromDraftHprSurvey";
import Company from "./Company";

import { useCompanySubTab } from "store";

const RedirectBasedOnStoredSubTab = () => {
  const [subTab] = useCompanySubTab();

  switch (subTab) {
    case "okrs":
      return <Redirect to="/company/okrs" />;
    case "reviews":
      return <Redirect to="/company/reviews" />;
    case "surveys":
      return <Redirect to="/company/surveys" />;
    case "360-feedback":
      return <Redirect to="/company/360-feedback" />;
    case "hpr-surveys":
      return <Redirect to="/company/hpr-surveys" />;
    default:
      return <Redirect to="/company/overview" />;
  }
};

const routes = [
  {
    exact: true,
    path: "/company",
    layout: AppLayoutV3,
    component: RedirectBasedOnStoredSubTab,
  },
  // {
  //   exact: true,
  //   path: "/company/overview",
  //   layout: AppLayoutV3,
  //   component: Overview,
  // },
  // {
  //   exact: true,
  //   path: "/company/reviews",
  //   layout: AppLayoutV3,
  //   component: Reviews,
  // },
  {
    exact: true,
    path: "/company/reviews/add",
    component: AddReviewCycle,
  },
  {
    exact: true,
    path: "/company/reviews/:id/edit",
    component: EditReviewCycle,
  },
  {
    exact: true,
    path: "/company/reviews/:id/create_from_draft",
    component: CreateReviewCycleFromDraft,
  },
  // {
  //   exact: true,
  //   path: "/company/surveys",
  //   layout: AppLayoutV3,
  //   component: Surveys,
  // },
  {
    exact: true,
    path: "/company/survey/edit/:id",
    component: EditSurvey,
  },
  {
    exact: true,
    path: "/company/survey/clone_from/:id",
    component: CloneSurvey,
  },
  {
    exact: true,
    path: "/company/survey/create_from_draft/:id",
    component: SurveyCreateFromDraft,
  },
  {
    exact: true,
    path: "/company/surveys/add",
    component: AddSurveys,
  },
  // {
  //   exact: true,
  //   path: "/company/hpr-surveys",
  //   component: HprSurveys,
  //   layout: AppLayoutV3,
  // },
  {
    exact: true,
    path: "/company/hpr-surveys/add",
    component: AddHprSurveys,
  },
  {
    exact: true,
    path: "/company/hpr-survey/edit/:id",
    component: EditHprSurvey,
  },
  {
    exact: true,
    path: "/company/hpr-survey/create-from-draft/:id",
    component: CreateFromDraftHprSurvey,
  },
  // {
  //   exact: true,
  //   path: "/company/okrs",
  //   layout: AppLayoutV3,
  //   component: OKRs,
  // },
  // {
  //   exact: true,
  //   path: "/company/okrs/notifications",
  //   layout: AppLayoutV3,
  //   component: OKRNotifications,
  // },
  // {
  //   exact: true,
  //   path: "/company/okrs/bulk-upload",
  //   layout: AppLayoutV3,
  //   component: OKRBulkUpload,
  // },
  // {
  //   exact: true,
  //   path: "/company/360-feedback",
  //   layout: AppLayoutV3,
  //   component: ThreeSixtyFeedback,
  // },
  {
    exact: true,
    path: "/company/360-feedback/add",
    component: AddThreeSixtyFeedbackCycle,
  },
  {
    exact: true,
    path: "/company/360-feedback/:id/create_from_draft",
    component: CreateThreeSixtyFeedbackCycleFromDraft,
  },
  // {
  //     exact: true,
  //     path: "/company/enable_features",
  //     layout: AppLayoutV3,
  //     component: EnableFeatures,
  //   },
  // {
  //   exact: true,
  //   path: "/company/integrations",
  //   layout: AppLayoutV3,
  //   component: Slack,
  // },
  {
    exact: true,
    path: "/company/:selectedTab",
    layout: AppLayoutV3,
    component: Company,
  },
  {
    exact: true,
    path: "/company/:selectedTab/:selectedSubTab",
    layout: AppLayoutV3,
    component: Company,
  },
];

export default routes;

import React from "react";
import { Button, Layout, Card, Typography } from "antd";
import Icon from "@ant-design/icons";

import logo from "assets/logo.svg";
import { ReactComponent as GoogleImage } from "assets/auth/googleImage.svg";
import microsoftImage from "assets/auth/microsoftImage.png";

import firebaseApp, {
  googleAuthProvider,
  microsoftProvider,
} from "firebaseApp";
import { getAuth, signInWithPopup } from "firebase/auth";
import { Redirect, useLocation } from "react-router-dom";
import { useCurrentUser } from "store";

import styles from "./Auth.module.css";

const { Text, Title, Link } = Typography;

const Auth = () => {
  const [currentUser] = useCurrentUser();
  const location = useLocation();
  const auth = getAuth(firebaseApp);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithMicrosoft = async () => {
    try {
      await signInWithPopup(auth, microsoftProvider);
    } catch (error) {
      console.log(error);
    }
  };

  if (currentUser) {
    const search = location?.search || "";
    if (search?.slice(0, 11) === "?from_page=") {
      const toPage = search?.slice(12) || "/";
      return <Redirect to={toPage} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <Layout className={styles.fullHeight}>
      <Card className={styles.loginCard}>
        <div className={styles.loginDiv}>
          <Link href="https://culture.easy.xyz/">
            <img className="block-display" src={logo} alt="logo" />
          </Link>

          <Title className="mt-16" level={3}>
            Please authenticate to continue
          </Title>

          <Button
            className="mt-32 mb-24 font-weight-300 width-300-px"
            onClick={signInWithGoogle}
          >
            <Icon component={GoogleImage} />
            Continue with Google
          </Button>

          <Button
            className="mt-8 mb-24 font-weight-300 width-300-px"
            onClick={signInWithMicrosoft}
          >
            <img
              className={`mr-8 mt--3 ${styles.msftLogo}`}
              src={microsoftImage}
              alt="msft"
            />
            Continue with Microsoft
          </Button>

          <Text className={styles.footerText} type="secondary">
            By clicking Continue with Google, I accept the easy.xyz{" "}
            <span className="text-decoration-underline">Terms Of Service</span>{" "}
            and have read the{" "}
            <span className="text-decoration-underline">Privacy Policy</span>
          </Text>
        </div>
      </Card>
    </Layout>
  );
};

export default Auth;

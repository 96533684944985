import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Alert, Row, Typography, Button } from "antd";

const { Text } = Typography;

const SettingsFeatureAlert = ({
  text = "",
  settingsButtonText = "Go to Settings",
  settingsLink = "/settings/enable_features",
}) => {
  const history = useHistory();

  const handleGoToSettings = () => {
    history.push(settingsLink);
  };

  return (
    <Alert
      type="info"
      className="mb-16"
      message={
        <Row
          className="width-100-percent"
          justify="space-between"
          align="middle"
        >
          <Text>{text}</Text>
          <Button type="primary" size="small" onClick={handleGoToSettings}>
            {settingsButtonText}
          </Button>
        </Row>
      }
    />
  );
};

SettingsFeatureAlert.propTypes = {
  featureName: PropTypes.string,
};

export default SettingsFeatureAlert;

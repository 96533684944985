import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Row, Col, Button, Input } from "antd";

import MultiLineText from "./MultiLineText";
import styles from "./HprSurveyFormItemQuestion.module.css";

const { Text } = Typography;

const HprSurveyFormItemQuestion = ({
  question = {},
  mode = "answer",
  answerValue = 0,
  commentValue = "",
  updateOption = () => {},
  updateComment = () => {},
}) => {
  const [showComment, setShowComment] = useState(false);

  const handleSelectOption = (questionId, option) => {
    updateOption(questionId, option);
  };

  const handleChangeComment = (questionId, text) => {
    updateComment(questionId, text);
  };

  const toggleShowComment = (questionId) => {
    if (showComment) {
      if (commentValue) {
        updateComment(questionId, "");
      }
    } else {
      updateComment(questionId, commentValue);
    }
    setShowComment(!showComment);
  };

  const isReadOnly = mode === "answer" ? false : true;

  if (commentValue && !showComment) setShowComment(true);

  return (
    <div className="mt-48">
      <Row justify="space-between">
        <span>
          <Text
            className={clsx("mr-8", "font-weight-500")}
          >{`${question?.order}. ${question?.description}`}</Text>
          <Text type="secondary">
            {question?.is_required ? "" : "(Optional)"}
          </Text>
        </span>
      </Row>
      <Row className={clsx("mb-8", "mt-8")} justify="center">
        <Row justify="center" gutter={[30]}>
          {Object.keys(question.options)?.map((option) => (
            <Col key={option} className={styles.optionColumn}>
              <Button
                shape="circle"
                size="large"
                type={answerValue === parseInt(option) ? "primary" : "default"}
                className={
                  isReadOnly &&
                  answerValue !== parseInt(option) &&
                  "read-only-btn"
                }
                style={{
                  width: "70px",
                  height: "70px",
                  border: "1px solid #A3A3A3",
                  backgroundColor:
                    answerValue === parseInt(option)
                      ? isReadOnly
                        ? "#5330c977"
                        : "#5330c9"
                      : "white",
                  color: answerValue === parseInt(option) ? "white" : "#A3A3A3",
                }}
                disabled={isReadOnly}
                onClick={() => {
                  if (!isReadOnly) {
                    handleSelectOption(question?.id, option);
                  }
                }}
              >
                {option}
              </Button>
              <Text className={styles.optionText}>
                {question.options[option]}
              </Text>
            </Col>
          ))}
        </Row>
      </Row>
      {question?.allow_comments && !isReadOnly && (
        <Row justify="center" className={clsx("mb-8", "mt-8")}>
          <Col>
            <Button
              type="text"
              style={{ color: "#1755B7" }}
              onClick={(e) => toggleShowComment(question?.id)}
            >
              {showComment ? "Clear Comment" : "Add Comment"}
            </Button>
          </Col>
        </Row>
      )}
      {isReadOnly && showComment && (
        <div className="px-16">
          <br />
          <Text type="secondary">Your Comments:</Text>
          <MultiLineText
            startInSameLine={true}
            text={commentValue}
            italicize={true}
          />
        </div>
      )}
      {!isReadOnly && question?.allow_comments && showComment && (
        <Input.TextArea
          className={clsx("mt-8", "input-textarea-resize-none")}
          placeholder="Your Comment (Optional)"
          autoSize={{ minRows: 3, maxRows: 3 }}
          value={commentValue}
          onChange={(e) => handleChangeComment(question?.id, e.target.value)}
        />
      )}
    </div>
  );
};

HprSurveyFormItemQuestion.propTypes = {
  mode: PropTypes.string,
  answerValue: PropTypes.number,
  commentValue: PropTypes.string,
  question: PropTypes.object,
  updateOption: PropTypes.func,
};

export default HprSurveyFormItemQuestion;

import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd";

import {
  MacroHighlightedText,
  PerfReviewMCQAnswer,
  PerfReviewEditTextAnswer,
} from "components";
import { getNameFromEmployeeObj } from "app/appUtils";

import styles from "app/reviews/reviews.module.css";

const ViewAnswers = ({
  reviewData = {},
  revieweeAnswers = {},
  reviewerAnswers = {},
  isFromHrAdmin = false,
  shouldEditRevieweeAnswer = false,
  disabled = true,
}) => {
  const type =
    Boolean(reviewData?.my_role) && !isFromHrAdmin
      ? reviewData?.my_role
      : "indirect_manager";

  const peersAnswers =
    reviewData?.reviewee?.peers?.map((peer) =>
      peer?.answers ? peer : { ...peer, answers: {} }
    ) || [];

  const isManagerPresent =
    reviewData?.reviewer &&
    typeof reviewData?.reviewer === "object" &&
    Object.keys(reviewData?.reviewer)?.length > 0;

  const ratingOptionsHash = {};
  if (
    reviewData?.perf_review_cycle?.rating_options &&
    reviewData?.perf_review_cycle?.rating_options?.length > 0
  ) {
    reviewData.perf_review_cycle.rating_options.forEach((option) => {
      ratingOptionsHash[option?.id] = option?.value;
    });
  }

  return (
    <>
      {reviewData?.questions?.map((question, index) => {
        const questionOptionHash = {};

        if (question?.type === "PerfReviewMcq" && question?.options) {
          question.options.forEach((option) => {
            questionOptionHash[option?.id] = option?.value;
          });
        }
        return (
          <Row className={styles.width100Percent}>
            <div className="width-100-percent">
              <MacroHighlightedText
                className={`${styles.width100Percent} font-size-20`}
                firstName={reviewData?.reviewee?.first_name || "-"}
                lastName={reviewData?.reviewee?.last_name || "-"}
                highlightClassName="transparent-macro-question-highlight "
                text={`${index + 1}. ${
                  type === "reviewee" || !question?.is_required
                    ? question?.reviewee_text
                    : question?.reviewer_text
                }`}
              />
            </div>

            <div className="width-100-percent">
              {question.type === "PerfReviewText" && (
                <div className="mt-8 mb-30">
                  {question?.is_required && (
                    <div className="mb-16">
                      {peersAnswers?.map((peer) => {
                        const peerAnswers = peer?.answers || {};
                        const isNamePresent = peer?.name;
                        return (
                          <>
                            {peerAnswers[question.id] ? (
                              <>
                                <PerfReviewEditTextAnswer
                                  reviewId={reviewData?.id}
                                  assessmentId={peer?.id}
                                  isFromHrAdmin={isFromHrAdmin}
                                  question={question}
                                  shouldShowEdit={type !== "reviewee"}
                                  employee={
                                    isNamePresent ? peer : { name: "Anonymous" }
                                  }
                                  employeeDisplayName={
                                    isNamePresent
                                      ? getNameFromEmployeeObj(peer)
                                      : "Anonymous"
                                  }
                                  employeeRelation={" (Peer)"}
                                  answer={peerAnswers[question.id]}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </div>
                  )}

                  <PerfReviewEditTextAnswer
                    question={question}
                    isFromHrAdmin={isFromHrAdmin}
                    shouldShowEdit={shouldEditRevieweeAnswer}
                    reviewId={reviewData?.id}
                    assessmentId={reviewData?.reviewee?.assessment_id}
                    employee={reviewData?.reviewee}
                    employeeDisplayName={getNameFromEmployeeObj(
                      reviewData?.reviewee
                    )}
                    employeeRelation={
                      type === "reviewee" ? " (Self)" : " (Report)"
                    }
                    answer={revieweeAnswers[question.id]}
                  />

                  {question?.is_required && reviewerAnswers[question.id] && (
                    <PerfReviewEditTextAnswer
                      question={question}
                      isFromHrAdmin={isFromHrAdmin}
                      shouldShowEdit={isFromHrAdmin}
                      reviewId={reviewData?.id}
                      assessmentId={reviewData?.reviewer?.assessment_id}
                      employee={reviewData?.reviewer}
                      employeeDisplayName={
                        isManagerPresent
                          ? `${getNameFromEmployeeObj(reviewData?.reviewer)}`
                          : "No Manager"
                      }
                      employeeRelation={isManagerPresent ? " (Manager)" : ""}
                      answer={reviewerAnswers[question.id]}
                    />
                  )}
                </div>
              )}
            </div>

            {question.type === "PerfReviewMcq" && (
              <div className={styles.width100Percent}>
                <PerfReviewMCQAnswer
                  reviewId={reviewData?.id}
                  isFromHrAdmin={isFromHrAdmin}
                  // assessmentId={reviewData?.reviewer?.assessment_id}
                  type={type === "reviewee" ? "reviewee_text" : "reviewer_text"}
                  shouldShowEdit={isFromHrAdmin}
                  shouldEditRevieweeAnswer={shouldEditRevieweeAnswer}
                  disabled={disabled}
                  isPeer={false}
                  reviewData={reviewData}
                  question={question}
                  questionOptionHash={questionOptionHash}
                  revieweeAnswers={revieweeAnswers}
                  reviewerAnswers={reviewerAnswers}
                  peersAnswers={peersAnswers}
                />
              </div>
            )}
          </Row>
        );
      })}

      {reviewData?.perf_review_cycle?.rating_enabled && (
        <div className="mt-24">
          <MacroHighlightedText
            className="font-size-24"
            firstName={reviewData?.reviewee?.first_name || "-"}
            lastName={reviewData?.reviewee?.last_name || "-"}
            highlightClassName="transparent-macro-question-highlight"
            text={`${reviewData?.questions?.length + 1}. ${
              type === "reviewee"
                ? "How do you rate yourself?"
                : "How do you rate %FIRST_NAME%?"
            }`}
          />

          <PerfReviewMCQAnswer
            reviewId={reviewData?.id}
            isFromHrAdmin={isFromHrAdmin}
            // assessmentId={reviewData?.reviewer?.assessment_id}
            type={type === "reviewee" ? "reviewee_text" : "reviewer_text"}
            shouldShowEdit={isFromHrAdmin}
            shouldEditRevieweeAnswer={shouldEditRevieweeAnswer}
            disabled={disabled}
            isPeer={false}
            isRating={true}
            reviewData={reviewData}
            questionOptionHash={ratingOptionsHash}
            revieweeAnswers={revieweeAnswers}
            reviewerAnswers={reviewerAnswers}
            peersAnswers={peersAnswers}
          />
        </div>
      )}
    </>
  );
};
ViewAnswers.propTypes = {
  reviewData: PropTypes.object,
  revieweeAnswers: PropTypes.object,
  reviewerAnswers: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ViewAnswers;

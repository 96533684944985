import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import moment from "moment";
import { Typography, Timeline, Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { BoxLoader, EmployeeAvatarWithName } from "components";
import { useEmployees } from "hooks";
import { formattedDateTime } from "app/appUtils";
import { statusColors, statusText } from "../../constants";
import { getItemActivityDataApi } from "apis/okrV2Api";

import styles from "../../../OKRs.module.css";

const { Text } = Typography;

const getTaggedKeyResultChange = (change) => {
  const start = change?.changes?.tagged_key_result_id[0];
  const target = change?.changes?.tagged_key_result_id[1];

  const details = change?.key_result_details || {};

  if (!start && !target) {
    return (
      <Text className="mt-8 block-display">
        Something unexpected happened to retrieve this activity details
      </Text>
    );
  }

  if (!start && target) {
    return (
      <Text className="mt-8 block-display">
        Initiative was tagged to
        <Text strong className="ml-8">
          {details[target]}
        </Text>
      </Text>
    );
  }

  if (!target && start) {
    return (
      <Text className="mt-8 block-display">
        Initiative was removed as a tag from
        <Text strong className="ml-8">
          {details[start]}
        </Text>
      </Text>
    );
  }

  return (
    <Text className="mt-8 block-display">
      Tagged key result was changed:
      <Text strong className="ml-8">
        {details[start]}
      </Text>
      <ArrowRightOutlined className="mx-8" />
      <Text strong className="ml-8">
        {details[target]}
      </Text>
    </Text>
  );
};

const getActivityDataSource = (data = {}, isKeyResult, empHash = {}) => {
  const { checkins = [], comments = [], change_logs: changeLogs = [] } = data;

  const dataSource = [];

  comments.forEach((comment) => {
    if (!comment?.deleted) {
      dataSource.push({
        created_at: comment?.created_at,
        title: (
          <Text>
            <EmployeeAvatarWithName
              emptyText="An employee"
              className="mr-8"
              employee={comment}
            />
            made a comment at
          </Text>
        ),
        activity: (
          <span>
            <Text className="mt-8">{comment?.content}</Text>
            {comment?.created_at !== comment?.updated_at && !comment?.deleted && (
              <Text type="secondary" className="ml-8 mt-8 font-size-14">
                (edited)
              </Text>
            )}
          </span>
        ),
      });
    }
  });

  for (let i = checkins?.length - 1; i >= 0; i--) {
    if (i !== 0) {
      dataSource.push({
        created_at: checkins[i]?.created_at,
        title: (
          <>
            {checkins[i]?.updated_by ? (
              <Text>
                <EmployeeAvatarWithName
                  className="mr-8"
                  emptyText="An employee"
                  employee={checkins[i]?.updated_by}
                />
                made a checkin at
              </Text>
            ) : (
              <Text>A checkin was made at</Text>
            )}
          </>
        ),
        activity: (
          <span>
            <Text className="mt-8">
              {`Progress: ${checkins[i - 1]?.progress}% `}
            </Text>
            {isKeyResult && (
              <Text
                className="mt-8"
                style={{ color: statusColors[checkins[i - 1]?.status] }}
              >
                {`${statusText[checkins[i - 1]?.status]} `}
              </Text>
            )}
            <ArrowRightOutlined className="mx-8" />
            <Text className="mt-8">
              {`
              ${checkins[i]?.progress}% `}
            </Text>
            {isKeyResult && (
              <Text
                className="mt-8"
                style={{ color: statusColors[checkins[i]?.status] }}
              >
                {`${statusText[checkins[i]?.status]}`}
              </Text>
            )}
          </span>
        ),
      });
    } else {
      dataSource.push({
        created_at: checkins[i]?.created_at,
        title: (
          <>
            {checkins[i]?.updated_by ? (
              <Text>
                <EmployeeAvatarWithName
                  className="mr-8"
                  emptyText="An employee"
                  employee={checkins[i]?.updated_by}
                />
                made a checkin at
              </Text>
            ) : (
              <Text>A checkin was made at</Text>
            )}
          </>
        ),
        activity: (
          <span>
            <Text className="mt-8">{`Progress: 0% `}</Text>
            {isKeyResult && (
              <Text
                className="mt-8"
                style={{ color: statusColors["not_started"] }}
              >
                {statusText["not_started"]}
              </Text>
            )}
            <ArrowRightOutlined className="mx-8" />
            <Text className="mt-8">
              {`
                  ${checkins[i]?.progress}% `}
            </Text>
            {isKeyResult && (
              <Text
                className="mt-8"
                style={{ color: statusColors[checkins[i]?.status] }}
              >
                {`${statusText[checkins[i]?.status]}`}
              </Text>
            )}
          </span>
        ),
      });
    }
  }

  changeLogs.forEach((change) => {
    dataSource.push({
      created_at: change?.created_at,
      title: (
        <Text>
          <EmployeeAvatarWithName
            className="mr-8"
            employee={change?.updated_by}
            emptyText="An employee"
          />
          made the following changes at
        </Text>
      ),
      activity: (
        <>
          {change?.changes?.title && (
            <Text className="mt-8 block-display">
              {`Title: ${change?.changes?.title[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {`${change?.changes?.title[1]}`}
            </Text>
          )}

          {change?.changes?.owner_id && (
            <span className="block-display">
              <Text className="mt-8">{`Owner: `}</Text>
              <EmployeeAvatarWithName
                employee={empHash[change?.changes?.owner_id[0]]}
              />
              <ArrowRightOutlined className="mx-8" />

              <EmployeeAvatarWithName
                employee={empHash[change?.changes?.owner_id[1]]}
              />
            </span>
          )}

          {change?.changes?.kpi_metric && (
            <Text className="mt-8 block-display">
              {`KPI Metric: ${change?.changes?.kpi_metric[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {`
            ${change?.changes?.kpi_metric[1]}`}
            </Text>
          )}

          {change?.changes?.start && (
            <Text className="mt-8 block-display">
              {`Start: ${change?.changes?.start[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {`
            ${change?.changes?.start[1]}`}
            </Text>
          )}

          {change?.changes?.target && (
            <Text className="mt-8 block-display">
              {`Target: ${change?.changes?.target[0]}`}
              <ArrowRightOutlined className="mx-8" />
              {`
            ${change?.changes?.target[1]}`}
            </Text>
          )}

          {change?.changes?.tagged_key_result_id &&
            getTaggedKeyResultChange(change)}

          {change?.changes?.postponed && (
            <Text className="mt-8 block-display">
              {`This key result was ${
                change?.changes?.postponed[1] ? "postponed" : "un-postponed"
              }`}
            </Text>
          )}
        </>
      ),
    });
  });

  dataSource.sort((a, b) => {
    const aCreatedAt = a?.created_at;
    const bCreatedAt = b?.created_at;

    if (
      moment(aCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").isAfter(
        moment(bCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return -1;
    }
    if (
      moment(aCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").isBefore(
        moment(bCreatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    ) {
      return 1;
    }
    return 0;
  });

  return dataSource;
};

const ActivityModal = ({
  isKeyResult = true,
  objectiveId = "",
  keyResultId = "",
  initiativeId = "",
}) => {
  const { data, status, isLoading, isFetching } = useQuery(
    [
      "getItemActivityData",
      objectiveId,
      isKeyResult ? keyResultId : initiativeId,
      isKeyResult,
    ],
    getItemActivityDataApi
  );

  const {
    allEmployeesHash,
    isEmployeesLoading,
    isEmployeesFetching,
  } = useEmployees();

  const loader =
    status === "loading" ||
    isLoading ||
    isFetching ||
    isEmployeesLoading ||
    isEmployeesFetching;

  const dataSource = getActivityDataSource(
    data || {},
    isKeyResult,
    allEmployeesHash || {}
  );

  return (
    <div>
      {loader ? (
        <BoxLoader />
      ) : (
        <div className={styles.activityCommentsTabPane}>
          <div className="my-8" />
          <Timeline mode="left">
            {dataSource?.map((item) => (
              <Timeline.Item>
                <>
                  <div className="mb-8">
                    <Text>{item?.title || ""}</Text>
                    <Text type="secondary" className="ml-8">
                      {formattedDateTime(item?.created_at)}
                    </Text>
                  </div>
                  {item?.activity || ""}
                </>
              </Timeline.Item>
            ))}
          </Timeline>
          {dataSource?.length === 0 && (
            <Empty description="No Activity to show"></Empty>
          )}
        </div>
      )}
    </div>
  );
};

ActivityModal.propTypes = {
  objectiveId: PropTypes.number,
  keyResultId: PropTypes.number,
  keyResultComments: PropTypes.array,
  handleUpdateComments: PropTypes.func,
};

export default ActivityModal;

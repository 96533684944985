import React from "react";
import { Redirect } from "react-router-dom";
// import Settings from "./Settings";
import CompanySettings from "./company-settings/CompanySettings";
import Directory from "./directory/Directory";
import Teams from "./directory/teams/Teams";
// import EnableFeatures from "../company/enable_features/EnableFeatures";
import AppLayoutV3 from "components/layouts/AppLayoutV3";
// import FullScreenLayout from "components/layouts/FullScreenLayout";
import AddEmployee from "./directory/add-employee/AddEmployee";
import PraiseBadges from "./praise-badges/PraiseBadges";
// import Slack from "../company/integrations/Slack";
// import Plans from "./billing/Plans";

const routes = [
  {
    exact: true,
    path: "/settings",
    layout: AppLayoutV3,
    component: () => <Redirect to="/settings/directory" />,
  },
  {
    exact: true,
    path: "/settings/company-settings",
    layout: AppLayoutV3,
    component: () => <Redirect to="/settings/company-settings/details" />,
  },
  {
    exact: true,
    path: "/settings/company-settings/:selectedTab",
    layout: AppLayoutV3,
    component: CompanySettings,
  },
  {
    exact: true,
    path: "/settings/directory",
    layout: AppLayoutV3,
    component: Directory,
  },
  {
    exact: true,
    path: "/settings/directory/teams",
    layout: AppLayoutV3,
    component: Teams,
  },
  {
    exact: true,
    path: "/settings/enable_features",
    layout: AppLayoutV3,
    component: () => <Redirect to="/company/overview" />,
  },
  {
    exact: true,
    path: "/settings/directory/new-employee",
    component: AddEmployee,
    layout: AppLayoutV3,
    // layout: () => (
    //   <FullScreenLayout
    //     contentGapSpan={3}
    //     contentLgSpan={18}
    //     source={{ path: "/settings/directory", name: "Directory" }}
    //     title="Enter details of a new employee."
    //   >
    //     <AddEmployee />
    //   </FullScreenLayout>
    // ),
  },
  {
    exact: true,
    path: "/settings/praise-badges",
    layout: AppLayoutV3,
    component: PraiseBadges,
  },
  {
    exact: true,
    path: "/settings/integrations/",
    layout: AppLayoutV3,
    component: () => <Redirect to="/company/integrations" />,
  },
  {
    exact: true,
    path: "/settings/integrations/slack",
    layout: AppLayoutV3,
    component: () => <Redirect to="/company/integrations" />,
  },
  {
    exact: true,
    path: "/settings/billing/",
    layout: AppLayoutV3,
    component: () => <Redirect to="/settings/company-settings/billing" />,
  },
  {
    exact: true,
    path: "/settings/billing/plans",
    layout: AppLayoutV3,
    component: () => <Redirect to="/settings/company-settings/billing" />,
  },
];

export default routes;

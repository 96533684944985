import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useQuery } from "react-query";

import { OKRsActivity } from "components";
import { getObjectivePeriodsApi } from "apis/okrV2Api";
import { errorNotification } from "app/appUtils";
import { getNameFromEmployeeObj } from "app/appUtils";

const EmployeeOKRActivity = ({
  defaultOKRPeriodId = null,
  employee = {},
  shouldShowCurrentPeriod = false,
}) => {
  const {
    data: periods,
    status: periodStatus,
    isLoading: isPeriodLoading,
    // isFetching: isPeriodFetching,
  } = useQuery("getAllObjectivePeriods", getObjectivePeriodsApi, {
    onError: (error) => {
      errorNotification(error);
    },
  });

  const okrPeriodLoading = periodStatus === "loading" || isPeriodLoading;

  return (
    <OKRsActivity
      defaultOKRPeriodId={defaultOKRPeriodId}
      employeeId={employee?.id}
      okrPeriodLoading={okrPeriodLoading}
      periods={periods}
      shouldShowCurrentPeriod={shouldShowCurrentPeriod}
      // panelClassName={"perf-review-okr-summary-panel-big"}
      isSmallContainer={true}
      toDate={moment().format("YYYY-MM-DD")}
      title={`${getNameFromEmployeeObj(employee)}'s OKRs`}
    />
  );
};

EmployeeOKRActivity.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EmployeeOKRActivity;

import React from "react";
// import PropTypes from 'prop-types';
import { useQuery } from "react-query";
import { Drawer, Row, Empty } from "antd";

import { BoxLoader } from "components";
import KPICard from "app/okrs/kpis/KPICard";
import { getEmployeeKPIsApi } from "apis/kpis";
import { errorNotification } from "app/appUtils";

const SeeKPIsDrawer = ({ employee = {}, onClose = () => {} }) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ["getEmployeeKPIs", employee?.id],
    getEmployeeKPIsApi,
    {
      onSuccess: () => {},
      onError: (error) => {
        errorNotification(error);
      },
    }
  );

  const loader = isLoading || isFetching;

  return (
    <Drawer
      title="See KPIs"
      width="60%"
      visible={Boolean(employee)}
      onClose={onClose}
    >
      {loader ? (
        <BoxLoader />
      ) : (
        <Row>
          {data?.length === 0 && <Empty description="No KPIs" />}
          {data?.map((kpi) => (
            <KPICard kpi={kpi} refetch={refetch} />
          ))}
        </Row>
      )}
    </Drawer>
  );
};

SeeKPIsDrawer.propTypes = {};

export default SeeKPIsDrawer;

import React from "react";
import PropTypes from "prop-types";
import { Input, Card, Typography } from "antd";

import {
  EmployeeAvatar,
  MacroHighlightedText,
  MultiLineText,
  PerfReviewViewMCQSelectionsList,
  PerfReviewEditTextAnswer,
  PerfReviewMCQAnswer,
} from "components";
import { getNameFromEmployeeObj } from "app/appUtils";
import styles from "app/reviews/reviews.module.css";

const { Text } = Typography;

const WriteAnswersV2 = ({
  isPeer = false,
  reviewData = {},
  selectedPeerAssessment = {},
  answers = {},
  ratingOptionId = null,
  type = "",
  disabled = true,
  shouldEditRevieweeAnswer = false,
  handleMcqAnswerChange = () => {},
  handleRatingChange = () => {},
  handleTextAnswerChange = () => {},
  ...props
}) => {
  const allQuestions = reviewData?.questions || [];
  const filteredQuestions =
    type === "reviewee_text"
      ? allQuestions
      : allQuestions?.filter((question) => question?.is_required) || [];

  const peersAnswers =
    reviewData?.reviewee?.peers?.filter(
      (peer) =>
        Boolean(peer?.answers) &&
        typeof peer?.answers === "object" &&
        Object.keys(peer?.answers)?.length > 0
    ) || [];

  const revieweeAnswers = reviewData?.reviewee?.answers || {};

  const ratingOptionsHash = {};
  if (
    reviewData?.perf_review_cycle?.rating_options &&
    reviewData?.perf_review_cycle?.rating_options?.length > 0
  ) {
    reviewData.perf_review_cycle.rating_options.forEach((option) => {
      ratingOptionsHash[option?.id] = option?.value;
    });
  }

  return (
    <div>
      {filteredQuestions?.map((question, index) => {
        const questionOptionHash = {};

        if (question?.type === "PerfReviewMcq" && question?.options) {
          question.options.forEach((option) => {
            questionOptionHash[option?.id] = option?.value;
          });
        }

        return (
          <div className="mb-30">
            <MacroHighlightedText
              className="font-size-24"
              firstName={
                isPeer
                  ? selectedPeerAssessment?.first_name || "-"
                  : reviewData?.reviewee?.first_name || "-"
              }
              lastName={
                isPeer
                  ? selectedPeerAssessment?.last_name || "-"
                  : reviewData?.reviewee?.last_name || "-"
              }
              highlightClassName="transparent-macro-question-highlight"
              text={`${index + 1}. ${
                question[isPeer ? "reviewer_text" : type]
              }`}
            />

            {!isPeer &&
              peersAnswers?.map((peer) => {
                const peerAnswers = peer?.answers || {};
                const isNamePresent = peer?.name;
                return (
                  <>
                    {question.type === "PerfReviewText" && (
                      <PerfReviewEditTextAnswer
                        reviewId={reviewData?.id}
                        assessmentId={peer?.id}
                        question={question}
                        shouldShowEdit={true}
                        employee={isNamePresent ? peer : { name: "Anonymous" }}
                        employeeDisplayName={
                          isNamePresent
                            ? getNameFromEmployeeObj(peer)
                            : "Anonymous"
                        }
                        employeeRelation={" (Peer)"}
                        answer={peerAnswers[question.id]}
                      />
                    )}
                  </>
                );
              })}

            {type === "reviewer_text" && (
              <>
                {question.type === "PerfReviewText" && (
                  <PerfReviewEditTextAnswer
                    reviewId={reviewData?.id}
                    assessmentId={reviewData?.reviewee?.assessment_id}
                    question={question}
                    shouldShowEdit={shouldEditRevieweeAnswer}
                    employee={reviewData?.reviewee}
                    employeeDisplayName={getNameFromEmployeeObj(
                      reviewData?.reviewee
                    )}
                    employeeRelation={" (Report)"}
                    answer={revieweeAnswers[question.id]}
                  />
                )}
              </>
            )}

            {question.type === "PerfReviewText" && (
              <>
                <div className="flex-display">
                  <EmployeeAvatar
                    className="mr-8 min-width-32-px"
                    emptyText="No Reviewer"
                    employee={
                      type === "reviewee_text" || isPeer
                        ? reviewData?.reviewee
                        : reviewData?.reviewer
                    }
                  />
                  <div className="width-100-percent">
                    {disabled ? (
                      answers[question.id] ? (
                        <MultiLineText text={answers[question.id] || ""} />
                      ) : (
                        <Text type="secondary" className="italic-font">
                          No answer yet
                        </Text>
                      )
                    ) : (
                      <Input.TextArea
                        className={styles.reviewAnswerTextArea}
                        autoSize={{ minRows: 4, maxRows: 10 }}
                        disabled={disabled}
                        value={answers[question.id] || ""}
                        onChange={(e) => handleTextAnswerChange(e, question.id)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {question.type === "PerfReviewMcq" && (
              <>
                {disabled ? (
                  <PerfReviewViewMCQSelectionsList
                    question={question}
                    revieweeAnswers={
                      type === "reviewee_text" || isPeer ? answers || {} : {}
                    }
                    reviewerAnswers={
                      type !== "reviewee_text" && !isPeer ? answers || {} : {}
                    }
                    peersAnswers={isPeer ? [] : peersAnswers}
                    reviewData={reviewData}
                  />
                ) : (
                  <PerfReviewMCQAnswer
                    reviewId={reviewData?.id}
                    type={type}
                    isFromEdit={true}
                    shouldShowEdit={true}
                    disabled={disabled}
                    isPeer={isPeer}
                    revieweeAnswers={revieweeAnswers}
                    reviewData={reviewData}
                    question={question}
                    questionOptionHash={questionOptionHash}
                    value={answers[question.id] || null}
                    onChange={(e) => handleMcqAnswerChange(e, question.id)}
                    peersAnswers={peersAnswers}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
      {reviewData?.perf_review_cycle?.rating_enabled && !isPeer && (
        <>
          <MacroHighlightedText
            className="font-size-24"
            firstName={reviewData?.reviewee?.first_name || "-"}
            lastName={reviewData?.reviewee?.last_name || "-"}
            highlightClassName="transparent-macro-question-highlight"
            text={`${filteredQuestions?.length + 1}. ${
              type === "reviewee_text"
                ? "How do you rate yourself?"
                : "How do you rate %FIRST_NAME%?"
            }`}
          />

          {disabled ? (
            <PerfReviewViewMCQSelectionsList
              isRating={true}
              revieweeAnswers={
                type === "reviewee_text" || isPeer ? answers || {} : {}
              }
              reviewerAnswers={
                type !== "reviewee_text" && !isPeer ? answers || {} : {}
              }
              peersAnswers={isPeer ? [] : peersAnswers}
              reviewData={reviewData}
            />
          ) : (
            <PerfReviewMCQAnswer
              reviewId={reviewData?.id}
              type={type}
              isFromEdit={true}
              shouldShowEdit={true}
              disabled={disabled}
              isPeer={isPeer}
              revieweeAnswers={revieweeAnswers}
              reviewData={reviewData}
              isRating={true}
              questionOptionHash={ratingOptionsHash}
              value={ratingOptionId || null}
              onChange={handleRatingChange}
              peersAnswers={peersAnswers}
            />
          )}
        </>
      )}
    </div>
  );
};

WriteAnswersV2.propTypes = {
  reviewData: PropTypes.object,
  answers: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  handleMcqAnswerChange: PropTypes.func,
  handleTextAnswerChange: PropTypes.func,
};

const WriteAnswersV2Container = ({
  reviewData = {},
  answers = {},
  ratingOptionId = null,
  type = "",
  disabled = true,
  shouldShowCard = false,
  handleMcqAnswerChange = () => {},
  handleTextAnswerChange = () => {},
  handleRatingChange = () => {},
  ...props
}) => {
  if (shouldShowCard) {
    return (
      <Card>
        <WriteAnswersV2
          reviewData={reviewData}
          answers={answers}
          ratingOptionId={ratingOptionId}
          type={type}
          disabled={disabled}
          handleMcqAnswerChange={handleMcqAnswerChange}
          handleTextAnswerChange={handleTextAnswerChange}
          handleRatingChange={handleRatingChange}
          {...props}
        />
      </Card>
    );
  }

  return (
    <WriteAnswersV2
      reviewData={reviewData}
      answers={answers}
      ratingOptionId={ratingOptionId}
      type={type}
      disabled={disabled}
      handleMcqAnswerChange={handleMcqAnswerChange}
      handleTextAnswerChange={handleTextAnswerChange}
      handleRatingChange={handleRatingChange}
      {...props}
    />
  );
};

WriteAnswersV2Container.propTypes = {
  reviewData: PropTypes.object,
  answers: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  handleMcqAnswerChange: PropTypes.func,
  handleTextAnswerChange: PropTypes.func,
};

export default WriteAnswersV2Container;

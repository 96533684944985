import axios from "axios";

const getUsersApi = async () => {
  const { data, status } = await axios.get("/api/v2/admin/employees");
  return { data, status };
};

const getSurveyDetailsApi = async (_, id) => {
  const { data } = await axios.get(`/api/v2/admin/surveys/${id}`);
  return data;
};

const getSurveysApi = async () => {
  const { data } = await axios.get("/api/v2/admin/surveys/");
  return data;
};

const createSurveyApi = async (survey) => {
  const { data } = await axios.post("/api/v2/admin/surveys/", { survey });
  return data;
};

// const updateSurveyApi = async ({ id, survey }) => {
//   const { data } = await axios.put(`/api/v2/surveys/${id}/`, {
//     survey,
//   });
//   return data;
// };

const notifyParticipantsApi = async (id) => {
  const { data } = await axios.put(
    `/api/v2/admin/surveys/${id}/notify_participants`
  );
  return data;
};

const getMySurveysApi = async () => {
  const { data } = await axios.get("/api/v2/my_survey_participations");
  return data;
};

const getSurveyParticipationDetailsApi = async (_, id) => {
  const { data } = await axios.get(`/api/v2/my_survey_participations/${id}`);
  return data;
};

const postSurveyResponseApi = async ({ id, response, draft = false }) => {
  const { data } = await axios.post(`/api/v2/my_survey_participations/${id}`, {
    response,
    draft,
  });
  return data;
};

export {
  getSurveyDetailsApi,
  getSurveysApi,
  createSurveyApi,
  getUsersApi,
  notifyParticipantsApi,
  getMySurveysApi,
  getSurveyParticipationDetailsApi,
  postSurveyResponseApi,
};

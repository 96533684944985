import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Card, Row, Col, Space, Typography, Modal } from "antd";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";

import { InlineSpinner } from "components";
import BadgeForm from "./BadgeForm";
import { deleteBadgeApi } from "apis/praiseApis";
import {
  errorNotification,
  successNotification,
  errorNotificationWithString,
} from "app/appUtils";
import { iconOptionsMap } from "./constants";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";

const { Text, Title } = Typography;
const { confirm } = Modal;

const Badge = ({ badge = {}, refetch = () => {}, handleEdit = () => {} }) => {
  const [deleteBadge, { status }] = useMutation(deleteBadgeApi, {
    onSuccess: () => {
      refetch();
      successNotification("Deleted Badge Successfully");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleDeleteBadge = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: `This will delete this badge (${badge?.title || ""}).`,
      onOk() {
        deleteBadge({ id: badge?.id });
      },
      okText: "Delete",
      onCancel() {},
    });
  };
  return (
    <Card className="my-8">
      <Row>
        <Col xs={{ span: 4 }} lg={{ span: 1 }}>
          <Row className="height-100-percent" justify="center" align="middle">
            {iconOptionsMap[badge?.icon]}
          </Row>
        </Col>
        <Col xs={{ span: 20 }} lg={{ span: 16 }}>
          <Space className="ml-24" direction="vertical">
            <Title level={3}>{badge?.title || ""}</Title>
            <Text>{badge?.description || ""}</Text>
          </Space>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <Row justify="end" align="end" className="height-100-percent">
            <Space>
              {status === "loading" && <InlineSpinner fontSize={24} />}
              <Icon component={Edit} onClick={handleEdit} />
              <Icon
                className="ml-16"
                component={Delete}
                onClick={handleDeleteBadge}
              />
            </Space>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

Badge.propTypes = {
  badge: PropTypes.object,
  refetch: PropTypes.func,
  handleEdit: PropTypes.func,
};

const BadgeListItem = ({
  badge = {},
  canCreateOrEdit = false,
  setIsBeingEdited = () => {},
  refetch = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    if (isEdit) {
      setIsEdit(false);
      setIsBeingEdited(false);
    } else {
      if (canCreateOrEdit) {
        setIsEdit(true);
        setIsBeingEdited(true);
      } else {
        errorNotificationWithString(
          "Cant Edit while creating or editing other badge!"
        );
      }
    }
  };

  return (
    <>
      {isEdit ? (
        <BadgeForm
          mode="edit"
          badge={badge}
          refetch={refetch}
          onCancel={handleEdit}
          onSave={handleEdit}
        />
      ) : (
        <Badge badge={badge} refetch={refetch} handleEdit={handleEdit} />
      )}
    </>
  );
};

BadgeListItem.propTypes = {
  badge: PropTypes.object,
  canCreateOrEdit: PropTypes.bool,
  setIsBeingEdited: PropTypes.bool,
  refetch: PropTypes.func,
};

export default BadgeListItem;

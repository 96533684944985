import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { Modal, Row, Typography } from "antd";

import { PlanCardsRow, BoxLoader } from "components";
import CardDetailsModel from "app/settings/billing/models/CardDetailsModel";
import { successNotification, errorNotification } from "app/appUtils";
import { updatePlanApi } from "apis/billingApi";

const { Title } = Typography;

const TrialExpiredModel = ({ isAdmin = true }) => {
  const [isDetailsModelOpen, setIsDetailsModelOpen] = useState(false);

  const [updatePlan, { isLoading: updateLoading }] = useMutation(
    updatePlanApi,
    {
      onSuccess: () => {
        successNotification("Successfully updated plan.");
        window.location = "/";
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const handlesIsDetailsModelOpen = () => {
    setIsDetailsModelOpen(!isDetailsModelOpen);
  };

  const handleUpdate = (name) => {
    updatePlan({ planName: name });
  };

  return (
    <>
      {isDetailsModelOpen && (
        <CardDetailsModel
          type={"add-card"}
          shouldAskAddress={true}
          shouldAskCurrencyType={false}
          onCancel={() => {
            handlesIsDetailsModelOpen(null);
          }}
          handleUpdate={handleUpdate}
        />
      )}
      <Modal
        width="1000px"
        visible={true}
        title={"Trial Period is Over"}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        {updateLoading && <BoxLoader />}
        <Row justify="center">
          {isAdmin ? (
            <>
              <Title level={3}>
                Your trial period has expired. Please select a plan to use
                culture.easy
              </Title>

              <PlanCardsRow
                className="mt-40 mb-48"
                cardBackGroundColor="#F5F5F5"
                onUpgrade={handlesIsDetailsModelOpen}
                refetch={() => {
                  window.location = "/";
                }}
              />
            </>
          ) : (
            <Title level={3}>
              Your trial period has expired. Please contact your admin to update
              to a plan to access culture.easy
            </Title>
          )}
        </Row>
      </Modal>
    </>
  );
};

TrialExpiredModel.propTypes = {};

export default TrialExpiredModel;

import React from "react";

import PropTypes from "prop-types";
import { Alert, Typography, Button, Row, Col, Tabs, Space, Modal } from "antd";

import { ButtonWithSpinner } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";
import styles from "../Review.module.css";
import { formattedDate } from "app/appUtils";

const { Title, Text, Link } = Typography;
const { TabPane } = Tabs;

const NotificationModal = ({
  from = "index",
  isLoading = false,
  cycle = {},
  visible = false,
  handleCancel = () => {},
  handleSubmit = () => {},
}) => {
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  const getFooterButtons = () => {
    if (from === "form") {
      return [
        <ButtonWithSpinner
          type="primary"
          onClick={() => {
            setButtonWithSpinner("now");
            handleSubmit(true);
          }}
          disabled={isLoading}
          isSpinning={isLoading && buttonWithSpinner === "now"}
        >
          Email Now
        </ButtonWithSpinner>,
        <ButtonWithSpinner
          type="primary"
          disabled={isLoading}
          isSpinning={isLoading && buttonWithSpinner === "later"}
          onClick={() => {
            setButtonWithSpinner("later");
            handleSubmit(false);
          }}
        >
          Email Later
        </ButtonWithSpinner>,
        <Button
          disabled={isLoading}
          type="primary"
          ghost
          onClick={handleCancel}
        >
          Cancel
        </Button>,
      ];
    }
    return [
      <ButtonWithSpinner
        type="primary"
        disabled={isLoading}
        isSpinning={isLoading && buttonWithSpinner === "now"}
        onClick={() => {
          setButtonWithSpinner("now");
          handleSubmit(true);
        }}
      >
        Email Now
      </ButtonWithSpinner>,
      <Button disabled={isLoading} type="primary" ghost onClick={handleCancel}>
        Cancel
      </Button>,
    ];
  };

  return (
    <Modal
      visible={visible}
      title={"Inform Participants by Email?"}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      onOk={handleSubmit}
      footer={getFooterButtons()}
    >
      <Tabs defaultActiveKey="reviewee">
        <TabPane tab="Reviewee Template" key="reviewee">
          <Alert
            className="mb-16"
            type="warning"
            message="This is just a preview with sample data. The names, links and dates will be replaced"
          />
          <div className={styles.notificationBody}>
            <Space direction="vertical">
              <Title level={4}>Subject: Time for a self-assessment</Title>
              <Text>Hi Joe,</Text>
              <Text>
                {`Your performance review has been set up for ${
                  cycle?.name
                }, and you can start working on your self-assessments now.
                Please write and share your self-assessment by ${formattedDate(
                  cycle?.deadline_for_submit
                )}.`}
              </Text>
              <Text>
                You can edit and submit your self-assessment by clicking the
                following link
              </Text>
              <Link>(a link to the newly created performance review)</Link>
            </Space>
          </div>
        </TabPane>
        <TabPane tab="Reviewer Template" key="reviewer">
          <Alert
            className="mb-16"
            type="warning"
            message="This is just a preview with sample data. The names, links and dates will be replaced"
          />
          <div className={styles.notificationBody}>
            <Space direction="vertical">
              <Title level={4}>
                Subject: Heads Up on your team's performance reviews
              </Title>
              <Text>Hi Joe,</Text>
              <Text>{`Performance reviews for the following team members are created for the cycle: ${cycle?.name}`}</Text>
              <Row>
                <Col span={8}>
                  <Space direction="vertical">
                    <Text>Name</Text>
                    <Text>Steve</Text>
                    <Text>Joe</Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Text>Link</Text>
                    <Link>
                      (a link to the newly created performance review)
                    </Link>
                    <Link>
                      (a link to the newly created performance review)
                    </Link>
                  </Space>
                </Col>
              </Row>
              <Text>
                {`Please submit the review by ${formattedDate(
                  cycle?.deadline_for_submit
                )} and sign them by ${formattedDate(cycle?.deadline_for_sign)}`}
              </Text>
            </Space>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

NotificationModal.propTypes = {
  cycle: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default NotificationModal;

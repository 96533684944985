import React from 'react';
import PropTypes from 'prop-types';
import { Form } from "antd";

import HprSurveyFormItemQuestion from "./HprSurveyFormItemQuestion";
import StepFormActionButtons from "../app/hpr-surveys/common/form-components/StepFormActionButtons";


const HprDimensionQuestionForm = ({
    mode= "answer",
    answers={},
    questions=[],
    handleSaveAsDraft=() => {},
    title="",
    handlePreviousClick=() => {},
    isSubmitted = false,
    currentStep = 0,
    lastStep = 0,
    updateOption = () => {},
    updateComment = () => {}
    }) => {
    const [form] = Form.useForm();

    return (
        <Form
            className="mt-24"
            form={form}
            name="surveyBasics"
            layout="vertical">
            <h2 className="mt-24">{title}</h2>
            {questions?.map((question, index) => {
                const answerValue = answers[question?.id]?.answer || 0
                const commentValue = answers[question?.id]?.comment || ""
                return (
                    <HprSurveyFormItemQuestion
                        key={question.id}
                        question={question}
                        mode={mode}
                        answerValue={parseInt(answerValue)}
                        commentValue = {commentValue.toString()}
                        updateOption = {updateOption}
                        updateComment = {updateComment}
                    ></HprSurveyFormItemQuestion>)})}
            <Form.Item>
                <StepFormActionButtons
                    currentForm={form}
                    mode={mode}
                    currentStep={currentStep}
                    lastStep={lastStep}
                    shouldShowSaveAsDraft={!isSubmitted}
                    handlePreviousClick={handlePreviousClick}
                    handleSaveAsDraft={() => {
                        handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 0 });
                    }}
                    />
            </Form.Item>
        </Form>
    );
};

HprDimensionQuestionForm.propTypes = {
    mode: PropTypes.string,
    title: PropTypes.string,
    answers: PropTypes.object,
    questions: PropTypes.array,
    handleSaveAsDraft: PropTypes.func,
    handlePreviousClick: PropTypes.func,
    isSubmitted: PropTypes.bool,
    currentStep: PropTypes.number,
    lastStep: PropTypes.number,
    updateOption: PropTypes.func,
};

export default HprDimensionQuestionForm;
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import {
  Popover,
  Tooltip,
  InputNumber,
  Row,
  Col,
  Typography,
  Button,
  Input,
  Grid,
} from "antd";
import Icon from "@ant-design/icons";
import ReactSlider from "react-slider";

import { ButtonWithSpinner } from "components";
import {
  getValueFromPercentage,
  getPercentageFromValue,
  getExpectedPercentageFor,
  getStatus,
} from "../utils";
import { statusText } from "../constants";
import { ReactComponent as Drag } from "assets/icons/drag.svg";
import { statusColors } from "../constants";
import {
  successNotification,
  errorNotification,
  getIsMobile,
} from "app/appUtils";
import {
  postKeyResultCheckInApi,
  postInitiativeCheckInApi,
} from "apis/okrV2Api";

import styles from "../../OKRs.module.css";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const ProgressStatus = ({ status = "not_started", className }) => {
  const rowClassName = `${className} height-50-percent`;
  switch (status) {
    case "not_started":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.notStartedDot} />
          <Text>Not Started</Text>
        </Row>
      );
    case "at_risk":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.atRiskDot} /> <Text>At Risk</Text>
        </Row>
      );
    case "behind":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.behindDot} />
          <Text>Behind</Text>
        </Row>
      );
    case "on_track":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.onTrackDot} />
          <Text>On Track</Text>
        </Row>
      );
    case "postponed":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.postponedDot} />
          <Text>Postponed</Text>
        </Row>
      );
    case "done":
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.doneDot} />
          <Text>Done</Text>
        </Row>
      );
    default:
      return (
        <Row className={rowClassName} align="middle">
          <span className={styles.notStartedDot} />
          <Text>Not Started</Text>
        </Row>
      );
  }
};

const getSizeOfSlider = (screens) => {
  if (screens?.lg && !screens?.xl) {
    return styles?.midWidthRcProgressSlider;
  }
  const isMobile = getIsMobile(screens);
  if (isMobile) {
    return styles?.miniRcProgressSlider;
  }
  return styles?.normalRcProgressSlider;
};

const ProgressSlider = ({
  objectiveId = "",
  itemId = "",
  isKeyResult = true,
  isFromReviews = false,
  isProgressPopoverVisible = false,
  setIsProgressPopoverVisible = () => {},
  metric = "",
  current = 0,
  currentStatus = "at_risk",
  dueDate = null,
  start = 0,
  target = 100,
  disabled = false,
  selectedPeriodObj = {},
  refetch = () => {},
  detailsRefetch = () => {},
  setProgressValue = () => {},
  refetchAncestorProgress = () => {},
}) => {
  const screens = useBreakpoint();
  const sliderRef = useRef();

  const [
    postKeyResultCheckIn,
    { status: savingStatus, isLoading, isFetching },
  ] = useMutation(postKeyResultCheckInApi, {
    onSuccess: () => {
      successNotification("Saved Checkin");
      setComment("");
      refetch();
      detailsRefetch();
      refetchAncestorProgress();
      setProgressValue(currentValue);
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [
    postInitiativeCheckIn,
    {
      status: initiativeCheckInStatus,
      isLoading: initiativeCheckInIsLoading,
      isFetching: initiativeCheckInISFetching,
    },
  ] = useMutation(postInitiativeCheckInApi, {
    onSuccess: () => {
      successNotification("Saved Checkin");
      setComment("");
      refetch();
      detailsRefetch();
      setProgressValue(currentValue);
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [currentValue, setCurrentValue] = useState(current);
  const [status, setStatus] = useState(currentStatus);
  const [comment, setComment] = useState("");

  const expectedProgress = getExpectedPercentageFor(
    dueDate ? { ...selectedPeriodObj, end_date: dueDate } : selectedPeriodObj
  );

  useEffect(() => {
    setCurrentValue(current);
  }, [current]);

  useEffect(() => {
    setStatus(
      getStatus(
        getPercentageFromValue(currentValue, start, target),
        expectedProgress,
        currentStatus
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, expectedProgress]);

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    if (!isProgressPopoverVisible && !isSaving) {
      setCurrentValue(current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProgressPopoverVisible]);

  const screenSizes = Object.values(screens);
  useEffect(() => {
    if (
      sliderRef &&
      sliderRef?.current &&
      sliderRef?.current?.resize &&
      typeof sliderRef?.current?.resize === "function"
    ) {
      // eslint-disable-next-line no-unused-expressions
      sliderRef?.current?.resize();
    }
  }, [screenSizes]);

  const handleVisible = () => {
    setIsProgressPopoverVisible(!isProgressPopoverVisible);
  };

  const handleSliderChange = (val) => {
    if (!disabled) {
      setCurrentValue(getValueFromPercentage(val, start, target));
      setIsProgressPopoverVisible(true);
    }
  };

  const handleInputChange = (val) => {
    setCurrentValue(val);
  };

  const handleCommentChange = (e) => {
    setComment(e?.target?.value);
  };

  const handleSave = () => {
    if (isKeyResult) {
      postKeyResultCheckIn({
        objectiveId,
        keyResultId: itemId,
        checkin: { progress: currentValue, status, comment },
      });
    } else {
      postInitiativeCheckIn({
        objectiveId,
        initiativeId: itemId,
        checkin: { progress: currentValue, comment },
      });
    }
    handleVisible();
  };

  const handleCancel = () => {
    setCurrentValue(current);
    handleVisible();
  };

  const currentPercentage = getPercentageFromValue(currentValue, start, target);
  // const lastProgress = getValueFromPercentage(current, start, target);
  const isSaving =
    savingStatus === "loading" ||
    isLoading ||
    isFetching ||
    initiativeCheckInStatus === "loading" ||
    initiativeCheckInIsLoading ||
    initiativeCheckInISFetching;

  return (
    <>
      <Popover
        content={
          <>
            <Row gutter={[8]} style={{ width: "300px" }}>
              <Col span={isKeyResult ? 10 : 24}>
                <Text type="secondary" className=" mb-8 block-display">
                  {metric === "%" ? "Progress(%)" : metric}
                </Text>
                <InputNumber
                  disabled={disabled}
                  className="width-100-percent"
                  value={currentValue}
                  onChange={handleInputChange}
                />

                <Row align="middle" justify="start">
                  {/* <Text
                type="secondary"
                className="font-size-14"
              >{`Current: ${current}`}</Text>
              <div className="grey-dot" /> */}
                  <Text
                    type="secondary"
                    className="font-size-14"
                  >{`Target: ${target}`}</Text>
                  {/* <div className="grey-dot" />
              <Text
                type="secondary"
                className="font-size-14"
              >{`Start: ${start}`}</Text> */}
                </Row>
              </Col>
              <Col span={isKeyResult ? 14 : 0}>
                <Text type="secondary" className="mb-8 block-display">
                  Status
                </Text>
                <ProgressStatus
                  className="width-100-percent mb-8"
                  status={status}
                />
              </Col>
            </Row>

            <Row className="my-8">
              <Col span={24}>
                <Text type="secondary" className="mb-8 block-display">
                  Comment (Optional)
                </Text>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  className="input-textarea-resize-none"
                  placeholder="Add an optional comment"
                  value={comment}
                  onChange={handleCommentChange}
                />
              </Col>
            </Row>

            <Row justify="end" gutter={[8]}>
              <Col>
                <Button size="small" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <ButtonWithSpinner
                  size="small"
                  isSpinning={isSaving}
                  spinnerColor={"white"}
                  disabled={disabled || isSaving}
                  className="mr-8"
                  type="primary"
                  variant="contained"
                  onClick={handleSave}
                >
                  Check-in
                </ButtonWithSpinner>
              </Col>
            </Row>
          </>
        }
        title={null}
        trigger="click"
        visible={isProgressPopoverVisible}
        onVisibleChange={handleVisible}
        getPopupContainer={() => document.getElementById("progress-popover")}
      >
        {isKeyResult ? (
          <ReactSlider
            disabled={disabled}
            ref={sliderRef}
            className={`${styles.rcProgressSlider} ${getSizeOfSlider(
              screens
            )} ${isFromReviews ? styles.miniRcProgressSlider : ""} ${
              disabled ? "cursor-not-allowed" : ""
            }`}
            trackClassName={styles.rcProgressTrack}
            marks={[expectedProgress]}
            value={currentPercentage}
            onChange={handleSliderChange}
            renderMark={(props) => (
              <Tooltip title={`Expected: ${expectedProgress}%`} {...props}>
                <div
                  style={{
                    display: "inline",
                    left: `${expectedProgress > 0 ? expectedProgress : 0}%`,
                    height: "100%",
                    position: "absolute",
                    backgroundColor: "transparent !important",
                    borderRight: "2px solid rgba(51, 51, 51, 0.3)",
                  }}
                  className={styles.expectedProgressBar}
                ></div>
              </Tooltip>
            )}
            renderThumb={(props) => {
              // if (!disabled) {
              return (
                <div
                  {...props}
                  className={
                    disabled
                      ? styles.rcProgressDisabledThumb
                      : styles.rcProgressThumb
                  }
                  onClick={() => {
                    setIsProgressPopoverVisible(true);
                  }}
                >
                  <Icon component={Drag} />
                </div>
              );
              // }
            }}
            renderTrack={(props, state) => {
              return (
                <div
                  {...props}
                  style={{
                    ...props?.style,
                    backgroundColor:
                      currentPercentage !== 0 &&
                      state.index === 0 &&
                      statusColors[status],
                  }}
                  index={state.index}
                >
                  {state.index === 0 && (
                    <Text className={styles.rcProgressSliderTrackText}>
                      {statusText[status]}
                    </Text>
                  )}
                </div>
              );
            }}
          />
        ) : (
          <ReactSlider
            disabled={disabled}
            className={`${styles.basicSlider} okr-progress-slider ${
              disabled ? "cursor-not-allowed objective-slider" : ""
            }`}
            trackClassName={styles.rcProgressTrack}
            value={currentPercentage}
            onChange={handleSliderChange}
            tooltipVisible={false}
            renderThumb={(props) => {
              // if (!disabled) {
              return (
                <div
                  {...props}
                  className={
                    disabled
                      ? styles.rcProgressDisabledThumb
                      : styles.rcBasicThumb
                  }
                  onClick={() => {
                    setIsProgressPopoverVisible(true);
                  }}
                ></div>
              );
              // }
            }}
            renderTrack={(props, state) => {
              return (
                <div
                  {...props}
                  style={{
                    ...props?.style,
                    backgroundColor:
                      currentPercentage !== 0 && state.index === 0 && "#007bff",
                  }}
                  index={state.index}
                ></div>
              );
            }}
          />
        )}
      </Popover>
    </>
  );
};
ProgressSlider.propTypes = {
  metric: PropTypes.string,
  current: PropTypes.number,
  target: PropTypes.number,
  start: PropTypes.number,
};

export default ProgressSlider;

import axios from "axios";

const getAllTeamsApi = async () => {
  const { data } = await axios.get("/api/v2/teams/");
  return data;
};

const saveTeamApi = async ({ team }) => {
  const { data } = await axios.post("/api/v2/admin/teams/", {
    ...team,
  });
  return data;
};

const deleteTeamApi = async ({ id }) => {
  const { data } = await axios.delete(`/api/v2/admin/teams/${id}`);
  return data;
};

export { getAllTeamsApi, saveTeamApi, deleteTeamApi };

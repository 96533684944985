export const TEXT_TYPE = "PerfReviewTextQuestion";
export const MCQ_TYPE = "PerfReviewMcqQuestion";

export const ratingOptions = [
  { id: 1, value: "0" },
  { id: 2, value: "1" },
  { id: 3, value: "2" },
  { id: 4, value: "3" },
  { id: 5, value: "4" },
  { id: 6, value: "5" },
];

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  DatePicker,
  // Radio,
  Row,
  Col,
  Checkbox,
} from "antd";
// import { VList } from "virtuallist-antd";

import {
  EmployeeSearchSelect,
  EmployeeAvatarWithName,
  SelectEmployeesRadioGroup,
  TreeTable,
  // VirtualTable,
} from "components";
import {
  getInitialTreeData,
  getInitialTreeDataForPerfReview,
} from "app/company/common/utils";
import StepFormActionButtons from "./StepFormActionButtons";
// import { getNameFromEmployeeObj } from "app/appUtils";

import styles from "../../Review.module.css";

const { RangePicker } = DatePicker;

const prepareColumns = (employees, inActiveEmployees, managerMap) => [
  {
    title: "Employee",
    dataIndex: "key",
    key: "key",
    render: (data, row) => {
      // return <Text>{getNameFromEmployeeObj(row)}</Text>;
      return (
        <>
          <EmployeeAvatarWithName employee={row} shouldShowInactive={true} />
        </>
      );
    },
  },
  {
    title: "Reviewer",
    render: (data, row) => {
      return (
        <Form.Item
          className="mb-0"
          shouldUpdate
          // name={["managerMappings", [data.id]]}
        >
          {({ getFieldValue, setFieldsValue }) => {
            const managerMappings = getFieldValue("managerMappings") || {};

            const handleChange = (managerId) => {
              const empId = data?.id;
              const newManagerMapping = { ...managerMappings };
              newManagerMapping[empId] = managerId;
              setFieldsValue({ managerMappings: newManagerMapping });
            };

            return (
              <EmployeeSearchSelect
                value={managerMappings[data?.id]}
                onChange={handleChange}
                shouldShowAvatar={true}
                placeholder="Select Reviewer"
                className={styles.reviewerSelectWidth}
                defaultValue={managerMap ? managerMap[data?.id] : null}
                employees={employees?.filter((emp) => emp?.id !== data?.id)}
                inActiveEmployees={inActiveEmployees}
              />
            );
          }}
        </Form.Item>
      );
    },
  },
];

function CycleBasicsForm({
  mode = "create",
  initialValues = {},
  employees = [],
  inActiveEmployees = [],
  handleSaveAsDraft = () => {},
  handlePreviousClick = () => {},
}) {
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );
  const [initialTreeData, setInitialTreeData] = useState(
    getInitialTreeData(employees, inActiveEmployees)
  );

  useEffect(() => {
    if (!initialValues.managerMappings) {
      const value = {};
      employees.forEach((employee) => {
        value[employee.key] = employee.manager_id;
      });
      form.setFieldsValue({ managerMappings: value });
    } else {
      form.setFieldsValue({
        managerMappings: { ...initialValues.managerMapping },
      });
      setTreeData(
        getInitialTreeDataForPerfReview(
          initialValues.managerMapping,
          employees,
          inActiveEmployees
        )
      );
      setInitialTreeData(
        getInitialTreeDataForPerfReview(
          initialValues.managerMapping,
          employees,
          inActiveEmployees
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmployeeSelection = (selectedRowKeys, managerMappings) => {
    const reviews = selectedRowKeys?.map((key) => ({
      reviewee_id: key,
      reviewer_id: managerMappings[key] || null,
    }));
    form.setFieldsValue({ reviews });
  };

  const handleResetSearch = () => {
    setTreeData(initialTreeData);
  };

  const handleSelect = (updatedKeys) => {
    handleEmployeeSelection(updatedKeys, form.getFieldValue("managerMappings"));
  };

  const handleSelectAll = (employeeIds) => {
    handleEmployeeSelection(employeeIds, form.getFieldValue("managerMappings"));
  };

  return (
    <Form
      className="mt-24"
      form={form}
      name="cycleBasics"
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Cycle Name"
        rules={[
          {
            required: true,
            message: "Missing Cycle Name",
          },
          {
            whitespace: true,
            message: "Cycle Name cannot be Empty",
          },
        ]}
        className={styles.cycleBasicsName}
        required={false}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Cycle Description"
        rules={[
          {
            required: true,
            message: "Missing Cycle Description",
          },
          {
            whitespace: true,
            message: "Cycle Description cannot be Empty",
          },
        ]}
        required={false}
      >
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          className="input-textarea-resize-none"
        />
      </Form.Item>

      <Row gutter={[40]}>
        <Col>
          <Form.Item
            name="period"
            label="Cycle Period"
            rules={[
              {
                required: true,
                message: "Missing Cycle Period",
              },
            ]}
            required={false}
          >
            <RangePicker />
          </Form.Item>
        </Col>
        <Col className="flex-display align-items-center">
          <Form.Item
            noStyle
            name="has_peer_reviews"
            label=""
            required={false}
            getValueFromEvent={(e) => e.target.checked}
            valuePropName="checked"
          >
            <Checkbox>Enable Peer Reviews</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="managerMappings" hidden={true} />
      <Form.Item
        name="for_entire_company"
        label="Who will be reviewed in this cycle?"
        hidden={mode === "edit"}
        className="mb-0"
      >
        <SelectEmployeesRadioGroup handleResetSearch={handleResetSearch} />
      </Form.Item>

      <Form.Item hidden name="reviews"></Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.reviews !== curValues?.reviews ||
          prevValues?.for_entire_company !== curValues?.for_entire_company
        }
        hidden={mode === "edit"}
      >
        {() => {
          let reviews = form.getFieldValue("reviews") || [];

          return !form.getFieldValue("for_entire_company") ? (
            <TreeTable
              selections={reviews}
              selectedRowKeys={reviews?.map((review) => review?.reviewee_id)}
              initialTreeData={initialTreeData}
              activeEmployees={employees}
              inActiveEmployees={inActiveEmployees}
              columns={prepareColumns(
                employees,
                inActiveEmployees,
                form.getFieldValue("managerMappings")
              )}
              dataSource={treeData}
              setTreeData={setTreeData}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              handleResetSearch={handleResetSearch}
            />
          ) : null;
        }}
      </Form.Item>

      <Form.Item>
        <StepFormActionButtons
          mode={mode}
          currentStep={0}
          shouldShowSaveAsDraft={initialValues?.draft}
          handlePreviousClick={handlePreviousClick}
          handleSaveAsDraft={() => {
            handleSaveAsDraft({ ...form.getFieldsValue(true), draft_step: 0 });
          }}
        />
      </Form.Item>
    </Form>
  );
}

export default CycleBasicsForm;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  Row,
  Col,
  Button,
  Empty,
  Grid,
  Table,
  Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  BoxLoader,
  EmployeeAvatar,
  EmployeeAvatarWithName,
  EditEmployeeNameModal,
  DueDateText,
} from "components";
import { useCurrentCompany } from "store";
import { getHomeDetailsApi } from "apis/homeApi";
import SeeOKRsDrawer from "./common/SeeOKRsDrawer";
import { getStatus, getExpectedPercentageFor } from "app/okrs/common/utils";
import { statusColors, statusText } from "app/okrs/common/constants";
import { updateProfileApi } from "apis/commonApi";
import {
  getNameFromEmployeeObj,
  successNotification,
  errorNotification,
} from "app/appUtils";
import { ReactComponent as TodoIcon } from "assets/icons/todo.svg";

import styles from "./Home.module.css";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const OKRRow = ({
  className = "",
  screens = {},
  expectedProgress = 0,
  rowData = {},
  handleSeeEmployeeOkrs = () => {},
}) => {
  const status = getStatus(rowData?.okr_progress, expectedProgress);

  return (
    <Row className={className} justify="space-between" align="middle">
      <Col
        xs={{ span: 24 }}
        lg={{ span: 24 }}
        xl={{ span: 9 }}
        xxl={{ span: 10 }}
      >
        <EmployeeAvatarWithName
          // className="min-width-150-px max-width-150-px"
          employee={rowData?.employee}
        />
      </Col>
      <Col
        xs={{ span: 14 }}
        lg={{ span: 14 }}
        xl={{ span: 7 }}
        xxl={{ span: 8 }}
      >
        <Text
          style={{ color: statusColors[status] }}
        >{`${rowData?.okr_progress}% ${statusText[status]}`}</Text>
      </Col>
      <Col
        xs={{ span: 10 }}
        lg={{ span: 10 }}
        xl={{ span: 8 }}
        xxl={{ span: 5 }}
        className={`flex-display ${
          screens?.lg ? "justify-content-flex-end" : ""
        }`}
      >
        <Button
          size="small"
          type="link"
          ghost={true}
          onClick={() => {
            handleSeeEmployeeOkrs(rowData?.employee);
          }}
        >
          See OKRs
        </Button>
      </Col>
    </Row>
  );
};

const Home = (props) => {
  const screens = useBreakpoint();
  const [currentCompany, setCurrentCompany] = useCurrentCompany();

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditEmployeeVisible, setIsEditEmployeeVisible] = useState(false);
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery(
    "getHomeDetails",
    getHomeDetailsApi
  );

  const [
    updateEmployee,
    {
      status: updateEmployeeStatus,
      isLoading: updateEmployeeLoading,
      isFetching: updateEmployeeFetching,
    },
  ] = useMutation(updateProfileApi, {
    onSuccess: (data) => {
      refetch();
      successNotification("Employee Updated Successfully");

      const currentCompanyUser = { ...currentCompany?.user };
      setCurrentCompany({
        ...currentCompany,
        user: { ...currentCompanyUser, name: data?.name },
      });
      handleIsEditEmployeeVisible();
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleSeeEmployeeOkrs = (employee = null) => {
    setSelectedEmployee(employee);
  };

  const handleNavigateToMyOKRs = () => {
    history.push("/okrs");
  };

  const handleNavigateToTodo = (todo) => {
    history.push(todo?.link_extension);
  };

  const handleIsEditEmployeeVisible = () => {
    setIsEditEmployeeVisible(!isEditEmployeeVisible);
  };

  const handleEmployeeNameChange = (employee, { first_name, last_name }) => {
    updateEmployee({
      employee: {
        first_name,
        last_name,
      },
    });
  };

  const updatingEmployee =
    updateEmployeeStatus === "loading" ||
    updateEmployeeLoading ||
    updateEmployeeFetching;

  const expectedProgress = getExpectedPercentageFor(
    data?.current_objective_period
  );
  const myStatus = getStatus(data?.my_summary?.okr_progress, expectedProgress);

  return (
    <div>
      <Helmet>
        <title>Home - culture.easy</title>
      </Helmet>
      {Boolean(selectedEmployee) && (
        <SeeOKRsDrawer
          screens={screens}
          employee={selectedEmployee}
          onClose={() => handleSeeEmployeeOkrs(null)}
        />
      )}
      {isEditEmployeeVisible && (
        <EditEmployeeNameModal
          isProfile={true}
          visible={isEditEmployeeVisible}
          employee={data?.my_summary?.employee}
          isUpdating={updatingEmployee}
          onUpdate={handleEmployeeNameChange}
          onCancel={handleIsEditEmployeeVisible}
        />
      )}
      <Title level={2}>Home</Title>
      {isLoading ? (
        <BoxLoader />
      ) : (
        <>
          <Card className="width-100-percent mt-16">
            <Row align="middle" gutter={[24, 24]}>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 14 }}
                xxl={{ span: 16 }}
              >
                {screens?.md ? (
                  <div className="flex-display align-items-center">
                    <EmployeeAvatar
                      height={"100px"}
                      width={"100px"}
                      className="min-width-100-px mr-8"
                      shouldShowDetails={false}
                      employee={data?.my_summary?.employee}
                      textFontSize="40px"
                    />
                    <div className="ml-8">
                      <div>
                        <Title level={2} className="inline-display">
                          {getNameFromEmployeeObj(data?.my_summary?.employee)}
                        </Title>
                        <EditOutlined
                          className="ml-16 cursor-pointer"
                          onClick={handleIsEditEmployeeVisible}
                        />
                      </div>

                      <div>
                        <Text className="block-display">
                          {data?.my_summary?.employee?.designation
                            ? `${data?.my_summary?.employee?.designation}`
                            : ""}
                        </Text>
                        <Text className="block-display">
                          {data?.my_summary?.employee?.sub_department || ""}{" "}
                          {data?.my_summary?.employee?.department || ""}
                        </Text>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pl-24">
                    <EmployeeAvatarWithName
                      height={"40px"}
                      width={"40px"}
                      employee={data?.my_summary?.employee}
                    />
                    <EditOutlined
                      className="ml-16 cursor-pointer"
                      onClick={handleIsEditEmployeeVisible}
                    />
                    <div>
                      <Text className="block-display">
                        {data?.my_summary?.employee?.designation
                          ? `${data?.my_summary?.employee?.designation}`
                          : ""}
                      </Text>
                      <Text className="block-display">
                        {data?.my_summary?.employee?.sub_department || ""}{" "}
                        {data?.my_summary?.employee?.department || ""}
                      </Text>
                    </div>
                  </div>
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                xxl={{ span: 8 }}
              >
                <Row
                  align="top"
                  justify="center"
                  className="flex-direction-column pl-24"
                >
                  <div className="width-100-percent">
                    <Text className="block-display">My OKR status</Text>
                    <Space direction={screens?.md ? "horizontal" : "vertical"}>
                      <Title
                        className="mr-8 mb-0"
                        level={3}
                        style={{ color: statusColors[myStatus] }}
                      >{`${data?.my_summary?.okr_progress || 0}% (${
                        statusText[myStatus]
                      })`}</Title>
                      <Button
                        className={`${screens?.md ? "" : "pt-0"} pl-0`}
                        type="link"
                        size="small"
                        onClick={handleNavigateToMyOKRs}
                      >
                        See My OKRs
                      </Button>
                    </Space>
                    <Text className="block-display mt-8">Reports To</Text>
                    <EmployeeAvatarWithName
                      employee={data?.manager_summary?.employee}
                      emptyText="No Manager"
                    />
                  </div>
                </Row>

                {/* <Row align="middle" gutter={[16, 16]}>
                  <Col
                    xs={{ span: 24 }}
                    lg={{ span: 8 }}
                    xxl={{ span: 7 }}
                    className="flex-display flex-direction-column justify-content-center"
                  >
                    <div>
                      <Title
                        level={3}
                        style={{ color: statusColors[myStatus] }}
                      >{`${data?.my_summary?.okr_progress || 0}% (${
                        statusText[myStatus]
                      })`}</Title>
                      <Text type="secondary">My OKR status</Text>
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 14 }} xxl={{ span: 14 }}>
                    <Text type="secondary" className="block-display">
                      Reports To
                    </Text>
                    <EmployeeAvatarWithName
                      employee={data?.manager_summary?.employee}
                      emptyText="No Manager"
                    />
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Card>
          <Row className="mt-48" gutter={{ xs: [0, 16], md: [16] }}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 14 }}
              // xxl={{ span: 16 }}
            >
              <Card className={"mb-16"}>
                <Row align="middle" className="mb-8">
                  <TodoIcon />
                  <Title
                    level={4}
                    className="font-size-18 ml-8 mb-0 inline-display"
                  >
                    Your TODOs
                  </Title>
                </Row>
                <div className={styles?.todosCard}>
                  {data?.action_items?.length > 0 ? (
                    <>
                      {data?.action_items?.map((todo) => {
                        const isDueDateInPast = moment(
                          todo?.due_date,
                          "YYYY-MM-DD"
                        )
                          ?.startOf("D")
                          ?.isBefore(moment());

                        const differenceOfDates = Math.abs(
                          moment(todo?.due_date, "YYYY-MM-DD")
                            ?.startOf("D")
                            ?.diff(moment()?.startOf("D"), "days")
                        );

                        return (
                          <Row
                            className={`mb-8 px-24 py-16 ${styles.todoCard}`}
                            align="middle"
                            justify="space-between"
                          >
                            <Col
                              xs={{ span: 24 }}
                              md={{ span: 16 }}
                              lg={{ span: 17 }}
                            >
                              <Text className="block-display">
                                {todo?.action}
                              </Text>
                              <div>
                                <DueDateText
                                  differenceOfDates={differenceOfDates}
                                  isDueDateInPast={isDueDateInPast}
                                />
                              </div>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              md={{ span: 8 }}
                              lg={{ span: 7 }}
                              className={`flex-display ${
                                screens?.md ? "justify-content-flex-end" : ""
                              }`}
                            >
                              <Button
                                className="min-width-125-px"
                                type="primary"
                                ghost={true}
                                size="small"
                                onClick={() => handleNavigateToTodo(todo)}
                              >
                                {todo?.link_description || ""}
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <Empty description="No Todos" />
                  )}
                </div>
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 14 }}
              lg={{ span: 10 }}
              // xxl={{ span: 8 }}
            >
              <Card>
                {data?.reports_summary?.length > 0 ? (
                  <>
                    <Title level={4} className="font-size-18">
                      OKR progress of my team members
                    </Title>
                    {screens?.md ? (
                      <Table
                        showHeader={false}
                        columns={getOKRColumns(
                          screens,
                          expectedProgress,
                          handleSeeEmployeeOkrs
                        )}
                        locale={{
                          emptyText: <Empty description="No Reports" />,
                        }}
                        dataSource={data?.reports_summary}
                        pagination={false}
                      />
                    ) : (
                      <div>
                        {data?.reports_summary?.length > 0 ? (
                          <>
                            {data?.reports_summary?.map((rowData) => (
                              <OKRRow
                                className="mb-8"
                                screens={screens}
                                expectedProgress={expectedProgress}
                                rowData={rowData}
                                handleSeeEmployeeOkrs={handleSeeEmployeeOkrs}
                              />
                            ))}
                          </>
                        ) : (
                          <Empty description="No Reports" />
                        )}
                      </div>
                    )}
                  </>
                ) : null}

                {data?.manager_summary?.employee ? (
                  <>
                    <Title className="mt-16" level={3}>
                      My Manager
                    </Title>
                    {screens?.md ? (
                      <Table
                        showHeader={false}
                        columns={getOKRColumns(
                          screens,
                          expectedProgress,
                          handleSeeEmployeeOkrs
                        )}
                        locale={{
                          emptyText: <Empty description="No Manager" />,
                        }}
                        dataSource={[data?.manager_summary]}
                        pagination={false}
                      />
                    ) : (
                      <OKRRow
                        screens={screens}
                        expectedProgress={expectedProgress}
                        rowData={data?.manager_summary}
                        handleSeeEmployeeOkrs={handleSeeEmployeeOkrs}
                      />
                    )}
                  </>
                ) : null}
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const getOKRColumns = (
  screens,
  expectedProgress,
  handleSeeEmployeeOkrs = () => {}
) => {
  return [
    {
      title: "Report",
      dataIndex: "employee",
      key: "employee",
      width: "40%",
      // width: !screens?.xl ? 125 : undefined,
      render: (employee) => <EmployeeAvatarWithName employee={employee} />,
    },
    {
      title: "OKR Progress",
      dataIndex: "okr_progress",
      key: "okr_progress",
      width: "40%",
      // width: !screens?.xl ? 125 : undefined,
      render: (okr_progress) => {
        const status = getStatus(okr_progress, expectedProgress);
        return (
          <>
            <Text
              className="block-display"
              style={{ color: statusColors[status] }}
            >{`${okr_progress}%`}</Text>

            <Text
              style={{ color: statusColors[status] }}
            >{`(${statusText[status]})`}</Text>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, row) => (
        <Row justify="end">
          <Button
            className={"px-0"}
            size="small"
            type="link"
            ghost={true}
            onClick={() => {
              handleSeeEmployeeOkrs(row?.employee);
            }}
          >
            See OKRs
          </Button>
        </Row>
      ),
    },
  ];
};

Home.propTypes = {};

export default Home;

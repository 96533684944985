import React from "react";
import { useCurrentUser, useIsSignedOut } from "store";
import { Redirect, useLocation } from "react-router-dom";

import { PageSpinner } from "components";

const AuthGuard = ({ children }) => {
  const [currentUser] = useCurrentUser();
  const [isSignedOut] = useIsSignedOut();
  const location = useLocation();

  if (isSignedOut) {
    //   // we have this here because we don't want to show login page while signing out.
    //   // If we just change location <AppLayoutV3 />, app gets re-rendered and since AuthGuard is rendered
    //   // before layout, we'll see login page for a split second which is bad UX.
    //   // localStorage.removeItem("currentCompanyId");
    //   const linkedInPixel = document.getElementById("linkedin-pixel");
    //   const linkedInConversionPixel = document.getElementById(
    //     "linkedin-pixel-conversion"
    //   );

    //   if (linkedInPixel) {
    //     linkedInPixel.remove();
    //   }
    //   if (linkedInConversionPixel) {
    //     linkedInConversionPixel.remove();
    //   }

    //   window.location = "https://culture.easy.xyz";
    return <PageSpinner />;
  }

  if (currentUser) {
    return children;
  }
  return (
    <Redirect to={`/auth?from_page=${location?.pathname}${location?.search}`} />
  );
};

export default AuthGuard;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Alert, Tooltip, Row, Typography, Empty } from "antd";
import { useQuery } from "react-query";

import { BoxLoader, EmployeeAvatarGroup } from "components";
import { getObjectiveDetailsApi } from "apis/okrV2Api";

const { Text } = Typography;

const DeleteObjectiveWarning = ({
  visible = false,
  objectiveId = "",
  objectiveTitle = "",
  onCancel = () => {},
  onDelete = () => {},
}) => {
  const { data, isLoading, isFetching } = useQuery(
    ["getObjectiveDetails", objectiveId],
    getObjectiveDetailsApi
  );

  const loader = isLoading || isFetching;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onDelete}
      okText="Delete"
      okButtonProps={{ type: "danger" }}
      title="Delete OKR"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {data?.objectives?.length > 0 ? (
          <>
            <Alert
              type="warning"
              message={`All the sub objectives of this objective (${objectiveTitle}) will be unaligned. Do you still
        want to proceed?`}
            />
            {loader && <BoxLoader height="10vh" />}

            <div
              className="mt-16"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {data?.objectives?.length === 0 && (
                <Empty description="No Sub objectives" />
              )}
              {data?.objectives?.map((objective) => (
                <Row align="middle" className="parentObjectiveSelectRow">
                  <EmployeeAvatarGroup
                    className="mr-8"
                    divClassName="inline-display height-90-percent"
                    employees={objective?.owners || []}
                  />
                  <Tooltip title={objective?.title}>
                    <Text style={{ maxWidth: "70%" }} ellipsis={true}>
                      {objective?.title}
                    </Text>
                  </Tooltip>
                </Row>
              ))}
            </div>
          </>
        ) : (
          <Text>{`This Objective(${objectiveTitle}) will be deleted. This action can not be undone.`}</Text>
        )}
      </div>
    </Modal>
  );
};

DeleteObjectiveWarning.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default DeleteObjectiveWarning;

const savePraise = () => {
  return {
    id: 1,
    title: "Test Praise",
    description: "Test praise description",
    praise_badge: {
      id: 1,
      icon: "test",
      title: "Test Badge",
      description: "Test badge description",
    },
    praised_by: {
      id: 1,
      first_name: "Ritesh",
      last_name: "Malani",
      email: "ritesh@beautifulcode.in",
      name: "Ritesh Malani",
      active: true,
    },
    praised_employees: [
      {
        id: 1,
        employee: {
          id: 1,
          first_name: "Ritesh",
          last_name: "Malani",
          email: "ritesh@beautifulcode.in",
          name: "Ritesh Malani",
          active: true,
        },
      },
    ],
    created_at: "2020-08-03 06:34:36.846674",
  };
};

const getPraises = (db, request) => {
  return [
    {
      id: 1,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "flight_take_off",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 2,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 2,
        icon: "business_center",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 3,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 4,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 3,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "event_seat",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 4,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "build",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 5,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "thumb_up",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
        {
          id: 2,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Chirath R",
            active: true,
          },
        },
        {
          id: 3,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Bal Krishna Singh",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 6,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "group_work",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 7,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "star",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 8,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "cake",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
    {
      id: 9,
      title: "Test Praise",
      description: "Test praise description",
      praise_badge: {
        id: 1,
        icon: "brush",
        title: "Test Badge",
        description: "Test badge description",
      },
      praised_by: {
        id: 1,
        first_name: "Ritesh",
        last_name: "Malani",
        email: "ritesh@beautifulcode.in",
        name: "Ritesh Malani",
        active: true,
      },
      praised_employees: [
        {
          id: 1,
          employee: {
            first_name: "Ritesh",
            last_name: "Malani",
            email: "ritesh@beautifulcode.in",
            name: "Ritesh Malani",
            active: true,
          },
        },
      ],
      created_at: "2020-08-03 06:34:36.846674",
      likes: 10,
      have_i_liked: true,
      comments: [
        {
          id: 1,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "Test Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
        {
          id: 2,
          author_id: "ritesh@beautifulcode.in",
          author_fn: "Ritesh",
          author_ln: "Malani",
          author_profile_pic_url: "",
          content: "New Comment",
          created_at: "2020-09-30",
          updated_at: "2020-09-30",
        },
      ],
    },
  ];
};

const deletePraise = () => {
  return { message: "Praise Deleted" };
};

const likePraise = () => {
  return {
    likes: 20,
  };
};

const unlikePraise = () => {
  return {
    likes: 19,
  };
};

const postComment = () => {
  return {
    id: 1,
    author_id: "ritesh@beautifulcode.in",
    author_fn: "Ritesh",
    author_ln: "Malani",
    author_profile_pic_url: "",
    content: "Test Comment",
    created_at: "2020-09-30",
    updated_at: "2020-09-30",
  };
};

const getAllComments = () => {
  return [
    {
      id: 1,
      author_id: "ritesh@beautifulcode.in",
      author_fn: "Ritesh",
      author_ln: "Malani",
      author_profile_pic_url: "",
      content: "Test Comment",
      created_at: "2020-09-30",
      updated_at: "2020-09-30",
    },
    {
      id: 2,
      author_id: "ritesh@beautifulcode.in",
      author_fn: "Ritesh",
      author_ln: "Malani",
      author_profile_pic_url: "",
      content: "New Comment",
      created_at: "2020-09-30",
      updated_at: "2020-09-30",
    },
  ];
};

const updateComment = () => {
  return {
    id: 1,
    author_id: "ritesh@beautifulcode.in",
    author_fn: "Ritesh",
    author_ln: "Malani",
    author_profile_pic_url: "",
    content: "new Comment",
    created_at: "2020-09-30",
    updated_at: "2020-09-30",
  };
};

const deleteComment = () => {
  return {
    message: "comment deleted",
  };
};

const savePraiseBadge = () => {
  return {
    id: 1,
    icon: "test",
    title: "Test Badge",
    description: "Test badge description",
  };
};

const getAllPraiseBadges = () => {
  return [
    {
      id: 10,
      icon: "flash_on",
      title: "Problem Solver",
      description:
        "Recognition for someone who does not take it easy until a problem is solved.",
    },
    {
      id: 9,
      icon: "brush",
      title: "Innovator",
      description: "A badge for someone who comes with innovative solutions.",
    },
    {
      id: 8,
      icon: "cake",
      title: "Thanks",
      description: "Short and sweet ‘thank you’ badge.",
    },
    {
      id: 7,
      icon: "star",
      title: "Rockstar",
      description: "For someone who is simply awesome.",
    },
    {
      id: 6,
      icon: "group_work",
      title: "Team Player",
      description: "A badge for someone who helps succeed as a team.",
    },
    {
      id: 5,
      icon: "flight_take_off",
      title: "Bar Raiser",
      description: "A toast to someone who goes to the next level.",
    },
    {
      id: 4,
      icon: "thumb_up",
      title: "Thumbs up",
      description: "Appreciation for a job well done.",
    },
    {
      id: 3,
      icon: "build",
      title: "Automator",
      description: "A badge for someone who uses technology to solve problems.",
    },
    {
      id: 2,
      icon: "event_seat",
      title: "Evangelist",
      description: "A badge for someone who espouses company or team values.",
    },
    {
      id: 1,
      icon: "business_center",
      title: "Money Maker",
      description: "For someone who makes the business financially successful.",
    },
  ];
};

const deletePraiseBadge = () => {
  return { message: "Praise Badge Deleted." };
};

export {
  deletePraise,
  unlikePraise,
  savePraise,
  likePraise,
  getPraises,
  postComment,
  getAllComments,
  updateComment,
  deleteComment,
  savePraiseBadge,
  getAllPraiseBadges,
  deletePraiseBadge,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Input } from "antd";
import { useMutation, useQueryCache } from "react-query";

import { useCurrentCompany } from "store";
import { APIBasedEmployeeSearch } from "components";
import { postRequestFeedbackApi } from "apis/continuousFeedbackApi";
import { successNotification, errorNotification } from "app/appUtils";

const RequestFeedbackModal = ({
  visible = false,
  feedback = {},
  onCancel = () => {},
  refetch = () => {},
}) => {
  const [currentCompany] = useCurrentCompany();
  const isEdit = Boolean(feedback?.id);
  const [form] = Form.useForm();
  const queryCache = useQueryCache();

  const [availableEmployees, setAvailableEmployees] = useState(
    isEdit ? feedback?.employees : []
  );

  const [postRequestFeedback] = useMutation(postRequestFeedbackApi, {
    onSuccess: () => {
      onCancel();
      form.resetFields();
      refetch();
      successNotification("Successfully raised request");
      queryCache.invalidateQueries("getFeedbackRequest");
    },
    onError: (error) => {
      errorNotification(error);
    },
  });

  const handleSetAvailableEmployees = (availableEmployees = []) => {
    const uniqueEmployeeIds = [
      ...new Set(availableEmployees.map((emp) => emp?.id)),
    ];
    const uniqueEmployees = uniqueEmployeeIds.map((empId) =>
      availableEmployees?.find((emp) => emp?.id === empId)
    );
    setAvailableEmployees(uniqueEmployees);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        postRequestFeedback({
          continuous_feedback_request: values,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const currentCompanyUserId = currentCompany?.user?.id;

  return (
    <Modal
      width="750px"
      title={isEdit ? "Edit Request" : "Request Feedback"}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        initialValues={{
          id: isEdit ? feedback?.id : undefined,
          employees: isEdit
            ? feedback?.employees?.map((emp) => emp?.id)
            : undefined,
          question: isEdit ? feedback?.question : "",
        }}
        layout="vertical"
      >
        <Form.Item name="id" hidden={true} />
        <Form.Item
          name="employees"
          label="Request Feedback From"
          rules={[
            { required: true, message: "Missing employees." },
            {
              validator: (rule, value, callback) => {
                if (value?.includes(currentCompanyUserId)) {
                  callback("Can't nominate yourself for feedback.");
                } else {
                  callback();
                }
              },
            },
          ]}
          required={false}
        >
          <APIBasedEmployeeSearch
            mode="multiple"
            className="width-250-px mr-8"
            currentOwners={availableEmployees}
            // placeholder="Employee name"
            showArrow={false}
            allowClear={true}
            shouldShowAvatar={true}
            setAvailableEmployees={handleSetAvailableEmployees}
          />
        </Form.Item>
        <Form.Item
          name="question"
          label="Request Feedback regarding"
          rules={[{ required: true, message: "Missing question." }]}
          required={false}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 6 }}
            className="input-textarea-resize-none"
          ></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

RequestFeedbackModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default RequestFeedbackModal;

import React from "react";
import PropTypes from "prop-types";
import { Badge } from "antd";

import styles from "./DraftRibbon.module.css";
const { Ribbon } = Badge;

const DraftRibbon = ({ hidden = true, children }) => {
  if (hidden) {
    return children;
  }
  return (
    <Ribbon className={styles.draftRibbon} placement="start" text="Draft">
      {children}
    </Ribbon>
  );
};
DraftRibbon.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.any,
};
export default DraftRibbon;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Space, Typography } from "antd";

import {
  ViewAnswers,
  EmployeeAvatarWithName,
  // WriteAnswersV2,
} from "components";

import styles from "../Review.module.css";

const { Text } = Typography;

const ViewAssessmentModal = ({ reviewData = {}, onCancel = () => {} }) => {
  const revieweeStatus = reviewData?.reviewee?.status;
  const reviewerStatus = reviewData?.reviewer?.status || "Not Started";

  return (
    <Modal
      visible={Boolean(reviewData)}
      title={"View Assessment"}
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      width={1000}
      onCancel={onCancel}
      footer={null}
    >
      <Row className="mb-16">
        <Col span={12}>
          <Space size={4}>
            <EmployeeAvatarWithName employee={reviewData?.reviewee} />{" "}
            <Text type="secondary">(Report)</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space size={4}>
            <EmployeeAvatarWithName
              emptyText="No Reviewer"
              employee={reviewData?.reviewer}
            />{" "}
            <Text type="secondary">(Manager)</Text>
          </Space>
        </Col>
      </Row>

      <div className={styles.viewAnswersModal}>
        {/* {(revieweeStatus === "Signed" || revieweeStatus === "Submitted") &&
          (reviewerStatus === "Signed" || reviewerStatus === "Submitted") && (
            <ViewAnswers
              reviewData={reviewData}
              shouldEditRevieweeAnswer={true}
              revieweeAnswers={reviewData?.reviewee?.answers || {}}
              reviewerAnswers={reviewData?.reviewer?.answers || {}}
            />
          )}

        {(revieweeStatus === "Signed" || revieweeStatus === "Submitted") &&
          (reviewerStatus === "Not Started" ||
            reviewerStatus === "In Progress" ||
            reviewerStatus === "Unlocked") && (
            <Row justify="space-between">
              <Col span={12}>
                <WriteAnswersV2
                  reviewData={reviewData}
                  shouldEditRevieweeAnswer={true}
                  answers={reviewData?.reviewee?.answers || {}}
                  type="reviewee_text"
                  disabled={true}
                />
              </Col>
              <Col span={11}>
                Waiting for Reviewer to submit their responses.
              </Col>
            </Row>
          )}

        {(revieweeStatus === "Not Started" ||
          revieweeStatus === "In Progress" ||
          revieweeStatus === "Unlocked") &&
          (reviewerStatus === "Signed" || reviewerStatus === "Submitted") && (
            <Row justify="space-between">
              <Col span={11}>
                Waiting for Reviewee to submit their responses.
              </Col>
              <Col span={12}>
                <WriteAnswersV2
                  reviewData={reviewData}
                  shouldEditRevieweeAnswer={true}
                  answers={reviewData?.reviewer?.answers || {}}
                  type="reviewer_text"
                  disabled={true}
                />
              </Col>
            </Row>
          )} */}

        {(revieweeStatus === "Not Started" ||
          revieweeStatus === "In Progress" ||
          revieweeStatus === "Unlocked") &&
        (reviewerStatus === "Not Started" ||
          reviewerStatus === "In Progress" ||
          reviewerStatus === "Unlocked") ? (
          <Text>
            Neither Reviewer nor Reviewee have submitted their assessment yet.
            You will be able to see after either of them submit their review.
          </Text>
        ) : (
          <ViewAnswers
            isFromHrAdmin={true}
            reviewData={reviewData}
            shouldEditRevieweeAnswer={true}
            revieweeAnswers={reviewData?.reviewee?.answers || {}}
            reviewerAnswers={reviewData?.reviewer?.answers || {}}
          />
        )}
      </div>
    </Modal>
  );
};
ViewAssessmentModal.propTypes = {
  reviewId: PropTypes.number,
  onCancel: PropTypes.func,
};

export default ViewAssessmentModal;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as BackArrowPrimary } from "assets/icons/back_arrow_primary.svg";
import styles from "./BackLink.module.css";

const { Text } = Typography;

const BackLink = ({ to = "", name = "" }) => {
  return (
    <Link to={to} className={styles.backLink}>
      <Icon component={BackArrowPrimary} />
      <Text className="ml-16 text-primary">{name}</Text>
    </Link>
  );
};

BackLink.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string,
};

export default BackLink;

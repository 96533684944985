import React from "react";
import { Redirect } from "react-router-dom";
import AppLayoutV3 from "components/layouts/AppLayoutV3";
import OKRs from "./OKRs";
// import AddOKR from "./add/AddOKR.jsx";
// import EditOKR from "./edit/EditOKR.jsx";
import TreeView from "./tree-view/TreeView.jsx";

import { PageNotFound } from "components";

const routes = [
  {
    exact: true,
    path: "/okrs/add/",
    component: PageNotFound,
    layout: AppLayoutV3,
  },
  // {
  //   exact: true,
  //   path: "/okrs/edit/:okrId",
  //   component: EditOKR,
  // },
  {
    exact: true,
    path: "/okrs/company-okrs/tree-view",
    component: TreeView,
  },
  {
    exact: true,
    path: "/okrs/:selectedTab",
    layout: AppLayoutV3,
    component: OKRs,
  },
  {
    exact: true,
    path: "/okrs",
    layout: AppLayoutV3,
    component: () => <Redirect to="/okrs/my-okrs" />,
  },
];

export default routes;

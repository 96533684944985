import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Typography,
  Divider,
  Table,
  Button,
  Row,
  Card,
  Col,
  Collapse,
  Modal,
} from "antd";
import {
  CaretRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import {
  BoxLoader,
  APIBasedEmployeeSearch,
  DonutChart,
  PerfReviewExpandedRowRender,
  CustomExpandTable,
} from "components";
import AddPeersDrawer from "./components/AddPeersDrawer";
import PerfReviewTodoPanelHeader from "./components/PerfReviewTodoPanelHeader";
import { getColumns } from "./utils";
import { searchReportsChain } from "apis/commonApi";
import {
  getReviewCycleDetailsApi,
  getIndirectReportReviewsApi,
  finalizePeersApi,
  bulkFinalizeReportsApi,
} from "apis/perfReviewApi";
import {
  errorNotification,
  errorNotificationWithString,
  formattedDate,
  formattedDateShortYear,
} from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { confirm } = Modal;

const getNudgedReviewsForDetails = (
  reviews = [],
  reviewId = "",
  who = "reviewee",
  nudgedAt = ""
) => {
  const updatedReviews = reviews?.map((review) => {
    if (review?.id === reviewId) {
      if (who === "reviewee") {
        return { ...review, reviewee_nudge_at: nudgedAt };
      } else {
        return { ...review, reviewer_nudge_at: nudgedAt };
      }
    }
    return { ...review };
  });
  return updatedReviews;
};

const getDatesDiff = (date = "") => {
  if (!date) {
    return [0, false];
  }

  const isDueDateInPast = moment(date, "YYYY-MM-DD")
    ?.startOf("D")
    ?.isBefore(moment());

  const differenceOfDates = Math.abs(
    moment(date, "YYYY-MM-DD")
      ?.startOf("D")
      ?.diff(moment()?.startOf("D"), "days")
  );
  return [differenceOfDates, isDueDateInPast];
};

const ReviewDetailsOverview = ({
  enabled = false,
  setUrlSwitchIds = () => {},
  setSelectedReviewCycle = () => {},
}) => {
  const { reviewCycleId } = useParams();

  const [review, setReview] = useState({});
  const [nudgingTable, setNudgingTable] = useState("");
  const [indirectSearchError, setIndirectSearchError] = useState("");
  const [reviewsToSubmit, setReviewsToSubmit] = useState(0);

  const [searchedIndirectReports, setSearchedIndirectReports] = useState([]);
  const [availableIndirectReports, setAvailableIndirectReports] = useState([]);
  //   const [reviewEditId, setReviewEditId] = useState(null);
  const [revieweeForPeers, setRevieweeForPeers] = useState(null);

  const { status, isLoading, refetch, isFetching } = useQuery(
    ["getReviewDetails", reviewCycleId],
    getReviewCycleDetailsApi,
    {
      enabled,
      onSuccess: (data) => {
        setReview(data);
        if (data?.my_review?.length > 0) {
          setUrlSwitchIds({
            navigateTo: "write",
            reviewId: data?.my_review[0]?.id,
          });
        } else if (data?.my_peer_assessments?.length > 0) {
          setUrlSwitchIds({
            navigateTo: "peer-write",
            reviewId: null,
            peerId: data?.my_peer_assessments[0]?.id,
          });
        } else if (data?.my_report_reviews?.length > 0) {
          setUrlSwitchIds({
            navigateTo: "write",
            reviewId: data?.my_report_reviews[0]?.id,
          });
        }

        if (Boolean(revieweeForPeers)) {
          const myReportReviews = data?.my_report_reviews || [];
          myReportReviews.forEach((report) => {
            if (report?.id === revieweeForPeers?.id) {
              setRevieweeForPeers(report);
            }
          });
        }
        //submitReviews is the reviews user need to submit
        let submitReviews = 0;
        const peerAssessments = data?.my_peer_assessments || [];
        const my_review = data?.my_review || [];
        const myReview = my_review[0];
        const myReportReviews = data?.my_report_reviews || [];
        peerAssessments.forEach((peerAssessment) => {
          if (peerAssessment?.status !== "submitted") {
            submitReviews += 1;
          }
        });
        if (
          Boolean(myReview?.reviewee_status) &&
          (myReview?.reviewee_status !== "Signed" ||
            myReview?.reviewer_status !== "Signed")
        ) {
          submitReviews += 1;
        }
        myReportReviews.forEach((reportAssessment) => {
          if (
            reportAssessment?.reviewee_status !== "Signed" ||
            reportAssessment?.reviewer_status !== "Signed"
          ) {
            submitReviews += 1;
          }
        });
        setReviewsToSubmit(submitReviews);
        setSelectedReviewCycle(data?.review_cycle);
        return data;
      },
    }
  );

  const [
    getIndirectReportReviews,
    { data: indirectReportsData, status: indirectReportsStatus },
  ] = useMutation(getIndirectReportReviewsApi, {
    onSuccess: (data) => {
      if (data?.length === 0) {
        errorNotificationWithString("No reviews found for this employee");
      }
      return data;
    },
    onError: (err) => {
      errorNotification(err);
    },
  });

  const [finalizePeers, { isLoading: isFinalizingPeer }] = useMutation(
    finalizePeersApi,
    {
      onSuccess: () => {
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    }
  );

  const [bulkFinalizeReports, { isLoading: isBulkFinalizingPeer }] =
    useMutation(bulkFinalizeReportsApi, {
      onSuccess: () => {
        refetch();
      },
      onError: (err) => {
        errorNotification(err);
      },
    });

  const handleSelectIndirectReports = (val = []) => {
    setSearchedIndirectReports(val);
  };

  const handleNudgingTable = (type = "") => {
    setNudgingTable(type);
  };

  const handleSearchIndirectReports = () => {
    if (searchedIndirectReports?.length > 0) {
      getIndirectReportReviews({
        reviewCycleId: review?.review_cycle?.id,
        report_ids: searchedIndirectReports,
      });
      setIndirectSearchError("");
    } else {
      setIndirectSearchError("Can't search with empty indirect reports");
    }
  };

  const handleNudgeSuccess = (data, type) => {
    if (type === "self") {
      setReview({
        ...review,
        self_assessment: getNudgedReviewsForDetails(
          review?.self_assessment,
          data?.review_id,
          "reviewer",
          data?.nudge_at
        ),
      });
    } else if (type === "direct") {
      setReview({
        ...review,
        direct_report_assessment: getNudgedReviewsForDetails(
          review?.direct_report_assessment,
          data?.review_id,
          "reviewee",
          data?.nudge_at
        ),
      });
    }
  };

  const handleAvailableIndirectReports = (availableEmployees = []) => {
    const uniqueEmployeeIds = [
      ...new Set(availableEmployees.map((emp) => emp?.id)),
    ];
    const uniqueEmployees = uniqueEmployeeIds.map((empId) =>
      availableEmployees?.find((emp) => emp?.id === empId)
    );
    setAvailableIndirectReports(uniqueEmployees);
  };

  const handleFinalize = (reviewee) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "After finalizing, you wont be able to change these peers again.",
      onOk() {
        finalizePeers({ reviewId: reviewee?.id });
      },
      okButtonProps: { disabled: isFinalizingPeer },
    });
  };

  const unFinalizedReviews = review?.my_report_reviews
    ?.filter((review) => {
      return !review?.peer_selection_finalized;
    })
    ?.map((review) => review?.id);

  const handleBulkFinalize = () => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content:
        "This will finalize all the peers for all peers. After finalizing, you wont be able to change these peers again.",
      onOk() {
        bulkFinalizeReports({
          reviewCycleId: review?.review_cycle?.id,
          review_ids: unFinalizedReviews,
        });
      },
      okButtonProps: { disabled: isBulkFinalizingPeer },
    });
  };

  const handleAddPeers = (reviewee) => {
    setRevieweeForPeers(reviewee);
  };

  const loader = status === "loading" || isLoading;
  // || isFetching;

  if (loader) {
    return <BoxLoader />;
  }

  const totalReviewsToSubmit =
    (review?.my_peer_assessments?.length || 0) +
    (review?.my_report_reviews?.length || 0) +
    (review?.my_review?.length || 0);

  const hasPeersEnabled = review?.review_cycle?.has_peer_reviews;
  const appealWindowStart =
    review?.review_cycle?.appeal_window_start_date || null;
  const appealWindowEnd = review?.review_cycle?.appeal_window_end_date || null;

  return (
    <>
      {Boolean(revieweeForPeers) && (
        <AddPeersDrawer
          revieweeForPeers={revieweeForPeers}
          handleAddPeers={handleAddPeers}
          refetch={refetch}
          onClose={() => {
            handleAddPeers(null);
          }}
        />
      )}
      {isFetching && <BoxLoader className="mb-16" height="5vh" />}
      <Card className="mb-16 reviews-overview-details-card">
        <Row gutter={[32]} algin="middle">
          {hasPeersEnabled && (
            <Col className="flex-display flex-direction-column justify-content-center">
              <Text type="secondary" className="block-display">
                Add Peers Due date
              </Text>
              <Text>
                {formattedDate(review?.review_cycle?.deadline_for_adding_peer)}
              </Text>
            </Col>
          )}
          <Col className="flex-display flex-direction-column justify-content-center">
            <Text type="secondary" className="block-display">
              Submit Due date
            </Text>
            <Text>
              {formattedDate(review?.review_cycle?.deadline_for_submit)}
            </Text>
          </Col>
          {appealWindowStart && appealWindowEnd && (
            <Col className="flex-display flex-direction-column justify-content-center">
              <Text type="secondary" className="block-display">
                Appeal Window
              </Text>
              <Text>
                {`${formattedDateShortYear(
                  appealWindowStart
                )} - ${formattedDateShortYear(appealWindowEnd)}`}
              </Text>
            </Col>
          )}

          <Col className="flex-display flex-direction-column justify-content-center">
            <Text type="secondary" className="block-display">
              Sign Due date
            </Text>
            <Text>
              {formattedDate(review?.review_cycle?.deadline_for_sign)}
            </Text>
          </Col>
          {review?.review_cycle?.auto_sign_date && (
            <Col className="flex-display flex-direction-column justify-content-center">
              <Text type="secondary" className="block-display">
                Auto Sign date
              </Text>
              <Text>
                {formattedDateShortYear(review?.review_cycle?.auto_sign_date)}
              </Text>
            </Col>
          )}

          <Col className="flex-display flex-direction-column justify-content-center">
            <Text type="secondary" className="block-display">
              Reviews to Submit
            </Text>
            <Text>{reviewsToSubmit || 0}</Text>
          </Col>
          <Col>
            <DonutChart
              showLabels={true}
              // series={[
              //   review?.review_cycle?.reviews_to_submit -
              //     review?.review_cycle?.reviews_submitted,
              //   review?.review_cycle?.reviews_submitted,
              // ]}
              series={[totalReviewsToSubmit - reviewsToSubmit, reviewsToSubmit]}
              formatter={(w) => {
                const percentage = Math.round(
                  (w.globals.seriesTotals[0] /
                    (w.globals.seriesTotals[0] + w.globals.seriesTotals[1])) *
                    100
                );
                return percentage ? `${percentage}%` : "0";
              }}
              // formatter={(w) => {
              //   console.log(w.globals.seriesTotals);
              //   if (w.globals.seriesTotals !== [0, 0]) {
              //     return w.globals.seriesTotals.reduce((a, b) => {
              //       const val = Math.round((b / a + b) * 100);
              //       return `${val}%`;
              //     }, 0);
              //   }
              //   return "";
              // }}
              showName={false}
              valueOffsetY={6}
              totalFontSize={"16px"}
              labels={["Completed", "Pending"]}
              colors={["#a48be8", "#e2e2e2"]}
            />
          </Col>
        </Row>
      </Card>

      <Title level={3}>Your TODOS</Title>
      <Collapse
        accordion={true}
        className="mb-16 perf-review-details-collapse root-collapse"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {hasPeersEnabled && review?.my_report_reviews?.length > 0 && (
          <Panel
            className="perf-review-details-panel"
            header={
              <PerfReviewTodoPanelHeader
                title={"Add and Finalize peers to your Direct Reports."}
                differenceOfDates={
                  getDatesDiff(
                    review?.review_cycle?.deadline_for_adding_peer
                  )[0]
                }
                isDueDateInPast={
                  getDatesDiff(
                    review?.review_cycle?.deadline_for_adding_peer
                  )[1]
                }
                todosCompletedCount={
                  review?.my_report_reviews?.filter(
                    (review) => review?.peer_selection_finalized
                  )?.length
                }
                totalTodosCompletedCount={review?.my_report_reviews?.length}
              />
            }
            key="add-finalize-peers"
          >
            {unFinalizedReviews?.length > 0 && (
              <Row justify="end">
                <Button type="link" onClick={handleBulkFinalize}>
                  Bulk Finalize
                </Button>
              </Row>
            )}
            <Table
              className={"m-16 light-border"}
              loading={nudgingTable === "peers-table"}
              columns={getColumns({
                type: "finalize-peers-table",
                review,
                handleNudgingTable,
                handleNudgeSuccess,
                handleAddPeers,
                handleFinalize,
              })}
              dataSource={review?.my_report_reviews}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
              scroll={mobileTableScroll}
            />
          </Panel>
        )}
        {review?.my_review?.length > 0 && (
          <Panel
            className="perf-review-details-panel"
            header={
              <PerfReviewTodoPanelHeader
                title={"Complete your self assessment"}
                differenceOfDates={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[0]
                }
                isDueDateInPast={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[1]
                }
                todosCompletedCount={
                  review?.my_review?.length > 0
                    ? review?.my_review[0]?.reviewee_status === "Signed" &&
                      review?.my_review[0]?.reviewer_status === "Signed"
                      ? 1
                      : 0
                    : 0
                }
                totalTodosCompletedCount={review?.my_review?.length}
              />
            }
            key="submit-for-assessment"
          >
            <CustomExpandTable
              className={"m-16 light-border"}
              loading={nudgingTable === "self"}
              columnParams={{
                type: "self",
                review,
                handleNudgingTable,
                handleNudgeSuccess,
              }}
              dataSource={review?.my_review}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
              scroll={mobileTableScroll}
              rowKey={(row) => row?.id}
              expandable={{
                expandedRowClassName: () => "perf-review-expanded-row",
                expandIcon: ({ expanded, onExpand, record }) => null,
                expandedRowRender: (row) => (
                  <PerfReviewExpandedRowRender
                    shouldShowRevieweeNudge={false}
                    hasPeersEnabled={hasPeersEnabled}
                    row={row}
                    cycle={review?.review_cycle}
                    setIsNudging={handleNudgingTable}
                    handleNudgeSuccess={handleNudgeSuccess}
                  />
                ),
                expandIconColumnIndex: 2,
              }}
            />
          </Panel>
        )}
        {review?.my_report_reviews?.length > 0 && (
          <Panel
            className="perf-review-details-panel"
            header={
              <PerfReviewTodoPanelHeader
                title={"Complete your direct report assessments"}
                differenceOfDates={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[0]
                }
                isDueDateInPast={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[1]
                }
                todosCompletedCount={
                  review?.my_report_reviews?.filter(
                    (report) =>
                      report?.reviewee_status === "Signed" &&
                      report?.reviewer_status === "Signed"
                  )?.length
                }
                totalTodosCompletedCount={review?.my_report_reviews?.length}
              />
            }
            key="direct-reports-assessment"
          >
            <CustomExpandTable
              className={"m-16 light-border"}
              loading={nudgingTable === "direct"}
              columnParams={{
                type: "direct",
                review,
                handleNudgingTable,
                handleNudgeSuccess,
              }}
              dataSource={review?.my_report_reviews}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
              scroll={mobileTableScroll}
              rowKey={(row) => row?.id}
              expandable={{
                expandedRowKeys: [162],
                expandIcon: ({ expanded, onExpand, record }) => null,
                expandedRowRender: (row) => (
                  <PerfReviewExpandedRowRender
                    type="direct"
                    shouldShowReviewerNudge={false}
                    hasPeersEnabled={hasPeersEnabled}
                    row={row}
                    cycle={review?.review_cycle}
                    setIsNudging={handleNudgingTable}
                    handleNudgeSuccess={handleNudgeSuccess}
                  />
                ),
                expandIconColumnIndex: 2,
              }}
            />
          </Panel>
        )}
        {hasPeersEnabled && review?.my_peer_assessments?.length > 0 && (
          <Panel
            className="perf-review-details-panel"
            header={
              <PerfReviewTodoPanelHeader
                title={"Submit your Peer assessments"}
                differenceOfDates={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[0]
                }
                isDueDateInPast={
                  getDatesDiff(review?.review_cycle?.deadline_for_submit)[1]
                }
                todosCompletedCount={
                  review?.my_peer_assessments?.filter(
                    (peer) => peer?.status === "submitted"
                  )?.length
                }
                totalTodosCompletedCount={review?.my_peer_assessments?.length}
              />
            }
            key="peer-assessment"
          >
            <Table
              className={"m-16 light-border"}
              dataSource={review?.my_peer_assessments?.map((assessment) => ({
                ...assessment,
                deadline_for_submit: review?.review_cycle?.deadline_for_submit,
              }))}
              columns={getColumns({
                type: "my-peers",
                review,
                handleNudgingTable,
                handleNudgeSuccess,
              })}
              pagination={{
                hideOnSinglePage: true,
                size: "small",
              }}
              scroll={mobileTableScroll}
            />
          </Panel>
        )}
      </Collapse>

      <Title level={3} className="mt-50">
        My Indirect Reports Reviews
      </Title>
      <Divider className="my-8" />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <APIBasedEmployeeSearch
            mode="multiple"
            className="width-250-px mb-8"
            value={searchedIndirectReports}
            currentOwners={availableIndirectReports}
            placeholder="Type indirect report name"
            showArrow={false}
            allowClear={true}
            shouldShowAvatar={true}
            onChange={handleSelectIndirectReports}
            setAvailableEmployees={handleAvailableIndirectReports}
            searchApi={searchReportsChain}
          />

          <Button
            className="ml-8 mb-4"
            size="small"
            type="primary"
            ghost={true}
            onClick={handleSearchIndirectReports}
          >
            Get Reviews
          </Button>
        </div>
        {indirectSearchError && (
          <Text type="danger">{indirectSearchError}</Text>
        )}
      </div>
      {indirectReportsData?.length > 0 && (
        <CustomExpandTable
          className={"m-16 light-border"}
          loading={
            indirectReportsStatus === "loading" || nudgingTable === "indirect"
          }
          columnParams={{
            type: "indirect",
            review,
            handleNudgingTable,
            handleNudgeSuccess,
          }}
          dataSource={indirectReportsData}
          pagination={{
            hideOnSinglePage: true,
            size: "small",
          }}
          scroll={mobileTableScroll}
          rowKey={(row) => row?.id}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => null,
            expandedRowRender: (row) => (
              <PerfReviewExpandedRowRender
                type="indirect"
                hasPeersEnabled={hasPeersEnabled}
                key={row?.id}
                row={row}
                cycle={review?.review_cycle}
                setIsNudging={handleNudgingTable}
                handleNudgeSuccess={handleNudgeSuccess}
              />
            ),
            expandIconColumnIndex: 2,
          }}
        />
      )}
      <Divider className="my-8" />
    </>
  );
};

ReviewDetailsOverview.propTypes = {};

export default ReviewDetailsOverview;

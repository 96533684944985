import React from "react";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Form, Input, Modal, Space, Typography, Row, Col } from "antd";
import { DeleteOutlined, DragOutlined } from "@ant-design/icons";

import useSetQuestionFormValues from "hooks/useSetQuestionFormValues";
import styles from "../../../ThreeSixtyFeedback.module.css";
import { MCQ_TYPE } from "../../../threeSixtyConstants";

const { Title, Link, Text } = Typography;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((question, index) => ({ ...question, order: index + 1 }));
};

const AddMcqQuestionForm = ({ visible, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  useSetQuestionFormValues({ type: MCQ_TYPE, form, values: initialValues });

  const onOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleUpdateOption = (e, optionId, options = []) => {
    const updatedOptions = options.map((option) => {
      if (option.id === optionId) {
        return { ...option, value: e.target.value };
      }
      return { ...option };
    });
    form.setFieldsValue({ options: updatedOptions });
  };

  const handleRemoveOption = (optionId, options = []) => {
    const updatedOptions = options.filter((option) => option.id !== optionId);
    const updatedOrderedOptions = updatedOptions.map((option, index) => ({
      ...option,
      order: index + 1,
    }));
    form.setFieldsValue({ options: updatedOrderedOptions });
  };

  const handleAddOption = (options = []) => {
    //TODO: get max of id add 1 for new option
    const maxOptionId =
      options?.length > 0
        ? Math.max(...options?.map((option) => option?.id))
        : 0;
    const updatedOptions = [...options, { id: maxOptionId + 1, value: "" }];
    form.setFieldsValue({ options: updatedOptions });
  };

  const handleDragEndOption = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let options = form.getFieldValue("options") || [];
    const orderedOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    form.setFieldsValue({ options: orderedOptions });
  };

  return (
    <Modal
      maskClosable={false}
      width="1000px"
      title="Add Multiple Choice Question"
      visible={visible}
      onOk={onOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="mcqQuestionForm"
        initialValues={{ type: MCQ_TYPE, options: [{ id: 1, value: "" }] }}
      >
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="order" hidden>
          <Input />
        </Form.Item>

        <Row>
          <Col span={24}>
            {/* <Text>Question for the Reviewee</Text> */}
            <Form.Item
              name="description"
              // label="Question for the Reviewee"
              rules={[
                {
                  required: true,
                  message: "Question is required",
                },
                {
                  whitespace: true,
                  message: "Question cant be empty.",
                },
              ]}
              required={false}
            >
              <Input.TextArea
                className={clsx("mt-16", "input-textarea-resize-none")}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Space className="mb-16" direction="vertical">
          <Text type="secondary">Macros you can use in the question text.</Text>
          <Text>First Name - %FIRST_NAME%</Text>
          <Text>Last Name - %LAST_NAME%</Text>
          <Text>Full Name - %FULL_NAME%</Text>
        </Space>

        <Title level={4}>Options</Title>
        <Form.Item shouldUpdate className={styles.createMcqOptionsWidth}>
          {() => {
            const options = form.getFieldValue("options") || [];
            return (
              <DragDropContext onDragEnd={handleDragEndOption}>
                <Droppable droppableId="options">
                  {(provided) => (
                    <div
                      className="mt-8"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {options.map((option, index) => (
                        <Draggable draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <div
                              className="mb-8"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Input
                                value={option?.value || ""}
                                onChange={(e) => {
                                  handleUpdateOption(
                                    e,
                                    option?.id,
                                    form.getFieldValue("options") || []
                                  );
                                }}
                                addonAfter={
                                  <Space>
                                    <DragOutlined
                                      {...provided.dragHandleProps}
                                    />
                                    <DeleteOutlined
                                      onClick={() => {
                                        handleRemoveOption(
                                          option?.id,
                                          form.getFieldValue("options") || []
                                        );
                                      }}
                                    />
                                  </Space>
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <Form.Item>
                        <Link
                          onClick={() => {
                            handleAddOption(form.getFieldValue("options"));
                          }}
                          block
                        >
                          Add Another Option
                        </Link>
                      </Form.Item>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddMcqQuestionForm.propTypes = {};

export default AddMcqQuestionForm;

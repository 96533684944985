import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Input, Select, Button, Divider } from "antd";

const { Option } = Select;

const KPISelect = ({ value, onChange }) => {
  const options = [
    "$",
    "Revenue",
    "%",
    "Number",
    "profits",
    "profit margin",
    "Meetings",
  ];
  const [isCustom, setIsCustom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [newOption, setNewOption] = useState("");

  const handleCustom = () => {
    if (!isCustom) {
      onChange("");
    }
    setIsCustom(!isCustom);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Select
      value={value}
      open={isOpen}
      onChange={(val) => {
        onChange(val);
        handleOpen();
      }}
      // onBlur={handleOpen}
      // onFocus={handleOpen}
      onClick={handleOpen}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          {isCustom || !options?.includes(value) ? (
            <div
              style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Input
                style={{ flex: "auto" }}
                value={value}
                placeholder="Type & Enter"
                onChange={(e) => {
                  e.stopPropagation();
                  setNewOption(e.target.value);
                  onChange(e.target.value || "");
                }}
                onPressEnter={(e) => {
                  e.stopPropagation();
                  onChange(newOption || "");
                  handleOpen();
                }}
              />
            </div>
          ) : (
            <Row justify="center">
              <Button
                type="link"
                // size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCustom();
                }}
              >
                Custom
              </Button>
            </Row>
          )}
        </div>
      )}
    >
      {options?.map((option) => (
        <Option value={option}>
          <Row justify="end">{option}</Row>
        </Option>
      ))}
      {newOption && (
        <Option value={newOption} style={{ display: "none" }}>
          <Row justify="end">{newOption}</Row>
        </Option>
      )}
    </Select>
  );
};

KPISelect.propTypes = {
  onChange: PropTypes.func,
};

export default KPISelect;

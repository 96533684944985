const getBillingDetailsMock = () => {
  return {
    plan_name: "paid_plan",
    charge_per_user_per_month: 3,
    card_last_4_digits: 1234,
    invoices: [
      {
        id: 1,
        from_date: "2020-08-03 06:34:36.846674",
        to_date: "2021-08-03",
        amount: 21.5,
        currency: "usd",
        status: "",
        pdf_link: "https://www.google.com",
      },
    ],
  };
};

const getSetupIntentMock = () => {
  return { client_secrete: "abcd" };
};

export { getBillingDetailsMock, getSetupIntentMock };

import React from "react";
import PropTypes from "prop-types";
import { List, Radio } from "antd";

import styles from "app/reviews/reviews.module.css";

const PerfReviewMCQRadioGroup = ({
  disabled = false,
  value = null,
  isRating = false,
  ratingOptions = [],
  question = {},
  onChange = () => {},
}) => {
  const options = isRating ? ratingOptions : question?.options || [];

  return (
    <List bordered className={`${styles.optionsListBorder}`}>
      <List.Item>
        <Radio.Group
          disabled={disabled}
          value={
            value
            //   answers[question.id] || null
          }
          onChange={
            onChange
            //   (e) => handleMcqAnswerChange(e, question.id)
          }
        >
          {options?.map((option) => (
            <Radio className={styles.radioBtn} value={option.id}>
              {option.value}
            </Radio>
          ))}
        </Radio.Group>
      </List.Item>
    </List>
  );
};

PerfReviewMCQRadioGroup.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object,
  question: PropTypes.object,
  onChange: PropTypes.func,
};

export default PerfReviewMCQRadioGroup;

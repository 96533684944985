import AppLayoutV3 from "components/layouts/AppLayoutV3";
import Home from "./Home";

const routes = [
  {
    exact: true,
    path: "/home",
    layout: AppLayoutV3,
    component: Home,
  },
];

export default routes;

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  Button,
  Steps,
  Row,
  Select,
  Upload,
  Table,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import * as PapaParse from "papaparse";
import { ButtonWithSpinner } from "components";
import { swapObjectKeysWithValues } from "app/appUtils";
import { mobileTableScroll } from "app/appConstants";

import styles from "./BulkCreateEmployeesStepper.module.css";

const { Step } = Steps;
const { Text, Link } = Typography;
const { Option } = Select;

const PreviewTable = ({
  numberOfRows = 4,
  selectedCsvEmployees = [],
  dataSource = [],
  setSelectedCsvEmployees = () => {},
}) => {
  useEffect(() => {
    if (selectedCsvEmployees?.length === 0) {
      setSelectedCsvEmployees(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space direction="vertical">
      <Text type="secondary">{`Found ${dataSource?.length || 0} entries`}</Text>
      <Table
        columns={[
          {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
            width: "30%",
          },
          {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
            width: "30%",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ellipsis: true,
            width: "30%",
          },
          {
            title: "Manager Email",
            dataIndex: "manager_email",
            key: "manager_email",
            ellipsis: true,
            width: "30%",
            render: (val) => <>{val ? val : "-"}</>,
          },
          {
            title: "Employee Code",
            dataIndex: "company_employee_id",
            key: "company_employee_id",
            ellipsis: true,
            width: "20%",
            render: (val) => <>{val ? val : "-"}</>,
          },
          {
            title: "Department",
            dataIndex: "department",
            key: "department",
            ellipsis: true,
            width: "20%",
            render: (val) => <>{val ? val : "-"}</>,
          },
          {
            title: "Sub Department",
            dataIndex: "sub_department",
            key: "sub_department",
            ellipsis: true,
            width: "20%",
            render: (val) => <>{val ? val : "-"}</>,
          },
          {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            ellipsis: true,
            width: "20%",
            render: (val) => <>{val ? val : "-"}</>,
          },
        ]}
        dataSource={dataSource}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedCsvEmployees?.map((row) => row.key),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCsvEmployees([...selectedRows]);
          },
        }}
        scroll={{ x: 300, y: 50 * numberOfRows }}
        pagination={false}
      />
    </Space>
  );
};

const BulkCreateEmployeesStepper = ({
  numberOfRows = 4,
  isAddEmployeesPosting = false,
  selectedCsvEmployees = [],
  bulkUploadEmployees = () => {},
  setSelectedCsvEmployees = () => {},
}) => {
  const [csvFileList, setCsvFileList] = useState([]);
  const [csvEmployees, setCsvEmployees] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [csvColumns, setCsvColumns] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const onMapFieldChange = (field, mappedField) => {
    const updatedFieldMap = { ...fieldMapping };
    let existingKey = "";
    Object.entries(updatedFieldMap).forEach(([key, value]) => {
      if (value === mappedField) {
        existingKey = key;
      }
    });
    if (existingKey) {
      delete updatedFieldMap[existingKey];
    }
    updatedFieldMap[field] = mappedField;
    setFieldMapping(updatedFieldMap);
  };

  const getFormattedCsvEmployees = () => {
    const fieldMappingReverseMap = swapObjectKeysWithValues(fieldMapping);

    return csvEmployees.map((emp) => ({
      key: emp[fieldMappingReverseMap["email"]],
      first_name: emp[fieldMappingReverseMap["first_name"]],
      last_name: emp[fieldMappingReverseMap["last_name"]],
      email: emp[fieldMappingReverseMap["email"]],
      manager_email: emp[fieldMappingReverseMap["manager_email"]],
      department: emp[fieldMappingReverseMap["department"]],
      sub_department: emp[fieldMappingReverseMap["sub_department"]],
      designation: emp[fieldMappingReverseMap["designation"]],
      company_employee_id: emp[fieldMappingReverseMap["company_employee_id"]],
    }));
  };

  const areAllFieldsSelected = () => {
    const fieldMappingReverseMap = swapObjectKeysWithValues(fieldMapping);
    return (
      fieldMappingReverseMap["email"] &&
      fieldMappingReverseMap["first_name"] &&
      fieldMappingReverseMap["last_name"]
    );
  };

  return (
    <div className={styles.step2Stepper}>
      <Steps className="mb-24" current={currentStep}>
        <Step title="Upload" />
        <Step title="Map Columns" />
        <Step title="Preview" />
      </Steps>
      {currentStep === 0 && (
        <div className={styles.uploadBtnWithText}>
          <Upload
            accept={("csv", "text/csv")}
            beforeUpload={(file) => {
              setCsvFileList([file]);
              const reader = new FileReader();
              reader.onload = (e) => {
                const csvData = PapaParse.parse(reader.result, {
                  header: true,
                  dynamicTyping: true,
                  skipEmptyLines: true,
                  transformHeader: (header) =>
                    header.toLowerCase().replace(/\W/g, "_"),
                });
                setCsvEmployees(csvData?.data);

                const columns = csvData?.meta?.fields?.map((field, index) => ({
                  key: index,
                  csv_column: field,
                }));
                setCsvColumns(columns);
                setCurrentStep(1);
              };
              reader.readAsText(file);
              return false;
            }}
            onRemove={() => {
              setCsvEmployees([]);
              setCsvFileList([]);
              setSelectedCsvEmployees([]);
              setFieldMapping([]);
              setCsvColumns([]);
            }}
            // showUploadList={false}
            fileList={csvFileList}
          >
            <Button
              icon={<UploadOutlined />}
              size="small"
              type="primary"
              ghost={true}
            >
              Upload Employee CSV File
            </Button>
          </Upload>
          <Text className={clsx("small-text", "mx-16")}>or</Text>
          <Link href="/sample_employees.csv" className={"fs-14"}>
            Download Sample CSV Format
          </Link>
        </div>
      )}

      {currentStep === 1 && (
        <>
          <Text type="secondary" className="mb-8">
            We need First Name, Last Name and Email to add employees to
            directory. Please map the corresponding fields in your CSV file.
          </Text>
          <Table
            dataSource={csvColumns}
            columns={[
              {
                title: "CSV Column",
                dataIndex: "csv_column",
                key: "csv_column",
              },
              {
                title: "Mapped Field",
                dataIndex: "mapped_field",
                key: "mapped_field",
                width: "65%",
                render: (text, row) => (
                  <Select
                    className="width-80-percent"
                    style={{
                      color:
                        fieldMapping[row.csv_column] &&
                        fieldMapping[row.csv_column] !== "dont_map"
                          ? "#5330C9"
                          : "black",
                    }}
                    value={fieldMapping[row.csv_column] || "dont_map"}
                    onChange={(value) => {
                      onMapFieldChange(row.csv_column, value);
                    }}
                  >
                    <Option value="first_name">First Name</Option>
                    <Option value="last_name">Last Name</Option>
                    <Option value="email">Email</Option>
                    <Option value="manager_email">
                      Manager Email (Optional)
                    </Option>
                    <Option value="company_employee_id">
                      Employee Code (Optional)
                    </Option>
                    <Option value="department">Department (Optional)</Option>
                    <Option value="sub_department">
                      Sub Department (Optional)
                    </Option>
                    <Option value="designation">Designation (Optional)</Option>
                    <Option value="dont_map">
                      <Text type="secondary">Don't Map</Text>
                    </Option>
                  </Select>
                ),
              },
            ]}
            rowClassName={(record) => {
              if (
                fieldMapping[record.csv_column] &&
                fieldMapping[record.csv_column] !== "dont_map"
              ) {
                return "ant-table-row-selected";
              }
              return "";
            }}
            scroll={mobileTableScroll}
            pagination={{ size: "small", hideOnSinglePage: true }}
          />
        </>
      )}

      {currentStep === 2 && (
        <PreviewTable
          numberOfRows={numberOfRows}
          selectedCsvEmployees={selectedCsvEmployees}
          dataSource={getFormattedCsvEmployees()}
          setSelectedCsvEmployees={setSelectedCsvEmployees}
        />
      )}

      {currentStep > 0 && (
        <Row className="mt-16" justify="space-between">
          <Button
            onClick={handlePrevious}
            type="primary"
            size="small"
            ghost={true}
          >
            Previous
          </Button>

          {currentStep < 2 && (
            <Button
              disabled={currentStep === 1 && !areAllFieldsSelected()}
              onClick={handleNext}
              type="primary"
              size="small"
            >
              Next
            </Button>
          )}

          {currentStep === 2 && (
            <ButtonWithSpinner
              size="small"
              isSpinning={isAddEmployeesPosting}
              className="mb-16"
              type="primary"
              disabled={
                selectedCsvEmployees.length === 0 || isAddEmployeesPosting
              }
              onClick={bulkUploadEmployees}
            >
              Add Employees to Directory
            </ButtonWithSpinner>
          )}
        </Row>
      )}
    </div>
  );
};

BulkCreateEmployeesStepper.propTypes = {};

export default BulkCreateEmployeesStepper;

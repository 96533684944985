const surveyQuestionTypes = {
  TEXT: "SurveyText",
  MCQ: "SurveyMcq",
};

const templates = [
  {
    name: "Employee Engagement Survey",
    description:
      "Use this survey to learn about how engaged your employees are in various initiatives of the company.",
    questions: [
      {
        type: surveyQuestionTypes.MCQ,
        order: 1,
        description: "I’m very happy to work for our company",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 2,
        description: "I see myself growing in the next 1 year",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 3,
        description: "My colleagues help me whenever I need help.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 4,
        description: "My work environment pushes me to get better at my job.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 5,
        description: "I understand the company goals.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 6,
        description:
          "I’m aligned with the company goals and am motivated to achieve them.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 7,
        description: "I would recommend our company for my friends to join.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
    ],
  },
  {
    name: "Remote Work Effectiveness",
    description:
      "Check how remote work environment is working for the employees.",
    questions: [
      {
        type: surveyQuestionTypes.MCQ,
        order: 1,
        description:
          "I feel that I’m as productive working remotely as I would if I were in the office.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 2,
        description: "I am able to communicate effectively working remote.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 3,
        description:
          "At our company, there are clear expectations on how employees should communicate while working remote.",
        options: {
          "1": "Strongly Disagree",
          "2": "Partially Disagree",
          "3": "Not Sure",
          "4": "Partially Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 4,
        description: "What more can you do to be more remote-productive?",
        allow_comments: false,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 5,
        description:
          "What more can your team members do to be more remote-productive?",
        allow_comments: false,
        is_required: true,
      },
    ],
  },
  {
    name: "Learning and Development",
    description: "Review how employees perceive their career development.",
    questions: [
      {
        type: surveyQuestionTypes.MCQ,
        order: 1,
        description: "My company invests in my training and education.",
        options: {
          "1": "Strongly Disagree",
          "2": "Neutral",
          "3": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 2,
        description:
          "I get enough opportunities to apply my skills and knowledge in the workplace.",
        options: {
          "1": "Strongly Disagree",
          "2": "Neutral",
          "3": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 3,
        description:
          "I get help and support from my colleagues when it comes to learning new skills.",
        options: {
          "1": "Strongly Disagree",
          "2": "Neutral",
          "3": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 4,
        description:
          "What more can the company do to help with your professional learning?",
        allow_comments: false,
        is_required: true,
      },
    ],
  },
  {
    name: "Manager Performance",
    description: "Collect anonymous feedback on managerial cadre.",
    questions: [
      {
        type: surveyQuestionTypes.MCQ,
        order: 1,
        description: "I would recommend my manager to a colleague.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 2,
        description: "My manager helps me whenever I get stuck or needs help.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 3,
        description: "My manager is readily available for a conversation.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 4,
        description: "I get very actionable feedback from my manager.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 5,
        description:
          "My manager recognizes team members and gives the credit when it is due.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 6,
        description: " How can your manager improve?",
        allow_comments: false,
        is_required: true,
      },
    ],
  },
  {
    name: "Leadership Perception",
    description: "Understand how your employees perceive the leadership team.",
    questions: [
      {
        type: surveyQuestionTypes.MCQ,
        order: 1,
        description:
          "My company’s leadership keeps us informed about what is happening.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.MCQ,
        order: 2,
        description:
          "My company’s leaders have communicated a vision that motivates me.",
        options: {
          "1": "Strongly Disagree",
          "2": "Somewhat Disagree",
          "3": "Neutral",
          "4": "Somewhat Agree",
          "5": "Strongly Agree",
        },
        allow_comments: true,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 3,
        description: "What is your advice to the company’s leadership?",
        allow_comments: false,
        is_required: true,
      },
      {
        type: surveyQuestionTypes.TEXT,
        order: 4,
        description: "What do you think the leadership gets right?",
        allow_comments: false,
        is_required: true,
      },
    ],
  },
];

export default templates;

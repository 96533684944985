import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Tabs, PageHeader } from "antd";
import { useParams, useHistory } from "react-router-dom";

import CompanyDetails from "../directory/common/CompanyDetails";
import Plans from "../billing/Plans";

const { Title } = Typography;
const { TabPane } = Tabs;

const CompanySettings = ({ shouldShowCancel = false }) => {
  const history = useHistory();

  const { selectedTab: defaultSelectedTab } = useParams();
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    history.push(`/settings/company-settings/${tab}`);
  };

  return (
    <div>
      <PageHeader
        className="pl-0 pt-0 pb-0"
        title={<Title level={2}>Company Settings</Title>}
      />

      <Tabs className="mt-8" activeKey={selectedTab} onChange={handleTabChange}>
        <TabPane tab="Details" key="details">
          <CompanyDetails shouldShowCancel={shouldShowCancel} />
        </TabPane>
        <TabPane tab="Billing" key="billing">
          <div className="mb-16">
            <Plans shouldShowHeader={false} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

CompanySettings.propTypes = {
  shouldShowCancel: PropTypes.bool,
};

export default CompanySettings;

import React from "react";

import PropTypes from "prop-types";
import { Alert, Typography, Button, Space, Modal } from "antd";

import { ButtonWithSpinner } from "components";
import useButtonWithSpinner from "hooks/useButtonWithSpinner";
import styles from "./NotificationModal.module.css";
import { formattedDate } from "app/appUtils";

const { Title, Text, Link } = Typography;

const NotificationModal = ({
  from = "index",
  isLoading = false,
  cycle = {},
  visible = false,
  handleCancel = () => {},
  handleSubmit = () => {},
}) => {
  const [buttonWithSpinner, setButtonWithSpinner] = useButtonWithSpinner();

  const getFooterButtons = () => {
    if (from === "form") {
      return [
        <ButtonWithSpinner
          type="primary"
          onClick={() => {
            setButtonWithSpinner("now");
            handleSubmit(true);
          }}
          disabled={isLoading}
          isSpinning={isLoading && buttonWithSpinner === "now"}
        >
          Email Now
        </ButtonWithSpinner>,
        <ButtonWithSpinner
          type="primary"
          disabled={isLoading}
          isSpinning={isLoading && buttonWithSpinner === "later"}
          onClick={() => {
            setButtonWithSpinner("later");
            handleSubmit(false);
          }}
        >
          Email Later
        </ButtonWithSpinner>,
        <Button
          disabled={isLoading}
          type="primary"
          ghost
          onClick={handleCancel}
        >
          Cancel
        </Button>,
      ];
    }
    return [
      <ButtonWithSpinner
        disabled={isLoading}
        type="primary"
        onClick={() => {
          setButtonWithSpinner("now");
          handleSubmit(true);
        }}
        isSpinning={isLoading && buttonWithSpinner === "now"}
      >
        Email Now
      </ButtonWithSpinner>,
      <Button disabled={isLoading} type="primary" ghost onClick={handleCancel}>
        Cancel
      </Button>,
    ];
  };

  return (
    <Modal
      visible={visible}
      title={"Inform Participants by Email?"}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      onOk={handleSubmit}
      footer={getFooterButtons()}
    >
      <Alert
        className="mb-16"
        type="warning"
        message="This is just a preview with sample data. The names, links and dates will be replaced"
      />
      <div className={styles.notificationBody}>
        <Space size="middle" direction="vertical">
          <Title level={3}>Subject: Time for your 360 feedback</Title>
          <Text>Hi Joe,</Text>
          <Text className="block-display">
            {`Your 360 feedback has been set up for ${cycle?.name}.`}
          </Text>
          <Text className="block-display">
            {cycle?.employee_can_nominate &&
              `Please nominate your reviewers by ${formattedDate(
                cycle?.nominate_due_date
              )}. Your manager can edit your reviewers list.
            `}
          </Text>
          <Link>(a link to the newly created survey)</Link>
          <Text className="block-display">
            {`Feedback will be collected anonymously from your reviewers and will
            be visible to you and your manager on ${formattedDate(
              cycle?.submit_due_date
            )}`}
          </Text>
          <Text className="block-display">Thank You!</Text>
        </Space>
      </div>
    </Modal>
  );
};

NotificationModal.propTypes = {
  cycle: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default NotificationModal;

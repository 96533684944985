import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";

import { EmployeeOKRActivity } from "components";

const SeeOKRsDrawer = ({
  defaultOKRPeriodId = null,
  screens = {},
  employee = null,
  onClose = () => {},
}) => {
  return (
    <Drawer
      title={"OKRs"}
      width={screens?.lg ? "50%" : "95%"}
      visible={Boolean(employee)}
      onClose={onClose}
    >
      <EmployeeOKRActivity
        defaultOKRPeriodId={defaultOKRPeriodId}
        employee={employee}
        shouldShowCurrentPeriod={true}
      />
    </Drawer>
  );
};

SeeOKRsDrawer.propTypes = {
  employee: PropTypes.object,
};

export default SeeOKRsDrawer;

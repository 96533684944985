const timezones = {
  "UTC-11:00 MIT Midway Islands Time": "GMT-11:00 MIT Midway Islands Time",
  "UTC-10:00 HST Hawaii Standard Time": "GMT-10:00 HST Hawaii Standard Time",
  "UTC-9:00 AST Alaska Standard Time": "GMT-9:00 AST Alaska Standard Time",
  "UTC-08:00 Pacific/Pitcarian": "GMT-8:00 PST Pacific Standard Time",
  "UTC-07:00 PNT Phoenix Standard Time": "GMT-7:00 PNT Phoenix Standard Time",
  "UTC-07:00 MST Mountain Standard Time": "GMT-7:00 MST Mountain Standard Time",
  "UTC-06:00 CST Central Standard Time": "GMT-6:00 CST Central Standard Time",
  "UTC-05:00 EST Eastern Standard Time": "GMT-5:00 EST Eastern Standard Time",
  "UTC-05:00 IET Indiana Eastern Standard Time":
    "GMT-5:00 IET Indiana Eastern Standard Time",
  "UTC-04:00 PRT Puerto Rico and US Virgin Islands Time":
    "GMT-4:00 PRT Puerto Rico and US Virgin Islands Time",
  "UTC-03:30 CNT Canada Newfoundland Time":
    "GMT-3:30 CNT Canada Newfoundland Time",
  "UTC-03:00 AGT Argentina Standard Time":
    "GMT-3:00 AGT Argentina Standard Time",
  "UTC-03:00 BET Brazil Eastern Time": "GMT-3:00 BET Brazil Eastern Time",
  "UTC-01:00 CAT Central African Time": "GMT-1:00 CAT Central African Time",
  "UTC+00:00 Greenwich Mean Time": "GMT Greenwich Mean Time",
  "UTC+00:00 UTC Universal Coordinated Time":
    "GMT+0:00 UTC Universal Coordinated Time",
  "UTC+01:00 ECT European Central Time": "GMT+1:00 ECT European Central Time",
  "UTC+02:00 EET Eastern European Time": "GMT+2:00 EET Eastern European Time",
  "UTC+02:00 ART (Arabic) Egypt Standard Time":
    "GMT+2:00 ART (Arabic) Egypt Standard Time",
  "UTC+03:00 EAT Eastern African Time": "GMT+3:00 EAT Eastern African Time",
  "UTC+03:30 MET Middle East Time": "GMT+3:30 MET Middle East Time",
  "UTC+04:00 NET	Near East Time": "GMT+4:00 NET	Near East Time",
  "UTC+05:00 PLT	Pakistan Lahore Time": "GMT+5:00 PLT	Pakistan Lahore Time",
  "UTC+05:30 IST India Standard Time": "GMT+5:30 IST India Standard Time",
  "UTC+06:00 BST Bangladesh Standard Time":
    "GMT+6:00 BST Bangladesh Standard Time",
  "UTC+07:00 VST Vietnam Standard Time": "GMT+7:00 VST Vietnam Standard Time",
  "UTC+08:00 CTT China Taiwan Time": "GMT+8:00 CTT China Taiwan Time",
  "UTC+09:00 JST Japan Standard Time": "GMT+9:00 JST Japan Standard Time",
  "UTC+09:30 ACT Australia Central Time": "GMT+9:30 ACT Australia Central Time",
  "UTC+10:00 AET Australia Eastern Time":
    "GMT+10:00 AET Australia Eastern Time",
  "UTC+11:00 SST Solomon Standard Time": "GMT+11:00 SST Solomon Standard Time",
  "UTC+12:00 NST New Zealand Standard Time":
    "GMT+12:00 NST New Zealand Standard Time",
};

const atTimes = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const days = [
  {
    value: "sunday",
    display: "S",
  },
  {
    value: "monday",
    display: "M",
  },
  {
    value: "tuesday",
    display: "T",
  },
  {
    value: "wednesday",
    display: "W",
  },
  {
    value: "thursday",
    display: "T",
  },
  {
    value: "friday",
    display: "F",
  },
  {
    value: "saturday",
    display: "S",
  },
];

export { timezones, atTimes, days };

import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Row, Button } from "antd";

const { Title } = Typography;

const EmptyOKRCard = ({
  canUpdate = true,
  Icon = <></>,
  text = "",
  handleCreateObjective = () => {},
}) => {
  return (
    <Card className="mt-40">
      <Row
        justify="center"
        align="middle"
        className="flex-direction-column my-48"
      >
        <div>{Icon}</div>
        <Title level={3} className="mt-64 text-primary">
          {text}
        </Title>
        {canUpdate ? (
          <Button className="mt-16" type="primary" ghost={true}>
            <Row align="top" onClick={handleCreateObjective}>
              Create OKR
            </Row>
          </Button>
        ) : (
          <></>
        )}
      </Row>
    </Card>
  );
};

EmptyOKRCard.propTypes = {
  Icon: PropTypes.func,
  text: PropTypes.string,
};

export default EmptyOKRCard;

import React from "react";
// import PropTypes from 'prop-types';
import { PDFDownloadLink } from "@react-pdf/renderer";

import PerfReviewAssessmentDocument from "./PerfReviewAssessmentDocument";
import { getNameFromEmployeeObj } from "app/appUtils";

const PerfReviewAssessmentDownloadBtn = ({
  reviewData = {},
  type = "",
  className = "ant-btn ant-btn-primary ant-btn-background-ghost",
}) => {
  return (
    <>
      <PDFDownloadLink
        className={className}
        document={
          <PerfReviewAssessmentDocument reviewData={reviewData} type={type} />
        }
        fileName={`${getNameFromEmployeeObj(
          reviewData?.reviewee
        )}'s Assessment.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <>
            {
              loading
                ? "Creating Document..."
                : // <Button className="mt-16" type="primary" ghost={true}>
                  "Download Assessment"
              // </Button>
            }
            {error && error}
          </>
        )}
      </PDFDownloadLink>
    </>
  );
};

export default PerfReviewAssessmentDownloadBtn;

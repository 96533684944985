import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import { InlineSpinner, PerfReviewAssessmentDownloadBtn } from "components";

const { Text } = Typography;

const PerfReviewDownloadAssessment = ({
  type = "",
  disabled = false,
  reviewId = null,
  reviewData = {},
  loader = false,
  downloadClassName = "py-12 color-text-color",
}) => {
  return (
    <div>
      <>
        {disabled ? (
          <Text disabled={true} className={downloadClassName}>
            Download Assessment
          </Text>
        ) : (
          <>
            {loader ? (
              <>
                <InlineSpinner fontSize={20} /> <Text>Fetching Assessment</Text>
              </>
            ) : (
              <>
                {/* {reviewData?.reviewee?.status === "Signed" &&
                reviewData?.reviewer?.status === "Signed" ? ( */}
                <PerfReviewAssessmentDownloadBtn
                  reviewData={reviewData}
                  type={type}
                  className={downloadClassName}
                />
                {/* ) : (
                  <Tooltip title="Can't download assessment until both Reviewee & Reviewer have signed">
                    <Text disabled={true} className={downloadClassName}>
                      Download Assessment
                    </Text>
                  </Tooltip>
                )} */}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

PerfReviewDownloadAssessment.propTypes = {
  reviewId: PropTypes.number,
};

export default PerfReviewDownloadAssessment;

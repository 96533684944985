import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Form, Input, Modal, Row, Col, Typography, Space, Switch } from "antd";

import useSetQuestionFormValues from "hooks/useSetQuestionFormValues";
import MacroTextNote from "./MacroTextNote";

const { Text } = Typography;

const TEXT_TYPE = "PerfReviewText";

const AddTextQuestionForm = ({ visible, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const [reviewerError, setReviewerError] = useState("");
  useSetQuestionFormValues({ type: TEXT_TYPE, form, values: initialValues });

  useEffect(() => {
    setReviewerError("");
  }, []);

  const onOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      width="1000px"
      title={"Add Text Question"}
      visible={visible}
      onOk={onOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="textQuestionForm"
        initialValues={{ type: TEXT_TYPE }}
      >
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="order" hidden>
          <Input />
        </Form.Item>

        <Row gutter={[16]}>
          <Col span={12}>
            <Text>Question for the Reviewee</Text>
            <Form.Item
              name="reviewee_text"
              // label="Question for the Reviewee"
              rules={[
                {
                  required: true,
                  message: "Reviewee question is required",
                },
                {
                  whitespace: true,
                  message: "Reviewee question cant be empty.",
                },
              ]}
              required={false}
            >
              <Input.TextArea
                className={clsx("mt-16", "input-textarea-resize-none")}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space>
              <Form.Item noStyle shouldUpdate>
                {({ setFieldsValue, getFieldValue }) => {
                  const value = getFieldValue("is_required");
                  return (
                    <Switch
                      checked={value}
                      onChange={(checked) => {
                        setFieldsValue({ is_required: checked });
                      }}
                    />
                  );
                }}
              </Form.Item>
              <Text>Question for the Reviewer</Text>
            </Space>

            <Form.Item
              shouldUpdate
              // name="reviewer_text"
              // // label="Question for the Reviewer"
              // rules={[
              //   {
              //     required: true,
              //     message: "Reviewer question is required",
              //   },
              //   {
              //     whitespace: true,
              //     message: "Reviewer question cant be empty.",
              //   },
              // ]}
              // required={false}
            >
              {({ setFieldsValue, getFieldValue }) => {
                const isRequired = getFieldValue("is_required");
                const reviewerText = getFieldValue("reviewer_text");
                return (
                  <>
                    {isRequired && (
                      <>
                        <Input.TextArea
                          value={reviewerText}
                          onChange={(e) => {
                            const reviewer_text = e.target.value;
                            setFieldsValue({ reviewer_text });
                            if (!reviewer_text) {
                              setReviewerError("Reviewer question is required");
                            } else if (!reviewer_text?.trim()) {
                              setReviewerError(
                                "Reviewer question cant be empty."
                              );
                            } else {
                              setReviewerError("");
                            }
                          }}
                          className={clsx(
                            "mt-16",
                            "input-textarea-resize-none"
                          )}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                        {reviewerError && (
                          <Text type="danger">{reviewerError}</Text>
                        )}
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <MacroTextNote />
      </Form>
    </Modal>
  );
};

AddTextQuestionForm.propTypes = {};

export default AddTextQuestionForm;

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Alert, Modal, Button, Typography, Input, Row, Col } from "antd";

import styles from "../../../Survey.module.css";

const { Text, Title } = Typography;

const surveyQuestionTypes = {
  TEXT: "SurveyText",
  MCQ: "SurveyMcq",
};

const PreviewModal = ({
  visible = false,
  survey = {},
  questions = [],
  handleCancel = () => {},
}) => {
  return (
    <Modal
      visible={visible}
      title={"Questionnaire Preview"}
      destroyOnClose
      onCancel={handleCancel}
      keyboard={false}
      maskClosable={false}
      width={1000}
      footer={[
        <Button type="primary" ghost onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Row>
        <Col span={3} />
        <Col span={18}>
          <Alert
            className="mb-8"
            type="info"
            message={
              "This is a preview of how the questionnaire will look to participants."
            }
          />
          <Title level={2}>Survey Name: {survey?.name || ""}</Title>
          <Text type="secondary">{`Due date: ${survey?.due_date?.format(
            "YYYY/MM/DD"
          )}`}</Text>
          {questions?.map((question) => (
            <div className="mt-48">
              <span>
                <Text
                  className={clsx("mr-8", "font-weight-500")}
                >{`${question.order}. ${question?.description}`}</Text>
                <Text type="secondary">
                  {question?.is_required ? "" : "(Optional)"}
                </Text>
              </span>
              {question?.type === surveyQuestionTypes?.TEXT ? (
                <>
                  <Input.TextArea
                    disabled={true}
                    placeholder="Your Answer"
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    className="input-textarea-resize-none"
                  />
                </>
              ) : (
                <>
                  <Row className={clsx("mb-16", "mt-8")} justify="center">
                    <Row justify="center" gutter={[30]}>
                      {Object.keys(question.options)?.map((option) => (
                        <Col key={option} className={styles.optionCol}>
                          <Button
                            shape="circle"
                            size="large"
                            className={styles.optionBtn}
                          >
                            {option}
                          </Button>
                          <Text className={styles.optionText}>
                            {question.options[option]}
                          </Text>
                        </Col>
                      ))}
                    </Row>
                  </Row>
                  {question?.allow_comments && (
                    <Input.TextArea
                      className="mt-8 input-textarea-resize-none"
                      placeholder="Your Comment (Optional)"
                      disabled={true}
                      autoSize={{ minRows: 3, maxRows: 3 }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </Col>
        <Col span={3} />
      </Row>
    </Modal>
  );
};

PreviewModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  survey: PropTypes.object,
  questions: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PreviewModal;

import moment from "moment";
import { statusColors } from "./constants";
import { formattedDate } from "app/appUtils";

const getValueFromPercentage = (percentage, start, target) => {
  const areDecimalNumbers = start % 1 !== 0 && target % 1 !== 0;

  if (start > target) {
    const isDifferenceLessThan5 = start - target < 5;
    const finalValue = start - (percentage * (start - target)) / 100;
    return areDecimalNumbers || isDifferenceLessThan5
      ? Math.round(finalValue * 100) / 100
      : Math.round(finalValue);
  }
  const isDifferenceLessThan5 = target - start < 5;
  const finalValue = (percentage * (target - start)) / 100 + start;
  return areDecimalNumbers || isDifferenceLessThan5
    ? Math.round(finalValue * 100) / 100
    : Math.round(finalValue);
};

const getPercentageFromValue = (value, start, target) => {
  if (start > target) {
    return 100 - ((value - target) / (start - target)) * 100;
  }
  return ((value - start) / (target - start)) * 100;
};

const getAllDatesInSelectedPeriod = (selectedPeriod = {}) => {
  const startDate = moment(selectedPeriod?.start_date, "YYYY-MM-DD");
  const endDate = moment(selectedPeriod?.end_date, "YYYY-MM-DD");

  const first = startDate.clone();
  const dates = [];

  while (first.isSameOrBefore(endDate)) {
    dates.push(formattedDate(first));
    first.add(1, "days");
  }
  return dates;
};

const getExpectedPercentageFor = (
  selectedPeriodObj = {},
  date = moment().startOf("day")
) => {
  const periodStartDate = moment(selectedPeriodObj?.start_date, "YYYY-MM-DD");

  if (date.isSameOrBefore(periodStartDate)) {
    return 0;
  }

  const periodTargetDate = moment(selectedPeriodObj?.end_date, "YYYY-MM-DD");
  const periodDays = periodTargetDate?.diff(periodStartDate, "days");
  const daysCompleted = Math.min(
    date.diff(periodStartDate, "days"),
    periodDays
  );

  const expectedProgress = (daysCompleted / periodDays) * 100;
  return Math.round(expectedProgress);
};

const getStatus = (current = 0, expected = 0, currentStatus = "at_risk") => {
  if (currentStatus === "postponed") {
    return currentStatus;
  }

  if (current >= 100) {
    return "done";
  } else if (current === 0) {
    return "not_started";
  } else if (current >= expected) {
    return "on_track";
  } else if (current >= 0.7 * expected) {
    return "behind";
  } else {
    return "at_risk";
  }
};

const getLastStatus = (groupData = {}) => {
  const dates = Object.keys(groupData) || [];
  const lastDate = moment.max(dates?.map((date) => moment(date)));
  const formattedLastDate = lastDate?.format("YYYY-MM-DD");
  return groupData[formattedLastDate] || 0;
};

const getObjectiveStatusesFromSectionData = (objectives, selectedPeriodObj) => {
  const statuses = {
    not_started: 0,
    on_track: 0,
    behind: 0,
    at_risk: 0,
    done: 0,
  };

  objectives.forEach((objective) => {
    const percentage = Math.round(objective?.progress || 0);
    const expectedPercentage = getExpectedPercentageFor(selectedPeriodObj);
    const currentStatus =
      percentage > 0
        ? getStatus(percentage, expectedPercentage)
        : "not_started";
    statuses[currentStatus] += 1;
  });

  return statuses;
};

const getActualChartData = (allDates = [], groupSummaryData = {}) => {
  const allSummaryDates = Object.keys(groupSummaryData);
  const allSummaryMomentDates = allSummaryDates?.map((date) => moment(date));

  const minDate = moment.min(allSummaryMomentDates);
  const maxDate = moment.max(allSummaryMomentDates);

  const plots = [];
  let previousValue = 0;

  allDates.forEach((date) => {
    const dateInMoment = moment(date, "MMM Do, YYYY");
    const dateInFormat = dateInMoment.format("YYYY-MM-DD");
    // const dateToShow = dateInMoment.format("MMM Do, YYYY");

    if (dateInMoment.isBefore(minDate)) {
      plots.push({ x: date, y: 0 });
    } else if (dateInMoment.isAfter(maxDate)) {
      plots.push({ x: date, y: null });
    } else {
      const checkInValue = groupSummaryData[dateInFormat];
      if (checkInValue) {
        plots.push({ x: date, y: checkInValue });
        previousValue = checkInValue;
      } else {
        plots.push({ x: date, y: previousValue });
      }
    }
  });

  return plots;
};

const getExpectedDateObjects = (allDates, selectedPeriodObj) => {
  return allDates.map((date) => ({
    x: date,
    y: Math.round(
      getExpectedPercentageFor(selectedPeriodObj, moment(date, "MMM Do, YYYY"))
    ),
  }));
};

const getLastProgressPercentage = (groupSummaryData = {}) => {
  const allSummaryDates = Object.keys(groupSummaryData);
  const allSummaryMomentDates = allSummaryDates?.map((date) => moment(date));

  const minDate = moment.max(allSummaryMomentDates);
  return groupSummaryData[minDate.format("YYYY-MM-DD")] || null;
};

const searchStringChecker = (string = "", searchTerms = []) => {
  return searchTerms?.some((term) => term && string?.includes(term));
};

const getSearchedObjectives = (allObjectives = [], searchTerm = "") => {
  if (!searchTerm) {
    return allObjectives;
  }

  if (allObjectives?.length === 0) {
    return false;
  }

  const searchTerms = searchTerm?.toLowerCase()?.split(" ") || [];

  const filteredObjectives = allObjectives?.filter((obj) => {
    const objHasText = searchStringChecker(
      obj?.title?.toLowerCase(),
      searchTerms
    );
    const filteredKrs = obj?.key_results?.filter((kr) =>
      searchStringChecker(kr?.title?.toLowerCase(), searchTerms)
    );
    const subObjHasSearchTerm = getSearchedObjectives(
      obj?.sub_objectives || [],
      searchTerm
    );
    return (
      objHasText || filteredKrs?.length > 0 || subObjHasSearchTerm?.length > 0
    );
  });

  return filteredObjectives;
};

const getFilteredObjectives = (
  searchedObjectives = [],
  summary = {},
  selectedPeriodObj,
  filters = {}
) => {
  const statuses = filters?.selectedStatuses || [];
  const lessThan = filters?.lessThan || {};
  const greaterThan = filters?.greaterThan || {};
  const owners = filters?.owners || [];

  const statusFilteredObjectives =
    statuses?.length === 0
      ? [...searchedObjectives]
      : searchedObjectives?.filter((obj) => {
          const currentProgressOfObj =
            summary?.objectives_percentage_progress_data[obj?.id];
          const currentStatus = getStatus(
            currentProgressOfObj,
            getExpectedPercentageFor(selectedPeriodObj),
            ""
          );
          return statuses?.includes(currentStatus);
        });

  const arithmeticFilteredObjectives =
    !lessThan?.selected && !greaterThan?.selected
      ? statusFilteredObjectives
      : statusFilteredObjectives?.filter((obj) => {
          const currentProgressOfObj =
            summary?.objectives_percentage_progress_data[obj?.id];

          let isFiltered = false;
          if (lessThan?.selected) {
            isFiltered = currentProgressOfObj < lessThan?.value;
          }
          if (greaterThan?.selected) {
            isFiltered = currentProgressOfObj > greaterThan?.value;
          }
          if (lessThan?.selected && greaterThan?.selected) {
            isFiltered =
              currentProgressOfObj > greaterThan?.value &&
              currentProgressOfObj < lessThan?.value;
          }

          return isFiltered;
        });

  const ownerIds = owners?.map((owner) => owner?.id);
  const ownerFilteredObjectives =
    ownerIds?.length === 0
      ? arithmeticFilteredObjectives
      : arithmeticFilteredObjectives?.filter((obj) => {
          return ownerIds?.includes(obj?.owner?.id);
        });

  return ownerFilteredObjectives;
};

const getTreeDataFromSectionData = (
  objectives = [],
  selectedPeriodObj = {},
  progressData = {}
) => {
  return (
    objectives?.map((obj) => {
      return {
        ...obj,
        node_type: "objective",
        selectedPeriodObj,
        current: progressData ? progressData[obj?.id] : 0,
        currentProgress: progressData ? progressData[obj?.id] : 0,
        children: [
          ...getTreeDataFromSectionData(
            obj?.sub_objectives || [],
            selectedPeriodObj,
            progressData
          ),
          // ...keyResults,
        ],
      };
    }) || []
  );
};

const getActionStyles = (screens, isSmallContainer, ownersLength) => {
  let justifyContent = "flex-end";

  if (
    isSmallContainer ||
    ((screens.xs || screens.sm || screens.md) && !screens.lg)
  ) {
    justifyContent = "flex-start";
  }

  return {
    marginTop: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent,
    marginLeft: isSmallContainer && ownersLength > 1 ? "24px" : "0px",
    flexWrap: "wrap",
  };
};

const getOutlineWidth = (statuses) => {
  const zeroStatuses = statuses.filter((status) => status === 0) || [];
  const zeroOKRs = zeroStatuses?.length || 0;
  switch (zeroOKRs) {
    case 1:
      return 0.005;
    case 2:
      return 0.005;
    case 3:
      return 0.005;
    case 4:
      return 0;
    case 5:
      return 0;
    default:
      return 0.01;
  }
};

const getHsBarData = (statuses) => {
  const data = [
    {
      value: statuses["not_started"],
      color: statusColors["not_started"],
    },
    {
      value: statuses["on_track"],
      color: statusColors["on_track"],
    },
    {
      value: statuses["behind"],
      color: statusColors["behind"],
    },
    {
      value: statuses["at_risk"],
      color: statusColors["at_risk"],
    },
    {
      value: statuses["done"],
      color: statusColors["done"],
    },
  ];

  data.sort((a, b) => b?.value - a?.value);
  return data;
};

export {
  getValueFromPercentage,
  getPercentageFromValue,
  getAllDatesInSelectedPeriod,
  getExpectedPercentageFor,
  getStatus,
  getObjectiveStatusesFromSectionData,
  getActualChartData,
  getLastProgressPercentage,
  getExpectedDateObjects,
  getLastStatus,
  getSearchedObjectives,
  getFilteredObjectives,
  getTreeDataFromSectionData,
  getActionStyles,
  getHsBarData,
  getOutlineWidth,
};
